import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Button,
  Input,
  Row,
  Col,
  Label,
  FormFeedback,
  Spinner,
} from "reactstrap";

import InputMask from "react-input-mask";
import DatePicker from "react-datepicker";
import { GetLanguageString } from "../helper/Components";
import useForm from "../shared/useForm";
import {
  CheckPermission,
  LoadingSpinner,
  convertUTCToLocalTime,
  DateOfBirthValidater,
  JoiningDateValidater,
} from "../../constants/const";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import { encryptStorage } from "../../constants/EncryptStorage";
import { GetSelectedLanguage } from "../helper/Method";
import { ApiNotifications } from "../notification/ApiNotifications";
import SelectMemberUnit from "../member/SelectMemberUnit";
import Draggable from "react-draggable";
import imageCompression from "browser-image-compression";

const EditAddNewRafiqNotificationModal = ({
  setAddRafiqModal,
  notificationId,
  setupdateList,
  updateList,
}) => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [buttonDisable, setButtonDisable] = useState(false);
  const [errorFiles, setErrorFiles] = useState({
    baitFormFront: "",
    baitFormBack: "",
    kawaifForm: "",
  });
  const memberValues = {
    dateOfBirth: null,
    cnic: "",
    fatherName: "",
    fullName: "",
    joiningDate: null,
    referenceNote: "",
    documents: [],
    unit: {
      value: loggedInUnitId === 0 ? 1 : loggedInUnitId,
      label: "",
      unitTypeName: loggedInUnitId === 0 ? "Markaz" : "",
    },
  };

  const { t } = useTranslation();
  const toggle = () => setAddRafiqModal(false);
  const [loading, setLoading] = useState(false);
  const { errors, setErrors, values, handleInputChange, setValues } =
    useForm(memberValues);

  const setValuesRef = useRef();
  setValuesRef.current = setValues;
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  useEffect(() => {
    setLoading(true);
    ApiNotifications()
      .EditData(notificationId)
      .then((res) => {
        setValuesRef.current({
          ...res.data.addRafiq,
          dateOfBirth: res.data.addRafiq.dateOfBirth
            ? new Date(convertUTCToLocalTime(res.data.addRafiq.dateOfBirth))
            : null,
          joiningDate: res.data.addRafiq.joiningDate
            ? new Date(convertUTCToLocalTime(res.data.addRafiq.joiningDate))
            : null,
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        swal({
          title: "Error: " + err.response?.data || err,
          icon: "error",
          buttons: "OK",
        });
        setLoading(false);
      });
  }, [notificationId]);

  const [viewButtonDisable, setViewButtonDisable] = useState(
    Array(values.documents?.length).fill(false)
  );

  const validate = () => {
    let temp = {};
    temp.fullName =
      values.fullName !== "" ? (
        ""
      ) : (
        <GetLanguageString props="member_first_name_error_message" />
      );
    temp.fatherName =
      values.fatherName !== "" ? (
        ""
      ) : (
        <GetLanguageString props="member_father_name_error_message" />
      );
    temp.cnic =
      values.cnic === ""
        ? values.cnic.length === 13
          ? ""
          : "CNIC is not valid. It must be 13 numbers."
        : "";
    temp.dateOfBirth =
      values.dateOfBirth !== null ? (
        DateOfBirthValidater(values.birthDate) ? (
          ""
        ) : (
          <GetLanguageString props="member_birth_date_less_error_message" />
        )
      ) : (
        <GetLanguageString props="member_birth_date_error_message" />
      );
    temp.joiningDate =
      values.joiningDate !== null ? (
        JoiningDateValidater(values.joiningDate) ? (
          ""
        ) : (
          <GetLanguageString props="member_joining_date_range_error_message" />
        )
      ) : (
        <GetLanguageString props="member_joining_date_error_message" />
      );

    temp.unitError =
      values.unit.unitTypeName !== "Markaz" &&
      values.unit.unitTypeName !== "Zone" &&
      values.unit.unitTypeName !== "ZoneOS" ? (
        ""
      ) : (
        <GetLanguageString props="member_unit_check_error" />
      );

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputChangeCnic = (e) => {
    const { value } = e.target;
    setValues({
      ...values,
      cnic: value.replace(/-/g, ""),
    });

    setErrors({
      ...errors,
      cnic: "",
    });
  };

  const [valuesFiles, setValuesFiles] = useState({
    baitFormFront: "",
    baitFormBack: "",
    kawaifForm: "",
  });

  const openFile = (docType, index) => {
    setViewButtonDisable((prevStates) => {
      const newLoadingStates = [...prevStates];
      newLoadingStates[index] = true;
      return newLoadingStates;
    });
    ApiNotifications()
      .GetNotificationsDocs(notificationId, 0, docType)
      .then((res) => {
        const binaryData = atob(res.data.bytes);
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uintArray = new Uint8Array(arrayBuffer);

        for (let i = 0; i < binaryData.length; i++) {
          uintArray[i] = binaryData.charCodeAt(i);
        }

        const blob = new Blob([uintArray], { type: res.data.fileType });
        const url = URL.createObjectURL(blob);

        const newTab = window.open();
        newTab.document.open();
        newTab.document.write(`
        <html>
        <head>
          <style>
            body {
              margin: 0;
              overflow: hidden;
            }
            iframe {
              width: 100%;
              height: 100%;
              border: none;
              background-color: transparent;
            }
          </style>
        </head>
        <body>
          <iframe src="${url}"></iframe>
        </body>
      </html>
    `);
        newTab.document.close();

        URL.revokeObjectURL(url);

        setViewButtonDisable((prevStates) => {
          const newLoadingStates = [...prevStates];
          newLoadingStates[index] = false;
          return newLoadingStates;
        });
      })
      .catch((err) => {
        swal({
          title: "Error: " + err.response?.data || err,
          icon: "error",
          buttons: "OK",
        });
        setViewButtonDisable((prevStates) => {
          const newLoadingStates = [...prevStates];
          newLoadingStates[index] = false;
          return newLoadingStates;
        });
        console.log(err.response.data);
      });
  };

  const handleUpload = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.size < 10 * 1024 * 1024) {
      const options = {
        maxSizeMB: 10,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      let compressedFile;
      if (!selectedFile.type.includes("pdf")) {
        compressedFile = await imageCompression(selectedFile, options);
      }

      if (e.target.name === "baitFormFront") {
        setValuesFiles({
          ...valuesFiles,
          baitFormFront: compressedFile,
        });
        setErrorFiles({
          ...errorFiles,
          baitFormFront: "",
        });
      } else if (e.target.name === "baitFormBack") {
        setValuesFiles({
          ...valuesFiles,
          baitFormBack:
            compressedFile !== undefined ? compressedFile : selectedFile,
        });
        setErrorFiles({
          ...errorFiles,
          baitFormBack: "",
        });
      } else if (e.target.name === "kawaifForm") {
        setValuesFiles({
          ...valuesFiles,
          kawaifForm: compressedFile,
        });
        setErrorFiles({
          ...errorFiles,
          kawaifForm: "",
        });
      }
    } else {
      if (e.target.name === "baitFormFront") {
        setValuesFiles({
          ...valuesFiles,
          baitFormFront: null,
        });
        setErrorFiles({
          ...errorFiles,
          baitFormFront:
            selectedFile?.size > 10 * 1024 * 1024 ? (
              <GetLanguageString props="member_add_file_upload_size_error" />
            ) : (
              <GetLanguageString props="member_add_file_upload_error" />
            ),
        });
      } else if (e.target.name === "baitFormBack") {
        setValuesFiles({
          ...valuesFiles,
          baitFormBack: null,
        });
        setErrorFiles({
          ...errorFiles,
          baitFormBack:
            selectedFile?.size > 10 * 1024 * 1024 ? (
              <GetLanguageString props="member_add_file_upload_size_error" />
            ) : (
              <GetLanguageString props="member_add_file_upload_error" />
            ),
        });
      } else if (e.target.name === "kawaifForm") {
        setValuesFiles({
          ...valuesFiles,
          kawaifForm: null,
        });
        setErrorFiles({
          ...errorFiles,
          kawaifForm:
            selectedFile?.size > 10 * 1024 * 1024 ? (
              <GetLanguageString props="member_add_file_upload_size_error" />
            ) : (
              <GetLanguageString props="member_add_file_upload_error" />
            ),
        });
      }
    }
  };

  const handleInputChangeJoiningDate = (value) => {
    if (value !== null) {
      if (value <= new Date()) {
        setValues({
          ...values,
          joiningDate: value,
        });
        setErrors({
          ...errors,
          joiningDate: "",
        });
      } else {
        setErrors({
          joiningDate:
            "The Joining date must not be greater than current date.",
        });
      }
    } else {
      setValues({
        ...values,
        joiningDate: null,
      });
    }
  };

  const handleInputChangedateOfBirth = (value) => {
    if (value !== null) {
      if (value <= new Date()) {
        setValues({
          ...values,
          dateOfBirth: value,
        });
        setErrors({
          ...errors,
          dateOfBirth: "",
        });
      } else {
        setErrors({
          dateOfBirth: "The Birth date must not be greater than current date.",
        });
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const files = new FormData();
    files.append("BaitFormFront", valuesFiles.baitFormFront);
    files.append("BaitFormBack", valuesFiles.baitFormBack);
    files.append("KawaifForm", valuesFiles.kawaifForm);

    const performSubmit = () => {
      if (
        validate() &&
        Object.values(errorFiles).every((value) => value === "")
      ) {
        setButtonDisable(true);
        setLoading(true);
        ApiNotifications()
          .NotificationDocsSave(files)
          .then((res) => {
            values.docs = res.data;
            ApiNotifications()
              .EditAddRafiqNotificationRequest(notificationId, values)
              .then((res) => {
                swal({
                  title: t(res.data),
                  icon: "success",
                  buttons: "OK",
                  className: GetSelectedLanguage() === "en" ? "en" : "ur",
                });
                setLoading(false);
                setupdateList(!updateList);
                toggle();
              })
              .catch((err) => {
                swal({
                  title: "Error: " + err.response?.data || err,
                  icon: "error",
                  buttons: "OK",
                });
                setLoading(false);
                setButtonDisable(false);
                console.log(err);
              });
          })
          .catch((err) => {
            swal({
              title: err,
              icon: "error",
              buttons: "OK",
            });
            toggle();
            console.log(err);
          });
      }
    };

    performSubmit();
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal size="lg" isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="edit_add_rafiq_notification" />}
          </ModalHeader>
          <ModalBody>
            {!loading ? (
              <div className="ibox ">
                <div className="ibox-content">
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label className="col-form-label">
                          {<GetLanguageString props="member_first_name" />}
                        </Label>
                        <Input
                          className="ur no-drag"
                          autoFocus
                          type="text"
                          name="fullName"
                          value={values.fullName}
                          onChange={handleInputChange}
                          {...(errors.fullName && { invalid: true })}
                        />
                        {errors.fullName && (
                          <FormFeedback>
                            <h4>{errors.fullName}</h4>
                          </FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label className="col-form-label">
                          {<GetLanguageString props="member_father_name" />}
                        </Label>
                        <Input
                          className="ur no-drag"
                          type="text"
                          name="fatherName"
                          value={values.fatherName}
                          onChange={handleInputChange}
                          {...(errors.fatherName && { invalid: true })}
                        />
                        {errors.fatherName && (
                          <FormFeedback>
                            <h4>{errors.fatherName}</h4>
                          </FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label className="col-form-label">
                          {<GetLanguageString props="member_joining_date" />}
                        </Label>
                        <DatePicker
                          showYearDropdown
                          dropdownMode="select"
                          placeholderText="dd/mm/yyyy"
                          className="form-control no-drag"
                          selected={values.joiningDate}
                          onChange={handleInputChangeJoiningDate}
                          dateFormat="dd/MM/yyyy"
                        />
                        {errors.joiningDate && (
                          <h4 className="text-error">{errors.joiningDate}</h4>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label className="col-form-label">
                          {<GetLanguageString props="member_cnic" />}
                        </Label>

                        <InputMask
                          mask="99999-9999999-9"
                          value={values.cnic}
                          onChange={handleInputChangeCnic}
                          name="cnic"
                          maskChar=""
                        >
                          {(inputProps) => (
                            <Input
                              {...inputProps}
                              type="text"
                              className="no-drag"
                              placeholder="______-_______-_"
                              {...(errors.cnic && { invalid: true })}
                            />
                          )}
                        </InputMask>
                        {errors.cnic && (
                          <FormFeedback>
                            <h4>{errors.cnic}</h4>
                          </FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label className="col-form-label">
                          {<GetLanguageString props="member_date_of_birth" />}
                        </Label>
                        <DatePicker
                          showYearDropdown
                          dropdownMode="select"
                          placeholderText="dd/mm/yyyy"
                          className="form-control no-drag"
                          selected={values.dateOfBirth}
                          onChange={handleInputChangedateOfBirth}
                          dateFormat="dd/MM/yyyy"
                        />
                        {errors.dateOfBirth && (
                          <h4 className="text-error">{errors.dateOfBirth}</h4>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label className="col-form-label">
                          {<GetLanguageString props="member_reference_note" />}
                        </Label>
                        <Input
                          type="text"
                          name="referenceNote"
                          className="no-drag"
                          value={values.referenceNote}
                          onChange={handleInputChange}
                          {...(errors.referenceNote && { invalid: true })}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {CheckPermission("CanRequestAddMember") ? (
                    <>
                      <Row>
                        <Col md="8">
                          <Label className="col-form-label">
                            {
                              <GetLanguageString props="member_add_bait_form_front" />
                            }
                          </Label>
                          <Input
                            type="file"
                            accept="image/*"
                            name="baitFormFront"
                            onChange={handleUpload}
                            maxSize={10 * 1024 * 1024}
                          />
                          {errorFiles && (
                            <div className="text-error">
                              {errorFiles.baitFormFront}
                            </div>
                          )}
                        </Col>
                        <Col md="4" className="text-right">
                          {values?.documents?.map((item, index) => {
                            return item.fileName === "Bait Form Front" ? (
                              <div className="text-center">
                                <Label className="col-form-label">
                                  {
                                    <GetLanguageString props="member_add_rafiq_request_view_uploaded_document" />
                                  }
                                </Label>
                                <br></br>
                                <Button
                                  color="white"
                                  disabled={viewButtonDisable[index]}
                                  size="m"
                                  onClick={() => openFile(item.fileType, index)}
                                >
                                  {viewButtonDisable[index] ? (
                                    <Spinner size="sm" color="dark" />
                                  ) : (
                                    <>
                                      <i class="fa fa-eye"></i>{" "}
                                      <GetLanguageString props="member_add_rafiq_request_view_document" />
                                    </>
                                  )}
                                </Button>
                              </div>
                            ) : null;
                          })}
                        </Col>
                      </Row>
                      <Row>
                        <Col md="8">
                          <Label className="col-form-label">
                            {
                              <GetLanguageString props="member_add_bait_form_back" />
                            }
                          </Label>
                          <Input
                            type="file"
                            accept="image/*"
                            name="baitFormBack"
                            onChange={handleUpload}
                            maxSize={10 * 1024 * 1024}
                          />
                          {errorFiles && (
                            <div className="text-error">
                              {errorFiles.baitFormBack}
                            </div>
                          )}
                        </Col>{" "}
                        <Col md="4" className="text-right">
                          {values?.documents?.map((item, index) => {
                            return item.fileName === "Bait Form Back" ? (
                              <div className="text-center">
                                <Label className="col-form-label">
                                  {
                                    <GetLanguageString props="member_add_rafiq_request_view_uploaded_document" />
                                  }
                                </Label>
                                <br></br>
                                <Button
                                  color="white"
                                  disabled={viewButtonDisable[index]}
                                  size="m"
                                  onClick={() => openFile(item.fileType, index)}
                                >
                                  {viewButtonDisable[index] ? (
                                    <Spinner size="sm" color="dark" />
                                  ) : (
                                    <>
                                      <i class="fa fa-eye"></i>{" "}
                                      <GetLanguageString props="member_add_rafiq_request_view_document" />
                                    </>
                                  )}
                                </Button>
                              </div>
                            ) : null;
                          })}
                        </Col>
                      </Row>
                      <Row>
                        <Col md="8">
                          <Label className="col-form-label">
                            {
                              <GetLanguageString props="member_add_kawaif_form" />
                            }
                          </Label>
                          <Input
                            type="file"
                            accept="image/*,application/pdf"
                            name="kawaifForm"
                            onChange={handleUpload}
                            maxSize={10 * 1024 * 1024}
                          />
                          {errorFiles && (
                            <div className="text-error">
                              {errorFiles.kawaifForm}
                            </div>
                          )}
                        </Col>{" "}
                        <Col md="4" className="text-right">
                          {values?.documents?.map((item, index) => {
                            return item.fileName === "Kawaif Form" ? (
                              <div className="text-center">
                                <Label className="col-form-label">
                                  {
                                    <GetLanguageString props="member_add_rafiq_request_view_uploaded_document" />
                                  }
                                </Label>
                                <br></br>
                                <Button
                                  color="white"
                                  disabled={viewButtonDisable[index]}
                                  size="m"
                                  onClick={() => openFile(item.fileType, index)}
                                >
                                  {viewButtonDisable[index] ? (
                                    <Spinner size="sm" color="dark" />
                                  ) : (
                                    <>
                                      <i class="fa fa-eye"></i>{" "}
                                      <GetLanguageString props="member_add_rafiq_request_view_document" />
                                    </>
                                  )}
                                </Button>
                              </div>
                            ) : null;
                          })}
                        </Col>
                      </Row>
                      <br></br>
                    </>
                  ) : null}
                  <Row>
                    <SelectMemberUnit
                      values={values}
                      setValues={setValues}
                      unitId={values.unitId}
                    />
                    {errors.unitError && (
                      <h4 className="text-error">{errors.unitError}</h4>
                    )}
                  </Row>
                </div>
              </div>
            ) : (
              LoadingSpinner()
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="white" onClick={toggle}>
              {<GetLanguageString props="common_cancel" />}
            </Button>
            <Button
              color="primary"
              type="submit"
              disabled={buttonDisable}
              onClick={handleSubmit}
            >
              {<GetLanguageString props="common_save_changes" />}
            </Button>
          </ModalFooter>
        </Modal>
      </Draggable>
    </div>
  );
};

export default EditAddNewRafiqNotificationModal;
