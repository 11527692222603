import React, { useState } from "react";
import {
  Form,
  FormGroup,
  Input,
  Button,
  FormFeedback,
  Row,
  Col,
} from "reactstrap";

import useForm from "../shared/useForm";
import { requestAccountApi } from "../../actions/api";
import { LoadingSpinner } from "../../constants/const";
import SetCode from "./SetCode";
import { GetSelectedLanguage } from "../helper/Method";
import { GetLanguageString } from "../helper/Components";

const intialFieldValues = {
  regNo: "",
  code: "",
  password: "",
  isVerified: false,
  isPassword: true,
};

const ForgotPassword = ({ setForgotPassword, forgotPassword }) => {
  const selected = GetSelectedLanguage();
  const [loading, setLoading] = useState(false);
  const [loadCode, setLoadCode] = useState(false);

  const {
    errors,
    setErrors,
    serverError,
    setServerError,
    values,
    setValues,
    handleInputChange,
  } = useForm(intialFieldValues);

  const validate = () => {
    let temp = {};
    temp.regNo = values.regNo
      ? ""
      : selected === "en"
      ? "The Registration Number field is required."
      : selected === "ur"
      ? " رجسٹریشن نمبر لازم ہے "
      : null;
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      requestAccountApi()
        .forgotPassword(values)
        .then((res) => {
          setLoading(false);
          setLoadCode(true);
        })
        .catch((err) => {
          setLoading(false);
          setServerError(err.response.data.errors[0]);
        });
    }
  };

  return (
    <div>
      {!loading ? (
        <>
          <div className="passwordBox">
            {serverError && (
              <h4 className="text-danger text-center">{serverError}</h4>
            )}
            <Row>
              <Col md="12">
                <div className="ibox-content">
                  <h2 className="font-bold">
                    <GetLanguageString props="login_forgot_password" />
                  </h2>
                  {loadCode ? (
                    <SetCode
                      signUp={forgotPassword}
                      setSignUp={setForgotPassword}
                      signUpValues={values}
                      setSignUpValues={setValues}
                    />
                  ) : (
                    <>
                      <p>
                        <GetLanguageString props="login_forgot_password_text" />
                      </p>
                      <Row>
                        <Col lg="12">
                          <Form onSubmit={handleSubmit} className="m-t">
                            <FormGroup>
                              <Input
                                autoFocus
                                className={selected === "ur" ? "ur" : "en"}
                                type="text"
                                name="regNo"
                                value={values.regNo}
                                onChange={handleInputChange}
                                placeholder={
                                  selected === "en"
                                    ? "Registration Number"
                                    : selected === "ur"
                                    ? "رجسٹریشن نمبر"
                                    : null
                                }
                                {...(errors.regNo && { invalid: true })}
                              />
                              {errors.regNo && (
                                <FormFeedback>
                                  <h4 className={selected === "ur" ? "ur" : "en"}>
                                    {errors.regNo}
                                  </h4>
                                </FormFeedback>
                              )}
                            </FormGroup>
                            <Button
                              color="primary"
                              type="submit"
                              className="block full-width m-b"
                            >
                              <GetLanguageString props="login_send_code" />
                            </Button>
                          </Form>
                        </Col>
                      </Row>
                    </>
                  )}
                </div>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col mg="12">
                <p className="text-muted text-center">
                  <GetLanguageString props="login_forgot_password_go_back_to_login" />
                </p>
                <Button
                  size="sm"
                  color="white"
                  className="full-width"
                  onClick={() => setForgotPassword(!forgotPassword)}
                >
                  <GetLanguageString props="login" />
                </Button>
              </Col>
            </Row>
          </div>
        </>
      ) : (
        LoadingSpinner()
      )}
    </div>
  );
};

export default ForgotPassword;
