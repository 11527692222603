import React, { useState } from "react";
import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { LoadingSpinner } from "../../../../../constants/const";
import { GetLanguageString } from "../../../../helper/Components";
import { GetCustomStyles } from "../../../../helper/Method";
import { ApiUnit } from "../../../ApiUnit";
import SelectMonth from "../SelectMonth";
import Activities from "./Activities";
import Counts from "./Counts";
import Events from "./Events";
import Others from "./Others";
import { ProgressBar, Step } from "react-step-progress-bar";
import { saveAs } from "file-saver";
import moment from "moment";
import { encryptStorage } from "../../../../../constants/EncryptStorage";
import swal from "sweetalert";
import { FcCheckmark } from "react-icons/fc";

const CreateReports = ({ history }) => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    unitId: loggedInUnitId,
    month: null,
    year: null,
    date: null,
  });
  const [data, setData] = useState(null);
  const [reportData, setReportData] = useState();
  const [steps, setSteps] = useState(0);
  const [unitInformation, setUnitInformation] = useState({});
  const [countingSection, setCountingSection] = useState({});
  const [usraIjtimaSection, setUsraIjtimaSection] = useState({});
  const [eventsSection, setEventsSection] = useState({});
  const [othersSection, setOtherSection] = useState({});
  const sections = ["اعداد و شمار", "سرگرمیاں", "دیگر اجتماعات", "دیگر"];

  useEffect(() => {
    setLoading(true);
    ApiUnit()
      .createMonthlyReport(values)
      .then((res) => {
        if (res.data.errorMessageReport !== null) {
          swal({
            title: "Report",
            text: res.data.errorMessageReport.errorMessage,
            icon: "warning",
          });
        } else if (res.data.usraMonthlyReportModel !== null) {
          setData(res.data.usraMonthlyReportModel);
          setUnitInformation(res.data.usraMonthlyReportModel.unitInformation);
          setCountingSection(res.data.usraMonthlyReportModel.countingSection);
          setUsraIjtimaSection(
            res.data.usraMonthlyReportModel.usraIjtimaSection
          );
          setEventsSection(res.data.usraMonthlyReportModel.eventsSection);
          setOtherSection(res.data.usraMonthlyReportModel.othersSection);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  }, [values]);

  useEffect(() => {
    setReportData({
      ...reportData,
      unitId: values.unitId,
      month: values.month,
      year: values.year,
      unitInformation: unitInformation,
      countingSection: countingSection,
      usraIjtimaSection: usraIjtimaSection,
      eventsSection: eventsSection,
      othersSection: othersSection,
    });
  }, [
    countingSection,
    usraIjtimaSection,
    eventsSection,
    othersSection,
    reportData,
    setReportData,
    values,
    unitInformation,
  ]);
  const handleSubmit = () => {
    ApiUnit()
      .getUnitMonthlyReportExcel(reportData)
      .then((res) => {
        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(
          blob,
          "UsraMonthlyReport _ " +
            moment()
              .month(values.month - 1)
              .format("MMMM") +
            ".xlsx"
        );
      })
      .catch((err) => console.log(err));
  };

  var stepPercentage = 0;
  if (steps === 0) {
    stepPercentage = 16;
  } else if (steps === 1) {
    stepPercentage = 49.5;
  } else if (steps === 2) {
    stepPercentage = 82.5;
  } else if (steps === 3) {
    stepPercentage = 100;
  } else {
    stepPercentage = 0;
  }

  return (
    <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
      <div className="ibox">
        <div className="ibox-title">
          <h5>Select Month</h5>
          <Button
            color="primary"
            size="sm"
            className={GetCustomStyles().btn_style}
            onClick={() => history.goBack()}
          >
            {<GetLanguageString props="courses_participants_back" />}
          </Button>
        </div>
        <div className="ibox-content">
          <SelectMonth searchUnit={values} setSearchUnit={setValues} />
        </div>
      </div>
      {data !== null ? (
        <div className="ibox panel panel-primary">
          <div className="panel-heading ur">
            <Row>
              <Col md="9">
                <div>
                  <h1 className="m">ماہانہ جائزہ اسرہ</h1>
                  <h4 className="m">{unitInformation.unitName}</h4>
                </div>
              </Col>

              <Col md="3">
                <Row md="3">
                  <td className="text-center">
                    <h4>{<GetLanguageString props="Naqeeb" />} : </h4>
                  </td>
                  <td>
                    <h3>{unitInformation.naqeebName}</h3>
                  </td>
                </Row>
                <Row md="3">
                  <td className="text-center">
                    <h4>{<GetLanguageString props="Tanzeem" />} :</h4>
                  </td>
                  <td>
                    <h3>{unitInformation.tanzeemName}</h3>
                  </td>
                </Row>
                <Row md="3">
                  <td className="text-center">
                    <h4>{<GetLanguageString props="Halqa" />} :</h4>
                  </td>
                  <td>
                    <h3>{unitInformation.halqaName}</h3>
                  </td>
                </Row>
              </Col>
            </Row>
          </div>
          <div className="panel-body">
            <div className="m ibox-content">
              <div className="progressBarCustom">
                <Row>
                  <ProgressBar percent={stepPercentage}>
                    {sections.map((item, index) => {
                      return (
                        <Step key={index}>
                          {({ accomplished, index }) => (
                            <>
                              <div className="progressBarText ur">
                                <h2>{item}</h2>
                              </div>
                              <div
                                className={`indexedStep ${
                                  accomplished ? "accomplished" : null
                                }`}
                                onClick={() => setSteps(index)}
                              >
                                {steps > index ? (
                                  <FcCheckmark
                                    size={18}
                                    color="green"
                                    style={{ marginRight: 3, marginBottom: 1 }}
                                  />
                                ) : null}
                              </div>
                            </>
                          )}
                        </Step>
                      );
                    })}
                  </ProgressBar>
                </Row>
              </div>
              {!loading ? (
                <div>
                  {steps === 0 ? (
                    <Counts
                      steps={steps}
                      setSteps={setSteps}
                      countingSection={countingSection}
                      setCountingSection={setCountingSection}
                    />
                  ) : steps === 1 ? (
                    <Activities
                      steps={steps}
                      setSteps={setSteps}
                      usraIjtimaSection={usraIjtimaSection}
                      setUsraIjtimaSection={setUsraIjtimaSection}
                    />
                  ) : steps === 2 ? (
                    <Events
                      steps={steps}
                      setSteps={setSteps}
                      eventsSection={eventsSection}
                      setEventsSection={setEventsSection}
                    />
                  ) : steps === 3 ? (
                    <Others
                      steps={steps}
                      setSteps={setSteps}
                      othersSection={othersSection}
                      setOtherSection={setOtherSection}
                    />
                  ) : null}
                </div>
              ) : (
                LoadingSpinner()
              )}
              <div>
                <Row>
                  <Col md="1">
                    <Button
                      block
                      color="primary"
                      type="submit"
                      disabled={steps === 0}
                      onClick={() => setSteps(steps - 1)}
                    >
                      {<GetLanguageString props="Previous" />}
                    </Button>
                  </Col>
                  <Col md="10">
                    <div></div>
                  </Col>
                  <Col md="1">
                    <Button
                      block
                      className={GetCustomStyles().btn_style}
                      color="primary"
                      type="submit"
                      onClick={() =>
                        steps < 3 ? setSteps(steps + 1) : handleSubmit()
                      }
                    >
                      {steps < 3 ? (
                        <GetLanguageString props="Next" />
                      ) : (
                        <GetLanguageString props="Submit" />
                      )}
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default withRouter(CreateReports);
