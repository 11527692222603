import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { GetLanguageString } from "../../helper/Components";
import moment from "moment";
import { convertUTCToLocalTime, isUrdu } from "../../../constants/const";
import { GetSelectedLanguage } from "../../helper/Method";

const MemberUnit = ({ tab2, memberUnitList }) => {
  const [unitNames, setUnitNames] = useState([]);
  useEffect(() => {
    let tempNames = [];

    memberUnitList.forEach((element) => {
      tempNames.push({
        names: element.unitName.split("،"),
      });
    });

    setUnitNames(tempNames);
  }, [memberUnitList]);

  return (
    <div role="tabpanel" id="tab-2" className={"tab-pane " + tab2}>
      <div className="panel-body">
        <div className="table-responsive">
          <Table responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>
                  {
                    <GetLanguageString props="member_member_detail_common_unit_name" />
                  }
                </th>
                <th>
                  {
                    <GetLanguageString props="member_member_detail_common_unit_date" />
                  }
                </th>
                <th>{<GetLanguageString props="common_description" />}</th>
                <th>{<GetLanguageString props="member_reference_note" />}</th>
              </tr>
            </thead>
            <tbody>
              {memberUnitList.map((record, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>{" "}
                    <td className="ur">
                      <ol
                        className="breadcrumb"
                      >
                        {unitNames[index]?.names?.map((item, index) => {
                          return (
                            <li
                              className={
                                GetSelectedLanguage() === "ur"
                                  ? "breadcrumb-item-ur"
                                  : "breadcrumb-item"
                              }
                              key={index}
                            >
                              {item}
                            </li>
                          );
                        })}
                      </ol>
                    </td>
                    <td>
                      {record.unitDate
                        ? moment(convertUTCToLocalTime(record.unitDate)).format(
                            "DD/MM/yyyy"
                          )
                        : "-"}
                    </td>
                    <td className={isUrdu(record.description) ? "ur" : ""}>
                      {record.description}
                    </td>
                    <td className={isUrdu(record.referenceNote) ? "ur" : ""}>
                      {record.referenceNote}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default MemberUnit;
