import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { Row, Col, FormGroup, Label, Input, Button } from "reactstrap";

import { requestApi } from "../../../actions/api";
import { GetLanguageString } from "../../helper/Components";
import { DateValidater, GetSelectedLanguage } from "../../helper/Method";
import CourseSelectUnit from "../CourseSelectUnit";
import { ApiCourse } from "../ApiCourse";
import { isUrdu } from "../../../constants/const";

const SearchCourseReport = ({
  searchCourseModel,
  setSearchCourseModel,
  unitId,
  setSearchButton,
}) => {
  const [courseType, setCourseType] = useState([]);
  const [values, setValues] = useState(searchCourseModel);
  const [showDeletedCheckBox, setShowDeletedCheckBox] = useState(false);
  const [searchByLocation, setSearchByLocation] = useState(true);
  const [searchByAddress, setSearchByAddress] = useState(false);
  const [excludeExUnit, setExcludeExUnit] = useState(true);
  const [mainUnitId, setMainUnitId] = useState();
  const dateRangeValidation = {
    startDate: null,
    endDate: null,
  };
  const [dateValidations, setDateValidations] = useState(dateRangeValidation);
  const [courseLocation, setCourseLocation] = useState([]);
  const courseOptions = [];

  useEffect(() => {
    requestApi("coursetype/")
      .fetchAll()
      .then((res) => {
        setCourseType(res.data);
      })
      .catch((err) => console.log(err));

    ApiCourse()
      .getCoursesLocations()
      .then((res) => {
        setCourseLocation(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (values.unit.value === 0) setMainUnitId(unitId);
    else if (unitId !== values.unit.value) setMainUnitId(values.unit.value);
    else setMainUnitId(unitId);
  }, [unitId, values.unit.value]);

  courseType.forEach((d) => courseOptions.push({ value: d.id, label: d.name }));

  const handleChangeCourseType = (record) => {
    if (record !== null) {
      let ids = [];
      record.forEach((r) => ids.push(r.value));
      setValues({
        ...values,
        courseTypeIds: ids,
      });
    } else {
      setValues({
        ...values,
        courseTypeIds: 0,
      });
    }
  };

  const handleChangeCourseLocation = (record) => {
    setValues({
      ...values,
      courseLocation: record,
    });
  };

  const handleInputChangeStartDate = (value) => {
    let date = value;
    const hours = new Date(value).getHours();

    if (hours === 0) {
      date = new Date(value.setHours(value.getHours() + 5));
    }

    if (DateValidater(date, values.endDate)) {
      setValues({
        ...values,
        startDate: date,
      });
      setDateValidations(dateRangeValidation);
    } else {
      setDateValidations({
        ...dateValidations,
        startDate: (
          <GetLanguageString props="common_start_date_validation_message" />
        ),
      });
    }
  };

  const handleInputChangeEndDate = (value) => {
    let date = value;
    const hours = new Date(value).getHours();

    if (hours === 0) {
      date = new Date(value.setHours(value.getHours() + 5));
    }
    
    if (DateValidater(values.startDate, date)) {
      setValues({
        ...values,
        endDate: date,
      });
      setDateValidations(dateRangeValidation);
    } else {
      setDateValidations({
        ...dateValidations,
        endDate: (
          <GetLanguageString props="common_end_date_validation_message" />
        ),
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubUnits = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setShowDeletedCheckBox(v);
    if (!v) {
      setValues({
        ...values,
        isDeletedUnits: v,
        isSubUnits: v,
      });
    } else {
      setValues({
        ...values,
        isSubUnits: v,
      });
    }
  };

  const handleDeletedUnits = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setValues({
      ...values,
      isDeletedUnits: v,
    });
  };

  const handleSubmit = () => {
    if (values.unit.value === 0) values.unit = { value: unitId, label: "" };
    setSearchButton(true);
    setSearchCourseModel(values);
  };

  const handleExcludeExUnit = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setExcludeExUnit(v);
  };

  return (
    <>
      <Row>
        <Col lg="4">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="courses_start_date" />}
            </Label>
            <DatePicker
              showYearDropdown
              placeholderText="dd/mm/yyyy"
              className={
                GetSelectedLanguage() === "en"
                  ? "form-control"
                  : "form-control ur"
              }
              selected={values.startDate}
              onChange={handleInputChangeStartDate}
              dateFormat="dd/MM/yyyy"
            />
            {dateValidations.startDate && (
              <h4 className="text-error">{dateValidations.startDate}</h4>
            )}
          </FormGroup>
        </Col>
        <Col lg="4">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="courses_end_date" />}
            </Label>
            <DatePicker
              showYearDropdown
              placeholderText="dd/mm/yyyy"
              className={
                GetSelectedLanguage() === "en"
                  ? "form-control"
                  : "form-control ur"
              }
              selected={values.endDate}
              onChange={handleInputChangeEndDate}
              dateFormat="dd/MM/yyyy"
            />
            {dateValidations.endDate && (
              <h4 className="text-error">{dateValidations.endDate}</h4>
            )}
          </FormGroup>
        </Col>
        <Col lg="4">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="courses_course_type" />}
            </Label>
            <Select
              options={courseOptions}
              className="basic-multi-select ur"
              placeholder={
                GetSelectedLanguage() === "en" ? "Select" : "منتخب کریں"
              }
              isMulti
              isClearable
              onChange={handleChangeCourseType}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label for="name">
              <Input
                id="location"
                type="radio"
                checked={searchByLocation}
                onChange={() => {
                  setSearchByLocation(true);
                  setSearchByAddress(false);
                  setValues({
                    ...values,
                    address: "",
                  });
                }}
              />{" "}
              {<GetLanguageString props="courses_course_location" />}
            </Label>
            <Select
              options={courseLocation}
              className="basic-single ur"
              isDisabled={searchByAddress}
              isClearable={true}
              value={values.courseLocation}
              placeholder={
                GetSelectedLanguage() === "en" ? "Select" : "منتخب کریں"
              }
              onChange={handleChangeCourseLocation}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Input
              id="address"
              type="radio"
              checked={searchByAddress}
              onChange={() => {
                setSearchByLocation(false);
                setSearchByAddress(true);
                setValues({
                  ...values,
                  courseLocation: null,
                });
              }}
            />{" "}
            <Label for="regNo">
              {<GetLanguageString props="courses_address" />}
            </Label>
            <Input
              className={isUrdu(values.address) ? "ur" : ""}
              type="text"
              disabled={searchByLocation}
              name="address"
              value={values.address}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <CourseSelectUnit
        unitId={mainUnitId}
        setValues={setValues}
        values={values}
        isDeleted={values.isDeletedUnits}
        excludeExUnit={excludeExUnit}
      />
      <Row>
        <Col md="2">
          <FormGroup check>
            <Label check for="excludeExUnit">
              <Input
                id="excludeExUnit"
                type="checkbox"
                name="excludeExUnit"
                checked={!excludeExUnit}
                value={!excludeExUnit}
                onChange={handleExcludeExUnit}
              />
              {<GetLanguageString props="member_search_member_ex_unit" />}
            </Label>
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup check>
            <Label check for="subUnit">
              <Input
                id="subUnit"
                type="checkbox"
                name="isSubUnits"
                checked={values.isSubUnits}
                value={!values.isSubUnits}
                onChange={handleSubUnits}
              />
              {<GetLanguageString props="member_search_member_sub_unit" />}
            </Label>
          </FormGroup>
        </Col>
        {showDeletedCheckBox ? (
          <Col md="2">
            <FormGroup check>
              <Label check for="deleteUnit">
                <Input
                  id="deleteUnit"
                  type="checkbox"
                  name="isDeletedUnits"
                  value={!values.isDeletedUnits}
                  onChange={handleDeletedUnits}
                />
                Include deleted sub units
              </Label>
            </FormGroup>
          </Col>
        ) : null}
      </Row>
      <Row>
        <Col className="text-center">
          <FormGroup>
            <Button
              color="primary"
              className="m-l-sm"
              type="submit"
              onClick={handleSubmit}
            >
              {<GetLanguageString props="common_view_report" />}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default SearchCourseReport;
