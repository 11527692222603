import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";
import { withRouter, useLocation } from "react-router-dom";

import { requestApi } from "../../actions/api";
import { GetLanguageString } from "../helper/Components";
import SelectUnit from "../shared/SelectUnit";
import { LoadingSpinner } from "../../constants/const";
import { GetSelectedLanguage } from "../helper/Method";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";

const EditEvent = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const editValue = location.state.editValue;
  const [eventValues, setEventValues] = useState({
    date: null,
    endDate: null,
    address: "",
    description: "",
    remarks: "",
    unit: {},
    canSubunitMarkAttendance: false,
    updateAllEvent: false,
  });
  const [errors, setErrors] = useState(eventValues);

  const { t } = useTranslation();
  useEffect(() => {
    setLoading(true);
    requestApi("event/")
      .fetchById(editValue)
      .then((res) => {
        setEventValues({
          ...res.data,
          date: res.data.date ? new Date(res.data.date) : null,
          endDate: res.data.endDate ? new Date(res.data.endDate) : null,
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  }, [editValue]);

  const validate = () => {
    let temp = {};
    temp.address = eventValues.address ? (
      ""
    ) : (
      <GetLanguageString props="events_address_error_message" />
    );
    const currentDate = new Date();
  const minDate = new Date(2020, 0, 1);
  const maxDate = new Date(currentDate.getFullYear() + 2, 11, 31);

  temp.date =
    eventValues.date !== null ? (
      eventValues.date >= minDate && eventValues.date <= maxDate ? (
        ""
      ) : (
        <GetLanguageString props="events_date_range_error_message" />
      )
    ) : (
      <GetLanguageString props="events_date_error_message" />
    );

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputChangeDate = (value) => {
    setEventValues({
      ...eventValues,
      date: value,
    });
    setErrors({
      date: "",
    });
  };

  const handleInputChangeEndDate = (value) => {
    setEventValues({
      ...eventValues,
      endDate: value,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEventValues({
      ...eventValues,
      [name]: value,
    });
    setErrors({
      [name]: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      if(eventValues.isRecurring){
        swal({
          title: t("events_recurring_title_alert"),
          text: t("events_attendance_update_text_alert"),
          className: GetSelectedLanguage() === "en" ? "" : "ur",
          icon: "success",
          buttons: {
            cancel: {
              text: t("events_cancel_button"), 
              value: false,
              visible: true,
              className: "",
              closeModal: true
            },
            updateOne: {
              text: t("events_update_this_button"), 
              value: "updateOne",
              visible: true,
              className: "",
              closeModal: true
            },
            updateAll: {
              text: t("events_update_all_button"), 
              value: "updateAll",
              visible: true,
              className: "",
              closeModal: true
            },
          }
        }).then((value) => {
          if (value) {
            eventValues.updateAllEvent = value === "updateAll";
            simpleEventUpdate();
          }
        });
      } else {
        simpleEventUpdate();
      }
    }
  };

  function adjustDateForTimeZone(eventValues) {
    eventValues.date = new Date(
      eventValues.date.setHours(eventValues.date.getHours() + 5)
    );
    if(eventValues.endDate !== null)
      eventValues.endDate = new Date(
        eventValues.endDate.setHours(eventValues.endDate.getHours() + 5)
      );
  }

  function simpleEventUpdate() {
    setLoading(true);
    adjustDateForTimeZone(eventValues);
    requestApi("event/")
      .update(editValue, eventValues)
      .then((res) => {
        swal({
          title: t("events_edit_event_success"),
          className: GetSelectedLanguage() === "en" ? "" : "ur",
          icon: "success",
          buttons: t("common_ok_button"),
        });
        history.goBack();
        setLoading(false);
      })
      .catch((err) => {
        console.error("Update failed", err);
        setLoading(false);
      });
  }

  const rTl = () => {
    if (GetSelectedLanguage() === "ur") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
      {!loading ? (
        <Form onSubmit={handleSubmit}>
          <div className="ibox">
            <div className="ibox-title">
              <h3>{<GetLanguageString props="courses_select_unit" />}</h3>
            </div>
            <div className="ibox-content">
              {
                <SelectUnit
                  setValues={setEventValues}
                  eventValues={eventValues}
                  unitId={eventValues.unit.value}
                  excludeExUnit={true}
                  isDisabled={true}
                />
              }
            </div>
          </div>
          <div className="ibox ">
            <div className="ibox-title">
              <h3>{<GetLanguageString props="events_edit_event" />}</h3>
            </div>
            <div className="ibox-content">
              <Row>
                <Col lg="4">
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="events_address" />}
                    </Label>
                    <Input
                      className="ur"
                      type="text"
                      name="address"
                      value={eventValues.address}
                      onChange={handleInputChange}
                    />
                    {errors.address && (
                      <FormFeedback>
                        <h4>{errors.address}</h4>
                      </FormFeedback>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="events_date" />}
                    </Label>
                    <DatePicker
                      showYearDropdown
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      timeCaption="Time"
                      placeholderText="dd/mm/yyyy"
                      className="form-control"
                      selected={eventValues.date}
                      onChange={handleInputChangeDate}
                      dateFormat="MMMM d, yyyy h:mm aa"
                    />
                    {errors.date && (
                      <h4 className="text-error">{errors.date}</h4>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="events_event_type" />}
                    </Label>
                    <Select
                      value={eventValues.eventType}
                      className="basic-single ur"
                      isDisabled
                      isClearable={true}
                      placeholder={
                        GetSelectedLanguage() === "en" ? "Select" : "منتخب کریں"
                      }
                    />
                    {errors.eventType === "error" ? (
                      <h4 className="text-error">
                        {
                          <GetLanguageString props="events_event_type_error_message" />
                        }
                      </h4>
                    ) : null}
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="events_end_date" />}
                    </Label>
                    <DatePicker
                      showYearDropdown
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      timeCaption="Time"
                      placeholderText="dd/mm/yyyy"
                      className="form-control"
                      selected={eventValues.endDate}
                      onChange={handleInputChangeEndDate}
                      dateFormat="MMMM d, yyyy h:mm aa"
                    />
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="common_description" />}
                    </Label>
                    <Input
                      className="ur"
                      type="text"
                      name="description"
                      value={eventValues.description}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="common_remarks" />}
                    </Label>
                    <Input
                      className="ur"
                      type="text"
                      name="remarks"
                      value={eventValues.remarks}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <FormGroup check>
                    <Label
                      className={
                        GetSelectedLanguage === "ur"
                          ? "ur col-form-label"
                          : "col-form-label"
                      }
                      check
                      for="isRecurring"
                    >
                      <span
                        style={{
                          float: rTl() ? "right" : "left",
                          marginLeft: rTl() ? "24px" : "0px",
                        }}
                      >
                        <Input
                          id="isRecurring"
                          type="checkbox"
                          name="isRecurring"
                          disabled
                          checked={eventValues.isRecurring}
                        />
                      </span>
                      <span>
                        {
                          <GetLanguageString props="events_is_recurring_period" />
                        }
                      </span>
                    </Label>
                  </FormGroup>
                </Col>
                {eventValues?.eventType?.isSelected ? (
                  <Col md="4">
                    <FormGroup check>
                      {/* Allow Sub Units*/}
                      <Label
                        className={
                          GetSelectedLanguage === "ur"
                            ? "ur col-form-label"
                            : "col-form-label"
                        }
                        check
                        for="canSubunitMarkAttendance"
                      >
                        <span
                          style={{
                            float: rTl() ? "right" : "left",
                            marginLeft: rTl() ? "24px" : "0px",
                          }}
                        >
                          <Input
                            id="canSubunitMarkAttendance"
                            type="checkbox"
                            name="canSubunitMarkAttendance"
                            value={!eventValues.canSubunitMarkAttendance}
                            onChange={handleInputChange}
                            checked={eventValues.canSubunitMarkAttendance}
                          />
                        </span>
                        <span>
                          {
                            <GetLanguageString props="settings_event_type_allow_sub_units" />
                          }
                        </span>
                      </Label>
                    </FormGroup>
                  </Col>
                ) : null}
              </Row>
              <Row className="m-t-lg">
                <Col md="4">
                  <Button color="white" onClick={() => history.goBack()}>
                    {<GetLanguageString props="common_cancel" />}
                  </Button>
                  <Button color="primary" className="m-l-sm" type="submit">
                    {<GetLanguageString props="common_save_changes" />}
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      ) : (
        LoadingSpinner()
      )}
    </div>
  );
};

export default withRouter(EditEvent);
