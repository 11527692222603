import React, { useState } from "react";
import { useEffect } from "react";
import { encryptStorage } from "../../constants/EncryptStorage";
import { Col, FormGroup, Label, NavLink, Row, Input } from "reactstrap";
import { ApiUnit } from "../unit/ApiUnit";
import Select from "react-select";
import { GetLanguageString } from "../helper/Components";
import ManagePermissions from "./ManagePermissions";

const SelectPermissions = ({
  setValues,
  values,
  unitId,
  id,
  index,
  setModifyMemberType,
  setRequestModifyMemberType,
  setModifyMember,
  setRequestModifyMember,
  errors,
  addRafiq,
  setAddRafiq,
  requestAddRafiq,
  setRequestAddRafiq,
  cancelRafiq,
  setCancelRafiq,
  requestCancelRafiq,
  setRequestCancelRafiq,
  setRequestMarkAsEx,
  setMarkAsEx,
  setModifyResponsibility,
  setRequestResponsibilityModification,
  loading,
  setLoading
}) => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [unitList, setUnitList] = useState([]);
  const [excludeExUnit, setExcludeExUnit] = useState(true);
  const [tab1, setTab1] = useState("active");
  const [tab2, setTab2] = useState("");
  const unitTypeName = "null";

  useEffect(() => {
    ApiUnit()
      .getUnitList(unitId, loggedInUnitId, false, unitTypeName, excludeExUnit)
      .then((res) => {
        setUnitList(res.data);
      })
      .catch((err) => console.log(err));
  }, [unitId, loggedInUnitId, excludeExUnit]);

  const handleChangeUnits = (record) => {
    if (record !== null) {
      ApiUnit()
        .getUnitList(record.value, loggedInUnitId, false, unitTypeName, excludeExUnit)
        .then((res) => {
          setUnitList(res.data);
        })
        .catch((err) => console.log(err));

      let tempValues = [...values];
      tempValues[id].value = record.value;
      tempValues[id].label = record.label;
      setValues(tempValues);
    }
  };

  const removeObject = () => {
    let tempValues = [...values];
    tempValues.splice(id, 1)
    setValues(tempValues);
  };

  const handleExcludeExUnit = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setExcludeExUnit(v);
  };

  return (
    <>
      <Row>
        <div className="col-md-12">
          <div className="tabs-container">
            {id >= 1 ? (
              <div>
                <i
                  className={"fa fa-2x fa-minus-square m-b-xs m-l-xs"}
                  style={{ cursor: "pointer", color: "red" }}
                  onClick={removeObject}
                />
              </div>
            ) : null}
            <ul className="nav nav-tabs" role="tablist">
              <li>
                <NavLink
                  to="#"
                  className={"nav-link " + tab1}
                  onClick={() => {
                    setTab1("active");
                    setTab2("");
                  }}
                >
                  {<GetLanguageString props="member_select_unit" />}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="#"
                  className={"nav-link " + tab2}
                  onClick={() => {
                    setTab1("");
                    setTab2("active");
                  }}
                >
                  {<GetLanguageString props="member_member_detail_access_rights_select_permissions" />}
                </NavLink>
              </li>
            </ul>
            <div className="tab-content">
              <div role="tabpanel" id="tab-1" className={"tab-pane " + tab1}>
                <div className="panel-body">
                  <Row>
                    {unitList.map((record, index) => {
                      const unitOptions = [];
                      record.unitList.forEach((d) =>
                        unitOptions.push({
                          value: d.id,
                          label: d.name,
                        })
                      );
                      let selected = {};
                      let unitName = "";
                      if (record.parentUnitName !== null)
                        unitName = record.parentUnitName;
                      if (record.selectedUnit !== null) {
                        selected = {
                          value: record.selectedUnit.id,
                          label: record.selectedUnit.name,
                        };
                      }

                      return (
                        <Col md="2" key={index}>
                          <FormGroup>
                            <Label className="col-form-label">
                              {unitName !== "" ? (
                                <GetLanguageString
                                  props="member_select_unit_name"
                                  value={unitName}
                                />
                              ) : (
                                <GetLanguageString props="member_select_unit" />
                              )}
                            </Label>
                            <Select
                              options={unitOptions}
                              value={selected}
                              className="basic-single ur"
                              classNamePrefix="select"
                              onChange={handleChangeUnits}
                            />
                          </FormGroup>
                        </Col>
                      );
                    })}
                  </Row>
                  <Row>
                    <Col md="4">
                      <FormGroup check>
                        <Label check for="excludeExUnit">
                          <Input
                            id="excludeExUnit"
                            type="checkbox"
                            name="excludeExUnit"
                            checked={!excludeExUnit}
                            value={!excludeExUnit}
                            onChange={handleExcludeExUnit}
                          />
                          {<GetLanguageString props="member_search_member_ex_unit" />}
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </div>
              <div role="tabpanel" id="tab-2" className={"tab-pane " + tab2}>
                <div
                  className="panel-body"
                  style={{ backgroundColor: "#f9f9f9" }}
                >
                  <ManagePermissions
                    id={id}
                    setModifyMemberType={setModifyMemberType}
                    setRequestModifyMemberType={setRequestModifyMemberType}
                    setModifyMember={setModifyMember}
                    setRequestModifyMember={setRequestModifyMember}
                    errors={errors}
                    addRafiq={addRafiq}
                    setAddRafiq={setAddRafiq}
                    cancelRafiq={cancelRafiq}
                    requestCancelRafiq={requestCancelRafiq}
                    requestAddRafiq={requestAddRafiq}
                    setRequestAddRafiq={setRequestAddRafiq}
                    setCancelRafiq={setCancelRafiq}
                    setRequestCancelRafiq={setRequestCancelRafiq}
                    setRequestMarkAsEx={setRequestMarkAsEx}
                    setMarkAsEx={setMarkAsEx}
                    setModifyResponsibility={setModifyResponsibility}
                    setRequestResponsibilityModification={setRequestResponsibilityModification}
                    values={values}
                    setValues={setValues}
                    loading={loading}
                    setLoading={setLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row>
      <div class="divider div-transparent div-stopper"></div>
    </>
  );
};

export default SelectPermissions;
