import React, { useState } from "react";
import { convertUTCToLocalTime } from "../../../constants/const";
import moment from "moment";
import { GetLanguageString } from "../../helper/Components";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Col,
  Row,
  Table,
} from "reactstrap";

const MemberCourses = ({ tab5, courseList }) => {
  const [open, setOpen] = useState("");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  return (
    <div role="tabpanel" id="tab-5" className={"tab-pane " + tab5}>
      <div className="panel-body">
        <Row>
          <Col md="12">
            {courseList?.map((item, index) => {
              return (
                <div key={index}>
                  <Accordion open={open} toggle={toggle} key={index}>
                    <AccordionItem>
                      <AccordionHeader
                        className="ur"
                        targetId={(index + 1).toString()}
                      >
                        {item.label}
                      </AccordionHeader>
                      <AccordionBody accordionId={(index + 1).toString()}>
                        <div className="table-responsive">
                          <Table responsive>
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>
                                  {
                                    <GetLanguageString props="member_member_detail_course_name" />
                                  }
                                </th>
                                <th>
                                  {
                                    <GetLanguageString props="member_member_detail_course_start_date" />
                                  }
                                </th>
                                <th>
                                  {
                                    <GetLanguageString props="member_member_detail_course_end_date" />
                                  }
                                </th>
                                <th>
                                  {
                                    <GetLanguageString props="member_member_detail_course_comments" />
                                  }
                                </th>
                                <th>
                                  {
                                    <GetLanguageString props="member_member_detail_course_status" />
                                  }
                                </th>
                                <th>
                                  {
                                    <GetLanguageString props="member_member_detail_course_location" />
                                  }
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {item.details
                                .sort(function (a, b) {
                                  if (a.startDate < b.startDate) return 1;
                                  if (a.startDate > b.startDate) return -1;
                                  return 0;
                                })
                                .map((record, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td className="ur">
                                        {record.courseName}
                                      </td>
                                      <td>
                                        {record.startDate
                                          ? moment(
                                              convertUTCToLocalTime(
                                                record.startDate
                                              )
                                            ).format("DD/MM/yyyy")
                                          : null}
                                      </td>
                                      <td>
                                        {record.endDate
                                          ? moment(
                                              convertUTCToLocalTime(
                                                record.endDate
                                              )
                                            ).format("DD/MM/yyyy")
                                          : null}
                                      </td>
                                      <td className="ur">{record.comments}</td>
                                      <td>
                                        {record.courseStatus ? (
                                          <GetLanguageString props="courses_status_completed" />
                                        ) : (
                                          <GetLanguageString props="courses_status_incompleted" />
                                        )}
                                      </td>
                                      <td className="ur">{record.location}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </Table>
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                  </Accordion>
                </div>
              );
            })}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default MemberCourses;
