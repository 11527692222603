import React, { useEffect, useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  Row,
  Col,
  Table,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import { GetLanguageString } from "../helper/Components";
import { GetCustomStyles, GetSelectedLanguage } from "../helper/Method";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import EventParticipants from "./EventParticipants";
import EventParticipantSelectUnit from "./EventParticipantSelectUnit";
import moment from "moment";
import { LoadingSpinner } from "../../constants/const";
import EventParticipantsAhbab from "./EventParticipantsAhbab";
import EventSelectiveParticipantList from "./EventSelectiveParticipantList";
import EventSelectiveParticipantListAhbab from "./EventSelectiveParticipantListAhbab";
import { requestApi } from "../../actions/api";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import FormPrompt from "../shared/FormPrompt";

const EventAttendance = ({ history }) => {
  const [activeTab, setActiveTab] = useState("rufqa");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();
  const eventAddress = location.state.eventAddress;
  const eventDate = location.state.eventDate;
  const eventType = location.state.eventType;
  const forChildUnit = location.state.forChildUnit;
  const forAhbab = location.state.forAhbab;
  const filterExist = location.state.filterExist;
  const [rufqaPercentage, setRufqaPercentage] = useState(0.0);
  const [ahbabPercentage, setAhbabPercentage] = useState(0.0);
  const [renderParticipants, setRenderParticipants] = useState(false);
  const [showStatusDropDown, setShowStatusDropDown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updatedList, setUpdatedList] = useState([]);
  const eventId = location.state.eventId;
  const unitId = location.state.eventUnitId;
  const [values, setValues] = useState({
    unit: { value: unitId, lable: "" },
    isSubUnits: true,
  });
  const rows = [
    { value: 10, label: "10 Rows" },
    { value: 20, label: "20 Rows" },
    { value: 30, label: "30 Rows" },
    { value: 40, label: "40 Rows" },
    { value: 50, label: "50 Rows" },
    { value: 60, label: "60 Rows" },
    { value: 70, label: "70 Rows" },
    { value: 80, label: "80 Rows" },
    { value: 90, label: "90 Rows" },
    { value: 100, label: "100 Rows" },
    { value: 200, label: "200 Rows" },
    { value: 300, label: "300 Rows" },
  ];
  const [rowsDropDownOpen, setRowsDropDownOpen] = useState(false);
  const rowsToggle = () => setRowsDropDownOpen((prevState) => !prevState);
  const [currentRow, setCurrentRow] = useState(rows[0].label);

  const status = [
    { value: null, label: "notifications_all" },
    { value: 0, label: "events_participants_attendance_absent" },
    {
      value: 1,
      label: "events_participants_attendance_present",
      checked: false,
    },
    { value: 2, label: "events_participants_attendance_leave" },
  ];

  const [searchEventParticipants, setSearchEventParticipants] = useState({
    unitId: unitId,
    eventId: eventId,
    isSubUnits: values.isSubUnits,
    activePage: 1,
    pageSize: rows[0].value,
    attendanceStatus: null,
  });

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (updatedList.length !== 0) {
        return "You have unsaved changes. Are You sure you want to leave this page?\nآپ کے پاس غیر محفوظ تبدیلیاں ہیں۔ کیا آپ اس پیج کو ریلوڈ کرنا چاھتے ہیں؟";
      }
    });

    return () => unblock();
  }, [updatedList, history]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    setUpdatedList([]);
  }, [
    searchEventParticipants.unitId,
    searchEventParticipants.attendanceStatus,
  ]);

  const handleSubmit = () => {
    setLoading(true);
    setButtonDisabled(true);
    if (updatedList.length === 0) {
      swal({
        title: t("events_attendance_event_success"),
        className: GetSelectedLanguage() === "en" ? "" : "ur",
        icon: "success",
        buttons: GetSelectedLanguage() === "en" ? "OK" : "ٹھیک ہے ",
      }).then((ok) => {
        setRenderParticipants((prevState) => !prevState);
      });
    } else {
      requestApi("eventparticipant/")
        .create({
          unitId: searchEventParticipants.unitId,
          eventId: eventId,
          eventParticipantsModelList: updatedList,
        })
        .then((res) => {
          setUpdatedList([]);
          setRenderParticipants((prevState) => !prevState);
          swal({
            title: t("events_attendance_event_success"),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "success",
            buttons: GetSelectedLanguage() === "en" ? "OK" : "ٹھیک ہے ",
          });
          setLoading(false);
          setButtonDisabled(false);
        })
        .catch((err) => {
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
          console.log(err);
          setLoading(false);
          setButtonDisabled(false);
        });
    }
  };

  return (
    <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
      <FormPrompt hasUnsavedChanges={updatedList.length !== 0} />
      <div className="panel panel-info">
        <div className="panel-heading">
          <Row>
            <Col md="10">
              {" "}
              <h4>
                <GetLanguageString props="events_attendance" />
              </h4>
            </Col>
            <Col md="2">
              <Button
                color="primary"
                size="sm"
                className={GetCustomStyles().btn_style}
                onClick={() => history.goBack()}
              >
                {<GetLanguageString props="courses_participants_back" />}
              </Button>
            </Col>
          </Row>
        </div>
        <div className="panel-body">
          {!loading ? (
            <Row>
              <Col md="5" style={{ marginLeft: "50px" }}>
                <Row>
                  <h1 className="ur">{eventType}</h1>
                </Row>
                <Row>
                  {" "}
                  <h3 className="ur">{eventAddress}</h3>
                </Row>{" "}
                <Row>
                  {" "}
                  <h3>
                    {moment(eventDate)
                      .locale(GetSelectedLanguage())
                      .format("DD/MM/yyyy    h:mm a")}
                  </h3>
                </Row>
              </Col>
              <Col md="3"></Col>
              <Col md="2">
                <Table size="small" className="m-b-xs">
                  <tbody>
                    <tr>
                      <td className="text-center">
                        <strong> {rufqaPercentage.toFixed(2)} % </strong> &ensp;
                        <GetLanguageString props="events_attendance_rufqa_percentage" />{" "}
                      </td>
                    </tr>
                    {forAhbab ? (
                      <tr>
                        <td className="text-center">
                          <strong>{ahbabPercentage.toFixed(2)} % </strong>{" "}
                          &ensp;
                          <GetLanguageString props="events_attendance_ahbab_percentage" />{" "}
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </Table>
              </Col>
            </Row>
          ) : (
            LoadingSpinner()
          )}
          {forChildUnit ? (
            <div style={{ marginLeft: "50px", marginTop: "20px" }}>
              <EventParticipantSelectUnit
                values={values}
                setValues={setValues}
                eventUnitId={unitId}
                setSearchEventParticipants={setSearchEventParticipants}
                searchEventParticipants={searchEventParticipants}
                forChildUnit={forChildUnit}
                showStatusDropDown={showStatusDropDown}
              />
            </div>
          ) : null}
        </div>
      </div>
      <div className="tabs-container">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "rufqa" })}
              onClick={() => {
                toggleTab("rufqa");
              }}
            >
              <GetLanguageString props="unit_non_responsible_member" />
            </NavLink>
          </NavItem>
          {forAhbab ? (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "ahbab" })}
                onClick={() => {
                  toggleTab("ahbab");
                }}
              >
                <GetLanguageString props="unit_ahbaab" />
              </NavLink>
            </NavItem>
          ) : null}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="rufqa">
            <div className="panel-body">
              {filterExist ? (
                <EventParticipants
                  untiId={unitId}
                  eventId={eventId}
                  renderParticipants={renderParticipants}
                  setSearchEventParticipants={setSearchEventParticipants}
                  searchEventParticipants={searchEventParticipants}
                  rows={rows}
                  currentRow={currentRow}
                  setCurrentRow={setCurrentRow}
                  activeTab={activeTab}
                  updatedList={updatedList}
                  setUpdatedList={setUpdatedList}
                  setLoading={setLoading}
                  loading={loading}
                  setButtonDisabled={setButtonDisabled}
                  setShowStatusDropDown={setShowStatusDropDown}
                  setRufqaPercentage={setRufqaPercentage}
                  setAhbabPercentage={setAhbabPercentage}
                  status={status}
                />
              ) : (
                <EventSelectiveParticipantList
                  eventId={eventId}
                  renderParticipants={renderParticipants}
                  setRenderParticipants={setRenderParticipants}
                  setSearchEventParticipants={setSearchEventParticipants}
                  searchEventParticipants={searchEventParticipants}
                  rows={rows}
                  currentRow={currentRow}
                  setCurrentRow={setCurrentRow}
                  activeTab={activeTab}
                  updatedList={updatedList}
                  setUpdatedList={setUpdatedList}
                  setLoading={setLoading}
                  loading={loading}
                  setButtonDisabled={setButtonDisabled}
                  setShowStatusDropDown={setShowStatusDropDown}
                  setRufqaPercentage={setRufqaPercentage}
                  setAhbabPercentage={setAhbabPercentage}
                  status={status}
                />
              )}
            </div>
          </TabPane>
          {forAhbab ? (
            <TabPane tabId="ahbab">
              <div className="panel-body">
                {filterExist ? (
                  <EventParticipantsAhbab
                    untiId={unitId}
                    eventId={eventId}
                    renderParticipants={renderParticipants}
                    setSearchEventParticipants={setSearchEventParticipants}
                    searchEventParticipants={searchEventParticipants}
                    rows={rows}
                    currentRow={currentRow}
                    setCurrentRow={setCurrentRow}
                    activeTab={activeTab}
                    updatedList={updatedList}
                    setUpdatedList={setUpdatedList}
                    setLoading={setLoading}
                    loading={loading}
                    setButtonDisabled={setButtonDisabled}
                    setShowStatusDropDown={setShowStatusDropDown}
                    setRufqaPercentage={setRufqaPercentage}
                    setAhbabPercentage={setAhbabPercentage}
                    status={status}
                  />
                ) : (
                  <EventSelectiveParticipantListAhbab
                    eventId={eventId}
                    renderParticipants={renderParticipants}
                    setRenderParticipants={setRenderParticipants}
                    setSearchEventParticipants={setSearchEventParticipants}
                    searchEventParticipants={searchEventParticipants}
                    rows={rows}
                    currentRow={currentRow}
                    setCurrentRow={setCurrentRow}
                    activeTab={activeTab}
                    updatedList={updatedList}
                    setUpdatedList={setUpdatedList}
                    setLoading={setLoading}
                    loading={loading}
                    setButtonDisabled={setButtonDisabled}
                    setShowStatusDropDown={setShowStatusDropDown}
                    setRufqaPercentage={setRufqaPercentage}
                    setAhbabPercentage={setAhbabPercentage}
                    status={status}
                  />
                )}
              </div>
            </TabPane>
          ) : null}
          <Row
            style={{
              backgroundColor: "white",
              marginLeft: 2,
              marginRight: 2,
              height: 50,
            }}
          >
            <Row style={{ marginTop: 10 }}>
              <Col md="6">
                <Row>
                  <Col md="1" className="text-right m-1">
                    <h4>Show:</h4>
                  </Col>
                  <Col md="1" className="text-left">
                    <Dropdown isOpen={rowsDropDownOpen} toggle={rowsToggle}>
                      <DropdownToggle
                        caret
                        color="primary"
                        aria-expanded={rowsDropDownOpen}
                      >
                        {currentRow}{" "}
                      </DropdownToggle>
                      <DropdownMenu
                        style={{
                          paddingLeft: "35px",
                          overflow: "auto",
                          height: 150,
                        }}
                      >
                        {rows.map((record, index) => {
                          return (
                            <DropdownItem
                              key={index}
                              onClick={() => {
                                setCurrentRow(record.label);
                                setSearchEventParticipants({
                                  ...searchEventParticipants,
                                  pageSize: record.value,
                                });
                              }}
                            >
                              {record.label}
                            </DropdownItem>
                          );
                        })}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Button
                  color="primary"
                  className={GetCustomStyles().btn_style}
                  onClick={() => handleSubmit()}
                  disabled={buttonDisabled}
                >
                  {<GetLanguageString props="common_save_changes" />}
                </Button>{" "}
                <Button
                  color="white"
                  className={GetCustomStyles().btn_style}
                  onClick={() => history.goBack()}
                >
                  {<GetLanguageString props="common_cancel" />}
                </Button>
              </Col>
            </Row>
          </Row>
        </TabContent>
      </div>
      <br></br>
    </div>
  );
};

export default withRouter(EventAttendance);
