import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import swal from "sweetalert";
import { LoadingSpinner, isUrdu } from "../../../constants/const";
import { GetLanguageString } from "../../helper/Components";
import { GetSelectedLanguage } from "../../helper/Method";
import useForm from "../../shared/useForm";
import { ApiDocumentType } from "./ApiDocumentType";

const documentTypeValues = {
  name: "",
  description: "",
};

const EditDocumentType = ({ setEditModal, setReload, reload, id }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { errors, setErrors, values, setValues, handleInputChange } =
    useForm(documentTypeValues);
  const toggle = () => setEditModal(false);

  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  useEffect(() => {
    setLoading(true);
    ApiDocumentType()
      .editDocumentType(id)
      .then((res) => {
        setValues(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const validate = () => {
    let temp = {};
    temp.name = values.name ? "" : t("settings_name_error_message");
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      ApiDocumentType()
        .updateDocumentType(values)
        .then((res) => {
          swal({
            title: t("settings_document_type_edit_success"),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "success",
            buttons: "OK",
          });
          setReload(!reload);
          setLoading(false);
          toggle();
        })
        .catch((err) => {
          swal({
            title: err,
            text: err.response.data,
            icon: "error",
            buttons: "OK",
          });
          console.log(err);
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="settings_document_type_edit" />}
          </ModalHeader>
          {!loading ? (
            <Form onSubmit={handleSubmit}>
              <ModalBody>
                <FormGroup>
                  <Label>{<GetLanguageString props="common_name" />}</Label>
                  <Input
                    autoFocus
                    type="text"
                    name="name"
                    className={isUrdu(values.name) ? "ur no-drag" : "no-drag"}
                    value={values.name}
                    onChange={handleInputChange}
                    {...(errors.name && { invalid: true })}
                  />
                  {errors.name && (
                    <FormFeedback>
                      <h4>{errors.name}</h4>
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label>
                    {<GetLanguageString props="common_description" />}
                  </Label>
                  <Input
                    type="text"
                    name="description"
                    className={
                      isUrdu(values.description) ? "ur no-drag" : "no-drag"
                    }
                    value={values.description}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="white" onClick={toggle}>
                  {<GetLanguageString props="common_cancel" />}
                </Button>
                <Button color="primary" type="submit">
                  {<GetLanguageString props="common_save_changes" />}
                </Button>
              </ModalFooter>
            </Form>
          ) : (
            LoadingSpinner()
          )}
        </Modal>
      </Draggable>
    </div>
  );
};

export default EditDocumentType;
