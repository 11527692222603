import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col, FormGroup, Label, Button, Input } from "reactstrap";

import { GetLanguageString } from "../../helper/Components";
import SelectUnit from "../../shared/SelectUnit";
import { ApiUnit } from "../ApiUnit";
import { encryptStorage } from "../../../constants/EncryptStorage";

const SearchMonthlyReport = ({ searchUnit, setSearchUnit }) => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [excludeExUnit, setExcludeExUnit] = useState(true);
  const [values, setValues] = useState(searchUnit);
  const [errors, setErrors] = useState(searchUnit);

  if (values.unit.value === 0) {
    ApiUnit()
      .getUnit(loggedInUnitId, loggedInUnitId)
      .then((res) => {
        let temp = res.data.unitList[0];
        let temp2 = { value: temp.id, lable: temp.name };
        setValues({
          ...values,
          unit: temp2,
        });
      })
      .catch((err) => console.log(err));
  }

  const validate = () => {
    let temp = {};
    temp.date =
      values.date !== null ? "" : "The Select Month field is required.";

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputChangeDate = (value) => {
    if (value !== null) {
      let temp = new Date(value);
      setValues({
        ...values,
        date: value,
        month: temp.getMonth() + 1,
        year: temp.getFullYear(),
      });

      setErrors({
        date: "",
      });
    }
  };

  const handleSubmit = () => {
    if (validate()) {
      setSearchUnit(values);
    }
  };

  const handleExcludeExUnit = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setExcludeExUnit(v);
  };

  return (
    <>
      <SelectUnit
        unitId={values.unit.value}
        setValues={setValues}
        values={values}
        excludeExUnit={excludeExUnit}
      />
      <Row>
        <Col md="4">
          <FormGroup check>
            <Label check for="excludeExUnit">
              <Input
                id="excludeExUnit"
                type="checkbox"
                name="excludeExUnit"
                checked={!excludeExUnit}
                value={!excludeExUnit}
                onChange={handleExcludeExUnit}
              />
              {<GetLanguageString props="member_search_member_ex_unit" />}
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <FormGroup>
            <Label className="col-form-label">Select Month</Label>
            <DatePicker
              dateFormat="MMMM yyyy"
              placeholderText="Select month"
              className="form-control"
              showMonthYearPicker
              selected={values.date}
              onChange={handleInputChangeDate}
            />
            {errors.date && <h4 className="text-error">{errors.date}</h4>}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <FormGroup>
            <Button
              color="primary"
              className="m-l-sm"
              type="submit"
              onClick={handleSubmit}
            >
              {<GetLanguageString props="common_view_report" />}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default SearchMonthlyReport;
