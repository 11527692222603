import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  withRouter,
} from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import swal from "sweetalert";
import { convertToDateString, LoadingSpinner } from "../../../constants/const";
import { ApiLetterForCounselling } from "../../config/letterForCounselling/ApiLetterForCounselling";
import { GetLanguageString } from "../../helper/Components";
import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import { addMember } from "../ApiMember";
import LetterForCounsellingDataTable from "./LetterForCounsellingDataTable";
import LetterForCounsellingMemberDataContainer from "./LetterForCounsellingMemberDataContainer";

const AddLetterForCounselling = ({ history }) => {
  const location = useLocation();
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const memberId = location.state?.memberId;

  const memberValues = location.state?.memberValues;

  const { t } = useTranslation();
  const [selectedFrequency, setSelectedFrequency] = useState(null);
  const [loading, setLoading] = useState(false);

  const [questionsList, setQuestionsList] = useState([]);
  const [selectedLetterForCounselling, setSelectedLetterForCounselling] =
    useState();
  const [letterColor, setLetterColor] = useState("");
  const [filteredFrequencyList, setFilteredFrequencyList] = useState();
  const [data, setData] = useState();
  const [lfcList, setLfcList] = useState();
  const [errorMessage, setErrorMessage] = useState({
    questions: "",
    selectedFrequency: "",
  });
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [selectedYear, setSelectedYear] = useState(new Date(currentYear, 0, 1));

  const handleFilteredFrequenciesByLfc = (lfc) => {
    setSelectedLetterForCounselling(lfc);

    filterFrequenciesByLfc(data, lfc);
  };

  const filterFrequenciesByLfc = (data, lfc) => {
    const matchingLfcItems = data.filter(
      (item) => item.lfc.value === lfc.value
    );
    if (matchingLfcItems.length > 0) {
      // Combine all frequencies from matching items
      const combinedFrequencies = matchingLfcItems.flatMap(
        (item) => item.frequencies
      );

      // Get distinct frequencies based on their value
      const distinctFrequencies = combinedFrequencies.filter(
        (freq, index, self) =>
          index === self.findIndex((f) => f.value === freq.value)
      );

      // Set the distinct frequencies
      setFilteredFrequencyList(distinctFrequencies);
      // Fix: Set the first distinct frequency if available
      setSelectedFrequency(
        distinctFrequencies.length !== 0 ? distinctFrequencies[0] : null
      );

      if (distinctFrequencies.length === 0) setQuestionsList([]);
      setErrorMessage({
        ...errorMessage,
        selectedFrequency:
          distinctFrequencies.length === 0
            ? "member_member_detail_letter_for_counselling_already_exist_error_message"
            : "",
      });
    } else {
      setFilteredFrequencyList([]);
      setSelectedFrequency(null);
      setQuestionsList([]);
    }
  };

  useEffect(() => {
    ApiLetterForCounselling()
      .GetMemberLetterForCounsellingByYears(
        selectedYear.getFullYear(),
        memberId
      )
      .then((res) => {
        if (res.data.length !== 0) {
          setData(res.data); 
          const extractedLfcList = res.data
            .map((item) => item.lfc)
            .filter(
              (item, index, self) =>
                index ===
                self.findIndex((lfcItem) => lfcItem.value === item.value)
            );
          setLfcList(extractedLfcList);
          setSelectedLetterForCounselling(res.data[0]?.lfc);

          filterFrequenciesByLfc(res.data, res.data[0]?.lfc);
        } else {
          setErrorMessage({
            ...errorMessage,
            selectedFrequency:
              "member_member_detail_letter_for_counselling_no_letter_year_error_message",
          });
          setSelectedLetterForCounselling(null);
          setLfcList([]);
          setFilteredFrequencyList([]);
          setSelectedFrequency(null);
          setQuestionsList([]);
        }
      })
      .catch((err) => {
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
      });
    // eslint-disable-next-line
  }, [selectedYear, memberId]);

  const getQuestions = () => {
    if (selectedFrequency) setLoading(true);
    addMember()
      .GetLetterForCounsellingQuestions(
        selectedLetterForCounselling?.value,
        memberId
      )
      .then((res) => {
        let tempValues = [...res.data.memberLetterForCounsellingQuestions];

        tempValues = tempValues.map((question) => {
          return { ...question, errorColor: "#ffffff" };
        });
        setErrorMessage({
          ...errorMessage,
          questions: "",
        });
        setQuestionsList(tempValues);
        setLetterColor(res.data.letterColor);
        setLoading(false);
      })
      .catch((err) => {
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
        setLoading(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      questionsList.every((question) => question.rating !== 0) &&
      Object.values(errorMessage).every((x) => x === "") &&
      selectedFrequency !== null &&
      Object.keys(selectedFrequency).length !== 0
    ) {
      const values = {
        memberId: memberId,
        letterForCounsellingId: selectedLetterForCounselling?.value,
        letterFrequency: selectedFrequency.value,
        receivedAt: convertToDateString(selectedDate),
        letterYear: selectedYear.getFullYear(),
        questions: questionsList,
      }; 
      setLoading(true);
      addMember()
        .AddMemberLetterForCounselling(values)
        .then((res) => {
          swal({
            title: t(
              "member_member_detail_letter_for_counselling_added_success"
            ),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "success",
            buttons: "OK",
          });
          history.goBack();
          setLoading(false);
        })
        .catch((err) => {
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
          setLoading(false);
        });
    } else {
      if (questionsList.some((question) => question.rating === 0)) {
        let tempValues = [...questionsList];

        tempValues = tempValues.map((question) => {
          if (question.rating === 0) {
            return { ...question, errorColor: "#FFEBEB" };
          }
          return question;
        });

        setQuestionsList(tempValues);
      }
      setErrorMessage({
        ...errorMessage,
        questions: questionsList.some((question) => question.rating === 0)
          ? "member_member_detail_letter_for_counselling_questions_error_message"
          : "",
        selectedFrequency:
          selectedFrequency === null ||
          Object.keys(selectedFrequency).length === 0
            ? "member_member_detail_letter_for_counselling_already_exist_error_message"
            : "",
      });
    }
  };

  return (
    <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
      <div className="panel panel-primary">
        <div className="panel-heading">
          {" "}
          <Row>
            <Col md="10"></Col>
            <Col md="2">
              <Button
                color="info"
                size="sm"
                className={GetCustomStyles().btn_style}
                onClick={() => history.goBack()}
              >
                {<GetLanguageString props="unit_back_btn" />}
              </Button>
            </Col>
          </Row>
        </div>
        <div className="panel-body">
          <LetterForCounsellingMemberDataContainer
            memberValues={memberValues}
          />
          <br></br>
          <br></br>
          <Row>
            <Col md="3">
              <FormGroup>
                <Label className="col-form-label">
                  {
                    <GetLanguageString props="member_member_detail_letter_for_counselling_year" />
                  }
                </Label>
                <DatePicker
                  showYearPicker
                  dateFormat="yyyy"
                  className="form-control"
                  selected={selectedYear}
                  maxDate={currentDate}
                  onChange={(y) => {
                    setSelectedYear(y);
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label className="col-form-label">
                  {
                    <GetLanguageString props="settings_letter_for_counselling" />
                  }
                </Label>
                <Select
                  value={selectedLetterForCounselling}
                  options={lfcList}
                  isDisabled={selectedLetterForCounselling === null}
                  className="basic-single ur"
                  classNamePrefix="select"
                  onChange={(e) => {
                    handleFilteredFrequenciesByLfc(e);
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup style={{ marginTop: 5 }}>
                <Label>
                  {<GetLanguageString props="settings_event_type_frequency" />}
                </Label>
                <Select
                  value={selectedFrequency}
                  options={filteredFrequencyList}
                  className="basic-single ur"
                  isDisabled={selectedFrequency === null}
                  classNamePrefix="select"
                  onChange={(e) => {
                    setSelectedFrequency(e);
                    setErrorMessage({
                      ...errorMessage,
                      selectedFrequency: "",
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label className="col-form-label">
                  {
                    <GetLanguageString props="member_member_detail_letter_for_counselling_received_date" />
                  }
                </Label>
                <DatePicker
                  showYearDropdown
                  placeholderText="dd/mm/yyyy"
                  className="form-control"
                  selected={selectedDate}
                  maxDate={currentDate}
                  onChange={(d) => setSelectedDate(d)}
                  dateFormat="dd/MM/yyyy"
                />
              </FormGroup>
            </Col>
          </Row>{" "}
          <Row>
            <Col className="text-center">
              <FormGroup>
                <Button
                  color="primary"
                  className="m-l-sm"
                  disabled={selectedFrequency === null}
                  type="submit"
                  onClick={getQuestions}
                >
                  {<GetLanguageString props="common_search" />}
                </Button>
              </FormGroup>
            </Col>
          </Row>
          {errorMessage.selectedFrequency && (
            <Row>
              <h5 className="text-error">
                * {<GetLanguageString props={errorMessage.selectedFrequency} />}
              </h5>
            </Row>
          )}
          {errorMessage.questions && (
            <Row>
              <h5 className="text-error">
                * {<GetLanguageString props={errorMessage.questions} />}
              </h5>
            </Row>
          )}
        </div>
      </div>
      {questionsList.length !== 0 && selectedFrequency !== null ? (
        <div
          className="panel panel-primary"
          style={{ borderColor: letterColor }}
        >
          <div className="panel-body">
            {!loading ? (
              <>
                <LetterForCounsellingDataTable
                  questionsList={questionsList}
                  setQuestionsList={setQuestionsList}
                  setErrorMessage={setErrorMessage}
                  errorMessage={errorMessage}
                  letterColor={letterColor}
                />
                <Row>
                  <div className={GetCustomStyles().btn_style}>
                    {" "}
                    <Button
                      color={"primary"}
                      size="sm"
                      className={GetCustomStyles().btn_style}
                      onClick={handleSubmit}
                    >
                      <GetLanguageString props="Submit" />
                    </Button>
                  </div>{" "}
                </Row>
              </>
            ) : (
              LoadingSpinner()
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default withRouter(AddLetterForCounselling);
