import React, { useEffect, useState } from "react";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import Select from "react-select";
import { SketchPicker } from "react-color";
import { GetLanguageString } from "../helper/Components";
import { GetSelectedLanguage } from "../helper/Method";

const PickColor = ({ setColor, color }) => {
  const [selectedDropDownColor, setSelectedDropDownColor] = useState(color);
  const [selectedPickerColor, setSelectedPickerColor] = useState(color);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const predefinedColors = [
    { hex: "#ffffff", label: "White" },
    { hex: "#ff0000", label: "Red" },
    { hex: "#00ff00", label: "Green" },
    { hex: "#0000ff", label: "Blue" },
    { hex: "#000000", label: "Black" },
    { hex: "#ffff00", label: "Yellow" },
    { hex: "#ff6600", label: "Orange" },
    { hex: "#800080", label: "Purple" },
  ];
  useEffect(() => {
    setSelectedDropDownColor(color);
    setSelectedPickerColor(color);
  }, [color]);

  const handleDropDownColorSelect = (color) => {
    setSelectedDropDownColor(color.hex);
    setSelectedPickerColor("");
    setColor(color.hex);
    setShowColorPicker(false);
  };

  const handlePickerColorSelect = (color) => {
    setSelectedPickerColor(color.hex);
    setSelectedDropDownColor("");
    setColor(color.hex);
  };

  const handleAddColorClick = () => {
    setShowColorPicker(!showColorPicker);
  };

  return (
    <FormGroup>
      <Label>{<GetLanguageString props="settings_select_color" />}</Label>
      <Row>
        <Col md={6}>
          <Select
            options={predefinedColors}
            value={
              selectedDropDownColor !== null
                ? predefinedColors.find(
                    (color) => color.hex === selectedDropDownColor
                  )
                : null
            }
            onChange={handleDropDownColorSelect}
            className="basic-single no-drag"
            classNamePrefix="select"
          />
        </Col>
        <Col md={3}>
          <Button color="primary" onClick={handleAddColorClick}>
            {!showColorPicker ? (
              <GetLanguageString props="settings_pick_color" />
            ) : (
              <GetLanguageString props="settings_close_color_picker" />
            )}
          </Button>
        </Col>
        <Col md={3}>
          {selectedDropDownColor !== "" || selectedPickerColor !== "" ? (
            <div
              style={{
                width: "25px",
                height: "25px",
                borderRadius: "50%",
                border: "1px solid black",
                backgroundColor:
                  selectedDropDownColor !== ""
                    ? selectedDropDownColor
                    : selectedPickerColor !== ""
                    ? selectedPickerColor
                    : "",
              }}
            >
              <span
                className={
                  GetSelectedLanguage() === "en"
                    ? "m-l-lg no-drag"
                    : "m-r-lg no-drag"
                }
              >
                {selectedDropDownColor !== ""
                  ? selectedDropDownColor
                  : selectedPickerColor !== ""
                  ? selectedPickerColor
                  : ""}
              </span>
            </div>
          ) : null}
          {showColorPicker && (
            <SketchPicker
              color={
                selectedPickerColor !== ""
                  ? selectedPickerColor
                  : selectedDropDownColor !== ""
                  ? selectedDropDownColor
                  : ""
              }
              onChange={handlePickerColorSelect}
            />
          )}
        </Col>
      </Row>
    </FormGroup>
  );
};

export default PickColor;
