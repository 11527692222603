import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { Table, Button, ButtonGroup } from "reactstrap";
import { useTranslation } from "react-i18next";

import { requestApi } from "../../../actions/api";
import { GetLanguageString, NoResult } from "../../helper/Components";
import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import { LoadingSpinner } from "../../../constants/const";
import EditBooks from "./EditBooks";
import AddBook from "./AddBook";
import { IoAdd } from "react-icons/io5";

const Books = ({ activeTab }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [books, setBooks] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [editValue, setEditValue] = useState();

  useEffect(() => {
    if (activeTab === "books") {
      setLoading(true);
      requestApi("books/")
        .fetchAll()
        .then((res) => {
          setBooks(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [activeTab]);

  const handleOnDelete = (value, isAffiliated) => {
    if (isAffiliated) {
      swal({
        title: t("common_is_affiliated"),
        text: t("common_is_affiliated_message"),
        icon: "warning",
        className: GetSelectedLanguage() === "en" ? "" : "ur",
      });
    } else {
      swal({
        title: t("common_delete_message"),
        text: t("common_delete_detail_message"),
        icon: "warning",
        buttons: true,
        className: GetSelectedLanguage() === "en" ? "" : "ur",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setLoading(true);
          requestApi("books/")
            .delete(value)
            .then((res) => {
              setBooks(res.data);
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        }
      });
    }
  };

  const handleOnEdit = (e) => {
    const { value } = e.target;
    setEditValue(value);
    setEditModal(true);
  };

  return (
    <div>
      {editModal ? (
        <EditBooks
          editValue={editValue}
          setEditModal={setEditModal}
          setBooks={setBooks}
        />
      ) : null}
      {modal ? <AddBook setModal={setModal} setBooks={setBooks} /> : null}
      <div className="ibox ">
        <div className="ibox-title">
          <Button
            color="default"
            size="sm"
            className={GetCustomStyles().btn_style}
            onClick={() => {
              setModal(true);
            }}
          >
            <IoAdd
              size={18}
              color="#18A689"
              style={{ marginRight: 3, marginBottom: 1 }}
            />
            {<GetLanguageString props="book_add_book" />}
          </Button>
        </div>
        <div className="ibox-content">
          {!loading ? (
            books.length !== 0 ? (
              <div className="table-responsive">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{<GetLanguageString props="common_name" />}</th>
                      <th>{<GetLanguageString props="book_type" />}</th>
                      <th className="text-right">
                        {<GetLanguageString props="common_action" />}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {books.map((record, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="ur">{record.name}</td>
                          <td className="ur">{record.staticBookType.label}</td>
                          <td className="text-right">
                            <ButtonGroup>
                              <Button
                                color="white"
                                size="sm"
                                onClick={handleOnEdit}
                                value={record.id}
                              >
                                {<GetLanguageString props="common_edit" />}
                              </Button>
                              <Button
                                color="white"
                                size="sm"
                                onClick={() =>
                                  handleOnDelete(record.id, record.isAffiliated)
                                }
                              >
                                {<GetLanguageString props="common_delete" />}
                              </Button>
                            </ButtonGroup>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            ) : (
              <NoResult />
            )
          ) : (
            LoadingSpinner()
          )}
        </div>
      </div>
    </div>
  );
};

export default Books;
