import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { GetLanguageString } from "../../helper/Components";
import ProfilePhoto from "./ProfilePhoto";
import Logout from "../../logout/Logout";
import ChangePasswordModal from "./ChangePassword";
import ChangePhotoModal from "./ChangePhoto";
import jwtDecode from "jwt-decode";
import { encryptStorage } from "../../../constants/EncryptStorage";

const ProfileSettings = () => {
  var decodedToken = jwtDecode(encryptStorage.getItem("token"));

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [changePhotoModal, setChangePhotoModal] = useState(false);
  const [returnLogout, setReturnLogout] = useState(false);

  const handleChangePhoto = () => {
    setChangePhotoModal(true);
  };

  const handleChangePassword = () => {
    setChangePasswordModal(true);
  };

  const handleLogout = () => {
    setReturnLogout(true);
  };

  if (returnLogout) return <Logout />;
  return (
    <>
      {changePasswordModal ? (
        <ChangePasswordModal
          setChangePasswordModal={setChangePasswordModal}
          setReturnLogout={setReturnLogout}
        />
      ) : null}
      {changePhotoModal ? (
        <ChangePhotoModal
          setChangePhotoModal={setChangePhotoModal}
          setReturnLogout={setReturnLogout}
        />
      ) : null}
      <div className="dropdown profile-element">
        <ProfilePhoto />
        <Link to="#">
          <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle tag="span" aria-expanded={dropdownOpen}>
              <h4 className="block m-t-xs font-bold ur">
                {decodedToken.given_name.toUpperCase()}
              </h4>
              {decodedToken.role === "" ? null : (
                <span className="text-muted text-xs block ">
                  {decodedToken.role}
                  <b className="caret m-l-xs"></b>
                </span>
              )}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={handleChangePhoto}>
                {<GetLanguageString props="change_profile_photo" />}
              </DropdownItem>
              <DropdownItem onClick={handleChangePassword}>
                {<GetLanguageString props="change_password" />}
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={handleLogout}>
                {<GetLanguageString props="common_log_out" />}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Link>
      </div>
    </>
  );
};
export default ProfileSettings;