import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Table } from "reactstrap";
import { withRouter } from "react-router-dom";

import { GetLanguageString, NoResult } from "../helper/Components";
import { LoadingSpinner } from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";
import SearchBooks from "./SearchBooks";
import UpdateBook from "./UpdateBook";
import { addMember } from "../member/ApiMember";

const Books = ({ history, match }) => {
  const [loading, setLoading] = useState(false);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const memberId = parseInt(encryptStorage.getItem("memberId"));
  const canManage = encryptStorage.getItem("canManage");
  const [booksMembers, setBooksMembers] = useState([]);
  const [updateModal, setUpdateModal] = useState(false);
  const [updateValue, setUpdateValue] = useState();
  const [searchMemberBooksModel, setSearchMemberBooksModel] = useState({
    unit: { value: loggedInUnitId, lable: "" },
    isSubUnits: true,
    loggedInUnitId: loggedInUnitId,
  });

  useEffect(() => {
    setLoading(true);
    addMember()
      .getUnitMember(searchMemberBooksModel.unit.value, searchMemberBooksModel.isSubUnits)
      .then((res) => {
        setBooksMembers(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [searchMemberBooksModel]);

  const handleOnEdit = (e) => {
    const { value } = e.target;
    setUpdateValue(value);
    setUpdateModal(!updateModal);
  };

  return (
    <div>
      {updateModal ? (
        <UpdateBook memberId={updateValue} setUpdateModal={setUpdateModal} />
      ) : null}
      <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
        {memberId !== 0 && !canManage ? null : (
          <SearchBooks
            searchMemberBooksModel={searchMemberBooksModel}
            setSearchMemberBooksModel={setSearchMemberBooksModel}
            unitId={searchMemberBooksModel.unit.value}
          />
        )}
        <div className="ibox ">
          <div className="ibox-title">
            <h5>{<GetLanguageString props="member_search_member" />}</h5>
          </div>
          <div className="ibox-content">
            {!loading ? (
              booksMembers.length !== 0 ? (
                <div className="table-responsive">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>{<GetLanguageString props="common_name" />}</th>
                        <th>
                          {<GetLanguageString props="book_update_unit_name" />}
                        </th>
                        <th>
                          {
                            <GetLanguageString props="book_update_responsibilities" />
                          }
                        </th>
                        <th>
                          {
                            <GetLanguageString props="book_update_member_type" />
                          }
                        </th>
                        <th className="text-center">
                          {<GetLanguageString props="common_action" />}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {booksMembers?.map((record, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td className="ur">{record.name}</td>
                            <td>
                              <Button
                                size="xs"
                                color="info"
                                className="label label-info m-r-xs"
                              >
                                <h5 className="ur">{record.unitName}</h5>
                              </Button>
                            </td>
                            <td>
                              {record.responsbilities.map(
                                (item1, index1) => {
                                  return (
                                    <Button
                                      key={index1}
                                      size="xs"
                                      color="success"
                                      className="label label-success m-r-xs"
                                    >
                                      <h5 className="ur">{item1}</h5>
                                    </Button>
                                  );
                                }
                              )}
                            </td>
                            <td className="ur">{record.memberTypeName}</td>
                            <td className="text-center">
                              <ButtonGroup>
                                <Button
                                  color="info"
                                  size="sm"
                                  onClick={handleOnEdit}
                                  value={record.memberId}
                                >
                                  {
                                    <GetLanguageString props="book_update_update_books" />
                                  }
                                </Button>
                              </ButtonGroup>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <NoResult />
              )
            ) : (
              LoadingSpinner()
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Books);
