import React from "react";
import { convertUTCToLocalTime } from "../../../constants/const";
import moment from "moment";
import { Button, Table } from "reactstrap";
import { GetPeriodName } from "../../helper/Method";
import { GetLanguageString } from "../../helper/Components";

const MemberLetterForCounselling = ({ tab3, letterForCounsellingList }) => {
  return (
    <div role="tabpanel" id="tab-3" className={"tab-pane " + tab3}>
      <div className="panel-body">
        <div className="table-responsive">
          <Table responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>{<GetLanguageString props="member_reg_number" />}</th>
                <th>
                  {
                    <GetLanguageString props="member_member_detail_letter_for_counselling_title" />
                  }
                </th>
                <th>
                  {
                    <GetLanguageString props="member_member_detail_responsibility_history" />
                  }
                </th>
                <th>
                  {
                    <GetLanguageString props="member_member_detail_unit_history" />
                  }
                </th>
                <th>
                  {
                    <GetLanguageString props="member_member_detail_letter_for_counselling_received_date" />
                  }
                </th>
              </tr>
            </thead>
            <tbody>
              {letterForCounsellingList.map((record, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{record.regNo}</td>
                    <td className="ur">
                      {record.title} (
                      {GetPeriodName(
                        record.letterFrequency,
                        record.letterForFrequency
                      )}{" "}
                      {record.year})
                    </td>
                    <td>
                      {record.responsibility.map((item, index) => {
                        return (
                          <Button
                            size="xs"
                            color="info"
                            key={index}
                            className="label label-info m-r-xs"
                          >
                            <h5 className="ur">{item}</h5>
                          </Button>
                        );
                      })}
                    </td>
                    <td>
                      <Button
                        size="xs"
                        color="info"
                        key={index}
                        className="label label-primary m-r-xs"
                      >
                        <h5 className="ur">{record.unitName}</h5>
                      </Button>
                    </td>
                    <td>
                      {record.receivedAt
                        ? moment(
                            convertUTCToLocalTime(record.receivedAt)
                          ).format("DD/MM/yyyy")
                        : null}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default MemberLetterForCounselling;
