import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";

import Draggable from "react-draggable";
import { requestApi } from "../../../actions/api";
import { LoadingSpinner } from "../../../constants/const";
import { GetLanguageString } from "../../helper/Components";
import useForm from "../../shared/useForm";
import PickColor from "../PickColor";

const memberTypeValues = {
  type: "",
  code: "",
  gender:"m",
  color:"",
};

const AddMemberType = ({ setModal, reload, setReload }) => {
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("");
  const { t } = useTranslation();
  const { errors, setErrors, values, setValues, handleInputChange } =
    useForm(memberTypeValues);
  const toggle = () => setModal(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  const validate = () => {
    let temp = {};
    temp.type = values.type ? "" : t("settings_name_error_message");
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleGenderStatus = (e) => {
    const value = e.target.value;
    let gender = "";
    if (value === "0"){
      gender = "m";
    }
    else if (value === "1") {
      gender = "f";
    }

    setValues({
      ...values,
      gender: gender,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    values.color = color;
    if (validate()) {
      setLoading(true);
      requestApi("membertype/")
        .create(values)
        .then((res) => {
          toggle();
          setReload(!reload)
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="settings_add_member_type" />}
          </ModalHeader>
          {!loading ? (
            <Form onSubmit={handleSubmit}>
              <ModalBody>
                <FormGroup>
                  <Label>{<GetLanguageString props="common_name" />}</Label>
                  <Input
                    autoFocus
                    type="text"
                    name="type"
                    className="ur no-drag"
                    value={values.type}
                    onChange={handleInputChange}
                    {...(errors.type && { invalid: true })}
                  />
                  {errors.type && (
                    <FormFeedback>
                      <h4>{errors.type}</h4>
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label>
                    {<GetLanguageString props="settings_code" />}
                  </Label>
                  <Input
                    type="text"
                    className="no-drag"
                    name="code"
                    value={values.code}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>{<GetLanguageString props="settings_gender" />}</Label>
                  <Row className=" m-t-sm">
                    <Col md="2">
                      <FormGroup check>
                        <Label check for="male">
                          <Input
                            id="male"
                            type="radio"
                            checked={values.gender === 'm'}
                            value={0}
                            onClick={handleGenderStatus}
                          />
                          {<GetLanguageString props="settings_gender_male" />}
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup check>
                        <Label check for="female">
                          <Input
                            id="female"
                            type="radio"
                            checked={values.gender === 'f'}
                            value={1}
                            onClick={handleGenderStatus}
                          />
                          {<GetLanguageString props="settings_gender_female" />}
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </FormGroup>
                <PickColor setColor={setColor} color={color} />
              </ModalBody>
              <ModalFooter>
                <Button color="white" onClick={toggle}>
                  {<GetLanguageString props="common_cancel" />}
                </Button>
                <Button color="primary" type="submit">
                  {<GetLanguageString props="common_save_changes" />}
                </Button>
              </ModalFooter>
            </Form>
          ) : (
            LoadingSpinner()
          )}
        </Modal>
      </Draggable>  
    </div>
  );
};

export default AddMemberType;
