import React, { useState } from "react";
import {
  FormGroup,
  Form,
  Label,
  Input,
  Row,
  Col,
  NavLink,
  TabContent,
  TabPane,
  Nav,
  NavItem,
} from "reactstrap";
import Select from "react-select";
import { LoadingSpinner } from "../../constants/const";
import classnames from "classnames";

const ManagePermissions = ({
  id,
  // handleMemberAccessRights,
  setModifyMemberType,
  setRequestModifyMemberType,
  setModifyMember,
  setRequestModifyMember,
  errors,
  addRafiq,
  setAddRafiq,
  requestAddRafiq,
  setRequestAddRafiq,
  cancelRafiq,
  setCancelRafiq,
  requestCancelRafiq,
  setRequestCancelRafiq,
  setRequestMarkAsEx,
  setMarkAsEx,
  setModifyResponsibility,
  setRequestResponsibilityModification,
  values,
  setValues,
  loading,
}) => {
  const [activeTab, setActiveTab] = useState(1);

  const toggleTabs = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const handlePermissions = (e, index, index1, label) => {
    const { checked } = e.target;
    const tempValues = [...values[id].permissions.permissions];
    tempValues[index].claims[index1].isSelected = checked;
    tempValues[index].claims.map((item, index2) => {
      return item.name.includes("View")
        ? item.isSelected
          ? null
          : (tempValues[index].claims[index2].isSelected = checked)
        : null;
    });

    let temp = [...values];
    temp[id].permissions.permissions = tempValues;
    setValues(temp);

    if (label === "CanCancelMemberRegistration") {
      setCancelRafiq(!cancelRafiq);
    } else if (label === "CanRequestMemberRegistrationCancelation") {
      setRequestCancelRafiq(!requestCancelRafiq);
    } else if (label === "CanAddMember") {
      setAddRafiq(!addRafiq);
    } else if (label === "CanRequestAddMember") {
      setRequestAddRafiq(!requestAddRafiq);
    }
  };

  const handleSelectedOptions = (
    record,
    index,
    index1,
    claimName,
    permissionName
  ) => {
    let tempValues = [...values[id].permissions.permissions];
    tempValues[index].claims[index1].selectedOptions = record;

    let temp = [...values];
    temp[id].permissions.permissions = tempValues;
    setValues(temp);

    if (permissionName.includes("Rufaqaa")) {
      if (claimName === "CanModifyMembers") {
        setModifyMember(record);
      } else if (claimName.includes("RequestMemberModification")) {
        setRequestModifyMember(record);
      } else if (claimName.includes("ModifyMemberType")) {
        setModifyMemberType(record);
      } else if (claimName.includes("RequestMemberTypeModification")) {
        setRequestModifyMemberType(record);
      } else if (claimName.includes("ModifyResponsibility")) {
        setModifyResponsibility(record);
      } else if (claimName.includes("RequestResponsibilityModification")) {
        setRequestResponsibilityModification(record);
      } else if (claimName.includes("MarkAsEx")) {
        setMarkAsEx(record);
      } else if (claimName.includes("RequestMarkRafiqAsEx")) {
        setRequestMarkAsEx(record);
      }
    }
  };

  return (
    <div>
      {!loading ? (
        <Form>
          <div className="wrapper wrapper-content">
            <Row>
              <Col>
                <div className="tabs-container">
                  <div className="tabs-left">
                    <Nav tabs>
                      {values[id].permissions?.permissions?.map(
                        (item, index) => {
                          return item.claims.length !== 0 ? (
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === index + 1,
                                })}
                                onClick={() => {
                                  toggleTabs(index + 1);
                                }}
                              >
                                <h5>{item.name}</h5>
                              </NavLink>
                            </NavItem>
                          ) : null;
                        }
                      )}
                    </Nav>
                    <TabContent activeTab={activeTab}>
                      {values[id].permissions?.permissions?.map(
                        (item, index) => {
                          return (
                            <TabPane tabId={index + 1}>
                              <div className="panel-body">
                                {item.claims.map((record, index1) => {
                                  return (
                                    <>
                                      <Row>
                                        <Col md="6">
                                          <FormGroup check>
                                            <Row className="m-4">
                                              <Label>
                                                <Input
                                                  id={
                                                    "isSelected" + record.value
                                                  }
                                                  type="checkbox"
                                                  checked={record.isSelected}
                                                  onChange={(e) => {
                                                    handlePermissions(
                                                      e,
                                                      index,
                                                      index1,
                                                      record.name
                                                    );
                                                  }}
                                                />
                                                {record.label}
                                              </Label>
                                            </Row>
                                          </FormGroup>
                                        </Col>
                                        {item.name.includes("Rufaqaa") ? (
                                          record.isSelected &&
                                            (record.label.includes("Modif") ||
                                              record.label.includes("Move") ||
                                          record.label.includes("Ex")) ? (
                                            <Col md="5" className="m">
                                              <FormGroup>
                                                <Select
                                                  isOptionDisabled={(options) =>
                                                    options.isDisabled
                                                  }
                                                  value={record.selectedOptions}
                                                  options={record.options}
                                                  className={
                                                    record.label.includes(
                                                      "Type"
                                                    ) ||
                                                    record.label.includes(
                                                      "Responsibili"
                                                    ) ||
                                                    record.label.includes("Ex")
                                                      ? "basic-multi-select ur"
                                                      : "basic-multi-select"
                                                  }
                                                  classNamePrefix="Select"
                                                  isMulti
                                                  onChange={(e) => {
                                                    handleSelectedOptions(
                                                      e,
                                                      index,
                                                      index1,
                                                      record.name,
                                                      item.name
                                                    );
                                                  }}
                                                  isDisabled={record.isDisabled}
                                                />
                                              </FormGroup>
                                            </Col>
                                          ) : null
                                        ) : record.isSelected &&
                                          !item.name.includes("Funds") &&
                                          !item.name.includes("Ahbab") &&
                                          !item.name.includes("Mudaris") &&
                                          !item.name.includes("Other") &&
                                          !item.name.includes("Document") &&
                                          !record.name.includes("View") ? (
                                          <Col md="5" className="m">
                                            <FormGroup>
                                              <Select
                                                isOptionDisabled={(options) =>
                                                  options.isDisabled
                                                }
                                                value={record.selectedOptions}
                                                options={record?.options}
                                                className="basic-multi-select ur"
                                                classNamePrefix="Select a Type"
                                                placeholder="قسم منتخب کریں"
                                                isMulti
                                                onChange={(e) => {
                                                  handleSelectedOptions(
                                                    e,
                                                    index,
                                                    index1,
                                                    record.name,
                                                    item.name
                                                  );
                                                }}
                                                isDisabled={record.isDisabled}
                                              />
                                            </FormGroup>
                                          </Col>
                                        ) : null}
                                      </Row>
                                    </>
                                  );
                                })}
                              </div>
                            </TabPane>
                          );
                        }
                      )}
                    </TabContent>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          {errors.modifyMember && (
            <h4 className="text-error">{errors.modifyMember}</h4>
          )}
          {errors.modifyMemberType && (
            <h4 className="text-error">{errors.modifyMemberType}</h4>
          )}
          {errors.addMember && (
            <h4 className="text-error">{errors.addMember}</h4>
          )}
          {errors.CancelMemberRegistration && (
            <h4 className="text-error">{errors.CancelMemberRegistration}</h4>
          )}
        </Form>
      ) : (
        LoadingSpinner()
      )}
    </div>
  );
};

export default ManagePermissions;
