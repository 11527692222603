import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Label,
  Button,
  NavLink,
  Input,
  Nav,
  NavItem,
} from "reactstrap";
import { LoadingSpinner } from "../../constants/const";
import { GetLanguageString } from "../helper/Components";
import { ApiBooks } from "./ApiBooks";
import Draggable from "react-draggable";

const UpdateBook = ({ memberId, setUpdateModal }) => {
  const selectedBooks = {
    memberId: memberId,
    zimedaranNisaab: [],
    mubtadiNisaab: [],
    multazimNisaab: [],
    mudaraseenNisaab: [],
    dawatiNisaab: [],
  };
  const [values, setValues] = useState(selectedBooks);
  const [loading, setLoading] = useState(false);
  const [tab1, setTab1] = useState("");
  const [tab2, setTab2] = useState("");
  const [tab3, setTab3] = useState("");
  const [tab4, setTab4] = useState("");
  const [tab5, setTab5] = useState("");

  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  const toggle = () => setUpdateModal(false);

  useEffect(() => {
    setLoading(true);
    ApiBooks()
      .getBooksList(memberId)
      .then((res) => {
        if (res.data.mubtadiNisaab !== null) {
          setTab1("active");
        }
        if (res.data.multazimNisaab !== null) {
          setTab2("active");
        }
        setValues(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err)
        setLoading(false);
      });
  }, [memberId]);

  const handleZimedaraanNisaabBooks = (e, index) => {
    const { checked } = e.target;
    let tempValues = values.zimedaranNisaab;
    tempValues[index].isSelected = checked;
    setValues({
      ...values,
      zimedaranNisaab: tempValues,
    });
  };
  const handleMubtadiNisaabBooks = (e, index) => {
    const { checked } = e.target;
    let tempValues = values.mubtadiNisaab;
    tempValues[index].isSelected = checked;
    setValues({
      ...values,
      mubtadiNisaab: tempValues,
    });
  };

  const handleMultazimNisaabBooks = (e, index) => {
    const { checked } = e.target;
    let tempValues = values.multazimNisaab;
    tempValues[index].isSelected = checked;
    setValues({
      ...values,
      multazimNisaab: tempValues,
    });
  };

  const handleMudaraseenNisaabBooks = (e, index) => {
    const { checked } = e.target;
    let tempValues = values.mudaraseenNisaab;
    tempValues[index].isSelected = checked;
    setValues({
      ...values,
      mudaraseenNisaab: tempValues,
    });
  };
  const handleDawatiNisaabBooks = (e, index) => {
    const { checked } = e.target;
    let tempValues = values.dawatiNisaab;
    tempValues[index].isSelected = checked;
    setValues({
      ...values,
      dawatiNisaab: tempValues,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    ApiBooks()
      .updateBooks(values)
      .then((res) => {
        toggle();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div>
    <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
      <Modal isOpen={true} className="inmodal" autoFocus={false}>
        <ModalHeader className="modal-title" tag="h4">
          {<GetLanguageString props="book_update_update_books" />}
        </ModalHeader>
        {!loading ? (
          <Form>
            <ModalBody>
              <div className="tabs-container">
                <Nav tabs>
                  {values.mubtadiNisaab && (
                    <NavItem>
                      <NavLink
                        className={tab1}
                        onClick={() => {
                          setTab1("active");
                          setTab2("");
                          setTab3("");
                          setTab4("");
                          setTab5("");
                        }}
                      >
                        <h6 className="font-bold">
                          <b>
                            {<GetLanguageString props="book_update_book2" />}
                          </b>
                        </h6>
                      </NavLink>
                    </NavItem>
                  )}
                  {values.multazimNisaab && (
                    <NavItem>
                      <NavLink
                        className={tab2}
                        onClick={() => {
                          setTab1("");
                          setTab2("active");
                          setTab3("");
                          setTab4("");
                          setTab5("");
                        }}
                      >
                        <h6 className="font-bold">
                          <b>
                            {<GetLanguageString props="book_update_book3" />}
                          </b>
                        </h6>
                      </NavLink>
                    </NavItem>
                  )}
                  {values.zimedaranNisaab && (
                    <NavItem>
                      <NavLink
                        className={tab3}
                        onClick={() => {
                          setTab1("");
                          setTab2("");
                          setTab3("active");
                          setTab4("");
                          setTab5("");
                        }}
                      >
                        <h6 className="font-bold">
                          <b>
                            {<GetLanguageString props="book_update_book1" />}
                          </b>
                        </h6>
                      </NavLink>
                    </NavItem>
                  )}
                  {values.mudaraseenNisaab && (
                    <NavItem>
                      <NavLink
                        className={tab4}
                        onClick={() => {
                          setTab1("");
                          setTab2("");
                          setTab3("");
                          setTab4("active");
                          setTab5("");
                        }}
                      >
                        <h6 className="font-bold">
                          <b>
                            {<GetLanguageString props="book_update_book4" />}
                          </b>
                        </h6>
                      </NavLink>
                    </NavItem>
                  )}
                  {values.dawatiNisaab && (
                    <NavItem>
                      <NavLink
                        className={tab5}
                        onClick={() => {
                          setTab1("");
                          setTab2("");
                          setTab3("");
                          setTab4("");
                          setTab5("active");
                        }}
                      >
                        <h6 className="font-bold">
                          <b>
                            {<GetLanguageString props="book_update_book5" />}
                          </b>
                        </h6>
                      </NavLink>
                    </NavItem>
                  )}
                </Nav>
                <div className="tab-content">
                  <div
                    role="tabpanel"
                    id="tab-1"
                    className={"tab-pane " + tab1}
                  >
                    <div className="panel-body">
                      {values.mubtadiNisaab?.map((record, index) => {
                        return (
                          <FormGroup check key={index}>
                            <Label check>
                              <Input
                                id={"isSelected" + record.value}
                                type="checkbox"
                                checked={record.isSelected}
                                onChange={(e) =>
                                  handleMubtadiNisaabBooks(e, index)
                                }
                              />
                             <h4 className="ur no-drag">{record.label}</h4>
                            </Label>
                          </FormGroup>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="tab-content">
                  <div
                    role="tabpanel"
                    id="tab-2"
                    className={"tab-pane " + tab2}
                  >
                    <div className="panel-body">
                      {values.multazimNisaab?.map((record, index) => {
                        return (
                          <FormGroup check key={index}>
                            <Label check>
                              <Input
                                id={"isSelected" + record.value}
                                type="checkbox"
                                checked={record.isSelected}
                                onChange={(e) =>
                                  handleMultazimNisaabBooks(e, index)
                                }
                              />
                              <h4 className="ur no-drag">{record.label}</h4>
                            </Label>
                          </FormGroup>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="tab-content">
                  <div
                    role="tabpanel"
                    id="tab-3"
                    className={"tab-pane " + tab3}
                  >
                    <div className="panel-body">
                      {values.zimedaranNisaab?.map((record, index) => {
                        return (
                          <FormGroup check key={index}>
                            <Label check>
                              <Input
                                id={"isSelected" + record.value}
                                type="checkbox"
                                checked={record.isSelected}
                                onChange={(e) =>
                                  handleZimedaraanNisaabBooks(e, index)
                                }
                              />
                              <h4 className="ur no-drag">{record.label}</h4>
                            </Label>
                          </FormGroup>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="tab-content">
                  <div
                    role="tabpanel"
                    id="tab-4"
                    className={"tab-pane " + tab4}
                  >
                    <div className="panel-body">
                      {values.mudaraseenNisaab?.map((record, index) => {
                        return (
                          <FormGroup check key={index}>
                            <Label check>
                              <Input
                                id={"isSelected" + record.value}
                                type="checkbox"
                                checked={record.isSelected}
                                onChange={(e) =>
                                  handleMudaraseenNisaabBooks(e, index)
                                }
                              />
                              <h4 className="ur no-drag">{record.label}</h4>
                            </Label>
                          </FormGroup>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="tab-content">
                  <div
                    role="tabpanel"
                    id="tab-5"
                    className={"tab-pane " + tab5}
                  >
                    <div className="panel-body">
                      {values.dawatiNisaab?.map((record, index) => {
                        return (
                          <FormGroup check key={index}>
                            <Label check>
                              <Input
                                id={"isSelected" + record.value}
                                type="checkbox"
                                checked={record.isSelected}
                                onChange={(e) =>
                                  handleDawatiNisaabBooks(e, index)
                                }
                              />
                              <h4 className="ur no-drag">{record.label}</h4>
                            </Label>
                          </FormGroup>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="white" onClick={toggle}>
                {<GetLanguageString props="common_cancel" />}
              </Button>
              <Button color="primary" type="submit" onClick={handleSubmit}>
                {<GetLanguageString props="common_save_changes" />}
              </Button>
            </ModalFooter>
          </Form>
        ) : (
          LoadingSpinner()
        )}
      </Modal>
    </Draggable>  
    </div>
  );
};

export default UpdateBook;
