import React, { useState } from "react";
import { useEffect } from "react";
import { GetLanguageString } from "../helper/Components";
import { Button, Col, Form, Row } from "reactstrap";
import { withRouter, useLocation } from "react-router-dom";
import { GetCustomStyles, GetSelectedLanguage } from "../helper/Method";
import SelectPermissions from "./SelectPermissions";
import { ApiUser } from "../users/ApiUsers";
import { encryptStorage } from "../../constants/EncryptStorage";
import { LoadingSpinner } from "../../constants/const";
import swal from "sweetalert";

const UserPermisions = ({ history }) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const claimGivenById = parseInt(encryptStorage.getItem("memberId"));
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const memberId = location.state?.memberId;
  const [initialPermissions, setInitialPermissions] = useState([]);
  const [modifyMemberType, setModifyMemberType] = useState([]);
  const [requestModifyMemberType, setRequestModifyMemberType] = useState([]);
  const [modifyMember, setModifyMember] = useState([]);
  const [requestModifyMember, setRequestModifyMember] = useState([]);
  const [addRafiq, setAddRafiq] = useState(false);
  const [requestAddRafiq, setRequestAddRafiq] = useState(false);
  const [cancelRafiq, setCancelRafiq] = useState(false);
  const [requestCancelRafiq, setRequestCancelRafiq] = useState(false);
  const [markAsEx, setMarkAsEx] = useState([]);
  const [requestMarkAsEx, setRequestMarkAsEx] = useState([]);
  const [modifyResponsibility, setModifyResponsibility] = useState([]);
  const [
    requestResponsibilityModification,
    setRequestResponsibilityModification,
  ] = useState([]);
  const [units, setUnits] = useState([]);
  const [updatePermission, setUpdatePermissions] = useState(true);

  const [errors, setErrors] = useState({
    modifyMember: "",
    modifyMemberType: "",
    addMember: "",
    CancelMemberRegistration: "",
    modifyResponsibility: "",
    markAsEx: "",
  });

  const validate = () => {
    let temp = {};
    let member = modifyMember.filter((item) =>
      requestModifyMember.some(
        (requestModifyMember) => requestModifyMember.value === item.value
      )
    );
    let memberType = modifyMemberType.filter((item) =>
      requestModifyMemberType.some(
        (requestModifyMember) => requestModifyMember.value === item.value
      )
    );
    let markAsExRafiq = markAsEx.filter((item) =>
      requestMarkAsEx.some(
        (requestMarkAsEx) => requestMarkAsEx.value === item.value
      )
    );
    let responsibilityModification = modifyResponsibility.filter((item) =>
      requestResponsibilityModification.some(
        (requestResponsibilityModification) =>
          requestResponsibilityModification.value === item.value
      )
    );
    temp.modifyResponsibility =
      Object.keys(responsibilityModification).length === 0 ? (
        ""
      ) : (
        <GetLanguageString props="settings_responsibility_manage_permission_rufaqaa_permission_modify_responsibility_error" />
      );
    temp.modifyMember =
      Object.keys(member).length === 0 ? (
        ""
      ) : (
        <GetLanguageString props="settings_responsibility_manage_permission_rufaqaa_permission_modify_Member_error" />
      );
    temp.modifyMemberType =
      Object.keys(memberType).length === 0 ? (
        ""
      ) : (
        <GetLanguageString props="settings_responsibility_manage_permission_rufaqaa_permission_modify_Member_Type_error" />
      );

      temp.markAsExRafiq =
      Object.keys(markAsExRafiq).length === 0 ? (
        ""
      ) : (
        <GetLanguageString props="settings_responsibility_manage_permission_rufaqaa_permission_mark_as_ex_error" />
      );
    temp.addMember =
      addRafiq && requestAddRafiq ? (
        <GetLanguageString props="settings_responsibility_manage_permission_rufaqaa_permission_add_member_error" />
      ) : (
        ""
      );

    temp.CancelMemberRegistration =
      cancelRafiq && requestCancelRafiq ? (
        <GetLanguageString props="settings_responsibility_manage_permission_rufaqaa_permission_cancel_rafiq_registration_error" />
      ) : (
        ""
      );

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  useEffect(() => {
    setLoading(true);
    ApiUser()
      .getInitialPermissions(memberId, claimGivenById)
      .then((res) => {
        setInitialPermissions(res.data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [memberId, claimGivenById]);

  useEffect(() => {
    setLoading(true);
    ApiUser()
      .getMemberPermissions(memberId, claimGivenById)
      .then((res) => {
        if (res.data.length > 0) setUnits(res.data);
        else {
          setUnits([
            {
              value: loggedInUnitId,
              label: "",
              isDisabled: false,
              permissions: {
                permissions: [...initialPermissions],
              },
            },
          ]);
        }
        res.data.map((item) => {
          item.permissions.permissions.map((p) =>
            p.name.includes("Rufaqa")
              ? p.claims.map((c) =>
                  c.name === "CanModifyMembers"
                    ? setModifyMember(c.selectedOptions)
                    : c.name.includes("RequestMemberModification")
                    ? setRequestModifyMember(c.selectedOptions)
                    : c.name.includes("ModifyMemberType")
                    ? setModifyMemberType(c.selectedOptions)
                    : c.name.includes("CanMarkAsEx")
                    ? setMarkAsEx(c.selectedOptions)
                    : c.name.includes("RequestMarkRafiqAsEx")
                    ? setRequestMarkAsEx(c.selectedOptions)
                    : c.name.includes("CanModifyResponsibility")
                    ? setModifyResponsibility(c.selectedOptions)
                    : c.name.includes("CanRequestResponsibilityModification")
                    ? setRequestResponsibilityModification(c.selectedOptions)
                    : c.name.includes("RequestMemberTypeModification")
                    ? setRequestModifyMemberType(c.selectedOptions)
                    : c.name === "CanCancelMemberRegistration"
                    ? c.isSelected
                      ? setCancelRafiq(true)
                      : setCancelRafiq(false)
                    : c.name === "CanRequestMemberRegistrationCancelation"
                    ? c.isSelected
                      ? setRequestCancelRafiq(true)
                      : setRequestCancelRafiq(false)
                    : c.name === "CanAddMember"
                    ? c.isSelected
                      ? setAddRafiq(true)
                      : setAddRafiq(false)
                    : c.name === "CanRequestAddMember"
                    ? c.isSelected
                      ? setRequestAddRafiq(true)
                      : setRequestAddRafiq(false)
                    : null
                )
              : null
          );
          return item;
        });

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
        setLoading(false);
      });
  }, [
    memberId,
    claimGivenById,
    initialPermissions,
    updatePermission,
    loggedInUnitId,
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      setButtonDisable(true);
      ApiUser()
        .setMemberPermissions({
          memberId: memberId,
          givenByMemberId: claimGivenById,
          permissions: units,
          options: [],
        })
        .then((res) => {
          setUpdatePermissions(!updatePermission);
          swal({
            title: "Permissions Added Successfully!",
            icon: "success",
            buttons: "OK",
          });
          setLoading(false);
          setButtonDisable(false);
          history.goBack();
        })
        .catch((err) => {
          console.log(err.response.data);
          let responsibilityName = err.response.data.responsibilityName;
          let permissionName = err.response.data.permissionName;
          const isUrdu = GetSelectedLanguage() === "ur";

          swal({
            title: isUrdu
              ? `آپ منتخب شدہ نظم لیول کے لئے "${permissionName}" میں ذمہ داری "${responsibilityName}" نہیں دے سکتے `
              : `You cannot assign responsibility "${responsibilityName}" in "${permissionName}" for the selected unit level.`,
            icon: "error",
            buttons: "OK",
            className: isUrdu ? "reponsibility-modal-ur" : "reponsibility-modal"
          });

          setLoading(false);
          setButtonDisable(false);
        });
    }
  };

  const addObject = () => {
    const tempValues = [...units];
    tempValues.push({
      value: loggedInUnitId,
      label: "",
      isDisabled: false,
      userPermissions: {
        permissions: GetInitialPermissions(initialPermissions),
      },
    });
    setUnits(tempValues);
  };

  return (
    <>
      {!loading ? (
        <div className="panel-body">
          <div className="panel panel-primary">
            <div className="panel-heading">
              <Button
                color="dark"
                size="xs"
                className={GetCustomStyles().btn_style}
                onClick={() => {
                  addObject();
                }}
              >
                {<GetLanguageString props="unit_add_unit" />}
              </Button>
              {<GetLanguageString props="member_select_unit_and_permissions" />}
            </div>
            <div className="panel-body">
              <Form onSubmit={handleSubmit}>
                {units?.map((record, index) => {
                  return !record.isDisabled ? (
                    <SelectPermissions
                      memberId={memberId}
                      key={index}
                      id={index}
                      unitId={record.value}
                      setValues={setUnits}
                      values={units}
                      setModifyMemberType={setModifyMemberType}
                      setRequestModifyMemberType={setRequestModifyMemberType}
                      setModifyMember={setModifyMember}
                      setRequestModifyMember={setRequestModifyMember}
                      errors={errors}
                      addRafiq={addRafiq}
                      setAddRafiq={setAddRafiq}
                      cancelRafiq={cancelRafiq}
                      requestCancelRafiq={requestCancelRafiq}
                      requestAddRafiq={requestAddRafiq}
                      setRequestAddRafiq={setRequestAddRafiq}
                      setCancelRafiq={setCancelRafiq}
                      setRequestCancelRafiq={setRequestCancelRafiq}
                      setRequestMarkAsEx={setRequestMarkAsEx}
                      setMarkAsEx={setMarkAsEx}
                      setModifyResponsibility={setModifyResponsibility}
                      setRequestResponsibilityModification={setRequestResponsibilityModification}
                      loading={loading}
                      setLoading={setLoading}
                    />
                  ) : null;
                })}
                <Row>
                  <Col md="4">
                    <Button color="white" onClick={() => history.goBack()}>
                      {<GetLanguageString props="common_cancel" />}
                    </Button>
                    <Button
                      color="primary"
                      disabled={buttonDisable}
                      className="m-l-sm"
                      type="submit"
                    >
                      {<GetLanguageString props="common_save_changes" />}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      ) : (
        LoadingSpinner()
      )}
    </>
  );
};

function GetInitialPermissions(permissions) {
  const tempPermissions = [];

  permissions?.forEach((element) => {
    let temp = { name: "", claims: [] };
    temp.name = element.name;

    let tempClaims = [];
    element.claims?.forEach((element1) => {
      let claim = {
        name: "",
        isSelected: false,
        label: "",
        value: 0,
        selectedOptions: [],
        options: [],
      };

      claim.name = element1.name;
      claim.label = element1.label;
      claim.value = element1.value;
      if (element1.options !== null && element1.options !== undefined)
        claim.options = [...element1.options];

      tempClaims.push(claim);
    });

    temp.claims = [...tempClaims];

    tempPermissions.push(temp);
  });

  return tempPermissions;
}

export default withRouter(UserPermisions);
