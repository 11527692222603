import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { t } from "i18next";
import { Button, ButtonGroup, Row, Table } from "reactstrap";
import { GetLanguageString } from "../../helper/Components";
import { addMember } from "../ApiMember";
import moment from "moment";
import {
  LoadingSpinner,
  convertUTCToLocalTime,
} from "../../../constants/const";
import swal from "sweetalert";
import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import UpdatePledgesModel from "../MemberPledges/UpdatePledgesModel";
import AddMemberPledgeModal from "../MemberPledges/AddMemberPledges";

const UpdatePledges = ({ tab12, memberId, setDiscard, discard, activeTab }) => {
  const [pledges, setPledges] = useState();
  const [data, setData] = useState({
    id: 0,
    index: 0,
  });
  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [canAddPledge, setCanAddPledge] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (memberId !== 0) {
      setLoading(true);
      addMember()
        .getMemberPledgesById(memberId)
        .then((res) => {
          setPledges(res.data.pledges);
          setCanAddPledge(res.data.canAddPledge);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [memberId, refresh]);

  useEffect(() => {
    if (tab12 === "active") {
      if (activeTab && activeTab !== "tab12") {
        setDiscard(!discard);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const handleEdit = (id, index) => {
    setData({
      id: id,
      index: index,
    });
    setEditModal(true);
  };

  const handleDelete = (id) => {
    swal({
      title: t("common_delete_message"),
      text: t("common_delete_detail_message"),
      className: GetSelectedLanguage() === "en" ? "" : "ur",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        addMember()
          .deleteMemberPledges(id)
          .then(() => {
            swal({
              title: t("member_member_detail_delete_pledge_msg"),
              className: GetSelectedLanguage() === "en" ? "" : "ur",
              icon: "success",
              buttons: GetSelectedLanguage() === "en" ? "OK" : "ٹھیک ہے ",
            });
            setRefresh(!refresh);
            setLoading(false);
          })
          .catch((err) => {
            setRefresh(!refresh);
            setLoading(false);
          });
      }
    });
  };
  
  return (
    <div role="tabpanel" id="tab12" className={"tab-pane " + tab12}>
      {addModal && (
        <AddMemberPledgeModal
          refresh={refresh}
          memberId={memberId}
          setRefresh={setRefresh}
          setAddModal={setAddModal}
        />
      )}
      {editModal && (
        <UpdatePledgesModel
          pledges={pledges}
          data={data}
          refresh={refresh}
          setRefresh={setRefresh}
          setEditModal={setEditModal}
        />
      )}

      <div className="panel-body">
        {!loading ? (
          <>
            {canAddPledge ? (
              <Row>
                <div className={GetCustomStyles().btn_style}>
                  <Button
                    color="white"
                    size="sm"
                    className={GetCustomStyles().btn_style}
                    onClick={() => setAddModal(true)}
                  >
                    <i class="fa fa-plus"></i> &nbsp;
                    <GetLanguageString props="member_member_detail_add_pledge" />
                  </Button>
                </div>
              </Row>
            ) : null}
            <div className="table-responsive">
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th className="text-center">
                      {
                        <GetLanguageString props="member_member_detail_pledges_name" />
                      }
                    </th>
                    <th className="text-center">
                      {
                        <GetLanguageString props="member_member_detail_pledges_date" />
                      }
                    </th>
                    <th className="text-center">
                      {<GetLanguageString props="common_action" />}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {pledges?.map((record, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td className="ur text-center ">{record.pledgeName}</td>
                        <td className="text-center">
                          {record.pledgeDate
                            ? moment(
                                convertUTCToLocalTime(record.pledgeDate)
                              ).format("DD/MM/yyyy")
                            : "-"}
                        </td>
                        <td className="text-center">
                          <ButtonGroup>
                            <Button
                              color="white"
                              size="sm"
                              onClick={() => handleEdit(record.id, index)}
                            >
                              <GetLanguageString props="common_edit" />
                            </Button>{" "}
                            <Button
                              color="white"
                              size="sm"
                              onClick={() => handleDelete(record.id)}
                            >
                              <GetLanguageString props="common_delete" />
                            </Button>{" "}
                          </ButtonGroup>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </>
        ) : (
          LoadingSpinner()
        )}
      </div>
    </div>
  );
};

export default withRouter(UpdatePledges);
