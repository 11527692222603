import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Label,
  Button,
  Row,
  Input,
  FormFeedback,
} from "reactstrap";
import DatePicker from "react-datepicker";
import Draggable from "react-draggable";

import { GetLanguageString } from "../helper/Components";
import {
  convertToDateString,
  nonAlphanumericRegex,
} from "../../constants/const";

const ChangeUnitCode = ({
  setChangeUnitCodeModal,
  setUnitValues,
  unitValues,
  setServerError,
}) => {
  const toggle = () => setChangeUnitCodeModal(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [changeUnitCode, setChangeUnitCode] = useState({
    date: unitValues?.codeChangeDate,
    code: unitValues?.code,
    referenceNote: unitValues?.codeReferenceNote,
  });
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  const [errors, setErrors] = useState({
    code: "",
    date: "",
  });

  const validate = () => {
    let temp = {};
    temp.date = selectedDate ? (
      ""
    ) : (
      <GetLanguageString props="common_date_error_message" />
    );
    temp.code = changeUnitCode.code ? (
      changeUnitCode.code.length === 2 ? (
        ""
      ) : (
        <GetLanguageString props="unit_code_error_message1" />
      )
    ) : (
      <GetLanguageString props="unit_code_error_message" />
    );
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputDate = (value) => {
    if (value !== null) {
      if (value <= new Date()) {
        setSelectedDate(value);

        setErrors({
          date: "",
        });
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "code") {
      if (!nonAlphanumericRegex.test(value)) {
        setChangeUnitCode({
          ...changeUnitCode,
          code: value,
        });
        setErrors({
          code: "",
        });
      }
    } else {
      setChangeUnitCode({
        ...changeUnitCode,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setUnitValues({
        ...unitValues,
        codeChangeDate: convertToDateString(selectedDate),
        code: changeUnitCode.code,
        codeReferenceNote: changeUnitCode.referenceNote,
      });
      setServerError("");
      toggle();
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="unit_change_unit_code" />}
          </ModalHeader>
          <Form onSubmit={handleSubmit}>
            <ModalBody>
              <div className="ibox-content">
                <Row>
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="unit_unit_code" />}
                    </Label>
                    <Input
                      type="text"
                      name="code"
                      className="no-drag"
                      value={changeUnitCode?.code}
                      onChange={handleInputChange}
                      {...(errors.code && { invalid: true })}
                    />
                    {errors.code && (
                      <FormFeedback>
                        <h4>{errors.code}</h4>
                      </FormFeedback>
                    )}
                  </FormGroup>
                </Row>
                <Row>
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="member_edit_member_date" />}
                    </Label>
                    <DatePicker
                      placeholderText="dd/mm/yyyy"
                      className="form-control no-drag"
                      selected={selectedDate}
                      maxDate={new Date()}
                      onChange={handleInputDate}
                      dateFormat="dd/MM/yyyy"
                    />
                    {errors?.date && (
                      <h4 className="text-error">{errors?.date}</h4>
                    )}
                  </FormGroup>
                </Row>
                <Row>
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="member_reference_note" />}
                    </Label>
                    <Input
                      className="ur no-drag"
                      type="text"
                      name="referenceNote"
                      value={changeUnitCode?.referenceNote}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </Row>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="white" onClick={toggle}>
                {<GetLanguageString props="common_cancel" />}
              </Button>
              <Button color="primary" type="submit">
                {<GetLanguageString props="common_save_changes" />}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </Draggable>
    </div>
  );
};

export default ChangeUnitCode;
