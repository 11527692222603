import React, { useState } from "react";
import { encryptStorage } from "../../../constants/EncryptStorage";
import SearchEventAttendance from "./SearchEventAttendance";
import { GetLanguageString, NoResult } from "../../helper/Components";
import { useEffect } from "react";
import { ApiEvent } from "../ApiEvent";
import { Col, Row, Table } from "reactstrap";
import { LoadingSpinner } from "../../../constants/const";
import swal from "sweetalert";

const UnitWiseEventAttendance = () => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [loading, setLoading] = useState(false);
  const [searchButton, setSearchButton] = useState(false);
  const [data, setData] = useState({});

  let tempDate = new Date();
  tempDate.setUTCDate(1);
  const [searchEventWiseAttendanceModel, setSearchEventWiseAttendanceModel] =
    useState({
      unit: { value: loggedInUnitId, lable: "" },
      isSubUnits: true,
      startDate: tempDate,
      endDate: new Date(),
      eventTypeIds: [],
    });

  useEffect(() => {
    if (searchButton) {
      setLoading(true);
      ApiEvent()
        .GetUnitWiseEventAttendanceReport(searchEventWiseAttendanceModel)
        .then((res) => {
          setData(res.data.report);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
          setLoading(false);
        });
    }
  }, [searchButton, searchEventWiseAttendanceModel]);


  return (
    <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
      <div className="ibox">
        <div className="ibox-title">
          <h5>{<GetLanguageString props="common_generate_report" />}</h5>
        </div>
        <SearchEventAttendance
          searchEventWiseAttendanceModel={searchEventWiseAttendanceModel}
          setSearchEventWiseAttendanceModel={setSearchEventWiseAttendanceModel}
          setSearchButton={setSearchButton}
        />
      </div>
      {searchButton ? (
        <div className="ibox">
          <div className="ibox-title">
            <h5>{<GetLanguageString props="quick_event_reports_button2" />}</h5>
          </div>
          <div className="ibox-content">
            {!loading ? (
              data !== undefined &&
              data !== null &&
              Object.keys(data).length !== 0 ? (
                <div
                  className="panel-body ibox-center"
                  style={{ marginLeft: 100, marginRight: 100 }}
                >
                  <Table className="table-bordered m-b-none">
                    <thead>
                      <tr>
                        <th
                          style={{
                            border: "1px solid black",
                          }}
                        ></th>
                        <th
                          style={{
                            border: "1px solid black",
                          }}
                        >
                          <GetLanguageString props="report_member_mubtadi" />
                        </th>
                        <th
                          style={{
                            border: "1px solid black",
                          }}
                        >
                          <GetLanguageString props="report_member_multazim" />
                        </th>
                        <th
                          style={{
                            border: "1px solid black",
                          }}
                        >
                          <GetLanguageString props="report_member_mutazir" />
                        </th>
                        <th
                          style={{
                            border: "1px solid black",
                          }}
                        >
                          <GetLanguageString props="report_member_tawajah_talab" />
                        </th>
                        <th
                          style={{
                            border: "2px solid black",
                          }}
                        >
                          <GetLanguageString props="report_vacant_responsibilities_total" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => (
                        <tr key={index}>
                          <td
                            className="text-center ur"
                            style={{
                              border: "1px solid black",
                            }}
                          >
                            <Row>
                              <Col md="4"></Col>
                              <Col md="4">
                                <h3>{item.name} </h3>
                              </Col>
                              <Col md="1" className="m-1">
                                <span
                                  className="label label-success"
                                  style={{ borderRadius: "50%" }}
                                >
                                  {item.eventCounts}
                                </span>
                              </Col>
                              <Col md="4"></Col>
                            </Row>
                          </td>
                          <td
                            style={{
                              border:
                                item.name !== "ٹوٹل"
                                  ? "1px solid black"
                                  : "2px solid black",
                            }}
                          >
                            {item.mubtadi
                              ? item.mubtadi.toFixed(2) + "%"
                              : "0.0%"}
                          </td>
                          <td
                            style={{
                              border:
                                item.name !== "ٹوٹل"
                                  ? "1px solid black"
                                  : "2px solid black",
                            }}
                          >
                            {item.multazim
                              ? item.multazim.toFixed(2) + "%"
                              : "0.0%"}
                          </td>
                          <td
                            style={{
                              border:
                                item.name !== "ٹوٹل"
                                  ? "1px solid black"
                                  : "2px solid black",
                            }}
                          >
                            {item.mutazir
                              ? item.mutazir.toFixed(2) + "%"
                              : "0.0%"}
                          </td>
                          <td
                            style={{
                              border:
                                item.name !== "ٹوٹل"
                                  ? "1px solid black"
                                  : "2px solid black",
                            }}
                          >
                            {item.tawajahTalab
                              ? item.tawajahTalab.toFixed(2) + "%"
                              : "0.0%"}
                          </td>
                          <td
                            style={{
                              border: "2px solid black",
                            }}
                          >
                            {item.totalPercentage
                              ? item.totalPercentage.toFixed(2) + "%"
                              : "0.0%"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <NoResult />
              )
            ) : (
              LoadingSpinner()
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default UnitWiseEventAttendance;
