import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, Nav, NavLink, Row, TabContent } from "reactstrap";

import { FaRegMoneyBill1 } from "react-icons/fa6";
import { ImUserCheck } from "react-icons/im";
import { IoIosNotifications } from "react-icons/io";
import { MdAssignmentAdd } from "react-icons/md";
import { TbReportAnalytics } from "react-icons/tb";
import { CheckIsAdmin, CheckPermission } from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";
import { GetLanguageString } from "../helper/Components";
import { ApiUnit } from "../unit/ApiUnit";
import ExpectedMembersDashboard from "./ExpectedMembersDashboard";
import FundDetail from "./FundDetail";
import MemberDetail from "./MemberDetail";
import UnitEventDetail from "./UnitEventDetail";
import UpcomingCourses from "./UpcomingCourses";
import UpcomingEvents from "./UpComingEvents";

const Dashboard = ({ history }) => {
  const isIncharge = encryptStorage.getItem("canManage");
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [unit, setUnit] = useState({ value: loggedInUnitId, lable: "" });
  const [tab1, setTab1] = useState("");
  const [tab2, setTab2] = useState("");
  const [tab3, setTab3] = useState("");
  const [tab4, setTab4] = useState("");
  const [tab5, setTab5] = useState("");
  const [tab6, setTab6] = useState("");

  useEffect(() => {
    if (isIncharge) setTab1("active");
    else setTab4("active");
  }, [isIncharge]);

  useEffect(() => {
    if (CheckIsAdmin()) {
      ApiUnit()
        .getUnit(loggedInUnitId, loggedInUnitId)
        .then((res) => {
          let temp = res.data.unitList[0];
          let temp2 = { value: temp.id, lable: temp.name };
          setUnit(temp2);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [loggedInUnitId]);

  return (
    <div className="wrapper wrapper-content wrapper-custom">
      <Row>
        <Col md="12">
          <div className="tabs-container">
            <Nav tabs>
              {isIncharge ? (
                <>
                  <li>
                    <NavLink
                      to="#"
                      className={tab1}
                      onClick={() => {
                        setTab1("active");
                        setTab2("");
                        setTab3("");
                        setTab4("");
                        setTab5("");
                        setTab6("");
                      }}
                    >
                      {<GetLanguageString props="dashboard_quick_link" />}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="#"
                      className={tab2}
                      onClick={() => {
                        setTab1("");
                        setTab2("active");
                        setTab3("");
                        setTab4("");
                        setTab5("");
                        setTab6("");
                      }}
                    >
                      {
                        <GetLanguageString props="dashboard_members_type_details" />
                      }
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="#"
                      className={tab3}
                      onClick={() => {
                        setTab1("");
                        setTab2("");
                        setTab3("active");
                        setTab4("");
                        setTab5("");
                        setTab6("");
                      }}
                    >
                      {<GetLanguageString props="dashboard_events_details" />}
                    </NavLink>
                  </li>
                </>
              ) : null}
              <li>
                <NavLink
                  to="#"
                  className={tab4}
                  onClick={() => {
                    setTab1("");
                    setTab2("");
                    setTab3("");
                    setTab4("active");
                    setTab5("");
                    setTab6("");
                  }}
                >
                  <GetLanguageString props="courses_and_events" />
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="#"
                  className={tab5}
                  onClick={() => {
                    setTab1("");
                    setTab2("");
                    setTab3("");
                    setTab4("");
                    setTab5("active");
                    setTab6("");
                  }}
                >
                  <GetLanguageString props="ahbab" />
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="#"
                  className={tab6}
                  onClick={() => {
                    setTab1("");
                    setTab2("");
                    setTab3("");
                    setTab4("");
                    setTab5("");
                    setTab6("active");
                  }}
                >
                  <GetLanguageString props="sidebar_funds_infaq" />
                </NavLink>
              </li>
            </Nav>
            <TabContent>
              {isIncharge ? (
                <>
                  <div
                    role="tabpanel"
                    id="tab-1"
                    className={"tab-pane " + tab1}
                  >
                    <div className="panel-body">
                      <p>
                        {
                          <GetLanguageString props="dashboard_quick_link_message" />
                        }
                      </p>
                      <Row>
                        {CheckPermission("CanMarkEventAttendance") ? (
                          <Col xl="1" className="text-center">
                            <Button
                              className="btn-danger dim btn-large-dim"
                              onClick={() =>
                                history.push({
                                  pathname: `/event`,
                                  state: {
                                    unitId: unit.value,
                                  },
                                })
                              }
                            >
                              <ImUserCheck size={50} />
                            </Button>
                            <h4>
                              {
                                <GetLanguageString props="dashboard_quick_button1" />
                              }
                            </h4>
                          </Col>
                        ) : null}
                        {CheckPermission("CanAddEvents") ? (
                          <Col xl="1" className="text-center">
                            <Button
                              className="btn-primary dim btn-large-dim"
                              onClick={() =>
                                history.push({
                                  pathname: `event/addevent`,
                                  state: {
                                    unitId: unit.value,
                                  },
                                })
                              }
                            >
                              <MdAssignmentAdd size={50} />
                            </Button>
                            <h4>
                              {
                                <GetLanguageString props="dashboard_quick_button2" />
                              }
                            </h4>
                          </Col>
                        ) : null}
                        {CheckPermission("CanViewFunds") ? (
                          <Col xl="1" className="text-center">
                            <Button
                              className="btn-success dim btn-large-dim"
                              onClick={() =>
                                history.push({
                                  pathname: `/fundsmanagement/report/infaq`,
                                })
                              }
                            >
                              <FaRegMoneyBill1 size={50} />
                            </Button>
                            <h4>
                              {
                                <GetLanguageString props="dashboard_quick_button3" />
                              }
                            </h4>
                          </Col>
                        ) : null}
                        {CheckPermission("CanViewEvents") ? (
                          <Col xl="1" className="text-center">
                            <Button
                              className="btn-warning dim btn-large-dim"
                              onClick={() =>
                                history.push({
                                  pathname: `/eventReports`,
                                  state: {
                                    unitId: unit.value,
                                  },
                                })
                              }
                            >
                              <TbReportAnalytics size={50} />
                            </Button>
                            <h4>
                              {
                                <GetLanguageString props="dashboard_quick_button4" />
                              }
                            </h4>
                          </Col>
                        ) : null}
                        {CheckPermission("CanAddFunds") ? (
                          <Col xl="1" className="text-center">
                            <Button
                              className="btn-info dim btn-large-dim"
                              onClick={() =>
                                history.push({
                                  pathname: `/fundsmanagement`,
                                })
                              }
                            >
                              <FaRegMoneyBill1 size={50} />
                            </Button>
                            <h4>
                              {
                                <GetLanguageString props="dashboard_quick_button5" />
                              }
                            </h4>
                          </Col>
                        ) : null}
                        {/* <Col xl="1" className="text-center">
                          <Button
                            className="btn-danger dim btn-large-dim"
                            onClick={() =>
                              history.push({
                                pathname: `/updateBooks`,
                              })
                            }
                          >
                              <FaRegMoneyBill1 size={50} />
                          </Button>
                          <h4>
                            {
                              <GetLanguageString props="dashboard_quick_button6" />
                            }
                          </h4>
                        </Col>
                        <Col xl="1" className="text-center">
                          <Button
                            className="btn-primary dim btn-large-dim"
                            onClick={() =>
                              history.push({
                                pathname: `/meetingMembers`,
                              })
                            }
                          >
                            <i className="fa fa-users"></i>
                          </Button>
                          <h4>
                            {
                              <GetLanguageString props="dashboard_quick_button7" />
                            }
                          </h4>
                        </Col> */}
                        {CheckPermission("CanViewNazam") ? (
                          <Col xl="1" className="text-center">
                            <Button
                              className="btn-success dim btn-large-dim"
                              onClick={() =>
                                history.push({
                                  pathname: `/unit/createReports`,
                                })
                              }
                            >
                              <TbReportAnalytics size={50} />
                            </Button>
                            <h4>
                              {
                                <GetLanguageString props="dashboard_quick_button8" />
                              }
                            </h4>
                          </Col>
                        ) : null}
                        {!CheckIsAdmin() ? (
                          <Col xl="1" className="text-center">
                            <Button
                              className="btn-success dim btn-large-dim"
                              onClick={() =>
                                history.push({
                                  pathname: `/memberRequests`,
                                })
                              }
                            >
                              <IoIosNotifications size={50} />
                            </Button>
                            <h4>
                              {
                                <GetLanguageString props="dashboard_quick_button9" />
                              }
                            </h4>
                          </Col>
                        ) : null}
                      </Row>
                    </div>
                  </div>
                  <UnitEventDetail tempUnit={unit} tab3={tab3} />
                  <MemberDetail tempUnit={unit} tab2={tab2} />
                </>
              ) : null}
              <div role="tabpanel" id="tab-4" className={"tab-pane " + tab4}>
                <div className="panel-body">
                  <Row>
                    <Col md="6">
                      <UpcomingCourses tab4={tab4} />
                    </Col>
                    <Col md="6">
                      <UpcomingEvents tempUnit={unit} tab4={tab4} />
                    </Col>
                  </Row>
                </div>
              </div>
              <ExpectedMembersDashboard tab5={tab5} />
              <FundDetail tab6={tab6} />
            </TabContent>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(Dashboard);
