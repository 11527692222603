import {useState} from "react";

const useForm = (intialFieldValues) => {
    const [ values, setValues ]  = useState(intialFieldValues);
    const [serverError,setServerError] = useState();
    const [errors,setErrors] = useState(intialFieldValues);

    const handleInputChange = e => {
        setServerError(null);
        setErrors(intialFieldValues);

        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    };

    return{
        serverError,
        setServerError,
        errors,
        setErrors,
        values,
        setValues,
        handleInputChange
    }
}

export default useForm;