import React, { useState, useEffect } from "react";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const ImageDisplay = (props) => {
  const { imageToCrop, config, onImageCropped, autoCrop = true } = props;

  const [cropConfig, setCropConfig] = useState(config);
  const [imageRef, setImageRef] = useState();

  useEffect(() => {
    if (autoCrop) {
      cropImage();
    }
  // eslint-disable-next-line
  }, [autoCrop, imageToCrop, config]);

  async function cropImage() {
    if (imageRef && config.width && config.height) {
      const croppedImage = await getCroppedImage(
        imageRef,
        config,
        'croppedImage.jpeg'
      );

    onImageCropped(croppedImage, config);
    };
  };

  const getCroppedImage = (sourceImage, config, fileName) => {
    const canvas = document.createElement('canvas');
    
    canvas.width = config.width;
    canvas.height = config.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      sourceImage,
      config.scaleX,
      config.scaleY, 
      config.scaleWidth,
      config.scaleHeight,
      0,
      0,
      config.width,
      config.height,
    );
    
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            reject(new Error('Canvas is empty'));
            return;
          }
          blob.name = fileName;
          const croppedImageUrl = window.URL.createObjectURL(blob);
          resolve(croppedImageUrl);
        },
        'image/jpeg'
        );
      });
    }

  return (
    <div style={{ display: 'none'}}>
      {autoCrop ? (
        <img
          alt="Cropped Img"
          src={imageToCrop}
          ref={(img) => setImageRef(img)}
          onLoad={() => cropImage()}  // Automatically crop when the image is loaded
        />
        ) : (
        <ReactCrop
          src={imageToCrop}
          crop={cropConfig}
          ruleOfThirds
          onImageLoaded={(imageRef) => setImageRef(imageRef)}
          onComplete={(cropConfig) => cropImage(cropConfig)}
          onChange={(cropConfig) => setCropConfig(cropConfig)}
          crossorigin="anonymous"
        />
        )}
    </div>
  );
}

ImageDisplay.defaultProps = {
  onImageCropped: () => {},
  autoCrop: false,
};

export default ImageDisplay;
