import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, FormGroup, Label, Input, Button } from "reactstrap";

import { GetLanguageString } from "../helper/Components";
import { GetCustomStyles } from "../helper/Method";
import SelectUnit from "../shared/SelectUnit";

const SearchBooks = ({ searchMemberBooksModel, setSearchMemberBooksModel, unitId, history }) => {
  const [values, setValues] = useState(searchMemberBooksModel);
  const [excludeExUnit, setExcludeExUnit] = useState(true);
  const handleSubUnits = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setValues({
      ...values,
      isSubUnits: v,
    });
  };

  const handleSubmit = () => {
    if (values.unitId === 0) values.unitId = unitId;
    setSearchMemberBooksModel(values);
  };

  const handleExcludeExUnit = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setExcludeExUnit(v);
  };

  return (
    <div className="ibox ">
      <div className="ibox-title">
        <h5>{<GetLanguageString props="member_search_member" />}</h5>
        <Button
            color="primary"
            size="sm"
            className={GetCustomStyles().btn_style}
            onClick={() => history.goBack()}
          >
            {<GetLanguageString props="courses_participants_back" />}
          </Button>
      </div>
      <div className="ibox-content">
        <SelectUnit unitId={unitId} setValues={setValues} values={values} excludeExUnit={excludeExUnit} />
        <Row>
          <Col md="2">
            <FormGroup check>
              <Label check for="excludeExUnit">
                <Input
                  id="excludeExUnit"
                  type="checkbox"
                  name="excludeExUnit"
                  checked={!excludeExUnit}
                  value={!excludeExUnit}
                  onChange={handleExcludeExUnit}
                />
                {<GetLanguageString props="member_search_member_ex_unit" />}
              </Label>
            </FormGroup>
          </Col>
          <Col md="2">
            <FormGroup check>
              <Label check for="subUnit">
                <Input
                  id="subUnit"
                  type="checkbox"
                  name="isSubUnits"
                  checked={values.isSubUnits}
                  value={!values.isSubUnits}
                  onChange={handleSubUnits}
                />
                {<GetLanguageString props="member_search_member_sub_unit" />}
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <FormGroup>
              <Button
                color="primary"
                className="m-l-sm"
                type="submit"
                onClick={handleSubmit}
              >
                {<GetLanguageString props="common_search" />}
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default withRouter(SearchBooks);
