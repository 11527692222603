import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col, FormGroup, Label, Button, Input } from "reactstrap";

import { GetLanguageString } from "../helper/Components";
import { DateValidater } from "../helper/Method";
import SelectUnit from "../shared/SelectUnit";

const SearchSMSReport = ({ smsSerachValues, setSMSSerachValues }) => {
  const [values, setValues] = useState(smsSerachValues);
  const dateRangeValidation = {
    startDate: null,
    endDate: null,
  };
  const [dateValidations, setDateValidations] = useState(dateRangeValidation);
  const [excludeExUnit, setExcludeExUnit] = useState(true);

  const handleInputChangeStartDate = (value) => {
    if (DateValidater(value, values.endDate)) {
      setValues({
        ...values,
        startDate: value,
      });
      setDateValidations(dateRangeValidation);
    } else {
      setDateValidations({
        ...dateValidations,
        startDate: (
          <GetLanguageString props="common_start_date_validation_message" />
        ),
      });
    }
  };

  const handleInputChangeEndDate = (value) => {
    if (DateValidater(values.startDate, value)) {
      setValues({
        ...values,
        endDate: value,
      });
      setDateValidations(dateRangeValidation);
    } else {
      setDateValidations({
        ...dateValidations,
        endDate: (
          <GetLanguageString props="common_end_date_validation_message" />
        ),
      });
    }
  };

  const handleSubmit = () => {
    setSMSSerachValues(values);
  };

  const handleExcludeExUnit = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setExcludeExUnit(v);
  };

  return (
    <>
      <Row>
        <Col lg="6">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="courses_start_date" />}
            </Label>
            <DatePicker
              showYearDropdown
              placeholderText="dd/mm/yyyy"
              className="form-control"
              selected={values.startDate}
              onChange={handleInputChangeStartDate}
              dateFormat="dd/MM/yyyy"
            />
            {dateValidations.startDate && (
              <h4 className="text-error">{dateValidations.startDate}</h4>
            )}
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="courses_end_date" />}
            </Label>
            <DatePicker
              showYearDropdown
              placeholderText="dd/mm/yyyy"
              className="form-control"
              selected={values.endDate}
              onChange={handleInputChangeEndDate}
              dateFormat="dd/MM/yyyy"
            />
            {dateValidations.endDate && (
              <h4 className="text-error">{dateValidations.endDate}</h4>
            )}
          </FormGroup>
        </Col>
      </Row>
      <SelectUnit
        values={values}
        setValues={setValues}
        unitId={values.unit.value}
        excludeExUnit={excludeExUnit}
      />
      <Row>
        <Col md="4">
          <FormGroup check>
            <Label check for="excludeExUnit">
              <Input
                id="excludeExUnit"
                type="checkbox"
                name="excludeExUnit"
                checked={!excludeExUnit}
                value={!excludeExUnit}
                onChange={handleExcludeExUnit}
              />
              {<GetLanguageString props="member_search_member_ex_unit" />}
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <FormGroup>
            <Button
              color="primary"
              className="m-l-sm"
              type="submit"
              onClick={handleSubmit}
            >
              {<GetLanguageString props="common_view_report" />}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default SearchSMSReport;
