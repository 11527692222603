import React from "react";
import moment from "moment";
import 'moment/locale/ur';
import { convertUTCToLocalTime } from "../../constants/const";

const PrintMember = ({memberValues, pledges, courseList, assessments, books, docsNames, croppedImage}) => {
moment.locale('ur');

const unitName = memberValues?.currentUnitAndResponsibility?.unitName && memberValues?.currentUnitAndResponsibility?.unitName.replace('،','/');
const currentUnitName = unitName && unitName.split('،');
let baitform = [
  {
    fileName: "Multazim Bait Form Front",
    isUploaded: 0,
    label: "ملتزم بیعت فارم فرنٹ"
  },
  {
    fileName: "Multazim Bait Form Back",
    isUploaded: 0,
    label: "ملتزم بیعت فارم بیک"
  },
  {
    fileName: "Kawaif Form",
    isUploaded: 0,
    label: "کوائف فارم"
  },
  {
    fileName: "Mubtadi Bait Form Front",
    isUploaded: 0,
    label: "مبتدی بیعت فارم فرنٹ"
  },
  {
    fileName: "Mubtadi Bait Form Back",
    isUploaded: 0,
    label: "مبتدی بیعت فارم بیک"
  }
];

if (docsNames) {
  for (var d of docsNames) {
    for (var b of baitform) {
      if (d.fileName === b.fileName)
        b.isUploaded = 1
    }
  }
}

return(
    <div id="section-to-print" style={{
        width: "100%",
        padding: '0.15in 0.4in',
        background: '#fff',
        color: '#000',
        fontSize:'13px'
    }}
    className="print" 
    >
        <div style={{direction : 'rtl'}} className="ur">
            <div className="row m-0">
                <div className="col-12 p-0">
                    <div className="d-flex justify-content-between">
                    <div className="mr-3">
                        <h1 className="mb-2 mt-1" style={{fontSize: '40px' }}>{memberValues?.fullName}</h1>
                        <p className="mb-0">
                        <small style={{fontSize: '22px', fontWeight: '400' }}>
                            ولدیت:{memberValues?.fatherName}
                        </small>
                        </p>
                        <div className="details">
                        <table>
                            <tbody>
                            <tr>
                                <td style={{direction: 'ltr'}}>، {memberValues?.bloodGroup}</td>
                                <td> عمر {new Date(memberValues?.age).getUTCFullYear() - 1970} سال، </td>
                                <td>{memberValues?.maritalStatus} {memberValues?.sons && "، " + memberValues?.sons + "بیٹے"} {memberValues?.daughters && "، " + memberValues?.daughters + "بیٹی"}</td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    <div className="mr-3">
                        <div className="details text-center">
                        <p className="mb-0" style={{
                            direction:'ltr'
                        }}>
                            <strong>{memberValues?.regNo}</strong>:رجسٹریشن نمبر
                        </p>
                        <p className="mb-0">
                            <span>{memberValues?.memberType}</span>&nbsp; / &nbsp;<span>{memberValues.responsibilityName}</span>&nbsp; / &nbsp; 
                            <span>{currentUnitName && currentUnitName[0]}</span>&nbsp;
                        </p>
                        <p className="mb-0">
                            <span>خط برائے طلب اصلاح:{memberValues?.letterForCounsellingList[0]?.title}/{currentUnitName && currentUnitName[0]}</span>
                        </p>
                        <p className="mb-0">
                            <span>تاریخ رجسٹریشن:{memberValues?.joiningDate && moment(convertUTCToLocalTime(memberValues?.joiningDate)).format('LL')}</span>
                        </p>
                        </div>
                    </div>
                    <div>
                        <ul className="list-unstyled small">
                        <li className="d-flex justify-content-between align-items-center">
                            <span>مرکزی مدرس</span>
                            <span>
                            <span className="fa fa-times text-muted"></span>
                            </span>
                        </li>
                        <li className="d-flex justify-content-between align-items-center">
                            <span>رجسٹرڈ مدرس</span>
                            <span>
                            <span className="fa fa-times text-muted"></span>
                            </span>
                        </li>
                        {assessments?.length > 0 ? (
                            assessments?.map((record, index) => {
                                return (
                                    <li key={index} className="d-flex justify-content-between align-items-center">
                                        <span>{record.assessmentName}</span>
                                        <span>{record.assessmentStatus}</span>
                                    </li>
                                );
                              })

                        ): (
                            <>
                                <li className="d-flex justify-content-between align-items-center">
                                    <span>عربی ٹیسٹ</span>
                                    <span>
                                    <i className="fa fa-times text-muted"></i>
                                    </span>
                                </li>
                                <li className="d-flex justify-content-between align-items-center">
                                    <span>تجوید ٹیسٹ</span>
                                    <span>
                                    <i className="fa fa-times text-muted"></i>
                                    </span>
                                </li>
                            </>
                        )}
                        {books?.length > 0 ? (
                            books?.map((record, index) => {
                                return (
                                    <li key={index} className="d-flex justify-content-between align-items-center">
                                        <span>{record.bookName}</span>
                                        <span>
                                        <i className="fa fa-check"></i>
                                        </span>
                                    </li>
                                );
                              })
                        ) : (
                            <li className="d-flex justify-content-between align-items-center">
                                <span> ملتزم نصاب مکمل</span>
                                <span>
                                <i className="fa fa-times text-muted"></i>
                                </span>
                            </li>
                        )}
                        </ul>
                    </div>
                    {croppedImage ?
                        <div style={{marginRight: -30}}>
                            <img
                              src={croppedImage}
                              width={100}
                              height={110}
                              alt="Uploaded"
                            />
                        </div>
                    : null}
                    </div>
                </div>
            </div>
            <div className="row m-0" style={{borderTop: "1px solid black"}}>
                <div className="col-9 p-0">
                    <div className="row m-0">
                    <div className="col-3 p-0">
                        <div className="card border-none border-none m-0">
                        <div className="body p-2">
                            <label className="title">حیثیت</label>
                            <ul className="list-unstyled">
                                {memberValues.memberTypeHistoryList?.map((record, index) => {
                                    return(
                                        <>
                                        <li key={index} className="d-flex justify-content-between align-items-center small">
                                            <span>{record.type}</span>
                                            <span className="small">{moment(convertUTCToLocalTime(record?.date)).format('LL')}</span>
                                        </li>
                                        </>
                                    )
                                })}
                            </ul>
                        </div>
                        </div>
                        <div className="card border-none m-0"
                            style={{
                                width: '204px',
                                paddingTop: '24px'
                            }}
                        >
                        <div className="body p-2"
                        >
                            <label className="title">بیعت</label>
                            <ul className="list-unstyled">
                                {pledges?.map((record, index) => {
                                    return (
                                        <li key={index} className="d-flex justify-content-between align-items-center small"

                                        >
                                            <span>{record.pledgeName}</span>
                                            <span>
                                                {moment(convertUTCToLocalTime(record?.pledgeDate)).format('LL')}
                                            </span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        </div>
                    </div>
                    <div className="col-4 p-0">
                        <div className="card border-none m-0">
                        <div className="body p-2">
                            <label className="title">نظم کی تفصیل</label>
                            <ul className="list-unstyled">
                                {memberValues?.memberUnitList?.map((record, index) => {
                                    return(
                                        <li key={index} className="d-flex justify-content-between align-items-center small">
                                            <span>{record.unitName}</span>
                                            <span className="small">{record.unitDate ? moment(convertUTCToLocalTime(record?.unitDate)).format('LL') : ""}</span>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        </div>
                    </div>
                    <div className="col-2 p-0">
                        <div className="card border-none m-0">
                        <div className="body p-2">
                            <label className="title">ملک / شہر</label>
                            <ul className="list-unstyled">
                            <li className="d-flex justify-content-between align-items-center small">
                                <span>{memberValues?.country}</span>
                                <span className="small">{memberValues?.countryDate && moment(convertUTCToLocalTime(memberValues?.countryDate)).format('LL')}</span>
                            </li>
                            </ul>
                        </div>
                        </div>
                    </div>
                    <div className="col-3 p-0">
                        <div className="card border-none m-0">
                        <div className="body p-2">
                            <small>
                            <label className="title">
                                موصول شدہ خطوط
                                {memberValues?.letterForCounsellingList?.length >= 5 ? (<span class="small"> (آخری 5)</span>) : null}
                            </label>
                            <ul className="list-unstyled" style={{lineHeight:1.2}}>
                                {memberValues?.letterForCounsellingList?.map((record, index) => {
                                    return(
                                        <li key={index} className="d-flex justify-content-between align-items-center small">
                                            <span>{record?.title}</span>
                                            <span>{record?.month}</span>
                                            <span>{record?.year}</span>
                                        </li>
                                    )
                                })}
                            </ul>
                            </small>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="row m-0" style={{borderTop: "1px solid black"}}>
                    <div className="col-auto p-0">
                        <div className="card border-none m-0">
                        <div className="body p-2">
                            <label className="title">موجودہ ذمہ داریاں</label>
                            <table className="table-sm text-dark">
                                <tbody>
                                    {memberValues?.memberResponsibilityList?.filter(record => record.toDate === null).map((record, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <span className="fa fa-check-circle-o"></span>
                                                </td>
                                                
                                                <td className="small">{moment(convertUTCToLocalTime(record?.fromDate)).format('LL')}</td>
                                                <td>{record?.responsibilityName + " " + record?.unitName}   </td>
                                            </tr>
                                        )
                                    }) }
                                </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
                    <div className="col-auto p-0">
                        <div className="card border-none m-0">
                        <div className="body p-2">
                            <label className="title">سابقہ ذمہ داریاں</label>
                            <table className="table-sm text-dark">
                                <tbody>
                                    {memberValues?.memberResponsibilityList?.filter(record => record.toDate !== null).map((record, index) => {
                                        return (
                                          <tr key={index}>
                                            <td>
                                                <span className="fa fa-times-circle-o"></span>
                                            </td>
                                            <td className="small text-right"> {record.fromDate !== null ? moment(convertUTCToLocalTime(record.fromDate)).format('LL') : ""}  تا {record.toDate !== null ? moment(convertUTCToLocalTime(record.toDate)).format('LL') : ""}</td>
                                            <td> {record.responsibilityName}    {record.unitName}</td>
                                          </tr>
                                        );
                                      })}
                                </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="card border-none m-0" style={{borderTop: "1px solid black"}}>
                    <div className="body p-2">
                        <label className="title">تربیتی کورس</label>
                        <table className="table-sm text-dark" style={{lineHeight:1}}>
                        <tbody>
                            {courseList?.map((item, index) => {
                                return (
                                    <div key={index}>
                                        {item.details
                                        .sort(function (a, b) {
                                            if (a.startDate < b.startDate)
                                            return 1;
                                            if (a.startDate > b.startDate)
                                            return -1;
                                            return 0;
                                        })
                                        .map((record, index) => {
                                            return (
                                            <tr key={index}>
                                                <td className="small">
                                                {record.courseStatus ? (
                                                    <span className="fa fa-check"></span>
                                                ) : (
                                                    <span className="fa fa-times"></span>
                                                )}
                                                </td>
                                                <td className="small" width="110">
                                                {moment(convertUTCToLocalTime(record?.startDate)).format('LL')}
                                                </td>
                                                <td>
                                                    <span className="mr-2 small">{record?.courseName}</span>
                                                </td>
                                                <td></td>
                                                <td style={{paddingRight: 30}}>
                                                    <span className="mr-2 small" >{record?.comments}</span>
                                                </td>
                                            </tr>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>
                <div
                    className="col-3 p-0"
                    style={{borderRight: "1px solid black"}}
                >
                    <div className="card border-none m-0">
                    <div className="body p-2">
                        <ul className="list-unstyled mb-0">
                        <li className="d-flex justify-content-between align-items-center small">
                            <span className="mr-2">
                            <i className="fa fa-tablet"></i>
                            </span>
                            <span className="ltr">{memberValues?.phone}</span>
                        </li>
                        <li className="d-flex justify-content-between align-items-center small">
                            <span className="mr-2">
                            <small>
                                <i className="fa fa-envelope-o"></i>
                            </small>
                            </span>
                            <span className="ltr">{memberValues?.email}</span>
                        </li>
                        </ul>
                    </div>
                    <div className="body p-2">
                        <ul className="list-unstyled">
                        <li className="d-flex align-items-top small mb-1">
                            موجودہ پتہ :
                            <div className="rtl text-left" style={{lineHeight:1.3}}>
                           {memberValues?.currentAddress}
                            </div>
                        </li>
                        <li className="d-flex align-items-top small mb-1">
                            مستقل پتہ :
                            <div className="rtl text-left" style={{lineHeight:1.3}}>
                            {memberValues?.permanentAddress}
                            </div>
                        </li>
                        </ul>
                    </div>
                    <div className="body p-2">
                        <label className="title">پیشہ</label>
                        <table className="table-sm text-dark">
                        <tbody>
                            <tr>
                            <td className="small">{memberValues.occupation}</td>
                            </tr>
                        </tbody>
                        </table>
                        <label className="title">تعلیم</label>
                        <table className="table-sm text-dark">
                        <tbody>
                            <tr>
                            <td className="small">{memberValues.education}</td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    <div className="body p-2">
                        <label className="title">دستاویزات</label>
                        <ul className="list-unstyled small">
                            {baitform?.length > 0 &&
                            baitform?.map((record, index) => {
                                return (
                                    <li
                                      style={{
                                        fontSize: '10px'
                                      }} 
                                      className="d-flex justify-content-between align-items-center"
                                      key={index} 
                                    >
                                        <span>{record.label}</span>
                                        <span>
                                            <i className={ record.isUploaded ? "fa fa-check text-muted" : "fa fa-times text-muted" }></i>
                                        </span>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)
}

export default PrintMember;