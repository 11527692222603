import React, { useEffect, useState } from "react";
import { Table, Button, Label, Input, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";

import { GetLanguageString, NoResult } from "../helper/Components";
import { GetCustomStyles, GetSelectedLanguage } from "../helper/Method";
import { ApiEvent } from "./ApiEvent";
import { LoadingSpinner } from "../../constants/const";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import Switch from "react-switch";

const EventParticipants = ({
  renderParticipants,
  searchEventParticipants,
  setSearchEventParticipants,
  activeTab,
  currentRow,
  updatedList,
  setUpdatedList,
  loading,
  setButtonDisabled,
  setShowStatusDropDown,
  setRufqaPercentage,
  setAhbabPercentage,
  status,
}) => {
  const [pagination, setPagination] = useState({ activePage: 1 });
  const [loadingInner, setLoadingInner] = useState(false);
  const selectedLanguage = GetSelectedLanguage();

  let absent = false;
  let present = false;
  let leave = false;
  const [participants, setParticipants] = useState([]);

  // Function to check if an entry exists in the updated list
  const entryExists = (entry) => {
    return updatedList.some((item) => item.memberId === entry.memberId);
  };

  // Function to update or add entries to the updated list
  const updateOrAddEntry = (entry) => {
    const index = updatedList.findIndex(
      (item) => item.memberId === entry.memberId
    );
    if (index !== -1) {
      // Entry exists, check if values have changed
      const existingEntry = updatedList[index];
      const valuesChanged = Object.keys(entry).some(
        (key) => entry[key] !== existingEntry[key]
      );
      if (valuesChanged) {
        updatedList[index] = { ...existingEntry, ...entry };
      }
    } else {
      // Entry doesn't exist, add it
      updatedList.push(entry);
    }
  };

  const handleAttendanceStatus = (status, index) => {
    let tempValues = [...participants];
    tempValues[index].attendanceStatus = status;
    setParticipants(tempValues);

    if (entryExists(tempValues[index])) {
      updateOrAddEntry(tempValues[index]);
    } else {
      setUpdatedList((prevList) => [...prevList, tempValues[index]]);
    }
  };

  const handleSearchAttendanceStatus = (s) => {
    setSearchEventParticipants({
      ...searchEventParticipants,
      attendanceStatus: s,
    });
  };

  const handleOnTime = (e, index) => {
    let tempValues = [...participants];
    tempValues[index].onTime = e;
    setParticipants(tempValues);

    if (entryExists(tempValues[index])) {
      updateOrAddEntry(tempValues[index]);
    } else {
      setUpdatedList((prevList) => [...prevList, tempValues[index]]);
    }
  };
  const handlePageChange = (pageNumber) => {
    setSearchEventParticipants({
      ...searchEventParticipants,
      activePage: pageNumber,
    });
    setPagination({
      ...pagination,
      activePage: pageNumber,
    });
  };

  useEffect(() => {
    if (activeTab === "rufqa") {
      if (searchEventParticipants.type === "ahbab") {
        searchEventParticipants.activePage = 1;
      }
      searchEventParticipants.type = "rufqa";
      setLoadingInner(true);
      setButtonDisabled(true);
      ApiEvent()
        .getEventParticipants(searchEventParticipants)
        .then((res) => {
          setPagination(res.data);
          let newList = [];
          res.data.eventParticipantsModelList.map((record) => {
            const updatedItem = updatedList.find(
              (item) =>
                item.eventId === record.eventId &&
                item.memberId === record.memberId
            );

            if (updatedItem) newList.push(updatedItem);
            else newList.push(record);

            return null;
          });
          setParticipants(newList);
          setLoadingInner(false);
          setButtonDisabled(
            res.data.eventParticipantsModelList.length !== 0 ? false : true
          );
          setShowStatusDropDown(!res.data.isFirstTime);
          setRufqaPercentage(
            res.data.rufqaPercentage === "NaN" ? 0.0 : res.data.rufqaPercentage
          );
          setAhbabPercentage(
            res.data.ahbabPercentage === "NaN" ? 0.0 : res.data.ahbabPercentage
          );
        })
        .catch((err) => {
          setLoadingInner(false);
          setButtonDisabled(false);
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
        });
    }
    // eslint-disable-next-line
  }, [searchEventParticipants, currentRow, renderParticipants, activeTab]);

  let recordIndex =
    searchEventParticipants.pageSize *
      (searchEventParticipants.activePage - 1) +
    1;

  let pageInfo = `Displaying ${recordIndex}-${
    recordIndex + pagination.pageSize - 1 < pagination.totalItem
      ? recordIndex + pagination.pageSize - 1
      : pagination.totalItem
  } of ${pagination.totalItem}`;

  return (
    <>
      {" "}
      <Row>
        <Col md="10">
          <Row style={{ marginLeft: "20px", marginTop: "5px" }}>
            {status.map((item, index) => {
              return (
                <Col md="1">
                  <Label
                    className="form-check form-check-inline"
                    for={"statusRufqaCheck" + item.value}
                  >
                    <Input
                      id={"statusRufqaCheck" + item.value}
                      type="radio"
                      checked={
                        item.value === searchEventParticipants.attendanceStatus
                      }
                      value={searchEventParticipants.attendanceStatus}
                      name={"statusRafiq" + item.value}
                      onChange={() => handleSearchAttendanceStatus(item.value)}
                      className="form-check-input"
                    />
                    {<GetLanguageString props={item.label} />}
                  </Label>
                </Col>
              );
            })}
          </Row>
        </Col>
        <Col md="2"></Col>
      </Row>
      <hr></hr>
      <div>
        {!loading && !loadingInner ? (
          participants.length !== 0 ? (
            <>
              <div className="table-responsive">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{<GetLanguageString props="common_name" />}</th>
                      <th>{<GetLanguageString props="member_reg_number" />}</th>
                      <th className="text-center">
                        {<GetLanguageString props="settings_member_type" />}
                      </th>
                      <th className="text-center">
                        {<GetLanguageString props="unit_unit_table" />}
                      </th>
                      <th className="text-center">
                        {<GetLanguageString props="common_attendance" />}
                      </th>
                      <th>
                        {
                          <GetLanguageString props="events_participants_time_status" />
                        }
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {participants?.map((record, index) => {
                      let unitName = record.memberUnitName?.split("/");
                      return (
                        <tr key={index}>
                          <td>{index + recordIndex}</td>
                          <td className="ur">
                            <h4>{record.name}</h4>
                          </td>
                          <td>{record.memberRegNo}</td>
                          <td className="text-center ur">
                            {record.memberType}
                          </td>{" "}
                          <td className="ur">
                            <ol className="breadcrumb">
                              {unitName.map((item, index) => {
                                return (
                                  <li
                                    className={
                                      GetSelectedLanguage() === "ur"
                                        ? "breadcrumb-item-ur"
                                        : "breadcrumb-item"
                                    }
                                    key={index}
                                  >
                                    <Button
                                      size="xs"
                                      color="info"
                                      className="ur"
                                    >
                                      {item}
                                    </Button>
                                  </li>
                                );
                              })}
                            </ol>
                          </td>
                          <td className="text-center">
                            <Label
                              className="form-check form-check-inline"
                              for={"absentRafiq" + record.memberId}
                            >
                              {record.attendanceStatus === 0
                                ? (absent = true)
                                : null}
                              <Input
                                id={"absentRafiq" + record.memberId}
                                type="radio"
                                checked={absent}
                                name={"status" + record.memberId}
                                onChange={() =>
                                  handleAttendanceStatus(0, index)
                                }
                                className="form-check-input"
                              />
                              {
                                <GetLanguageString props="events_participants_attendance_absent" />
                              }
                            </Label>
                            <Label
                              className="form-check form-check-inline"
                              for={"presentRafiq" + record.memberId}
                            >
                              {record.attendanceStatus === 1
                                ? (present = true)
                                : null}
                              <Input
                                id={"presentRafiq" + record.memberId}
                                type="radio"
                                checked={present}
                                name={"status" + record.memberId}
                                onChange={() =>
                                  handleAttendanceStatus(1, index)
                                }
                                className="form-check-input"
                              />
                              {
                                <GetLanguageString props="events_participants_attendance_present" />
                              }
                            </Label>
                            <Label
                              className="form-check form-check-inline"
                              for={"leaveRafiq" + record.memberId}
                            >
                              {record.attendanceStatus === 2
                                ? (leave = true)
                                : null}
                              <Input
                                id={"leaveRafiq" + record.memberId}
                                checked={leave}
                                type="radio"
                                name={"status" + record.memberId}
                                onChange={() =>
                                  handleAttendanceStatus(2, index)
                                }
                                className="form-check-input"
                              />
                              {
                                <GetLanguageString props="events_participants_attendance_leave" />
                              }
                            </Label>
                          </td>
                          <td>
                            <Label
                              htmlFor="small-radius-switch"
                              for={"onTimeRafiq" + record.memberId}
                            >
                              <Switch
                                id={
                                  "small-radius-switch onTimeRafiq" +
                                  record.memberId
                                }
                                disabled={
                                  record.attendanceStatus === 1 ? false : true
                                }
                                checked={record.onTime}
                                onChange={(e) => handleOnTime(e, index)}
                                handleDiameter={32}
                                offColor="#888888"
                                onColor="#6fcab8"
                                offHandleColor="#fff"
                                onHandleColor="#18A689"
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={25}
                                width={90}
                                borderRadius={20}
                                uncheckedIcon={
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "100%",
                                      color: "white",
                                      paddingRight: 25,
                                      fontSize:
                                        selectedLanguage === "ur" ? 25 : 15,
                                      paddingBottom:
                                        selectedLanguage === "ur" ? 10 : 0,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <GetLanguageString
                                      props={"events_participants_late"}
                                    />
                                  </div>
                                }
                                checkedIcon={
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "100%",
                                      width: "60px",
                                      color: "white",
                                      paddingLeft: 5,
                                      fontSize:
                                        selectedLanguage === "ur" ? 19 : 12,
                                      paddingBottom:
                                        selectedLanguage === "ur" ? 5 : 0,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <GetLanguageString
                                      props={"events_participants_on_time"}
                                    />
                                  </div>
                                }
                                className="react-switch"
                              />
                            </Label>
                          </td>
                          {
                            ((absent = false),
                            (present = false),
                            (leave = false))
                          }
                        </tr>
                      );
                    })}
                  </tbody>
                  <tr>
                    <td colSpan="2">{pageInfo}</td>
                    <td colSpan="11">
                      <ul className={GetCustomStyles().page_style}>
                        <Pagination
                          activePage={pagination.activePage}
                          itemsCountPerPage={pagination.pageSize}
                          totalItemsCount={pagination.totalItem}
                          pageRangeDisplayed={pagination.pageRange}
                          onChange={handlePageChange}
                        />
                      </ul>
                    </td>
                  </tr>
                </Table>
              </div>
            </>
          ) : (
            <NoResult />
          )
        ) : (
          LoadingSpinner()
        )}
      </div>
    </>
  );
};

export default withRouter(EventParticipants);
