import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { GetLanguageString, NoResult } from "../../helper/Components";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { addMember, setupApi } from "../ApiMember";
import { withRouter } from "react-router-dom";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import { isEqual } from "lodash";
import {
  CheckIsAdmin,
  CheckPermission,
  LoadingSpinner,
  convertUTCToLocalTime,
  isUrdu,
  customOption,
  convertToDateString,
} from "../../../constants/const";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ApiEducation } from "../../config/education/ApiEducation";

const UpdateEducationOccupationSkill = ({
  history,
  tab5,
  memberId,
  addNewRafiq,
  activeTab,
  setActiveTab,
  setDiscard,
  discard,
}) => {
  const notify = (string) => toast.success(string);
  const { t } = useTranslation();
  const [memberEducationDegreeList, setMemberEductationDegreeList] = useState();
  const [memberOccupationList, setMemberOccupationList] = useState();
  const [memberSkillsList, setMemberSkillsList] = useState();
  const [loadingEducation, setLoadingEducation] = useState(false);
  const [reloadEducation, setReloadEducation] = useState(false);
  const [loadingOccupation, setLoadingOccupation] = useState(false);
  const [reloadOccupation, setReloadOccupation] = useState(false);
  const [loadingSkill, setLoadingSkill] = useState(false);
  const [educationFieldDisable, setEducationFieldDisable] = useState(false);
  const [reloadSkill, setReloadSkill] = useState(false);
  const [educationDates, setEducationDates] = useState([
    {
      fromYear: "",
      toYear: "",
    },
  ]);
  const [memberEducationList, setMemberEductationList] = useState([
    {
      educationId: 0,
      educationType: {},
      educationField: {
        value: 0,
        label: "",
      },
      educationFieldList: [],
      toYear: "",
      fromYear: "",
      institution: "",
      deletable: false,
      edited: false,
    },
  ]);

  const [educationTestList, setEducationTestList] = useState([
    {
      educationId: 0,
      educationType: {},
      educationField: {
        value: 0,
        label: "",
      },
      educationFieldList: [],
      toYear: "",
      fromYear: "",
      institution: "",
      deletable: false,
      edited: false,
    },
  ]);
  const [memberOccupations, setMemberOccupations] = useState([
    {
      occupationId: 0,
      occupationType: {},
      description: "",
      referenceNote: "",
      deletable: false,
      edited: false,
    },
  ]);
  const [testOccupationsList, setTestOccupationsList] = useState([
    {
      occupationId: 0,
      occupationType: {},
      description: "",
      referenceNote: "",
      deletable: false,
      edited: false,
    },
  ]);

  const [memberSkills, setMemberSkills] = useState([
    {
      skillId: 0,
      skillType: {},
      description: "",
      referenceNote: "",
      deletable: false,
      edited: false,
    },
  ]);
  const [testSkillsList, setTestSkillsList] = useState([
    {
      skillId: 0,
      skillType: {},
      description: "",
      referenceNote: "",
      deletable: false,
      edited: false,
    },
  ]);
  const [errorsEducation, setErrorsEducation] = useState([
    {
      educationType: "",
    },
  ]);

  const validateEducation = () => {
    let temp = [];
    memberEducationList.map((item, index) => {
      let tempErrors = {};
      if (!item.deletable) {
        tempErrors.educationType =
          Object.keys(item.educationType).length !== 0 ? (
            ""
          ) : (
            <GetLanguageString props="member_member_details_education_error_message" />
          );

        temp.push(tempErrors);
      } else {
        temp.push({
          educationType: "",
        });
      }
      return tempErrors;
    });
    setErrorsEducation(temp);
    return allAreTrue(
      temp.map((item) => {
        return Object.values(item).every((x) => x === "");
      })
    );
  };

  const [errorsOccupation, setErrorsOccupation] = useState([
    {
      occupationType: "",
    },
  ]);

  const validateOccupation = () => {
    let temp = [];
    memberOccupations.map((item, index) => {
      let tempErrors = {};
      if (!item.deletable) {
        tempErrors.occupationType =
          Object.keys(item.occupationType).length !== 0 ? (
            ""
          ) : (
            <GetLanguageString props="member_member_details_occupation_error_message" />
          );

        temp.push(tempErrors);
      } else {
        temp.push({
          occupationType: "",
        });
      }
      return tempErrors;
    });
    setErrorsOccupation(temp);
    return allAreTrue(
      temp.map((item) => {
        return Object.values(item).every((x) => x === "");
      })
    );
  };
  function allAreTrue(arr) {
    return arr.every((element) => element === true);
  }
  const [errorsSkill, setErrorsSkill] = useState([
    {
      skillType: "",
    },
  ]);
  const validateSkill = () => {
    let temp = [];
    memberSkills.map((item, index) => {
      let tempErrors = {};
      if (!item.deletable) {
        tempErrors.skillType =
          Object.keys(item.skillType).length !== 0 ? (
            ""
          ) : (
            <GetLanguageString props="member_member_details_skills_error_message" />
          );

        temp.push(tempErrors);
      } else {
        temp.push({
          skillType: "",
        });
      }
      return tempErrors;
    });
    setErrorsSkill(temp);
    return allAreTrue(
      temp.map((item) => {
        return Object.values(item).every((x) => x === "");
      })
    );
  };

  // getting educations list
  useEffect(() => {
    if (tab5 === "active") {
      setLoadingEducation(true);
      addMember()
        .getMemberEducationDetail(memberId)
        .then((res) => {
          let educations = [];
          let tempEducations = [];
          let tempEducationDates = [];
          res.data.educations.forEach((e) => {
            tempEducations.push({
              ...e,
              toYear: e.toYear ? new Date(convertUTCToLocalTime(e.toYear)) : "",
              fromYear: e.fromYear
                ? new Date(convertUTCToLocalTime(e.fromYear))
                : "",
              educationFieldList:
                e.educationFieldList != null ? e.educationFieldList : [],
            });

            educations.push({
              ...e,
              toYear: e.toYear ? new Date(convertUTCToLocalTime(e.toYear)) : "",
              fromYear: e.fromYear
                ? new Date(convertUTCToLocalTime(e.fromYear))
                : "",
              educationFieldList:
                e.educationFieldList != null ? e.educationFieldList : [],
            });

            tempEducationDates.push({
              toYear: e.toYear ? new Date(convertUTCToLocalTime(e.toYear)) : "",
              fromYear: e.fromYear
                ? new Date(convertUTCToLocalTime(e.fromYear))
                : "",
            });
          });

          if (res.data.educations.length !== 0) {
            setMemberEductationList(educations);
            setEducationDates(tempEducationDates);
            let tempErrors = [];
            educations.map(() => {
              return tempErrors.push({
                educationType: "",
                educationField: "",
              });
            });
            setErrorsEducation(tempErrors);
          } else {
            setMemberEductationList([
              {
                educationId: 0,
                educationType: {},
                educationField: {
                  value: 0,
                  label: "",
                },
                educationFieldList: [],
                toYear: "",
                fromYear: "",
                institution: "",
              },
            ]);
          }

          if (tempEducations.length > 0) {
            setEducationTestList(tempEducations);
          } else {
            setEducationTestList([
              {
                educationId: 0,
                educationType: {},
                educationField: {
                  value: 0,
                  label: "",
                },
                educationFieldList: [],
                toYear: "",
                fromYear: "",
                institution: "",
              },
            ]);
            setEducationDates([
              {
                fromYear: "",
                toYear: "",
              },
            ]);
          }

          setLoadingEducation(false);
        })
        .catch((err) => {
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
          setLoadingEducation(false);
          console.log(err);
        });
    }
  }, [memberId, reloadEducation, tab5]);

  useEffect(() => {
    if (tab5 === "active") {
      setLoadingOccupation(true);
      addMember()
        .getMemberOccupationDetail(memberId)
        .then((res) => {
          let occupation = [];
          let tempOccupations = [];
          res.data.occupations.forEach((o) => {
            occupation.push({
              ...o,
            });
            tempOccupations.push({
              ...o,
            });
          });

          setLoadingOccupation(false);
          if (res.data.occupations.length !== 0) {
            setMemberOccupations(res.data.occupations);
            let tempErrors = [];
            res.data.occupations.map(() => {
              return tempErrors.push({
                occupationType: "",
              });
            });
            setErrorsOccupation(tempErrors);
          } else {
            setMemberOccupations([
              {
                occupationId: 0,
                occupationType: {},
                description: "",
                referenceNote: "",
                deletable: false,
                edited: false,
              },
            ]);
          }
          if (tempOccupations.length > 0) {
            setTestOccupationsList(tempOccupations);
          } else {
            setTestOccupationsList([
              {
                occupationId: 0,
                occupationType: {},
                description: "",
                referenceNote: "",
                deletable: false,
                edited: false,
              },
            ]);
          }
        })
        .catch((err) => {
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
          setLoadingOccupation(false);
          console.log(err);
        });
    }
  }, [memberId, reloadOccupation, tab5]);

  useEffect(() => {
    if (tab5 === "active") {
      ApiEducation()
        .getEducationTypesForDropDown()
        .then((res) => {
          setMemberEductationDegreeList(res.data);
        })
        .catch((err) => {
          console.log(err);
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
        });

      setupApi()
        .getOccupations()
        .then((res) => {
          setMemberOccupationList(res.data);
        })
        .catch((err) => {
          console.log(err);
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
        });

      setupApi()
        .getSkills()
        .then((res) => {
          setMemberSkillsList(res.data);
        })
        .catch((err) => {
          console.log(err);
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
        });
    }
  }, [memberId, tab5]);

  //GET MEMBER SKILLS
  useEffect(() => {
    if (tab5 === "active") {
      setLoadingSkill(true);
      addMember()
        .getMemberSkillsDetail(memberId)
        .then((res) => {
          let memberskills = [];
          let tempSkills = [];
          res.data.skills.forEach((s) => {
            memberskills.push({ ...s });
            tempSkills.push({ ...s });
          });
          if (res.data.skills.length !== 0) {
            setMemberSkills(memberskills);
            let tempErrors = [];
            memberskills.map(() => {
              return tempErrors.push({
                skillType: "",
              });
            });
            setErrorsSkill(tempErrors);
          } else {
            setMemberSkills([
              {
                skillId: 0,
                skillType: {},
                description: "",
                referenceNote: "",
              },
            ]);
          }
          if (tempSkills.length > 0) {
            setTestSkillsList(tempSkills);
          } else {
            setTestSkillsList([
              {
                skillId: 0,
                skillType: {},
                description: "",
                referenceNote: "",
              },
            ]);
          }

          setLoadingSkill(false);
        })
        .catch((err) => {
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });

          setLoadingSkill(false);
          console.log(err);
        });
    }
  }, [memberId, reloadSkill, tab5]);

  //RAFEEQ EDUCATION
  const addEducation = () => {
    const tempValues = [
      {
        educationId: 0,
        educationType: {},
        educationField: {
          value: 0,
          label: "",
        },
        educationFieldList: [],
        toYear: "",
        fromYear: "",
        institution: "",
        deletable: false,
        edited: false,
      },
      ...memberEducationList,
    ];
    setMemberEductationList(tempValues);

    const tempDates = [
      {
        fromYear: "",
        toYear: "",
      },
      ...educationDates,
    ];

    setEducationDates(tempDates);

    const tempErrors = [
      {
        educationType: "",
      },
      ...errorsEducation,
    ];
    setErrorsEducation(tempErrors);
  };
  const handleOnChangeEducationType = (value, id) => {
    let tempValues = [...memberEducationList];
    tempValues[id].educationType = value;
    tempValues[id].educationField = { value: 0, label: "" };
    tempValues[id].edited = true;
    setMemberEductationList(tempValues);

    setEducationFieldDisable(true);
    ApiEducation()
      .getEducationFieldsDropDown(value.value)
      .then((res) => {
        setMemberEductationList((prevEducationList) => {
          let tempValues = [...prevEducationList];
          tempValues[id].educationFieldList = res.data;

          return tempValues;
        });

        setEducationFieldDisable(false);
      })
      .catch((err) => {
        console.log(err);
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
        setEducationFieldDisable(false);
      });

    let temp = [...errorsEducation];
    temp[id].educationType = "";
    setErrorsEducation(temp);
  };
  const handleOnChangeEducationField = (value, id) => {
    let tempValues = [...memberEducationList];
    tempValues[id].educationField = value;
    tempValues[id].edited = true;
    setMemberEductationList(tempValues);
  };
  const removeEducationObject = (id) => {
    let tempValues = [...memberEducationList];
    tempValues[id].deletable = true;
    tempValues[id].edited = true;
    setMemberEductationList(tempValues);
  };
  const handleToDate = (toDate, index) => {
    let tempDateValues = [...educationDates];
    tempDateValues[index].toYear = toDate;
    setEducationDates(tempDateValues);

    let tempValues = [...memberEducationList];
    tempValues[index].edited = true;
    setMemberEductationList(tempValues);

  };
  const handleFromDate = (fromDate, index) => {

    let tempDateValues = [...educationDates];
    tempDateValues[index].fromYear = fromDate;
    setEducationDates(tempDateValues);

    let tempValues = [...memberEducationList];
    tempValues[index].edited = true;
    setMemberEductationList(tempValues);
  };
  const handleInstitution = (institution, index) => {
    let tempValues = [...memberEducationList];
    tempValues[index].institution = institution.target.value;
    tempValues[index].edited = true;

    setMemberEductationList(tempValues);
  };
  const handleSubmitEducation = (e) => {
    e.preventDefault();
    apiCallForEducation();
    setEducationTestList(memberEducationList);
  };

  const apiCallForEducation = () => {
    if (validateEducation()) {
      setLoadingEducation(true);

      const updatedEducationList = memberEducationList.map((item, index) => ({
        ...item,
        toYear: convertToDateString(educationDates[index]?.toYear || item.toYear), 
        fromYear: convertToDateString(educationDates[index]?.fromYear || item.fromYear), 
      }));
      addMember()
        .addMemberEducation(
          {
            memberId: memberId,
            educations: updatedEducationList,
          },
          addNewRafiq
        )
        .then((res) => {
          if (res.data === "") {
            swal({
              title: t("member__member_education_added_success_message"),
              className: GetSelectedLanguage() === "en" ? "" : "ur",
              icon: "success",
              buttons: "OK",
            }).then((ok) => {
              setDiscard(!discard);
            });
          } else notify(res.data);

          setLoadingEducation(false);
          setReloadEducation(!reloadEducation);
        })
        .catch((err) => {
          console.log(err);
          setLoadingEducation(false);
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          }).then((ok) => {
            setActiveTab("tab5");
          });
        });
    }
  };
  
  //RAFEEQ OCCUPATION
  const addOccupation = () => {
    const tempValues = [
      {
        occupationId: 0,
        occupationType: {},
        description: "",
        referenceNote: "",
        deletable: false,
        edited: false,
      },
      ...memberOccupations,
    ];
    setMemberOccupations(tempValues);

    const tempErrors = [
      {
        occupationType: "",
      },
      ...errorsOccupation,
    ];
    setErrorsOccupation(tempErrors);
  };

  const handleOnChangeOccupationType = (value, id) => {
    let tempValues = [...memberOccupations];
    tempValues[id].occupationType = value;
    tempValues[id].edited = true;
    setMemberOccupations(tempValues);

    let temp = [...errorsOccupation];
    temp[id].occupationType = "";
    setErrorsOccupation(temp);
  };
  const removeOccupationObject = (id) => {
    let tempValues = [...memberOccupations];
    tempValues[id].deletable = true;
    tempValues[id].edited = true;
    setMemberOccupations(tempValues);
  };

  const handleDescription = (description, index) => {
    let tempValues = [...memberOccupations];
    tempValues[index].description = description.target.value;
    tempValues[index].edited = true;
    setMemberOccupations(tempValues);
  };
  const handleReferenceNote = (referenceNote, index) => {
    let tempValues = [...memberOccupations];
    tempValues[index].referenceNote = referenceNote.target.value;
    tempValues[index].edited = true;
    setMemberOccupations(tempValues);
  };

  const handleSubmitOccupation = (e) => {
    e.preventDefault();
    apiCallForOccupation();
    setTestOccupationsList(memberOccupations);
  };

  const apiCallForOccupation = () => {
    if (validateOccupation()) {
      if (memberOccupations.every((item) => item.edited === false)) {
        swal({
          title: t("member__member_occupation_added_success_message"),
          className: GetSelectedLanguage() === "en" ? "" : "ur",
          icon: "success",
          buttons: "OK",
        });
      } else {
        setLoadingOccupation(true);
        addMember()
          .addMemberOccupation(
            {
              memberId: memberId,
              occupations: memberOccupations,
            },
            addNewRafiq
          )
          .then((res) => {
            if (res.data === "") {
              swal({
                title: t("member__member_occupation_added_success_message"),
                className: GetSelectedLanguage() === "en" ? "" : "ur",
                icon: "success",
                buttons: "OK",
              }).then((ok) => {
                setDiscard(!discard);
              });
            } else notify(res.data);

            setLoadingOccupation(false);
            setReloadOccupation(!reloadOccupation);
          })
          .catch((err) => {
            console.log(err);
            setLoadingOccupation(false);
            swal({
              title: err,
              icon: "error",
              buttons: "OK",
            }).then((ok) => {
              setActiveTab("tab5");
            });
          });
      }
    }
  };

  //RAFEEQ SKILLS
  const addSkill = () => {
    const tempValues = [
      {
        skillId: 0,
        skillType: {},
        description: "",
        referenceNote: "",
        deletable: false,
        edited: false,
      },
      ...memberSkills,
    ];
    setMemberSkills(tempValues);
    const tempErrors = [
      {
        skillType: "",
      },
      ...errorsSkill,
    ];
    setErrorsSkill(tempErrors);
  };

  const handleOnChangeSkillType = (value, id) => {
    let tempValues = [...memberSkills];
    tempValues[id].skillType = value;
    tempValues[id].edited = true;
    setMemberSkills(tempValues);

    let temp = [...errorsSkill];
    temp[id].skillType = "";
    setErrorsSkill(temp);
  };
  const removeSkillObject = (id) => {
    let tempValues = [...memberSkills];
    tempValues[id].deletable = true;
    tempValues[id].edited = true;
    setMemberSkills(tempValues);
  };
  const handleSkillDescription = (description, index) => {
    let tempValues = [...memberSkills];
    tempValues[index].description = description.target.value;
    tempValues[index].edited = true;
    setMemberSkills(tempValues);
  };
  const handleSkillReferenceNote = (referenceNote, index) => {
    let tempValues = [...memberSkills];
    tempValues[index].referenceNote = referenceNote.target.value;
    tempValues[index].edited = true;
    setMemberSkills(tempValues);
  };
  const handleSubmitSkills = (e) => {
    e.preventDefault();
    apiCallForSkills();
    setTestSkillsList(memberSkills);
  };

  const apiCallForSkills = () => {
    if (validateSkill()) {
      setLoadingSkill(true);
      addMember()
        .addMemberSkills(
          { memberId: memberId, skills: memberSkills },
          addNewRafiq
        )
        .then((res) => {
          if (res.data === "") {
            swal({
              title: t("member__member_skill_added_success_message"),
              className: GetSelectedLanguage() === "en" ? "" : "ur",
              icon: "success",
              buttons: "OK",
            }).then((ok) => {
              setDiscard(!discard);
            });
          } else notify(res.data);
          setReloadSkill(!reloadSkill);
          setLoadingSkill(false);
        })
        .catch((err) => {
          console.log(err);
          setLoadingSkill(false);
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          }).then((ok) => {
            setActiveTab("tab5");
          });
        });
    }
  };

  useEffect(() => {
    if (tab5 === "active" && activeTab && activeTab !== "tab5") {
      const isMemberOccupationChanged = !isEqual(
        testOccupationsList,
        memberOccupations
      );

      const isMemberEducationChanged = !isEqual(
        educationTestList,
        memberEducationList
      );

      const isMemberSkillsChanged = !isEqual(testSkillsList, memberSkills);

      if (
        isMemberOccupationChanged &&
        !isMemberEducationChanged &&
        !isMemberSkillsChanged
      ) {
        swal({
          title: "Discard changes?",
          text: "Do you want to discard changes or save before canceling for Occupation?",
          icon: "warning",
          buttons: ["Save Changes", "Discard"],
          closeOnClickOutside: false,
          dangerMode: true,
        }).then((willDiscard) => {
          if (willDiscard) {
            setDiscard(!discard);
            setTestOccupationsList([]);
            setMemberOccupations([]);
          } else {
            apiCallForOccupation();
          }
        });
      } else if (
        !isMemberOccupationChanged &&
        isMemberEducationChanged &&
        !isMemberSkillsChanged
      ) {
        swal({
          title: "Discard changes?",
          text: "Do you want to discard changes or save before canceling for Education?",
          icon: "warning",
          buttons: ["Save Changes", "Discard"],
          closeOnClickOutside: false,
          dangerMode: true,
        }).then((willDiscard) => {
          if (willDiscard) {
            setDiscard(!discard);
            setMemberEductationList([]);
            setEducationTestList([]);
          } else {
            apiCallForEducation();
          }
        });
      } else if (
        !isMemberOccupationChanged &&
        !isMemberEducationChanged &&
        isMemberSkillsChanged
      ) {
        swal({
          title: "Discard changes?",
          text: "Do you want to discard changes or save before canceling for skills?",
          icon: "warning",
          buttons: ["Save Changes", "Discard"],
          closeOnClickOutside: false,
          dangerMode: true,
        }).then((willDiscard) => {
          if (willDiscard) {
            setDiscard(!discard);
            setTestSkillsList([]);
            setMemberSkills([]);
          } else {
            apiCallForSkills();
          }
        });
      } else if (
        isMemberOccupationChanged &&
        isMemberEducationChanged &&
        !isMemberSkillsChanged
      ) {
        swal({
          title: "Discard changes?",
          text: "Do you want to discard changes or save before canceling for Education and Occupation?",
          icon: "warning",
          buttons: ["Save Changes", "Discard"],
          closeOnClickOutside: false,
          dangerMode: true,
        }).then((willDiscard) => {
          if (willDiscard) {
            setDiscard(!discard);
            setMemberEductationList([]);
            setEducationTestList([]);
            setTestOccupationsList([]);
            setMemberOccupations([]);
          } else {
            apiCallForOccupation();
            apiCallForEducation();
          }
        });
      } else if (
        isMemberOccupationChanged &&
        !isMemberEducationChanged &&
        isMemberSkillsChanged
      ) {
        swal({
          title: "Discard changes?",
          text: "Do you want to discard changes or save before canceling for Skills and Occupation?",
          icon: "warning",
          buttons: ["Save Changes", "Discard"],
          closeOnClickOutside: false,
          dangerMode: true,
        }).then((willDiscard) => {
          if (willDiscard) {
            setDiscard(!discard);
            setTestSkillsList([]);
            setMemberSkills([]);
            setTestOccupationsList([]);
            setMemberOccupations([]);
          } else {
            apiCallForOccupation();
            apiCallForSkills();
          }
        });
      } else if (
        !isMemberOccupationChanged &&
        isMemberEducationChanged &&
        isMemberSkillsChanged
      ) {
        swal({
          title: "Discard changes?",
          text: "Do you want to discard changes or save before canceling for Education AND Skills?",
          icon: "warning",
          buttons: ["Save Changes", "Discard"],
          closeOnClickOutside: false,
          dangerMode: true,
        }).then((willDiscard) => {
          if (willDiscard) {
            setDiscard(!discard);
            setMemberEductationList([]);
            setEducationTestList([]);
            setTestSkillsList([]);
            setMemberSkills([]);
          } else {
            apiCallForEducation();
            apiCallForSkills();
          }
        });
      } else if (
        isMemberOccupationChanged &&
        isMemberEducationChanged &&
        isMemberSkillsChanged
      ) {
        swal({
          title: "Discard changes?",
          text: "Do you want to discard changes or save before canceling for Education, Occupation And Skills?",
          icon: "warning",
          buttons: ["Save Changes", "Discard"],
          closeOnClickOutside: false,
          dangerMode: true,
        }).then((willDiscard) => {
          if (willDiscard) {
            setDiscard(!discard);
            setMemberEductationList([]);
            setEducationTestList([]);
            setMemberOccupations([]);
            setTestOccupationsList([]);
            setTestSkillsList([]);
            setMemberSkills([]);
          } else {
            apiCallForEducation();
            apiCallForOccupation();
            apiCallForSkills();
          }
        });
      } else {
        // Common logic if none of the conditions are true
        setDiscard(!discard);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const handleOccupationCancel = (e) => {
    const isMemberOccupationChanged = !isEqual(
      testOccupationsList,
      memberOccupations
    );
    if (isMemberOccupationChanged) {
      swal({
        title: "Discard changes?",
        text: "Do you want to discard changes or save before canceling?",
        icon: "warning",
        buttons: ["Save Changes", "Discard"],
        closeOnClickOutside: false,
        dangerMode: true,
      }).then((willDiscard) => {
        if (willDiscard) {
          history.goBack(); // Process with cancel
        } else {
          handleSubmitOccupation(e); // Call the save function when the user chooses not to discard changes
        }
      });
    } else {
      history.goBack(); // No changes, directly go back
    }
  };

  const handleEducationCancel = (e) => {
    const isMemberEducationChanged = !isEqual(
      educationTestList,
      memberEducationList
    );
    if (isMemberEducationChanged) {
      swal({
        title: "Discard changes?",
        text: "Do you want to discard changes or save before canceling?",
        icon: "warning",
        buttons: ["Save Changes", "Discard"],
        closeOnClickOutside: false,
        dangerMode: true,
      }).then((willDiscard) => {
        if (willDiscard) {
          history.goBack(); // Process with cancel
        } else {
          handleSubmitEducation(e); // Call the save function when the user chooses not to discard changes
        }
      });
    } else {
      history.goBack(); // No changes, directly go back
    }
  };

  const handleSkillsCancel = (e) => {
    const isMemberSkillsChanged = isEqual(testSkillsList, memberSkills);

    if (!isMemberSkillsChanged) {
      swal({
        title: "Discard changes?",
        text: "Do you want to discard changes or save before canceling?",
        icon: "warning",
        buttons: ["Save Changes", "Discard"],
        closeOnClickOutside: false,
        dangerMode: true,
      }).then((willDiscard) => {
        if (willDiscard) {
          history.goBack(); // Process with cancel
        } else {
          handleSubmitSkills(e); // Call the save function when the user chooses not to discard changes
        }
      });
    } else {
      history.goBack(); // No changes, directly go back
    }
  };
  return (
    <div role="tabpanel" id="tab-5" className={"tab-pane " + tab5}>
      <div className="panel-body">
        <Row>
          <Col md="4">
            <div className="ibox">
              <div className="ibox-title">
                <Row>
                  <Col md="11">
                    <h5>{<GetLanguageString props="member_education" />}</h5>
                  </Col>
                  <Col md="1" className="text-center">
                    <div>
                      <i
                        className={"fa fa-2x fa-plus-square"}
                        style={{ cursor: "pointer", color: "green" }}
                        onClick={addEducation}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="ibox-content">
                {!loadingEducation ? (
                  <Form onSubmit={handleSubmitEducation}>
                    {memberEducationList.every(
                      (item) => item.deletable === true
                    ) ? (
                      <NoResult />
                    ) : (
                      memberEducationList.map((item, index) => {
                        return !item.deletable ? (
                          <>
                            <Row key={index}>
                              <Col md="6">
                                <FormGroup>
                                  <Label className="col-form-label">
                                    {
                                      <GetLanguageString props="member_education_degree" />
                                    }
                                  </Label>
                                  <Select
                                    options={memberEducationDegreeList}
                                    value={item.educationType}
                                    components={{ Option: customOption }}
                                    className={
                                      isUrdu(item.educationType.label)
                                        ? "basic-single ur"
                                        : "basic-single"
                                    }
                                    placeholder={
                                      GetSelectedLanguage() === "en"
                                        ? "Select"
                                        : "منتخب کریں"
                                    }
                                    classNamePrefix="select"
                                    onChange={(type) => {
                                      handleOnChangeEducationType(type, index);
                                    }}
                                  />
                                  {errorsEducation[index]?.educationType && (
                                    <h4 className="text-error">
                                      {errorsEducation[index].educationType}
                                    </h4>
                                  )}
                                </FormGroup>
                              </Col>
                              <Col md="6">
                                <FormGroup>
                                  <Label className="col-form-label">
                                    {
                                      <GetLanguageString props="education_degree_field" />
                                    }
                                  </Label>
                                  <Select
                                    options={item?.educationFieldList}
                                    value={item.educationField}
                                    isDisabled={educationFieldDisable}
                                    components={{ Option: customOption }}
                                    className={
                                      isUrdu(item.educationField?.label)
                                        ? "basic-single ur"
                                        : "basic-single"
                                    }
                                    placeholder={
                                      GetSelectedLanguage() === "en"
                                        ? "Select Education Field"
                                        : "منتخب کریں"
                                    }
                                    // classNamePrefix="select"
                                    onChange={(type) => {
                                      handleOnChangeEducationField(type, index);
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="6">
                                <FormGroup>
                                  <Label className="col-form-label">
                                    {
                                      <GetLanguageString props="member_report_to" />
                                    }
                                  </Label>
                                  <DatePicker
                                    placeholderText="YYYY"
                                    className="form-control"
                                    showYearPicker
                                    placeholder={
                                      GetSelectedLanguage() === "en"
                                        ? "Select"
                                        : "منتخب کریں"
                                    }
                                    selected={educationDates[index].toYear}
                                    onChange={(toDate) => {
                                      handleToDate(toDate, index);
                                    }}
                                    dateFormat="yyyy"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="6">
                                <FormGroup>
                                  <Label className="col-form-label">
                                    {
                                      <GetLanguageString props="member_report_from" />
                                    }
                                  </Label>
                                  <DatePicker
                                    placeholderText="YYYY"
                                    className="form-control"
                                    showYearPicker
                                    placeholder={
                                      GetSelectedLanguage() === "en"
                                        ? "Select"
                                        : "منتخب کریں"
                                    }
                                    selected={
                                      educationDates[index].fromYear
                                    }
                                    onChange={(fromDate) =>
                                      handleFromDate(fromDate, index)
                                    }
                                    dateFormat="yyyy"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="10">
                                <FormGroup>
                                  <Label className="col-form-label">
                                    {
                                      <GetLanguageString props="member_education_institution" />
                                    }
                                  </Label>
                                  <Input
                                    type="text"
                                    name="institution"
                                    value={item.institution}
                                    onChange={(institution) =>
                                      handleInstitution(institution, index)
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="2" className="text-left">
                                <i
                                  className={"fa fa-2x fa-minus-square m-t-xl "}
                                  style={{ cursor: "pointer", color: "red" }}
                                  onClick={() => removeEducationObject(index)}
                                />
                              </Col>
                            </Row>
                            <hr></hr>
                          </>
                        ) : null;
                      })
                    )}
                    <Row>
                      <Col md="5"></Col>
                      <Col md="7">
                        <div className={GetCustomStyles().btn_style}>
                          <Button color="white" onClick={handleEducationCancel}>
                            {<GetLanguageString props="common_cancel" />}
                          </Button>
                          <Button
                            color="primary"
                            className="m-l-sm"
                            type="submit"
                          >
                            {<GetLanguageString props="common_save_changes" />}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                ) : (
                  LoadingSpinner()
                )}
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="ibox">
              <div className="ibox-title">
                <Row>
                  <Col md="11">
                    <h5>{<GetLanguageString props="member_occupation" />}</h5>
                  </Col>
                  <Col md="1" className="text-center">
                    <div>
                      <i
                        className={"fa fa-2x fa-plus-square"}
                        style={{ cursor: "pointer", color: "green" }}
                        onClick={addOccupation}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="ibox-content">
                {!loadingOccupation ? (
                  <Form onSubmit={handleSubmitOccupation}>
                    {memberOccupations.every(
                      (item) => item.deletable === true
                    ) ? (
                      <NoResult />
                    ) : (
                      memberOccupations.map((item, index) => {
                        return !item.deletable ? (
                          <>
                            <FormGroup key={index}>
                              <Label className="col-form-label">
                                {
                                  <GetLanguageString props="member_occupation" />
                                }
                              </Label>
                              <Select
                                options={memberOccupationList}
                                value={item.occupationType}
                                components={{ Option: customOption }}
                                classNamePrefix="select"
                                className={
                                  isUrdu(item.occupationType.label)
                                    ? "basic-single ur"
                                    : "basic-single"
                                }
                                placeholder={
                                  GetSelectedLanguage() === "en"
                                    ? "Select"
                                    : "منتخب کریں"
                                }
                                onChange={(type) => {
                                  handleOnChangeOccupationType(type, index);
                                }}
                              />
                              {errorsOccupation[index]?.occupationType && (
                                <h4 className="text-error">
                                  {errorsOccupation[index].occupationType}
                                </h4>
                              )}
                            </FormGroup>
                            <FormGroup>
                              <Label className="col-form-label">
                                {
                                  <GetLanguageString props="common_description" />
                                }
                              </Label>
                              <Input
                                type="text"
                                name="description"
                                className={isUrdu(item.description) ? "ur" : ""}
                                value={item.description}
                                onChange={(description) =>
                                  handleDescription(description, index)
                                }
                              />
                            </FormGroup>
                            <Row>
                              <Col md="10">
                                <FormGroup>
                                  <Label className="col-form-label">
                                    {
                                      <GetLanguageString props="member_reference_note" />
                                    }
                                  </Label>
                                  <Input
                                    type="text"
                                    name="referenceNote"
                                    value={item.referenceNote}
                                    onChange={(referenceNote) =>
                                      handleReferenceNote(referenceNote, index)
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="2" className="text-left">
                                <i
                                  className={"fa fa-2x fa-minus-square m-t-xl "}
                                  style={{ cursor: "pointer", color: "red" }}
                                  onClick={() => removeOccupationObject(index)}
                                />
                              </Col>
                            </Row>
                            <hr></hr>
                          </>
                        ) : null;
                      })
                    )}
                    <Row>
                      <Col md="5"></Col>

                      <Col md="7">
                        <div className={GetCustomStyles().btn_style}>
                          <Button
                            color="white"
                            onClick={handleOccupationCancel}
                          >
                            {<GetLanguageString props="common_cancel" />}
                          </Button>
                          <Button
                            color="primary"
                            className="m-l-sm"
                            type="submit"
                          >
                            {<GetLanguageString props="common_save_changes" />}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                ) : (
                  LoadingSpinner()
                )}
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="ibox">
              <div className="ibox-title">
                <Row>
                  <Col md="11">
                    <h5>{<GetLanguageString props="member_skills" />}</h5>
                  </Col>
                  <Col md="1" className="text-center">
                    <div>
                      <i
                        className={"fa fa-2x fa-plus-square"}
                        style={{ cursor: "pointer", color: "green" }}
                        onClick={addSkill}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="ibox-content">
                {!loadingSkill ? (
                  <Form onSubmit={handleSubmitSkills}>
                    {memberSkills.every((item) => item.deletable === true) ? (
                      <NoResult />
                    ) : (
                      memberSkills.map((item, index) => {
                        return !item.deletable ? (
                          <>
                            <FormGroup key={index}>
                              <Label className="col-form-label">
                                {<GetLanguageString props="member_skills" />}
                              </Label>
                              <Select
                                options={memberSkillsList}
                                components={{ Option: customOption }}
                                value={item.skillType}
                                className={
                                  isUrdu(item.skillType.label)
                                    ? "basic-single ur"
                                    : "basic-single"
                                }
                                classNamePrefix="select"
                                placeholder={
                                  GetSelectedLanguage() === "en"
                                    ? "Select"
                                    : "منتخب کریں"
                                }
                                onChange={(type) => {
                                  handleOnChangeSkillType(type, index);
                                }}
                              />
                              {errorsSkill[index]?.skillType && (
                                <h4 className="text-error">
                                  {errorsSkill[index].skillType}
                                </h4>
                              )}
                            </FormGroup>
                            <FormGroup>
                              <Label className="col-form-label">
                                {
                                  <GetLanguageString props="common_description" />
                                }
                              </Label>
                              <Input
                                type="text"
                                name="description"
                                value={item.description}
                                onChange={(description) =>
                                  handleSkillDescription(description, index)
                                }
                              />
                            </FormGroup>
                            <Row>
                              <Col md="10">
                                <FormGroup>
                                  <Label className="col-form-label">
                                    {
                                      <GetLanguageString props="member_reference_note" />
                                    }
                                  </Label>
                                  <Input
                                    type="text"
                                    name="referenceNote"
                                    value={item.referenceNote}
                                    onChange={(referenceNote) =>
                                      handleSkillReferenceNote(
                                        referenceNote,
                                        index
                                      )
                                    }
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="2" className="text-left">
                                <i
                                  className={"fa fa-2x fa-minus-square m-t-xl "}
                                  style={{ cursor: "pointer", color: "red" }}
                                  onClick={() => removeSkillObject(index)}
                                />
                              </Col>
                            </Row>
                            <hr></hr>
                          </>
                        ) : null;
                      })
                    )}
                    <Row>
                      <Col md="5"></Col>
                      <Col md="7">
                        <div className={GetCustomStyles().btn_style}>
                          <Button color="white" onClick={handleSkillsCancel}>
                            {<GetLanguageString props="common_cancel" />}
                          </Button>
                          <Button
                            color="primary"
                            className="m-l-sm"
                            type="submit"
                          >
                            {<GetLanguageString props="common_save_changes" />}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                ) : (
                  LoadingSpinner()
                )}
              </div>
            </div>
          </Col>
        </Row>
        {CheckPermission("CanRequestMemberModificationPersonalInfo") &&
        !CheckIsAdmin() &&
        !addNewRafiq ? (
          <div>
            <h4 className="text-error">* Request Modification</h4>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default withRouter(UpdateEducationOccupationSkill);
