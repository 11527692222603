import axios from "axios";

import { BASEURL } from "../../../constants/const";
import { encryptStorage } from "../../../constants/EncryptStorage";

export function ApiDocumentType() {
  const userToken = encryptStorage.getItem("token");

  const authAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const url = BASEURL + "documentType/";
  return {
    addDocumentType: (record) => authAxios.post(url + "add", record),
    getDocumentTypes: () => authAxios.get(url + "GetList"),
    editDocumentType: (id) => authAxios.get(url + `edit/${id}`),
    updateDocumentType: (formData) => authAxios.post(url + "edit", formData),
    deleteDocumentType: (id) => authAxios.delete(url + `delete/${id}`),
    getDocumentTypesForDropDowns: () => authAxios.get(url + "getDocumentTypes"),
  };
}
