import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Form, Row, Col, FormGroup, Label, Input, Button } from "reactstrap";

import useForm from "../shared/useForm";
import { ApiUnit } from "../unit/ApiUnit";
import { GetLanguageString } from "../helper/Components";
import { requestApi } from "../../actions/api";
import { ApiResponsibility } from "../config/responsibilityType/ApiResponsibilityType";
import { encryptStorage } from "../../constants/EncryptStorage";

const SearchUsers = ({
  unitId,
  setUnit,
  setSearchMemberModel,
  searchMemberModel,
  setSearchButton
}) => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [unitList, setUnitList] = useState([]);
  const [memberTypeList, setMemberTypeList] = useState([]);
  const [responsibilityList, setResponsibilityList] = useState([]);
  const [excludeExUnit, setExcludeExUnit] = useState(true);
  const memberTypeOptions = [];
  const ResponsibilityOptions = [];
  const unitTypeName = "null";

  useEffect(() => {
    ApiResponsibility()
      .getResponsibility()
      .then((res) => {
        setResponsibilityList(res.data);
      })
      .catch((err) => console.log(err));

    requestApi("membertype/")
      .fetchAll()
      .then((res) => {
        setMemberTypeList(res.data);
      })
      .catch((err) => console.log(err));

    ApiUnit()
      .getUnitList(unitId, loggedInUnitId, false, unitTypeName, excludeExUnit)
      .then((res) => {
        setUnitList(res.data);
      })
      .catch((err) => console.log(err));
  }, [unitId, loggedInUnitId, excludeExUnit]);

  memberTypeList.forEach((d) =>
    memberTypeOptions.push({ value: d.id, label: d.type })
  );
  responsibilityList.forEach((d) =>
    ResponsibilityOptions.push({ value: d.id, label: d.type })
  );

  const { values, handleInputChange, setValues } = useForm(searchMemberModel);

  const handleChangeUnits = (record) => {
    if (record !== null) {
      ApiUnit()
        .getUnitList(record.value, loggedInUnitId, false, unitTypeName, excludeExUnit)
        .then((res) => {
          setUnitList(res.data);
        })
        .catch((err) => console.log(err));

      setUnit({ unitId: record.value, unitName: record.label });
      setValues({ ...values, unitId: record.value });
    }
  };

  const handleChangeMemberTypes = (record) => {
    if (record !== null) {
      setValues({
        ...values,
        memberTypeId: record.value,
      });
    } else {
      setValues({
        ...values,
        memberTypeId: 0,
      });
    }
  };

  const handleChangeResponsibility = (record) => {
    if (record !== null) {
      setValues({
        ...values,
        responsibilityId: record.value,
      });
    } else {
      setValues({
        ...values,
        responsibilityId: 0,
      });
    }
  };

  const handleSubUnits = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setValues({
      ...values,
      isSubUnits: v,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (values.unitId === 0) values.unitId = unitId;
    setSearchButton(true)
    setSearchMemberModel(values);
  };

  const handleExcludeExUnit = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setExcludeExUnit(v);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col md="2">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="member_search_by_name" />}
            </Label>
            <Input
              className="ur"
              type="text"
              name="name"
              value={values.name}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="member_search_by_regno" />}
            </Label>
            <Input
              type="text"
              name="regNo"
              value={values.regNo}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="member_search_by_phoneno" />}
            </Label>
            <Input
              type="text"
              name="phoneNo"
              value={values.phoneNo}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="member_search_by_address" />}
            </Label>
            <Input
            className="ur"
              type="text"
              name="address"
              value={values.address}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="member_select_responsibility" />}
            </Label>
            <Select
              options={ResponsibilityOptions}
              className="basic-single ur"
              isClearable
              onChange={handleChangeResponsibility}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="member_select_member_type" />}
            </Label>
            <Select
              options={memberTypeOptions}
              className="basic-single ur"
              isClearable
              onChange={handleChangeMemberTypes}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        {unitList.map((record, index) => {
          const unitOptions = [];
          record.unitList.forEach((d) =>
            unitOptions.push({ value: d.id, label: d.name })
          );
          let selected = {};
          let unitName = "";
          if (record.parentUnitName !== null) unitName = record.parentUnitName;
          if (record.selectedUnit !== null) {
            selected = {
              value: record.selectedUnit.id,
              label: record.selectedUnit.name,
            };
          }

          return (
            <Col md="2" key={index}>
              <FormGroup>
                <Label className="col-form-label">
                  {unitName !== "" ? (
                    <GetLanguageString
                      props="member_select_unit_name"
                      value={unitName}
                    />
                  ) : (
                    <GetLanguageString props="member_select_unit" />
                  )}
                </Label>
                <Select
                  options={unitOptions}
                  value={selected}
                  className="basic-single ur"
                  classNamePrefix="select"
                  onChange={handleChangeUnits}
                />
              </FormGroup>
            </Col>
          );
        })}
      </Row>
      <Row>
        <Col md="2">
          <FormGroup check>
            <Label check for="excludeExUnit">
              <Input
                id="excludeExUnit"
                type="checkbox"
                name="excludeExUnit"
                checked={!excludeExUnit}
                value={!excludeExUnit}
                onChange={handleExcludeExUnit}
              />
              {<GetLanguageString props="member_search_member_ex_unit" />}
            </Label>
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup check>
            <Label check for="subUnit">
              <Input
                id="subUnit"
                type="checkbox"
                name="isSubUnits"
                checked={values.isSubUnits}
                value={!values.isSubUnits}
                onChange={handleSubUnits}
              />
              {<GetLanguageString props="member_search_member_sub_unit" />}
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <FormGroup>
            <Button color="primary" className="m-l-sm" type="submit">
              {<GetLanguageString props="common_search" />}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default SearchUsers;
