import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { Table, Button, ButtonGroup } from "reactstrap";
import { useTranslation } from "react-i18next";

import { requestApi } from "../../../actions/api";
import EditLetterForCounselling from "./EditLetterForCounselling";
import { GetLanguageString, NoResult } from "../../helper/Components";
import {
  LoadingSpinner,
  convertUTCToLocalTime,
} from "../../../constants/const";
import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import AddLetterForCounselling from "./AddLetterForCounselling";
import moment from "moment";
import { ApiLetterForCounselling } from "./ApiLetterForCounselling";
import LetterForCounsellingQuestions from "./LetterForCounsellingQuestions";
import { IoAdd } from "react-icons/io5";

const LetterForCounselling = ({ activeTab }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [letterForCounselling, setLetterForCounselling] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [editValue, setEditValue] = useState();
  const [editTitle, setEditTitle] = useState();
  const [questionsModal, setQuestionsModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (activeTab === "letterForCounselling") {
      setLoading(true);
      requestApi("letterForCounselling/")
        .fetchAll()
        .then((res) => {
          setLetterForCounselling(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [activeTab, reload]);

  const handleOnDelete = (value, isAffiliated) => {
    if (isAffiliated) {
      swal({
        title: t("common_is_affiliated"),
        text: t("common_is_affiliated_message"),
        className: GetSelectedLanguage() === "en" ? "" : "ur",
        icon: "warning",
      });
    } else {
      swal({
        title: t("common_delete_message"),
        text: t("common_delete_detail_message"),
        icon: "warning",
        className: GetSelectedLanguage() === "en" ? "" : "ur",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setLoading(true);
          ApiLetterForCounselling()
            .delete(value)
            .then((res) => {
              swal({
                title: t("settings_letter_for_counselling_delete_success"),
                className: GetSelectedLanguage() === "en" ? "" : "ur",
                icon: "success",
                buttons: "OK",
              });
              setLoading(false);
              setReload(!reload);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        }
      });
    }
  };

  const handleOnEdit = (e) => {
    const { value } = e.target;
    setEditValue(value);
    setEditModal(true);
  };

  const handleQuestions = (id, title) => {
    setEditValue(id);
    setEditTitle(title);
    setQuestionsModal(true);
  };
  return (
    <>
      {addModal ? (
        <AddLetterForCounselling
          setAddModal={setAddModal}
          setReload={setReload}
          reload={reload}
        />
      ) : null}
      {editModal ? (
        <EditLetterForCounselling
          editValue={editValue}
          setEditModal={setEditModal}
          setReload={setReload}
          reload={reload}
        />
      ) : null}
      {questionsModal ? (
        <LetterForCounsellingQuestions
          setQuestiionsModal={setQuestionsModal}
          lfcId={editValue}
          lfcTitle={editTitle}
        />
      ) : null}
      {!loading ? (
        <div className="ibox">
          <div className="ibox-title">
            <h5>
              {<GetLanguageString props="settings_letter_for_counsellings" />}
            </h5>
            <Button
              color="white"
              size="sm"
              className={GetCustomStyles().btn_style}
              onClick={() => setAddModal(true)}
            >
              <IoAdd
                size={18}
                color="#18A689"
                style={{ marginRight: 3, marginBottom: 1 }}
              />
              <GetLanguageString props="settings_letter_for_counselling_add" />
            </Button>
          </div>
          <div className="ibox-content">
            {letterForCounselling.length !== 0 ? (
              <div className="table-responsive">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th className="text-center">
                        {<GetLanguageString props="settings_title" />}
                      </th>
                      <th className="text-center">
                        {
                          <GetLanguageString props="member_member_detail_course_start_date" />
                        }
                      </th>
                      <th className="text-center">
                        {
                          <GetLanguageString props="member_member_detail_course_end_date" />
                        }
                      </th>
                      <th className="text-center">
                        {<GetLanguageString props="settings_code" />}
                      </th>
                      <th className="text-center">
                        {
                          <GetLanguageString props="settings_event_type_frequency" />
                        }
                      </th>
                      <th className="text-center">
                        {
                          <GetLanguageString props="settings_letter_for_counselling_default_rating_min" />
                        }
                      </th>
                      <th className="text-center">
                        {
                          <GetLanguageString props="settings_letter_for_counselling_default_rating_max" />
                        }
                      </th>
                      <th className="text-center">
                        {
                          <GetLanguageString props="settings_letter_for_counselling_archive" />
                        }
                      </th>
                      <th className="text-center">
                        <GetLanguageString props="member_address_enabled" />
                      </th>
                      <th className="text-center">
                        <GetLanguageString props="settings_letter_for_counselling_page_color" />
                      </th>
                      <th className="text-center">
                        {<GetLanguageString props="common_action" />}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {letterForCounselling.map((record, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="ur text-center">{record.title}</td>
                          <td className="text-center">
                            {record.startDate
                              ? moment(
                                  convertUTCToLocalTime(record.startDate)
                                ).format("DD/MM/yyyy")
                              : "-"}
                          </td>
                          <td className="text-center">
                            {record.endDate
                              ? moment(
                                  convertUTCToLocalTime(record.endDate)
                                ).format("DD/MM/yyyy")
                              : "-"}
                          </td>
                          <td className="text-center">{record.code}</td>
                          <td className="text-center">
                            {
                              <GetLanguageString
                                props={record.frequency.label}
                              />
                            }
                          </td>
                          <td className="text-center">
                            {record.defaultRatingMin}
                          </td>
                          <td className="text-center">
                            {record.defaultRatingMax}
                          </td>
                          <td className="text-center">
                            {record.isArchived ? (
                              <GetLanguageString props="Yes" />
                            ) : (
                              <GetLanguageString props="No" />
                            )}
                          </td>
                          <td className="text-center">
                            {record.isEnabled ? (
                              <GetLanguageString props="Yes" />
                            ) : (
                              <GetLanguageString props="No" />
                            )}
                          </td>{" "}
                          <td className="p-t-custom text-center">
                            <div
                              style={{
                                width: "25px",
                                height: "25px",
                                borderRadius: "50%",
                                backgroundColor: record.pageColor,
                              }}
                            >
                              {" "}
                              <span
                                className={
                                  GetSelectedLanguage() === "en"
                                    ? "m-l-lg"
                                    : "m-r-lg"
                                }
                              >
                                {record.pageColor}
                              </span>
                            </div>
                          </td>
                          <td className="text-center">
                            <ButtonGroup>
                              <Button
                                color="white"
                                size="sm"
                                onClick={() =>
                                  handleQuestions(record.id, record.title)
                                }
                                value={record.id}
                              >
                                {
                                  <GetLanguageString props="settings_letter_for_counselling_see_questions" />
                                }
                              </Button>
                              <Button
                                color="white"
                                size="sm"
                                onClick={handleOnEdit}
                                value={record.id}
                              >
                                {<GetLanguageString props="common_edit" />}
                              </Button>
                              <Button
                                color="white"
                                size="sm"
                                onClick={() =>
                                  handleOnDelete(record.id, record.isAffiliated)
                                }
                              >
                                {<GetLanguageString props="common_delete" />}
                              </Button>
                            </ButtonGroup>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            ) : (
              <NoResult />
            )}
          </div>
        </div>
      ) : (
        LoadingSpinner()
      )}
    </>
  );
};

export default LetterForCounselling;
