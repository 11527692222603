import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { Table, Button, ButtonGroup } from "reactstrap";
import { useTranslation } from "react-i18next";

import { requestApi } from "../../../actions/api";
import EditMemberType from "./EditMember";
import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import {
  GetLanguageString,
  NoResult,
  GetSortingUI,
} from "../../helper/Components";
import { LoadingSpinner } from "../../../constants/const";
import useSorting from "../../shared/useSorting";
import AddMemberType from "./AddMember";
import { IoAdd } from "react-icons/io5";

const initialValues = {
  name: "neutral",
};

const MemberType = ({ activeTab }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [memberType, setMemberType] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [editValue, setEditValue] = useState();
  const [addModal, setAddModal] = useState(false);
  const { sortObject, handleSorting } = useSorting(initialValues);

  useEffect(() => {
    if (activeTab === "member") {
      setLoading(true);
      let tempSortObject = {
        isTypeAscending: sortObject.name === "ascending",
        isTypeDescending: sortObject.name === "descending",
      };
      requestApi("membertype/")
        .fetchSorted(tempSortObject)
        .then((res) => {
          setMemberType(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [sortObject, activeTab, reload]);

  const handleOnDelete = (value, isAffiliated) => {
    if (isAffiliated) {
      swal({
        title: t("common_is_affiliated"),
        text: t("common_is_affiliated_message"),
        icon: "warning",
      });
    } else {
      swal({
        title: t("common_delete_message"),
        text: t("common_delete_detail_message"),
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setLoading(true);
          requestApi("membertype/")
            .delete(value)
            .then((res) => {
              setReload(!reload);
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        }
      });
    }
  };

  const handleOnEdit = (e) => {
    const { value } = e.target;
    setEditValue(value);
    setEditModal(true);
  };

  return (
    <>
      {addModal ? (
        <AddMemberType
          setModal={setAddModal}
          setMemberType={setMemberType}
          setReload={setReload}
          reload={reload}
        />
      ) : null}
      {editModal ? (
        <EditMemberType
          editValue={editValue}
          setEditModal={setEditModal}
          setMemberType={setMemberType}
          setReload={setReload}
          reload={reload}
        />
      ) : null}
      <div className="ibox">
        <div className="ibox-title">
          <h5>{<GetLanguageString props="settings_member_type" />}</h5>
          <Button
            color="white"
            size="sm"
            className={GetCustomStyles().btn_style}
            onClick={() => setAddModal(true)}
          >
            <IoAdd
              size={18}
              color="#18A689"
              style={{ marginRight: 3, marginBottom: 1 }}
            />
            <GetLanguageString props="settings_add_member_type" />
          </Button>
        </div>
        {!loading ? (
          memberType.length !== 0 ? (
            <div className="ibox-content">
              <div className="table-responsive">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th
                        className="cursor-pointer"
                        onClick={() => handleSorting("name")}
                      >
                        <GetLanguageString props="common_name" />
                        <GetSortingUI sortObject={sortObject} name="name" />
                      </th>
                      <th>
                        <GetLanguageString props="settings_code" />
                      </th>
                      <th>
                        <GetLanguageString props="settings_color_code" />
                      </th>
                      <th>
                        <GetLanguageString props="settings_gender" />
                      </th>
                      <th className="text-right">
                        {<GetLanguageString props="common_action" />}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {memberType.map((record, index) => {
                      return (
                        <tr key={index}>
                          <td className="p-t-custom">{index + 1}</td>
                          <td className="ur p-t-custom">{record.type}</td>
                          <td className="p-t-custom">{record.code}</td>
                          <td className="p-t-custom">
                            <div
                              style={{
                                width: "25px",
                                height: "25px",
                                borderRadius: "50%",
                                backgroundColor: record.color,
                              }}
                            >
                              {" "}
                              <span
                                className={
                                  GetSelectedLanguage() === "en"
                                    ? "m-l-lg"
                                    : "m-r-lg"
                                }
                              >
                                {record.color}
                              </span>
                            </div>
                          </td>
                          <td className="p-t-custom">
                            {record.gender === "m" ? (
                              <GetLanguageString props="settings_gender_male" />
                            ) : (
                              <GetLanguageString props="settings_gender_female" />
                            )}
                          </td>
                          <td className="text-right">
                            <ButtonGroup>
                              <Button
                                color="white"
                                size="sm"
                                onClick={handleOnEdit}
                                value={record.id}
                              >
                                {<GetLanguageString props="common_edit" />}
                              </Button>
                              <Button
                                color="white"
                                size="sm"
                                onClick={() =>
                                  handleOnDelete(record.id, record.isAffiliated)
                                }
                              >
                                {<GetLanguageString props="common_delete" />}
                              </Button>
                            </ButtonGroup>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          ) : (
            <NoResult />
          )
        ) : (
          LoadingSpinner()
        )}
      </div>
    </>
  );
};

export default MemberType;
