import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import MemberUnitList from "../MemberUnit/MemberUnitList";

const UpdateMemberUnit = ({
  tab9,
  memberId,
  addNewRafiq,
  setUpdatedDetails,
  activeTab,
  setDiscard,
  discard,
}) => {
  useEffect(() => {
    // Check if the active tab has changed
    if (tab9 === "active") {
      if (activeTab && activeTab !== "tab9") {
        setDiscard(!discard);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);
  return (
    <div role="tabpanel" id="tab-9" className={"tab-pane " + tab9}>
      <MemberUnitList
        tab9={tab9}
        memberId={memberId}
        addNewRafiq={addNewRafiq}
        setUpdatedDetails={setUpdatedDetails}
      />
    </div>
  );
};

export default withRouter(UpdateMemberUnit);
