import React from "react";
import { withRouter } from "react-router-dom";
import App from "../../App";
import { encryptStorage } from "../../constants/EncryptStorage";

function Logout({ history }, setDoLogout) {
  const selectedLanguage = localStorage.getItem("i18nextLng");
  localStorage.clear();
  encryptStorage.clear();
  history.replace("/");
  localStorage.setItem("i18nextLng", selectedLanguage);
  window.location.reload();
  setDoLogout(false);
  return <App />;
}

export default withRouter(Logout);
