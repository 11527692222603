import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import { GetLanguageString } from "../../helper/Components";
import { addMember, setupApi } from "../ApiMember";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import {
  CheckIsAdmin,
  CheckPermission,
  LoadingSpinner,
  isUrdu,
} from "../../../constants/const";
import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isEqual } from "lodash";

const UpdateMaritalStatus = ({ history, tab2, memberId, addNewRafiq, 
  activeTab,
  setActiveTab,
  discard,
  setDiscard }) => {
  const notify = (string) => toast.success(string);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [maritalStatusList, setMaritalStatusList] = useState();
  const [originalMaritalStatus, setOriginalMaritalStatus] = useState({
    maritalStatusId: 0,
    memberId: memberId,
    maritalStatus: {},
    rafiqSons: 0,
    rafiqDaughters: 0,
    description: "",
    referenceNote: "",
  });
  const [maritalStatus, setMaritalStatus] = useState({
    maritalStatusId: 0,
    memberId: memberId,
    maritalStatus: {},
    rafiqSons: 0,
    rafiqDaughters: 0,
    description: "",
    referenceNote: "",
  });
  const [errors, setErrors] = useState({
    ...maritalStatus,
    marital: "",
  });

  const validate = () => {
    let temp = {};
    temp.marital =
      Object.keys(maritalStatus?.maritalStatus).length !== 0 ? (
        ""
      ) : (
        <GetLanguageString props="member_member_details_marital_status_error_message" />
      );

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  useEffect(() => {
    if (tab2 === "active") {
      setupApi()
        .getMaritalStatus()
        .then((res) => {
          setMaritalStatusList(res.data);
        })
        .catch((err) => console.log(err));

      addMember()
        .getMemberMaritalStatusDetail(memberId)
        .then((res) => {
          setMaritalStatus(res.data);
          setOriginalMaritalStatus(res.data);
        })
        .catch((err) => {
          console.log(err);

          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
        });
    }
  }, [memberId, tab2]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let v= value;
    if((name === "rafiqSons" || name === "rafiqDaughters") && value){
      v = parseInt(value);
    }
    setMaritalStatus({
      ...maritalStatus,
      [name]: v,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    apiCall();
    setOriginalMaritalStatus(maritalStatus);
  };
  const apiCall =()=>{
    if (validate()) {
      setLoading(true);
      addMember()
        .addMemberMaritalStatus(addNewRafiq, maritalStatus)
        .then((res) => {
          if (res.data === "") {
            swal({
              title: t("member_member_marital_status_added_success_message"),
              className: GetSelectedLanguage() === "en" ? "" : "ur",
              icon: "success",
              buttons: "OK",
            }).then((ok) => {
              setDiscard(!discard);
            });
          } else notify(res.data);

          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          }).then((ok) => {
            setActiveTab("tab2");
          });
        });
    }
  }

  const handleCancel = (e) => {
      const isMaritalStatusChanged = isEqual(originalMaritalStatus, maritalStatus);
      if (!isMaritalStatusChanged) {
        swal({
          title: "Discard changes?",
          text: "Do you want to discard changes or save before canceling?",
          icon: "warning",
          buttons: ["Save Changes", "Discard"],
          closeOnClickOutside: false,
          dangerMode: true,
        }).then((willDiscard) => {
          if (willDiscard) {
            history.goBack(); // Process with cancel
          } else {
            handleSubmit(e); // Call the save function when the user chooses not to discard changes
          }
        });
      } else {
        history.goBack(); // No changes, directly go back
      }
  };

  useEffect(() => {
    if(tab2 === "active"){
    // Check if the active tab has changed
    if (activeTab && activeTab !== "tab2"){
    const isMaritalStatusChanged = !isEqual(originalMaritalStatus, maritalStatus); // JSON.stringify(maritalStatus) === JSON.stringify maritalStatus);

      if (isMaritalStatusChanged) {
        swal({
          title: "Discard changes?",
          text: "Do you want to discard changes or save before canceling for Martial Status?",
          icon: "warning",
          buttons: ["Save Changes", "Discard"],
          closeOnClickOutside: false,
          dangerMode: true,
        }).then((willDiscard) => {
          if (willDiscard) {
             // Process with cancel
            setDiscard(!discard);
            setOriginalMaritalStatus("");
            setMaritalStatus("");
          } else {
            apiCall();
          }
        });
      } else  {
        setDiscard(!discard);
      }
    } 
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <div role="tabpanel" id="tab-2" className={"tab-pane " + tab2}>
      {!loading ? (
        <Form onSubmit={handleSubmit}>
          <div className="panel-body">
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label className="col-form-label">
                    {<GetLanguageString props="member_marital_status" />}
                  </Label>
                  <Select
                    options={maritalStatusList}
                    value={maritalStatus.maritalStatus}
                    className="basic-single ur"
                    classNamePrefix="select"
                    placeholder={
                      GetSelectedLanguage() === "en" ? "Select" : "منتخب کریں"
                    }
                    onChange={(ms) => {
                      setMaritalStatus({
                        ...maritalStatus,
                        maritalStatus: ms,
                      });
                      setErrors({
                        ...errors,
                        marital: "",
                      });
                    }}
                  />
                  {errors.marital && (
                    <h4 className="text-error">{errors.marital}</h4>
                  )}
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label className="col-form-label">
                    {<GetLanguageString props="member_marital_status_sons" />}
                  </Label>
                  <Input
                    type="number"
                    name="rafiqSons"
                    value={maritalStatus.rafiqSons}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label className="col-form-label">
                    {
                      <GetLanguageString props="member_marital_status_daughters" />
                    }
                  </Label>
                  <Input
                    type="number"
                    name="rafiqDaughters"
                    value={maritalStatus.rafiqDaughters}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label className="col-form-label">
                    {<GetLanguageString props="common_description" />}
                  </Label>
                  <Input
                    type="text"
                    className={isUrdu(maritalStatus.description)?  "ur" : "" }
                    name="description"
                    value={maritalStatus.description}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label className="col-form-label">
                    {<GetLanguageString props="member_reference_note" />}
                  </Label>
                  <Input
                    type="text"
                    className={isUrdu(maritalStatus.referenceNote)?  "ur" : "" }
                    name="referenceNote"
                    value={maritalStatus.referenceNote}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="8"></Col>
              <Col md="4">
                <div className={GetCustomStyles().btn_style}>
                  <Button color="white" onClick={handleCancel}>
                    {<GetLanguageString props="common_cancel" />}
                  </Button>
                  <Button color="primary" className="m-l-sm" type="submit">
                    {<GetLanguageString props="common_save_changes" />}
                  </Button>
                </div>
              </Col>
            </Row>
            {CheckPermission("CanRequestMemberModificationPersonalInfo") &&
            !CheckIsAdmin() &&
            !addNewRafiq ? (
              <div>
                <h4 className="text-error">* Request Modification</h4>
              </div>
            ) : null}
          </div>
        </Form>
      ) : (
        LoadingSpinner()
      )}
    </div>
  );
};

export default withRouter(UpdateMaritalStatus);
