import React, { useState, useEffect } from "react";
import Select from "react-select";

import useForm from "../../shared/useForm";
import { ApiUnit } from "../../unit/ApiUnit";
import {
  Button,
  Table,
  Row,
  Input,
  Col,
  FormGroup,
  Form,
  Label,
  ButtonGroup,
} from "reactstrap";
import { PDFExport } from "@progress/kendo-react-pdf";
import { saveAs } from "file-saver";

import {
  GetLanguageString,
  NoResult,
  SetReportTitle,
} from "../../helper/Components";
import { GetCustomStyles } from "../../helper/Method";
import { LoadingSpinner, isUrdu } from "../../../constants/const";
import { encryptStorage } from "../../../constants/EncryptStorage";
// import { utils, writeFile } from 'xlsx';
import { addMember } from "../../member/ApiMember";
import { FcPrint } from "react-icons/fc";
import { RiFileExcel2Line } from "react-icons/ri";

const UnitMemberReport = () => {
  const [nonResponsibleLoading, setNonResponsibleLoading] = useState(false);
  const [subUnitLoading, setSubUnitLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [excludeExUnit, setExcludeExUnit] = useState(true);
  const [showCnic, setShowCnic] = useState(false);
  const [responsibleLoading, setResponsibleLoading] = useState(false);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [responsibleMembers, setResponsibleMembers] = useState([]);
  const [nonResponsibleMembers, setNonResponsibleMembers] = useState([]);
  const [unitMembers, setUnitMembers] = useState([]);
  const [reportTitle, setReportTitle] = useState("");
  const [memberDetails, setMemberDetails] = useState({});
  const [modal, setModal] = useState(false);
  const [searchButton, setSearchButton] = useState(false);
  const [reportSearchMemberModel, setReportSearchMemberModel] = useState({
    isTawajahTalab: false,
    isMutazir: false,
    unitId: loggedInUnitId,
    unitName: "",
  });
  let className = "";
  const unitTypeName = "null";
  // const [excelData, setExcelData] = useState([{}]);

  const pdfExportComponent = React.useRef(null);

  // const exportPDFWithComponent = () => {
  //   if (pdfExportComponent.current) {
  //     pdfExportComponent.current.save();
  //   }
  // };
  const [unitList, setUnitList] = useState([]);
  const { values, setValues } = useForm(reportSearchMemberModel);

  useEffect(() => {
    ApiUnit()
      .getUnitList(
        loggedInUnitId,
        loggedInUnitId,
        false,
        unitTypeName,
        excludeExUnit
      )
      .then((res) => {
        setUnitList(res.data);
      })
      .catch((err) => console.log(err));
  }, [loggedInUnitId, excludeExUnit]);

  const handleChangeUnits = (record) => {
    if (record !== null) {
      ApiUnit()
        .getUnitList(
          record.value,
          loggedInUnitId,
          false,
          unitTypeName,
          excludeExUnit
        )
        .then((res) => {
          setUnitList(res.data);
        })
        .catch((err) => console.log(err));
      setValues({
        ...values,
        unitId: record.value,
        unitName: record.label,
      });
    } else {
      ApiUnit()
        .getUnitList(
          loggedInUnitId,
          loggedInUnitId,
          false,
          unitTypeName,
          excludeExUnit
        )
        .then((res) => {
          setUnitList(res.data);
        })
        .catch((err) => console.log(err));
      setValues({
        ...values,
        unitId: 0,
      });
    }
  };

  const handleCheckbox = (e) => {
    const { name, value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setValues({
      ...values,
      [name]: v,
    });
  };

  const handleShowCnic = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setShowCnic(v);
  };

  const formatCNIC = (cnic) => {
    if (cnic && cnic.length === 15 && cnic[5] === "-" && cnic[13] === "-") {
      return cnic;
    }
    return `${cnic.slice(0, 5)}-${cnic.slice(5, 12)}-${cnic.slice(12)}`;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSearchButton(true);
    if (values.unitName !== "") setReportSearchMemberModel(values);
    else {
      setReportSearchMemberModel({
        ...values,
        unitName: unitList[0]?.unitList[0]?.name,
      });
    }
  };

  useEffect(() => {
    if (reportSearchMemberModel.unitId > 0 && searchButton) {
      setLoading(true);
      addMember()
        .getMembersCustomReport(reportSearchMemberModel)
        .then((res) => {
          setMemberDetails(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  }, [reportSearchMemberModel, searchButton]);

  useEffect(() => {
    if (reportSearchMemberModel.unitId > 0 && searchButton) {
      setResponsibleLoading(true);
      addMember()
        .getResponsibleMembersCustomReport(reportSearchMemberModel)
        .then((res) => {
          setResponsibleMembers(res.data.responsibleMembers);
          // let temp2 = [];
          // res.data.responsibleMembers?.forEach((m, index) =>
          //   temp2.push({
          //     SNo: index + 1,
          //     RegNo: m.regNo,
          //     Name: m.name,
          //     Address: m.currentAddress,
          //     Phone: m.phone,
          //     RafeeqType: m.memberTypeName,
          //     Units: m.unitName,
          //   })
          // );
          // setExcelData(temp2);
          setResponsibleLoading(false);
        })
        .catch((err) => {
          setResponsibleLoading(false);
          console.log(err);
        });
    }
  }, [reportSearchMemberModel, searchButton]);

  useEffect(() => {
    if (reportSearchMemberModel.unitId > 0 && searchButton) {
      setNonResponsibleLoading(true);
      addMember()
        .getNonResponsibleMembersCustomReport(reportSearchMemberModel)
        .then((res) => {
          setNonResponsibleMembers(res.data.nonResponsibleMembers);
          setNonResponsibleLoading(false);
        })
        .catch((err) => {
          setNonResponsibleLoading(false);
          console.log(err);
        });
    }
  }, [reportSearchMemberModel, searchButton]);

  useEffect(() => {
    if (reportSearchMemberModel.unitId > 0 && searchButton) {
      setSubUnitLoading(true);
      addMember()
        .getSubUnitMembersCustomReport(reportSearchMemberModel)
        .then((res) => {
          setUnitMembers(res.data.unitMembers);
          setSubUnitLoading(false);
        })
        .catch((err) => {
          setSubUnitLoading(false);
          console.log(err);
        });
    }
  }, [reportSearchMemberModel, searchButton]);

  useEffect(() => {
    if (reportTitle !== "") window.print();
  }, [reportTitle]);

  const handleReportTitle = () => {
    setModal(true);
  };

  const excelDownload = () => {
    memberDetails.unitName = reportSearchMemberModel.unitName;
    memberDetails.responsibleMembers = responsibleMembers;
    memberDetails.nonResponsibleMembers = nonResponsibleMembers;
    memberDetails.unitMembers = unitMembers;
    memberDetails.showCnic = showCnic;

    addMember()
      .generateMembersCustomReport(memberDetails)
      .then((res) => {
        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "MemberReport.xlsx");
      })
      .catch((err) => console.log(err));
  };

  const handleExcludeExUnit = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setExcludeExUnit(v);
  };

  return (
    <div>
      {modal ? (
        <SetReportTitle setModal={setModal} setReportTitle={setReportTitle} />
      ) : null}
      <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
        <div className="ibox noprint">
          <div className="ibox-title">
            <h5>{<GetLanguageString props="common_generate_report" />}</h5>
          </div>
          <div className="ibox-content">
            <Form onSubmit={handleSubmit}>
              <Row>
                {unitList.map((record, index) => {
                  const unitOptions = [];
                  record.unitList.forEach((d) =>
                    unitOptions.push({ value: d.id, label: d.name })
                  );
                  let clearable = false;
                  if (index === 0) clearable = true;
                  let selected = {};
                  let unitName = "";
                  if (record.parentUnitName !== null)
                    unitName = record.parentUnitName;
                  if (record.selectedUnit !== null) {
                    selected = {
                      value: record.selectedUnit.id,
                      label: record.selectedUnit.name,
                    };
                  }

                  return (
                    <Col md="2" key={index}>
                      <FormGroup>
                        <Label className="col-form-label">
                          {unitName !== "" ? (
                            <GetLanguageString
                              props="member_select_unit_name"
                              value={unitName}
                            />
                          ) : (
                            <GetLanguageString props="member_select_unit" />
                          )}
                        </Label>
                        <Select
                          options={unitOptions}
                          value={selected}
                          className="basic-single ur"
                          classNamePrefix="select"
                          isClearable={clearable}
                          onChange={handleChangeUnits}
                        />
                      </FormGroup>
                    </Col>
                  );
                })}
              </Row>
              <Row className="m-t-sm">
                <Col md="2">
                  <FormGroup check>
                    <Label check for="excludeExUnit">
                      <Input
                        id="excludeExUnit"
                        type="checkbox"
                        name="excludeExUnit"
                        checked={!excludeExUnit}
                        value={!excludeExUnit}
                        onChange={handleExcludeExUnit}
                      />
                      {
                        <GetLanguageString props="member_search_member_ex_unit" />
                      }
                    </Label>
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup check>
                    <Label check for="isTawajahTalab">
                      <Input
                        id="isTawajahTalab"
                        type="checkbox"
                        name="isTawajahTalab"
                        checked={values.isTawajahTalab}
                        value={!values.isTawajahTalab}
                        onChange={handleCheckbox}
                      />
                      {
                        <GetLanguageString props="report_member_show_tawajah_talab" />
                      }
                    </Label>
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup check>
                    <Label check for="isMutazir">
                      <Input
                        id="isMutazir"
                        type="checkbox"
                        name="isMutazir"
                        checked={values.isMutazir}
                        value={!values.isMutazir}
                        onChange={handleCheckbox}
                      />
                      {<GetLanguageString props="report_member_show_motazir" />}
                    </Label>
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup check>
                    <Label check for="showCnic">
                      <Input
                        id="showCnic"
                        type="checkbox"
                        name="showCnic"
                        checked={showCnic}
                        value={!showCnic}
                        onChange={handleShowCnic}
                      />
                      {<GetLanguageString props="report_member_show_cnic" />}
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="m-t-md">
                <Col className="text-center">
                  <FormGroup>
                    <Button color="primary" className="m-l-sm" type="submit">
                      {<GetLanguageString props="common_view_report" />}
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
        {searchButton ? (
          <div className="ibox">
            <div className="ibox-title">
              <ButtonGroup className={GetCustomStyles().btn_style}>
                <Button color="default" size="sm" onClick={excelDownload}>
                  <RiFileExcel2Line
                    size={15}
                    color="#009440"
                    style={{ marginRight: 3, marginBottom: 1 }}
                  />
                  {<GetLanguageString props="member_report_export_excel" />}
                </Button>
                <Button color="default" size="sm" onClick={handleReportTitle}>
                  <FcPrint size={15} className={GetCustomStyles().fa_style} />
                  {<GetLanguageString props="common_print" />}
                </Button>
              </ButtonGroup>
            </div>
            <PDFExport
              ref={pdfExportComponent}
              fileName="CustomMembersReport"
              scale={0.6}
              paperSize="A4"
              alignItems="center"
            >
              <div className="ibox-content ibox-center" id="section-to-print">
                <Row className="print">
                  <div className="text-center">
                    <h3>Title : {reportTitle}</h3>
                  </div>
                </Row>
                {!loading ? (
                  <>
                    <h1 className="text-center ur">
                      {" "}
                      {reportSearchMemberModel.unitName}
                    </h1>
                    {memberDetails?.unitAmeerName !== null &&
                    memberDetails?.unitAmeerName !== "" ? (
                      <h2 className="text-center">
                        <span className="ur">
                          {memberDetails?.unitAmeerResponsibilityName} :{" "}
                          {memberDetails?.unitAmeerName}
                        </span>
                      </h2>
                    ) : null}
                    <div className="panel panel-success">
                      <div className="panel-heading">
                        <h3 className="text-center">
                          <GetLanguageString props="report_member_members_details" />
                        </h3>
                      </div>
                      <div className="panel-body">
                        <h3 className="text-center">
                          {" "}
                          <GetLanguageString props="report_member_total_members" />{" "}
                          : {memberDetails?.totalUnitMember}
                        </h3>
                        <Row className="text-center">
                          <Col md="6">
                            <h4>
                              {" "}
                              <GetLanguageString props="report_member_mubtadi" />{" "}
                              : {memberDetails?.mubtadiUnitMember}
                            </h4>
                          </Col>
                          <Col md="6">
                            <h4>
                              {" "}
                              <GetLanguageString props="report_member_mubtadi_tarbiyati" />{" "}
                              : {memberDetails?.mubtadiTarbiyatiParticipants}
                            </h4>
                          </Col>
                        </Row>
                        <Row className="text-center">
                          <Col md="6">
                            <h4>
                              {" "}
                              <GetLanguageString props="report_member_multazim" />{" "}
                              : {memberDetails?.multazimUnitMember}
                            </h4>
                          </Col>
                          <Col md="6">
                            <h4>
                              {" "}
                              <GetLanguageString props="report_member_multazim_tarbiyati" />{" "}
                              : {memberDetails?.multazimTarbiyatiParticipants}
                            </h4>
                          </Col>
                        </Row>
                        <Row className="text-center">
                          <Col md="6">
                            <h4>
                              {" "}
                              <GetLanguageString props="report_member_mutazir" />{" "}
                              : {memberDetails?.mutazirUnitMember}
                            </h4>
                          </Col>
                          <Col md="6">
                            <h4>
                              {" "}
                              <GetLanguageString props="report_member_tawajah_talab" />{" "}
                              : {memberDetails?.tawajahTalabUnitMember}
                            </h4>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </>
                ) : (
                  LoadingSpinner()
                )}
                {!responsibleLoading ? (
                  <>
                    {responsibleMembers.length !== 0 ? (
                      <div className="panel panel-success">
                        <div className="panel-heading">
                          <h3 className="text-center">
                            <GetLanguageString props="report_member_responsible_members" />
                          </h3>
                        </div>
                        <div className="table-responsive">
                          <Table className="table-bordered m-b-none">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>
                                  <GetLanguageString props="member_reg_number" />
                                </th>
                                <th>
                                  <GetLanguageString props="common_name" />
                                </th>
                                <th>
                                  <GetLanguageString props="member_father_name" />
                                </th>
                                {showCnic ? (
                                  <th>
                                    <GetLanguageString props="member_cnic" />
                                  </th>
                                ) : null}
                                <th>
                                  {<GetLanguageString props="member_address" />}
                                </th>
                                <th>
                                  {<GetLanguageString props="member_phone" />}
                                </th>
                                <th>
                                  <GetLanguageString props="settings_member_type" />
                                </th>
                                <th>
                                  <GetLanguageString props="member_member_detail_common_responsibility" />
                                </th>
                                <th>
                                  {" "}
                                  <GetLanguageString props="report_member_tarbiati_course" />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {responsibleMembers?.map((record, index) => {
                                index === 0
                                  ? (className = "highlight-row")
                                  : (className = "");
                                return (
                                  <tr key={index} className={className}>
                                    <td>{index + 1}</td>
                                    <td>{record.regNo}</td>
                                    <td className="ur">{record.name}</td>
                                    <td className="ur">{record.fatherName}</td>
                                    {showCnic ? (
                                      <td>
                                        {record.cnic !== null &&
                                        record.cnic !== ""
                                          ? formatCNIC(record.cnic)
                                          : " - "}
                                      </td>
                                    ) : null}
                                    <td
                                      className={
                                        isUrdu(record.currentAddress)
                                          ? "ur"
                                          : ""
                                      }
                                    >
                                      {record.currentAddress}
                                    </td>
                                    <td>
                                      {record.phone != null
                                        ? record.phone
                                            .split(",")
                                            .map((phoneNumber, index) => (
                                              <div key={index}>
                                                {phoneNumber}
                                              </div>
                                            ))
                                        : ""}
                                    </td>
                                    <td className="ur">
                                      {record.memberTypeName}
                                    </td>
                                    <td>
                                      {record.responsbilities.map(
                                        (item, index) => {
                                          return (
                                            <h4 className="ur" key={index}>
                                              {item}
                                            </h4>
                                          );
                                        }
                                      )}
                                    </td>
                                    <td className="text-center">
                                      {record.tarbiatiCourse ? (
                                        <i
                                          className={"fa fa-2x fa-check "}
                                          style={{ color: "green" }}
                                        />
                                      ) : (
                                        <i
                                          className={"fa fa-2x fa-times "}
                                          style={{ color: "red" }}
                                        />
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    ) : null}
                  </>
                ) : (
                  LoadingSpinner()
                )}
                {!nonResponsibleLoading ? (
                  <>
                    {nonResponsibleMembers.length !== 0 ? (
                      <div className="panel panel-success">
                        <div className="panel-heading">
                          <h3 className="text-center">
                            <GetLanguageString props="report_member_members" />
                          </h3>
                        </div>
                        <div className="table-responsive">
                          <Table className="table-bordered m-b-none">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>
                                  <GetLanguageString props="member_reg_number" />
                                </th>
                                <th>
                                  <GetLanguageString props="common_name" />
                                </th>
                                <th>
                                  <GetLanguageString props="member_father_name" />
                                </th>
                                {showCnic ? (
                                  <th>
                                    <GetLanguageString props="member_cnic" />
                                  </th>
                                ) : null}
                                <th>
                                  {<GetLanguageString props="member_address" />}
                                </th>
                                <th>
                                  {<GetLanguageString props="member_phone" />}
                                </th>
                                <th>
                                  <GetLanguageString props="settings_member_type" />
                                </th>
                                <th>
                                  {" "}
                                  <GetLanguageString props="report_member_tarbiati_course" />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {nonResponsibleMembers?.map((record, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{record.regNo}</td>
                                    <td className="ur">{record.name}</td>
                                    <td className="ur">{record.fatherName}</td>

                                    {showCnic ? (
                                      <td>
                                        {record.cnic !== null &&
                                        record.cnic !== ""
                                          ? formatCNIC(record.cnic)
                                          : " - "}
                                      </td>
                                    ) : null}
                                    <td
                                      className={
                                        isUrdu(record.currentAddress)
                                          ? "ur"
                                          : ""
                                      }
                                    >
                                      {record.currentAddress}
                                    </td>
                                    <td>
                                      {record.phone != null
                                        ? record.phone
                                            .split(",")
                                            .map((phoneNumber, index) => (
                                              <div key={index}>
                                                {phoneNumber}
                                              </div>
                                            ))
                                        : ""}
                                    </td>
                                    <td className="ur">
                                      {record.memberTypeName}
                                    </td>
                                    <td className="text-center">
                                      {record.tarbiatiCourse ? (
                                        <i
                                          className={"fa fa-2x fa-check "}
                                          style={{ color: "green" }}
                                        />
                                      ) : (
                                        <i
                                          className={"fa fa-2x fa-times "}
                                          style={{ color: "red" }}
                                        />
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    ) : null}
                  </>
                ) : (
                  LoadingSpinner()
                )}
                {!subUnitLoading ? (
                  <>
                    {unitMembers.length !== 0 ? (
                      <h1 className="text-center mb-5 mt-4">
                        <GetLanguageString props="report_member_sub_units" />
                      </h1>
                    ) : null}

                    {unitMembers?.map((record, index) => {
                      return (
                        <div key={index} className="panel panel-success">
                          <div className="panel-heading">
                            <h2 className="ur text-center">
                              {record.unitName}
                            </h2>
                          </div>
                          <div className="table-responsive">
                            {record?.unitMembers?.length !== 0 ? (
                              <Table className="table-bordered m-b-none">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>
                                      <GetLanguageString props="member_reg_number" />
                                    </th>
                                    <th>
                                      <GetLanguageString props="common_name" />
                                    </th>
                                    <th>
                                      <GetLanguageString props="member_father_name" />
                                    </th>
                                    {showCnic ? (
                                      <th>
                                        <GetLanguageString props="member_cnic" />
                                      </th>
                                    ) : null}
                                    <th>
                                      {
                                        <GetLanguageString props="member_address" />
                                      }
                                    </th>
                                    <th>
                                      {
                                        <GetLanguageString props="member_phone" />
                                      }
                                    </th>
                                    <th>
                                      <GetLanguageString props="settings_member_type" />
                                    </th>
                                    <th>
                                      <GetLanguageString props="member_member_detail_common_responsibility" />
                                    </th>
                                    <th>
                                      {" "}
                                      <GetLanguageString props="report_member_tarbiati_course" />
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {record?.unitMembers.map((record, index1) => {
                                    index1 === 0
                                      ? (className = "highlight-row")
                                      : (className = "");
                                    return (
                                      <tr key={index1} className={className}>
                                        <td>{index1 + 1}</td>
                                        <td>{record.regNo}</td>
                                        <td className="ur">{record.name}</td>
                                        <td className="ur">
                                          {record.fatherName}
                                        </td>

                                        {showCnic ? (
                                          <td>
                                            {record.cnic !== null &&
                                            record.cnic !== ""
                                              ? formatCNIC(record.cnic)
                                              : " - "}
                                          </td>
                                        ) : null}
                                        <td
                                          className={
                                            isUrdu(record.currentAddress)
                                              ? "ur"
                                              : ""
                                          }
                                        >
                                          {record.currentAddress}
                                        </td>
                                        <td>
                                          {record.phone != null
                                            ? record.phone
                                                .split(",")
                                                .map((phoneNumber, index) => (
                                                  <div key={index}>
                                                    {phoneNumber}
                                                  </div>
                                                ))
                                            : ""}
                                        </td>
                                        <td className="ur">
                                          {record.memberTypeName}
                                        </td>
                                        <td>
                                          {record.responsbilities?.map(
                                            (item, index) => {
                                              return (
                                                <h4 className="ur" key={index}>
                                                  {item}
                                                </h4>
                                              );
                                            }
                                          )}
                                        </td>
                                        <td className="text-center">
                                          {record.tarbiatiCourse ? (
                                            <i
                                              className={"fa fa-2x fa-check "}
                                              style={{ color: "green" }}
                                            />
                                          ) : (
                                            <i
                                              className={"fa fa-2x fa-times "}
                                              style={{ color: "red" }}
                                            />
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            ) : (
                              <NoResult />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  LoadingSpinner()
                )}
                {responsibleMembers.length === 0 &&
                unitMembers.length === 0 &&
                nonResponsibleMembers.length === 0 ? (
                  <NoResult />
                ) : null}
              </div>
            </PDFExport>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default UnitMemberReport;
