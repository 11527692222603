import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Pagination from "react-js-pagination";
import { Button, Table } from "reactstrap";

import { ApiUnit } from "../../../unit/ApiUnit";
import { addMember } from "../../ApiMember";

import { GetLanguageString, NoResult } from "../../../helper/Components";
import { GetCustomStyles, GetSelectedLanguage } from "../../../helper/Method";
import { encryptStorage } from "../../../../constants/EncryptStorage";
import { LoadingSpinner } from "../../../../constants/const";
import { baitHeading } from "../../../../content/css/customCss";
import { saveAs } from "file-saver";

import SearchMembers from "./SearchMembers";
import { RiFileExcel2Line } from "react-icons/ri";
import { FcCheckmark } from "react-icons/fc";
import { RxCross2 } from "react-icons/rx";

const MemberDocs = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [docsReport, setDocsReport] = useState([]);

  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const unitTypeName = encryptStorage.getItem("unitTypeName");

  const [unit, setUnit] = useState({
    unitId: loggedInUnitId,
    unitName: "",
    unitTypeName: unitTypeName,
  });
  const [searchValues, setSearchValues] = useState({
    name: "",
    regNo: "",
    unitId: loggedInUnitId,
    loggedInUnitId: loggedInUnitId,
    isSubUnits: true,
    activePage: 1,
  });

  const [searchButton, setSearchButton] = useState(false);

  const [pagination, setPagination] = useState({ activePage: 1 });
  let recordIndex = pagination.pageSize * (pagination.activePage - 1) + 1;

  const selectedLanguage = GetSelectedLanguage();

  useEffect(() => {
    if (searchButton) {
      setLoading(true);
      addMember()
        .getMemberDocsReport(searchValues)
        .then((res) => {
          setDocsReport(res.data.list);
          setPagination(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [searchValues, searchButton]);

  useEffect(() => {
    if (unit.unitId === 0) {
      ApiUnit()
        .getUnit(loggedInUnitId, loggedInUnitId)
        .then((res) => {
          let temp = res.data.unitList[0];
          setSearchValues({
            ...searchValues,
            unitId: temp.id,
          });
          setUnit({ unitId: temp.id, unitName: temp.name });
        })
        .catch((err) => console.log(err));
    }

    // eslint-disable-next-line
  }, [unit.unitId, loggedInUnitId]);

  const excelDownload = () => {
    if (searchButton) {
      addMember()
        .GetMemberDocsExcelReport(searchValues)
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(blob, "MemberDocs.xlsx");
        })
        .catch((err) => console.log(err));
    }
  };

  const handlePageChange = (pageNumber) => {
    setSearchValues((prevState) => ({
      ...prevState,
      activePage: pageNumber,
    }));

    setPagination((prevPagination) => ({
      ...prevPagination,
      activePage: pageNumber,
    }));
  };

  return (
    <div>
      <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
        <div className="ibox ">
          <div className="ibox-title">
            <h5>{<GetLanguageString props="member_search_member" />}</h5>
          </div>
          <div className="ibox-content">
            <SearchMembers
              unitId={unit.unitId}
              setUnit={setUnit}
              searchValues={searchValues}
              setSearchValues={setSearchValues}
              setSearchButton={setSearchButton}
            />
          </div>
        </div>
        {searchButton ? (
          <div className="ibox">
            <div className="ibox-title">
              <h5>{<GetLanguageString props="member_documents_report" />}</h5>
              {docsReport?.length > 0 ? (
                <Button
                  color="default"
                  size="sm"
                  onClick={excelDownload}
                  className={GetCustomStyles().btn_style}
                >
                  <RiFileExcel2Line
                    size={15}
                    color="#009440"
                    style={{ marginRight: 3, marginBottom: 1 }}
                  />
                  {<GetLanguageString props="member_report_export_excel" />}
                </Button>
              ) : null}
            </div>
            <div className="ibox-content">
              {!loading ? (
                docsReport?.length > 0 ? (
                  <>
                    <Table className="table-bordered m-b-none">
                      <thead>
                        <tr>
                          <th colSpan={5}></th>
                          <th
                            colSpan={4}
                            className="ur text-center"
                            style={baitHeading}
                          >
                            (شیخ شجاع الدین) بیعت ثالث
                          </th>
                          <th
                            colSpan={4}
                            className="ur text-center"
                            style={baitHeading}
                          >
                            (حافظ عاکف سعید) بیعت ثانی
                          </th>
                          <th
                            colSpan={4}
                            className="ur text-center"
                            style={baitHeading}
                          >
                            (ڈاکٹر اسرار احمد ؒ) بیعت اول
                          </th>
                        </tr>
                        <tr
                          style={{
                            height: selectedLanguage === "en" ? "180px" : null,
                          }}
                        >
                          <th
                            className="text-center"
                            style={{
                              paddingTop:
                                selectedLanguage === "en" ? "70px" : null,
                            }}
                          >
                            #
                          </th>
                          <th
                            className="text-center"
                            style={{
                              paddingTop:
                                selectedLanguage === "en" ? "70px" : null,
                            }}
                          >
                            {<GetLanguageString props="member_reg_number" />}
                          </th>
                          <th
                            className="text-center"
                            style={{
                              paddingTop:
                                selectedLanguage === "en" ? "70px" : null,
                            }}
                          >
                            {<GetLanguageString props="common_name" />}
                          </th>
                          <th
                            className="text-center"
                            style={{
                              paddingTop:
                                selectedLanguage === "en" ? "70px" : null,
                            }}
                          >
                            {<GetLanguageString props="member_photo" />}
                          </th>
                          <th
                            className="text-center"
                            style={{
                              paddingTop:
                                selectedLanguage === "en" ? "70px" : null,
                            }}
                          >
                            {<GetLanguageString props="Kawaif Form" />}
                          </th>
                          <th
                            style={{
                              transform:
                                selectedLanguage === "en"
                                  ? "rotate(180deg)"
                                  : null,
                              whiteSpace:
                                selectedLanguage === "en" ? "nowrap" : null,
                              writingMode:
                                selectedLanguage === "en"
                                  ? "vertical-rl"
                                  : null,
                            }}
                          >
                            {
                              <GetLanguageString props="Mubtadi Bait Form Front" />
                            }
                          </th>
                          <th
                            style={{
                              transform:
                                selectedLanguage === "en"
                                  ? "rotate(180deg)"
                                  : null,
                              whiteSpace:
                                selectedLanguage === "en" ? "nowrap" : null,
                              writingMode:
                                selectedLanguage === "en"
                                  ? "vertical-rl"
                                  : null,
                            }}
                          >
                            {
                              <GetLanguageString props="Mubtadi Bait Form Back" />
                            }
                          </th>
                          <th
                            style={{
                              transform:
                                selectedLanguage === "en"
                                  ? "rotate(180deg)"
                                  : null,
                              whiteSpace:
                                selectedLanguage === "en" ? "nowrap" : null,
                              writingMode:
                                selectedLanguage === "en"
                                  ? "vertical-rl"
                                  : null,
                            }}
                          >
                            {
                              <GetLanguageString props="Multazim Bait Form Front" />
                            }
                          </th>
                          <th
                            style={{
                              transform:
                                selectedLanguage === "en"
                                  ? "rotate(180deg)"
                                  : null,
                              whiteSpace:
                                selectedLanguage === "en" ? "nowrap" : null,
                              writingMode:
                                selectedLanguage === "en"
                                  ? "vertical-rl"
                                  : null,
                            }}
                          >
                            {
                              <GetLanguageString props="Multazim Bait Form Back" />
                            }
                          </th>
                          <th
                            style={{
                              transform:
                                selectedLanguage === "en"
                                  ? "rotate(180deg)"
                                  : null,
                              whiteSpace:
                                selectedLanguage === "en" ? "nowrap" : null,
                              writingMode:
                                selectedLanguage === "en"
                                  ? "vertical-rl"
                                  : null,
                            }}
                          >
                            {
                              <GetLanguageString props="Mubtadi Bait Form Front" />
                            }
                          </th>
                          <th
                            style={{
                              transform:
                                selectedLanguage === "en"
                                  ? "rotate(180deg)"
                                  : null,
                              whiteSpace:
                                selectedLanguage === "en" ? "nowrap" : null,
                              writingMode:
                                selectedLanguage === "en"
                                  ? "vertical-rl"
                                  : null,
                            }}
                          >
                            {
                              <GetLanguageString props="Mubtadi Bait Form Back" />
                            }
                          </th>
                          <th
                            style={{
                              transform:
                                selectedLanguage === "en"
                                  ? "rotate(180deg)"
                                  : null,
                              whiteSpace:
                                selectedLanguage === "en" ? "nowrap" : null,
                              writingMode:
                                selectedLanguage === "en"
                                  ? "vertical-rl"
                                  : null,
                            }}
                          >
                            {
                              <GetLanguageString props="Multazim Bait Form Front" />
                            }
                          </th>
                          <th
                            style={{
                              transform:
                                selectedLanguage === "en"
                                  ? "rotate(180deg)"
                                  : null,
                              whiteSpace:
                                selectedLanguage === "en" ? "nowrap" : null,
                              writingMode:
                                selectedLanguage === "en"
                                  ? "vertical-rl"
                                  : null,
                            }}
                          >
                            {
                              <GetLanguageString props="Multazim Bait Form Back" />
                            }
                          </th>
                          <th
                            style={{
                              transform:
                                selectedLanguage === "en"
                                  ? "rotate(180deg)"
                                  : null,
                              whiteSpace:
                                selectedLanguage === "en" ? "nowrap" : null,
                              writingMode:
                                selectedLanguage === "en"
                                  ? "vertical-rl"
                                  : null,
                            }}
                          >
                            {
                              <GetLanguageString props="Mubtadi Bait Form Front" />
                            }
                          </th>
                          <th
                            style={{
                              transform:
                                selectedLanguage === "en"
                                  ? "rotate(180deg)"
                                  : null,
                              whiteSpace:
                                selectedLanguage === "en" ? "nowrap" : null,
                              writingMode:
                                selectedLanguage === "en"
                                  ? "vertical-rl"
                                  : null,
                            }}
                          >
                            {
                              <GetLanguageString props="Mubtadi Bait Form Back" />
                            }
                          </th>
                          <th
                            style={{
                              transform:
                                selectedLanguage === "en"
                                  ? "rotate(180deg)"
                                  : null,
                              whiteSpace:
                                selectedLanguage === "en" ? "nowrap" : null,
                              writingMode:
                                selectedLanguage === "en"
                                  ? "vertical-rl"
                                  : null,
                            }}
                          >
                            {
                              <GetLanguageString props="Multazim Bait Form Front" />
                            }
                          </th>
                          <th
                            style={{
                              transform:
                                selectedLanguage === "en"
                                  ? "rotate(180deg)"
                                  : null,
                              whiteSpace:
                                selectedLanguage === "en" ? "nowrap" : null,
                              writingMode:
                                selectedLanguage === "en"
                                  ? "vertical-rl"
                                  : null,
                            }}
                          >
                            {
                              <GetLanguageString props="Multazim Bait Form Back" />
                            }
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {docsReport?.map((record, index) => {
                          return (
                            <tr key={index}>
                              <td className="text-center">
                                {index + recordIndex}
                              </td>
                              <td className="text-center">{record.regNo}</td>
                              <td
                                className="ur text-center"
                                style={{
                                  minWidth:
                                    selectedLanguage === "ur" ? "100px" : null,
                                }}
                              >
                                {record.name}
                              </td>
                              <td className="text-center">
                                {record.photo ? (
                                  <FcCheckmark
                                    size={20}
                                    color="green"
                                    style={{ marginRight: 3, marginBottom: 1 }}
                                  />
                                ) : (
                                  <RxCross2
                                    size={20}
                                    color="red"
                                    style={{ marginRight: 3, marginBottom: 1 }}
                                  />
                                )}
                              </td>
                              <td className="text-center">
                                {record.kawaifForm ? (
                                  <FcCheckmark
                                    size={20}
                                    color="green"
                                    style={{ marginRight: 3, marginBottom: 1 }}
                                  />
                                ) : (
                                  <RxCross2
                                    size={20}
                                    color="red"
                                    style={{ marginRight: 3, marginBottom: 1 }}
                                  />
                                )}
                              </td>
                              {record.pledgeDocs?.map((docs) => {
                                return (
                                  <>
                                    <td className="text-center">
                                      {docs.mubtadiBaitFormFront ? (
                                        <FcCheckmark
                                          size={20}
                                          color="green"
                                          style={{
                                            marginRight: 3,
                                            marginBottom: 1,
                                          }}
                                        />
                                      ) : (
                                        <RxCross2
                                          size={20}
                                          color="red"
                                          style={{
                                            marginRight: 3,
                                            marginBottom: 1,
                                          }}
                                        />
                                      )}
                                    </td>
                                    <td className="text-center">
                                      {docs.mubtadiBaitFormBack ? (
                                        <FcCheckmark
                                          size={20}
                                          color="green"
                                          style={{
                                            marginRight: 3,
                                            marginBottom: 1,
                                          }}
                                        />
                                      ) : (
                                        <RxCross2
                                          size={20}
                                          color="red"
                                          style={{
                                            marginRight: 3,
                                            marginBottom: 1,
                                          }}
                                        />
                                      )}
                                    </td>
                                    <td className="text-center">
                                      {docs.multazimBaitFormFront ? (
                                        <FcCheckmark
                                          size={20}
                                          color="green"
                                          style={{
                                            marginRight: 3,
                                            marginBottom: 1,
                                          }}
                                        />
                                      ) : (
                                        <RxCross2
                                          size={20}
                                          color="red"
                                          style={{
                                            marginRight: 3,
                                            marginBottom: 1,
                                          }}
                                        />
                                      )}
                                    </td>
                                    <td className="text-center">
                                      {docs.multazimBaitFormBack ? (
                                        <FcCheckmark
                                          size={20}
                                          color="green"
                                          style={{
                                            marginRight: 3,
                                            marginBottom: 1,
                                          }}
                                        />
                                      ) : (
                                        <RxCross2
                                          size={20}
                                          color="red"
                                          style={{
                                            marginRight: 3,
                                            marginBottom: 1,
                                          }}
                                        />
                                      )}
                                    </td>
                                  </>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan="120">
                            <ul className={GetCustomStyles().page_style}>
                              <Pagination
                                activePage={pagination.activePage}
                                itemsCountPerPage={pagination.pageSize}
                                totalItemsCount={pagination.totalItem}
                                pageRangeDisplayed={pagination.pageRange}
                                onChange={handlePageChange}
                              />
                            </ul>
                          </td>
                        </tr>
                      </tfoot>
                    </Table>
                  </>
                ) : (
                  <NoResult />
                )
              ) : (
                LoadingSpinner()
              )}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default withRouter(MemberDocs);
