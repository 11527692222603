import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { RxCross2 } from "react-icons/rx";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import Select from "react-select";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import swal from "sweetalert";
import {
  DateOfBirthValidater,
  LoadingSpinner,
  convertToDateString,
  isUrdu,
} from "../../../constants/const";
import { encryptStorage } from "../../../constants/EncryptStorage";
import { GetLanguageString, NoResult } from "../../helper/Components";
import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import { setupApi } from "../../member/ApiMember";
import TableView from "../../shared/SimilarRecordTable";
import { ApiAhbab } from "../ApiAhbab";
import SelectUnitForAhbab from "../SelectUnitForAhbab";

const AddHabib = ({ history }) => {
  const [selectedDateOfBirth, setSelectedDateOfBirth] = useState(null);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [loading, setLoading] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [similarMatchRecords, setSimilarMatchRecords] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState();
  const [selectedStateId, setSelectedStateId] = useState();
  const [educationDegreeList, setEductationDegreeList] = useState();
  const [occupationList, setOccupationList] = useState();
  const [values, setValues] = useState({
    unit: { value: 0, lable: "", unitName: "" },
  });
  const selectedLanguage = GetSelectedLanguage();

  const { t } = useTranslation();

  const [formValues, setFormValues] = useState({
    habibName: "",
    birthDate: "",
    educationDegree: "",
    educationInstitution: "",
    address: "",
    country: "",
    state: "",
    city: "",
    contactPhone: "",
    contactEmail: "",
    contactMobileNumber: "",
    occupation: "",
    occupationDescription: "",
    influncedPersonalities: "",
    otherParticular: "",
    gender: "m",
    unit: { value: loggedInUnitId, label: "" },
  });

  const [errors, setErrors] = useState(formValues);

  const validate = () => {
    let temp = {};
    temp.habibName =
      formValues.habibName !== "" ? (
        ""
      ) : (
        <GetLanguageString props="ahbab_name_error_message" />
      );
    temp.contactMobileNumber =
      formValues.contactMobileNumber !== "" ? (
        ""
      ) : (
        <GetLanguageString props="member_phone_error_message" />
      );
    temp.address =
      formValues.address !== "" ? (
        ""
      ) : (
        <GetLanguageString props="ahbab_address_error_message" />
      );

    temp.birthDate = DateOfBirthValidater(selectedDateOfBirth) ? (
      ""
    ) : (
      <GetLanguageString props="ahbab_birth_date_less_error_message" />
    );

    temp.country =
      Object.keys(formValues.country).length === 0 ? (
        <GetLanguageString props="member_member_details_country_error_message" />
      ) : (
        ""
      );

    temp.state =
      Object.keys(formValues.state).length === 0 && states.length !== 0 ? (
        <GetLanguageString props="member_member_details_state_error_message" />
      ) : (
        ""
      );

    temp.city =
      Object.keys(formValues.city).length === 0 && cities.length !== 0 ? (
        <GetLanguageString props="member_member_details_city_error_message" />
      ) : (
        ""
      );

    temp.unitId =
      values.unit.value === 0 ? (
        <GetLanguageString props="ahbab_add_unit_error" />
      ) : values.unit.unitName !== "Markaz" &&
        values.unit.unitName !== "Zone" &&
        values.unit.unitName !== "ZoneOS" &&
        values.unit.unitName !== "Munfarid" &&
        values.unit.unitName !== "UsraMuavin" ? (
        ""
      ) : (
        <GetLanguageString props="ahbab_add_unit_error" />
      );

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      ApiAhbab()
        .GetSimilarAhbab(formValues.contactMobileNumber)
        .then((res) => {
          setSimilarMatchRecords(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [formValues.contactMobileNumber]);

  useEffect(() => {
    setLoading(true);
    setupApi()
      .GetCountries()
      .then((res) => {
        setCountries(res.data.countries);
        setSelectedCountryId(res.data.selectedCountry.value);
        setFormValues({
          ...formValues,
          country: res.data.selectedCountry,
        });
        if (formValues.state === "" || formValues.city === "") {
          setStates([]);
          setCities([]);
          setSelectedStateId(0);
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));

    setLoading(true);
    setButtonDisable(true);
    setupApi()
      .getEducationTypesForDropDown()
      .then((res) => {
        setEductationDegreeList(res.data);
        setButtonDisable(false);
        setLoading(false);
      })
      .catch((err) => console.log(err));

    setLoading(true);
    setButtonDisable(true);
    setupApi()
      .getOccupations()
      .then((res) => {
        setOccupationList(res.data);
        setButtonDisable(false);
        setLoading(false);
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setupApi()
      .GetStates(selectedCountryId)
      .then((res) => {
        setStates(res.data);
        if (res.data.length === 0) {
          setCities([]);
        }
      })
      .catch((err) => console.log(err));
  }, [selectedCountryId]);

  useEffect(() => {
    setupApi()
      .GetCities(selectedStateId)
      .then((res) => {
        setCities(res.data);
      })
      .catch((err) => console.log(err));
  }, [selectedStateId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleOnChangeCountry = (country) => {
    setFormValues({
      ...formValues,
      country: country,
      state: "",
      city: "",
    });
    setCities([]);
    setSelectedStateId(0);
    setSelectedCountryId(country.value);
  };

  const handleOnChangeCity = (value) => {
    setFormValues({
      ...formValues,
      city: value,
    });
  };
  const handleOnChangeState = (state) => {
    setFormValues({
      ...formValues,
      state: state,
      city: "",
    });
    setSelectedStateId(state.value);
  };

  const handleOnChangeOccupation = (value) => {
    setFormValues({
      ...formValues,
      occupation: value,
    });
  };

  const handleOnChangeEducation = (value) => {
    setFormValues({
      ...formValues,
      educationDegree: value,
    });
  };

  const handleInputChangeBirthDate = (value) => {
    if (value !== null) {
      if (value <= new Date()) {
        setSelectedDateOfBirth(value);
        setErrors({
          ...errors,
          birthDate: "",
        });
      } else {
        setErrors({
          ...errors,
          birthDate: "The Birth date must not be greater than current date.",
        });
      }
    } else {
      setFormValues({
        ...formValues,
        birthDate: null,
      });
      setSelectedDateOfBirth(null);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      formValues.unit = values.unit;
      formValues.birthDate = convertToDateString(selectedDateOfBirth);
      setLoading(true);
      setButtonDisable(true);
      ApiAhbab()
        .Add(formValues)
        .then((res) => {
          swal({
            title: t("ahbab_add_success_message"),
            icon: "success",
            buttons: "OK",
          }).then((done) => {
            if (done) history.goBack();
          });
          setButtonDisable(false);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
          console.log(err);
          setButtonDisable(false);
          setLoading(false);
        });
    }
  };

  return (
    <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
      <div className="ibox">
        <div className="ibox-title">
          <h5>{<GetLanguageString props="ahbab_add" />}</h5>
          <Button
            color="primary"
            size="sm"
            className={GetCustomStyles().btn_style}
            onClick={() => history.goBack()}
          >
            {<GetLanguageString props="courses_participants_back" />}
          </Button>
        </div>
        <div className="ibox-content">
          <Form onSubmit={handleSubmit}>
            {!loading ? (
              <>
                <Row>
                  <Col md="6">
                    <Row>
                      <Label className="col-form-label">
                        <h4>
                          {
                            <GetLanguageString props="sidebar_personal_information" />
                          }
                        </h4>
                      </Label>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Row>
                            <Label className="col-form-label">
                              {<GetLanguageString props="common_name" />}
                              <span className="text-error">&nbsp;*</span>
                            </Label>
                          </Row>
                          <Input
                            className={isUrdu(formValues.habibName) ? "ur" : ""}
                            type="text"
                            name="habibName"
                            value={formValues.habibName}
                            onChange={handleInputChange}
                            {...(errors?.habibName && { invalid: true })}
                          />
                          {errors.habibName && (
                            <FormFeedback>
                              <h4>{errors.habibName}</h4>
                            </FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label className="col-form-label">
                            {<GetLanguageString props="member_date_of_birth" />}
                            <span className="text-error">&nbsp;*</span>
                          </Label>
                          <DatePicker
                            placeholderText="dd/mm/yyyy"
                            className="form-control no-drag"
                            selected={selectedDateOfBirth}
                            showYearDropdown
                            scrollableYearDropdown
                            onChange={handleInputChangeBirthDate}
                            dateFormat="dd/MM/yyyy"
                          />
                        </FormGroup>{" "}
                        {errors.birthDate && (
                          <h4 className="text-error">{errors.birthDate}</h4>
                        )}
                      </Col>
                      <FormGroup>
                        <Label className="col-form-label">
                          {<GetLanguageString props="settings_gender" />}
                        </Label>
                        <Row>
                          <Col>
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="gender"
                                  value="m"
                                  checked={formValues.gender === "m"}
                                  onChange={handleInputChange}
                                />{" "}
                                {
                                  <GetLanguageString props="settings_gender_male" />
                                }
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="gender"
                                  value="f"
                                  checked={formValues.gender === "f"}
                                  onChange={handleInputChange}
                                />{" "}
                                {
                                  <GetLanguageString props="settings_gender_female" />
                                }
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Row>{" "}
                    <hr style={{ marginTop: 19 }}></hr>
                    <Row>
                      <Label className="col-form-label">
                        <h4>
                          {<GetLanguageString props="member_contact_details" />}
                        </h4>
                        <Row>
                          <Col md="4">
                            <FormGroup>
                              <Label className="col-form-label">
                                {
                                  <GetLanguageString props="member_mobile_number" />
                                }

                                <span className="text-error">&nbsp;*</span>
                              </Label>
                              <Input
                                type="text"
                                name="contactMobileNumber"
                                className="no-drag"
                                value={formValues.contactMobileNumber}
                                onChange={handleInputChange}
                                {...(errors?.contactMobileNumber && {
                                  invalid: true,
                                })}
                              />
                              {errors.contactMobileNumber && (
                                <FormFeedback>
                                  <h4>{errors.contactMobileNumber}</h4>
                                </FormFeedback>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label className="col-form-label">
                                {<GetLanguageString props="member_phone" />}
                              </Label>
                              <Input
                                type="text"
                                name="contactPhone"
                                value={formValues.contactPhone}
                                onChange={handleInputChange}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label className="col-form-label">
                                {<GetLanguageString props="member_email" />}
                              </Label>
                              <Input
                                type="text"
                                name="contactEmail"
                                value={formValues.contactEmail}
                                onChange={handleInputChange}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Label>
                    </Row>
                    <Row>
                      {similarMatchRecords?.length !== 0 ? (
                        <div className="ibox">
                          <div className="ibox-title">
                            <Row>
                              <Col md="11">
                                <h5>
                                  {
                                    <GetLanguageString props="notifications_see_changes_similar_records" />
                                  }
                                </h5>
                              </Col>
                              <Col md="1">
                                <div
                                  className={GetCustomStyles().unit_btn_style}
                                >
                                  <RxCross2
                                    size={20}
                                    color="red"
                                    style={{
                                      marginRight: 3,
                                      marginBottom: 1,
                                    }}
                                    onClick={() => setSimilarMatchRecords([])}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <div
                            className="ibox-content"
                            style={{
                              maxHeight: 300,
                              overflowY: "auto",
                            }}
                          >
                            <div>
                              {similarMatchRecords?.length !== 0 ? (
                                <TableView data={similarMatchRecords} />
                              ) : (
                                <NoResult />
                              )}
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </Row>
                  </Col>
                  <Col md="6">
                    <Row>
                      <Label className="col-form-label">
                        <h4>
                          {
                            <GetLanguageString props="member_education_details" />
                          }
                        </h4>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <Label className="col-form-label">
                                {
                                  <GetLanguageString props="member_education_degree" />
                                }
                              </Label>
                              <Select
                                options={educationDegreeList}
                                value={formValues.educationDegree}
                                className="basic-single"
                                placeholder={
                                  selectedLanguage === "en"
                                    ? "Select Education"
                                    : "منتخب کریں"
                                }
                                classNamePrefix="select"
                                onChange={(type) => {
                                  handleOnChangeEducation(type);
                                  setErrors({
                                    ...errors,
                                    educationDegree: "",
                                  });
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label className="col-form-label">
                                {
                                  <GetLanguageString props="member_education_institution" />
                                }
                              </Label>
                              <Input
                                type="text"
                                name="educationInstitution"
                                value={formValues.educationInstitution}
                                onChange={handleInputChange}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Label>
                    </Row>
                    <hr></hr>
                    <Row>
                      <Label className="col-form-label">
                        <h4>
                          {
                            <GetLanguageString props="member_occupation_details" />
                          }
                        </h4>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <Label className="col-form-label">
                                {
                                  <GetLanguageString props="member_occupation" />
                                }
                              </Label>
                              <Select
                                options={occupationList}
                                value={formValues.occupation}
                                className="basic-single"
                                classNamePrefix="select"
                                placeholder={
                                  selectedLanguage === "en"
                                    ? "Select Occupation"
                                    : "منتخب کریں"
                                }
                                onChange={(type) => {
                                  handleOnChangeOccupation(type);
                                  setErrors({
                                    ...errors,
                                    occupation: "",
                                  });
                                }}
                              />
                            </FormGroup>
                          </Col>

                          <Col md="6">
                            <FormGroup>
                              <Label className="col-form-label">
                                {
                                  <GetLanguageString props="common_description" />
                                }
                              </Label>
                              <Input
                                type="text"
                                name="occupationDescription"
                                className="ur"
                                value={formValues.occupationDescription}
                                onChange={handleInputChange}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Label>
                    </Row>
                  </Col>{" "}
                  <hr></hr>
                </Row>{" "}
                <Row>
                  <Row>
                    <Label className="col-form-label">
                      <h4>
                        {<GetLanguageString props="member_address_details" />}
                      </h4>
                    </Label>
                  </Row>
                  <Col md="6">
                    <FormGroup>
                      <Label className="col-form-label">
                        {<GetLanguageString props="member_address" />}
                        <span className="text-error">&nbsp;*</span>
                      </Label>
                      <Input
                        className={isUrdu(formValues.address) ? "ur" : ""}
                        type="text"
                        name="address"
                        value={formValues.address}
                        onChange={handleInputChange}
                        {...(errors?.address && { invalid: true })}
                      />
                      {errors.address && (
                        <FormFeedback>
                          <h4>{errors.address}</h4>
                        </FormFeedback>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <Label className="col-form-label">
                            {<GetLanguageString props="member_country" />}
                            <span className="text-error">&nbsp;*</span>
                          </Label>
                          <Select
                            options={countries}
                            value={formValues.country}
                            className="basic-single"
                            placeholder={
                              selectedLanguage === "en"
                                ? "Select"
                                : "منتخب کریں"
                            }
                            classNamePrefix="select"
                            onChange={(country) => {
                              handleOnChangeCountry(country);
                              setErrors({
                                ...errors,
                                country: "",
                              });
                            }}
                          />
                          {errors.country && (
                            <h4 className="text-error">{errors.country}</h4>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        {states.length === 0 ? (
                          <h3 className="text-center m-5">{" - "}</h3>
                        ) : (
                          <FormGroup>
                            <Label className="col-form-label">
                              {
                                <GetLanguageString props="ahbeb_state_province" />
                              }
                              <span className="text-error">&nbsp;*</span>
                            </Label>
                            <Select
                              options={states}
                              value={formValues.state}
                              className="basic-single"
                              placeholder={
                                states.length === 0
                                  ? selectedLanguage === "en"
                                    ? "No States Available"
                                    : "کوئی صوبہ موجود نہی ہے"
                                  : selectedLanguage === "en"
                                  ? "Select"
                                  : "منتخب کریں"
                              }
                              classNamePrefix="select"
                              onChange={(state) => {
                                handleOnChangeState(state);
                                setErrors({
                                  ...errors,
                                  state: "",
                                });
                              }}
                            />
                            {errors.state && (
                              <h4 className="text-error">{errors.state}</h4>
                            )}
                          </FormGroup>
                        )}
                      </Col>
                      <Col md="4">
                        {cities.length === 0 ? (
                          <h3 className="text-center m-5">{" - "}</h3>
                        ) : (
                          <FormGroup>
                            <Label className="col-form-label">
                              {<GetLanguageString props="member_city" />}
                              <span className="text-error">&nbsp;*</span>
                            </Label>
                            <Select
                              options={cities}
                              value={formValues.city}
                              className="basic-single"
                              placeholder={
                                cities.length === 0
                                  ? selectedLanguage === "en"
                                    ? "No Cities Available"
                                    : "کوئی شہر موجود نہی ہے"
                                  : selectedLanguage === "en"
                                  ? "Select"
                                  : "منتخب کریں"
                              }
                              classNamePrefix="select"
                              onChange={(city) => {
                                handleOnChangeCity(city);
                                setErrors({
                                  ...errors,
                                  city: "",
                                });
                              }}
                            />
                            {errors.city && (
                              <h4 className="text-error">{errors.city}</h4>
                            )}
                          </FormGroup>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <hr></hr>
                <Row>
                  <Label className="col-form-label">
                    <h4>{<GetLanguageString props="member_select_unit" />}</h4>
                  </Label>
                  <SelectUnitForAhbab
                    values={values}
                    setValues={setValues}
                    setErrors={setErrors}
                    unitId={loggedInUnitId}
                  />
                  {errors.unitId ? (
                    <h4 className="text-error">*{errors.unitId}</h4>
                  ) : null}
                </Row>
              </>
            ) : (
              LoadingSpinner()
            )}
            <Row>
              <Col md="8"></Col>

              <Col md="4">
                <div className={GetCustomStyles().btn_style}>
                  <Button
                    color="primary"
                    disabled={buttonDisable}
                    className="m-l-sm"
                    type="submit"
                  >
                    {<GetLanguageString props="common_save_changes" />}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default withRouter(AddHabib);
