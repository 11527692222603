import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import Draggable from "react-draggable";
import swal from "sweetalert";
import { LoadingSpinner } from "../../../constants/const";
import { GetLanguageString } from "../../helper/Components";
import { GetSelectedLanguage } from "../../helper/Method";
import { ApiLetterForCounselling } from "./ApiLetterForCounselling";

const EditLetterForCounsellingQuestion = ({
  setEditModal,
  setReload,
  reload,
  editValue,
  lfcTitle,
  lfcId,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [
    letterForCounsellingQuestionValues,
    setLetterForCounsellingQuestionValues,
  ] = useState({
    id: editValue,
    lfcId: lfcId,
    questionId: {},
    sortOrder: "",
    questionNumber: "",
    isEnabled: true,
    isArchive: false,
    defaultRating: null,
  });
  const [errors, setErrors] = useState(letterForCounsellingQuestionValues);
  const [counsellingQuestionList, setCounsellingQuestionList] = useState([]);
  const toggle = () => setEditModal(false);
  const [selectedCounsellingQuestionType, setSelectedCounsellingQuestionType] =
    useState({});
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  useEffect(() => {
    setLoading(true);
    ApiLetterForCounselling()
      .EditLetterForCounsellingQuestion(editValue)
      .then((res) => {
        setLetterForCounsellingQuestionValues(
          res.data
        );
        setSelectedCounsellingQuestionType({
          value: res.data.questionId,
          label: res.data.question
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
      });

    setLoading(true);
    ApiLetterForCounselling()
      .GetCouncellingQuestionsListForLfc(lfcId)
      .then((res) => {
        setCounsellingQuestionList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
      });
  }, [lfcId, editValue]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setErrors({
      ...errors,
      [name]: "",
    });

    setLetterForCounsellingQuestionValues({
      ...letterForCounsellingQuestionValues,
      [name]: value,
    });
  };

  const handleChangeCounsellingQuestionType = (record) => {
    if (record === null) {
      setLetterForCounsellingQuestionValues({
        ...letterForCounsellingQuestionValues,
        counsellingQuestion: {},
      });
      setSelectedCounsellingQuestionType({});
    } else {
      setLetterForCounsellingQuestionValues({
        ...letterForCounsellingQuestionValues,
        counsellingQuestion: record,
      });
      setSelectedCounsellingQuestionType(record);
    }
    setErrors({
      ...errors,
      counsellingQuestion: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    letterForCounsellingQuestionValues.questionId =
      selectedCounsellingQuestionType.value;
    letterForCounsellingQuestionValues.lfcId = lfcId;

    setLoading(true);
    ApiLetterForCounselling()
      .UpdateLetterForCounsellingQuestion(editValue, letterForCounsellingQuestionValues)
      .then((res) => {
        setLoading(false);
        swal({
          title: t("settings_letter_for_counselling_question_edit_success"),
          className: GetSelectedLanguage() === "en" ? "" : "ur",
          icon: "success",
          buttons: "OK",
        });
        setReload(!reload);
        toggle();
      })
      .catch((err) => {
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
        setLoading(false);
      });
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {
              <GetLanguageString props="settings_letter_for_counselling_question_edit_question" />
            }
          </ModalHeader>
          {!loading ? (
            <Form onSubmit={handleSubmit}>
              <ModalBody>
                <FormGroup>
                  <Label>
                    {
                      <GetLanguageString props="settings_letter_for_counselling" />
                    }
                  </Label>
                  <Input
                    type="text"
                    name="title"
                    className="no-drag ur"
                    disabled
                    value={lfcTitle}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    {
                      <GetLanguageString props="settings_counselling_question" />
                    }
                  </Label>
                  <Select
                    value={selectedCounsellingQuestionType}
                    options={counsellingQuestionList}
                    className="basic-single no-drag ur"
                    classNamePrefix="select"
                    onChange={handleChangeCounsellingQuestionType}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="no-drag">
                    {<GetLanguageString props="unit_sort_order" />}
                  </Label>
                  <Input
                    autoFocus
                    type="text"
                    name="sortOrder"
                    className="no-drag"
                    value={letterForCounsellingQuestionValues?.sortOrder}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="no-drag">
                    {
                      <GetLanguageString props="settings_letter_for_counselling_question_question_number" />
                    }
                  </Label>
                  <Input
                    type="text"
                    name="questionNumber"
                    className="no-drag"
                    value={letterForCounsellingQuestionValues?.questionNumber}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    {
                      <GetLanguageString props="settings_letter_for_counselling_question_default_rating" />
                    }
                  </Label>
                  <Input
                    type="number"
                    name="defaultRating"
                    className="no-drag"
                    value={letterForCounsellingQuestionValues?.defaultRating}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup check>
                  <Label check for="isArchive">
                    {" "}
                    <span
                      style={{
                        float:
                          GetSelectedLanguage() === "ur" ? "right" : "left",
                        marginLeft:
                          GetSelectedLanguage() === "ur" ? "24px" : "0px",
                      }}
                    >
                      <Input
                        id="isArchive"
                        type="checkbox"
                        name="isArchive"
                        value={!letterForCounsellingQuestionValues?.isArchive}
                        onChange={handleInputChange}
                        checked={letterForCounsellingQuestionValues?.isArchive}
                      />
                    </span>
                    {
                      <GetLanguageString props="settings_letter_for_counselling_archive" />
                    }
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check for="isEnabled">
                    {" "}
                    <span
                      style={{
                        float:
                          GetSelectedLanguage() === "ur" ? "right" : "left",
                        marginLeft:
                          GetSelectedLanguage() === "ur" ? "24px" : "0px",
                      }}
                    >
                      <Input
                        id="isEnabled"
                        type="checkbox"
                        checked={letterForCounsellingQuestionValues?.isEnabled}
                        name="isEnabled"
                        value={!letterForCounsellingQuestionValues?.isEnabled}
                        onChange={handleInputChange}
                      />
                    </span>
                    {<GetLanguageString props="member_address_enabled" />}
                  </Label>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="white" onClick={toggle}>
                  {<GetLanguageString props="common_cancel" />}
                </Button>
                <Button color="primary" type="submit">
                  {<GetLanguageString props="common_save_changes" />}
                </Button>
              </ModalFooter>
            </Form>
          ) : (
            LoadingSpinner()
          )}
        </Modal>
      </Draggable>
    </div>
  );
};

export default EditLetterForCounsellingQuestion;
