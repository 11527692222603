import axios from "axios";

import { BASEURL } from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";

export function ApiUser() {
  const userToken = encryptStorage.getItem("token");

  const authAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const url = BASEURL + "api/account";
  return {
    createUser: (newRecord) => authAxios.post(url + "/createuser", newRecord),
    makeAdmin: (id) => authAxios.post(url + `/makeAdmin/${id}`),
    removeAdmin: (id) => authAxios.post(url + `/removeAdmin/${id}`),
    disableUser: (newRecord) => authAxios.post(url + "/disableuser", newRecord),
    enableUser: (newRecord) => authAxios.post(url + "/enableUser", newRecord),
    resetPassword: (regNo) => authAxios.post(url + "/resetPassword", regNo),
    getResponsibilityPermissions: (value) => authAxios.get(url + `/getResponsibilityPermissions/${value}`),
    setResponsibilityPermissions: (values) => authAxios.post(url + "/setResponsibilityPermissions", values),
    getMemberPermissions: (value, claimGivenById) => authAxios.get(url + `/getMemberPermissions/${value}/${claimGivenById}`),
    getInitialPermissions: (value, claimGivenById) => authAxios.get(url + `/getInitialPermissions/${value}/${claimGivenById}`),
    setMemberPermissions: (values) => authAxios.post(url + "/setMemberPermissions", values),
    GetMemberAccessRights: (memberId) =>
      authAxios.get(url + "/GetMemberAccessRights/" + memberId),

  };
}
