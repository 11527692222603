import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import Draggable from "react-draggable";
import {
  LoadingSpinner,
  convertToDateString,
  isUrdu,
} from "../../../constants/const";
import DatePicker from "react-datepicker";
import { GetLanguageString } from "../../helper/Components";
import { ApiPledges } from "./ApiPledges";
import { GetSelectedLanguage } from "../../helper/Method";
import swal from "sweetalert";

const AddPledge = ({ setAddModal, setReload, reload }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [pledgeValues, setPledgeValues] = useState({
    ameerName: "",
    pledgeName: "",
    fromDate: null,
  });
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [errors, setErrors] = useState(pledgeValues);
  const toggle = () => setAddModal(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  const validate = () => {
    let temp = {};
    temp.pledgeName = pledgeValues.pledgeName
      ? ""
      : t("settings_name_error_message");
    temp.fromDate = selectedDate ? "" : t("common_date_error_message");

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputChangeFromDate = (value) => {
    if (value !== null) {
      if (value <= new Date()) {
        setSelectedDate(value);
        setErrors({
          ...errors,
          fromDate: "",
        });
      } else {
        setErrors({
          ...errors,
          fromDate: t("date_greater_than_current_date_error"),
        });
      }
    } else {
      setSelectedDate(new Date());
    }
  };

  useEffect(() => {
    setLoading(true);
    ApiPledges()
      .GetAmeerTanzeemName()
      .then((res) => {
        setPledgeValues({
          ...pledgeValues,
          ameerName: res.data,
          fromDate: new Date(),
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPledgeValues({
      ...pledgeValues,
      [name]: value,
    });
    setErrors({
      ...errors,
      pledgeName: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      pledgeValues.fromDate = convertToDateString(selectedDate);
      setLoading(true);
      ApiPledges()
        .AddPledge(pledgeValues)
        .then((res) => {
          toggle();
          setLoading(false);
          swal({
            title: t("settings_pledge_added_success"),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "success",
            buttons: "OK",
          });
          setReload(!reload);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
        });
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="member_member_detail_add_pledge" />}
          </ModalHeader>
          {!loading ? (
            <Form onSubmit={handleSubmit}>
              <ModalBody>
                <FormGroup>
                  <Label>
                    {<GetLanguageString props="report_member_ammer_name" />}
                  </Label>
                  <Input
                    autoFocus
                    type="text"
                    name="ameerName"
                    className="no-drag ur"
                    value={pledgeValues.ameerName}
                    disabled
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    {
                      <GetLanguageString props="member_member_detail_pledges_name" />
                    }
                  </Label>
                  <Input
                    type="text"
                    name="pledgeName"
                    className={
                      isUrdu(pledgeValues.pledgeName) ? "no-drag ur" : "no-drag"
                    }
                    value={pledgeValues.pledgeName}
                    onChange={handleInputChange}
                    {...(errors.pledgeName && { invalid: true })}
                  />
                  {errors.pledgeName && (
                    <h4
                      className={
                        GetSelectedLanguage() === "ur"
                          ? "text-error ur"
                          : "text-error"
                      }
                    >
                      {errors?.pledgeName}
                    </h4>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="col-form-label">
                    {<GetLanguageString props="member_report_from" />}
                  </Label>
                  <DatePicker
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd/mm/yyyy"
                    className="form-control no-drag"
                    selected={selectedDate}
                    maxDate={new Date()}
                    onChange={handleInputChangeFromDate}
                    dateFormat="dd/MM/yyyy"
                  />
                  {errors.fromDate && (
                    <h4
                      className={
                        GetSelectedLanguage() === "ur"
                          ? "text-error ur"
                          : "text-error"
                      }
                    >
                      {errors?.fromDate}
                    </h4>
                  )}
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="white" onClick={toggle}>
                  {<GetLanguageString props="common_cancel" />}
                </Button>
                <Button color="primary" type="submit">
                  {<GetLanguageString props="common_save_changes" />}
                </Button>
              </ModalFooter>
            </Form>
          ) : (
            LoadingSpinner()
          )}
        </Modal>
      </Draggable>
    </div>
  );
};

export default AddPledge;
