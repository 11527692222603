import React, { useState } from "react";
import {
  Button,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { GetLanguageString } from "../helper/Components";
import { LoadingSpinner } from "../../constants/const";
import { ApiNotifications } from "./ApiNotifications";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import { GetSelectedLanguage } from "../helper/Method";
import Draggable from "react-draggable";
const CancelRequestModal = ({
  notificationId,
  memberId,
  setCancelRequestModal,
  setupdateList,
  updateList
}) => {
  const toggle = () => setCancelRequestModal(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  const [cancelRequest, setCancelRequest] = useState({
    code: 4,
    notificationId: notificationId,
    createdById: memberId,
    comments: "",
  });
  const {t} = useTranslation();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCancelRequest({
      ...cancelRequest,
      [name]: value,
    });
  };
  const handleSubmit = () => {
    setLoading(true);
    setButtonDisable(true);
    ApiNotifications()
      .CancelReqeustNotification(cancelRequest)
      .then((res) => {
        if (res.data.includes("Cancelled")) {
          swal({
            title: t(res.data),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "success",
            buttons: "OK",
          });
          setLoading(false);
          setButtonDisable(false);
          setupdateList(!updateList)
          toggle();
        }
      })
      .catch((err) => {
        swal({
          title: "Error: " + err.response?.data || err,
          icon: "error",
          buttons: "OK",
        });
        setLoading(false);
        setButtonDisable(false);
      });
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal">
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="notification_cancel_request" />}
          </ModalHeader>
          <Form>
            <ModalBody>
              {!loading ? (
                <>
                  <div className="ibox-content">
                    <Row>
                      <Label className="col-form-label">
                        <h4>
                          {
                            <GetLanguageString props="member_member_detail_course_comments" />
                          }
                        </h4>
                        <Input
                          type="text"
                          name="comments"
                          className="no-drag"
                          value={cancelRequest.comments}
                          onChange={handleInputChange}
                        />
                      </Label>
                    </Row>
                  </div>
                </>
              ) : (
                LoadingSpinner()
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="white" onClick={toggle}>
                {<GetLanguageString props="common_cancel" />}
              </Button>
              <Button
                color="danger"
                disabled={buttonDisable}
                type="submit"
                  onClick={handleSubmit}
              >
                {<GetLanguageString props="notification_cancel_request" />}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </Draggable>
    </div>
  );
};

export default CancelRequestModal;
