import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Label,
  Input,
  Button,
  FormFeedback
} from "reactstrap";
import { LoadingSpinner } from "../../constants/const";
import { ApiTutorials } from "./ApiTutorials";
import swal from "sweetalert";
import { GetSelectedLanguage } from "../helper/Method";
import { t } from "i18next";
import { GetLanguageString } from "../helper/Components";
import Draggable from "react-draggable";

const AddCategory = ({ setSelectCategory, setShowCategory, refreshCategory, setRefreshCategory }) => {
  const [formData, setFormData] = useState({
    categoryName: "",
  });
  const [loading, setLoading] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [errors, setErrors] = useState();
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  const toggle = () => setShowCategory(false);

  const validate = () => {
    let temp = {};
    temp.categoryName = formData.categoryName ? (
      ""
    ) : (
      <GetLanguageString props="tutorial_category_error_message" />
    );

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    })
  };

  const handleSubmitCategory = (e) => {
    e.preventDefault();
    if(validate()) {
    setLoading(true);
    setButtonDisable(true);
    ApiTutorials()
    .addCategory(formData)
    .then((res) => {
      swal({
        title: t("video_tutorial_category_added_msg"),
        className: GetSelectedLanguage() === "en" ? "" : "ur",
        icon: "success",
        buttons: GetSelectedLanguage() === "en" ? "OK" : "ٹھیک ہے ",
      });
      setSelectCategory(res.data);
      setLoading(false);
      setButtonDisable(false);
      setRefreshCategory(!refreshCategory)
      toggle()
    })
    .catch((err) => {
      swal({
        title: err,
        icon: "error",
        buttons: "OK",
      });
      setLoading(false);
      setButtonDisable(false);
    });
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            <GetLanguageString props="video_tutorial_add_category" />
          </ModalHeader>
          {!loading ? (
            <Form onSubmit={handleSubmitCategory}>
              <ModalBody>
                <FormGroup>
                  <Label>
                    <GetLanguageString props="video_tutorial_add_category" />
                  </Label>
                  <Input
                    autoFocus
                    type="text"
                    name="categoryName"
                    className="no-drag"
                    value={formData.categoryName}
                    onChange={handleInputChange}
                    {...(errors?.categoryName && { invalid: true })}
                  />
                  {errors?.categoryName && (
                    <FormFeedback>
                    <h4 className="text-error no-drag">{errors?.categoryName}</h4>
                    </FormFeedback>
                  )}
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="white" onClick={toggle}>
                  <GetLanguageString props="common_cancel" />
                </Button>
                <Button color="primary" type="submit" disabled={buttonDisable}>
                  <GetLanguageString props="common_save" />
                </Button>
              </ModalFooter>
            </Form>
          ) : (
            LoadingSpinner()
          )}
        </Modal>
      </Draggable>
    </div>
  );
};

export default AddCategory;
