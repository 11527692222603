import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Table } from "reactstrap";
import swal from "sweetalert";

import { LoadingSpinner, isUrdu } from "../../../constants/const";
import { GetLanguageString, NoResult } from "../../helper/Components";
import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import AddEducationType from "./AddEducationType";
import { ApiEducation } from "./ApiEducation";
import AddEducationField from "./AddEducationField";
import AddDegreeCertificate from "./AddDegreeCertificate";
import EditDegree from "./EditDegree";
import AddInstitute from "./AddInstitute";
import { IoAdd } from "react-icons/io5";

const Education = ({ activeTab }) => {
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const { t } = useTranslation();
  const [degree, setDegree] = useState([]);
  const [educationTypeAddModal, setEducationTypeAddModal] = useState(false);
  const [educationFieldAddModal, setEducationFieldAddModal] = useState(false);
  const [educationDegreeAddModal, setEducationDegreeAddModal] = useState(false);
  const [instituteAddModal, setInstituteAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editValue, setEditValue] = useState();

  useEffect(() => {
    if (activeTab === "education") {
      setLoading(true);
      ApiEducation()
        .getDegree()
        .then((res) => {
          setDegree(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [reload, activeTab]);

  const handleOnEdit = (e) => {
    const { value } = e.target;
    setEditValue(value);
    setEditModal(true);
  };

  const handleOnDelete = (id) => {
    swal({
      title: t("common_delete_message"),
      text: t("common_delete_detail_message"),
      className: GetSelectedLanguage() === "en" ? "" : "ur",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        ApiEducation()
          .deleteDegree(id)
          .then((res) => {
            setLoading(false);
            setReload(!reload);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            setReload(!reload);
          });
      }
    });
  };

  return (
    <>
      {educationDegreeAddModal && (
        <AddDegreeCertificate
          setEducationDegreeAddModal={setEducationDegreeAddModal}
          reload={reload}
          setReload={setReload}
        />
      )}
      {instituteAddModal && (
        <AddInstitute setInstituteAddModal={setInstituteAddModal} />
      )}
      {educationTypeAddModal && (
        <AddEducationType setEducationTypeAddModal={setEducationTypeAddModal} />
      )}
      {educationFieldAddModal && (
        <AddEducationField
          setEducationFieldAddModal={setEducationFieldAddModal}
        />
      )}
      {editModal && (
        <EditDegree
          setEditModal={setEditModal}
          editValue={editValue}
          reload={reload}
          setReload={setReload}
        />
      )}
      {!loading ? (
        <div className="ibox">
          <div className="ibox-title">
            <h5>{<GetLanguageString props="member_education" />}</h5>
            <ButtonGroup className={GetCustomStyles().unit_btn_style}>
              <Button
                color="success"
                size="xs"
                onClick={() => setEducationDegreeAddModal(true)}
              >
                <IoAdd size={18} style={{ marginRight: 3, marginBottom: 1 }} />
                <GetLanguageString props="education_add_degree" />
              </Button>
              <Button
                color="primary"
                size="xs"
                onClick={() => setInstituteAddModal(true)}
              >
                <IoAdd size={18} style={{ marginRight: 3, marginBottom: 1 }} />
                <GetLanguageString props="education_add_institute" />
              </Button>
              <Button
                color="info"
                size="xs"
                onClick={() => setEducationTypeAddModal(true)}
              >
                <IoAdd size={18} style={{ marginRight: 3, marginBottom: 1 }} />
                <GetLanguageString props="education_add_type" />
              </Button>
              <Button
                color="primary"
                size="xs"
                onClick={() => setEducationFieldAddModal(true)}
              >
                <IoAdd size={18} style={{ marginRight: 3, marginBottom: 1 }} />
                <GetLanguageString props="education_add_field" />
              </Button>
            </ButtonGroup>
          </div>
          <div className="ibox-content">
            {degree.length !== 0 ? (
              <div className="table-responsive">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>
                        {<GetLanguageString props="member_education_degree" />}
                      </th>
                      <th>
                        {<GetLanguageString props="education_degree_type" />}
                      </th>
                      <th>
                        {
                          <GetLanguageString props="settings_notification_type_code" />
                        }
                      </th>
                      <th>
                        {<GetLanguageString props="education_degree_fields" />}
                      </th>
                      <th>{<GetLanguageString props="common_action" />}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {degree.map((record, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className={isUrdu(record.degree) ? "ur" : ""}>
                            {record.degree}
                          </td>
                          <td>{record.title !== null ? record.title : "-"}</td>
                          <td>{record.code !== null ? record.code : "-"}</td>
                          <td>
                            {record.fields !== null
                              ? record.fields.map((f, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <span
                                        className={
                                          isUrdu(f)
                                            ? "label label-success m-1 ur"
                                            : "label label-success m-1"
                                        }
                                      >
                                        {f}
                                      </span>
                                      {(index + 1) % 5 === 0 && <br />}{" "}
                                      {(index + 1) % 5 === 0 && <br />}{" "}
                                      {/* Add a newline after every 5 iterations */}
                                    </React.Fragment>
                                  );
                                })
                              : "-"}
                          </td>
                          <td className="text-center">
                            <ButtonGroup>
                              <Button
                                color="white"
                                size="sm"
                                onClick={handleOnEdit}
                                value={record.id}
                              >
                                {<GetLanguageString props="common_edit" />}
                              </Button>
                              <Button
                                color="white"
                                size="sm"
                                onClick={() => handleOnDelete(record.id)}
                              >
                                {<GetLanguageString props="common_delete" />}
                              </Button>
                            </ButtonGroup>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            ) : (
              <NoResult />
            )}
          </div>
        </div>
      ) : (
        LoadingSpinner()
      )}
    </>
  );
};

export default Education;
