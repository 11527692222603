import axios from "axios";

import { BASEURL } from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";

export function ApiEvent() {
  const userToken = encryptStorage.getItem("token");

  const authAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const url = BASEURL + "event/";
  return {
    delete: (id, updateAllEvent) =>
      authAxios.delete(url + "delete/" + id + "/" + updateAllEvent),
    get: (searchEventModel) =>
      authAxios.post(url + "getList/", searchEventModel),
    getEventsReport: (searchEventModel) =>
      authAxios.post(url + "getEventsReport/", searchEventModel),
    getEventsReportWithNoAttendance: (searchEventModel) =>
      authAxios.post(
        url + "getEventsReportWithNoAttendance/",
        searchEventModel
      ),
    getEventParticipants: (searchEventParticipants) =>
      authAxios.post(url + "getEventParticipants/", searchEventParticipants),
    getResponsibleEventParticipants: (unitId, eventId) =>
      authAxios.get(
        url + "getResponsibleEventParticipants/" + unitId + "/" + eventId
      ),
    getInChargeAndResponsibleEventParticipants: (unitId, eventId) =>
      authAxios.get(
        url +
          "getInChargeAndResponsibleEventParticipants/" +
          unitId +
          "/" +
          eventId
      ),
    canAddEvent: () => authAxios.get(url + "CanAddEvent"),
    getParticipantsByEvent: (model) =>
      authAxios.post(
        BASEURL + "eventparticipant/GetSelectiveParticipantsByEvent/",
        model
      ),
    searchMembersforEventParticipants: (
      unitId,
      isSubUnit,
      name,
      type,
      eventId
    ) =>
      authAxios.get(
        url +
          "searchMembersforEventParticipants/" +
          unitId +
          "/" +
          isSubUnit +
          "/" +
          name +
          "/" +
          type +
          "/" +
          eventId
      ),
    addeventparticipant: (newRecord) =>
      authAxios.post(
        BASEURL + "eventparticipant/AddEventParticipant/",
        newRecord
      ),
    deleteEventParticipant: (memberId, eventId) =>
      authAxios.delete(
        BASEURL + "eventparticipant/delete/" + memberId + "/" + eventId
      ),
    updateEventParticipant: (record) =>
      authAxios.post(
        BASEURL + "eventparticipant/UpdateSelectiveEventParticipant/",
        record
      ),
    GetEventWiseEventAttendanceReport: (record) =>
      authAxios.post(
        BASEURL + "event/GetEventWiseEventAttendanceReport/",
        record
      ),
    GetRafiqWiseEventAttendanceReport: (record) =>
      authAxios.post(
        BASEURL + "event/GetRafiqWiseEventAttendanceReport/",
        record
      ),
    GetUnitWiseEventAttendanceReport: (record) =>
      authAxios.post(
        BASEURL + "event/GetUnitWiseEventAttendanceReport/",
        record
      ),
  };
}
