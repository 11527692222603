import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup,
  Form,
  Button,
  FormFeedback,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import jwtDecode from "jwt-decode";
import swal from "sweetalert";

import { GetLanguageString } from "../../helper/Components";
import { requestAccountApi } from "../../../actions/api";
import { LoadingSpinner } from "../../../constants/const";
import { encryptStorage } from "../../../constants/EncryptStorage";
import Draggable from "react-draggable";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const ChangePasswordModal = ({ setModal, setReturnLogout }) => {
  const [loading, setLoading] = useState(false);
  const tokenString = encryptStorage.getItem("token");
  var decodedToken = jwtDecode(tokenString);
  const [passwordChangeValues, setPasswordChangeValues] = useState({
    userName: decodedToken.UserName,
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    serverError: [],
  });
  const toggle = () => setModal(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [oldPasswordType, setOldPasswordType] = useState("password");
  const [newPasswordType, setNewPasswordType] = useState("password");
  const [confirmNewPasswordType, setConfirmNewPasswordType] =
    useState("password");

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  const [errors, setErrors] = useState(passwordChangeValues);

  const validate = () => {
    let temp = {};
    temp.oldPassword = passwordChangeValues.oldPassword
      ? ""
      : "Please Enter Current Password";
    temp.newPassword = passwordChangeValues.newPassword
      ? passwordChangeValues.newPassword.length >= 8
        ? ""
        : "Password should consist of 8 or more characters."
      : "Please Enter New Password";
    temp.confirmPassword = passwordChangeValues.confirmPassword
      ? ""
      : "Please Enter Confirm Password";

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setErrors({
      oldPassword: "",
    });
    setPasswordChangeValues({
      ...passwordChangeValues,
      [name]: value,
    });
  };

  const ConfirmPassword = () => {
    if (
      passwordChangeValues.newPassword !== "" &&
      passwordChangeValues.confirmPassword !== ""
    ) {
      let val = passwordChangeValues.newPassword.localeCompare(
        passwordChangeValues.confirmPassword
      );
      if (val === 0) return true;
    } else return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      if (ConfirmPassword()) {
        setLoading(true);
        requestAccountApi()
          .changePassword(passwordChangeValues)
          .then((res) => {
            setModal(false);
            setLoading(false);
            swal({
              title: "Password Changed",
              text: "Please login again",
              icon: "success",
            }).then(() => {
              setReturnLogout(true);
            });
          })
          .catch((err) => {
            setErrors({
              ...errors,
              serverError: err.response.data.errors,
            });
            setLoading(false);
          });
      } else {
        setErrors({
          ...errors,
          confirmPassword: "Passwords do not match",
        });
      }
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="change_password" />}
          </ModalHeader>
          {!loading ? (
            <Form onSubmit={handleSubmit}>
              <ModalBody>
                {errors.serverError && (
                  <ul>
                    {errors.serverError.map((record, index) => {
                      return (
                        <li className="text-danger" key={index}>
                          <h4>{record}</h4>
                        </li>
                      );
                    })}
                  </ul>
                )}
                <FormGroup>
                  <Label>Current Password</Label>
                  <InputGroup>
                    <Input
                      autoFocus
                      type={oldPasswordType}
                      name="oldPassword"
                      className="no-drag"
                      value={passwordChangeValues.oldPassword}
                      onChange={handleInputChange}
                      {...(errors.oldPassword && { invalid: true })}
                    />
                    <InputGroupText
                      className="no-drag"
                      style={{ backgroundColor: "#FFFFFF" }}
                      onClick={() => {
                        setOldPasswordType(
                          oldPasswordType === "password" ? "text" : "password"
                        );
                      }}
                    >
                      {oldPasswordType === "password" ? (
                        <FaEye color="#5C636A" />
                      ) : (
                        <FaEyeSlash color="#5C636A" />
                      )}
                    </InputGroupText>
                  </InputGroup>

                  {errors.oldPassword && (
                    <FormFeedback>
                      <h4>{errors.oldPassword}</h4>
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label>New Password</Label>{" "}
                  <InputGroup>
                    <Input
                      type={newPasswordType}
                      name="newPassword"
                      className="no-drag"
                      value={passwordChangeValues.newPassword}
                      onChange={handleInputChange}
                      {...(errors.newPassword && { invalid: true })}
                    />
                    <InputGroupText
                      className="no-drag"
                      style={{ backgroundColor: "#FFFFFF" }}
                      onClick={() => {
                        setNewPasswordType(
                          newPasswordType === "password" ? "text" : "password"
                        );
                      }}
                    >
                      {newPasswordType === "password" ? (
                        <FaEye color="#5C636A" />
                      ) : (
                        <FaEyeSlash color="#5C636A" />
                      )}
                    </InputGroupText>
                  </InputGroup>
                  {errors.newPassword && (
                    <FormFeedback>
                      <h4>{errors.newPassword}</h4>
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label>Confirm Password</Label>{" "}
                  <InputGroup>
                    <Input
                      type={confirmNewPasswordType}
                      name="confirmPassword"
                      className="no-drag"
                      value={passwordChangeValues.confirmPassword}
                      onChange={handleInputChange}
                      {...(errors.confirmPassword && { invalid: true })}
                    />
                    <InputGroupText
                      className="no-drag"
                      style={{ backgroundColor: "#FFFFFF" }}
                      onClick={() => {
                        setConfirmNewPasswordType(
                          confirmNewPasswordType === "password"
                            ? "text"
                            : "password"
                        );
                      }}
                    >
                      {confirmNewPasswordType === "password" ? (
                        <FaEye color="#5C636A" />
                      ) : (
                        <FaEyeSlash color="#5C636A" />
                      )}
                    </InputGroupText>
                  </InputGroup>
                  {errors.confirmPassword && (
                    <FormFeedback>
                      <h4>{errors.confirmPassword}</h4>
                    </FormFeedback>
                  )}
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="white" onClick={toggle}>
                  {<GetLanguageString props="common_cancel" />}
                </Button>
                <Button color="primary" type="submit">
                  {<GetLanguageString props="common_save_changes" />}
                </Button>
              </ModalFooter>
            </Form>
          ) : (
            LoadingSpinner()
          )}
        </Modal>
      </Draggable>
    </div>
  );
};

export default ChangePasswordModal;
