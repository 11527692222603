import moment from "moment";
import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import {
  convertUTCToLocalTime,
  isUrdu,
  LoadingSpinner,
} from "../../../constants/const";
import { GetLanguageString } from "../../helper/Components";
import { GetSelectedLanguage } from "../../helper/Method";
import { addMember } from "../ApiMember";

const MemberResponsibility = ({ tab9, memberId }) => {
  const [unitNames, setUnitNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [memberResponsibilityList, setMemberResponsibilityList] = useState([]);
  const customTableStyles = {
    border: "1px solid #DEE2E6",
  };
  useEffect(() => {
    if (tab9 === "active") {
      setLoading(true);
      addMember()
        .getMemberResponsibilityList(memberId, true)
        .then((res) => {
          let tempNames = [];
          res.data.forEach((element) => {
            if (element.unit.value !== 0) {
              tempNames.push({
                names: element.unit?.label.split("،"),
              });
            }
          });
          setUnitNames(tempNames);
          setMemberResponsibilityList(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [memberId, tab9]);

  return (
    <div role="tabpanel" id="tab-9" className={"tab-pane " + tab9}>
      <div className="panel-body">
        {!loading ? (
          memberResponsibilityList?.length !== 0 ? (
            <Table bordered>
              <thead>
                <tr>
                  <th>#</th>
                  <th>
                    {
                      <GetLanguageString props="member_member_detail_common_unit_name" />
                    }
                  </th>
                  <th>
                    {
                      <GetLanguageString props="member_member_detail_common_responsibility" />
                    }
                  </th>
                  <th>{<GetLanguageString props="member_report_from" />}</th>
                  <th>{<GetLanguageString props="member_report_to" />}</th>
                  <th>
                    {
                      <GetLanguageString props="member_member_detail_renewal_date" />
                    }
                  </th>
                  <th>{<GetLanguageString props="member_reference_note" />}</th>
                </tr>
              </thead>
              <tbody>
                {memberResponsibilityList?.map((record, index) => {
                  return record.renewalDates.length !== 0 ? (
                    record.renewalDates?.map((item, index1) => {
                      return (
                        <tr key={index}>
                          {index1 === 0 ? (
                            <>
                              <td
                                style={customTableStyles}
                                rowSpan={record.renewalDates.length}
                              >
                                {index + 1}
                              </td>
                              <td
                                style={customTableStyles}
                                rowSpan={record.renewalDates.length}
                                className="ur"
                              >
                                <ol className="breadcrumb">
                                  {unitNames[index]?.names?.map(
                                    (item, index) => {
                                      return (
                                        <li
                                          className={
                                            GetSelectedLanguage() === "ur"
                                              ? "breadcrumb-item-ur"
                                              : "breadcrumb-item"
                                          }
                                          key={index}
                                        >
                                          {item}
                                        </li>
                                      );
                                    }
                                  )}
                                </ol>
                              </td>
                              <td
                                style={customTableStyles}
                                rowSpan={record.renewalDates.length}
                                className="ur"
                              >
                                {record.displayName !== "" ? (
                                  <span>
                                    ({record.displayName}){" "}
                                    {record.responsibilityName}{" "}
                                  </span>
                                ) : (
                                  record.responsibilityName
                                )}
                              </td>
                              <td
                                style={customTableStyles}
                                rowSpan={record.renewalDates.length}
                              >
                                {record.responsibilityDate
                                  ? moment(
                                      convertUTCToLocalTime(
                                        record.responsibilityDate
                                      )
                                    ).format("DD/MM/yyyy")
                                  : "-"}
                              </td>{" "}
                              <td
                                style={customTableStyles}
                                rowSpan={record.renewalDates.length}
                              >
                                {record.responsibilityEndDate
                                  ? moment(
                                      convertUTCToLocalTime(
                                        record.responsibilityEndDate
                                      )
                                    ).format("DD/MM/yyyy")
                                  : "-"}
                              </td>
                            </>
                          ) : null}
                          <td
                            style={{
                              borderBottom: "1px solid #DEE2E6",
                              borderRight: "1px solid #DEE2E6",
                            }}
                          >
                            {item
                              ? moment(convertUTCToLocalTime(item)).format(
                                  "DD/MM/yyyy"
                                )
                              : "-"}
                          </td>

                          {index1 === 0 ? (
                            <>
                              <td
                                style={customTableStyles}
                                rowSpan={record.renewalDates.length}
                                className={
                                  isUrdu(record.referenceNote) ? "ur" : ""
                                }
                              >
                                {record?.referenceNote}
                              </td>
                            </>
                          ) : null}
                        </tr>
                      );
                    })
                  ) : (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className="ur">
                        <ol className="breadcrumb">
                          {unitNames[index]?.names?.map((item, index) => {
                            return (
                              <li
                                className={
                                  GetSelectedLanguage() === "ur"
                                    ? "breadcrumb-item-ur"
                                    : "breadcrumb-item"
                                }
                                key={index}
                              >
                                {item}
                              </li>
                            );
                          })}
                        </ol>
                      </td>
                      <td className="ur">
                        {record.displayName !== "" ? (
                          <span>
                            ({record.displayName}) {record.responsibilityName}{" "}
                          </span>
                        ) : (
                          record.responsibilityName
                        )}
                      </td>
                      <td>
                        {record.responsibilityDate
                          ? moment(
                              convertUTCToLocalTime(record.responsibilityDate)
                            ).format("DD/MM/yyyy")
                          : "-"}
                      </td>
                      <td>
                        {record.responsibilityEndDate
                          ? moment(
                              convertUTCToLocalTime(
                                record.responsibilityEndDate
                              )
                            ).format("DD/MM/yyyy")
                          : "-"}
                      </td>
                      <td>{"-"}</td>
                      <td className={isUrdu(record.referenceNote) ? "ur" : ""}>
                        {record?.referenceNote}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <>
              <h4 className="text-error text-center">
                {
                  <GetLanguageString props="common_no_result_responsibilities" />
                }
              </h4>
            </>
          )
        ) : (
          LoadingSpinner()
        )}
      </div>
    </div>
  );
};

export default MemberResponsibility;
