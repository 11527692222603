import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import swal from "sweetalert";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";

import { GetLanguageString } from "../../helper/Components";
import { ApiUnit } from "../../unit/ApiUnit";
import { GetSelectedLanguage } from "../../helper/Method";
import { LoadingSpinner } from "../../../constants/const";
import SelectResponsibilityData from "./SelectResponsibilityData";
import { encryptStorage } from "../../../constants/EncryptStorage";
import { addMember } from "../ApiMember";
import ResponsibilityErrorMessage from "./ResponsibilityErrorMessage";
import Draggable from "react-draggable";

const AddResponsibilityByUnit = ({
  addNewRafiq,
  unitId,
  loading1,
  memberId,
  setUpdatedDetails,
  addResponsibilityModal,
  setAddResponsibilityModal,
  setResponsibilityStateChange,
}) => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [responsibilityValues, setResponsibilityValues] = useState({
    memberId: memberId,
    responsibilityDate: null,
    referenceNote: "",
    displayName: "",
    unit: {
      value: unitId,
      label: "",
    },
    responsibility: {
      value: 0,
      label: "",
      isMultiple: false,
    },
    responsibilityPermissionExists: false,
  });
  const [unitList, setUnitList] = useState([]);
  const [loadings, setLoadings] = useState(loading1);
  const [loading, setLoading] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const { t } = useTranslation();
  const [displayMessageModal, setDisplayMessageModal] = useState(false);
  const notify = (string) => toast.success(string);
  const unitTypeName = "null";
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  const toggle = () => {
    setAddResponsibilityModal(false);
  };

  useEffect(() => {
    ApiUnit()
      .getUnitList(unitId, loggedInUnitId, false, unitTypeName)
      .then((res) => {
        setUnitList(res.data);
        setLoadings(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadings(false);
      });
  }, [unitId, loggedInUnitId]);

  const handleChangeUnits = (record) => {
    if (record !== null) {
      ApiUnit()
        .getUnitList(record.value, loggedInUnitId, false, unitTypeName)
        .then((res) => {
          setUnitList(res.data);
        })
        .catch((err) => console.log(err));

      setResponsibilityValues({
        ...responsibilityValues,
        unit: record,
      });
    }
  };

  const [errors, setErrors] = useState({
    responsibilityDate: "",
    responsibility: "",
    displayName: "",
  });

  const validate = () => {
    let temp = {};
    temp.responsibilityDate =
      responsibilityValues.responsibilityDate !== null ? (
        ""
      ) : (
        <GetLanguageString props="member_member_details_responsibility_date_error_message" />
      );

    temp.responsibility =
      responsibilityValues.responsibility.value !== 0 &&
      Object.values(responsibilityValues.responsibility).length !== 0 ? (
        ""
      ) : (
        <GetLanguageString props="member_member_details_responsibility_error_message" />
      );

    if (responsibilityValues.responsibility.isMultiple) {
      temp.displayName =
        responsibilityValues.responsibility.isMultiple &&
        responsibilityValues.displayName !== "" ? (
          ""
        ) : (
          <GetLanguageString props="responsibility_title_err" />
        );
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      if (!responsibilityValues.responsibility.isMultiple)
        responsibilityValues.displayName = "";
      setButtonDisable(true);
      setLoading(true);
      addMember()
        .addMemberResponsibility(responsibilityValues, addNewRafiq)
        .then((res) => {
          if (res.data === "Your request for modification is sent.") {
            notify(res.data);
            setLoading(false);
            toggle();
            setButtonDisable(false);
          } else if (res.data.cannotAssignResponsibility) {
            swal({
              title: t(
                "member__member_responsibility_error_cannot_assign_resposnibility"
              ),
              icon: "error",
              buttons: "OK",
              className: GetSelectedLanguage() === "en" ? "" : "ur",
            });
            setButtonDisable(false);
            setLoading(false);
            toggle();
          } else if (
            !res.data.thisMemberHaveResponsibility &&
            !res.data.otherMemberHaveResponsibility &&
            !res.data.isUsraMavin
          ) {
            swal({
              title: t("member__member_responsibility_added_success_message"),
              icon: "success",
              buttons: "OK",
              className: GetSelectedLanguage() === "en" ? "" : "ur",
            });
            setLoading(false);
            setResponsibilityStateChange((prevState) => !prevState);
            toggle();
            setButtonDisable(false);
            setUpdatedDetails((prevState) => !prevState);
          } else {
            setErrorMessages(res.data);
            setDisplayMessageModal(true);
            setLoading(false);
            setButtonDisable(false);
          }
        })
        .catch((err) => {
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
          console.log(err);
          setLoading(false);
          setButtonDisable(false);
        });
    }
  };

  return (
    <>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal
          size="lg"
          isOpen={addResponsibilityModal}
          className="inmodal"
          autoFocus={false}
        >
          {displayMessageModal ? (
            <ResponsibilityErrorMessage
              setDisplayMessageModal={setDisplayMessageModal}
              data={errorMessages}
              displayName={responsibilityValues.displayName}
            />
          ) : null}
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="settings_add_responsibility" />}
          </ModalHeader>
          <ModalBody>
            {!loading ? (
              !loadings ? (
                <Row>
                  <div className="col-md-12">
                    <Row>
                      <Label className="col-form-label">
                        <GetLanguageString props="member_select_responsibility_unit" />
                      </Label>
                      {unitList.map((record, index) => {
                        const unitOptions = [];
                        record.unitList.forEach((d) =>
                          d.unitTypeName !== "UsraMuavin" &&
                          d.unitTypeName !== "Munfarid" &&
                          d.unitTypeName !== "EX"
                            ? unitOptions.push({ value: d.id, label: d.name })
                            : null
                        );
                        let selected = {};
                        if (record.selectedUnit !== null) {
                          selected = {
                            value: record.selectedUnit.id,
                            label: record.selectedUnit.name,
                          };
                        }

                        const isLastIndex = index === unitList.length - 1;

                        return (
                          <>
                            <Col md="4" key={index}>
                              <FormGroup>
                                <Row>
                                  <Col md="10">
                                    <Select
                                      options={unitOptions}
                                      value={selected}
                                      className="basic-single ur no-drag"
                                      classNamePrefix="select"
                                      isDisabled={
                                        responsibilityValues.responsibilityPermissionExists
                                      }
                                      onChange={handleChangeUnits}
                                    />
                                  </Col>
                                  {isLastIndex ? null : (
                                    <Col md="2" className="p-0">
                                      {GetSelectedLanguage() === "en" ? (
                                        <i className="fa fa-arrow-right fa-lg m"></i>
                                      ) : (
                                        <i className="fa fa-arrow-left fa-lg m"></i>
                                      )}
                                    </Col>
                                  )}
                                </Row>
                              </FormGroup>
                            </Col>
                          </>
                        );
                      })}
                    </Row>
                    <div>
                      <SelectResponsibilityData
                        setValues={setResponsibilityValues}
                        values={responsibilityValues}
                        errors={errors}
                        setErrors={setErrors}
                        selectedUnit={responsibilityValues?.unit?.value}
                        memberId={memberId}
                        type={"add"}
                      />
                    </div>
                  </div>
                </Row>
              ) : (
                LoadingSpinner()
              )
            ) : (
              LoadingSpinner()
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="white" onClick={toggle}>
              {<GetLanguageString props="common_cancel" />}
            </Button>
            <Button
              color="primary"
              disabled={buttonDisable}
              type="submit"
              onClick={handleSubmit}
            >
              {<GetLanguageString props="common_save_changes" />}
            </Button>
          </ModalFooter>
        </Modal>
      </Draggable>
    </>
  );
};

export default AddResponsibilityByUnit;
