import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Table } from "reactstrap";
import swal from "sweetalert";

import { requestApi } from "../../../actions/api";
import { LoadingSpinner, isUrdu } from "../../../constants/const";
import { GetLanguageString, NoResult } from "../../helper/Components";
import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import AddNotificationType from "./AddNotificationType";
import EditNotificationType from "./EditNotificationType";
import { IoAdd } from "react-icons/io5";

const NotificationType = ({ activeTab }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [notificationType, setNotificationType] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [editValue, setEditValue] = useState();
  const [addModal, setAddModal] = useState(false);

  useEffect(() => {
    if (activeTab === "notification") {
      setLoading(true);
      requestApi("setupnotificationtype/")
        .fetchAll()
        .then((res) => {
          setNotificationType(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [activeTab]);

  const handleOnDelete = (value, isAffiliated) => {
    if (isAffiliated) {
      swal({
        title: t("common_is_affiliated"),
        text: t("common_is_affiliated_message"),
        className: GetSelectedLanguage() === "en" ? "" : "ur",
        icon: "warning",
      });
    } else {
      swal({
        title: t("common_delete_message"),
        text: t("common_delete_detail_message"),
        className: GetSelectedLanguage() === "en" ? "" : "ur",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setLoading(true);
          requestApi("setupnotificationtype/")
            .delete(value)
            .then((res) => {
              setNotificationType(res.data);
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        }
      });
    }
  };

  const handleOnEdit = (e) => {
    const { value } = e.target;
    setEditValue(value);
    setEditModal(true);
  };

  return (
    <>
      {addModal ? (
        <AddNotificationType
          setAddModal={setAddModal}
          setNotificationType={setNotificationType}
        />
      ) : null}
      {editModal ? (
        <EditNotificationType
          editValue={editValue}
          setEditModal={setEditModal}
          setNotificationType={setNotificationType}
        />
      ) : null}
      {!loading ? (
        <div className="ibox">
          <div className="ibox-title">
            <h5>{<GetLanguageString props="settings_notification_type" />}</h5>
            <Button
              color="white"
              size="sm"
              className={GetCustomStyles().btn_style}
              onClick={() => setAddModal(true)}
            >
              <IoAdd
                size={18}
                color="#18A689"
                style={{ marginRight: 3, marginBottom: 1 }}
              />
              <GetLanguageString props="settings_notification_type_add" />
            </Button>
          </div>
          <div className="ibox-content">
            {notificationType.length !== 0 ? (
              <div className="table-responsive">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>
                        {
                          <GetLanguageString props="settings_notification_type_code" />
                        }
                      </th>
                      <th>
                        {
                          <GetLanguageString props="settings_notification_type_name" />
                        }
                      </th>
                      <th className="text-center">
                        {
                          <GetLanguageString props="settings_notification_type_is_approval_process" />
                        }
                      </th>
                      <th>
                        {
                          <GetLanguageString props="settings_notification_type_recipients" />
                        }
                      </th>
                      <th className="text-center">
                        {
                          <GetLanguageString props="settings_notification_type_approval_bodies" />
                        }
                      </th>
                      <th className="text-center">
                        {<GetLanguageString props="common_action" />}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {notificationType.map((record, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{record.code}</td>
                          <td>{record.name}</td>
                          <td className="text-center">
                            {record.isApprovalProcess ? "Yes" : "No"}
                          </td>
                          <td>
                            {record.recipientNames.map((r) => {
                              return (
                                <span
                                  className={
                                    isUrdu(r) ? "label m-1 ur" : "label m-1"
                                  }
                                >
                                  {r}
                                </span>
                              );
                            })}
                          </td>
                          <td className="ur">
                            {record.approvalBodyNames.map((r) => {
                              return (
                                <span
                                  className={
                                    isUrdu(r)
                                      ? "label label-success m-1 ur"
                                      : "label label-success m-1"
                                  }
                                >
                                  {r}
                                </span>
                              );
                            })}
                          </td>
                          <td className="text-right">
                            <ButtonGroup>
                              <Button
                                color="white"
                                size="sm"
                                onClick={handleOnEdit}
                                value={record.id}
                              >
                                {<GetLanguageString props="common_edit" />}
                              </Button>
                              <Button
                                color="white"
                                size="sm"
                                onClick={() =>
                                  handleOnDelete(record.id, record.isAffiliated)
                                }
                              >
                                {<GetLanguageString props="common_delete" />}
                              </Button>
                            </ButtonGroup>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            ) : (
              <NoResult />
            )}
          </div>
        </div>
      ) : (
        LoadingSpinner()
      )}
    </>
  );
};

export default NotificationType;
