import React, { useState, useEffect } from "react";
import {
  FormGroup,
  Form,
  Label,
  Button,
  ModalBody,
  ModalFooter,
  Modal,
  ModalHeader,
} from "reactstrap";
import swal from "sweetalert";
import { t } from "i18next";
import DatePicker from "react-datepicker";
import Draggable from "react-draggable";
import { convertToDateString, LoadingSpinner, PledgeDateValidator } from "../../../constants/const";
import { GetLanguageString } from "../../helper/Components";
import { addMember } from "../ApiMember";
import { GetSelectedLanguage } from "../../helper/Method";
import Select from "react-select";

const AddMemberPledgeModal = ({
  memberId,
  refresh,
  setRefresh,
  setAddModal,
}) => {
  const [addPledgeValues, setAddPledeValues] = useState({
    memberId: memberId,
    pledge: {},
    pledgeDate: new Date(),
  });
  const [errors, setErrors] = useState();
  const [loading, setLoading] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [pledgesDropdown, setPledgesDropdown] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  useEffect(() => {
    addMember()
      .GetMemberMissingPlegdes(memberId)
      .then((res) => {
        setPledgesDropdown(res.data);
        setAddPledeValues({
          ...addPledgeValues,
          pledge: res.data[0],
        });
      })
      .catch((err) => {
        swal({
          title: err.response?.data || err,
          icon: "error",
          buttons: "OK",
        });
        console.log(err.response?.data || err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberId]);

  const validate = () => {
    let temp = {};
    temp.pledgeDate = selectedDate ? (
      PledgeDateValidator(selectedDate, addPledgeValues.pledge.pledgeDate) ? ( //check if the pledge date is valid
        ""
      ) : (
        t("member_member_detail_pledges_date_error_message")
      )
    ) : (
      <GetLanguageString props="member_member_detail_mudaris_details_assessments_date_error" />
    );
    temp.pledge =
      addPledgeValues.pledge === "" || addPledgeValues.pledge === null ? (
        <GetLanguageString props="member_member_detail_select_pledges_type_error" />
      ) : (
        ""
      );

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleChangePledge = (record) => {
    setAddPledeValues({
      ...addPledgeValues,
      pledge: record,
    });
    setErrors({
      ...errors,
      pledge: "",
    });
  };

  const handleInputChangeDate = (value) => {
    if (value <= new Date()) {
      setSelectedDate(value);
      setErrors({
        ...errors,
        pledgeDate: "",
      });
    } else {
      setErrors({
        ...errors,
        pledgeDate: t("tutorial_date_error_message"),
      });
      setSelectedDate(null)
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      if (selectedDate <= new Date()) {
        addPledgeValues.pledgeDate = convertToDateString(selectedDate)
        setLoading(true);
        setButtonDisable(true);
        addMember()
          .AddMemberPledge(addPledgeValues)
          .then(() => {
            swal({
              title: t("member_member_detail_add_pledge_msg"),
              className: GetSelectedLanguage() === "en" ? "" : "ur",
              icon: "success",
              buttons: GetSelectedLanguage() === "en" ? "OK" : "ٹھیک ہے ",
            });
            setRefresh(!refresh);
            setAddModal(false);
            setLoading(false);
            setButtonDisable(false);
          })
          .catch((err) => {
            swal({
              title: err,
              icon: "error",
              buttons: "OK",
            });
            setLoading(false);
            setButtonDisable(false);
          });
      }
    }
  };

  return (
    <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
      <Modal isOpen={true} className="inmodal" autoFocus={false}>
        <ModalHeader className="modal-title" tag="h4">
          <GetLanguageString props="member_member_detail_add_pledge" />
        </ModalHeader>
        {!loading ? (
          <Form onSubmit={handleSubmit}>
            <ModalBody>
              <FormGroup>
                <Label className="col-form-label">
                  {" "}
                  {
                    <GetLanguageString props="member_member_detail_select_pledges_type" />
                  }
                </Label>
                <Select
                  value={addPledgeValues.pledge}
                  options={pledgesDropdown}
                  className="basic-multi-select ur no-drag"
                  classNamePrefix="select"
                  onChange={(record) => {
                    handleChangePledge(record);
                  }}
                />
                {errors?.pledge && (
                  <div className="text-error">{errors.pledge}</div>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label">
                  {
                    <GetLanguageString props="member_member_detail_pledges_date" />
                  }
                </Label>
                <DatePicker
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="dd/mm/yyyy"
                  className="form-control no-drag"
                  selected={selectedDate}
                  maxDate={new Date()}
                  onChange={handleInputChangeDate}
                  dateFormat="dd/MM/yyyy"
                />
                {errors?.pledgeDate && (
                  <h4 className="text-error">{errors?.pledgeDate}</h4>
                )}
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="white" onClick={() => setAddModal(false)}>
                <GetLanguageString props="common_cancel" />
              </Button>
              <Button color="primary" type="submit">
                <GetLanguageString
                  props="common_save"
                  disabled={buttonDisable}
                />
              </Button>
            </ModalFooter>
          </Form>
        ) : (
          LoadingSpinner()
        )}
      </Modal>
    </Draggable>
  );
};

export default AddMemberPledgeModal;
