import React, { useEffect, useState } from "react";
import { Table, Button, ButtonGroup } from "reactstrap";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";

import EditCourseType from "./EditCourse";
import { GetLanguageString, NoResult } from "../../helper/Components";
import { LoadingSpinner } from "../../../constants/const";
import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import { ApiCourseType } from "./ApiCourseType";
import AddCourseType from "./AddCourse";
import { IoAdd } from "react-icons/io5";

const initialValues = {
  name: "neutral",
  code: "neutral",
};

const CourseType = ({ activeTab }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [courseType, setCourseType] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [editValue, setEditValue] = useState();
  const [reload, setReload] = useState(false);
  const [addModal, setAddModal] = useState(false);

  useEffect(() => {
    if (activeTab === "course") {
      setLoading(true);
      let tempSortObject = {
        isCourseTypeAscending: initialValues.name === "ascending",
        isCourseTypeDescending: initialValues.name === "descending",
        isCodeAscending: initialValues.code === "ascending",
        isCodeDescending: initialValues.code === "descending",
      };
      ApiCourseType()
        .getList(tempSortObject)
        .then((res) => {
          setCourseType(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [activeTab, reload]);

  const handleOnDelete = (value, isAffiliated) => {
    if (isAffiliated) {
      swal({
        title: t("common_is_affiliated"),
        text: t("common_is_affiliated_message"),
        className: GetSelectedLanguage() === "en" ? "" : "ur",
        icon: "warning",
      });
    } else {
      swal({
        title: t("common_delete_message"),
        text: t("common_delete_detail_message"),
        className: GetSelectedLanguage() === "en" ? "" : "ur",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setLoading(true);
          ApiCourseType()
            .delete(value)
            .then((res) => {
              setCourseType(res.data);
              setLoading(false);
              swal({
                title: t("settings_course_type_delete_success"),
                className: GetSelectedLanguage() === "en" ? "" : "ur",
                icon: "success",
                buttons: "OK",
              });
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
              swal({
                title: err,
                icon: "error",
                buttons: "OK",
              });
            });
        }
      });
    }
  };

  const handleOnEdit = (e) => {
    const { value } = e.target;
    setEditValue(value);
    setEditModal(true);
  };
  return (
    <>
      {addModal ? (
        <AddCourseType
          setModal={setAddModal}
          setReload={setReload}
          reload={reload}
        />
      ) : null}
      {editModal ? (
        <EditCourseType
          editValue={editValue}
          setEditModal={setEditModal}
          setCourseType={setCourseType}
        />
      ) : null}{" "}
      <div className="ibox">
        <div className="ibox-title">
          <h5>{<GetLanguageString props="settings_course_type" />}</h5>
          <Button
            color="white"
            size="sm"
            className={GetCustomStyles().btn_style}
            onClick={() => setAddModal(true)}
          >
            <IoAdd
              size={18}
              color="#18A689"
              style={{ marginRight: 3, marginBottom: 1 }}
            />
            <GetLanguageString props="settings_course_type_add" />
          </Button>
        </div>

        <div className="ibox-content">
          {!loading ? (
            courseType.length !== 0 ? (
              <div className="table-responsive">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th className="text-center">
                        {<GetLanguageString props="settings_code" />}
                      </th>
                      <th className="text-center">
                        <GetLanguageString props="common_name" />
                      </th>
                      <th className="text-center">
                        {
                          <GetLanguageString props="settings_course_type_short_name" />
                        }
                      </th>
                      <th className="text-center">
                        <GetLanguageString props="settings_event_type_allow_ahbab" />
                      </th>
                      <th className="text-center">
                        <GetLanguageString props="settings_course_type_for_teachers" />
                      </th>
                      <th className="text-center">
                        <GetLanguageString props="member_address_enabled" />
                      </th>
                      <th className="text-center">
                        <GetLanguageString props="settings_color_code" />
                      </th>

                      <th className="text-center">
                        {<GetLanguageString props="common_action" />}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {courseType.map((record, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-center">{index + 1}</td>
                          <td className="text-center">{record.code}</td>
                          <td className="ur text-center">{record.name}</td>
                          <td className="ur text-center">{record.shortName}</td>
                          <td className="text-center">
                            {record.allowAhbab === true ? (
                              <GetLanguageString props="Yes" />
                            ) : (
                              <GetLanguageString props="No" />
                            )}
                          </td>
                          <td className="text-center">
                            {record.forTeachers === true ? (
                              <GetLanguageString props="Yes" />
                            ) : (
                              <GetLanguageString props="No" />
                            )}
                          </td>
                          <td className="text-center">
                            {record.isEnabled === true ? (
                              <GetLanguageString props="Yes" />
                            ) : (
                              <GetLanguageString props="No" />
                            )}
                          </td>
                          <td className="text-center">
                            <div
                              style={{
                                width: "25px",
                                height: "25px",
                                borderRadius: "50%",
                                backgroundColor: record.color,
                              }}
                            >
                              {" "}
                              <span
                                className={
                                  GetSelectedLanguage() === "en"
                                    ? "m-l-lg"
                                    : "m-r-lg"
                                }
                              >
                                {record.color}
                              </span>
                            </div>
                          </td>
                          <td className="text-center">
                            <ButtonGroup>
                              <Button
                                color="white"
                                size="sm"
                                onClick={handleOnEdit}
                                value={record.id}
                              >
                                {<GetLanguageString props="common_edit" />}
                              </Button>
                              <Button
                                color="white"
                                size="sm"
                                onClick={() =>
                                  handleOnDelete(record.id, record.isAffiliated)
                                }
                              >
                                {<GetLanguageString props="common_delete" />}
                              </Button>
                            </ButtonGroup>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            ) : (
              <NoResult />
            )
          ) : (
            LoadingSpinner()
          )}
        </div>
      </div>
    </>
  );
};

export default CourseType;
