import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  ButtonGroup,
} from "reactstrap";
import { withRouter } from "react-router-dom";

import useForm from "../shared/useForm";
import { requestApi } from "../../actions/api";
import { GetLanguageString } from "../helper/Components";
import SelectUnit from "../shared/SelectUnit";
import {
  DateValidater,
  GetCustomStyles,
  GetSelectedLanguage,
} from "../helper/Method";
import { convertToDateString, LoadingSpinner } from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";
import { ApiCourse } from "./ApiCourse";
import AddCourseLocation from "./AddCourseLocation";
import EditCourseLocation from "./EditCourseLocation";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import { IoAdd } from "react-icons/io5";
import { BiSolidEdit } from "react-icons/bi";
import { BsTrash3Fill } from "react-icons/bs";

const courseValues = {
  startDate: null,
  description: "",
  endDate: null,
  unit: {},
  courseType: {},
  courseLocation: {},
};

const AddCourse = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [courseType, setCourseType] = useState([]);
  const [selectedCourseType, setSelectedCourseType] = useState({});
  const [courseLocation, setCourseLocation] = useState([]);
  const [selectedCourseLocation, setSelectedCourseLocation] = useState({});
  const [excludeExUnit, setExcludeExUnit] = useState(true);
  const [reloadList, setReloadList] = useState(false);
  const [addCourseLocationModal, setAddCourseLocationModal] = useState(false);
  const [editCourseLocationModal, setEditCourseLocationModal] = useState(false);
  const { errors, setErrors, values, handleInputChange, setValues } =
    useForm(courseValues);
  const { t } = useTranslation();
  const [selectedDates, setSelectedDates] = useState({
    startDate: null,
    endDate: null,
  });

  useEffect(() => {
    ApiCourse()
      .getAddCourseList()
      .then((res) => {
        setCourseType(res.data);
        if (res.data !== null) setSelectedCourseType(res.data[0]);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    ApiCourse()
      .getCoursesLocations()
      .then((res) => {
        setCourseLocation(res.data);
        if (res.data !== null) setSelectedCourseLocation(res.data[0]);
      })
      .catch((err) => console.log(err));
  }, [reloadList]);

  const validate = () => {
    let temp = {};
    temp.startDate = selectedDates.startDate ? (
      ""
    ) : (
      <GetLanguageString props="courses_start_date_error_message" />
    );
    temp.endDate = selectedDates.endDate ? (
      ""
    ) : (
      <GetLanguageString props="courses_end_date_error_message" />
    );
    temp.courseType =
      Object.keys(values.courseType).length === 0 ? "error" : "";

    temp.courseLocation =
      Object.keys(values.courseLocation).length === 0 ? "error" : "";
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleChangeCourseType = (record) => {
    let temp = {};
    if (record !== null) temp = record;
    setSelectedCourseType(temp);
    setValues({
      ...values,
      courseType: temp,
    });
    setErrors(courseValues);
  };
  const handleChangeCourseLocation = (record) => {
    let temp = {};
    if (record !== null) temp = record;
    setSelectedCourseLocation(temp);
    setValues({
      ...values,
      courseLocation: temp,
    });
    setErrors(courseValues);
  };

  const handleInputChangeStartDate = (value) => {
    if (DateValidater(value, selectedDates.endDate)) {
      setSelectedDates({
        ...selectedDates,
        startDate: value,
      });
      setErrors(courseValues);
    } else {
      setErrors({
        ...errors,
        startDate: (
          <GetLanguageString props="common_start_date_validation_message" />
        ),
      });
    }
  };

  const handleOnDelete = (id) => {
    swal({
      title: t("common_delete_message"),
      text: t("common_delete_detail_message"),
      className: GetSelectedLanguage() === "en" ? "" : "ur",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        ApiCourse()
          .deleteCourseLocation(id)
          .then((res) => {
            swal({
              title: t("courses_delete_course_location_success"),
              className: GetSelectedLanguage() === "en" ? "" : "ur",
              icon: "success",
              buttons: "OK",
            });
            setReloadList(!reloadList);
          })
          .catch((err) => {
            console.log(err);
            swal({
              title: t(err.response.data),
              className: GetSelectedLanguage() === "en" ? "" : "ur",
              icon: "error",
              buttons: "OK",
            });
          });
      }
    });
  };

  const handleInputChangeEndDate = (value) => {
    if (DateValidater(selectedDates.startDate, value)) {
      setSelectedDates({
        ...selectedDates,
        endDate: value,
      });
      setErrors(courseValues);
    } else {
      setErrors({
        ...errors,
        endDate: (
          <GetLanguageString props="common_end_date_validation_message" />
        ),
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (Object.keys(values.unit).length === 0)
      values.unit = { value: loggedInUnitId, lable: "" };
    values.courseType = selectedCourseType;
    values.courseLocation = selectedCourseLocation;
    if (validate()) {
      setLoading(true);
      values.startDate = convertToDateString(selectedDates.startDate);
      values.endDate = convertToDateString(selectedDates.endDate);
      requestApi("course/")
        .create(values)
        .then((res) => {
          history.goBack();
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const handleExcludeExUnit = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setExcludeExUnit(v);
  };
  return (
    <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
      {addCourseLocationModal ? (
        <AddCourseLocation
          setAddCourseLocationModal={setAddCourseLocationModal}
          setReloadList={setReloadList}
          reloadList={reloadList}
        />
      ) : null}{" "}
      {editCourseLocationModal ? (
        <EditCourseLocation
          setEditCourseLocationModal={setEditCourseLocationModal}
          selectedCourseLocation={selectedCourseLocation}
          setReloadList={setReloadList}
          reloadList={reloadList}
        />
      ) : null}
      {!loading ? (
        <Form onSubmit={handleSubmit}>
          <div className="ibox ">
            <div className="ibox-title">
              <h3>{<GetLanguageString props="courses_add_course" />}</h3>
            </div>
            <div className="ibox-content">
              <Row>
                <Col lg="3">
                  <FormGroup>
                    <Row>
                      <Col md="6">
                        {" "}
                        <Label className="col-form-label">
                          {
                            <GetLanguageString props="courses_course_location" />
                          }
                        </Label>
                      </Col>
                      <Col md="6" className="p-1">
                        {" "}
                        <ButtonGroup className={GetCustomStyles().btn_style}>
                          <Button
                            color="white"
                            size="xs"
                            onClick={() => setAddCourseLocationModal(true)}
                          >
                            <IoAdd
                              size={15}
                              color="#18A689"
                              style={{ marginRight: 3, marginBottom: 1 }}
                            />
                            {
                              <GetLanguageString props="tutorial_add_category" />
                            }
                          </Button>
                          {selectedCourseLocation !== null &&
                          Object.keys(selectedCourseLocation).length !== 0 ? (
                            <Button
                              color="white"
                              size="xs"
                              onClick={() => setEditCourseLocationModal(true)}
                            >
                              <BiSolidEdit
                                size={12}
                                style={{ marginRight: 3, marginBottom: 1 }}
                              />
                              {<GetLanguageString props="common_edit" />}
                            </Button>
                          ) : null}
                          <Button
                            color="white"
                            size="xs"
                            onClick={() =>
                              handleOnDelete(selectedCourseLocation.value)
                            }
                          >
                            <BsTrash3Fill
                              size={12}
                              style={{ marginRight: 3, marginBottom: 1 }}
                            />
                            {<GetLanguageString props="common_delete" />}
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                    <Select
                      value={selectedCourseLocation}
                      options={courseLocation}
                      className="basic-single ur"
                      isClearable={true}
                      placeholder="None"
                      classNamePrefix="select"
                      onChange={handleChangeCourseLocation}
                    />
                    {errors.courseLocation === "error" ? (
                      <h4 className="text-error">
                        {
                          <GetLanguageString props="courses_course_location_error_message" />
                        }
                      </h4>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col lg="3">
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="courses_course_type" />}
                    </Label>
                    <Select
                      value={selectedCourseType}
                      options={courseType}
                      className="basic-single ur"
                      isClearable={true}
                      placeholder="None"
                      classNamePrefix="select"
                      onChange={handleChangeCourseType}
                    />
                    {errors.courseType === "error" ? (
                      <h4 className="text-error">
                        {
                          <GetLanguageString props="courses_course_type_error_message" />
                        }
                      </h4>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col lg="3">
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="courses_start_date" />}
                    </Label>
                    <DatePicker
                      showYearDropdown
                      placeholderText="dd/mm/yyyy"
                      className="form-control"
                      selected={selectedDates.startDate}
                      onChange={handleInputChangeStartDate}
                      dateFormat="dd/MM/yyyy"
                    />
                    {errors.startDate && (
                      <h4 className="text-error">{errors.startDate}</h4>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="3">
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="courses_end_date" />}
                    </Label>
                    <DatePicker
                      showYearDropdown
                      placeholderText="dd/mm/yyyy"
                      className="form-control"
                      selected={selectedDates.endDate}
                      onChange={handleInputChangeEndDate}
                      dateFormat="dd/MM/yyyy"
                    />
                    {errors.endDate && (
                      <h4 className="text-error">{errors.endDate}</h4>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="courses_description" />}
                    </Label>
                    <Input
                      className="ur"
                      type="text"
                      name="description"
                      value={values.description}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </div>

          <div className="ibox">
            <div className="ibox-title">
              <h3>{<GetLanguageString props="courses_select_unit" />}</h3>
            </div>
            <div className="ibox-content">
              <Row>
                <Col>
                  {
                    <SelectUnit
                      setValues={setValues}
                      values={values}
                      unitId={loggedInUnitId}
                      excludeExUnit={excludeExUnit}
                    />
                  }
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <FormGroup check>
                    <Label check for="excludeExUnit">
                      <Input
                        id="excludeExUnit"
                        type="checkbox"
                        name="excludeExUnit"
                        checked={!excludeExUnit}
                        value={!excludeExUnit}
                        onChange={handleExcludeExUnit}
                      />
                      {
                        <GetLanguageString props="member_search_member_ex_unit" />
                      }
                    </Label>
                  </FormGroup>
                </Col>
              </Row>

              <div className="hr-line-dashed"></div>
              <Row>
                <Col md="4">
                  <Button color="white" onClick={() => history.goBack()}>
                    {<GetLanguageString props="common_cancel" />}
                  </Button>
                  <Button color="primary" className="m-l-sm" type="submit">
                    {<GetLanguageString props="common_save_changes" />}
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      ) : (
        LoadingSpinner()
      )}
    </div>
  );
};

export default withRouter(AddCourse);
