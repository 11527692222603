import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { encryptStorage } from "../../../constants/EncryptStorage";
import { GetLanguageString } from "../../helper/Components";
import UpdateMemberPhotoForm from "../../member/UpdateMember/UpdateMemberPhotoForm";
import Draggable from "react-draggable";

const ChangePhotoModal = ({ setChangePhotoModal }) => {
  const memberId = encryptStorage.getItem("memberId");
  const [updatedDetails, setUpdatedDetails] = useState(false);
  // eslint-disable-next-line
  const [imageUrlForDetails, setImageUrlForDetails] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  
  useEffect(() => {
    if (updatedDetails) {
      setChangePhotoModal(false);
    }
  // eslint-disable-next-line
  }, [updatedDetails])


  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="change_profile_photo" />}
          </ModalHeader>
            <UpdateMemberPhotoForm 
              memberId={memberId} 
              setUpdatedDetails={setUpdatedDetails}
              setImageUrlForDetails={setImageUrlForDetails}
            />
          <ModalFooter>
            <Button onClick={() => {setChangePhotoModal(false)}}>Close</Button>
          </ModalFooter>
        </Modal>
      </Draggable>
    </div>
  );
};

export default ChangePhotoModal;
