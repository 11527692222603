import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { Row, Col, FormGroup, Label, Input, Button } from "reactstrap";

import { requestApi } from "../../actions/api";
import { GetLanguageString } from "../helper/Components";
import { DateValidater, GetSelectedLanguage } from "../helper/Method";
import SelectUnit from "../shared/SelectUnit";
import { encryptStorage } from "../../constants/EncryptStorage";
import { isUrdu } from "../../constants/const";

const SearchEvent = ({
  searchEventModel,
  setSearchEventModel,
  unitId,
  setSearchButton,
}) => {
  const unitTypeName = encryptStorage.getItem("unitTypeName");
  const [eventType, setEventType] = useState([]);
  const [values, setValues] = useState(searchEventModel);
  const dateRangeValidation = {
    startDate: null,
    endDate: null,
  };
  const [dateValidations, setDateValidations] = useState(dateRangeValidation);
  const eventOptions = [];

  useEffect(() => {
    requestApi("eventtype/")
      .fetchAll()
      .then((res) => {
        setEventType(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  eventType.forEach((d) => eventOptions.push({ value: d.id, label: d.type }));

  const handleChangeEventType = (record) => {
    setValues({
      ...values,
      eventType: record,
    });
  };

  const handleInputChangeStartDate = (value) => {
    if (DateValidater(value, values.endDate)) {
      setValues({
        ...values,
        startDate: value,
      });
      setDateValidations(dateRangeValidation);
    } else {
      setDateValidations({
        ...dateValidations,
        startDate: (
          <GetLanguageString props="common_start_date_validation_message" />
        ),
      });
    }
  };

  const handleInputChangeEndDate = (value) => {
    if (DateValidater(values.startDate, value)) {
      setValues({
        ...values,
        endDate: value,
      });
      setDateValidations(dateRangeValidation);
    } else {
      setDateValidations({
        ...dateValidations,
        endDate: (
          <GetLanguageString props="common_end_date_validation_message" />
        ),
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubUnits = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setValues({
      ...values,
      isSubUnits: v,
    });
  };

  const handleSubmit = () => {
    if (values.unit.value === 0)
      values.unit = { value: unitId, label: "", label1: unitTypeName };
    setSearchButton(true);
    setSearchEventModel(values);
  };

  return (
    <>
      <Row>
        <Col xl="4" xxl="2" md="6">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="courses_start_date" />}
            </Label>
            <DatePicker
              showYearDropdown
              placeholderText="dd/mm/yyyy"
              className="form-control"
              selected={values.startDate}
              onChange={handleInputChangeStartDate}
              dateFormat="dd/MM/yyyy"
            />
            {dateValidations.startDate && (
              <h4 className="text-error">{dateValidations.startDate}</h4>
            )}
          </FormGroup>
        </Col>
        <Col xl="4" xxl="2" md="6">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="courses_end_date" />}
            </Label>
            <DatePicker
              showYearDropdown
              placeholderText="dd/mm/yyyy"
              className="form-control"
              selected={values.endDate}
              onChange={handleInputChangeEndDate}
              dateFormat="dd/MM/yyyy"
            />
            {dateValidations.endDate && (
              <h4 className="text-error">{dateValidations.endDate}</h4>
            )}
          </FormGroup>
        </Col>
        <Col xl="4" xxl="2" md="6">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="events_event_type" />}
            </Label>
            <Select
              options={eventOptions}
              className="basic-single ur"
              isClearable={true}
              placeholder={
                GetSelectedLanguage() === "en" ? "Select" : "منتخب کریں"
              }
              onChange={handleChangeEventType}
            />
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="courses_address" />}
            </Label>
            <Input
              className={isUrdu(values.address) ? "ur" : ""}
              type="text"
              name="address"
              value={values.address}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <SelectUnit
        unitId={unitId}
        setValues={setValues}
        values={values}
        excludeExUnit={true}
      />
      <Row>
        <Col md="2">
          <FormGroup check>
            <Label check for="subUnit">
              <Input
                id="subUnit"
                type="checkbox"
                name="isSubUnits"
                checked={values.isSubUnits}
                value={!values.isSubUnits}
                onChange={handleSubUnits}
              />
              {<GetLanguageString props="member_search_member_sub_unit" />}
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <FormGroup>
            <Button
              color="primary"
              className="m-l-sm"
              type="submit"
              onClick={handleSubmit}
            >
              {<GetLanguageString props="common_search" />}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default SearchEvent;
