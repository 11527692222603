import { useEffect, useState } from "react";
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";
import { ApiNotifications } from "../../notification/ApiNotifications";
import { encryptStorage } from "../../../constants/EncryptStorage";
import { withRouter } from "react-router-dom";
import { GetLanguageString } from "../../helper/Components";
import { GetSelectedLanguage } from "../../helper/Method";
import { IoIosNotifications } from "react-icons/io";

const UserNotifications = ({ history }) => {
  const [notificationsDropDownOpen, setNotificationsDropDownOpen] =
    useState(false);
  const notificationsToggle = () =>
    setNotificationsDropDownOpen((prevState) => !prevState);
  const [totalNotifications, setTotalNotifications] = useState(0);
  const [notificationsDropDownData, setNotificationsDropDownData] = useState(
    []
  );
  const responsibilityId = encryptStorage.getItem("responsibilityId");
  const loggedInUnitId = encryptStorage.getItem("loggedInUnitId");
  const memberId = encryptStorage.getItem("memberId");
  useEffect(() => {
    ApiNotifications()
      .getNotificationsSummary(responsibilityId, loggedInUnitId)
      .then((res) => {
        setNotificationsDropDownData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [responsibilityId, loggedInUnitId, memberId]);

  useEffect(() => {
    let total = 0;
    notificationsDropDownData.map((element, index) => {
      return (total += element.totalNotifications);
    });
    setTotalNotifications(total);
  }, [notificationsDropDownData]);

  return (
    <li className="m-r">
      <Dropdown isOpen={notificationsDropDownOpen} toggle={notificationsToggle}>
        <DropdownToggle className="count-info">
          <IoIosNotifications
            size={20}
            style={{ marginRight: 3, marginBottom: 1 }}
          />
          {totalNotifications > 0 && (
            <>
              &ensp;&ensp;
              <div className="label label-primary m-1">
                {totalNotifications}
              </div>
            </>
          )}
        </DropdownToggle>
        <DropdownMenu className="dropdown-alerts" style={{ width: 400 }}>
          {notificationsDropDownData.map((element, index) => (
            <>
              <div className="dropdown-item" key={index}>
                <Row>
                  <Col md="9">
                    <DropdownItem
                      key={index}
                      onClick={() => {
                        history.push({
                          pathname: `/notifications/`,
                        });
                      }}
                    >
                      {element.notificationTypeName}
                    </DropdownItem>
                  </Col>
                  <Col md="3">
                    <div className="text-center label label-primary m-1">
                      {element.totalNotifications}
                    </div>
                  </Col>
                </Row>
              </div>
              <li className="dropdown-divider"></li>
            </>
          ))}
          <div
            className="dropdown-item text-center"
            style={{ cursor: "pointer" }}
            onClick={() => {
              history.push({
                pathname: `/notifications/`,
              });
            }}
          >
            <strong>
              {" "}
              <GetLanguageString props={"see_all_alerts"} />{" "}
            </strong>
            <i
              className={
                GetSelectedLanguage() === "en"
                  ? "fa fa-angle-right"
                  : "fa fa-angle-left"
              }
            ></i>
          </div>
        </DropdownMenu>
      </Dropdown>
    </li>
  );
};
export default withRouter(UserNotifications);
