import React, { useEffect, useState } from "react";
import { Button, ButtonGroup } from "reactstrap";
import moment from "moment";

import { GetCustomStyles } from "../helper/Method";
import { GetLanguageString, NoResult } from "../helper/Components";
import { ApiCourse } from "../courses/ApiCourse";
import { ApiUnit } from "../unit/ApiUnit";
import { LoadingSpinner, convertUTCToLocalTime } from "../../constants/const";

const UpcomingCourses = ({tab4}) => {
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const [oneMonthCourse, setOneMonthCourse] = useState("primary");
  const [sixMonthCourse, setSixMonthCourse] = useState("white");
  const [yearCourse, setYearCourse] = useState("white");
  let currentDate = new Date();
  let lastDay = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    currentDate.getDate(),
    5
  );
  const [searchCourseModel, setSearchCourseModel] = useState({
    unit: { value: 0, lable: "" },
    startDate: currentDate,
    endDate: lastDay,
    isSubUnits: true,
  });

  useEffect(() => {
    if(tab4 === "active"){
      if (searchCourseModel.unit.value === 0) {
        setLoading(true);
        ApiUnit()
          .getUnit(0, 0)
          .then((res) => {
            let temp = res.data.unitList[0];
            let temp2 = { value: temp.id, lable: temp.name };
            setSearchCourseModel({
              ...searchCourseModel,
              unit: temp2,
            });
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      } else {
        setLoading(true);
        ApiCourse()
          .get(searchCourseModel)
          .then((res) => {
            setLoading(false);
            setCourses(res.data.courseModel);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      }
    }
  }, [searchCourseModel, tab4]);

  const handleCourseDate = (e) => {
    const { value } = e.target;
    let date = new Date();
    if (value === "0") {
      setOneMonthCourse("primary");
      setSixMonthCourse("white");
      setYearCourse("white");
      setSearchCourseModel({
        ...searchCourseModel,
        startDate: currentDate,
        endDate: lastDay,
      });
    } else if (value === "1") {
      setOneMonthCourse("white");
      setYearCourse("white");
      setSixMonthCourse("primary");
      lastDay = new Date(date.getFullYear(), date.getMonth() + 6, date.getDate(), 5);
      setSearchCourseModel({
        ...searchCourseModel,
        startDate: currentDate,
        endDate: lastDay,
      });
    } else if (value === "2") {
      setYearCourse("primary");
      setOneMonthCourse("white");
      setSixMonthCourse("white");
      lastDay = new Date(date.getFullYear(), date.getMonth() + 12, date.getDate(), 5);
      setSearchCourseModel({
        ...searchCourseModel,
        startDate: currentDate,
        endDate: lastDay,
      });
    }
  };

  return (
        <div className="ibox">
          <div className="ibox-title">
            <h5>{<GetLanguageString props="dashboard_upcomming_courses" />}</h5>
            <div className={GetCustomStyles().btn_style}>
              <ButtonGroup>
                <Button
                  size="xs"
                  color={oneMonthCourse}
                  value={0}
                  onClick={handleCourseDate}
                >
                  {<GetLanguageString props="dashboard_btn_1month" />}
                </Button>
                <Button
                  size="xs"
                  color={sixMonthCourse}
                  value={1}
                  onClick={handleCourseDate}
                >
                  {<GetLanguageString props="dashboard_btn_6month" />}
                </Button>
                <Button
                  size="xs"
                  color={yearCourse}
                  value={2}
                  onClick={handleCourseDate}
                >
                  {<GetLanguageString props="dashboard_btn_year" />}
                </Button>
              </ButtonGroup>
            </div>
          </div>
          <div className="ibox-content">
            {!loading ? (
              <div className="feed-activity-list">
                {courses.length !== 0 ? (
                  courses.map((record, index) => {
                    return (
                      <div className="feed-element" key={index}>
                        <div>
                          <small
                            className={GetCustomStyles().btn_style + " text-navy"}
                          >
                            {moment(convertUTCToLocalTime(record.startDate)).format("DD/MM/yyyy")} -{" "}
                            {moment(convertUTCToLocalTime(record.endDate)).format("DD/MM/yyyy")}
                          </small>
                          <strong className="ur">
                            {record.courseType.label} ({record.unit.label})
                          </strong>
                          <div className="ur">{record.address}</div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <NoResult />
                )}
              </div>
            ) : (
              LoadingSpinner()
            )}
          </div>
        </div>
  );
};

export default UpcomingCourses;



