import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import Draggable from "react-draggable";
import ReactCrop from 'react-image-crop';

import 'react-image-crop/dist/ReactCrop.css';

import { GetLanguageString } from "./Components";

const ImageCropper = (props) => {
    const {
      imageToCrop,
      handleSubmit,
      setImageCropModal,
      setCroppedImage,
      onImageCropped,
    } = props;
    
    const [imageRef, setImageRef] = useState();
    const [cropConfig, setCropConfig] = useState({
      unit: 'px',
      x: 25,
      y: 25,
      scaleX: 0,
      scaleY: 0,
      width: 270,
      height: 300,
      scaleWidth: 0,
      scaleHeight: 0,
      aspect: 0.9,
    });
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const handleDrag = (e, ui) => {
      const { x, y } = position;
      setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
    };

    const toggle = () => {
      setImageCropModal(false);
      setCroppedImage(null);
    };

    async function cropImage(crop) {
        if (imageRef && crop.width && crop.height) {
            const croppedImage = await getCroppedImage(
                imageRef,
                crop,
                'croppedImage.jpeg'
            );

            onImageCropped(croppedImage);
        }
    }

    function getCroppedImage(sourceImage, cropConfig, fileName) {
      // CREATING CROP IMG FROM SOURCE IMG
      const canvas = document.createElement('canvas');
      const scaleX = sourceImage.naturalWidth / sourceImage.width;
      const scaleY = sourceImage.naturalHeight / sourceImage.height;

      canvas.width = cropConfig.width;
      canvas.height = cropConfig.height;
      const ctx = canvas.getContext('2d');

      const configData = {
        x: cropConfig.x,
        y: cropConfig.y,
        scaleX: cropConfig.x * scaleX,
        scaleY: cropConfig.y * scaleY,
        width: cropConfig.width,
        height: cropConfig.height,
        scaleWidth: cropConfig.width * scaleX,
        scaleHeight: cropConfig.height * scaleY,
      };

      ctx.drawImage(
        sourceImage,
        configData.scaleX,
        configData.scaleY,
        configData.scaleWidth,
        configData.scaleHeight,
        0,
        0,
        configData.width,
        configData.height
      );
      
      return new Promise((resolve, reject) => {
        canvas.toBlob(
          (blob) => {
            if (!blob) {
              reject(new Error('Canvas is empty'));
              return;
            }

            blob.name = fileName;
            // CREATING OBJECT URL FROM GIVEN BLOB OBJECT
            const croppedImageUrl = window.URL.createObjectURL(blob);

            resolve(croppedImageUrl);
          }, 
          'image/jpeg',
          1
        );
      });
    }

    return (
        <>      
        <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal
          isOpen={true}
          size="xl"
          className="inmodal"
        >
        <ModalHeader className="modal-title" tag="h4">
          {<GetLanguageString props="crop_image" />}
        </ModalHeader>
        <ModalBody>
        <div className="text-center">
          <ReactCrop
              src={imageToCrop}
              crop={cropConfig}
              ruleOfThirds
              onImageLoaded={(imageRef) => setImageRef(imageRef)}
              onComplete={(cropConfig) => cropImage(cropConfig)}
              onChange={(cropConfig) => setCropConfig(cropConfig)}
              crossorigin="anonymous" // TO AVOID CORS-RELATED ISSUES
          />
        </div>
        </ModalBody>
          <ModalFooter>
            <Button color="white" onClick={toggle}>
              {<GetLanguageString props="common_cancel" />}
            </Button>
            <Button
              color="primary"
              type="submit"
              onClick={handleSubmit}
            >
              {<GetLanguageString props="common_save_changes" />}
            </Button>
          </ModalFooter>
        </Modal>
        </Draggable>
        </>
    );
}

ImageCropper.defaultProps = {
    onImageCropped: () => {}
}

export default ImageCropper;