import axios from "axios";

import { BASEURL } from "../../../constants/const";
import { encryptStorage } from "../../../constants/EncryptStorage";

export function ApiContactType() {
  const userToken = encryptStorage.getItem("token");

  const authAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const url = BASEURL + "setup/";
  return {
    getContactType: () => authAxios.get(url + "GetContactTypesList/"),
    addContactType: (newRecord) =>
      authAxios.post(url + "AddContactType/", newRecord),
    deleteContactType: (id) =>
      authAxios.delete(url + "DeleteContactType/" + id),
    getEditContactType: (id) => authAxios.get(url + "EditContactType/" + id),
    updateContactType: (updateRecord) =>
      authAxios.post(url + "EditContactType/", updateRecord),
  };
}
