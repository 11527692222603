import moment from 'moment';
import 'moment/locale/ur';
import React from 'react';
import { convertUTCToLocalTime, isUrdu } from '../../constants/const';
import {
  titleStyle,
  personalInfo,
  personalInfo2,
  section,
  spanNo,
  spanHeading,
  spanHeading2,
  spanHeading3,
  spanHeading4,
  spanData,
  spanData2,
  spanData3,
  spanData4,
  spanData5,
  spanData6,
  spanData7,
  spanData8,
  section2No,
  section2Heading,
  courseDate,
  courseLocation
} from '../../content/css/customCss';

const PrintNotification = ({ printData, printType }) => {
    moment.locale('ur');

    const modifyUnitName = (inputString) => {     
      const colonIndex = inputString.indexOf(':');
      // If ":" is found, remove data on the left side of it
      if (colonIndex !== -1) {
        const modifiedString = inputString.substring(colonIndex+1);
         return modifiedString.trim();
      } else {
        return inputString
      }
    }

    const splitNumbers = (phoneNo) => {
      var numbersArray = phoneNo.split(',');
      numbersArray = numbersArray.map(function (number) {
        return number.trim();
      });
    
      // Limit the array to at most two elements
      numbersArray = numbersArray.slice(0, 2);
    
      // Join the numbers with a forward slash
      const resultString = numbersArray.join(" | ");
    
      return resultString;
    };

    const checkContactNos = (inputString) => {
      const cleanedString = inputString.replace(/[^0-9]/g, '');
    
      if (cleanedString.startsWith('03')) 
        return cleanedString.substring(0, 11);
      else
        return cleanedString;
    }

    return(
      <div id='section-to-print' style={{
        width: "100%",
        padding: '0.15in 0.4in',
        background: '#fff',
        color: '#000',
        fontSize:'17px',
      }}
      className='print' 
      >
      <div 
        style={{ direction : 'rtl' }} 
      >
          <div
            className='title ur'
            style={{
              paddingTop: printType !== 1 && '48px',
            }}
          >
            <p style={titleStyle}>
              {printType === 1 ? (
                <u>
                  رفیق کی ایک حلقہ سے دوسرے حلقہ میں منتقلی
                </u>
              ) : (
                <u>
                  حبیب کی نظم میں منتقلی
                </u>
              )}
            </p>
          </div>
            <div className='ur'>
              <p>
              السلام علیکم و رحمتہ اللہ وبرکاتہ۔ مزاج گرامی!
              </p>
              {printType === 1 ? (
                <p>            
                  <span
                    style={{
                      display: 'inline-block',
                      textAlign: 'center',
                      marginLeft: '5px',
                    }}
                  >
                    حلقہ
                  </span>
                  <span
                    style={{
                      display: 'inline-block',
                      textAlign: 'center',
                      width: '120px',
                      marginLeft: '5px',
                      borderBottom: '2px dotted black',
                    }}
                  >
                    {printData.unitName !== null && printData.unitName !== "" ? modifyUnitName(printData.unitName) : "-"}                
                  </span>
                  <span>کے رفیق آپ کے حلقہ میں منتقل ہوۓ ہیں۔ موصوف کے متعلقہ کوائف درج ذیل ہیں:-</span>
                </p>
              ) : (
                <p>
                   درجہ ذیل حبیب آپ کے نظم میں منتقل کۓ جا رہے ہیں۔ موصوف کے متعلقہ کوائف درج ذیل ہیں:-
                </p>
              )}
            </div>
                                          {/* Personal Info */}
          <p className='ur'>(i) ذاتی کوائف: </p>
          
          {printType === 1 ? (
            <>
              <div style={personalInfo}>
                <div style={section}>
                  <p>
                    <span style={spanNo}>1.</span>
                    <span className='ur' style={spanHeading}>نام:</span>
                    <span 
                      className = { isUrdu(printData.fullName) ? 'ur' : 'en' }
                      style={spanData}
                    >
                      {printData.fullName !== null && printData.fullName !== "" ? printData.fullName : "-"}
                    </span>
                  </p>
                </div>
                <div style={section}>
                  <p>
                    <span style={spanNo}>2.</span>
                    <span className='ur' style={spanHeading3}>ولدیت:</span>
                    <span 
                      className = { isUrdu(printData.fatherName) ? 'ur' : 'en' }
                      style={spanData2}
                    >
                      {printData.fatherName !== null && printData.fatherName !== "" ? printData.fatherName : "-"}
                    </span>
                  </p>
                </div>
                <div style={section}>
                  <p>
                    <span style={spanNo}>3.</span>
                    <span className='ur' style={spanHeading2}>رجسٹریشن نمبر:</span>
                    <span 
                      className='ur'
                      style={spanData2}
                    >
                      {printData.registranNumber !== null && printData.registranNumber !== "" ? printData.registranNumber : "-"}
                    </span>
                  </p>
                </div>
              </div>
              <div style={personalInfo2}>
                <div style={section}>
                  <p>
                    <span style={spanNo}>4.</span>
                    <span className='ur' style={spanHeading2}>
                    موبائل نمبر:
                    </span>
                    <span
                      className='ur'
                      style={spanData4}
                    >
                      {printData.contactNo !== null && printData.contactNo !== "" ? splitNumbers(printData.contactNo) : "-"}
                    </span>
                  </p>
                  <p>
                    <span style={spanNo}>6.</span>
                    <span className='ur' style={spanHeading4}>پیشہ:</span>
                    <span 
                      className = { isUrdu(printData.occupation) ? 'ur' : 'en' }
                      style={{
                      display: 'inline-block',
                      width: '258px',
                      textAlign: 'center',
                      borderBottom: '2px dotted black',
                      }}
                    >
                      {printData.occupation !== null && printData.occupation  !== "" ? printData.occupation : "-"}
                    </span>
                  </p>
                  <p>
                    <span style={spanNo}>8.</span>
                    <span className='ur' style={spanHeading2}>تاریخ پیدائش:</span>
                    <span className='ur' style={spanData4}>
                      {printData.dob !== null && printData.dob !== "" ? moment(convertUTCToLocalTime(printData.dob)).format('LL') : "-"}
                    </span>
                  </p>
                </div>
                <div style={section}>
                  <p>
                    <span style={spanNo}>5.</span>
                    <span className='ur' style={spanHeading4}>
                    ای میل:
                    </span>
                    <span
                      className = { isUrdu(printData.email) ? 'ur' : 'en' }
                      style={spanData5}
                    >
                      {printData.email !== null && printData.email !== "" ? printData.email : "-"}
                    </span>
                  </p>
                  <p>
                    <span style={spanNo}>7.</span>
                    <span className='ur' style={spanHeading4}>تعلیم:</span>
                    <span
                      className = { isUrdu(printData.education) ? 'ur' : 'en' }
                      style={spanData5}
                    >
                      {printData.education !== null && printData.education !== "" ? printData.education : "-"}
                    </span>
                  </p>
                  <p>
                    <span style={spanNo}>9.</span>
                    <span className='ur' style={spanHeading2}
                    >ازدواجی حیثیت:
                    </span>
                    <span 
                      className = { isUrdu(printData.maritalStatus) ? 'ur' : 'en' }
                      style={spanData3}
                    >
                      {printData.maritalStatus !== null && printData.maritalStatus !== "" ? printData.maritalStatus : "-"}
                    </span>
                  </p>
                </div>
              </div>
              <p>
                <span style={{
                  paddingRight: '12px',
                  paddingLeft: '2px',
                }}
                >
                  10.
                </span>
                <span 
                  className='ur' 
                  style={{
                    display: 'inline-block',
                  }}
                >
                  موصوف جہاں سے منتقل ہوے ہیں، وہاں کا ایڈریس:
                </span>
                <span
                  className = { isUrdu(printData.newAddress) ? 'ur' : 'en' }
                  style={{
                    display: 'inline-block',
                    width: '430px',
                    marginRight: '12px',
                    textAlign: 'center',
                    borderBottom: '2px dotted black',
                  }}
                >
                  {printData.newAddress !== null && printData.newAddress !== "" ? printData.newAddress : "-"}
                </span>
              </p>
                                              {/* Tanzeem Info */}
              <p className='ur' >(ii) تنظیمی کوائف:</p>
              <p>
                <span style={section2No}>1.</span>
                <span 
                  className='ur' 
                  style={{
                    display: 'inline-block',
                  }}
                >
                  موصوف کے پاس کوئی ذمہ داری تھی؟
                </span>
                <span
                  className = { isUrdu(printData.responsibilityName) ? 'ur' : 'en' }
                  style={{
                    display: 'inline-block',
                    width: '500px',
                    marginRight: '8px',
                    textAlign: 'center',
                    borderBottom: '2px dotted black',
                  }}
                >
                  {printData.responsibilityName !== null && printData.responsibilityName !== "" ? printData.responsibilityName : "-"}
                </span>
              </p>
              <div className='couseDetails'>
                <p>
                  <span style={section2No}>2.</span>
                  <span className='ur' >مبتی تربیتی کورس میں کب اور کہاں شرکت کی؟</span>
                  <span 
                    className='ur' 
                    style={{  
                      paddingRight: '20px',
                      paddingLeft: '6px',
                    }}
                  >
                    تاریخ:
                  </span>
                  <span 
                    className='ur'
                    style={courseDate}
                  >
                    {printData.mubtadiCourse !== null && printData.mubtadiCourse.startDate !== null && printData.mubtadiCourse.startDate !== "" ? moment(convertUTCToLocalTime(printData.mubtadiCourse.startDate)).format('LL') : "-"}
                  </span>
                  <span className='ur' style={section2Heading}>مقام:</span>
                  <span
                    className = { 
                      (printData.mubtadiCourse !== null && printData.mubtadiCourse !== "") &&
                      isUrdu(printData.mubtadiCourse.location) ? 'ur' : 'en' 
                    }
                    style={courseLocation}
                  >
                    {printData.mubtadiCourse !== null && printData.mubtadiCourse.location !== null && printData.mubtadiCourse.location !== "" ? printData.mubtadiCourse.location : "-"}
                  </span>
                </p>
              </div>
              <div className='couseDetails'>
                <p>
                  <span style={section2No}>3.</span>
                  <span className='ur' >ملتزم تربیتی کورس میں کب اور کہاں شرکت کی؟</span>
                  <span className='ur' style={section2Heading}>تاریخ:</span>
                  <span className='ur' style={courseDate}>
                    {printData.multazimCourse !== null && printData.multazimCourse.startDate !== null && printData.multazimCourse.startDate !== "" ? moment(convertUTCToLocalTime(printData.multazimCourse.startDate)).format('LL') : "-"}
                  </span>
                  <span className='ur' style={section2Heading}>مقام:</span>
                  <span
                    className = { 
                      (printData.multazimCourse !== null && printData.multazimCourse !== "") &&
                      isUrdu(printData.multazimCourse.location) ? 'ur' : 'en' 
                    }
                    style={courseLocation}
                  >
                    {printData.multazimCourse !== null && printData.multazimCourse.location !== null && printData.multazimCourse.location !== "" ? printData.multazimCourse.location : "-"}
                  </span>
                </p>
              </div>
              <p>
                <span style={section2No}>4.</span>
                <span className='ur' >نصاب براۓ مطالعہ / سماعت کی موجودہ کیفیت(مبتدی/ملتزم/ذمہ داران/مدرسین)</span>
                <span
                  className = { isUrdu(printData.nisabBaraEMutaliya) ? 'ur' : 'en' }
                  style={{
                    display: 'inline-block',
                    width: '300px',
                    marginRight: '12px',
                    textAlign: 'center',
                    borderBottom: '2px dotted black',
                  }}
                >
                  {printData.nisabBaraEMutaliya !== null && printData.nisabBaraEMutaliya !== "" ? printData.nisabBaraEMutaliya : "-"}
                </span>
              </p>
                                              {/* Tanzeem Status */}
              <p className='ur' >(iii) تنظیمی کیفیت:</p>
              <p>
                <span style={section2No}>1.</span>
                <span className='ur' >تعلق مع اللہ (عبادات،اخلاقیات،معاملات)</span>
                <span
                  className = { isUrdu(printData.taluqMaAllah) ? 'ur' : 'en' }
                  style={{
                    display: 'inline-block',
                    width: '468px',
                    marginRight: '8px',
                    textAlign: 'center',
                    borderBottom: '2px dotted black',
                  }}
                >{printData.taluqMaAllah !== null && printData.taluqMaAllah !== "" ? printData.taluqMaAllah : "-"}</span>
              </p>
              <p>
                <span style={section2No}>2.</span>
                <span className='ur' >سمع و اطاعت(تنظیمی اجتماعات میں حاضری کی کیفیت)</span>
                <span
                  className = { isUrdu(printData.samaOTaat) ? 'ur' : 'en' }
                  style={{
                    display: 'inline-block',
                    width: '424px',
                    marginRight: '8px',
                    textAlign: 'center',
                    borderBottom: '2px dotted black',
                  }}
                >{printData.samaOTaat !== null && printData.samaOTaat !== "" ? printData.samaOTaat : "-"}</span>
              </p>
              <p>
                <span style={section2No}>3.</span>
                <span className='ur' >سمع و بصر(جرائد و دیگر کتب کا مطالعہ اور امیر محترم کے خطبات جمعہ کا مطالعہ یا سماعت)</span>
                <span
                  className = { isUrdu(printData.samaOBasar) ? 'ur' : 'en' }
                  style={{
                    display: 'inline-block',
                    width: '288px',
                    marginRight: '8px',
                    textAlign: 'center',
                    borderBottom: '2px dotted black',
                  }}
                >{printData.samaOBasar !== null && printData.samaOBasar !== "" ? printData.samaOBasar : "-"}</span>
              </p>
              <p>
                <span style={section2No}>4.</span>
                <span className='ur' >انفرادی دعوت / درس میں شرکت / تدریس کا اہتمام</span>
                <span
                  className = { isUrdu(printData.infradiDawat) ? 'ur' : 'en' }
                  style={{
                    display: 'inline-block',
                    width: '432px',
                    marginRight: '8px',
                    textAlign: 'center',
                    borderBottom: '2px dotted black',
                  }}
                >{printData.infradiDawat !== null && printData.infradiDawat !== "" ? printData.infradiDawat : "-"}</span>
              </p>
              <p>
                <span style={section2No}>5.</span>
                <span className='ur' >جہاد بالمال خصوصاً انفاق فی سبیل اللہ کی کیفیت</span>
                <span
                  className = { isUrdu(printData.jihadBilMaal) ? 'ur' : 'en' }
                  style={{
                    display: 'inline-block',
                    width: '468px',
                    marginRight: '8px',
                    textAlign: 'center',
                    borderBottom: '2px dotted black',
                  }}
                >{printData.jihadBilMaal !== null && printData.jihadBilMaal !== "" ? printData.jihadBilMaal : "-"}</span>
              </p>
              <p>
                <span style={section2No}>6.</span>
                <span className='ur' >دیگر تفصیلات</span>
                <span
                  className = { isUrdu(printData.otherInfo) ? 'ur' : 'en' }
                  style={{
                    display: 'inline-block',
                    width: '595px',
                    marginRight: '8px',
                    textAlign: 'center',
                    borderBottom: '2px dotted black',
                  }}
                >{printData.otherInfo !== null && printData.otherInfo !== "" ? printData.otherInfo : "-"}</span>
              </p>   
            </>
          ) : (
            <>
            <div style={personalInfo}>
              <div style={section}>
                <p>
                  <span style={spanNo}>1.</span>
                  <span style={spanHeading}  className='ur'>نام:</span>
                  <span 
                    className = { isUrdu(printData.fullName) ? 'ur' : 'en' }
                    style={spanData3}
                  >
                    {printData.fullName !== null && printData.fullName !== "" ? printData.fullName : "-"}
                  </span>
                </p>
                <p>
                  <span style={spanNo}>3.</span>
                  <span className='ur' style={spanHeading}>تعلیم:</span>
                  <span 
                    className = { isUrdu(printData.education) ? 'ur' : 'en' }
                    style={spanData3}
                  >
                    {printData.education !== null && printData.education !== "" ? printData.education : "-"}
                  </span>
                </p>
                <p>
                  <span style={spanNo}>5.</span>
                  <span className='ur' style={spanHeading2}>
                    موبائل نمبر:
                  </span>
                  <span className='ur' style={spanData6}>
                    {printData.contactNo !== null && printData.contactNo !== "" ? checkContactNos(printData.contactNo) : "-"}
                  </span>
                </p>
                <p>
                  <span style={spanNo}>7.</span>
                  <span className='ur' style={spanHeading2}>
                    سابقہ نظم کا نام:
                  </span>
                  <span 
                    className = { isUrdu(printData.preUnitName) ? 'ur' : 'en' }
                    style={spanData6}
                  >
                    {printData.preUnitName !== null && printData.preUnitName !== "" ? printData.preUnitName : "-"}
                  </span>
                </p>
              </div>
              <div style={section}>
                <p className='ur'>
                  <span style={spanNo}>2.</span>
                  <span style={spanHeading2}>تاریخ پیدائش:</span>
                  <span 
                    style={spanData8}
                  >
                    {printData.dob !== null && printData.dob !== "" ? moment(convertUTCToLocalTime(printData.dob)).format('LL') : "-"}
                  </span>
                </p>
                <p>
                  <span style={spanNo}>4.</span>
                  <span className='ur' style={spanHeading}>پیشہ:</span>
                  <span 
                    className = { isUrdu(printData.occupation) ? 'ur' : 'en' }
                    style={spanData7}
                  >
                    {printData.occupation !== null && printData.occupation !== "" ? printData.occupation : "-"}
                  </span>
                </p>
                <p className='ur'>
                  <span style={spanNo}>6.</span>
                  <span style={spanHeading3}>
                    ای میل:
                  </span>
                  <span style={spanData5}>
                    {printData.email !== null && printData.email !== "" ? printData.email : "-"}
                  </span>
                </p>
                <p>
                  <span style={spanNo}>8.</span>
                  <span className='ur' style={spanHeading2}>
                    نۓ نظم کا نام :
                  </span>
                  <span
                    className = { isUrdu(printData.newUnitName) ? 'ur' : 'en' }
                    style={spanData8}
                  >
                    {printData.newUnitName !== null && printData.newUnitName !== "" ? printData.newUnitName : "-"}
                  </span>
                </p>
              </div>
            </div>
              <p>
                <span style={{
                  paddingRight: '12px',
                  paddingLeft: '2px',
                }}
                >
                  10.
                </span>
                <span 
                  className='ur'
                  style={{
                    display: 'inline-block',
                  }}
                >
                  موصوف جہاں سے منتقل ہوے ہیں، وہاں کا ایڈریس:
                </span>
                <span
                  className = { isUrdu(printData.newAddress) ? 'ur' : 'en' }
                  style={{
                    display: 'inline-block',
                    width: '430px',
                    marginRight: '12px',
                    textAlign: 'center',
                    borderBottom: '2px dotted black',
                  }}
                >
                  {printData.newAddress !== null && printData.newAddress !== "" ? printData.newAddress : "-"}
                </span>
              </p>
            </>
          )}

          <div className='ur'>
            <p
              style={{
                paddingRight: '52px',
              }}
            >
              اللہ تعالیٰ سے دعا ہے کہ وہ اقامت دین کی جدوجہد میں ہمارا حامی و ناصر ہو۔ آمین۔
            </p>
          </div>
          <div
            style={{
              marginRight: '468px',
            }}
          >
            <p
              className='ur'
              style={{
                paddingRight: '48px',
                marginTop: printType === 1 ? '-24px' : '18px'
              }}
            >
              والسلام مع الاکرام
            </p>
            {printType === 1 && (
              <>
                <p
                  style={{
                    paddingTop: '10px'
                  }}
                >
                  <span className='ur'>
                    دستخط:
                  </span>
                  <span
                    style={{
                      display: 'inline-block',
                      width: '152px',
                      textAlign: 'center',
                      borderBottom: '2px dotted black',
                    }}
                  >
                  </span>
                </p>
                <p
                  className='ur'
                  style={{
                    marginTop: '-12px'
                  }}
                >
                  <span className='ur'>
                    نام امیر حلقہ:
                  </span>            
                  <span
                    className = { isUrdu(printData.ameerName) ? 'ur' : 'en' }
                    style={{
                      display: 'inline-block',
                      width: '122px',
                      textAlign: 'center',      
                      borderBottom: '2px dotted black',
                    }}
                  >
                    {printData.ameerName !== null && printData.ameerName !== "" ? printData.ameerName : "-"}
                  </span>
                </p>
              </>
            )}
          </div>
      </div>
      </div>
    )
}

export default PrintNotification;