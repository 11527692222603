import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, FormGroup, Input, Label, Row, Table } from "reactstrap";

const Events = ({eventsSection, setEventsSection }) => {
  const [halqaQurani, setHalqaQurani] = useState(eventsSection.halqaQurani);
  const [mahanaDawatiIjtima, setMahanaDawatiIjtima] = useState(
    eventsSection.mahanaDawatiIjtima
  );
  const [fehmDeen, setFehmDeen] = useState(eventsSection.fehmDeen);
  const [agahiMunkiraat, setAgahiMunkiraat] = useState(
    eventsSection.agahiMunkiraat
  );
  const [mahanaTarbiyatiIjtima, setMahanaTarbiyatiIjtima] = useState(
    eventsSection.mahanaTarbiyatiIjtima
  );
  
  useEffect(() => {
    setEventsSection({
      ...eventsSection,
      halqaQurani: halqaQurani,
      mahanaDawatiIjtima: mahanaDawatiIjtima,
      fehmDeen: fehmDeen,
      agahiMunkiraat: agahiMunkiraat,
      mahanaTarbiyatiIjtima: mahanaTarbiyatiIjtima
    })
  }, [setEventsSection, eventsSection, halqaQurani, mahanaDawatiIjtima, fehmDeen, agahiMunkiraat, mahanaTarbiyatiIjtima])
  

  const handleHalqaQurani = (e) => {
    const { name, value } = e.target;
    setHalqaQurani({
      ...halqaQurani,
      [name]: value,
    });
  };

  const handleMahanaDawatiIjtima = (e) => {
    const { name, value } = e.target;
    setMahanaDawatiIjtima({
      ...mahanaDawatiIjtima,
      [name]: value,
    });
  };

  const handleFehmDeen = (e) => {
    const { name, value } = e.target;
    setFehmDeen({
      ...fehmDeen,
      [name]: value,
    });
  };

  const handleAagahiMunkirat = (e) => {
    const { name, value } = e.target;
    setAgahiMunkiraat({
      ...agahiMunkiraat,
      [name]: value,
    });
  };

  const handleMahanaTarbiyatiIjtima = (e) => {
    const { name, value } = e.target;
    setMahanaTarbiyatiIjtima({
      ...mahanaTarbiyatiIjtima,
      [name]: value,
    });
  };

  return (
    <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
      <div className="ibox panel panel-primary ur">
        <div className="panel-body">
          <Row>
            <Col md="6">
              <div className="ibox panel panel-info">
                <div className="panel-heading">
                  <h3 className="text-center font-bold">حلقہ قرانی</h3>
                </div>
                <div className="panel-body">
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label className="col-form-label">
                          <h4>{"کتنے حلقہ قائم ہیں؟"}</h4>
                        </Label>
                        <Input
                          className="text-center"
                          type="text"
                          name="howMuchEstablished"
                          value={halqaQurani.howMuchEstablished}
                          onChange={handleHalqaQurani}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label className="col-form-label">
                          <h4>{"تسلسل"}</h4>
                        </Label>
                        <Input
                          className="text-center"
                          type="text"
                          name="continuity"
                          value={halqaQurani.continuity}
                          onChange={handleHalqaQurani}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="ibox-title">
                  {" "}
                  <h3 className="text-center font-bold">اوسط حاضری</h3>
                </div>
                <div className="ibox-content">
                  <Row>
                    <Col md="4">
                      <h4>{"ملتزم "}</h4>
                      <Input
                        className="text-center"
                        type="text"
                        name="averageMultazim"
                        placeholder="%"
                        value={halqaQurani.averageMultazim}
                        onChange={handleHalqaQurani}
                      />
                    </Col>
                    <Col md="4">
                      <h4>{"مبتدی "}</h4>
                      <Input
                        className="text-center"
                        type="text"
                        name="averageMubtadi"
                        placeholder="%"
                        value={halqaQurani.averageMubtadi}
                        onChange={handleHalqaQurani}
                      />
                    </Col>
                    <Col md="4">
                      <h4>{"احباب"}</h4>
                      <Input
                        className="text-center"
                        type="text"
                        name="averageAhbab"
                        placeholder="%"
                        value={halqaQurani.averageAhbab}
                        onChange={handleHalqaQurani}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col md="6">
              <div className="ibox panel panel-info">
                <div className="panel-heading">
                  <h2 className="text-center font-bold">اجتماعات</h2>
                  <h6 className="text-center font-bold">
                    ان اجتماعات میں آپ کے اسرہ سے کتنے
                  </h6>
                </div>
                <div className="panel-body">
                  <Table responsive> size="sm">
                    <thead>
                      <tr>
                        <th></th>
                        <th className="text-center">
                          <h4>{"ملتزم "}</h4>
                        </th>
                        <th className="text-center">
                          <h4>{"مبتدی "}</h4>
                        </th>
                        <th className="text-center">
                          <h4>{"احباب "}</h4>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="col-3">
                          <h4 className="text-center">{"ماہانہ دعوتی اجتماع "}</h4>
                        </td>
                        <td>
                          <Input
                            className="text-center"
                            type="text"
                            name="multazimCount"
                            value={mahanaDawatiIjtima.multazimCount}
                            onChange={handleMahanaDawatiIjtima}
                          />
                        </td>
                        <td>
                          <Input
                            className="text-center"
                            type="text"
                            name="mubtadiCount"
                            value={mahanaDawatiIjtima.mubtadiCount}
                            onChange={handleMahanaDawatiIjtima}
                          />
                        </td>
                        <td>
                          <Input
                            className="text-center"
                            type="text"
                            name="ahbabCount"
                            value={mahanaDawatiIjtima.ahbabCount}
                            onChange={handleMahanaDawatiIjtima}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <h4>{"فہم دین"}</h4>
                        </td>
                        <td>
                          <Input
                            className="text-center"
                            type="text"
                            name="multazimCount"
                            value={fehmDeen.multazimCount}
                            onChange={handleFehmDeen}
                          />
                        </td>
                        <td>
                          <Input
                            className="text-center"
                            type="text"
                            name="mubtadiCount"
                            value={fehmDeen.mubtadiCount}
                            onChange={handleFehmDeen}
                          />
                        </td>
                        <td>
                          <Input
                            className="text-center"
                            type="text"
                            name="ahbabCount"
                            value={fehmDeen.ahbabCount}
                            onChange={handleFehmDeen}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <h4>{"آگاہی منکرات مہم"}</h4>
                        </td>
                        <td>
                          <Input
                            className="text-center"
                            type="text"
                            name="multazimCount"
                            value={agahiMunkiraat.multazimCount}
                            onChange={handleAagahiMunkirat}
                          />
                        </td>
                        <td>
                          <Input
                            className="text-center"
                            type="text"
                            name="mubtadiCount"
                            value={agahiMunkiraat.mubtadiCount}
                            onChange={handleAagahiMunkirat}
                          />
                        </td>
                        <td>
                          <Input
                            className="text-center"
                            type="text"
                            name="ahbabCount"
                            value={agahiMunkiraat.ahbabCount}
                            onChange={handleAagahiMunkirat}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <h4>{"ماہانہ تربیتی اجتماع"}</h4>
                        </td>
                        <td>
                          <Input
                            className="text-center"
                            type="text"
                            name="multazimCount"
                            value={mahanaTarbiyatiIjtima.multazimCount}
                            onChange={handleMahanaTarbiyatiIjtima}
                          />
                        </td>
                        <td>
                          <Input
                            className="text-center"
                            type="text"
                            name="mubtadiCount"
                            value={mahanaTarbiyatiIjtima.mubtadiCount}
                            onChange={handleMahanaTarbiyatiIjtima}
                          />
                        </td>
                        <td>
                          <Input
                            className="text-center"
                            type="text"
                            name="ahbabCount"
                            value={mahanaTarbiyatiIjtima.ahbabCount}
                            onChange={handleMahanaTarbiyatiIjtima}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Events;
