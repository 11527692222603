import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";

import { encryptStorage } from "../../constants/EncryptStorage";
import { LoadingSpinner } from "../../constants/const";
import { GetSelectedLanguage } from "../helper/Method";
import { GetLanguageString } from "../helper/Components";
import { ApiUnit } from "../unit/ApiUnit";
import { GoArrowLeft, GoArrowRight } from "react-icons/go";

const SelectUnitForPermissions = ({
  setValues,
  values,
  unitId,
  isDisabled = false,
  index,
  setSelectedUnit,
  responsibilitySelected,
}) => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [unitList, setUnitList] = useState([]);
  const unitType = "null";
  if (unitId === 0) unitId = loggedInUnitId;

  useEffect(() => {
    ApiUnit()
      .getUnitList(unitId, loggedInUnitId, false, unitType, true)
      .then((res) => {
        setUnitList(res.data);
      })
      .catch((err) => console.log(err));
  }, [unitId, loggedInUnitId, unitType]);

  const handleChangeUnits = (record) => {
    if (record !== null) {
      ApiUnit()
        .getUnitList(record.value, loggedInUnitId, false, unitType, true)
        .then((res) => {
          setUnitList(res.data);
        })
        .catch((err) => console.log(err));

      setValues({
        ...values,
        unit: record,
      });

      setSelectedUnit(record);
    }
  };

  const handleForChildUnits = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setValues({
      ...values,
      forChildUnits: v,
    });
  };

  return (
    <>
      {unitList.length !== 0 ? (
        <Row>
          {unitList.map((record, index) => {
            const unitOptions = [];
            record.unitList.forEach((d) =>
              d.unitTypeName !== "UsraMuavin" &&
              d.unitTypeName !== "Munfarid" &&
              d.unitTypeName !== "EX"
                ? unitOptions.push({
                    value: d.id,
                    label: d.name,
                    unitName: d.unitTypeName,
                  })
                : null
            );
            let selected = {};
            if (record.selectedUnit !== null) {
              selected = {
                value: record.selectedUnit.id,
                label: record.selectedUnit.name,
              };
            }

            const isLastIndex = index === unitList.length - 1;
            return (
              <Col md="3" key={index}>
                <FormGroup>
                  <Row>
                    <Col md="10">
                      <Select
                        options={unitOptions}
                        value={selected}
                        className="basic-single ur"
                        classNamePrefix="select"
                        onChange={handleChangeUnits}
                        isDisabled={isDisabled}
                      />
                    </Col>
                    {isLastIndex ? null : (
                      <Col md="2" className="p-0">
                        {GetSelectedLanguage() === "en" ? (
                          <GoArrowRight
                            size={20}
                            style={{ marginRight: 3, marginBottom: 1 }}
                          />
                        ) : (
                          <GoArrowLeft
                            size={20}
                            style={{ marginRight: 3, marginBottom: 1 }}
                          />
                        )}
                      </Col>
                    )}
                  </Row>
                </FormGroup>
              </Col>
            );
          })}
          {!responsibilitySelected && (
            <Row>
              <Col md="4">
                <FormGroup check>
                  <Label check for={"forChildUnits" + index}>
                    <Input
                      id={"forChildUnits" + index}
                      type="checkbox"
                      name={"forChildUnits" + index}
                      checked={values.forChildUnits}
                      value={!values.forChildUnits}
                      onChange={handleForChildUnits}
                      disabled={isDisabled}
                    />
                    {
                      <GetLanguageString props="settings_events_for_child_unit" />
                    }
                  </Label>
                </FormGroup>
              </Col>
            </Row>
          )}
        </Row>
      ) : (
        LoadingSpinner()
      )}
    </>
  );
};

export default SelectUnitForPermissions;
