import axios from "axios";

import { BASEURL } from "../../../constants/const";
import { encryptStorage } from "../../../constants/EncryptStorage";

export function ApiAddressType() {
  const userToken = encryptStorage.getItem("token");

  const authAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const url = BASEURL + "setup/";
  return {
    getAddressType: () => authAxios.get(url + "GetAddressTypesList/"),
    addAddressType: (newRecord) =>
      authAxios.post(url + "AddAddressType/", newRecord),
    deleteAddressType: (id) =>
      authAxios.delete(url + "DeleteAddressType/" + id),
    getEditAddressType: (id) => authAxios.get(url + "EditAddressType/" + id),
    updateAddressType: (updateRecord) =>
      authAxios.post(url + "EditAddressType/", updateRecord),
  };
}
