import React, { useState } from "react";
import {
  Form,
  FormGroup,
  Input,
  Button,
  FormFeedback,
  InputGroup,
  InputGroupText,
} from "reactstrap";

import useForm from "../shared/useForm";
import { requestAccountApi } from "../../actions/api";
import { LoadingSpinner } from "../../constants/const";
import { GetLanguageString } from "../helper/Components";
import { GetSelectedLanguage } from "../helper/Method";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const SetPassword = ({ setSignUp, signUp, signUpValues }) => {
  const selected = GetSelectedLanguage();
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState([]);
  const passwordValues = {
    password: "",
    confirmPassword: "",
  };
  
  const [newPasswordType, setNewPasswordType] = useState("password");
  const [confirmNewPasswordType, setConfirmNewPasswordType] =
    useState("password");

  const { errors, setErrors, values, handleInputChange } =
    useForm(passwordValues);

  const validate = () => {
    let temp = {};
    temp.password = values.password
      ? values.password.length >= 8
        ? ""
        : selected === "en"
        ? "Password length should be more than 8."
        : selected === "ur"
        ? "پاس ورڈ کی لمبائی 8 سے زیادہ ہونی چاہیے۔"
        : null
      : selected === "en"
      ? "Please enter Password."
      : selected === "ur"
      ? " براہ کرم پاس ورڈ درج کریں۔"
      : null;
    temp.confirmPassword = values.confirmPassword
      ? values.confirmPassword.length >= 8
        ? ""
        : selected === "en"
        ? "Password length should be more than 8."
        : selected === "ur"
        ? "پاس ورڈ کی لمبائی 8 سے زیادہ ہونی چاہیے۔"
        : null
      : selected === "en"
      ? "Please enter Password."
      : selected === "ur"
      ? " براہ کرم پاس ورڈ درج کریں۔"
      : null;
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const ConfirmPassword = () => {
    if (values.password !== "" && values.confirmPassword !== "") {
      let val = values.password.localeCompare(values.confirmPassword);
      if (val === 0) return true;
    } else return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      if (ConfirmPassword()) {
        setLoading(true);
        signUpValues.password = values.password;
        if (signUpValues.isPassword) {
          requestAccountApi()
            .forgotPassword(signUpValues)
            .then((res) => {
              setLoading(false);
              setSignUp(!signUp);
            })
            .catch((err) => {
              setLoading(false);
              setServerError(err.response.data.errors);
            });
        } else {
          requestAccountApi()
            .createDynamicUser(signUpValues)
            .then((res) => {
              setLoading(false);
              setSignUp(!signUp);
            })
            .catch((err) => {
              setLoading(false);
              setServerError(err.response.data.errors);
            });
        }
      } else {
        setErrors({
          ...errors,
          confirmPassword: "Passwords do not match",
        });
      }
    }
  };

  return (
    <div>
      {!loading ? (
        <>
          <p>
            <GetLanguageString props="login_set_password_text" />
          </p>
          {serverError && (
            <ul className="text-start">
              {serverError.map((record, index) => {
                return (
                  <li className="text-danger" key={index}>
                    <h4>{record}</h4>
                  </li>
                );
              })}
            </ul>
          )}
          <Form onSubmit={handleSubmit} className="m-t">
            <FormGroup>
              <InputGroup>
                <Input
                  autoFocus
                  className={selected === "ur" ? "ur" : "en"}
                  type={newPasswordType}
                  name="password"
                  value={values.password}
                  onChange={handleInputChange}
                  placeholder={
                    selected === "en"
                      ? "Password"
                      : selected === "ur"
                      ? "پاس ورڈ"
                      : null
                  }
                  {...(errors.password && { invalid: true })}
                />
                <InputGroupText
                  style={{ backgroundColor: "#FFFFFF" }}
                  onClick={() => {
                    setNewPasswordType(
                      newPasswordType === "password" ? "text" : "password"
                    );
                  }}
                >
                  {newPasswordType === "password" ? (
                    <FaEye color="#5C636A" />
                  ) : (
                    <FaEyeSlash color="#5C636A" />
                  )}
                </InputGroupText>
              </InputGroup>
              {errors.password && (
                <FormFeedback>
                  <h4 className={selected === "ur" ? "ur" : "en"}>
                    {errors.password}
                  </h4>
                </FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <InputGroup>
                <Input
                  type={confirmNewPasswordType}
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleInputChange}
                  placeholder={
                    selected === "en"
                      ? "Confirm Password"
                      : selected === "ur"
                      ? "پاس ورڈ کی تصدیق کریں"
                      : null
                  }
                  {...(errors.confirmPassword && { invalid: true })}
                />{" "}
                <InputGroupText
                  style={{ backgroundColor: "#FFFFFF" }}
                  onClick={() => {
                    setConfirmNewPasswordType(
                      confirmNewPasswordType === "password"
                        ? "text"
                        : "password"
                    );
                  }}
                >
                  {confirmNewPasswordType === "password" ? (
                    <FaEye color="#5C636A" />
                  ) : (
                    <FaEyeSlash color="#5C636A" />
                  )}
                </InputGroupText>
              </InputGroup>
              {errors.confirmPassword && (
                <FormFeedback>
                  <h4 className={selected === "ur" ? "ur" : "en"}>
                    {errors.confirmPassword}
                  </h4>
                </FormFeedback>
              )}
            </FormGroup>
            <Button color="primary" type="submit" className="full-width m-b">
              <GetLanguageString props="login_set_password_register" />
            </Button>
          </Form>
        </>
      ) : (
        LoadingSpinner()
      )}
    </div>
  );
};

export default SetPassword;
