import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { RefreshToken } from "./constants/const";
import { encryptStorage } from "./constants/EncryptStorage";
import Logout from "./components/logout/Logout";

const UpdateToken = ({setDoLogout}) => {
  const [renderToApp, setRenderToApp] = useState(false);
  useEffect(() => {
    const interval = setInterval(CheckTokenTime, 1000);
    return () => clearInterval(interval);
  }, []);

  const CheckTokenTime = () => {
    const tokenString = encryptStorage.getItem("token");
    let token = "";
    if (tokenString !== undefined) {
      token = jwtDecode(tokenString);
    } else {
      setRenderToApp(true);
    }
    const tokenExpiry = new Date(token.exp * 1000);
    const currentTime = Date.now();
    if (tokenExpiry < currentTime) {
      RefreshToken();
    }
  };
  return renderToApp ? <Logout setDoLogout={setDoLogout}/> : null;
};

export default UpdateToken;