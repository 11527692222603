import axios from "axios";

import { BASEURL } from "../../../constants/const";
import { encryptStorage } from "../../../constants/EncryptStorage";

export function ApiNotificationType() {
  const userToken = encryptStorage.getItem("token");

  const authAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const url = BASEURL + "setupnotificationtype/";
  return {
    getNotificationTypeList: () => authAxios.get(url + "getList/"),
    getResponsibilityOptions: () => authAxios.get(url + "getResponsibilityOptions/"),
    //getUnitEventTypeList: (unitId) => authAxios.get(url + "getUnitEventTypeList/" + unitId),
    //getEventSubType: () => authAxios.get(url + "getEventSubType/"),
  };
}
