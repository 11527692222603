import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import { toast } from "react-toastify";

import { GetLanguageString } from "../../helper/Components";
import { addMember } from "../../member/ApiMember";
import { LoadingSpinner } from "../../../constants/const";
import { GetSelectedLanguage } from "../../helper/Method";
import ResponsibilityErrorMessage from "./ResponsibilityErrorMessage";
import SelectResponsibilityData from "./SelectResponsibilityData";
import Draggable from "react-draggable";
import SearchAutoComplete from "../../shared/SearchAutoComplete";

const AddResponsibilityByMember = ({
  setAddResponsibiltyModal,
  unitId,
  deleteResponsibilityToggle,
  setDeleteResponsibilityToggle,
}) => {
  const [loading, setLoading] = useState(false);
  const toggle = () => {
    setAddResponsibiltyModal(false);
  };
  const [searchByName, setSearchByName] = useState(true);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchByRegNo, setSearchByRegNo] = useState(false);
  const [regNo, setRegNo] = useState(0);
  const [memberId, setMemberId] = useState(0);
  const [memberExists, setMemberExists] = useState(true);
  const notify = (string) => toast.success(string);
  const [errorMessages, setErrorMessages] = useState([]);
  const [displayMessageModal, setDisplayMessageModal] = useState(false);
  const [responsibilityValues, setResponsibilityValues] = useState({
    memberId: memberId,
    memberRegNo: regNo,
    responsibilityDate: null,
    referenceNote: "",
    unit: {
      value: unitId,
      label: "",
    },
    responsibility: {
      value: 0,
      label: "",
    },
    responsibilityPermissionExists: false,
  });

  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  const handleRegNo = (e) => {
    setRegNo(parseInt(e.target.value));
    setErrors({
      ...errors,
      member: "",
    });
  };

  const [errors, setErrors] = useState({
    responsibilityDate: "",
    responsibility: "",
    displayName: "",
  });

  const validate = () => {
    let temp = {};
    temp.member = memberId !== 0 || regNo !== 0 ? "" : "Select Member";

    temp.responsibilityDate =
      responsibilityValues.responsibilityDate !== null ? (
        ""
      ) : (
        <GetLanguageString props="member_member_details_responsibility_date_error_message" />
      );

    temp.responsibility =
      responsibilityValues.responsibility.value !== 0 &&
      Object.values(responsibilityValues.responsibility).length !== 0 ? (
        ""
      ) : (
        <GetLanguageString props="member_member_details_responsibility_error_message" />
      );

    if (responsibilityValues.responsibility.isMultiple) {
      temp.displayName =
        responsibilityValues.responsibility.isMultiple &&
        responsibilityValues.displayName !== "" ? (
          ""
        ) : (
          <GetLanguageString props="responsibility_title_err" />
        );
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      responsibilityValues.memberRegNo = regNo;
      responsibilityValues.memberId = memberId;
      setLoading(true);
      addMember()
        .addMemberResponsibility(responsibilityValues, false)
        .then((res) => {
          if (
            res.data === "Your request for modification is sent." ||
            res.data === "Invalid Data"
          ) {
            notify(res.data);
            setLoading(false);
          } else if (res.data === "Reg Invalid") {
            notify(res.data);
            setMemberExists(!memberExists);
            setLoading(false);
          } else if (
            !res.data.thisMemberHaveResponsibility &&
            !res.data.otherMemberHaveResponsibility &&
            !res.data.isUsraMavin
          ) {
            swal({
              title: t("member__member_responsibility_added_success_message"),
              icon: "success",
              buttons: "OK",
              className: GetSelectedLanguage() === "en" ? "" : "ur",
            });
            toggle();
            setLoading(false);
            setDeleteResponsibilityToggle(!deleteResponsibilityToggle);
          } else {
            setErrorMessages(res.data);
            setDisplayMessageModal(true);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const [members, setMembers] = useState([]);
  const [name, setName] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    if (name !== "" && !name.includes("/")) {
      setLoadingSearch(true);
      const timeoutId = setTimeout(() => {
        addMember()
          .getUnitParticipants(unitId, name)
          .then((res) => {
            setMembers(res.data);
            setLoadingSearch(false);
          })
          .catch((err) => {
            console.log(err);
            setLoadingSearch(false);
          });
      }, 1500);
      return () => clearTimeout(timeoutId);
    }
    setMembers([]);
    setLoadingSearch(false);
  }, [unitId, name]);

  const handleOnSelect = (item) => {
    var str = item?.name;
    let remove = str.split(" - ").pop();
    let requiredName = str.replace(" - " + remove, "   ");
    setName(requiredName + " - " + remove.replace("/", " "));
    setMemberId(item.id);
    setErrors({
      ...errors,
      member: "",
    });
    setMembers([]);
  };

  return (
    <div>
      {displayMessageModal ? (
        <ResponsibilityErrorMessage
          setDisplayMessageModal={setDisplayMessageModal}
          data={errorMessages}
          displayName={responsibilityValues.displayName}
        />
      ) : null}
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal
          size="lg"
          style={{ maxWidth: "1200px", width: "100%" }}
          isOpen={true}
          className="inmodal"
          autoFocus={false}
        >
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="settings_add_responsibility" />}
          </ModalHeader>
          <ModalBody>
            {!loading ? (
              <>
                <div className="ibox ">
                  <div className="ibox-title">
                    <h5>
                      {
                        <GetLanguageString props="unit_add_responsibility_search_member" />
                      }
                    </h5>
                  </div>
                  <div className="ibox-content">
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Input
                            id="name"
                            type="radio"
                            checked={searchByName}
                            onChange={() => {
                              setSearchByRegNo(false);
                              setSearchByName(true);
                              setRegNo(0);
                            }}
                          />{" "}
                          <Label for="name">
                            {
                              <GetLanguageString props="unit_add_responsibility_member_by_name" />
                            }
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label for="regNo">
                            <Input
                              id="regNo"
                              type="radio"
                              checked={searchByRegNo}
                              onChange={() => {
                                setSearchByName(false);
                                setSearchByRegNo(true);
                                setRegNo(0);
                              }}
                            />{" "}
                            {
                              <GetLanguageString props="unit_add_responsibility_member_by_reg_no" />
                            }
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md="6"
                        className={GetSelectedLanguage() === "ur" ? "ur" : ""}
                      >
                        <SearchAutoComplete
                          members={members}
                          setMembers={setMembers}
                          setName={setName}
                          name={name}
                          type={"rufqa"}
                          loading={loadingSearch}
                          placeholder={t("common_search_by_name")}
                          handleOnSelect={handleOnSelect}
                          calledFrom="mudaris"
                        />
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Input
                            type="text"
                            name="reg_no"
                            placeholder="Reg No."
                            className="no-drag"
                            onChange={(e) => handleRegNo(e)}
                            value={regNo !== 0 ? regNo : ""}
                            disabled={searchByName}
                            {...(!memberExists &&
                              regNo !== null && { invalid: true })}
                          />
                          {!memberExists && regNo !== null ? (
                            <h6 style={{ color: "red", marginTop: 2 }}>
                              Please enter a valid Reg No. This user doesn't
                              exist with this Reg Number;
                            </h6>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {errors.member && (
                        <h4 className="text-error text-center">
                          {errors.member}
                        </h4>
                      )}
                    </Row>
                  </div>
                </div>
                <div className="ibox ">
                  <div className="ibox-title">
                    <h4>
                      {
                        <GetLanguageString props="member_select_responsibility" />
                      }
                    </h4>
                  </div>
                  <div className="ibox-content">
                    <SelectResponsibilityData
                      setValues={setResponsibilityValues}
                      values={responsibilityValues}
                      errors={errors}
                      setErrors={setErrors}
                      selectedUnit={responsibilityValues?.unit?.value}
                      memberId={memberId}
                      type={"add"}
                    />
                  </div>
                </div>
              </>
            ) : (
              LoadingSpinner()
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="white" onClick={toggle}>
              {<GetLanguageString props="common_cancel" />}
            </Button>
            <Button color="primary" type="submit" onClick={handleSubmit}>
              {<GetLanguageString props="common_save_changes" />}
            </Button>
          </ModalFooter>
        </Modal>
      </Draggable>
    </div>
  );
};

export default AddResponsibilityByMember;
