import React, { useState } from "react";
import { encryptStorage } from "../../constants/EncryptStorage";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { GetLanguageString } from "../helper/Components";
import DatePicker from "react-datepicker";
import SelectUnitForMoveUnit from "./SelectUnitForMoveUnit";
import {
  convertToDateString,
  LoadingSpinner,
  nonAlphanumericRegex,
} from "../../constants/const";
import { GetSelectedLanguage } from "../helper/Method";
import { ApiUnit } from "./ApiUnit";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import Draggable from "react-draggable";

const MoveUnit = ({
  setMoveUnitModal,
  moveUnitData,
  setMoveUnitData,
  unitName,
  unitTypeName,
  refreshList,
  setRefreshList,
  parentUnitIdForMove,
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    unit: { value: loggedInUnitId, lable: "", unitName: "" },
  });
  const toggle = () => {
    setMoveUnitModal(false);
  };

  const { t } = useTranslation();

  const [buttonDisable, setButtonDisable] = useState(false);
  const [unitCodeDisable, setUnitCodeDisable] = useState(true);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { value } = e.target;
    if (!nonAlphanumericRegex.test(value)) {
      setMoveUnitData({
        ...moveUnitData,
        newUnitCode: value,
        code: value,
      });
      setErrors({
        ...errors,
        newUnitCode: "",
      });
    }
  };

  const validate = () => {
    let temp = {};
    temp.unitError =
      moveUnitData.unitTypeParents.includes(values.unit.unitName) &&
      values.unit.value !== parentUnitIdForMove ? (
        ""
      ) : (
        <GetLanguageString props="unit_unit_move_error_message" />
      );
    temp.creationDate = selectedDate ? (
      ""
    ) : (
      <GetLanguageString props="unit_date_error_message" />
    );
    temp.newUnitCode = moveUnitData.code ? (
      moveUnitData.code.length === 2 ? (
        ""
      ) : (
        <GetLanguageString props="unit_code_error_message1" />
      )
    ) : (
      <GetLanguageString props="unit_code_error_message" />
    );
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputChangeCreationDate = (value) => {
    if (value !== null) {
      setSelectedDate(value);
      setErrors({
        ...errors,
        creationDate: "",
      });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      moveUnitData.parentUnitId = values.unit.value;
      moveUnitData.creationDate = convertToDateString(selectedDate);
      setLoading(true);
      setButtonDisable(true);
      ApiUnit()
        .MoveUnit(moveUnitData)
        .then((res) => {
          if (res.data.includes("Moved")) {
            swal({
              title: t("unit_unit_moved_success_message"),
              className: GetSelectedLanguage() === "en" ? "" : "ur",
              icon: "success",
              buttons: "OK",
            });
            setLoading(false);
            setButtonDisable(false);
            setRefreshList(!refreshList);
            toggle();
          }
        })
        .catch((err) => {
          if (err.response.data.includes("same")) {
            swal({
              title: t("unit_unit_move_same_code_error"),
              className: GetSelectedLanguage() === "en" ? "" : "ur",
              icon: "error",
              buttons: "OK",
            });
          } else {
            swal({
              title: "Error: " + err.response?.data || err,
              icon: "error",
              buttons: "OK",
            });
          }
          setLoading(false);
          setButtonDisable(false);
        });
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal
          isOpen={true}
          size="lg"
          style={{ maxWidth: "1080px", width: "100%" }}
          className="inmodal"
        >
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="unit_move_unit_title" />}
          </ModalHeader>
          <Form onSubmit={handleSubmit}>
            <ModalBody>
              {!loading ? (
                <>
                  <div className="ibox-title">
                    {GetSelectedLanguage() === "ur" ? (
                      <h4
                        className={GetSelectedLanguage() === "ur" ? "ur" : ""}
                      >
                        <span className="ur no-drag">
                          {unitTypeName + " : " + unitName}
                        </span>{" "}
                        کی منتقلی کے لئے نظم کا انتخاب کریں
                      </h4>
                    ) : (
                      <h4>
                        Select unit to move{" "}
                        <span className="ur no-drag">
                          {unitTypeName + " : " + unitName}
                        </span>{" "}
                        into
                      </h4>
                    )}
                  </div>
                  <div className="ibox-content">
                    <Row>
                      <SelectUnitForMoveUnit
                        values={values}
                        setValues={setValues}
                        setErrors={setErrors}
                        unitId={loggedInUnitId}
                      />
                    </Row>

                    {errors.unitError && (
                      <h4 className="text-error">{errors.unitError}</h4>
                    )}
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label>
                            {<GetLanguageString props="unit_unit_code" />}
                          </Label>
                          <Row>
                            <Col md="8">
                              <Input
                                type="text"
                                name="newUnitCode"
                                className="no-drag"
                                disabled={unitCodeDisable}
                                value={moveUnitData.code}
                                onChange={handleInputChange}
                              />
                            </Col>
                            <Col md="4">
                              <div>
                                <Button
                                  color="primary"
                                  size="m"
                                  onClick={() => {
                                    setUnitCodeDisable(false);
                                  }}
                                >
                                  {
                                    <GetLanguageString props="unit_change_unit_code" />
                                  }
                                </Button>
                              </div>
                            </Col>
                          </Row>
                          {errors.newUnitCode && (
                            <h4 className="text-error">{errors.newUnitCode}</h4>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label className="col-form-label">
                            {
                              <GetLanguageString props="dashboard_event_page_date" />
                            }
                          </Label>
                          <DatePicker
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText="dd/mm/yyyy"
                            className="form-control no-drag"
                            maxDate={new Date()}
                            selected={selectedDate}
                            onChange={handleInputChangeCreationDate}
                            dateFormat="dd/MM/yyyy"
                          />
                          {errors.creationDate && (
                            <h4 className="text-error">
                              {errors.creationDate}
                            </h4>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </>
              ) : (
                LoadingSpinner()
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="white" onClick={toggle}>
                {<GetLanguageString props="common_cancel" />}
              </Button>
              <Button color="primary" disabled={buttonDisable} type="submit">
                {<GetLanguageString props="common_save_changes" />}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </Draggable>
    </div>
  );
};

export default MoveUnit;
