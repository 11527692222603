import React, { useState } from "react";
import { Form, FormGroup, Input, Button, FormFeedback } from "reactstrap";

import useForm from "../shared/useForm";
import { requestAccountApi } from "../../actions/api";
import { LoadingSpinner } from "../../constants/const";
import SetPassword from "./SetPassword";
import { GetSelectedLanguage } from "../helper/Method";
import { GetLanguageString } from "../helper/Components";

const SetCode = ({ setSignUp, signUp, signUpValues, setSignUpValues }) => {
  const selected = GetSelectedLanguage();
  const [loading, setLoading] = useState(false);

  const {
    errors,
    setErrors,
    serverError,
    setServerError,
    values,
    handleInputChange,
  } = useForm(signUpValues);

  const validate = () => {
    let temp = {};
    temp.code = values.code
      ? ""
      : selected === "en"
      ? "Please enter the code."
      : selected === "ur"
      ? " براہ کرم کوڈ درج کریں۔"
      : null;
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      if (values.isPassword) {
        requestAccountApi()
          .forgotPassword(values)
          .then((res) => {
            setLoading(false);
            setSignUpValues({
              ...signUpValues,
              code: values.code,
              isVerified: true,
            });
          })
          .catch((err) => {
            setLoading(false);
            setServerError(err.response.data.errors[0]);
          });
      } else {
        requestAccountApi()
          .createDynamicUser(values)
          .then((res) => {
            setLoading(false);
            setSignUpValues({
              ...signUpValues,
              code: values.code,
              isVerified: true,
            });
          })
          .catch((err) => {
            setLoading(false);
            setServerError(err.response.data.errors[0]);
          });
      }
    }
  };

  return (
    <div>
      {!loading ? (
        signUpValues.isVerified ? (
          <SetPassword
            signUp={signUp}
            setSignUp={setSignUp}
            signUpValues={signUpValues}
          />
        ) : (
          <>
            <p>
              <GetLanguageString props="login_code_text" />
            </p>
            {serverError && <h4 className="text-danger">{serverError}</h4>}
            <Form onSubmit={handleSubmit} className="m-t">
              <FormGroup>
                <Input type="text" name="regNo" value={values.regNo} disabled />
              </FormGroup>
              <FormGroup>
                <Input
                  autoFocus
                  className={selected === "ur" ? "ur" : "en"}
                  type="text"
                  name="code"
                  value={values.code}
                  onChange={handleInputChange}
                  placeholder={
                    selected === "en"
                      ? "Enter Code"
                      : selected === "ur"
                      ? "کوڈ ڈالیں"
                      : null
                  }
                  {...(errors.code && { invalid: true })}
                />
                {errors.code && (
                  <FormFeedback>
                    <h4 className={selected === "ur" ? "ur" : "en"}>{errors.code}</h4>
                  </FormFeedback>
                )}
              </FormGroup>
              <Button color="primary" type="submit" className="full-width m-b">
                <GetLanguageString props="login_code_verify_code" />
              </Button>
            </Form>
          </>
        )
      ) : (
        LoadingSpinner()
      )}
    </div>
  );
};

export default SetCode;
