import React, { useState, useEffect } from "react";
import { 
  FormGroup, 
  Form, 
  Label, 
  Input, 
  Button, 
  ModalBody, 
  ModalFooter, 
  FormFeedback,
  Modal,
  ModalHeader, 
} from 'reactstrap';
import { ApiTutorials } from "./ApiTutorials";
import swal from "sweetalert";
import { GetSelectedLanguage } from "../helper/Method";
import { GetLanguageString } from "../helper/Components";
import { t } from "i18next";
import { LoadingSpinner } from "../../constants/const";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Draggable from "react-draggable";

const EditTutorial = ({ video, setEditModal, refresh, setRefresh, setSearch }) => {
  const [category, setCategory] = useState();
  const [formData, setFormData] = useState({
      tutorialId: video.tutorialId,
      title: video.title,
      categoryId: video.category.value,
      category: video.category,
      description: video.description,
      url: video.url,
      dateAdded: new Date(video.dateAdded)
    
  });
  const [errors, setErrors] = useState();
  const [loading, setLoading] = useState(false);
  const [selectCategory, setSelectCategory] = useState(formData.category);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  useEffect(() => {
    setLoading(true);
    ApiTutorials()
    .getCategory()
    .then((res) => {
      setCategory(res.data);
      setLoading(false);
    })
    .catch((err) => {
      setLoading(false);
    });
  }, [refresh]);

  const validate = () => {
    let temp = {};
    temp.title = formData.title ? (
      ""
    ) : (
      <GetLanguageString props="tutorial_title_error_message" />
    );
    temp.category =
    Object.keys(formData.category).length !== 0 ? (
      ""
    ) : (
      <GetLanguageString props="tutorial_category_error_message" />
    );
    temp.url = formData.url ? (
      ""
    ) : (
      <GetLanguageString props="tutorial_url_error_message" />
    );
    temp.dateAdded = formData.dateAdded ? (
      ""
    ) : (
      <GetLanguageString props="tutorial_date_error_message" />
    );

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    })
  };

  const handleTutorialCategory = (record) => {
    setFormData({
      ...formData,
      categoryId: record.value
    });
    setSelectCategory(record);
    setErrors({
      ...errors,
      category: "",
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if(validate()){
      setLoading(true);
      setButtonDisable(true);
      ApiTutorials()
        .editTutorials(formData.tutorialId, formData)
        .then(() => {
          swal({
            title: t("video_tutorial_edit_msg"),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "success",
            buttons: GetSelectedLanguage() === "en" ? "OK" : "ٹھیک ہے ",
          });
          setSearch(true)
          setRefresh(!refresh);
          setEditModal(false);
          setLoading(false);
          setButtonDisable(false);
        })
        .catch((err) => {
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
          setLoading(false);
          setButtonDisable(false);
        });
      } 
  };

  const handleInputChangeDate = (value) => {
    if (value <= new Date()) {
      setFormData({
        ...formData,
        dateAdded: value,
      });
      setErrors({
        ...errors,
        dateAdded: "",
      })
    } else {
      setErrors({
        dateAdded: "Date must not be greater than current date.",
      });
    }
  };

  return (
    <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
      <Modal isOpen={true} className="inmodal" autoFocus={false}> 
        <ModalHeader className="modal-title" tag="h4">
          <GetLanguageString props="video_tutorial_edit_tutorial" />
        </ModalHeader>
        {!loading ? (
          <Form onSubmit={handleSubmit}>
            <ModalBody>
              <FormGroup>
                <Label>
                  <GetLanguageString props="settings_title" />
                </Label>
                <Input
                  autoFocus
                  type="text"
                  className="no-drag"
                  name="title"
                  value={formData.title}
                  onChange={handleInputChange}
                  {...(errors?.title && { invalid: true })}
                  />
                  {errors?.title && (
                    <FormFeedback>
                      <h4>{errors?.title}</h4>
                    </FormFeedback>
                  )}
              </FormGroup>
              <FormGroup>
                <Label>
                  <GetLanguageString props="video_tutorial_category" />
                </Label>
                <Select
                  options={category}
                  value={selectCategory}
                  className="basic-single no-drag"
                  classNamePrefix="select"
                  onChange={handleTutorialCategory}
                />                            
                {errors?.category ? (
                  <h4 className="text-error">
                    {errors?.category}
                  </h4>
                ) : null}
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label">
                  {<GetLanguageString props="funds_date" />}
                </Label>
                <DatePicker
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="dd/mm/yyyy"
                  className="form-control no-drag"
                  selected={formData?.dateAdded}
                  onChange={handleInputChangeDate}
                  dateFormat="dd/MM/yyyy"
                />
                {errors?.dateAdded && (
                  <h4 className="text-error">{errors?.dateAdded}</h4>
                )}
              </FormGroup>
              <FormGroup>
                <Label>
                  <GetLanguageString props="video_tutorial_url" />
                </Label>
                <Input
                  type="text"
                  name="url"
                  className="no-drag"
                  value={formData.url}
                  onChange={handleInputChange}
                  {...(errors?.url && { invalid: true })}
                  />
                  {errors?.url && (
                    <FormFeedback>
                      <h4>{errors?.url}</h4>
                    </FormFeedback>
                  )}
              </FormGroup>
              <FormGroup>
                <Label>
                  <GetLanguageString props="courses_description" />
                </Label>
                <Input
                  type="text"
                  name="description"
                  className="no-drag"
                  value={formData.description}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="white" onClick={() => setEditModal(false)}>
                <GetLanguageString props="common_cancel" />
              </Button>
              <Button color="primary" type="submit" disabled={buttonDisable}>
                <GetLanguageString props="common_save" />
              </Button>{" "}
            </ModalFooter>
          </Form>
        ) : (
          LoadingSpinner()
        )}
      </Modal>
    </Draggable>
  );
};

export default EditTutorial;
