import React, { useEffect, useState } from "react";
import { Table, Button } from "reactstrap";
import { withRouter, useLocation } from "react-router-dom";

import { GetLanguageString } from "../helper/Components";
import { GetCustomStyles } from "../helper/Method";
import { ApiDashboard } from "./ApiDashboard";
import { LoadingSpinner } from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";
import { RxCross2 } from "react-icons/rx";
import { FcCheckmark } from "react-icons/fc";

const EventDetail = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [unitId, setUnitId] = useState(0);
  const [eventTypeId, setEventTypeId] = useState(0);
  const [eventName, setEventName] = useState(0);
  const [loggedInUnitParentId, setLoggedInUnitParentId] = useState(0);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  if (unitId === 0) {
    setUnitId(location.state.unitId);
    setEventTypeId(location.state.eventTypeId);
    setEventName(location.state.eventName);
  }
  const [parentUnitId, setParentUnitId] = useState(0);
  const [mainUnit, setMainUnit] = useState({});
  const [subUnits, setSubUnits] = useState([]);

  useEffect(() => {
    setLoading(true);
    ApiDashboard()
      .getEventDetailModel(unitId, eventTypeId)
      .then((res) => {
        setMainUnit(res.data);
        if (loggedInUnitId !== 0) {
          setLoggedInUnitParentId(res.data.parentUnitId);
          setParentUnitId(res.data.parentUnitId);
        }
        setSubUnits(res.data.eventDetailModelList);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [unitId, eventTypeId, loggedInUnitId]);

  const handleSubUnits = (value, message) => {
    if (message === null) {
      setLoading(true);
      ApiDashboard()
        .getEventDetailModel(value, eventTypeId)
        .then((res) => {
          setMainUnit(res.data);
          setParentUnitId(res.data.parentUnitId);
          setSubUnits(res.data.eventDetailModelList);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const back = (e) => {
    const { value } = e.target;
    setLoading(true);
    ApiDashboard()
      .getEventDetailModel(value, eventTypeId)
      .then((res) => {
        setMainUnit(res.data);
        setParentUnitId(res.data.parentUnitId);
        setSubUnits(res.data.eventDetailModelList);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div>
      <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
        {parentUnitId !== loggedInUnitParentId ? (
          <Button
            onClick={back}
            value={parentUnitId}
            className={GetCustomStyles().btn_style}
          >
            {<GetLanguageString props="unit_back_btn" />}
          </Button>
        ) : null}
        <h3>
          {
            <GetLanguageString
              props="dashboard_event_page_event_name"
              value={eventName}
            />
          }
        </h3>
        {mainUnit.isDisplay ? null : (
          <div className="panel panel-primary m-t-xl">
            <div className="panel-heading">
              {
                <GetLanguageString
                  props="dashboard_event_page_unit"
                  value={mainUnit.unitName}
                />
              }
            </div>
            <div className="panel-body">
              {!loading ? (
                <div className="table-responsive">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>{<GetLanguageString props="common_name" />}</th>
                        <th>
                          {
                            <GetLanguageString props="dashboard_event_page_unit_type" />
                          }
                        </th>
                        <th>
                          {
                            <GetLanguageString props="dashboard_event_page_status" />
                          }
                        </th>
                        <th>
                          {
                            <GetLanguageString props="dashboard_event_page_date" />
                          }
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td className="ur">{mainUnit.unitName}</td>
                        <td className="ur">{mainUnit.unitTypeName}</td>
                        <td>
                          {mainUnit.isEventConducted ? (
                            <FcCheckmark
                              size={22}
                              color="green"
                              style={{ marginRight: 3, marginBottom: 1 }}
                            />
                          ) : (
                            <RxCross2
                              size={22}
                              color="red"
                              style={{ marginRight: 3, marginBottom: 1 }}
                            />
                          )}
                        </td>
                        <td>
                          {mainUnit.isEventConducted
                            ? mainUnit.eventDate
                            : null}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              ) : (
                LoadingSpinner()
              )}
            </div>
          </div>
        )}
        {subUnits.length !== 0 ? (
          <div className="panel panel-warning m-t-xl">
            <div className="panel-heading">
              <GetLanguageString
                props="dashboard_event_page_sub_unit"
                value={mainUnit.unitName}
              />
            </div>
            <div className="panel-body">
              {!loading ? (
                <div className="table-responsive">
                  <Table className="table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>
                          {
                            <GetLanguageString props="dashboard_event_page_unit_name" />
                          }
                        </th>
                        <th>
                          {
                            <GetLanguageString props="dashboard_event_page_unit_type" />
                          }
                        </th>
                        <th className="text-center">
                          {
                            <GetLanguageString props="dashboard_event_page_total_events" />
                          }
                        </th>
                        <th className="text-center">
                          {
                            <GetLanguageString props="dashboard_events_details_conducted_events" />
                          }
                        </th>
                        <th className="text-center">
                          {
                            <GetLanguageString props="dashboard_event_page_percentage" />
                          }
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {subUnits.map((record, index) => {
                        return (
                          <tr
                            key={index}
                            onClick={() =>
                              handleSubUnits(
                                record.unitId,
                                record.eventDetailList.message
                              )
                            }
                          >
                            <td>{index + 1}</td>
                            <td className="ur">{record.unitName}</td>
                            <td className="ur">{record.unitTypeName}</td>
                            {record.eventDetailList.message !== null ? (
                              <>
                                <td className="text-center">
                                  {record.eventDetailList.message}
                                </td>
                                <td className="text-center">
                                  {record.eventDetailList.message}
                                </td>
                                <td className="text-center">
                                  {record.eventDetailList.message}
                                </td>
                              </>
                            ) : (
                              <>
                                <td className="text-center">
                                  {record.eventDetailList.totalEventsCount}
                                </td>
                                <td className="text-center">
                                  {record.eventDetailList.conductedEventsCount}
                                </td>
                                <td className="text-center">
                                  {record.eventDetailList.totalEventsCount !== 0
                                    ? Math.round(
                                        (record.eventDetailList
                                          .conductedEventsCount /
                                          record.eventDetailList
                                            .totalEventsCount) *
                                          100
                                      ).toFixed(1)
                                    : 0.0}
                                  %
                                </td>
                              </>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              ) : (
                LoadingSpinner()
              )}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default withRouter(EventDetail);
