import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import Draggable from "react-draggable";
import swal from "sweetalert";
import { LoadingSpinner } from "../../../../constants/const";
import { GetLanguageString } from "../../../helper/Components";
import { GetSelectedLanguage } from "../../../helper/Method";
import { ApiLetterForCounselling } from "../ApiLetterForCounselling";

const EditCounsellingQuestions = ({
  setEditModal,
  editValue,
  setReload,
  reload,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [counsellingQuestionsValues, setCounsellingQuestionsValues] = useState({
    content: "",
    updatedVersion: "",
    isEnabled: true,
    isArchive: false,
  });
  const [errors, setErrors] = useState(counsellingQuestionsValues);
  const [hasUpdatedVersionList, setPreviousQuestionsList] = useState([]);
  const toggle = () => setEditModal(false);
  const [selectedPreviousQuestionsType, setSelectedPreviousQuestionsType] =
    useState({});
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    setLoading(true);
    ApiLetterForCounselling()
      .editCounsellingQuestions(editValue)
      .then((res) => {
        setCounsellingQuestionsValues(res.data);
        setSelectedPreviousQuestionsType(res.data.hasUpdatedVersion);
      })
      .catch((err) => {
        setLoading(false);
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
      });

    setLoading(true);
    ApiLetterForCounselling()
      .getCounsellingQuestions()
      .then((res) => {
        setPreviousQuestionsList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        swal({
          content: err,
          icon: "error",
          buttons: "OK",
        });
      });
  }, [editValue]);

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setErrors({
      ...errors,
      [name]: "",
    });

    setCounsellingQuestionsValues({
      ...counsellingQuestionsValues,
      [name]: value,
    });
  };

  const validate = () => {
    let temp = {};
    temp.content = counsellingQuestionsValues.content
      ? ""
      : t("common_field_required_error");
    temp.updatedVersion =
      counsellingQuestionsValues.content === selectedPreviousQuestionsType.label
        ? t("settings_counselling_question_updated_version_error")
        : "";
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleChangePreviousQuestionsType = (record) => {
    if (record === null) {
      setCounsellingQuestionsValues({
        ...counsellingQuestionsValues,
        hasUpdatedVersion: {},
      });
      setSelectedPreviousQuestionsType({});
    } else {
      setCounsellingQuestionsValues({
        ...counsellingQuestionsValues,
        hasUpdatedVersion: record,
      });
      setSelectedPreviousQuestionsType(record);
    }
    setErrors({
      ...errors,
      hasUpdatedVersion: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      setLoading(true);
      ApiLetterForCounselling()
        .updateCounsellingQuestions(editValue, counsellingQuestionsValues)
        .then((res) => {
          setLoading(false);
          swal({
            title: t("settings_counselling_question_edit_success"),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "success",
            buttons: "OK",
          });
          setReload(!reload);
          toggle();
        })
        .catch((err) => {
          swal({
            content: err,
            icon: "error",
            buttons: "OK",
          });
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-content" tag="h4">
            {<GetLanguageString props="settings_counselling_question_add" />}
          </ModalHeader>
          {!loading ? (
            <Form onSubmit={handleSubmit}>
              <ModalBody>
                <FormGroup>
                  <Label className="no-drag">
                    {
                      <GetLanguageString props="settings_counselling_question_content" />
                    }
                  </Label>
                  <Input
                    autoFocus
                    type="text"
                    name="content"
                    className="ur no-drag"
                    value={counsellingQuestionsValues.content}
                    onChange={handleInputChange}
                    {...(errors.content && { invalid: true })}
                  />
                  {errors.content && (
                    <FormFeedback>
                      <h4
                        className={GetSelectedLanguage() === "ur" ? "ur" : ""}
                      >
                        {errors.content}
                      </h4>
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label>
                    {
                      <GetLanguageString props="settings_counselling_question_updated_version" />
                    }
                  </Label>
                  <Select
                    value={selectedPreviousQuestionsType}
                    options={hasUpdatedVersionList.map((f) => ({
                      value: f.id,
                      label: f.content,
                    }))}
                    className="basic-single no-drag ur"
                    classNamePrefix="select"
                    onChange={handleChangePreviousQuestionsType}
                  />
                  {errors.updatedVersion && (
                      <h4
                        className={GetSelectedLanguage() === "ur" ? "ur text-error" : "text-error"}
                      >
                        {errors.updatedVersion}
                      </h4>
                  )}
                </FormGroup>
                <FormGroup check>
                  <Label check for="isArchive">
                    {" "}
                    <span
                      style={{
                        float:
                          GetSelectedLanguage() === "ur" ? "right" : "left",
                        marginLeft:
                          GetSelectedLanguage() === "ur" ? "24px" : "0px",
                      }}
                    >
                      <Input
                        id="isArchive"
                        type="checkbox"
                        name="isArchive"
                        value={!counsellingQuestionsValues.isArchive}
                        onChange={handleInputChange}
                        checked={counsellingQuestionsValues.isArchive}
                      />
                    </span>
                    {
                      <GetLanguageString props="settings_letter_for_counselling_archive" />
                    }
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check for="isEnabled">
                    {" "}
                    <span
                      style={{
                        float:
                          GetSelectedLanguage() === "ur" ? "right" : "left",
                        marginLeft:
                          GetSelectedLanguage() === "ur" ? "24px" : "0px",
                      }}
                    >
                      <Input
                        id="isEnabled"
                        type="checkbox"
                        checked={counsellingQuestionsValues.isEnabled}
                        name="isEnabled"
                        value={!counsellingQuestionsValues.isEnabled}
                        onChange={handleInputChange}
                      />
                    </span>
                    {<GetLanguageString props="member_address_enabled" />}
                  </Label>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="white" onClick={toggle}>
                  {<GetLanguageString props="common_cancel" />}
                </Button>
                <Button color="primary" type="submit">
                  {<GetLanguageString props="common_save_changes" />}
                </Button>
              </ModalFooter>
            </Form>
          ) : (
            LoadingSpinner()
          )}
        </Modal>
      </Draggable>
    </div>
  );
};

export default EditCounsellingQuestions;
