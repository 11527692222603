import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";
import useForm from "../../shared/useForm";
import { ApiContactType } from "./ApiContactType";
import { GetLanguageString } from "../../helper/Components";
import { LoadingSpinner, isUrdu, isNumeric } from "../../../constants/const";
import swal from "sweetalert";
import Draggable from "react-draggable";
import { GetSelectedLanguage } from "../../helper/Method";
import { useTranslation } from "react-i18next";

let initialValues = {
  type: "",
  code: "",
  isRtl: false,
  inputType: "text",
  iconHtml: "",
  sortOrder: "",
};

const AddContactType = ({ setModal, setReload, reload }) => {
  const [loading, setLoading] = useState(false);
  const toggle = () => setModal(false);
  const { t } = useTranslation();

  const { errors, setErrors, values, setValues, handleInputChange } =
    useForm(initialValues);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  const handleCheckbox = (e) => {
    const { name, value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setValues({
      ...values,
      [name]: v,
    });
  };

  const validate = () => {
    let temp = {};
    temp.type = values.type ? (
      ""
    ) : (
      <GetLanguageString props="settings_name_error_message" />
    );
    temp.code = values.code ? (
      ""
    ) : (
      <GetLanguageString props="settings_code_error_message" />
    );
    temp.sortOrder = values.sortOrder ? (
      isNumeric(values.sortOrder) ? (
        ""
      ) : (
        <GetLanguageString props="settings_sort_order_numeric_error_message" />
      )
    ) : (
      <GetLanguageString props="settings_sort_order_error_message" />
    );

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      ApiContactType()
        .addContactType(values)
        .then((res) => {
          toggle();
          swal({
            title: t("settings_contact_type_add_success"),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "success",
            buttons: "OK",
          });
          setReload(!reload);
          setLoading(false);
        })
        .catch((err) => {
          toggle();
          swal({
            title: err,
            text: err.response.data,
            icon: "error",
            buttons: "OK",
          });
          setLoading(false);
          console.log(`Error Msg: ${err.response.data}, ${err}`);
        });
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="settings_contact_type_add" />}
          </ModalHeader>
          {!loading ? (
            <Form onSubmit={handleSubmit}>
              <ModalBody>
                <FormGroup>
                  <Label>{<GetLanguageString props="common_name" />}</Label>
                  <Input
                    autoFocus
                    type="text"
                    name="type"
                    className={isUrdu(values.type) ? "ur no-drag" : "no-drag"}
                    value={values.type}
                    onChange={handleInputChange}
                    {...(errors?.type && { invalid: true })}
                  />
                  {errors?.type && (
                    <FormFeedback>
                      <h4>{errors?.type}</h4>
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label>
                    {
                      <GetLanguageString props="settings_notification_type_code" />
                    }
                  </Label>
                  <Input
                    type="text"
                    name="code"
                    className="no-drag"
                    value={values.code}
                    onChange={handleInputChange}
                    {...(errors?.code && { invalid: true })}
                  />
                  {errors?.code && (
                    <FormFeedback>
                      <h4>{errors?.code}</h4>
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label>
                    {
                      <GetLanguageString props="settings_contact_type_input_type" />
                    }
                  </Label>
                  <Input
                    type="text"
                    name="inputType"
                    className="no-drag"
                    value={values.inputType}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    {
                      <GetLanguageString props="settings_contact_type_input_icon_html" />
                    }
                  </Label>
                  <Input
                    type="text"
                    name="iconHtml"
                    className="no-drag"
                    value={values.iconHtml}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>{<GetLanguageString props="unit_sort_order" />}</Label>
                  <Input
                    type="text"
                    name="sortOrder"
                    className="no-drag"
                    value={values.sortOrder}
                    onChange={handleInputChange}
                    {...(errors?.sortOrder && { invalid: true })}
                  />
                  {errors?.sortOrder && (
                    <FormFeedback>
                      <h4>{errors?.sortOrder}</h4>
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup check>
                  <Label check for="isRtl">
                    <span
                      style={{
                        float:
                          GetSelectedLanguage() === "ur" ? "right" : "left",
                        marginLeft:
                          GetSelectedLanguage() === "ur" ? "24px" : "0px",
                      }}
                    >
                      <Input
                        id="isRtl"
                        type="checkbox"
                        name="isRtl"
                        value={!values.isRtl}
                        onChange={handleCheckbox}
                        checked={values.isRtl}
                      />
                    </span>
                    {
                      <GetLanguageString props="settings_contact_type_input_rtl" />
                    }
                  </Label>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="white" onClick={toggle}>
                  {<GetLanguageString props="common_cancel" />}
                </Button>
                <Button type="submit" color="primary">
                  {<GetLanguageString props="common_save_changes" />}
                </Button>
              </ModalFooter>
            </Form>
          ) : (
            LoadingSpinner()
          )}
        </Modal>
      </Draggable>
    </div>
  );
};

export default AddContactType;
