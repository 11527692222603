import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Button,
  Row,
  Col,
  Label,
  Input,
  FormGroup,
  Table
} from "reactstrap";
import { LoadingSpinner } from "../../../constants/const";
import { encryptStorage } from "../../../constants/EncryptStorage";
import { GetLanguageString, NoResult } from "../../helper/Components";
import { addMember } from "../ApiMember";
import Draggable from "react-draggable";

const SelectPreviousMember = ({ memberId, setPreviousMemberModal, setCallApi }) => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState([]);
  const [previousMemberId, setPreviousMemberId] = useState(0);
  const [error, setError] = useState("");
  const [reportSearchMemberModel, setReportSearchMemberModel] = useState({
    unitId: loggedInUnitId,
    loggedInUnitId: loggedInUnitId,
    name:"",
    fatherName:""
  });
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  const toggle = () => setPreviousMemberModal(false);

  useEffect(() => {
    if(reportSearchMemberModel.name.length > 4 || reportSearchMemberModel.fatherName.length > 4){
        setLoading(true);
        addMember()
          .GetPreviousMembersList(reportSearchMemberModel)
          .then((res) => {
            setMembers(res.data);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
    }
  }, [reportSearchMemberModel]);

  const validate = () => {
    if(previousMemberId === 0) return false
    else return true
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setReportSearchMemberModel({
        ...reportSearchMemberModel,
        [name]: value
    })
  }
  
  const handleSubmit = (e) => {
    e.preventDefault();
    if(validate()){
      addMember()
         .SetMemberPerviousRecord(memberId, previousMemberId)
         .then((res) => {
           toggle();
           setCallApi(previousValue => !previousValue);
         })
         .catch((err) => console.log(err))
    }else {
        setError("member")
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="member_select_previous_record" />}
          </ModalHeader>
            <Form>
              <ModalBody>
              <Form>
                  <Row>
                      <Col md="6">
                      <FormGroup>
                          <Label className="col-form-label">
                          {<GetLanguageString props="member_search_by_name" />}
                          </Label>
                          <Input
                          className="ur no-drag"
                          type="text"
                          name="name"
                          value={reportSearchMemberModel.name}
                          onChange={handleInputChange}
                          />
                      </FormGroup>
                      </Col>
                      <Col md="6">
                      <FormGroup>
                          <Label className="col-form-label">
                          {<GetLanguageString props="member_search_by_father_name" />}
                          </Label>
                          <Input
                              className="ur no-drag"
                              type="text"
                              name="fatherName"
                              value={reportSearchMemberModel.fatherName}
                              onChange={handleInputChange}
                          />
                      </FormGroup>
                      </Col>
                  </Row>
              </Form>
              {!loading ? (
              <div className="ibox">
                  <div className="ibox-title">
                      {<GetLanguageString props="member_members" />}
                  </div>
                  <div className="ibox-content">
                      {members.length !== 0 ? (
                          <div className="table-responsive">
                              <Table className="table-bordered">
                              <thead>
                                  <tr>
                                  <th>
                                    {<GetLanguageString props="member_select" />}
                                  </th>
                                  <th>#</th>
                                  <th>
                                      <GetLanguageString props="member_reg_number" />
                                  </th>
                                  <th>
                                      <GetLanguageString props="common_name" />
                                  </th>
                                  <th>
                                      <GetLanguageString props="member_father_name" />
                                  </th>
                                  </tr>
                              </thead>
                              <tbody>
                                  {members?.map((record, index) => {
                                  return (
                                      <tr key={index}>
                                      <td>
                                        <Input
                                          id={"select"}
                                          type="radio"
                                          name="member"
                                          className="big-checkbox"
                                          onChange={() => {
                                            setPreviousMemberId(record.id)
                                            setError("")
                                          }}
                                        />
                                      </td>
                                      <td>{index + 1}</td>
                                      <td>{record.regNo}</td>
                                      <td className="ur no-drag">{record.fullName}</td>
                                      <td className="ur no-drag">{record.fatherName}</td>
                                      </tr>
                                  );
                                  })}
                              </tbody>
                              </Table>
                          </div>
                      ):(
                          <NoResult />
                      )}
                  </div>
              </div>
              ) : (
              LoadingSpinner()
              )}
              { error && (<h4 className="text-center no-results">
                  {<GetLanguageString props="member_select_error" />}</h4>)}
              </ModalBody>
              <ModalFooter>
                <Button color="white" onClick={toggle}>
                  {<GetLanguageString props="common_cancel" />}
                </Button>
                <Button color="primary" type="submit" onClick={handleSubmit}>
                  {<GetLanguageString props="common_save_changes" />}
                </Button>
              </ModalFooter>
            </Form>
        </Modal>
      </Draggable>
    </div>
  );
};

export default SelectPreviousMember;
