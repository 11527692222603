import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Col, Row } from "reactstrap";

import { GetLanguageString, NoResult } from "../helper/Components";
import { GetCustomStyles } from "../helper/Method";
import { ApiDashboard } from "./ApiDashboard";
import { LoadingSpinner } from "../../constants/const";

const UnitEventDetail = ({ tempUnit, tab3, history }) => {
  const [loading, setLoading] = useState(false);
  const [eventColSize, setEventColSize] = useState(0);
  const [eventDetails, setEventDetails] = useState([]);

  useEffect(() => {
    if(tab3 === "active"){
        setLoading(true);
        if(tempUnit.value !== 0){
          ApiDashboard()
            .getDashboardEventDetails(tempUnit.value)
            .then((res) => {
              setLoading(false);
              setEventDetails(res.data.eventsDetailsList);
              let tempEventlength = Math.floor(
                12 / res.data.eventsDetailsList.length
              );
              if (tempEventlength >= 2) setEventColSize(tempEventlength);
              else setEventColSize(2);
            })
            .catch((err) => {
              console.log("err ", err);
              setLoading(false);
            });
        }
    }
  }, [tempUnit, tab3]);

  const HandleEvent = (id, event) => {
    history.push({
      pathname: `/eventdetail`,
      state: {
        unitId: tempUnit.value,
        eventTypeId: id,
        eventName: event,
      },
    });
  };

  return (
    <>
      <div role="tabpanel" id="tab-3" className={"tab-pane " + tab3}>
        <div className="panel-body">
        {!loading ? (
            <Row>
              {eventDetails.length !== 0
                ? eventDetails.map((record, index) => {
                    return (
                      <Col xl={eventColSize} key={index}>
                        <div
                          className="ibox cursor-pointer"
                          onClick={() =>
                            HandleEvent(record.eventId, record.eventName)
                          }
                        >
                          <div className="ibox-title">
                            <div className={GetCustomStyles().btn_style}>
                              <span
                                className={"label label-primary float-right"}
                                style={{
                                  fontSize: "13px",
                                }}
                              >
                                {record.totalEventsCount}
                              </span>
                            </div>
                            <h5 className="ur">{record.eventName}</h5>
                          </div>
                          <div className="ibox-content">
                            <h1 className="no-margins">
                              {record.conductedEventsCount}
                            </h1>
                            <div
                              className={
                                GetCustomStyles().btn_style +
                                " font-bold text-navy"
                              }
                            >
                              {record.totalEventsCount !== 0
                                ? Math.round(
                                    (record.conductedEventsCount /
                                      record.totalEventsCount) *
                                      100
                                  ).toFixed(1)
                                : 0.0}
                              %
                            </div>
                            <small>
                              {
                                <GetLanguageString props="dashboard_events_details_conducted_events" />
                              }
                            </small>
                          </div>
                        </div>
                      </Col>
                    );
                  })
                : <NoResult />}
            </Row>
          ) : (
            LoadingSpinner()
          )}
        </div>
      </div>
    </>
  );
};

export default withRouter(UnitEventDetail);
