import React, { useEffect, useState } from "react";
import { Table, Button, ButtonGroup } from "reactstrap";
import swal from "sweetalert";
import { t } from "i18next";
import { ApiTutorialCategory } from "./ApiTutorialCategory";
import { GetLanguageString, NoResult } from "../../helper/Components";
import { LoadingSpinner } from "../../../constants/const";
import { GetSelectedLanguage } from "../../helper/Method";
import EditTutorialCategory from "./EditTutorialCategory";

const TutorialCategory = ({ activeTab }) => {
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [category, setCategory] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});

  const deleteCategory = (id) => {
    ApiTutorialCategory()
      .deleteCategory(id)
      .then(() => {
        setCategory((preValues) => preValues.filter((v) => v.id !== id));
        setRefresh(!refresh);
      })
      .catch((err) => {
        setRefresh(!refresh);
      });
  };

  const handleDelete = (id) => {
    let checkCategory = false;
    ApiTutorialCategory()
      .checkCategory(id)
      .then((res) => {
        checkCategory = res.data;
        if (checkCategory) {
          swal({
            title: t("common_delete_message"),
            text: t("check_category_message"),
            textAlign: "center",
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              deleteCategory(id);
            }
          });
        } else if (!checkCategory) {
          swal({
            title: t("common_delete_message"),
            text: t("common_delete_detail_message"),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              deleteCategory(id);
            }
          });
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (activeTab === "category") {
      setLoading(true);
      ApiTutorialCategory()
        .getCategory()
        .then((res) => {
          setCategory(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [refresh, activeTab]);

  const handleEdit = (category) => {
    setSelectedCategory(category);
    setEditModal(true);
  };

  return (
    <>
      {editModal ? (
        <EditTutorialCategory
          selectedCategory={selectedCategory}
          setEditModal={setEditModal}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      ) : null}
      {!loading ? (
        category.length !== 0 ? (
          <div className="table-responsive">
            <Table responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{<GetLanguageString props="tutorial_category" />}</th>
                  <th className="text-center">
                    {<GetLanguageString props="common_action" />}
                  </th>
                </tr>
              </thead>
              <tbody>
                {category.map((record, index) => {
                  return (
                    <tr key={index}>
                      <td className="p-t-custom">{index + 1}</td>
                      <td>{record.label}</td>
                      <td className="text-center">
                        <ButtonGroup>
                          <Button
                            color="white"
                            size="sm"
                            onClick={() => handleEdit(record)}
                          >
                            <GetLanguageString props="common_edit" />
                          </Button>{" "}
                          <Button
                            color="white"
                            size="sm"
                            onClick={() => handleDelete(record.value)}
                          >
                            <GetLanguageString props="common_delete" />
                          </Button>{" "}
                        </ButtonGroup>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        ) : (
          <NoResult />
        )
      ) : (
        LoadingSpinner()
      )}
    </>
  );
};

export default TutorialCategory;
