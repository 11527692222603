import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Row,
  FormGroup,
  Form,
  Button,
} from "reactstrap";
import { GetLanguageString } from "../../helper/Components";
import { convertToDateString, LoadingSpinner } from "../../../constants/const";
import DatePicker from "react-datepicker";
import { ApiMudaris } from "./ApiMudaris";
import swal from "sweetalert";
import { GetSelectedLanguage } from "../../helper/Method";
import { useTranslation } from "react-i18next";
import Draggable from "react-draggable";

const MemberMudarisCancelRegistration = ({
  setCancelRegistrationModal,
  registrationId,
  setUpdate,
  update,
  registrationDate,
}) => {
  const [loading, setLoading] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [errors, setErrors] = useState({
    registrationId: registrationId,
    date: "",
  });
  const toggle = () => setCancelRegistrationModal(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  const { t } = useTranslation();

  const validate = () => {
    let temp = {};
    temp.date =
      selectedDate !== null ? (
        ""
      ) : (
        <GetLanguageString props="member_member_detail_mudaris_details_assessments_date_error" />
      );

    temp.date =
      selectedDate.setHours(0, 0, 0, 0) >= new Date(registrationDate).setHours(0, 0, 0, 0) ? (
        ""
      ) : (
        <GetLanguageString props="member_member_detail_mudaris_details_assessments_cancel_date_error" />
      );

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };
  

  const handleInputChangeDate = (value) => {
    if (value !== null) {
      if (value <= new Date()) {
        setSelectedDate(value);
        setErrors({
          ...errors,
          date: "",
        });
      } else {
        setErrors({
          ...errors,
          date: <GetLanguageString props="tutorial_date_error_message" />,
        });
      }
    } else {
      setSelectedDate(null);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      swal({
        title: t("common_delete_message"),
        className: GetSelectedLanguage() === "en" ? "" : "ur",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setLoading(true);
          setButtonDisable(true);
          ApiMudaris()
            .DeleteMudarisRegistration({
              registrationId: registrationId,
              date: convertToDateString(selectedDate),
            })
            .then((res) => {
              swal({
                title: t(
                  "member_member_detail_mudaris_details_registrations_add_registrations_delete"
                ),
                className: GetSelectedLanguage() === "en" ? "" : "ur",
                icon: "success",
                buttons: "OK",
              });
              setLoading(false);
              setButtonDisable(false);
              setUpdate(!update);
              toggle();
            })
            .catch((err) => {
              console.log(err);
              swal({
                title: err,
                icon: "error",
                buttons: "OK",
              });
              setLoading(false);
              setButtonDisable(false);
            });
        }
      });
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {
              <GetLanguageString props="member_member_detail_mudaris_details_registrations_cancel" />
            }
          </ModalHeader>
          {!loading ? (
            <Form onSubmit={handleSubmit}>
              <ModalBody>
                {!loading ? (
                  <div className="ibox-content">
                    <Row>
                      <FormGroup>
                        <Label className="col-form-label">
                          {
                            <GetLanguageString props="member_member_detail_mudaris_details_registrations_start_date" />
                          }
                        </Label>
                        <DatePicker
                          showYearDropdown
                          placeholderText="dd/mm/yyyy"
                          className="form-control no-drag"
                          dateFormat="dd/MM/yyyy"
                          selected={selectedDate}
                          maxDate={new Date()}
                          onChange={(value) => handleInputChangeDate(value)}
                        />
                      </FormGroup>
                    </Row>
                    {errors?.date && (
                      <h4 className="text-error no-drag">{errors?.date}</h4>
                    )}
                  </div>
                ) : (
                  LoadingSpinner()
                )}
              </ModalBody>
              <ModalFooter>
                <Button color="white" onClick={toggle}>
                  {<GetLanguageString props="common_cancel" />}
                </Button>
                <Button color="primary" type="submit" disabled={buttonDisable}>
                  {<GetLanguageString props="common_save_changes" />}
                </Button>
              </ModalFooter>
            </Form>
          ) : (
            LoadingSpinner()
          )}
        </Modal>
      </Draggable>
    </div>
  );
};

export default MemberMudarisCancelRegistration;
