import axios from "axios";

import { BASEURL } from "../../../constants/const";
import { encryptStorage } from "../../../constants/EncryptStorage";

export function ApiMudaris() {
  const userToken = encryptStorage.getItem("token");

  const authAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });
  
  const fileAuthAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    responseType: "arraybuffer",
  });

  return {
    getAssessmentTypes: () =>
      authAxios.get(BASEURL + "setup/getAssessmentTypes"),
    getAssessmentStatuses: (id) =>
      authAxios.get(BASEURL + "setup/GetAssessmentStatuses/" + id),
    GetMemberMudarisInfo: (id) =>
      authAxios.get(BASEURL + "member/GetMemberMudarisInfo/" + id),
    CreateMudarisProfile: (id) =>
      authAxios.get(BASEURL + "member/CreateMudarisProfile/" + id),
    CreateMudarisProfileNew: (record) =>
      authAxios.post(BASEURL + "member/CreateMudarisProfileNew/", record),
    AddMudarisAssessment: (model) =>
      authAxios.post(BASEURL + "member/AddMudarisAssessment/", model),
    EditMudarisAssessment: (id) =>
      authAxios.get(BASEURL + "member/EditMudarisAssessment/" + id),
    UpdateMudarisAssessment: (model) =>
      authAxios.post(BASEURL + "member/UpdateMudarisAssessment/", model),
    DeleteMudarisAssessment: (id) =>
      authAxios.delete(BASEURL + "member/DeleteMudarisAssessment/" + id),
    AddMudarisRegistration: (model) =>
      authAxios.post(BASEURL + "member/AddMudarisRegistration/", model),
    EditMudarisRegistration: (id) =>
      authAxios.get(BASEURL + "member/EditMudarisRegistration/" + id),
    UpdateMudarisRegistration: (model) =>
      authAxios.post(BASEURL + "member/UpdateMudarisRegistration/", model),
    DeleteMudarisRegistration: (data) =>
      authAxios.post(BASEURL + "member/DeleteMudarisRegistration/", data),
    UndoDeleteMudarisRegistration: (id) =>
      authAxios.put(BASEURL + "member/UndoDeleteMudarisRegistration/" + id),
    GetRegistrationStatuses: () =>
      authAxios.get(BASEURL + "member/GetRegistrationStatuses/"),
    GetMudariseen: (record) =>
      authAxios.post(BASEURL + "member/GetMudariseen/", record),
    GetMudariseenExcelReport: (record) =>
      fileAuthAxios.post(BASEURL + "member/GetMudariseenExcelReport/", record),
  };
}
