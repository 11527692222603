import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Row, Col, FormGroup, Label } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { GetLanguageString } from "../helper/Components";
import { ApiResponsibility } from "../config/responsibilityType/ApiResponsibilityType";
import { GetSelectedLanguage } from "../helper/Method";
import { LoadingSpinner } from "../../constants/const";

const SelectResponsibility = ({
  values,
  setValues,
  id,
  unitId,
  errors,
  setErrors,
  previousResponsibility,
  selectedUnit,
  loading,
}) => {
  const [responsibility, setResponsibility] = useState([]);

  const [loadingResponsibility, setLoadingResponsibility] = useState(true);
  const initialResponsibilityValue =
    values[unitId].responsibilityList[id].value;

  const [selectedOption, setSelectedOption] = useState({
    value:
      initialResponsibilityValue !== 0
        ? values[unitId].responsibilityList[id].value
        : "",
    label:
      initialResponsibilityValue !== 0
        ? values[unitId].responsibilityList[id].label
        : "",
    isIncharge:
      initialResponsibilityValue !== 0
        ? values[unitId].responsibilityList[id].isIncharge
        : true,
  });
  const responsibilityOptions = [];
  responsibility.forEach((r) =>
    responsibilityOptions.push({
      label: r.type,
      value: r.id,
      isIncharge: r.isIncharge,
    })
  );

  useEffect(() => {
    ApiResponsibility()
      .getUnitResponsibilitiesByPermissions(selectedUnit)
      .then((res) => {
        setResponsibility(res.data);
        setLoadingResponsibility(false);
      })
      .catch((err) => console.log(err));
  }, [selectedUnit]);

  useEffect(() => {
    if (initialResponsibilityValue !== 0 || responsibility.length === 0) {
      setSelectedOption((prevSelectedOption) => ({
        ...prevSelectedOption,
        value: values[unitId].responsibilityList[id].value,
        label: values[unitId].responsibilityList[id].label,
        isIncharge: values[unitId].responsibilityList[id].isIncharge,
      }));
    } else {
      setSelectedOption((prevSelectedOption) => ({
        ...prevSelectedOption,
        value: "",
        label: "",
        isIncharge: true,
      }));
    }
  }, [
    selectedUnit,
    id,
    unitId,
    responsibility,
    values,
    initialResponsibilityValue,
  ]);

  const responsibilityAvailables = responsibility.length !== 0;

  useEffect(() => {
    setValues((prevValues) => {
      const tempValues = [...prevValues];
      tempValues[unitId].isAvailable = responsibilityAvailables;
      return tempValues;
    });
  }, [unitId, responsibilityAvailables, setValues]);

  const handleChangeResponsibility = (record) => {
    if (record !== null) {
      let tempValues = [...values];
      tempValues[unitId].responsibilityList[id].label = record.label;
      tempValues[unitId].responsibilityList[id].value = record.value;
      tempValues[unitId].responsibilityList[id].isIncharge = record.isIncharge;
      setValues(tempValues);
      setSelectedOption({
        value: values[unitId].responsibilityList[id].value,
        label: values[unitId].responsibilityList[id].label,
        isIncharge: values[unitId].responsibilityList[id].isIncharge,
      });
      setErrors({
        ...errors,
        responsibilityList: "",
      });
    }
  };

  const removeObject = () => {
    let tempValues = [...values];
    tempValues[unitId].responsibilityList[id].isDisabled = true;
    setValues(tempValues);
  };

  const handleInputChangeResponsibilityDate = (value) => {
    let tempValues = [...values];
    tempValues[unitId].responsibilityList[id].responsibilityDate = value;
    setValues(tempValues);
  };

  return (
    <>
      {!loading ? (
        <>
          {loadingResponsibility ? null : values[unitId].responsibilityList[id]
              .value === 0 && responsibilityOptions.length === 0 ? (
            <h4 className="text-error">
              {<GetLanguageString props="member_no_responsibility_available" />}
            </h4>
          ) : (
            <>
              <Row>
                <Col md="5">
                  <FormGroup>
                    <Label className="col-form-label">
                      {
                        <GetLanguageString props="member_select_responsibilities" />
                      }
                    </Label>
                    <Select
                      value={selectedOption}
                      className="basic-single ur"
                      placeholder={
                        GetSelectedLanguage() === "en" ? "Select" : "منتخب کریں"
                      }
                      options={responsibilityOptions}
                      onChange={handleChangeResponsibility}
                      isDisabled={
                        previousResponsibility !== 0 ||
                        responsibilityOptions.length === 0
                          ? true
                          : false
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label className="col-form-label">
                      {
                        <GetLanguageString props="member_member_detail_common_responsibility_date" />
                      }
                    </Label>
                    <DatePicker
                      showYearDropdown
                      placeholderText="dd/mm/yyyy"
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                      selected={
                        values[unitId].responsibilityList[id].responsibilityDate
                      }
                      disabled={
                        responsibilityOptions.length === 0 ? true : false
                      }
                      onChange={handleInputChangeResponsibilityDate}
                    />
                  </FormGroup>
                </Col>
                <Col md="1">
                  <i
                    className={"fa fa-2x fa-minus-square m-t-xl "}
                    style={{ cursor: "pointer", color: "red" }}
                    onClick={removeObject}
                  />
                </Col>
              </Row>
              <hr />
            </>
          )}
        </>
      ) : (
        LoadingSpinner()
      )}
    </>
  );
};

export default SelectResponsibility;
