import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Col,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Table,
} from "reactstrap";
import { GetLanguageString } from "../../../../helper/Components";

const Others = ({ othersSection, setOtherSection }) => {
  const [individualInvitationWorkStatus, setIndividualInvitationWorkStatus] =
    useState(othersSection.individualInvitationWorkStatus);
  const [extraActivities, setExtraActivities] = useState(
    othersSection.extraActivities
  );
  const [journals, setJournals] = useState(othersSection.journals);
  const [worthMentioningExtraActivities, setWorthMentioningExtraActivities] =
    useState(othersSection.worthMentioningExtraActivities);
  const [maktaba, setMaktaba] = useState(othersSection.maktaba);
  const [libraries, setLibraries] = useState(othersSection.libraries);

  const [tab1, setTab1] = useState("active");
  const [tab2, setTab2] = useState("");
  const [tab3, setTab3] = useState("");

  useEffect(() => {
    setOtherSection({
      ...othersSection,
      individualInvitationWorkStatus: individualInvitationWorkStatus,
      extraActivities: extraActivities,
      journals: journals,
      maktaba: maktaba,
      libraries: libraries,
      worthMentioningExtraActivities: worthMentioningExtraActivities,
    });
  }, [
    setOtherSection,
    othersSection,
    individualInvitationWorkStatus,
    extraActivities,
    journals,
    maktaba,
    libraries,
    worthMentioningExtraActivities,
  ]);

  const handleIndividualInvitationWorkStatus = (e) => {
    const { name, value } = e.target;
    setIndividualInvitationWorkStatus({
      ...individualInvitationWorkStatus,
      [name]: value,
    });
  };

  const handleIndividualInvitationWorkStatusChecks = (e) => {
    const { checked, name } = e.target;
    setIndividualInvitationWorkStatus({
      ...individualInvitationWorkStatus,
      [name]: checked,
    });

  };

  const handleExtraActivities = (e) => {
    const { name, value } = e.target;
    setExtraActivities({
      ...extraActivities,
      [name]: value,
    });
  };

  const handleJournals = (e) => {
    const { name, value } = e.target;
    setJournals({
      ...journals,
      [name]: value,
    });
  };

  const handleLibraries = (e) => {
    const { name, value } = e.target;
    setLibraries({
      ...libraries,
      [name]: value,
    });
  };

  const handleWorthMentioningExtraActivities = (e) => {
    const { value } = e.target;
    setWorthMentioningExtraActivities(value);
  };

  return (
    <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
      <div className="ibox panel panel-primary">
        <div className="ibox-content">
          <div className="tabs-container ibox-container">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={tab1}
                  onClick={() => {
                    setTab1("active");
                    setTab2("");
                    setTab3("");
                  }}
                >
                  <h3 className="font-bold ur">
                     انفرادی دعوتی کام کی کیفیت
                  </h3>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={tab2}
                  onClick={() => {
                    setTab1("");
                    setTab2("active");
                    setTab3("");
                  }}
                >
                  <h3 className="font-bold ur">نصرت</h3>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={tab3}
                  onClick={() => {
                    setTab1("");
                    setTab2("");
                    setTab3("active");
                  }}
                >
                  <h3 className="font-bold ur">دیگر</h3>
                </NavLink>
              </NavItem>
            </Nav>
            <div className="tab-content m ur">
              <div role="tabpanel" id="tab-1" className={"tab-pane " + tab1}>
                <Row>
                  <Col md="12">
                    <div className="panel panel-info">
                      <div className="panel-heading">
                        <h2 className="text-center font-bold ">
                          انفرادی دعوتی کام کی کیفیت
                        </h2>
                      </div>
                      <div className="ibox-content">
                        <Table responsive> bordered>
                          <thead>
                            <tr>
                              <th className="text-center">
                                <h3>حصہ لینے والے رفقاء</h3>
                              </th>
                              <th className="text-center">
                                <h3>زیر دعوت احباب (کل)</h3>
                              </th>
                              <th className="text-center">
                                <h3>مرحلہ نمبر 1 تمسک بالقران (حلقہ قرانی)</h3>
                              </th>
                              <th className="text-center">
                                <h3>مرحلہ نمبر 2 دوعوت ایمان (دعوتی نصاب)</h3>
                              </th>
                              <th className="text-center">
                                <h3>مرحلہ نمبر 3 دعوت دین (فہم دین)</h3>
                              </th>
                              <th className="text-center">
                                <h3>مرحلہ نمبر 4 (دعوت تنظیم)</h3>
                              </th>
                              <th className="text-center">
                                <h3>رفقاء کا کتنوں سے دوران ماہ رابطہ رہا؟</h3>
                              </th>
                              <th className="text-center">
                                <h3>احباب کی پیش رفت کا جائزہ لیا؟</h3>
                              </th>
                              <th className="text-center">
                                <h3>
                                  اشکالات و مشکلات پر مشورہ و معاونت کا اہتمام
                                  کیا گیا؟
                                </h3>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <Input
                                  className="text-center"
                                  type="text"
                                  name="totalParticipants"
                                  value={
                                    individualInvitationWorkStatus.totalParticipants
                                  }
                                  onChange={
                                    handleIndividualInvitationWorkStatus
                                  }
                                />
                              </td>
                              <td>
                                <Input
                                  className="text-center"
                                  type="text"
                                  name="underInvitationAhbab"
                                  value={
                                    individualInvitationWorkStatus.underInvitationAhbab
                                  }
                                  onChange={
                                    handleIndividualInvitationWorkStatus
                                  }
                                />
                              </td>
                              <td>
                                <Input
                                  className="text-center"
                                  type="text"
                                  name="ahbabStep1"
                                  value={
                                    individualInvitationWorkStatus.ahbabStep1
                                  }
                                  onChange={
                                    handleIndividualInvitationWorkStatus
                                  }
                                />
                              </td>
                              <td>
                                <Input
                                  className="text-center"
                                  type="text"
                                  name="ahbabStep2"
                                  value={
                                    individualInvitationWorkStatus.ahbabStep2
                                  }
                                  onChange={
                                    handleIndividualInvitationWorkStatus
                                  }
                                />
                              </td>
                              <td>
                                <Input
                                  className="text-center"
                                  type="text"
                                  name="ahbabStep3"
                                  value={
                                    individualInvitationWorkStatus.ahbabStep3
                                  }
                                  onChange={
                                    handleIndividualInvitationWorkStatus
                                  }
                                />
                              </td>
                              <td>
                                <Input
                                  className="text-center"
                                  type="text"
                                  name="ahbabStep4"
                                  value={
                                    individualInvitationWorkStatus.ahbabStep4
                                  }
                                  onChange={
                                    handleIndividualInvitationWorkStatus
                                  }
                                />
                              </td>
                              <td>
                                <Input
                                  className="text-center"
                                  type="text"
                                  name="membersMeetingHappens"
                                  value={
                                    individualInvitationWorkStatus.membersMeetingHappens
                                  }
                                  onChange={
                                    handleIndividualInvitationWorkStatus
                                  }
                                />
                              </td>
                              <td className="text-center">
                                <Input
                                  className="text-center"
                                  type="checkbox"
                                  checked={
                                    individualInvitationWorkStatus.ahbabProgressChecked
                                  }
                                  name="ahbabProgressChecked"
                                  value={
                                    individualInvitationWorkStatus.ahbabProgressChecked
                                  }
                                  onChange={(e) =>
                                    handleIndividualInvitationWorkStatusChecks(
                                      e
                                    )
                                  }
                                />
                              </td>
                              <td className="text-center">
                                <Input
                                  className="text-center"
                                  type="checkbox"
                                  checked={
                                    individualInvitationWorkStatus.discussionSessions
                                  }
                                  name="discussionSessions"
                                  value={
                                    individualInvitationWorkStatus.discussionSessions
                                  }
                                  onChange={(e) =>
                                    handleIndividualInvitationWorkStatusChecks(
                                      e
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="tab-content m ur">
              <div role="tabpanel" id="tab-2" className={"tab-pane " + tab2}>
                <Row>
                  <Col md="12">
                    <div className="panel panel-info ">
                      <div className="panel-heading">
                        <h2 className="text-center font-bold">نصرت</h2>
                        <h3 className="text-center">
                          مقامی تنظیم، حلقہ یا مرکز کے تحت ہونے والے اضافی
                          اجتماعات / مہمات / پروگراموں میں اُسرے کے رفقاء و
                          احباب کی طرف سے شرکت
                        </h3>
                      </div>
                      <div className="panel-body">
                        <Table responsive> bordered>
                          <thead>
                            <tr>
                              <th rowSpan={"2"}></th>
                              <th rowSpan={"2"} className="text-center">
                                <h3> انعقاد کرنے والے</h3>
                                <h4>(مقامی/حلقہ/علاقہ/مرکز)</h4>
                              </th>
                              <th rowSpan={"2"} className="text-center">
                                <h3>تفصیل و نوعیت</h3>
                              </th>
                              <th colSpan={"3"} className="text-center">
                                <h3>اسرہ جات کی طرف سے نصرت</h3>
                              </th>
                            </tr>
                            <tr>
                              <th className="text-center">ملتزم</th>
                              <th className="text-center">مبتدی</th>
                              <th className="text-center">احباب</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <h3 className="text-center">
                                  توسیع دعوت و تعارف
                                </h3>
                                <h4 className="text-center">
                                  دورہ ترجمہ قرآن/ خلاصہمضامین قرآن /
                                  اجتماعات/کیمپ/جلسہ/کرٹرمیٹنگ/ایک/دو/سہ روزہ
                                  وغیرہ
                                </h4>
                              </td>
                              <td>
                                <Input
                                  className="text-center"
                                  type="text"
                                  name="extendedInvitationIntroductionConductedUnit"
                                  value={
                                    extraActivities.extendedInvitationIntroductionConductedUnit
                                  }
                                  onChange={handleExtraActivities}
                                />
                              </td>
                              <td>
                                <Input
                                  className="text-center"
                                  type="text"
                                  name="extendedInvitationIntroductionDetails"
                                  value={
                                    extraActivities.extendedInvitationIntroductionDetails
                                  }
                                  onChange={handleExtraActivities}
                                />
                              </td>
                              <td>
                                <Input
                                  className="text-center"
                                  type="text"
                                  name="extendedInvitationIntroductionExcusedMultazim"
                                  value={
                                    extraActivities.extendedInvitationIntroductionExcusedMultazim
                                  }
                                  onChange={handleExtraActivities}
                                />
                              </td>
                              <td>
                                <Input
                                  className="text-center"
                                  type="text"
                                  name="extendedInvitationIntroductionExcusedMubtadi"
                                  value={
                                    extraActivities.extendedInvitationIntroductionExcusedMubtadi
                                  }
                                  onChange={handleExtraActivities}
                                />
                              </td>
                              <td>
                                <Input
                                  className="text-center"
                                  type="text"
                                  name="extendedInvitationIntroductionExcusedAhbab"
                                  value={
                                    extraActivities.extendedInvitationIntroductionExcusedAhbab
                                  }
                                  onChange={handleExtraActivities}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h3 className="text-center">
                                  امر بالمعروف و نہی عن المنکر
                                </h3>
                                <h3 className="text-center">(باللسان)</h3>
                                <h3 className="text-center">
                                  (تقسیم ہینڈ بل/مہمات/مظاہرہ وغیرہ)
                                </h3>
                              </td>
                              <td>
                                <Input
                                  className="text-center"
                                  type="text"
                                  name="activitiesForDoingGoodForbiddingBadConductedUnit"
                                  value={
                                    extraActivities.activitiesForDoingGoodForbiddingBadConductedUnit
                                  }
                                  onChange={handleExtraActivities}
                                />
                              </td>
                              <td>
                                <Input
                                  className="text-center"
                                  type="text"
                                  name="activitiesForDoingGoodForbiddingBadDetails"
                                  value={
                                    extraActivities.activitiesForDoingGoodForbiddingBadDetails
                                  }
                                  onChange={handleExtraActivities}
                                />
                              </td>
                              <td>
                                <Input
                                  className="text-center"
                                  type="text"
                                  name="activitiesForDoingGoodForbiddingBadMultazim"
                                  value={
                                    extraActivities.activitiesForDoingGoodForbiddingBadMultazim
                                  }
                                  onChange={handleExtraActivities}
                                />
                              </td>
                              <td>
                                <Input
                                  className="text-center"
                                  type="text"
                                  name="activitiesForDoingGoodForbiddingBadMubtadi"
                                  value={
                                    extraActivities.activitiesForDoingGoodForbiddingBadMubtadi
                                  }
                                  onChange={handleExtraActivities}
                                />
                              </td>
                              <td>
                                <Input
                                  className="text-center"
                                  type="text"
                                  name="activitiesForDoingGoodForbiddingBadAhbab"
                                  value={
                                    extraActivities.activitiesForDoingGoodForbiddingBadAhbab
                                  }
                                  onChange={handleExtraActivities}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="tab-content">
              <div role="tabpanel" id="tab-3" className={"tab-pane " + tab3}>
                <Row className="m ">
                  <Col md="6">
                    <div className="ibox panel panel-info ur">
                      <div className="panel-heading">
                        <h2 className="text-center font-bold">جرائد</h2>
                        <h3 className="text-center font-bold">
                          مستقل خریدار (اسرہ میں اجتماعی منگوانا،رفقاء و احباب
                          کا انفرادی لگوانا)
                        </h3>
                      </div>
                      <div className="panel-body">
                        <Table responsive> size="sm">
                          <thead>
                            <tr>
                              <th></th>
                              <th className="text-center">
                                <h4>{"ملتزم "}</h4>
                              </th>
                              <th className="text-center">
                                <h4>{"مبتدی "}</h4>
                              </th>
                              <th className="text-center">
                                <h4>{"احباب "}</h4>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <h3 className="text-center">ندائے خلافت</h3>
                              </td>
                              <td>
                                <Input
                                  className="text-center"
                                  type="text"
                                  name="nidayeKhilafatMultazim"
                                  value={journals.nidayeKhilafatMultazim}
                                  onChange={handleJournals}
                                />
                              </td>
                              <td>
                                <Input
                                  className="text-center"
                                  type="text"
                                  name="nidayeKhilafatMubtadi"
                                  value={journals.nidayeKhilafatMubtadi}
                                  onChange={handleJournals}
                                />
                              </td>
                              <td>
                                <Input
                                  className="text-center"
                                  type="text"
                                  name="nidayeKhilafatAhbab"
                                  value={journals.nidayeKhilafatAhbab}
                                  onChange={handleJournals}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="col-3">
                                <h3 className="text-center">{"میثاق"}</h3>
                              </td>
                              <td>
                                <Input
                                  className="text-center"
                                  type="text"
                                  name="mesaaqMultazim"
                                  value={journals.mesaaqMultazim}
                                  onChange={handleJournals}
                                />
                              </td>
                              <td>
                                <Input
                                  className="text-center"
                                  type="text"
                                  name="mesaaqMubtadi"
                                  value={journals.mesaaqMubtadi}
                                  onChange={handleJournals}
                                />
                              </td>
                              <td>
                                <Input
                                  className="text-center"
                                  type="text"
                                  name="mesaaqAhbab"
                                  value={journals.mesaaqAhbab}
                                  onChange={handleJournals}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                    <div className="ibox panel panel-info ur">
                      <div className="panel-heading">
                        <h2 className="text-center font-bold">
                          دیگر قابل ذکر امور
                        </h2>
                      </div>
                      <div className="panel-body">
                        <Label className="col-form-label">
                          <h3>
                            اگر اسرہ کی سطح پر کوئی تدریسی پروگرام /کورس ہو رہا
                            ہے تو اس کی تفصیل لکھیں (نوعیت/اوقات/تسلسل/شرکاء)
                          </h3>
                        </Label>
                        <Input
                          type="textarea"
                          name="worthMentioningExtraActivities"
                          value={othersSection.worthMentioningExtraActivities}
                          onChange={handleWorthMentioningExtraActivities}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="ibox panel panel-info">
                      <div className="panel-heading">
                        <h2 className="text-center font-bold ur">مکتبہ</h2>
                      </div>
                      <div className="panel-body">
                        <Row>
                          <Row>
                            <Col md="6" className="ur">
                              <h4>{"کیا کوئی مکتبہ/سیل پوائنٹ قائم ہے؟"}</h4>
                            </Col>
                            <Col md="3">
                              <Input
                                className="text-center"
                                type="radio"
                                checked={
                                  maktaba.isMaktabaEstablished ? true : false
                                }
                                value={!maktaba.isMaktabaEstablished}
                                onChange={() =>
                                  setMaktaba({
                                    ...maktaba,
                                    isMaktabaEstablished:
                                      !maktaba.isMaktabaEstablished,
                                  })
                                }
                              />{" "}
                              {<GetLanguageString props="Yes" />}
                            </Col>
                            <Col md="3">
                              <Input
                                className="text-center"
                                type="radio"
                                checked={
                                  maktaba.isMaktabaEstablished ? false : true
                                }
                                value={!maktaba.isMaktabaEstablished}
                                onChange={() =>
                                  setMaktaba({
                                    ...maktaba,
                                    isMaktabaEstablished:
                                      !maktaba.isMaktabaEstablished,
                                  })
                                }
                              />{" "}
                              {<GetLanguageString props="No" />}
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6" className="ur">
                              <h4>{"کیا آپ نے کوئی اسٹال لگائے؟"}</h4>
                            </Col>
                            <Col md="3">
                              <Input
                                className="text-center"
                                type="radio"
                                checked={maktaba.isStallPlaced ? true : false}
                                value={!maktaba.isStallPlaced}
                                onChange={() =>
                                  setMaktaba({
                                    ...maktaba,
                                    isStallPlaced: !maktaba.isStallPlaced,
                                  })
                                }
                              />{" "}
                              {<GetLanguageString props="Yes" />}
                            </Col>
                            <Col md="3">
                              <Input
                                className="text-center"
                                type="radio"
                                checked={maktaba.isStallPlaced ? false : true}
                                value={!maktaba.isStallPlaced}
                                onChange={() =>
                                  setMaktaba({
                                    ...maktaba,
                                    isStallPlaced: !maktaba.isStallPlaced,
                                  })
                                }
                              />{" "}
                              {<GetLanguageString props="No" />}
                            </Col>
                          </Row>
                        </Row>
                      </div>
                    </div>
                    <div className="ibox panel panel-info">
                      <div className="panel-heading ur">
                        <h2 className="text-center font-bold">لائبریری</h2>
                      </div>
                      <div className="panel-body">
                        <Row>
                          <Col md="6" className="ur">
                            <h4>{"کیا کوئی لائبریری قائم ہے؟"}</h4>
                          </Col>
                          <Col md="3">
                            <Input
                              className="text-center"
                              type="radio"
                              checked={
                                libraries.isLibraryEstablished ? true : false
                              }
                              value={!libraries.isLibraryEstablished}
                              onChange={() =>
                                setLibraries({
                                  ...libraries,
                                  isLibraryEstablished:
                                    !libraries.isLibraryEstablished,
                                })
                              }
                            />{" "}
                            {<GetLanguageString props="Yes" />}
                          </Col>
                          <Col md="3">
                            <Input
                              className="text-center"
                              type="radio"
                              checked={
                                libraries.isLibraryEstablished ? false : true
                              }
                              value={!libraries.isLibraryEstablished}
                              onChange={() =>
                                setLibraries({
                                  ...libraries,
                                  isLibraryEstablished:
                                    !libraries.isLibraryEstablished,
                                })
                              }
                            />{" "}
                            {<GetLanguageString props="No" />}
                          </Col>
                        </Row>
                      </div>
                      <div className="ibox-content">
                        <Row>
                          <Col md="4">
                            <h4 className="text-center ur">{"ممبران کی تعداد "}</h4>
                            <Input
                              className="text-center"
                              type="text"
                              name="rufaqaUsing"
                              value={libraries.rufaqaUsing}
                              onChange={handleLibraries}
                            />
                          </Col>
                          <Col md="4">
                            <h4 className="text-center ur">{"استفادہ کرنے والے رفقاء "}</h4>
                            <Input
                              className="text-center"
                              type="text"
                              name="membersCount"
                              value={libraries.membersCount}
                              onChange={handleLibraries}
                            />
                          </Col>
                          <Col md="4">
                            <h4 className="text-center ur">{" استفادہ کرنے والے احباب"}</h4>
                            <Input
                              className="text-center"
                              type="text"
                              name="ahbabUsing"
                              value={libraries.ahbabUsing}
                              onChange={handleLibraries}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Others;
