import React, { useState } from "react";
import { encryptStorage } from "../../../constants/EncryptStorage";
import moment from "moment";
import { GetLanguageString,
        NoResult,} from "../../helper/Components";
import { useEffect } from "react";
import { ApiEvent } from "../ApiEvent";
import { Table } from "reactstrap";
import { LoadingSpinner,convertUTCToLocalTime } from "../../../constants/const";
import SearchEventsWithNoAttendance from "./SearchEventsWithNoAttendance";

const EventReportWithNoAttendance = () => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [loading, setLoading] = useState(false);
  const [searchButton, setSearchButton] = useState(false);
  const [events,setEvents] =useState([])

  let tempDate = new Date();
  tempDate.setUTCDate(1);
  const [searchEventAttendanceModel, setSearchEventAttendanceModel] =
    useState({
      unit: { value: loggedInUnitId, lable: "" },
      isSubUnits: true,
      startDate: tempDate,
      endDate: new Date(),
      eventTypeIds: [],
    });

  useEffect(() => {
    if (searchButton) {
      setLoading(true);
      ApiEvent()
        .getEventsReportWithNoAttendance(searchEventAttendanceModel)
        .then((res) => {
          setEvents(res.data);

          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [searchButton, searchEventAttendanceModel]);


  return (
    <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
      <div className="ibox">
        <div className="ibox-title">
          <h5>{<GetLanguageString props="common_generate_report" />}</h5>
        </div>
        <SearchEventsWithNoAttendance
          searchEventAttendanceModel={searchEventAttendanceModel}
          setSearchEventAttendanceModel={setSearchEventAttendanceModel}
          setSearchButton={setSearchButton}
        />
      </div>
      {searchButton ? (
        <div className="ibox">
          <div className="ibox-title">
            <h5>{<GetLanguageString props="quick_event_reports_button4" />}</h5>
          </div>
          <div className="ibox-content">
            {!loading ? (
              events.length !== 0 ? (
                <div
                  className="panel-body ibox-center"
                >
                  <Table className="table-stripped table-bordered footable">
                    <thead>
                      <tr>
                      <th>#</th>
                      <th>
                            {
                              <GetLanguageString props="events_report_event_name" />
                            }
                      </th>
                      <th>
                            {<GetLanguageString props="events_address" />}
                          </th>
                          <th> 
                            <GetLanguageString props="events_date" />
                          </th>
                          <th>
                            {" "}
                            {<GetLanguageString props="events_description" />}
                          </th>
                          <th>
                            <GetLanguageString props="events_unit" />
                          </th>
                      </tr>
                    </thead>
                    <tbody>
                      {events?.map((record, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td className="ur">{record.eventType.label}</td>
                              <td className="ur">{record.address}</td>
                              <td>
                                {moment(convertUTCToLocalTime(record.date)).format(
                                  "DD/MM/yyyy  h:mm a"
                                )}
                              </td>
                              <td className="ur">{record.description}</td>
                              <td className="ur">{record.unit.label}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <NoResult />
              )
            ) : (
              LoadingSpinner()
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default EventReportWithNoAttendance