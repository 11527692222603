import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { TagsInput } from "react-tag-input-component";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import swal from "sweetalert";
import { requestApi } from "../../../actions/api";
import {
  LoadingSpinner,
  isAlphaNumeric,
  isCodeUniqueEdit,
} from "../../../constants/const";
import { GetLanguageString } from "../../helper/Components";
import PickColor from "../PickColor";
import { ApiUnitType } from "../unitType/ApiUnitType";
import { ApiEventType } from "./ApiEventType";
import { GetSelectedLanguage } from "../../helper/Method";

const EditEventType = ({ editValue, setEditModal, setEventType }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [codeList, setCodeList] = useState([]);
  const [prevCode, setPrevCode] = useState();
  const [eventTypeValues, setEventTypeValues] = useState({
    type: "",
    code: "",
    prevCode: "",
    unitType: [],
    frequency: {},
    allowAhbab: false,
    forChildUnit: false,
    defaultParticipantsFilters: [],
    color: "",
    isEnabled: true,
  });

  const [color, setColor] = useState("");
  const [errors, setErrors] = useState(eventTypeValues);
  const [unitType, setUnitType] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const toggle = () => setEditModal(false);
  const [staticFrequencyType, setStaticFrequencyType] = useState([]);
  const [selectedFrequencyType, setSelectedFrequencyType] = useState({});
  const [defaultParticipantsFilters, setDefaultParticipantsFilters] = useState(
    []
  );
  const options = [];
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  useEffect(() => {
    setLoading(true);
    ApiUnitType()
      .getUnitType()
      .then((res) => {
        setUnitType(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    requestApi("eventtype/")
      .fetchById(editValue)
      .then((res) => {
        setEventTypeValues(res.data);
        setPrevCode(res.data.code);
        if (res.data.color !== null) setColor(res.data.color);
        if (res.data.unitType !== null) setSelectedOptions(res.data.unitType);
        setStaticFrequencyType(res.data.frequencyList);
        setSelectedFrequencyType(res.data.frequency);
        setDefaultParticipantsFilters(
          res.data.defaultParticipantsFilters.toString().split(",")
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    ApiEventType()
      .getEventTypeCode()
      .then((res) => {
        setCodeList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [editValue]);

  unitType.forEach((d) => options.push({ value: d.id, label: d.type }));

  const validate = () => {
    let temp = {};
    temp.type = eventTypeValues.type ? "" : t("settings_name_error_message");
    temp.code = eventTypeValues.code ? (
      isAlphaNumeric(eventTypeValues.code) ? (
        isCodeUniqueEdit(codeList, prevCode, eventTypeValues.code) ? (
          ""
        ) : (
          <GetLanguageString props="settings_code_unique_error_message" />
        )
      ) : (
        <GetLanguageString props="settings_code_aplha_numeric_error_message" />
      )
    ) : (
      <GetLanguageString props="settings_code_error_message" />
    );
    temp.unitType =
      Object.keys(selectedOptions).length !== 0
        ? ""
        : t("settings_select_unit_type_error");

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleChangeUnitType = (record) => {
    setSelectedOptions(record);
    setErrors({
      ...errors,
      unitType: "",
    });
  };

  const handleChangeStaticFrequencyType = (record) => {
    if (record === null) setSelectedFrequencyType({});
    else setSelectedFrequencyType(record);
    setErrors({
      ...errors,
      frequency: {},
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setErrors({
      ...errors,
      type: "",
    });
    setEventTypeValues({
      ...eventTypeValues,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    eventTypeValues.prevCode = prevCode;
    eventTypeValues.unitType = selectedOptions;
    eventTypeValues.frequency = selectedFrequencyType;
    eventTypeValues.defaultParticipantsFilters =
      defaultParticipantsFilters.toString();
    eventTypeValues.color = color;
    if (validate()) {
      setLoading(true);
      requestApi("eventtype/")
        .update(editValue, eventTypeValues)
        .then((res) => {
          requestApi("eventtype/")
            .fetchAll()
            .then((res) => {
              toggle();
              setEventType(res.data);
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        })
        .catch((err) => {
          toggle();
          swal({
            title: err,
            text: err.response.data,
            icon: "error",
            buttons: "OK",
          });
          setLoading(false);
          console.log(`Error Msg: ${err.response.data}, ${err}`);
        });
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="settings_edit_event_type" />}
          </ModalHeader>
          {!loading ? (
            <Form onSubmit={handleSubmit}>
              <ModalBody>
                <FormGroup>
                  <Label>{<GetLanguageString props="common_name" />}</Label>
                  <Input
                    autoFocus
                    type="text"
                    name="type"
                    className="ur no-drag"
                    value={eventTypeValues.type}
                    onChange={handleInputChange}
                    {...(errors.type && { invalid: true })}
                  />
                  {errors.type && (
                    <FormFeedback>
                      <h4>{errors.type}</h4>
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label>{<GetLanguageString props="settings_code" />}</Label>
                  <Input
                    type="text"
                    name="code"
                    className="ur no-drag"
                    value={eventTypeValues.code}
                    onChange={handleInputChange}
                    {...(errors.code && { invalid: true })}
                  />
                  {errors.code && (
                    <FormFeedback>
                      <h4>{errors.code}</h4>
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label>
                    {<GetLanguageString props="settings_unit_type" />}
                  </Label>
                  <Select
                    value={selectedOptions}
                    options={options}
                    className="basic-single ur no-drag"
                    classNamePrefix="select"
                    onChange={handleChangeUnitType}
                  />
                  {errors?.unitType && (
                    <h4 className="text-error">{errors?.unitType}</h4>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label>
                    {
                      <GetLanguageString props="settings_event_type_frequency" />
                    }
                  </Label>
                  <Select
                    value={selectedFrequencyType}
                    options={staticFrequencyType}
                    className="basic-single no-drag"
                    classNamePrefix="select"
                    onChange={handleChangeStaticFrequencyType}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    {
                      <GetLanguageString props="settings_event_type_default_participants_filters" />
                    }
                  </Label>
                  <TagsInput
                    value={defaultParticipantsFilters}
                    onChange={setDefaultParticipantsFilters}
                    name="defaultParticipantsFilters"
                    className="no-drag"
                  />
                </FormGroup>
                <FormGroup check>
                  <Label check for="allowAhbab">
                    {" "}
                    <span
                      style={{
                        float:
                          GetSelectedLanguage() === "ur" ? "right" : "left",
                        marginLeft:
                          GetSelectedLanguage() === "ur" ? "24px" : "0px",
                      }}
                    >
                      <Input
                        id="allowAhbab"
                        type="checkbox"
                        name="allowAhbab"
                        value={!eventTypeValues.allowAhbab}
                        onChange={handleInputChange}
                        checked={eventTypeValues.allowAhbab}
                      />
                    </span>
                    {
                      <GetLanguageString props="settings_event_type_allow_ahbab" />
                    }
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check for="forChildUnit">
                    {" "}
                    <span
                      style={{
                        float:
                          GetSelectedLanguage() === "ur" ? "right" : "left",
                        marginLeft:
                          GetSelectedLanguage() === "ur" ? "24px" : "0px",
                      }}
                    >
                      <Input
                        id="forChildUnit"
                        type="checkbox"
                        name="forChildUnit"
                        value={!eventTypeValues.forChildUnit}
                        onChange={handleInputChange}
                        checked={eventTypeValues.forChildUnit}
                      />
                    </span>
                    {<GetLanguageString props="events_for_child_unit" />}
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check for="isEnabled">
                    {" "}
                    <span
                      style={{
                        float:
                          GetSelectedLanguage() === "ur" ? "right" : "left",
                        marginLeft:
                          GetSelectedLanguage() === "ur" ? "24px" : "0px",
                      }}
                    >
                      <Input
                        id="isEnabled"
                        type="checkbox"
                        checked={eventTypeValues.isEnabled}
                        name="isEnabled"
                        value={!eventTypeValues.isEnabled}
                        onChange={handleInputChange}
                      />
                    </span>
                    {<GetLanguageString props="member_address_enabled" />}
                  </Label>
                </FormGroup>
                <PickColor setColor={setColor} color={color} />
              </ModalBody>
              <ModalFooter>
                <Button color="white" onClick={toggle}>
                  {<GetLanguageString props="common_cancel" />}
                </Button>
                <Button color="primary" type="submit">
                  {<GetLanguageString props="common_save_changes" />}
                </Button>
              </ModalFooter>
            </Form>
          ) : (
            LoadingSpinner()
          )}
        </Modal>
      </Draggable>
    </div>
  );
};

export default EditEventType;
