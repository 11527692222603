import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
  Row,
  Col
} from "reactstrap";
import { useTranslation } from "react-i18next";

import { requestApi } from "../../../actions/api";
import { GetLanguageString } from "../../helper/Components";
import { LoadingSpinner } from "../../../constants/const";
import Draggable from "react-draggable";
import PickColor from "../PickColor";

const EditMemberType = ({ editValue, setEditModal, reload, setReload }) => {
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("");
  const { t } = useTranslation();
  const [memberTypeValues, setMemberTypeValues] = useState({
    type: "",
    code: "",
    gender:"",
    color:"",
  });
  const [errors, setErrors] = useState(memberTypeValues);
  const toggle = () => setEditModal(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  useEffect(() => {
    setLoading(true);
    requestApi("membertype/")
      .fetchById(editValue)
      .then((res) => {
        setColor(res.data.color)
        setMemberTypeValues(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [editValue]);

  const validate = () => {
    let temp = {};
    temp.type = memberTypeValues.type ? "" : t("settings_name_error_message");
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setErrors({
      type: "",
    });
    setMemberTypeValues({
      ...memberTypeValues,
      [name]: value,
    });
  };

  const handleGenderStatus = (e) => {
    const value = e.target.value;
    let gender = "";
    if (value === "0"){
      gender = "m";
    }
    else if (value === "1") {
      gender = "f";
    }

    setMemberTypeValues({
      ...memberTypeValues,
      gender: gender,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    memberTypeValues.color = color;
    if (validate()) {
      setLoading(true);
      requestApi("membertype/")
        .update(editValue, memberTypeValues)
        .then((res) => {
          toggle();
          setReload(!reload)
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="settings_edit_member_type" />}
          </ModalHeader>
          {!loading ? (
            <Form onSubmit={handleSubmit}>
              <ModalBody>
                <FormGroup>
                  <Label>{<GetLanguageString props="common_name" />}</Label>
                  <Input
                    autoFocus
                    type="text"
                    name="type"
                    className="ur no-drag"
                    value={memberTypeValues.type}
                    onChange={handleInputChange}
                    {...(errors.type && { invalid: true })}
                  />
                  {errors.type && (
                    <FormFeedback>
                      <h4>{errors.type}</h4>
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label>
                    {<GetLanguageString props="settings_code" />}
                  </Label>
                  <Input
                    type="text"
                    name="code"
                    className="no-drag"
                    value={memberTypeValues.code}
                    onChange={handleInputChange}
                    disabled
                  />
                </FormGroup>
                <FormGroup>
                  <Label>{<GetLanguageString props="settings_gender" />}</Label>
                  <Row className=" m-t-sm">
                    <Col md="2">
                      <FormGroup check>
                        <Label check for="male">
                          <Input
                            id="male"
                            type="radio"
                            checked={memberTypeValues.gender === 'm'}
                            value={0}
                            onClick={handleGenderStatus}
                          />
                          {<GetLanguageString props="settings_gender_male" />}
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup check>
                        <Label check for="female">
                          <Input
                            id="female"
                            type="radio"
                            checked={memberTypeValues.gender === 'f'}
                            value={1}
                            onClick={handleGenderStatus}
                          />
                          {<GetLanguageString props="settings_gender_female" />}
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </FormGroup>
                <PickColor setColor={setColor} color={color} />
              </ModalBody>
              <ModalFooter>
                <Button color="white" onClick={toggle}>
                  {<GetLanguageString props="common_cancel" />}
                </Button>
                <Button color="primary" type="submit">
                  {<GetLanguageString props="common_save_changes" />}
                </Button>
              </ModalFooter>
            </Form>
          ) : (
            LoadingSpinner()
          )}
        </Modal>
      </Draggable>
    </div>
  );
};

export default EditMemberType;
