import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useLocation, withRouter } from "react-router-dom";
import Select from "react-select";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";

import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import { convertToDateString, LoadingSpinner } from "../../constants/const";
import { GetLanguageString, NoResult } from "../helper/Components";
import { GetCustomStyles, GetMonths, GetSelectedLanguage, GetYears } from "../helper/Method";
import { ApiFund } from "./ApiFund";

const AddBulkFund = ({ history }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const unitId = location.state?.unitId;
  const membersList = location.state?.membersList;
  const [loading, setLoading] = useState(false);
  let months = GetMonths();
  let years = GetYears();
  const [selectedYear, setSelectedYear] = useState({});
  const [selectedMonth, setSelectedMonth] = useState({});
  const [fundValues, setFundValues] = useState({});
  const [fundValuesList, setFundValuesList] = useState([]);
  const [selectedDatesList, setSelectedDatesList] = useState([]);
  const [serverError, setServerError] = useState("");
  const [errors, setErrors] = useState({
    month: "",
    year: "",
  });

  useEffect(() => {
    let currentDate = new Date();
    let tempYear = currentDate.getFullYear();
    let tempMonth = currentDate.getMonth() + 1;
    let monthList = GetMonths();
    monthList.forEach((m) =>
      m.value === tempMonth ? setSelectedMonth(m) : null
    );
    setSelectedYear({ value: tempYear, label: tempYear });
    setFundValues({
      unitId: unitId,
      month: tempMonth,
      year: tempYear,
      funds: [],
    });
  }, [unitId]);

  useEffect(() => {
    if (membersList?.length !== 0) {
      let currentDate = new Date();
      let temp = [];
      let tempDates = [];
      membersList.forEach((record) => {
        temp.push({
          memberId: record?.value,
          memberName: record?.label,
          receiptNumber: "",
          amount: 0,
          date: currentDate,
        });

        tempDates.push({
          memberId: record?.value,
          date: currentDate,
        });
      });

      setSelectedDatesList(tempDates);
      setFundValuesList(temp);
    }
  }, [membersList]);

  const validate = () => {
    let temp = {};
    temp.month = fundValues.month ? (
      ""
    ) : (
      <GetLanguageString props="funds_add_fund_select_month_error" />
    );
    temp.year = fundValues.year ? (
      ""
    ) : (
      <GetLanguageString props="funds_add_fund_select_year_error" />
    );

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleMonth = (record) => {
    setFundValues({
      ...fundValues,
      month: record.value,
    });
    setSelectedMonth(record);
    setErrors({
      month: "",
    });
  };

  const handleYears = (record) => {
    setFundValues({
      ...fundValues,
      year: record.value,
    });
    setSelectedYear(record);
    setErrors({
      year: "",
    });
  };

  const handleInputChangeDate = (value, index) => {
    let tempValues = [...selectedDatesList];
    tempValues[index].date = value;

    setSelectedDatesList(tempValues);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    let tempValues = [...fundValuesList];
    if (!tempValues[index]) {
      tempValues[index] = {}; // Create an empty object if it doesn't exist
    }
    tempValues[index][name] = value;

    setFundValuesList(tempValues);
  };

  const handleSubmit = () => {
    if (validate()) {
      setLoading(true);
      const updatedFunds = fundValuesList.map((fund) => {
        const matchingDate = selectedDatesList.find(
          (date) => date.memberId === fund.memberId
        );
        return {
          ...fund,
          date: matchingDate ? convertToDateString(matchingDate.date) : null,
        };
      });
      fundValues.funds = updatedFunds;
      ApiFund()
        .addBulkFund(fundValues)
        .then((res) => {
          swal({
            title: t("member_member_detail_fund_added"),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "success",
            buttons: "OK",
          });
          setLoading(false);
          history.goBack();
        })
        .catch((err) => {
          if (err.response.data.length < 100) {
            setServerError(err.response.data);
          }
          swal({
            title: t("member_member_detail_fund_added_error"),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "error",
            buttons: "OK",
          });
          console.log(err.response.data);
          setLoading(false);
        });
    }
  };

  return (
    <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
      <div className="ibox ">
        <div className="ibox-title">
          <h5>{<GetLanguageString props="funds_add_bulk_fund" />}</h5>
        </div>
        {!loading ? (
          <Form>
            <div className="ibox-content">
              {serverError && <h4 className="text-danger">{serverError}</h4>}
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label className="col-form-label">
                      {
                        <GetLanguageString props="funds_add_fund_select_month" />
                      }
                    </Label>
                    <Select
                      value={selectedMonth}
                      className="basic-single no-drag"
                      classNamePrefix="select"
                      options={months}
                      onChange={handleMonth}
                    />
                    {errors.month && (
                      <h4 className="text-error">{errors.month}</h4>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="funds_add_fund_select_year" />}
                    </Label>
                    <Select
                      value={selectedYear}
                      className="basic-single no-drag"
                      classNamePrefix="select"
                      options={years}
                      onChange={handleYears}
                    />
                    {errors.year && (
                      <h4 className="text-error">{errors.year}</h4>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              {fundValuesList.length !== 0 ? (
                <Table>
                  <thead>
                    <tr style={{ borderBottom: "1px solid black" }}>
                      <th>#</th>
                      <th>{<GetLanguageString props="common_name" />}</th>
                      <th>{<GetLanguageString props="funds_date" />}</th>
                      <th>
                        {<GetLanguageString props="funds_receipt_number" />}
                      </th>
                      <th>
                        {<GetLanguageString props="funds_add_fund_amount" />}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {fundValuesList?.map((record, index) => {
                      return (
                        <>
                          <tr
                            key={index}
                            style={{ borderBottom: "1px solid grey" }}
                          >
                            <td>{index + 1}</td>
                            <td className="ur">
                              <h4>{record.memberName}</h4>
                            </td>
                            <td>
                              <DatePicker
                                maxDate={new Date()}
                                placeholderText="dd/mm/yyyy"
                                className="form-control no-drag"
                                dateFormat="dd/MM/yyyy"
                                selected={selectedDatesList[index]?.date}
                                onChange={(v) =>
                                  handleInputChangeDate(v, index)
                                }
                              />
                            </td>
                            <td>
                              <Input
                                autoFocus={index === 0}
                                type="text"
                                className="no-drag"
                                name="receiptNumber"
                                value={fundValuesList[index]?.receiptNumber}
                                onChange={(e) => handleInputChange(e, index)}
                              />
                            </td>
                            <td>
                              <Input
                                type="number"
                                className="no-drag"
                                name="amount"
                                value={fundValuesList[index]?.amount}
                                onChange={(e) => handleInputChange(e, index)}
                              />
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <NoResult />
              )}
              <Row
                style={{
                  backgroundColor: "white",
                  marginLeft: 2,
                  marginRight: 2,
                  height: 50,
                }}
              >
                <div style={{ marginTop: 10 }}>
                  <Button
                    color="primary"
                    className={GetCustomStyles().btn_style}
                    onClick={() => handleSubmit()}
                    disabled={fundValuesList.length === 0}
                  >
                    {<GetLanguageString props="common_save_changes" />}
                  </Button>
                  <Button
                    color="white"
                    className={GetCustomStyles().btn_style}
                    onClick={() => history.goBack()}
                  >
                    {<GetLanguageString props="common_cancel" />}
                  </Button>
                </div>
              </Row>
            </div>
          </Form>
        ) : (
          LoadingSpinner()
        )}
      </div>
    </div>
  );
};

export default withRouter(AddBulkFund);
