import React from "react";
import MemberMudarisDetails from "./MemberMudarisDetails";
import {
  useLocation,
  withRouter,
} from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "reactstrap";
import { GetLanguageString } from "../../helper/Components";
import { GetCustomStyles } from "../../helper/Method";

const MemberMudarisDetailsFromList = ({ history }) => {
  const location = useLocation();
  const memberId = location?.state?.memberId;
  const page = location?.state?.page;
  return (
    <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
      <div className="m">
        <Button
          size="sm"
          color="primary"
          className={GetCustomStyles().btn_style}
          onClick={() => {
            history.goBack();
          }}
        >
          {<GetLanguageString props="unit_back_btn" />}
        </Button>
      </div>
      <br></br>
      <div className="ibox-content m">
        <MemberMudarisDetails memberId={memberId} page={page} />{" "}
      </div>
    </div>
  );
};

export default withRouter(MemberMudarisDetailsFromList);
