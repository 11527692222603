import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import { LoadingSpinner } from "../../../constants/const";
import { GetLanguageString } from "../../helper/Components";
import { ApiEducation } from "./ApiEducation";
import Draggable from "react-draggable";
import swal from "sweetalert";
import { GetSelectedLanguage } from "../../helper/Method";
import Select from "react-select";

const EditDegree = ({ setEditModal, editValue, reload, setReload }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [educationType, setEducationType] = useState([]);
  const [educationFields, setEducationFields] = useState([]);
  const [values, setValues] = useState([]);
  const [degreeValues, setDegreeValues] = useState({
    id: "",
    degree: "",
    title: "",
    educationFieldsId: []
  });
  const [errors, setErrors] = useState(degreeValues);
  const toggle = () => setEditModal(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  const validate = () => {
    let temp = {};
    temp.degree = values.degree
      ? ""
      : t("education_degree_name_err");
    temp.educationTypeId = values.educationType.value > 0
      ? ""
      : t("education_degree_type_err");
    temp.educationFields = values.educationFields.length > 0
      ? ""
      : t("education_degree_fields_err");

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setErrors({
      ...errors,
      degree: "",
    });
    setDegreeValues({
      ...degreeValues,
      [name]: value,
    });
    setValues({
      ...values,
      degree: value,
    });
  };

  const handleSelect = (record) => {
    setErrors({
      ...errors,
      educationTypeId: "",
    });

    setDegreeValues({
      ...degreeValues,
      educationTypeId: record.value,
    });
    
    let obj = {
      value: record.value,
      label: record.label,
    };

    setValues({
      ...values,
      educationType: obj,
      educationTypeId: record.value,
    });
  };

  const handleSelectMulti = (record) => {
    let educationFields = [];
    let educationFieldIds = [];

    // eslint-disable-next-line
    record.map((obj) => {
      educationFieldIds.push(obj.value);
      educationFields.push({
        value: obj.value,
        label: obj.label
      });
    });

    if (educationFieldIds.length > 0) {
      setErrors({
        ...errors,
        educationFields: "",
      });
      setDegreeValues({
        ...degreeValues,
        educationFieldsId: educationFieldIds,
      });
      setValues({
        ...values,
        educationFields
      });
    }
  };

  useEffect(() => {
    ApiEducation()
      .getDegreeById(editValue)
      .then((res) => {
        setValues(res.data);

        let educationFieldIds = [];
        
        // eslint-disable-next-line
        res.data.educationFields.map((obj) => {
          educationFieldIds.push(obj.value);
        });
        setDegreeValues({
          ...degreeValues,
          id: res.data.id,
          degree : res.data.degree,
          educationFieldsId: educationFieldIds,
          educationTypeId: res.data.educationType.value,
        });

        setLoading(false);
        setLoading(!loading);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    ApiEducation()
    .getEducationType()
    .then((res) => {
      setEducationType(res.data);
    })
    .catch((err) => {
      console.log(err);
    });

    ApiEducation()
      .getEducationFields()
      .then((res) => {
        setEducationFields(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  // eslint-disable-next-line
  }, [editValue]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      setEditModal(false);
      ApiEducation()
        .editDegree(degreeValues.id, degreeValues)
        .then((res) => {
          setReload(!reload);
          toggle();
          setLoading(false);
          swal({
            title: t("education_edit_degree_success"),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "success",
            buttons: "OK",
          });
        })
        .catch((err) => {
          console.log(err);
          setReload(!reload);
          setLoading(false);
          swal({
            title: "Error: " + err.response?.data || err,
            icon: "error",
            buttons: "OK",
          });
        });
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="education_edit_degree" />}
          </ModalHeader>
          {!loading ? (
            <Form onSubmit={handleSubmit}>
              <ModalBody>
              <FormGroup>
                  <Label>
                    {
                      <GetLanguageString props="education_add_degree_name" />
                    }
                  </Label>
                  <Input
                    autoFocus
                    type="text"
                    name="degree"
                    className="no-drag"
                    value={values.degree}
                    onChange={handleInputChange}
                    {...(errors.degree && { invalid: true })}
                  />
                  {errors.degree && (
                    <FormFeedback>
                      <h4>{errors.degree}</h4>
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="col-form-label">
                    {
                      <GetLanguageString props="education_add_degree_type" />
                    }
                  </Label>
                  <Select
                    value={values.educationType}
                    options={educationType}
                    className="basic-select ur no-drag"
                    classNamePrefix="select"
                    onChange={handleSelect}
                    {...(errors.educationTypeId && { invalid: true })}
                  />
                  {errors.educationTypeId && (
                    <h4 className="text-error">{errors.educationTypeId}</h4>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="col-form-label">
                    {
                      <GetLanguageString props="education_add_degree_fields" />
                    }
                  </Label>
                  <Select
                    value={values.educationFields}
                    options={educationFields}
                    className="basic-multi-select ur no-drag"
                    classNamePrefix="select"
                    isMulti
                    onChange={handleSelectMulti}
                    {...(errors.educationFields && { invalid: true })}
                  />
                  {errors.educationFields && (
                    <h4 className="text-error">{errors.educationFields}</h4>
                  )}
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="white" onClick={toggle}>
                  {<GetLanguageString props="common_cancel" />}
                </Button>
                <Button color="primary" type="submit">
                  {<GetLanguageString props="common_save_changes" />}
                </Button>
              </ModalFooter>
            </Form>
          ) : (
            LoadingSpinner()
          )}
        </Modal>
      </Draggable>
    </div>
  );
};

export default EditDegree;
