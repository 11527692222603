import axios from "axios";

import { BASEURL } from "../constants/const";
import { encryptStorage } from "../constants/EncryptStorage";

export function requestApi(props) {
  const userToken = encryptStorage.getItem("token");

  const authAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const url = props;
  return {
    fetchAll: () => authAxios.get(url + "getList/"),
    fetchSorted: (record) => authAxios.post(url + "getList/", record),
    fetchById: (id) => authAxios.get(url + "edit/" + id),
    create: (newRecord) => authAxios.post(url + "add/", newRecord),
    update: (id, updateRecord) =>
      authAxios.put(url + "edit/" + id, updateRecord),
    delete: (id) => authAxios.delete(url + "delete/" + id),
  };
}

export function requestAccountApi() {
  const userToken = encryptStorage.getItem("token");

  const authAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const url = BASEURL + "api/account/";
  return {
    createLogin: (newRecord) => axios.post(url + "login", newRecord),
    forgotPassword: (newRecord) =>
      axios.post(url + "ForgotPassword", newRecord),
    createDynamicUser: (newRecord) =>
      axios.post(url + "CreateDynamicUser", newRecord),
    changePassword: (newRecord) =>
      authAxios.post(url + "ChangePassword", newRecord),
    changeResponsibility: (responsibilityId, unitId, userId) =>
      authAxios.get(url + "ChangeResponsibilityToken/" + responsibilityId + "/" + unitId + "/" + userId)
  };
}
