import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Form, Row, Col, FormGroup, Label, Input, Button } from "reactstrap";

import useForm from "../shared/useForm";
import { ApiUnit } from "../unit/ApiUnit";
import { GetLanguageString } from "../helper/Components";
import { encryptStorage } from "../../constants/EncryptStorage";
import { isUrdu } from "../../constants/const";
// import { setupApi } from "../member/ApiMember";
// import { GetSelectedLanguage } from "../helper/Method";
// import { LoadingSpinner } from "../../constants/const";

const SearchAhbab = ({
  unitId,
  setUnit,
  setSearchAhbabModel,
  searchAhbabModel,
  setSearchButton,
}) => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [unitList, setUnitList] = useState([]);
  const unitTypeName = "null";
  // const selectedLanguage = GetSelectedLanguage();
  // const [countries, setCountries] = useState([]);
  // const [states, setStates] = useState([]);
  // const [cities, setCities] = useState([]);
  // const [selectedCountryId, setSelectedCountryId] = useState();
  // const [selectedStateId, setSelectedStateId] = useState();
  // const [countriesLoading, setCountriesLoading] = useState(false);

  // useEffect(() => {
  //   setCountriesLoading(true);
  //   setupApi()
  //     .GetCountries()
  //     .then((res) => {
  //       setCountries(res.data.countries);
  //       setSelectedCountryId(res.data.selectedCountry.value);
  //       setValues({
  //         ...values,
  //         country: res.data.selectedCountry,
  //       });
  //       if (values.state === "" || values.city === "") {
  //         setStates([]);
  //         setCities([]);
  //         setSelectedStateId(0);
  //       }
  //       setCountriesLoading(false);
  //     })
  //     .catch((err) => console.log(err));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   setupApi()
  //     .GetStates(selectedCountryId)
  //     .then((res) => {
  //       setStates(res.data);
  //       if (res.data.length === 0) {
  //         setCities([]);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // }, [selectedCountryId]);

  // useEffect(() => {
  //   setupApi()
  //     .GetCities(selectedStateId)
  //     .then((res) => {
  //       setCities(res.data);
  //     })
  //     .catch((err) => console.log(err));
  // }, [selectedStateId]);

  // const handleOnChangeCountry = (country) => {
  //   setValues({
  //     ...values,
  //     country: country,
  //     state: null,
  //     city: null,
  //   });
  //   setCities([]);
  //   setSelectedStateId(0);
  //   setSelectedCountryId(country.value);
  // };

  // const handleOnChangeCity = (city) => {
  //   setValues({
  //     ...values,
  //     city: city,
  //   });
  // };
  // const handleOnChangeState = (state) => {
  //   setValues({
  //     ...values,
  //     state: state,
  //     city: null,
  //   });
  //   setSelectedStateId(state.value);
  // };

  useEffect(() => {
    ApiUnit()
      .getUnitList(unitId, loggedInUnitId, false, unitTypeName)
      .then((res) => {
        setUnitList(res.data);
      })
      .catch((err) => console.log(err));
  }, [unitId, loggedInUnitId]);

  const { values, handleInputChange, setValues } = useForm(searchAhbabModel);

  const handleChangeUnits = (record) => {
    if (record !== null) {
      ApiUnit()
        .getUnitList(record.value, loggedInUnitId, false, unitTypeName)
        .then((res) => {
          setUnitList(res.data);
        })
        .catch((err) => console.log(err));

      setUnit({ unitId: record.value, unitName: record.label });
      setValues({ ...values, unitId: record.value });
    }
  };

  const handleSubUnits = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setValues({
      ...values,
      isSubUnits: v,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (values.unitId === 0) values.unitId = unitId;
    setSearchButton(true);
    setSearchAhbabModel(values);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col md="3">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="member_search_by_name" />}
            </Label>
            <Input
              className={isUrdu(values.name) ? "ur" : ""}
              type="text"
              name="name"
              value={values.name}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="member_search_by_father_name" />}
            </Label>
            <Input
              className={isUrdu(values.fatherName) ? "ur" : ""}
              type="text"
              name="fatherName"
              value={values.fatherName}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="member_search_by_phoneno_email" />}
            </Label>
            <Input
              type="text"
              name="phoneNo"
              value={values.phoneNo}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="ahbab_address" />}
            </Label>
            <Input
              className={isUrdu(values.address) ? "ur" : ""}
              type="text"
              name="address"
              value={values.address}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
      </Row>
      {/* <Row>
        {" "}
        <Col lg="6">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="ahbab_address" />}
            </Label>
            <Input
              className="ur"
              type="text"
              name="address"
              value={values.address}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          {!countriesLoading ? (
            <Row>
              <Col md="4">
                <FormGroup>
                  <Label className="col-form-label">
                    {<GetLanguageString props="member_country" />}
                  </Label>
                  <Select
                    options={countries}
                    value={values.country}
                    
                    className="basic-single"
                    placeholder={
                      selectedLanguage === "en" ? "Select" : "منتخب کریں"
                    }
                    classNamePrefix="select"
                    onChange={(country) => {
                      handleOnChangeCountry(country);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                {states.length === 0 ? (
                  <h3 className="text-center m-5">{" - "}</h3>
                ) : (
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="ahbeb_state_province" />}
                    </Label>
                    <Select
                      options={states}
                      value={values.state}
                      className="basic-single"
                      
                      placeholder={
                        states.length === 0
                          ? selectedLanguage === "en"
                            ? "No States Available"
                            : "کوئی صوبہ موجود نہی ہے"
                          : selectedLanguage === "en"
                          ? "Select"
                          : "منتخب کریں"
                      }
                      classNamePrefix="select"
                      onChange={(state) => {
                        handleOnChangeState(state);
                      }}
                    />
                  </FormGroup>
                )}
              </Col>
              <Col md="4">
                {cities.length === 0 ? (
                  <h3 className="text-center m-5">{" - "}</h3>
                ) : (
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="member_city" />}
                    </Label>
                    <Select
                      options={cities}
                      value={values.city}
                      className="basic-single"
                      
                      placeholder={
                        cities.length === 0
                          ? selectedLanguage === "en"
                            ? "No Cities Available"
                            : "کوئی شہر موجود نہی ہے"
                          : selectedLanguage === "en"
                          ? "Select"
                          : "منتخب کریں"
                      }
                      classNamePrefix="select"
                      onChange={(city) => {
                        handleOnChangeCity(city);
                      }}
                    />
                  </FormGroup>
                )}
              </Col>
            </Row>
          ) : (
            LoadingSpinner()
          )}
        </Col>
      </Row> */}
      <Row>
        {unitList.map((record, index) => {
          const unitOptions = [];
          record.unitList.forEach((d) =>
            unitOptions.push({
              value: d.id,
              label: d.name,
              unitTypeName: d.unitTypeName,
            })
          );
          let selected = {};
          let unitName = "";
          if (record.parentUnitName !== null) unitName = record.parentUnitName;
          if (record.selectedUnit !== null) {
            selected = {
              value: record.selectedUnit.id,
              label: record.selectedUnit.name,
            };
          }

          return (
            <Col md="2" key={index}>
              <FormGroup>
                <Label className="col-form-label">
                  {unitName !== "" ? (
                    <GetLanguageString
                      props="member_select_unit_name"
                      value={unitName}
                    />
                  ) : (
                    <GetLanguageString props="member_select_unit" />
                  )}
                </Label>
                <Select
                  options={unitOptions}
                  value={selected}
                  className="basic-single ur"
                  classNamePrefix="select"
                  onChange={handleChangeUnits}
                />
              </FormGroup>
            </Col>
          );
        })}
      </Row>
      <Row>
        <Col md="2">
          <FormGroup check>
            <Label check for="subUnit">
              <Input
                id="subUnit"
                type="checkbox"
                name="isSubUnits"
                checked={values.isSubUnits}
                value={!values.isSubUnits}
                onChange={handleSubUnits}
              />
              {<GetLanguageString props="member_search_member_sub_unit" />}
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <FormGroup>
            <Button color="primary" className="m-l-sm" type="submit">
              {<GetLanguageString props="common_search" />}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default SearchAhbab;
