import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Row, Col, FormGroup } from "reactstrap";

import { ApiUnit } from "../unit/ApiUnit";
import { encryptStorage } from "../../constants/EncryptStorage";
import { GetSelectedLanguage } from "../helper/Method";
import { GoArrowLeft, GoArrowRight } from "react-icons/go";

const SelectUnitForAhbab = ({ setValues, values, setErrors, unitId }) => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const unitTypeName = null;
  const [unitList, setUnitList] = useState([]);
  if (unitId === 0) unitId = loggedInUnitId;

  useEffect(() => {
    ApiUnit()
      .getUnitList(unitId, loggedInUnitId, false, unitTypeName)
      .then((res) => {
        setUnitList(res.data);
      })
      .catch((err) => console.log(err));
  }, [unitId, loggedInUnitId, unitTypeName]);

  const handleChangeUnits = (record) => {
    if (record !== null) {
      ApiUnit()
        .getUnitList(record.value, loggedInUnitId, false, unitTypeName)
        .then((res) => {
          setUnitList(res.data);
        })
        .catch((err) => console.log(err));

      setValues({
        ...values,
        unit: record,
      });
      setErrors({
        unitId: "",
      });
    }
  };

  return (
    <>
      <Row>
        {unitList.map((record, index) => {
          const unitOptions = [];
          record.unitList.forEach((d) =>
            d.unitTypeName !== "UsraMuavin" &&
            d.unitTypeName !== "Munfarid" &&
            d.unitTypeName !== "EX"
              ? unitOptions.push({
                  value: d.id,
                  label: d.name,
                  unitName: d.unitTypeName,
                })
              : null
          );
          let selected = {};
          if (record.selectedUnit !== null) {
            selected = {
              value: record.selectedUnit.id,
              label: record.selectedUnit.name,
            };
          }
          const isLastIndex = index === unitList.length - 1;

          return (
            <Col md="2" key={index}>
              <FormGroup>
                <Row>
                  <Col md="10">
                    <Select
                      options={unitOptions}
                      value={selected}
                      className="basic-single ur"
                      classNamePrefix="select"
                      onChange={handleChangeUnits}
                    />
                  </Col>
                  {isLastIndex ? null : (
                    <Col md="2" className="p-0">
                      {GetSelectedLanguage() === "en" ? (
                        <GoArrowRight
                          size={20}
                          style={{ marginRight: 3, marginBottom: 1 }}
                        />
                      ) : (
                        <GoArrowLeft
                          size={20}
                          style={{ marginRight: 3, marginBottom: 1 }}
                        />
                      )}
                    </Col>
                  )}
                </Row>
              </FormGroup>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default SelectUnitForAhbab;
