import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  withRouter,
} from "react-router-dom/cjs/react-router-dom.min";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import swal from "sweetalert";
import { convertToDateString, LoadingSpinner } from "../../../constants/const";
import { GetLanguageString } from "../../helper/Components";
import {
  GetCustomStyles,
  GetPeriodName,
  GetSelectedLanguage,
} from "../../helper/Method";
import { addMember } from "../ApiMember";
import LetterForCounsellingDataTable from "./LetterForCounsellingDataTable";
import LetterForCounsellingMemberDataContainer from "./LetterForCounsellingMemberDataContainer";

const EditLetterForCounselling = ({ history }) => {
  const location = useLocation();

  const currentDate = new Date();
  const memberId = location.state?.memberId;
  const letterForCounselling = location.state?.letterForCounselling;
  const receivedLetterForCounsellingId =
    location.state?.receivedLetterForCounsellingId;
  const letterForFrequency = location.state?.letterForFrequency;
  const letterFrequency = location.state?.letterFrequency;
  const memberValues = location.state?.memberValues;
  const receivedAt = location.state?.receivedAt;
  const letterYear = location.state?.letterYear;
  const [letterColor, setLetterColor] = useState("");
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState({
    questions: "",
  });
  const [selectedDate, setSelectedDate] = useState(new Date(receivedAt));

  const [loading, setLoading] = useState(false);

  const [questionsList, setQuestionsList] = useState([]);

  useEffect(() => {
    setLoading(true);
    addMember()
      .EditReceivedLetterForCounselling(receivedLetterForCounsellingId)
      .then((res) => {
        let tempValues = [...res.data.memberLetterForCounsellingQuestions];

        tempValues = tempValues.map((question) => {
          return { ...question, errorColor: "#ffffff" };
        });
        setQuestionsList(tempValues);
        setLetterColor(res.data.letterColor);
        setLoading(false);
      })
      .catch((err) => {
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [receivedLetterForCounsellingId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      questionsList.every((question) => question.rating !== 0) &&
      Object.values(errorMessage).every((x) => x === "")
    ) {
      const values = {
        memberId: memberId,
        letterForCounsellingId: receivedLetterForCounsellingId,
        receivedAt: convertToDateString(selectedDate),
        questions: questionsList,
      };
      setLoading(true);
      addMember()
        .EditMemberLetterForCounselling(values)
        .then((res) => {
          swal({
            title: t(
              "member_member_detail_letter_for_counselling_edit_success"
            ),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "success",
            buttons: "OK",
          });
          history.goBack();
          setLoading(false);
        })
        .catch((err) => {
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
          setLoading(false);
        });
    } else {
      setErrorMessage({
        ...errorMessage,
        questions: questionsList.some((question) => question.rating === 0)
          ? "member_member_detail_letter_for_counselling_questions_error_message"
          : "",
      });
    }
  };

  return (
    <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
      <div className="panel panel-primary">
        <div className="panel-heading">
          {" "}
          <Row>
            <Col md="10"></Col>
            <Col md="2">
              <Button
                color="info"
                size="sm"
                className={GetCustomStyles().btn_style}
                onClick={() => history.goBack()}
              >
                {<GetLanguageString props="unit_back_btn" />}
              </Button>
            </Col>
          </Row>
        </div>
        <div className="panel-body">
          <LetterForCounsellingMemberDataContainer
            memberValues={memberValues}
          />
          <br></br>
          <br></br>
          <Row>
            <Col md="3">
              <FormGroup>
                <Label className="col-form-label">
                  {
                    <GetLanguageString props="member_member_detail_letter_for_counselling_year" />
                  }
                </Label>
                <Input value={letterYear} disabled />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label className="col-form-label">
                  {
                    <GetLanguageString props="settings_letter_for_counselling" />
                  }
                </Label>
                <Input
                  className={"ur"}
                  type="text"
                  disabled
                  value={letterForCounselling}
                />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup style={{ marginTop: 5 }}>
                <Label>
                  {<GetLanguageString props="settings_event_type_frequency" />}
                </Label>

                <Input
                  className={"ur"}
                  type="text"
                  disabled
                  value={GetPeriodName(letterFrequency, letterForFrequency)}
                />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label className="col-form-label">
                  {
                    <GetLanguageString props="member_member_detail_letter_for_counselling_received_date" />
                  }
                </Label>
                <DatePicker
                  showYearDropdown
                  placeholderText="dd/mm/yyyy"
                  className="form-control"
                  selected={selectedDate}
                  maxDate={currentDate}
                  onChange={(d) => setSelectedDate(d)}
                  dateFormat="dd/MM/yyyy"
                />
              </FormGroup>
            </Col>
          </Row>{" "}
          {errorMessage.frequency && (
            <Row>
              <h5 className="text-error">
                * {<GetLanguageString props={errorMessage.frequency} />}
              </h5>
            </Row>
          )}
          {errorMessage.selectedFrequency && (
            <Row>
              <h5 className="text-error">* {errorMessage.selectedFrequency}</h5>
            </Row>
          )}
          {errorMessage.questions && (
            <Row>
              <h5 className="text-error">
                * {<GetLanguageString props={errorMessage.questions} />}
              </h5>
            </Row>
          )}
        </div>
      </div>
      <div className="panel panel-primary" style={{ borderColor: letterColor }}>
        <div className="panel-body">
          {!loading ? (
            <>
              <LetterForCounsellingDataTable
                questionsList={questionsList}
                setQuestionsList={setQuestionsList}
                setErrorMessage={setErrorMessage}
                errorMessage={errorMessage}
                letterColor={letterColor}
              />
              <Row>
                <div className={GetCustomStyles().btn_style}>
                  {" "}
                  <Button
                    color="primary"
                    size="sm"
                    className={GetCustomStyles().btn_style}
                    onClick={handleSubmit}
                  >
                    <GetLanguageString props="Submit" />
                  </Button>
                </div>{" "}
              </Row>
            </>
          ) : (
            LoadingSpinner()
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(EditLetterForCounselling);
