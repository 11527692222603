import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import swal from "sweetalert";
import {
  CheckIsAdmin,
  CheckPermission,
  LoadingSpinner,
  convertToDateString,
  convertUTCToLocalTime,
  isUrdu,
} from "../../../constants/const";
import { GetLanguageString } from "../../helper/Components";
import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import { addMember, setupApi } from "../ApiMember";

const UpdateAddress = ({
  history,
  tab3,
  memberId,
  addNewRafiq,
  setUpdatedDetails,
  memberTypeId,
  activeTab,
  setActiveTab,
  discard,
  setDiscard,
}) => {
  const notify = (string) => toast.success(string);
  const selectedLanguage = GetSelectedLanguage();
  const { t } = useTranslation();
  const [countries, setCountries] = useState();
  const [addressDates, setAddressDates] = useState([{ date: new Date() }]);

  useEffect(() => {
    if (tab3 === "active") {
      setLoading(true);
      setupApi()
        .GetCountries()
        .then((res) => {
          setCountries(res.data.countries);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab3]);

  const [loading, setLoading] = useState(false);
  const [addressTypeList, setAddressTypeList] = useState();
  const [addressList, setAddressList] = useState([
    {
      addressType: {},
      country: {},
      city: {},
      countriesList: countries,
      statesList: [],
      citiesList: [],
      state: {},
      date: new Date(),
      address: "",
      latestAddress: true,
      isInContact: true,
      referenceNote: "",
      deletable: false,
      edited: false,
      isHabibRequest: memberTypeId !== 11 ? false : true,
    },
  ]);

  const [errors, setErrors] = useState([
    {
      addressType: "",
      country: "",
      state: "",
      city: "",
      address: "",
    },
  ]);
  const validate = () => {
    let temp = [];
    addressList.map((item, index) => {
      let tempErrors = {};
      if (!item.deletable) {
        tempErrors.addressType =
          Object.keys(item.addressType).length !== 0 ? (
            ""
          ) : (
            <GetLanguageString props="member_member_details_address_type_error_message" />
          );
        tempErrors.country =
          Object.keys(item.country).length !== 0 && item.country.value !== 0 ? (
            ""
          ) : (
            <GetLanguageString props="member_member_details_country_error_message" />
          );
        tempErrors.address = item.address ? (
          ""
        ) : (
          <GetLanguageString props="member_member_details_address_error_message" />
        );
        temp.country =
          Object.keys(item.country).length === 0 && item.country.value !== 0 ? (
            <GetLanguageString props="member_member_details_country_error_message" />
          ) : (
            ""
          );

        temp.state =
          item.state === null ? (
            Object.keys(item.state).length === 0 &&
            item.statesList.length !== 0 &&
            item.state.value !== 0 ? (
              <GetLanguageString props="member_member_details_state_error_message" />
            ) : (
              ""
            )
          ) : (
            ""
          );

        temp.city =
          item.city === null ? (
            Object.keys(item.city).length === 0 &&
            item.citiesList.length !== 0 &&
            item.city.value !== 0 ? (
              <GetLanguageString props="member_member_details_city_error_message" />
            ) : (
              ""
            )
          ) : (
            ""
          );

        temp.push(tempErrors);
      } else {
        temp.push({
          addressType: "",
          country: "",
          address: "",
        });
      }

      return tempErrors;
    });

    setErrors(temp);
    return allAreTrue(
      temp.map((item) => {
        return Object.values(item).every((x) => x === "");
      })
    );
  };

  const handleOnChangeAddressType = (value, id) => {
    let tempValues = [...addressList];
    tempValues[id].addressType = value;
    tempValues[id].edited = true;
    setAddressList(tempValues);
  };

  const handleOnChangeCountry = (country, id) => {
    let tempValues = [...addressList];
    tempValues[id].country = country;
    tempValues[id].edited = true;
    setAddressList(tempValues);
    setupApi()
      .GetStates(country.value)
      .then((res) => {
        setAddressList((prevAddressList) => {
          let tempValues = [...prevAddressList];
          tempValues[id].statesList = res.data;
          tempValues[id].citiesList = [];
          tempValues[id].state = {};
          tempValues[id].city = {};
          return tempValues;
        });
      })
      .catch((err) => console.log(err));
  };

  const handleOnChangeCity = (value, id) => {
    let tempValues = [...addressList];
    tempValues[id].city = value;
    tempValues[id].edited = true;
    setAddressList(tempValues);
  };

  const handleOnChangeState = (state, id) => {
    let tempValues = [...addressList];
    tempValues[id].state = state;
    tempValues[id].edited = true;
    setAddressList(tempValues);

    setupApi()
      .GetCities(state.value)
      .then((res) => {
        setAddressList((prevAddressList) => {
          let tempValues = [...prevAddressList];
          tempValues[id].citiesList = res.data;
          tempValues[id].city = {};
          return tempValues;
        });
      })
      .catch((err) => console.log(err));
  };

  const handleAddress = (address, index) => {
    let tempValues = [...addressList];
    tempValues[index].address = address.target.value;
    tempValues[index].edited = true;
    setAddressList(tempValues);
  };

  const handleReferenceNote = (referenceNote, index) => {
    let tempValues = [...addressList];
    tempValues[index].referenceNote = referenceNote.target.value;
    tempValues[index].edited = true;
    setAddressList(tempValues);
  };

  useEffect(() => {
    if (tab3 === "active") {
      setLoading(true);
      addMember()
        .getMemberAddressesDetail(memberId)
        .then((res) => {
          let addresses = [];
          let addressesDates = [];
          res?.data?.addresses.forEach((a) => {
            addresses.push({
              ...a,
              countriesList: countries,
              date:
                a.date !== null && a.date !== undefined
                  ? new Date(convertUTCToLocalTime(a.date))
                  : new Date(),
            });
            addressesDates.push({
              date:
                a.date !== null && a.date !== undefined
                  ? new Date(convertUTCToLocalTime(a.date))
                  : new Date(),
            });
          });          

          if (addresses.length > 0) {
            setAddressList(addresses);
            setAddressDates(addressesDates);

            let tempErrors = [];
            addresses.map(() => {
              return tempErrors.push({
                addressType: "",
                country: "",
                state: "",
                city: "",
                address: "",
              });
            });
            setErrors(tempErrors);
          } else {
            setAddressList([
              {
                addressType: {},
                country: {},
                countriesList: countries,
                statesList: [],
                citiesList: [],
                city: {},
                state: {},
                date: new Date(),
                address: "",
                latestAddress: true,
                isInContact: true,
                referenceNote: "",
                deletable: false,
                edited: false,
                isHabibRequest: memberTypeId !== 11 ? false : true,
              },
            ]);
            setAddressDates([{ date: new Date() }]);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage, memberId, countries, tab3, memberTypeId]);

  useEffect(() => {
    if (tab3 === "active") {
      setupApi()
        .getAddressTypes()
        .then((res) => {
          setAddressTypeList(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [tab3]);
  
  const handleInputChangeDate = (value, id) => {
    if (value !== null) {
      // let date = new Date(value.setHours(value.getHours() + 5));
      let tempValues = [...addressDates];
      tempValues[id].date = value;
      setAddressDates(tempValues);

      let tempData = [...addressList];
      tempData[id].edited = true;
      setAddressList(tempData);
    }
  };

  const addAddress = () => {
    const tempValues = [
      {
        addressType: {},
        countriesList: countries,
        statesList: [],
        citiesList: [],
        country: {},
        city: {},
        date: new Date(),
        address: "",
        latestAddress: true,
        isInContact: true,
        referenceNote: "",
        deletable: false,
        edited: false,
        isHabibRequest: memberTypeId !== 11 ? false : true,
      },
      ...addressList,
    ];
    const tempDates = [{ date: new Date() }, ...addressDates];
    setAddressList(tempValues);
    setAddressDates(tempDates);

    const tempErrors = [
      {
        addressType: "",
        country: "",
        state: "",
        city: "",
        address: "",
      },
      ...errors,
    ];
    setErrors(tempErrors);
  };

  const removeObject = (id) => {
    let tempValues = [...addressList];
    tempValues[id].deletable = true;
    tempValues[id].edited = true;
    setAddressList(tempValues);
  };

  const handleLatest = (index) => {
    var v = !addressList[index].latestAddress;
    let tempValues = [...addressList];
    tempValues[index].latestAddress = v;
    tempValues[index].edited = true;
    setAddressList(tempValues);
  };

  const handleIsInContact = (index) => {
    var v = !addressList[index].isInContact;
    let tempValues = [...addressList];
    tempValues[index].isInContact = v;
    tempValues[index].edited = true;
    setAddressList(tempValues);
  };

  function allAreTrue(arr) {
    return arr.every((element) => element === true);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    apiCall();
  };

  const apiCall = () => {
    if (validate()) {
      const updatedList = addressList.map((item, index) => ({
        ...item,
        date: convertToDateString(addressDates[index]?.date || item.date), // Update date if exists, else keep the old date
      }));

      if (addressList.every((item) => item.edited === false)) {
        swal({
          title:
            memberTypeId !== 11
              ? t("member_member_address_added_success_message")
              : t("member_habib_address_added_success_message"),
          className: GetSelectedLanguage() === "en" ? "" : "ur",
          icon: "success",
          buttons: "OK",
        });
      } else {
        setLoading(true);
        addMember()
          .addMemberAddress(addNewRafiq, {
            memberId: memberId,
            addresses: updatedList,
          })
          .then((res) => {
            if (res.data === "") {
              swal({
                title:
                  memberTypeId !== 11
                    ? t("member_member_address_added_success_message")
                    : t("member_habib_address_added_success_message"),
                className: GetSelectedLanguage() === "en" ? "" : "ur",
                icon: "success",
                buttons: "OK",
              }).then((ok) => {
                setDiscard(!discard);
              });
              addMember()
                .getMemberAddressesDetail(memberId)
                .then((res) => {
                  let addresses = [];
                  res.data.addresses.forEach((a) => {
                    addresses.push({
                      ...a,
                      date: a.date
                        ? new Date(convertUTCToLocalTime(a.date))
                        : new Date(),
                    });
                  });

                  res.data.addresses.length !== 0
                    ? setAddressList(addresses)
                    : setAddressList([
                        {
                          addressType: {},
                          country: {},
                          city: {},
                          date: new Date(),
                          address: "",
                          latestAddress: true,
                          isInContact: true,
                          referenceNote: "",
                          deletable: false,
                          edited: false,
                        },
                      ]);
                });
            } else notify(res.data);

            setUpdatedDetails((prevState) => !prevState);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            swal({
              title: err,
              icon: "error",
              buttons: "OK",
            }).then((ok) => {
              setActiveTab("tab3");
            });
            console.log(err);
          });
      }
    }
  };
  const handleCancel = (e) => {
    if (!addressList.every((item) => item.edited === false)) {
      swal({
        title: "Discard changes?",
        text: "Do you want to discard changes or save before canceling?",
        icon: "warning",
        buttons: ["Save Changes", "Discard"],
        closeOnClickOutside: false,
        dangerMode: true,
      }).then((willDiscard) => {
        if (willDiscard) {
          history.goBack(); // Process with cancel
        } else {
          handleSubmit(e); // Call the save function when the user chooses not to discard changes
        }
      });
    } else {
      history.goBack(); // No changes, directly go back
    }
  };
  useEffect(() => {
    // Check if the active tab has changed
    if (tab3 === "active") {
      if (activeTab && activeTab !== "tab3") {
        // Check if there are unsaved changes
        if (!addressList.every((item) => item.edited === false)) {
          swal({
            title: "Discard changes?",
            text: "Do you want to discard changes or save before canceling for Address?",
            icon: "warning",
            buttons: ["Save Changes", "Discard"],
            closeOnClickOutside: false,
            dangerMode: true,
          }).then((willDiscard) => {
            if (willDiscard) {
              setDiscard(!discard);
            } else {
              apiCall();
            }
          });
        } else {
          setDiscard(!discard);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <div role="tabpanel" id="tab-3" className={"tab-pane " + tab3}>
      <div className="panel-body">
        {!loading ? (
          <div className="ibox">
            <div className="ibox-title">
              <Row>
                <Col md="11">
                  <h5>{<GetLanguageString props="member_address" />}</h5>
                </Col>
                <Col md="1" className={GetCustomStyles().btn_style}>
                  <div>
                    <i
                      className={"fa fa-2x fa-plus-square"}
                      style={{ cursor: "pointer", color: "green" }}
                      onClick={addAddress}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="ibox-content">
              <Form onSubmit={handleSubmit}>
                {addressList?.map((item, index) => {
                  return !item.deletable ? (
                    <>
                      <Row key={index}>
                        <Col md="2">
                          <FormGroup>
                            <Label className="col-form-label">
                              {
                                <GetLanguageString props="member_address_type" />
                              }
                            </Label>
                            <Select
                              options={addressTypeList}
                              value={item.addressType}
                              className="basic-single ur"
                              placeholder={
                                selectedLanguage === "en"
                                  ? "Select"
                                  : "منتخب کریں"
                              }
                              onChange={(type) => {
                                handleOnChangeAddressType(type, index);
                                let temp = [...errors];
                                temp[index].addressType = "";
                                setErrors(temp);
                              }}
                            />
                            {errors[index]?.addressType ? (
                              <h4 className="text-error">
                                {errors[index]?.addressType}
                              </h4>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup>
                            <Label className="col-form-label">
                              {<GetLanguageString props="member_country" />}
                            </Label>
                            <Select
                              options={item.countriesList}
                              value={item.country}
                              className="basic-single"
                              placeholder={
                                selectedLanguage === "en"
                                  ? "Select"
                                  : "منتخب کریں"
                              }
                              classNamePrefix="select"
                              onChange={(country) => {
                                handleOnChangeCountry(country, index);
                                let temp = [...errors];
                                temp[index].country = "";
                                setErrors(temp);
                              }}
                            />
                            {errors[index]?.country ? (
                              <h4 className="text-error">
                                {errors[index]?.country}
                              </h4>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          {item.statesList?.length === 0 ||
                          item.statesList === null ? (
                            <h3 className="text-center m-5">{" - "}</h3>
                          ) : (
                            <FormGroup>
                              <Label className="col-form-label">
                                {
                                  <GetLanguageString props="ahbeb_state_province" />
                                }
                              </Label>
                              <Select
                                options={item.statesList}
                                value={item.state}
                                className="basic-single"
                                placeholder={
                                  item.statesList?.length === 0 ||
                                  item.statesList === null
                                    ? selectedLanguage === "en"
                                      ? "No States Available"
                                      : "کوئی صوبہ موجود نہی ہے"
                                    : selectedLanguage === "en"
                                    ? "Select"
                                    : "منتخب کریں"
                                }
                                classNamePrefix="select"
                                onChange={(state) => {
                                  handleOnChangeState(state, index);
                                  let temp = [...errors];
                                  temp[index].state = "";
                                  setErrors(temp);
                                }}
                              />
                              {errors[index]?.state ? (
                                <h4 className="text-error">
                                  {errors[index]?.state}
                                </h4>
                              ) : null}
                            </FormGroup>
                          )}
                        </Col>
                        <Col md="2">
                          {item.citiesList?.length === 0 ||
                          item.citiesList === null ? (
                            <h3 className="text-center m-5">{" - "}</h3>
                          ) : (
                            <FormGroup>
                              <Label className="col-form-label">
                                {<GetLanguageString props="member_city" />}
                              </Label>
                              <Select
                                options={item.citiesList}
                                value={item.city}
                                className="basic-single"
                                placeholder={
                                  item.citiesList?.length === 0 ||
                                  item.citiesList === null
                                    ? selectedLanguage === "en"
                                      ? "No Cities Available"
                                      : "کوئی شہر موجود نہی ہے"
                                    : selectedLanguage === "en"
                                    ? "Select"
                                    : "منتخب کریں"
                                }
                                classNamePrefix="select"
                                onChange={(city) => {
                                  handleOnChangeCity(city, index);
                                  let temp = [...errors];
                                  temp[index].city = "";
                                  setErrors(temp);
                                }}
                              />
                              {errors[index]?.city ? (
                                <h4 className="text-error">
                                  {errors[index]?.city}
                                </h4>
                              ) : null}
                            </FormGroup>
                          )}
                        </Col>
                        <Col md="2">
                          <FormGroup>
                            <Label className="col-form-label">
                              {
                                <GetLanguageString props="member_edit_member_date" />
                              }
                            </Label>
                            <DatePicker
                              placeholderText="dd/mm/yyyy"
                              className="form-control"
                              selected={addressDates[index].date}
                              maxDate={new Date()}
                              onChange={(date) =>
                                handleInputChangeDate(date, index)
                              }
                              dateFormat="dd/MM/yyyy"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup>
                            <Label className="col-form-label">
                              {
                                <GetLanguageString props="member_reference_note" />
                              }
                            </Label>
                            <Input
                              className={
                                GetSelectedLanguage() === "en" ? "" : "ur"
                              }
                              type="text"
                              name="referenceNote"
                              value={item.referenceNote}
                              onChange={(referenceNote) =>
                                handleReferenceNote(referenceNote, index)
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="5">
                          <FormGroup>
                            <Label className="col-form-label">
                              {<GetLanguageString props="member_address" />}
                            </Label>
                            <Input
                              className={isUrdu(item.address) ? "ur" : ""}
                              type="text"
                              name="address"
                              value={item.address}
                              onChange={(e) => {
                                handleAddress(e, index);
                                let temp = [...errors];
                                temp[index].address = "";
                                setErrors(temp);
                              }}
                            />
                            {errors[index]?.address ? (
                              <h4 className="text-error">
                                {errors[index]?.address}
                              </h4>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="3" className="m-t-xl text-center">
                          <FormGroup check>
                            <Label check for={"latestAddress" + index}>
                              <Input
                                id={"latestAddress" + index}
                                type="checkbox"
                                checked={addressList[index].latestAddress}
                                onChange={() => handleLatest(index)}
                              />
                              {
                                <GetLanguageString props="member_address_latest" />
                              }
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col md="3" className="m-t-xl text-center">
                          <FormGroup check>
                            <Label check for={"isInContact" + index}>
                              <Input
                                id={"isInContact" + index}
                                type="checkbox"
                                checked={addressList[index].isInContact}
                                onChange={() => handleIsInContact(index)}
                              />
                              {
                                <GetLanguageString props="member_address_in_contact" />
                              }
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col md="1" className="text-center">
                          <i
                            className={"fa fa-2x fa-minus-square m-t-xl "}
                            style={{ cursor: "pointer", color: "red" }}
                            onClick={() => removeObject(index)}
                          />
                        </Col>
                      </Row>
                      <br></br>
                      <hr></hr>
                    </>
                  ) : null;
                })}
                <Row>
                  <Col md="8"></Col>
                  <Col md="4">
                    <div className={GetCustomStyles().btn_style}>
                      <Button color="white" onClick={handleCancel}>
                        {<GetLanguageString props="common_cancel" />}
                      </Button>
                      <Button color="primary" className="m-l-sm" type="submit">
                        {<GetLanguageString props="common_save_changes" />}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
              {CheckPermission("CanRequestMemberModificationPersonalInfo") &&
              !CheckIsAdmin() &&
              !addNewRafiq ? (
                <div>
                  <h4 className="text-error">* Request Modification</h4>
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          LoadingSpinner()
        )}
      </div>
    </div>
  );
};

export default withRouter(UpdateAddress);
