import React from "react";
import { Table } from "reactstrap";
import { GetLanguageString, NoResult } from "../../helper/Components";
import moment from "moment";
import { convertUTCToLocalTime } from "../../../constants/const";

const MemberTypeHistory = ({ tab4, memberTypeHistoryList }) => {
  return (
    <div role="tabpanel" id="tab-4" className={"tab-pane " + tab4}>
      <div className="panel-body">
        {memberTypeHistoryList?.length !== 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th>#</th>
                <th className="text-center">
                  {
                    <GetLanguageString props="member_member_detail_member_type_history_name" />
                  }
                </th>
                <th className="text-center">
                  {
                    <GetLanguageString props="member_member_detail_member_type_history_date" />
                  }
                </th>
                <th className="text-center">
                  {<GetLanguageString props="common_description" />}
                </th>
                <th className="text-center">
                  {<GetLanguageString props="member_reference_note" />}
                </th>
              </tr>
            </thead>
            <tbody>
              {memberTypeHistoryList.map((record, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td className="ur text-center">
                      {record.memberType.label}
                    </td>
                    <td className="text-center">
                      {moment(convertUTCToLocalTime(record.date)).format(
                        "DD/MM/yyyy"
                      )}
                    </td>
                    <td className="text-center">{record.description}</td>
                    <td className="text-center">{record.referenceNote}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <NoResult />
        )}
      </div>
    </div>
  );
};

export default MemberTypeHistory;
