import React, { useEffect, useState } from "react";
import FundFilter from "../../helper/SearchFilter/FundFilter";
import {
  GetMonths,
  GetSelectedLanguage,
  SelectOnlyDate,
} from "../../helper/Method";
import { addMember } from "../ApiMember";
import { GetLanguageString, NoResult } from "../../helper/Components";
import moment from "moment";
import {
  CheckPermission,
  LoadingSpinner,
  convertUTCToLocalTime,
} from "../../../constants/const";
import { Table } from "reactstrap";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const MemberFunds = ({ tab15, editValue }) => {
  const location = useLocation();
  const [memberFundsDetails, setMemberFundsDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [unitNames, setUnitNames] = useState([]);

  let tempDate = new Date();
  tempDate.setDate(1);
  const [values, setValues] = useState({
    startDate: SelectOnlyDate(tempDate),
    endDate: SelectOnlyDate(new Date()),
    memberId: editValue,
  });
  let months = GetMonths();
  let monthName = "";

  useEffect(() => {
    if (tab15 === "active") {
      setLoading(true);
      addMember()
        .GetMemberFundsDetails(values)
        .then((res) => {
          setMemberFundsDetails(res.data);
          let tempNames = [];

          res.data.forEach((element) => {
            tempNames.push({
              names: element.unitName.split("،"),
            });
          });

          setUnitNames(tempNames);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [values, editValue, tab15]);

  return (
    <div role="tabpanel" id="tab-15" className={"tab-pane " + tab15}>
      <div className="panel-body" style={{ backgroundColor: "#f9f9f9" }}>
        <FundFilter values={values} setValues={setValues} />{" "}
        <div className="ibox ">
          <div className="ibox-title">
            <h5>{<GetLanguageString props="funds_paid_funds" />}</h5>
          </div>
          {/* Table for showing the Paid Fund Details */}
          <div className="ibox-content">
            <>
              {!loading ? (
                memberFundsDetails.length !== 0 ? (
                  <div className="table-responsive">
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>{<GetLanguageString props="funds_month" />}</th>
                          <th>{<GetLanguageString props="funds_year" />}</th>
                          <th>{<GetLanguageString props="funds_date" />}</th>
                          <th>{<GetLanguageString props="dashboard_event_page_unit_name" />}</th>
                          <th>
                            {<GetLanguageString props="funds_receipt_number" />}
                          </th>
                          {CheckPermission("CanViewInfaqAmount") &&
                            location.pathname === "/personalInfo" && (
                              <th>
                                {
                                  <GetLanguageString props="funds_add_fund_amount" />
                                }
                              </th>
                            )}
                        </tr>
                      </thead>
                      <tbody>
                        {memberFundsDetails?.map((record, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              {months.forEach((m) =>
                                m.value === record.month
                                  ? (monthName = m.label)
                                  : null
                              )}
                              <td>{monthName}</td>
                              <td>{record.year}</td>
                              <td>
                                {moment(
                                  convertUTCToLocalTime(record.date)
                                ).format("DD/MM/yyyy")}
                              </td>
                              <td className="ur">
                                <ol className="breadcrumb">
                                  {unitNames[index]?.names?.map(
                                    (item, index) => {
                                      return (
                                        <li
                                          className={
                                            GetSelectedLanguage() === "ur"
                                              ? "breadcrumb-item-ur"
                                              : "breadcrumb-item"
                                          }
                                          key={index}
                                        >
                                          {item}
                                        </li>
                                      );
                                    }
                                  )}
                                </ol>
                              </td>
                              <td>{record.receiptNumber}</td>
                              {CheckPermission("CanViewInfaqAmount") &&
                                location.pathname === "/personalInfo" && ( // Checks if it has permissions then show the Amount
                                  <td>{record.amount}</td>
                                )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <NoResult />
                )
              ) : (
                LoadingSpinner()
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberFunds;
