import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
} from "reactstrap";

import SelectUnit from "../../../shared/SelectUnit";
import { ApiUnit } from "../../../unit/ApiUnit";
import { GetLanguageString } from "../../../helper/Components";
import { encryptStorage } from "../../../../constants/EncryptStorage";
import FundFilter from "../../../helper/SearchFilter/FundFilter";

const UnitWiseInfaqSearchReport = ({ fundsValues, setFundsValues, setSearchButton }) => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [values, setValues] = useState(fundsValues);

  useEffect(() => {
    if (values.unit.value === 0) {
      ApiUnit()
        .getUnit(loggedInUnitId, loggedInUnitId, true)
        .then((res) => {
          let temp = res.data.unitList[0];
          let temp2 = { value: temp.id, lable: temp.name };
          setValues({
            ...values,
            unit: temp2,
          });
        })
        .catch((err) => console.log(err));
    }
  // eslint-disable-next-line
  }, [loggedInUnitId])

  const handleSearch = () => {
    setFundsValues(values);
    setSearchButton(true);
  };

  return (
    <div className="ibox-content">
        <FundFilter values={values} setValues={setValues} />
      <SelectUnit
        values={values}
        setValues={setValues}
        unitId={values.unit.value}
      />
      <Row>
        <Col md="12" className="text-center m-t-sm">
          <Button color="primary" type="submit" onClick={handleSearch}>
            {<GetLanguageString props="funds_submit" />}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default UnitWiseInfaqSearchReport;
