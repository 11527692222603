import axios from "axios";

import { BASEURL } from "../../../constants/const";
import { encryptStorage } from "../../../constants/EncryptStorage";

export function ApiBooks() {
  const userToken = encryptStorage.getItem("token");

  const authAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const url = BASEURL + "books/";
  return {
    getStaticBookType: () =>
      authAxios.get(url + "getStaticBookTypes/"),
      getBooksForMudariseen: () =>
      authAxios.get(url + "GetMudariseenBookTypes/"),
      getAllBooksForMember: (memberId) =>
      authAxios.get(url + "GetAllBooksForMember/" + memberId),
  };
}
