import React, { useState } from "react";
import {
  FormGroup,
  Label,
  Row,
  Col,
  NavLink,
  TabContent,
  TabPane,
  Nav,
  NavItem,
} from "reactstrap";
import Select from "react-select";
import classnames from "classnames";
import { NoResult } from "../helper/Components";

const MemberPermissions = ({ values }) => {
  const [activeTab, setActiveTab] = useState(1);
  const toggleTabs = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div>
      {values?.permissions?.length !== 0 ? (
        <div className="wrapper wrapper-content">
          <Row>
            <Col>
              <div className="tabs-container">
                <div className="tabs-left">
                  <Nav tabs>
                    {values?.permissions?.map((item, index) => {
                      return (
                        <NavItem key={index}>
                          <NavLink
                            className={classnames({
                              active: activeTab === index + 1,
                            })}
                            onClick={() => {
                              toggleTabs(index + 1);
                            }}
                          >
                            <h5>{item.name}</h5>
                          </NavLink>
                        </NavItem>
                      );
                    })}
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    {values?.permissions?.map((item, index) => {
                      return (
                        <TabPane tabId={index + 1} key={index}>
                          <div className="panel-body">
                            {item.claims.map((record, index1) => {
                              return (
                                <>
                                  <Row key={index}>
                                    <Col
                                      md={
                                        !item.name.includes("Funds") &&
                                        !item.name.includes("Other") &&
                                        !item.name.includes("Ahbab") &&
                                        !item.name.includes("Mudaris") 
                                          ? "6"
                                          : "10"
                                      }
                                    >
                                      <FormGroup check>
                                        <Row className="m-4">
                                          <Label>
                                            <h4>{record.label}</h4>
                                          </Label>
                                        </Row>
                                      </FormGroup>
                                    </Col>
                                    {item.name.includes("Rufaqaa") ? (
                                      record.label.includes("Modif") ||
                                      record.label.includes("Move") ||
                                      record.label.includes("Ex") ? (
                                        <Col md="5" className="m">
                                          <FormGroup>
                                            <Select
                                              isDisabled
                                              value={record.selectedOptions}
                                              options={record.options}
                                              className={
                                                record.label.includes("Type") ||
                                                record.label.includes(
                                                  "Responsibili"
                                                ) ||
                                                record.label.includes("Ex")
                                                  ? "basic-multi-select ur"
                                                  : "basic-multi-select"
                                              }
                                              classNamePrefix="Select"
                                              isMulti
                                            />
                                          </FormGroup>
                                        </Col>
                                      ) : null
                                    ) : !item.name.includes("Funds") &&
                                      !item.name.includes("Other") &&
                                      !item.name.includes("Ahbab") &&
                                      !item.name.includes("Mudaris") &&
                                      !record.label.includes("View") ? (
                                      <Col md="5" className="m">
                                        <FormGroup>
                                          <Select
                                            isDisabled
                                            value={record.selectedOptions}
                                            options={record?.options}
                                            className="basic-multi-select ur"
                                            classNamePrefix="Select a Unit Type"
                                            placeholder="نظم کی قسم منتخب کریں"
                                            isMulti
                                          />
                                        </FormGroup>
                                      </Col>
                                    ) : null}
                                  </Row>
                                </>
                              );
                            })}
                          </div>
                        </TabPane>
                      );
                    })}
                  </TabContent>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <NoResult />
      )}
    </div>
  );
};

export default MemberPermissions;
