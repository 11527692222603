import axios from "axios";

import { BASEURL } from "../../../constants/const";
import { encryptStorage } from "../../../constants/EncryptStorage";

export function ApiEducation() {
  const userToken = encryptStorage.getItem("token");

  const authAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const url = BASEURL + "education/";
  return {

    //  EDUCATION DEGREE / CERTIFICATE
    getDegree: () => authAxios.get(url + "GetDegree/"),
    getDegreeById: (id) => authAxios.get(url + "GetDegreeById/" + id),
    addDegree: (data) => authAxios.post(url + "AddDegree/", data),
    editDegree: (id, data) => authAxios.put(url + "EditDegree/" + id, data),
    deleteDegree: (id) => authAxios.delete(url + "DeleteDegree/" + id),

    //  EDUCATION INSTITUTE
    getInstitutes: () => authAxios.get(url + "GetInstitutes/"),
    getInstituteById: (id) => authAxios.get(url + "GetInstituteById/" + id),
    addInstitute: (data) => authAxios.post(url + "AddInstitute/", data),

    //  EDUCATION TYPE
    getEducationType: () => authAxios.get(url + "GetEducationType/"),
    getEducationTypeById: (id) => authAxios.get(url + "GetEducationTypeById/" + id),
    addEducationType: (data) => authAxios.post(url + "AddEducationType/", data),

    //  EDUCATION FIELD
    getEducationFields: () => authAxios.get(url + "GetEducationFields/"),
    getEducationFieldById: (id) => authAxios.get(url + "GetEducationFieldById/" + id),
    addEducationField: (data) => authAxios.post(url + "AddEducationField/", data),
    
    // FOR DROPDOWNS
    getEducationTypesForDropDown: () => authAxios.get(url + "GetEducationTypes"),
    getEducationFieldsDropDown: (degreeId) => authAxios.get(url + "GetEducationFieldsDropDown/" + degreeId),
    
  };
}
