import axios from "axios";

import { BASEURL } from "../../../constants/const";
import { encryptStorage } from "../../../constants/EncryptStorage";

export function ApiUnitType() {
  const userToken = encryptStorage.getItem("token");

  const authAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const url = BASEURL + "unittype/";
  return {
    getUnitType: () => authAxios.get(url + "getunittype/"),
    getUniqueUnitName: () => authAxios.get(url + "getuniqueunitname/"),
    getSortedUnitType: (record) => authAxios.post(url + "getunittype/", record),
    getStaticUnitType: () => authAxios.get(url + "getStaticUnitType/"),
    getUnitTypeSelectList: () => authAxios.get(url + "GetUnitTypeSelectList/"),
    getUnitTypeById: (id) => authAxios.get(url + "getunittypebyid/" + id),
    getUnitTypeForUnit: (id) => authAxios.get(url + "getunittypeforunit/" + id),
    GetUnitTypeForUnitAccordingToPermission: (id) => authAxios.get(url + "GetUnitTypeForUnitAccordingToPermission/" + id),
    setUnitType: (newRecord) => authAxios.post(url + "addunittype/", newRecord),
    deleteUnitType: (id) => authAxios.delete(url + "deleteunittype/" + id),
    updateUnitType: (id, updateRecord) =>
      authAxios.put(url + "putunittype/" + id, updateRecord),
  };
}
