import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { Table, Button, ButtonGroup } from "reactstrap";
import { useTranslation } from "react-i18next";

import { ApiUnitType } from "./ApiUnitType";
import EditUnitType from "./EditUnit";
import {
  GetLanguageString,
  NoResult,
  GetSortingUI,
} from "../../helper/Components";
import { LoadingSpinner } from "../../../constants/const";
import useSorting from "../../shared/useSorting";
import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import AddUnitType from "./AddUnit";
import { IoAdd } from "react-icons/io5";

const initialValues = {
  name: "neutral",
  type: "neutral",
};

const UnitType = ({ activeTab }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [unitType, setUnitType] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [editValue, setEditValue] = useState();
  const [modal, setModal] = useState(false);
  const { sortObject, handleSorting } = useSorting(initialValues);

  useEffect(() => {
    if (activeTab === "unit") {
      setLoading(true);
      let tempSortObject = {
        isTypeAscending:
          sortObject.name === "ascending" || sortObject.type === "ascending",
        isTypeDescending:
          sortObject.name === "descending" || sortObject.type === "descending",
      };
      ApiUnitType()
        .getSortedUnitType(tempSortObject)
        .then((res) => {
          setUnitType(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [sortObject, activeTab]);

  const handleOnDelete = (value, isAffiliated) => {
    if (isAffiliated) {
      swal({
        title: t("common_is_affiliated"),
        text: t("common_is_affiliated_message"),
        className: GetSelectedLanguage() === "en" ? "" : "ur",
        icon: "warning",
      });
    } else {
      swal({
        title: t("common_delete_message"),
        text: t("common_delete_detail_message"),
        className: GetSelectedLanguage() === "en" ? "" : "ur",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setLoading(true);
          ApiUnitType()
            .deleteUnitType(value)
            .then((res) => {
              setUnitType(res.data);
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        }
      });
    }
  };

  const handleOnEdit = (e) => {
    const { value } = e.target;
    setEditValue(value);
    setEditModal(true);
  };

  return (
    <>
      {editModal ? (
        <EditUnitType
          editValue={editValue}
          setEditModal={setEditModal}
          setUnitType={setUnitType}
        />
      ) : null}
      {modal ? (
        <AddUnitType
          setModal={setModal}
          setUnitType={setUnitType}
          unitType={unitType}
        />
      ) : null}
      {!loading ? (
        unitType.length !== 0 ? (
          <div className="ibox">
            <div className="ibox-title">
              <h5>
                <GetLanguageString props="dashboard_event_page_unit_type" />
              </h5>
              <Button
                color="white"
                size="sm"
                className={GetCustomStyles().btn_style}
                onClick={() => {
                  setModal(true);
                }}
              >
                <IoAdd
                  size={18}
                  color="#18A689"
                  style={{ marginRight: 3, marginBottom: 1 }}
                />
                {<GetLanguageString props="settings_add_unit_type" />}
              </Button>
            </div>
            <div className="ibox-content">
              <div className="table-responsive">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th
                        className="cursor-pointer"
                        onClick={() => handleSorting("name")}
                      >
                        <GetLanguageString props="common_name" />
                        <GetSortingUI sortObject={sortObject} name="name" />
                      </th>
                      <th>{<GetLanguageString props="settings_code" />}</th>
                      <th>
                        {<GetLanguageString props="settings_parent_units" />}
                      </th>
                      <th>
                        <GetLanguageString props="settings_color_code" />
                      </th>
                      <th className="text-right">
                        {<GetLanguageString props="common_action" />}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {unitType?.map((record, index) => {
                      return (
                        <tr key={index}>
                          <td className="p-t-custom">{index + 1}</td>
                          <td className="ur p-t-custom">{record.type}</td>
                          <td>{record.code}</td>
                          <td>
                            {record.selectList !== null
                              ? record.selectList.map((record1, index1) => {
                                  return (
                                    <Button
                                      size="xs"
                                      color="info"
                                      className="label label-info m-r-xs"
                                      key={index1}
                                    >
                                      <h6 className="ur">{record1.label}</h6>
                                    </Button>
                                  );
                                })
                              : null}
                          </td>{" "}
                          <td>
                            <div
                              style={{
                                width: "25px",
                                height: "25px",
                                borderRadius: "50%",
                                backgroundColor: record.color,
                              }}
                            >
                              {" "}
                              <span
                                className={
                                  GetSelectedLanguage() === "en"
                                    ? "m-l-lg"
                                    : "m-r-lg"
                                }
                              >
                                {record.color}
                              </span>
                            </div>
                          </td>
                          <td className="text-right">
                            <ButtonGroup>
                              <Button
                                color="white"
                                size="sm"
                                onClick={handleOnEdit}
                                value={record.id}
                              >
                                {<GetLanguageString props="common_edit" />}
                              </Button>
                              <Button
                                color="white"
                                size="sm"
                                onClick={() =>
                                  handleOnDelete(record.id, record.isAffiliated)
                                }
                              >
                                {<GetLanguageString props="common_delete" />}
                              </Button>
                            </ButtonGroup>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        ) : (
          <NoResult />
        )
      ) : (
        LoadingSpinner()
      )}
    </>
  );
};

export default UnitType;
