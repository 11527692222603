import React, { useEffect, useState } from "react";
import { Table, Button } from "reactstrap";

import { GetLanguageString } from "../helper/Components";
import { GetCustomStyles } from "../helper/Method";
import SearchSMSReport from "./SMSSerachReport";
import { smsApi } from "./SendSMSApi";
import { LoadingSpinner } from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";

const SMSReport = () => {
  const [loading, setLoading] = useState(false);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [parentUnitId, setParentUnitId] = useState(null);
  const [loggedInUnitParentId, setLoggedInUnitParentId] = useState(null);
  const [memberSMSDetails, setMemberSMSDetails] = useState({
    unitMembers: [],
    childUnits: [],
  });
  const [smsSerachValues, setSMSSerachValues] = useState({
    unit: { value: loggedInUnitId, lable: "" },
    startDate: null,
    endDate: null,
    userIds: [],
  });

  useEffect(() => {
    setLoading(true);
    smsApi()
      .getMultipleUserCost(smsSerachValues)
      .then((res) => {
        setMemberSMSDetails(res.data);
        if (loggedInUnitId !== 0) {
          setLoggedInUnitParentId(res.data.parentUnitId);
          setParentUnitId(res.data.parentUnitId);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [smsSerachValues, loggedInUnitId]);

  const handleSubUnits = (tempValue) => {
    smsSerachValues.unit = { value: tempValue, lable: "" };
    setLoading(true);
    smsApi()
      .getMultipleUserCost(smsSerachValues)
      .then((res) => {
        setMemberSMSDetails(res.data);
        setParentUnitId(res.data.parentUnitId);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const back = (e) => {
    const { value } = e.target;
    smsSerachValues.unit = { value: value, lable: "" };
    setLoading(true);
    smsApi()
      .getMultipleUserCost(smsSerachValues)
      .then((res) => {
        setMemberSMSDetails(res.data);
        setParentUnitId(res.data.parentUnitId);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
        <div className="ibox ">
          <div className="ibox-title">
            <h5>
              {<GetLanguageString props="funds_select_unit_and_member" />}
            </h5>
          </div>
          <div className="ibox-content">
            <SearchSMSReport
              smsSerachValues={smsSerachValues}
              setSMSSerachValues={setSMSSerachValues}
            />
          </div>
        </div>
        {parentUnitId !== loggedInUnitParentId ? (
          <div className="m-b-xxl">
            <Button
              onClick={back}
              value={parentUnitId}
              className={GetCustomStyles().btn_style}
            >
              {<GetLanguageString props="unit_back_btn" />}
            </Button>
          </div>
        ) : null}
        {!loading ? (
          <>
            {memberSMSDetails.childUnits.length !== 0 ? (
              <div className="panel panel-primary">
                <div className="panel-heading">
                  <h5>{<GetLanguageString props="sms_unit_details" />}</h5>
                </div>
                <div className="panel-body">
                  <div className="table-responsive">
                    <Table className="table-hover">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>
                            {
                              <GetLanguageString props="sms_unit_details_unit_name" />
                            }
                          </th>
                          <th className="text-center">
                            {<GetLanguageString props="sms_count" />}
                          </th>
                          <th className="text-center">
                            {<GetLanguageString props="sms_cost" />}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {memberSMSDetails.childUnits.map((record, index) => {
                          return (
                            <tr
                              key={index}
                              onClick={() => handleSubUnits(record.id)}
                            >
                              <td>{index + 1}</td>
                              <td className="ur">{record.name}</td>
                              <td className="text-center">{record.smsCount}</td>
                              <td className="text-center">
                                {record.smsCost} PKR
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            ) : null}
            {memberSMSDetails.unitMembers.length !== 0 ? (
              <div className="panel panel-info ">
                <div className="panel-heading">
                  <h5>{<GetLanguageString props="sms_member_details" />}</h5>
                </div>
                <div className="panel-body">
                  <div className="table-responsive">
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>
                            {
                              <GetLanguageString props="sms_member_details_unit_name" />
                            }
                          </th>
                          <th className="text-center">
                            {<GetLanguageString props="sms_count" />}
                          </th>
                          <th className="text-center">
                            {<GetLanguageString props="sms_cost" />}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {memberSMSDetails.unitMembers.map((record, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td className="ur">{record.name}</td>
                              <td className="text-center">{record.smsCount}</td>
                              <td className="text-center">
                                {record.smsCost} PKR
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            ) : null}
          </>
        ) : (
          LoadingSpinner()
        )}
      </div>
    </>
  );
};

export default SMSReport;
