import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import {
  Table,
  Col,
  Row,
  Button,
  NavLink,
  ButtonGroup,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

import AddUnit from "./AddUnit";
import EditUnit from "./EditUnit";
import { ApiUnit } from "./ApiUnit";
import { GetLanguageString, NoResult } from "../helper/Components";
import { GetCustomStyles, GetFooTableCustomStyles } from "../helper/Method";
import { GetSelectedLanguage } from "../helper/Method";
import UnitMembers from "./UnitMembers";
import {
  CheckIsAdmin,
  CheckPermission,
  LoadingSpinner,
  convertUTCToLocalTime,
} from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";
import NonResponsibleUnitMembers from "./NonResponsibleUnitMembers";
import moment from "moment";
import DeleteUnit from "./DeleteUnit";
import MoveUnit from "./MoveUnit";
import UnitAhbab from "./UnitAhbab";
import { IoAdd } from "react-icons/io5";

const initialValues = {
  name: "neutral",
  totalCount: "neutral",
  memberCount: "neutral",
  unitType: "neutral",
  childCount: "neutral",
  unitDate: "neutral",
  code: "neutral",
};

const Unit = ({ history, match }) => {
  const [unitLoading, setUnitLoading] = useState(false);

  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const isIncharge = encryptStorage.getItem("canManage");
  const unitTypeName = encryptStorage.getItem("unitTypeName");
  const [unit, setUnit] = useState([]); // Displays the list of the Units
  const [modal, setModal] = useState(false); // This modal is called when we add a new Unit
  const [editModal, setEditModal] = useState(false); // This modal is called when we edit a Unit
  const [deleteUnitModal, setDeleteUnitModal] = useState(false); // This modal is called when we delete a Unit
  const [editValue, setEditValue] = useState(); // This is the value of the record which want o be edit
  const [unitId, setUnitId] = useState(loggedInUnitId);
  const [unitName, setUnitName] = useState(null);
  const [parentUnitIdForMove, setParentUnitIdForMove] = useState(0);
  const [unitTypeNameForMoveUnit, setUnitTypeNameForMoveUnit] = useState(null);
  const [loggedInUnitParentId, setLoggedInUnitParentId] = useState(null);
  const [parentUnitId, setParentUnitId] = useState(null);
  const [unitTypeId, setUnitTypeId] = useState(null);
  const [isChildUnit, setIsChildUnit] = useState(false);
  const [isUnitMember, setIsUnitMember] = useState(false);
  const [unitIdForDelete, setUnitIdForDelete] = useState(false);
  const [canAddUnit, setCanAddUnit] = useState(false);
  const [refreshList, setRefreshList] = useState(false);
  const [moveUnitModal, setMoveUnitModal] = useState(false);
  const [moveUnitData, setMoveUnitData] = useState();
  const [containsExUnit, setContainsExUnit] = useState(false);
  const [exUnit, setExUnit] = useState(true);

  const [unitBreadCrumbs, setUnitBreadCrumbs] = useState([]);
  const toggle = () => setModal(!modal);

  const [tab1, setTab1] = useState("active");
  const [tab2, setTab2] = useState("");
  const [tab3, setTab3] = useState("");
  const [tab4, setTab4] = useState("");
  const [sortObject, setSortObject] = useState(initialValues);

  const handleSorting = (name) => {
    if (sortObject[name] === "neutral" || sortObject[name] === "descending") {
      setSortObject({
        ...initialValues,
        [name]: "ascending",
      });
    } else if (sortObject[name] === "ascending") {
      setSortObject({
        ...initialValues,
        [name]: "descending",
      });
    }
  };

  useEffect(() => {
    setUnitLoading(true);
    let tempSortObject = {
      isNameDescending: sortObject.name === "descending",
      isNameAscending: sortObject.name === "ascending",
      isTotalMembersCountDescending: sortObject.totalCount === "descending",
      isTotalMembersCountAscending: sortObject.totalCount === "ascending",
      isChildUnitsCountAscending: sortObject.childCount === "ascending",
      isChildUnitsCountDescending: sortObject.childCount === "descending",
      isUnitTypeAscending: sortObject.unitType === "ascending",
      isUnitTypeDescending: sortObject.unitType === "descending",
      isUnitMembersCountAscending: sortObject.memberCount === "ascending",
      isUnitMembersCountDescending: sortObject.memberCount === "descending",
      isUnitDateAscending: sortObject.unitDate === "ascending",
      isUnitDateDescending: sortObject.unitDate === "descending",
      isCodeAscending: sortObject.code === "ascending",
      isCodeDescending: sortObject.code === "descending",
    };
    ApiUnit()
      .getUnit(unitId, unitId, exUnit, tempSortObject)
      .then((res) => {
        setUnitName(res.data.name);
        setUnitTypeId(res.data.unitTypeId);
        setParentUnitId(res.data.parentUnitId);
        setCanAddUnit(res.data.canAdd);
        setContainsExUnit(res.data.containExUnit);
        if (res.data.unitList !== null) setUnit(res.data.unitList);
        else setUnit([]);
        setUnitLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setUnitLoading(false);
      });
  }, [sortObject, unitId, exUnit]);

  useEffect(() => {
    setUnitLoading(true);
    ApiUnit()
      .getUnit(loggedInUnitId, loggedInUnitId, exUnit)
      .then((res) => {
        let unitNames = [];

        if (res.data.unitList !== null) {
          setUnit(res.data.unitList);
        } else {
          setUnit([]);
        }
        if (loggedInUnitId === 0 && res.data.unitList !== null) {
          unitNames.push({
            id: res.data.unitList[0].id,
            name: res.data.unitList[0].name,
          });
        } else {
          unitNames.push({
            id: res.data.id,
            name: res.data.name,
          });
        }
        setUnitBreadCrumbs(unitNames);
        setLoggedInUnitParentId(res.data.parentUnitId);
        setParentUnitId(res.data.parentUnitId);
        setUnitName(res.data.name);
        setUnitLoading(false);
        setCanAddUnit(res.data.canAdd);
        setContainsExUnit(res.data.containExUnit);
      })
      .catch((err) => {
        console.log(err);
        setUnitLoading(false);
      });
  }, [loggedInUnitId, refreshList, exUnit]);

  const handleOnEdit = (e) => {
    const { value } = e.target;
    setEditValue(value);
    setEditModal(true);
  };

  const handleEnableSMS = (e) => {
    const { value } = e.target;
    swal({
      title: "Are you Sure",
      text: "This effect will also happen to its child unit. \n If any",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((sms) => {
      if (sms) {
        ApiUnit()
          .smsStatus(value, loggedInUnitId)
          .then((res) => {
            setUnit(res.data.unitList);
          })
          .catch((err) => console.log(err));
      }
    });
  };

  const handleOnMoveMember = (e) => {
    const { value, name } = e.target;
    history.push({
      pathname: `${match.url}/movemembers`,
      state: {
        unitId: value,
        check: name,
      },
    });
  };

  const handleSubUnits = (value, name) => {
    setUnitId(value);
    let unitNames = [...unitBreadCrumbs];
    unitNames.push({
      id: value,
      name: name,
    });
    if (unitBreadCrumbs[0]?.id !== value) setUnitBreadCrumbs(unitNames);
  };

  const back = (e) => {
    const { value } = e.target;

    setUnitId(value);
    let unitNames = [...unitBreadCrumbs];
    unitNames.pop();
    if (unitBreadCrumbs.length !== 1) setUnitBreadCrumbs(unitNames);
  };

  const handleBreadCrumbs = (index, value) => {
    if (value !== unitId) {
      let unitNames = [...unitBreadCrumbs];
      unitNames = unitNames.slice(0, index + 1);
      setUnitBreadCrumbs(unitNames);
      setUnitId(value);
    }
  };

  const handleMoveUnit = (
    id,
    moveUnitParentUnitId,
    code,
    unitTypeNameEn,
    unitTypeParents
  ) => {
    setMoveUnitData({
      id: id,
      parentUnitId: moveUnitParentUnitId,
      newUnitCode: "",
      code: code,
      creationDate: new Date(),
      unitTypeName: unitTypeNameEn,
      unitTypeParents: unitTypeParents,
    });
    setMoveUnitModal(true);
  };

  const handleExUnits = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = false;
    else v = true;
    setExUnit(v);
  };

  return (
    <div>
      <>
        {modal ? (
          <AddUnit
            loggedInUnitId={loggedInUnitId}
            setModal={setModal}
            setUnit={setUnit}
            unitId={unitId}
            unitTypeId={unitTypeId}
          />
        ) : null}
        {editModal ? (
          <EditUnit
            loggedInUnitId={loggedInUnitId}
            editValue={editValue}
            setEditModal={setEditModal}
            setUnit={setUnit}
            unitId={unitId}
          />
        ) : null}
        {deleteUnitModal ? (
          <DeleteUnit
            id={unitIdForDelete}
            isChildUnit={isChildUnit}
            isUnitMember={isUnitMember}
            setDeleteUnitModal={setDeleteUnitModal}
            setUnit={setUnit}
          />
        ) : null}
        {moveUnitModal ? (
          <MoveUnit
            setMoveUnitModal={setMoveUnitModal}
            moveUnitData={moveUnitData}
            setMoveUnitData={setMoveUnitData}
            unitName={unitName}
            unitTypeName={unitTypeNameForMoveUnit}
            refreshList={refreshList}
            setRefreshList={setRefreshList}
            parentUnitIdForMove={parentUnitIdForMove}
          />
        ) : null}
      </>
      <div className="row wrapper wrapper-custom border-bottom white-bg page-heading">
        <div className="col-lg-8">
          <h2 className="ur m-b-md">
            {unitBreadCrumbs.length !== 0
              ? unitBreadCrumbs[unitBreadCrumbs.length - 1].name
              : ""}
          </h2>
          <ol className="breadcrumb">
            {unitBreadCrumbs?.map((item, index) => {
              return (
                <li
                  className={
                    GetSelectedLanguage() === "ur"
                      ? "breadcrumb-item-ur"
                      : "breadcrumb-item"
                  }
                  key={index}
                  onClick={() => {
                    handleBreadCrumbs(index, item.id);
                  }}
                >
                  <Link to="#" className="ur">
                    {item.name}
                  </Link>
                </li>
              );
            })}
          </ol>
          {containsExUnit && (
            <>
              <br />
              <FormGroup check>
                <Label check for="subUnit">
                  <Input
                    id="exUnit"
                    type="checkbox"
                    name="exUnit"
                    checked={!exUnit}
                    value={exUnit}
                    onChange={handleExUnits}
                  />
                  {<GetLanguageString props="member_search_member_ex_unit" />}
                </Label>
              </FormGroup>
            </>
          )}
        </div>
        <div className="col-lg-4 m-t-md">
          <div className="title-action">
            <ButtonGroup className={GetCustomStyles().unit_btn_style}>
              {parentUnitId !== loggedInUnitParentId ? (
                <Button size="sm" onClick={back} value={parentUnitId}>
                  {<GetLanguageString props="unit_back_btn" />}
                </Button>
              ) : null}
              {canAddUnit ? (
                <Button color="primary" size="sm" onClick={toggle}>
                  <IoAdd
                    size={18}
                    style={{ marginRight: 3, marginBottom: 1 }}
                  />
                  {<GetLanguageString props="unit_add_unit" />}
                </Button>
              ) : null}
            </ButtonGroup>
          </div>
        </div>
      </div>
      <div className="wrapper wrapper-content">
        <Row>
          <Col md="12">
            <div className="tabs-container">
              <ul className="nav nav-tabs" role="tablist">
                {/* Tab 1 Units Tab*/}
                <li>
                  <NavLink
                    to="#"
                    className={"nav-link " + tab1}
                    onClick={() => {
                      setTab1("active");
                      setTab2("");
                      setTab3("");
                      setTab4("");
                    }}
                  >
                    {unitName !== null ? (
                      <GetLanguageString props="unit_unit_table_name" />
                    ) : (
                      <GetLanguageString props="unit_unit_table" />
                    )}
                  </NavLink>
                </li>

                {/* Tab 2 Responsible Members Tab*/}
                <li>
                  <NavLink
                    to="#"
                    className={"nav-link " + tab2}
                    onClick={() => {
                      setTab1("");
                      setTab2("active");
                      setTab3("");
                      setTab4("");
                    }}
                  >
                    {<GetLanguageString props="unit_responsible_member" />}
                  </NavLink>
                </li>

                {/* Tab 3 Non Responsible Tab */}
                <li>
                  <NavLink
                    to="#"
                    className={"nav-link " + tab3}
                    onClick={() => {
                      setTab1("");
                      setTab2("");
                      setTab3("active");
                      setTab4("");
                    }}
                  >
                    {<GetLanguageString props="unit_non_responsible_member" />}
                  </NavLink>
                </li>

                {/* Tab 4 Ahbab Tab */}
                <li>
                  <NavLink
                    to="#"
                    className={"nav-link " + tab4}
                    onClick={() => {
                      setTab1("");
                      setTab2("");
                      setTab3("");
                      setTab4("active");
                    }}
                  >
                    {<GetLanguageString props="unit_ahbaab" />}
                  </NavLink>
                </li>
              </ul>
              <div className="tab-content">
                <div role="tabpanel" id="tab-1" className={"tab-pane " + tab1}>
                  <div className="panel-body">
                    {!unitLoading ? (
                      <div className="table-responsive">
                        {unit.length !== 0 ? (
                          <Table responsive>
                            <thead>
                              <tr>
                                <th>#</th>
                                <th
                                  className="cursor-pointer text-center"
                                  onClick={() => handleSorting("code")}
                                >
                                  {<GetLanguageString props="settings_code" />}
                                  <span
                                    className={
                                      GetFooTableCustomStyles().span_style
                                    }
                                  >
                                    {sortObject.code === "neutral" ? (
                                      <>
                                        <i
                                          className={
                                            GetFooTableCustomStyles()
                                              .up_down_style
                                          }
                                        />
                                        <i
                                          className={
                                            GetFooTableCustomStyles()
                                              .down_up_style
                                          }
                                        />
                                      </>
                                    ) : sortObject.code === "ascending" ? (
                                      <i
                                        className={
                                          GetFooTableCustomStyles().up_style
                                        }
                                      />
                                    ) : (
                                      <i
                                        className={
                                          GetFooTableCustomStyles().down_style
                                        }
                                      />
                                    )}
                                  </span>
                                </th>
                                <th
                                  className="cursor-pointer"
                                  onClick={() => handleSorting("name")}
                                >
                                  {<GetLanguageString props="common_name" />}
                                  <span
                                    className={
                                      GetFooTableCustomStyles().span_style
                                    }
                                  >
                                    {sortObject.name === "neutral" ? (
                                      <>
                                        <i
                                          className={
                                            GetFooTableCustomStyles()
                                              .up_down_style
                                          }
                                        />
                                        <i
                                          className={
                                            GetFooTableCustomStyles()
                                              .down_up_style
                                          }
                                        />
                                      </>
                                    ) : sortObject.name === "ascending" ? (
                                      <i
                                        className={
                                          GetFooTableCustomStyles().up_style
                                        }
                                      />
                                    ) : (
                                      <i
                                        className={
                                          GetFooTableCustomStyles().down_style
                                        }
                                      />
                                    )}
                                  </span>
                                </th>
                                <th
                                  className="text-center cursor-pointer"
                                  onClick={() => handleSorting("unitDate")}
                                >
                                  {
                                    <GetLanguageString props="member_member_detail_member_type_history_date" />
                                  }
                                  <span
                                    className={
                                      GetFooTableCustomStyles().span_style
                                    }
                                  >
                                    {sortObject.unitDate === "neutral" ? (
                                      <>
                                        <i
                                          className={
                                            GetFooTableCustomStyles()
                                              .up_down_style
                                          }
                                        />
                                        <i
                                          className={
                                            GetFooTableCustomStyles()
                                              .down_up_style
                                          }
                                        />
                                      </>
                                    ) : sortObject.unitDate === "ascending" ? (
                                      <i
                                        className={
                                          GetFooTableCustomStyles().up_style
                                        }
                                      />
                                    ) : (
                                      <i
                                        className={
                                          GetFooTableCustomStyles().down_style
                                        }
                                      />
                                    )}
                                  </span>
                                </th>
                                <th
                                  className="text-center cursor-pointer"
                                  onClick={() => handleSorting("totalCount")}
                                >
                                  {
                                    <GetLanguageString props="unit_total_member_count" />
                                  }
                                  <span
                                    className={
                                      GetFooTableCustomStyles().span_style
                                    }
                                  >
                                    {sortObject.totalCount === "neutral" ? (
                                      <>
                                        <i
                                          className={
                                            GetFooTableCustomStyles()
                                              .up_down_style
                                          }
                                        />
                                        <i
                                          className={
                                            GetFooTableCustomStyles()
                                              .down_up_style
                                          }
                                        />
                                      </>
                                    ) : sortObject.totalCount ===
                                      "ascending" ? (
                                      <i
                                        className={
                                          GetFooTableCustomStyles().up_style
                                        }
                                      />
                                    ) : (
                                      <i
                                        className={
                                          GetFooTableCustomStyles().down_style
                                        }
                                      />
                                    )}
                                  </span>
                                </th>
                                <th
                                  className="text-center cursor-pointer"
                                  onClick={() => handleSorting("childCount")}
                                >
                                  {
                                    <GetLanguageString props="unit_child_unit" />
                                  }
                                  <span
                                    className={
                                      GetFooTableCustomStyles().span_style
                                    }
                                  >
                                    {sortObject.childCount === "neutral" ? (
                                      <>
                                        <i
                                          className={
                                            GetFooTableCustomStyles()
                                              .up_down_style
                                          }
                                        />
                                        <i
                                          className={
                                            GetFooTableCustomStyles()
                                              .down_up_style
                                          }
                                        />
                                      </>
                                    ) : sortObject.childCount ===
                                      "ascending" ? (
                                      <i
                                        className={
                                          GetFooTableCustomStyles().up_style
                                        }
                                      />
                                    ) : (
                                      <i
                                        className={
                                          GetFooTableCustomStyles().down_style
                                        }
                                      />
                                    )}
                                  </span>
                                </th>
                                <th
                                  className="cursor-pointer"
                                  onClick={() => handleSorting("unitType")}
                                >
                                  {
                                    <GetLanguageString props="settings_unit_type" />
                                  }
                                  <span
                                    className={
                                      GetFooTableCustomStyles().span_style
                                    }
                                  >
                                    {sortObject.unitType === "neutral" ? (
                                      <>
                                        <i
                                          className={
                                            GetFooTableCustomStyles()
                                              .up_down_style
                                          }
                                        />
                                        <i
                                          className={
                                            GetFooTableCustomStyles()
                                              .down_up_style
                                          }
                                        />
                                      </>
                                    ) : sortObject.unitType === "ascending" ? (
                                      <i
                                        className={
                                          GetFooTableCustomStyles().up_style
                                        }
                                      />
                                    ) : (
                                      <i
                                        className={
                                          GetFooTableCustomStyles().down_style
                                        }
                                      />
                                    )}
                                  </span>
                                </th>
                                {isIncharge ? (
                                  <th className="text-right">
                                    {
                                      <GetLanguageString props="common_action" />
                                    }
                                  </th>
                                ) : null}
                              </tr>
                            </thead>
                            <tbody>
                              {unit.map((record, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td className="text-center">
                                      {record.code}
                                    </td>
                                    <td>
                                      <Link
                                        to="#"
                                        onClick={() => {
                                          handleSubUnits(
                                            record.id,
                                            record.name
                                          );
                                        }}
                                      >
                                        <h4 className="ur">{record.name}</h4>
                                      </Link>
                                    </td>
                                    <td className="text-center">
                                      {record.unitDate
                                        ? moment(
                                            convertUTCToLocalTime(
                                              record.unitDate
                                            )
                                          ).format("DD/MM/yyyy")
                                        : "-"}
                                    </td>
                                    <td className="text-center">
                                      {record.totalMembersCount}
                                    </td>
                                    <td className="text-center">
                                      {record.childUnitsCount}
                                    </td>
                                    <td>
                                      <Button
                                        size="xs"
                                        color="info"
                                        className="label label-info m-r-xs"
                                      >
                                        <h5 className="ur">
                                          {record.unitTypeName}
                                        </h5>
                                      </Button>
                                    </td>
                                    {isIncharge ? (
                                      <td className="text-right">
                                        <ButtonGroup>
                                          {record.canMoveUnit &&
                                          parentUnitId !== 0 ? (
                                            <Button
                                              color="white"
                                              size="sm"
                                              onClick={() => {
                                                handleMoveUnit(
                                                  record.id,
                                                  unitId,
                                                  record.code,
                                                  record.unitTypeNameEn,
                                                  record.unitTypeParents
                                                );
                                                setUnitName(record.name);
                                                setUnitTypeNameForMoveUnit(
                                                  record.unitTypeName
                                                );
                                                setParentUnitIdForMove(
                                                  record.parentUnitId
                                                );
                                              }}
                                            >
                                              <GetLanguageString props="unit_move_unit" />
                                            </Button>
                                          ) : null}
                                          <Button
                                            color="white"
                                            size="sm"
                                            onClick={handleEnableSMS}
                                            value={record.id}
                                            disabled={
                                              !record.isParentUnitSmsEnabled
                                            }
                                          >
                                            {record.enableSms ? (
                                              <GetLanguageString props="unit_turn_sms_off" />
                                            ) : (
                                              <GetLanguageString props="unit_turn_sms_on" />
                                            )}
                                          </Button>
                                          {record.isUnitMember &&
                                          CheckIsAdmin() ? (
                                            <Button
                                              color="white"
                                              size="sm"
                                              onClick={handleOnMoveMember}
                                              name="admin"
                                              value={record.id}
                                            >
                                              {
                                                <GetLanguageString props="unit_unitmember_move_member" />
                                              }
                                            </Button>
                                          ) : null}
                                          {record.isUnitMember &&
                                          !CheckIsAdmin() &&
                                          CheckPermission(
                                            "CanMoveMembersLocalTransfer"
                                          ) ? (
                                            <Button
                                              color="white"
                                              size="sm"
                                              onClick={handleOnMoveMember}
                                              name="Local"
                                              value={record.id}
                                            >
                                              {unitTypeName === "Halqa" ? (
                                                <GetLanguageString props="unit_unitmember_move_member_localy" />
                                              ) : unitTypeName === "Tanzeem" ? (
                                                <GetLanguageString props="unit_unitmember_move_member_localy" />
                                              ) : (
                                                <GetLanguageString props="unit_unitmember_move_member_localy" />
                                              )}
                                            </Button>
                                          ) : null}
                                          {/* {record.isUnitMember &&
                                          !CheckIsAdmin() &&
                                          (CheckPermission(
                                            "CanMoveMembersOutsideTransfer"
                                          ) ||
                                            CheckPermission(
                                              "CanRequestMoveMembersOutsideTransfer"
                                            )) ? (
                                            <Button
                                              color="white"
                                              size="sm"
                                              onClick={handleOnMoveMember}
                                              name="Outside"
                                              value={record.id}
                                            >
                                              {unitTypeName === "Halqa" ? (
                                                <GetLanguageString props="unit_unitmember_move_member_other_halqa" />
                                              ) : unitTypeName === "Tanzeem" ? (
                                                <GetLanguageString props="unit_unitmember_move_member_other_tanzeem" />
                                              ) : (
                                                <GetLanguageString props="unit_unitmember_move_member_other" />
                                              )}
                                            </Button>
                                          ) : null} */}
                                          {record.canEdit ? (
                                            <Button
                                              color="white"
                                              size="sm"
                                              onClick={handleOnEdit}
                                              value={record.id}
                                            >
                                              {
                                                <GetLanguageString props="common_edit" />
                                              }
                                            </Button>
                                          ) : null}
                                          {record.canDelete ? (
                                            <Button
                                              color="white"
                                              size="sm"
                                              onClick={() => {
                                                setUnitIdForDelete(record.id);
                                                setIsUnitMember(
                                                  record.isUnitMember
                                                );
                                                setIsChildUnit(
                                                  record.isChildUnit
                                                );
                                                setDeleteUnitModal(true);
                                              }}
                                            >
                                              {
                                                <GetLanguageString props="common_delete" />
                                              }
                                            </Button>
                                          ) : null}
                                        </ButtonGroup>
                                      </td>
                                    ) : null}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        ) : (
                          <NoResult />
                        )}
                      </div>
                    ) : (
                      LoadingSpinner()
                    )}
                  </div>
                </div>
                <NonResponsibleUnitMembers
                  parentUnitId={parentUnitId}
                  unitId={unitId}
                  tab3={tab3}
                />

                <UnitAhbab
                  parentUnitId={parentUnitId}
                  unitId={unitId}
                  tab4={tab4}
                />

                <UnitMembers unitName={unitName} unitId={unitId} tab2={tab2} />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default withRouter(Unit);
