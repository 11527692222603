import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Input, Row, Table } from "reactstrap";

const Counts = ({ countingSection, setCountingSection }) => {
  const [membersCounts, setMembersCounts] = useState(
    countingSection.membersCounts
  );
  const [fundsMembersCount, setFundsMembersCount] = useState(
    countingSection.fundsMembersCount
  );
  const [booksCompletedMembersCount, setBooksCompletedMembersCount] = useState(
    countingSection.booksCompletedMembersCount
  );
  const [membersKnowPersonally, setMembersKnowPersonally] = useState(
    countingSection.membersKnowPersonally
  );
  const [membersMeetingHappens, setMembersMeetingHappens] = useState(
    countingSection.membersMeetingHappens
  );
  const [tarafiIjtimaMembersCount, setTarafiIjtimaMembersCount] = useState(
    countingSection.tarafiIjtimaMembersCount
  );
  const [tarbiyatiIjtimaMembersCount, setTarbiyatiIjtimaMembersCount] =
    useState(countingSection.tarbiyatiIjtimaMembersCount);

  useEffect(() => {
    setCountingSection({
      ...countingSection,
      membersCounts: membersCounts,
      tarafiIjtimaMembersCount: tarafiIjtimaMembersCount,
      tarbiyatiIjtimaMembersCount: tarbiyatiIjtimaMembersCount,
      fundsMembersCount: fundsMembersCount,
      booksCompletedMembersCount: booksCompletedMembersCount,
      membersMeetingHappens: membersMeetingHappens,
      membersKnowPersonally: membersKnowPersonally,
    });
  }, [
    booksCompletedMembersCount,
    countingSection,
    setCountingSection,
    membersCounts,
    fundsMembersCount,
    membersKnowPersonally,
    membersMeetingHappens,
    tarbiyatiIjtimaMembersCount,
    tarafiIjtimaMembersCount,
  ]);

  const handleMembersCounts = (e) => {
    const { name, value } = e.target;
    setMembersCounts({
      ...membersCounts,
      [name]: value,
    });
  };
  const handleFundsMembersCount = (e) => {
    const { name, value } = e.target;
    setFundsMembersCount({
      ...fundsMembersCount,
      [name]: value,
    });
  };
  const handleTarafiIjtimaMembersCount = (e) => {
    const { name, value } = e.target;
    setTarafiIjtimaMembersCount({
      ...tarafiIjtimaMembersCount,
      [name]: value,
    });
  };
  const handleTarbiyatiIjtimaMembersCount = (e) => {
    const { name, value } = e.target;
    setTarbiyatiIjtimaMembersCount({
      ...tarbiyatiIjtimaMembersCount,
      [name]: value,
    });
  };
  const handleMembersMeetingHappens = (e) => {
    const { name, value } = e.target;
    setMembersMeetingHappens({
      ...membersMeetingHappens,
      [name]: value,
    });
  };
  const handleBooksCompletedMembersCount = (e) => {
    const { name, value } = e.target;
    setBooksCompletedMembersCount({
      ...booksCompletedMembersCount,
      [name]: value,
    });
  };
  const handleMembersKnowPersonally = (e) => {
    const { name, value } = e.target;
    setMembersKnowPersonally({
      ...membersKnowPersonally,
      [name]: value,
    });
  };

  return (
    <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
      <div className="ibox panel panel-info ur">
        <div className="panel-heading"></div>
        <div className="panel-body ibox-content">
          <Table responsive> size="sm">
            <thead>
              <tr>
                <th className="text-center">
                  <h3>اعداد شمار</h3>
                </th>
                <th className="text-center">
                  <h3>تعداد</h3>
                </th>
                <th className="text-center">
                  <tr>
                    <h3>تعارفی اجتماع</h3>
                    <h4>(شرکت کر چکنے والے)</h4>
                  </tr>
                </th>
                <th className="text-center">
                  <tr>
                    <h3>تربیتی کورس </h3>
                    <h4>(کر چکنے والے)</h4>
                  </tr>
                </th>
                <th className="text-center">
                  <tr>
                    <h3>نصاب کی تکمیل</h3>
                    <h4>(کر چکنے والے)</h4>
                  </tr>
                </th>
                <th className="text-center">
                  <h3>انفاق (کرنے والے )</h3>
                </th>
                <th className="text-center">
                  <h3>
                    کتنوں سے تنظیمی اجتماعات و امور کے علاوہ رابطہ/ملاقات رہی؟
                  </h3>
                </th>
                <th className="text-center">
                  <h3>
                    کتنوں کے ذاتی،خانگی،معاشرتی و معاشی حالات سے آپ واقف ہیں؟
                  </h3>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <h4>{"ملتزم"}</h4>
                </td>
                <td>
                  <Input
                    className="text-center"
                    type="text"
                    name="totalMultazimCount"
                    value={membersCounts?.totalMultazimCount}
                    onChange={handleMembersCounts}
                  />
                </td>
                <td>
                  <Input
                    className="text-center"
                    type="text"
                    name="firstName"
                    value={"-"}
                    disabled
                  />
                </td>
                <td>
                  <Input
                    className="text-center"
                    type="text"
                    name="multazimCount"
                    value={tarbiyatiIjtimaMembersCount?.multazimCount}
                    onChange={handleTarbiyatiIjtimaMembersCount}
                  />
                </td>
                <td>
                  <Input
                    className="text-center"
                    type="text"
                    name="multazimCount"
                    value={booksCompletedMembersCount?.multazimCount}
                    onChange={handleBooksCompletedMembersCount}
                  />
                </td>
                <td>
                  <Input
                    className="text-center"
                    type="text"
                    name="multazimCount"
                    value={fundsMembersCount?.multazimCount}
                    onChange={handleFundsMembersCount}
                  />
                </td>
                <td>
                  <Input
                    className="text-center"
                    type="text"
                    name="multazimCount"
                    value={membersMeetingHappens?.multazimCount}
                    onChange={handleMembersMeetingHappens}
                  />
                </td>
                <td>
                  <Input
                    className="text-center"
                    type="text"
                    name="multazimCount"
                    value={membersKnowPersonally?.multazimCount}
                    onChange={handleMembersKnowPersonally}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <h4>{"معتذر"}</h4>
                </td>
                <td>
                  <Input
                    className="text-center"
                    type="text"
                    name="totalMuatazizCount"
                    value={membersCounts?.totalMuatazizCount}
                    onChange={handleMembersCounts}
                  />
                </td>
                <td>
                  <Input
                    className="text-center"
                    type="text"
                    value={"-"}
                    disabled
                  />
                </td>
                <td>
                  <Input
                    className="text-center"
                    type="text"
                    value={"-"}
                    disabled
                  />
                </td>
                <td>
                  <Input
                    className="text-center"
                    type="text"
                    name="muatazizCount"
                    value={booksCompletedMembersCount?.muatazizCount}
                    onChange={handleBooksCompletedMembersCount}
                  />
                </td>
                <td>
                  <Input
                    className="text-center"
                    type="text"
                    name="muatazizCount"
                    value={fundsMembersCount?.muatazizCount}
                    onChange={handleFundsMembersCount}
                  />
                </td>
                <td>
                  <Input
                    className="text-center"
                    type="text"
                    name="muatazizCount"
                    value={membersMeetingHappens?.muatazizCount}
                    onChange={handleMembersMeetingHappens}
                  />
                </td>
                <td>
                  <Input
                    className="text-center"
                    type="text"
                    name="muatazizCount"
                    value={membersKnowPersonally?.muatazizCount}
                    onChange={handleMembersKnowPersonally}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <h4>{"مبتدی"}</h4>
                </td>
                <td>
                  <Input
                    className="text-center"
                    type="text"
                    name="totalMubtadiCount"
                    value={membersCounts?.totalMubtadiCount}
                    onChange={handleMembersCounts}
                  />
                </td>
                <td>
                  <Input
                    className="text-center"
                    type="text"
                    name="mubtadiCount"
                    value={tarafiIjtimaMembersCount?.mubtadiCount}
                    onChange={handleTarafiIjtimaMembersCount}
                  />
                </td>
                <td>
                  <Input
                    className="text-center"
                    type="text"
                    name="mubtadiCount"
                    value={tarbiyatiIjtimaMembersCount?.mubtadiCount}
                    onChange={handleTarbiyatiIjtimaMembersCount}
                  />
                </td>
                <td>
                  <Input
                    className="text-center"
                    type="text"
                    name="mubtadiCount"
                    value={booksCompletedMembersCount?.mubtadiCount}
                    onChange={handleBooksCompletedMembersCount}
                  />
                </td>
                <td>
                  <Input
                    className="text-center"
                    type="text"
                    name="mubtadiCount"
                    value={fundsMembersCount?.mubtadiCount}
                    onChange={handleFundsMembersCount}
                  />
                </td>
                <td>
                  <Input
                    className="text-center"
                    type="text"
                    name="mubtadiCount"
                    value={membersMeetingHappens?.mubtadiCount}
                    onChange={handleMembersMeetingHappens}
                  />
                </td>
                <td>
                  <Input
                    className="text-center"
                    type="text"
                    name="mubtadiCount"
                    value={membersKnowPersonally?.mubtadiCount}
                    onChange={handleMembersKnowPersonally}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <h4>{"توجہ طلب"}</h4>
                </td>
                <td>
                  <Input
                    className="text-center"
                    type="text"
                    name="totalTawajaTalabCount"
                    value={membersCounts?.totalTawajaTalabCount}
                    onChange={handleMembersCounts}
                  />
                </td>
                <td>
                  <Input
                    className="text-center"
                    type="text"
                    name="tawajaTalabCount"
                    value={tarafiIjtimaMembersCount?.tawajaTalabCount}
                    onChange={handleTarafiIjtimaMembersCount}
                  />
                </td>
                <td>
                  <Input
                    className="text-center"
                    type="text"
                    value={"-"}
                    disabled
                  />
                </td>
                <td>
                  <Input
                    className="text-center"
                    type="text"
                    name="tawajaTalabCount"
                    value={booksCompletedMembersCount?.tawajaTalabCount}
                    onChange={handleBooksCompletedMembersCount}
                  />
                </td>
                <td>
                  <Input
                    className="text-center"
                    type="text"
                    name="tawajaTalabCount"
                    value={fundsMembersCount?.tawajaTalabCount}
                    onChange={handleFundsMembersCount}
                  />
                </td>
                <td>
                  <Input
                    className="text-center"
                    type="text"
                    name="tawajaTalabCount"
                    value={membersMeetingHappens?.tawajaTalabCount}
                    onChange={handleMembersMeetingHappens}
                  />
                </td>
                <td>
                  <Input
                    className="text-center"
                    type="text"
                    name="tawajaTalabCount"
                    value={membersKnowPersonally?.tawajaTalabCount}
                    onChange={handleMembersKnowPersonally}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <h4>{"کل رفقاء"}</h4>
                </td>
                <td>
                  <Input
                    className="text-center"
                    type="text"
                    name="totalMembersCount"
                    value={membersCounts?.totalMembersCount}
                    onChange={handleMembersCounts}
                  />
                </td>
                <td>
                  <Input
                    className="text-center"
                    type="text"
                    name="totalMembersCount"
                    value={tarafiIjtimaMembersCount?.totalMembersCount}
                    onChange={handleTarafiIjtimaMembersCount}
                  />
                </td>
                <td>
                  <Input
                    className="text-center"
                    type="text"
                    name="totalMembersCount"
                    value={tarbiyatiIjtimaMembersCount?.totalMembersCount}
                    onChange={handleTarbiyatiIjtimaMembersCount}
                  />
                </td>
                <td>
                  <Input
                    className="text-center"
                    type="text"
                    name="totalMembersCount"
                    value={booksCompletedMembersCount?.totalMembersCount}
                    onChange={handleBooksCompletedMembersCount}
                  />
                </td>
                <td>
                  <Input
                    className="text-center"
                    type="text"
                    name="totalMembersCount"
                    value={fundsMembersCount?.totalMembersCount}
                    onChange={handleFundsMembersCount}
                  />
                </td>
                <td>
                  <Input
                    className="text-center"
                    type="text"
                    name="totalMembersCount"
                    value={membersMeetingHappens?.totalMembersCount}
                    onChange={handleMembersMeetingHappens}
                  />
                </td>
                <td>
                  <Input
                    className="text-center"
                    type="text"
                    name="totalMembersCount"
                    value={membersKnowPersonally?.totalMembersCount}
                    onChange={handleMembersKnowPersonally}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
          <Row>
            <Col md="4">
              <h3>دوران ماہ تبدیلیوں کی وضاحت(نئے/منتقل/معذرت/اخراج/ فوت):</h3>
            </Col>
            <Col md="8">
              <Input
                type="textarea"
                name="changesDescription"
                value={countingSection.changesDescription}
                onChange={(e) => {
                  setCountingSection({
                    ...countingSection,
                    changesDescription: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Counts;
