import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup,
  Form,
  Button,
  FormFeedback,
  Row,
  Col,
} from "reactstrap";

import {
  ApiResponsibility,
  ApiResponsibilityType,
} from "./ApiResponsibilityType";
import { GetLanguageString } from "../../helper/Components";
import { LoadingSpinner } from "../../../constants/const";
import { ApiUnitType } from "../unitType/ApiUnitType";
import Draggable from "react-draggable";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import { GetSelectedLanguage } from "../../helper/Method";

const AddResponsibilityType = ({ setModal, setResponsibilityType }) => {
  const [loading, setLoading] = useState(false);
  const [responsibilityTypeValues, setResponsibilityTypeValues] = useState({
    title: "",
    code: "",
    sortOrder: "",
    responsibilityType: {},
    unitType: {},
    isDisable: false,
    isRequired: false,
    isSemiRequired: false,
    isMultiple: false,
    renewalRequired: false,
    renewalPeriod: null,
  });
  const [errors, setErrors] = useState(responsibilityTypeValues);
  const toggle = () => setModal(false);
  const [responsibilityTypes, setResponsibilityTypes] = useState([]);
  const [unitTypes, setUnitTypes] = useState([]);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  useEffect(() => {
    setLoading(true);
    ApiUnitType()
      .getUnitTypeSelectList()
      .then((res) => {
        setUnitTypes(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    ApiResponsibilityType()
      .getResponsibilityTypeSelectList()
      .then((res) => {
        setResponsibilityTypes(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const validate = () => {
    let temp = {};
    temp.title = responsibilityTypeValues.title ? (
      ""
    ) : (
      <GetLanguageString props="settings_title_error_message" />
    );
    temp.responsibilityType =
      Object.keys(responsibilityTypeValues.responsibilityType).length === 0
        ? "error"
        : "";
    temp.unitType =
      Object.keys(responsibilityTypeValues.unitType).length === 0
        ? "error"
        : "";
    if (responsibilityTypeValues.renewalRequired) {
      temp.renewalPeriod =
        responsibilityTypeValues.renewalPeriod !== null ? "" : "error";
    }
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    var v = value;

    if (name === "isRequired" && value === "true") {
      setResponsibilityTypeValues({
        ...responsibilityTypeValues,
        isRequired: v,
        isSemiRequired: false,
      });
    } else if (name === "isSemiRequired" && value === "true") {
      setResponsibilityTypeValues({
        ...responsibilityTypeValues,
        isRequired: false,
        isSemiRequired: v,
      });
    } else if (name === "notRequired" && value === "false") {
      setResponsibilityTypeValues({
        ...responsibilityTypeValues,
        isRequired: false,
        isSemiRequired: false,
      });
    } else {
      if (name === "isMultiple" && value === "true") v = true;
      else if (name === "isMultiple" && value === "false") v = false;

      if (name === "isDisable" && value === "true") v = true;
      else if (name === "isDisable" && value === "false") v = false;

      if (name === "renewalRequired" && value === "true") v = true;
      else if (name === "renewalRequired" && value === "false") {
        v = false;
      }

      setResponsibilityTypeValues({
        ...responsibilityTypeValues,
        [name]: v,
      });
    }
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleChangeSelectType = (record, name) => {
    setResponsibilityTypeValues({
      ...responsibilityTypeValues,
      [name]: record,
    });
    setErrors({
      title: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      if (!responsibilityTypeValues.renewalRequired)
        responsibilityTypeValues.renewalPeriod = null;
      setLoading(true);
      ApiResponsibility()
        .setResponsibility(responsibilityTypeValues)
        .then((ress) => {
          ApiResponsibility()
            .getResponsibilitiesDictionary()
            .then((res) => {
              swal({
                title: t("settings_responsibility_type_added_success"),
                className: GetSelectedLanguage() === "en" ? "" : "ur",
                icon: "success",
                buttons: "OK",
              });
              toggle();
              setResponsibilityType(res.data);
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        })
        .catch((err) => {
          swal({
            title: err.response?.data || err,
            icon: "error",
            buttons: "OK",
          });
          console.log(err);
          setLoading(false);
        });
    }
  };
  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="settings_add_responsibility" />}
          </ModalHeader>
          {!loading ? (
            <Form onSubmit={handleSubmit}>
              <ModalBody>
                <FormGroup>
                  <Label className="col-form-label">
                    {
                      <GetLanguageString props="settings_add_responsibility_unit_type" />
                    }
                  </Label>
                  <Select
                    value={responsibilityTypeValues.unitType}
                    options={unitTypes}
                    className="basic-single ur"
                    classNamePrefix="select"
                    onChange={(record) => {
                      handleChangeSelectType(record, "unitType");
                    }}
                  />
                  {errors.unitType === "error" ? (
                    <h4 className="text-error">
                      {
                        <GetLanguageString props="settings_select_unit_type_error" />
                      }
                    </h4>
                  ) : null}
                </FormGroup>
                <FormGroup>
                  <Label className="col-form-label">
                    {
                      <GetLanguageString props="settings_select_responsibility_type" />
                    }
                  </Label>
                  <Select
                    value={responsibilityTypeValues.responsibilityType}
                    options={responsibilityTypes}
                    className="basic-single ur"
                    classNamePrefix="select"
                    onChange={(record) => {
                      handleChangeSelectType(record, "responsibilityType");
                    }}
                  />
                  {errors.responsibilityType === "error" ? (
                    <h4 className="text-error">
                      {
                        <GetLanguageString props="settings_select_responsibility_type_error" />
                      }
                    </h4>
                  ) : null}
                </FormGroup>
                <FormGroup>
                  <Label>{<GetLanguageString props="settings_title" />}</Label>
                  <Input
                    autoFocus
                    type="text"
                    name="title"
                    className="ur no-drag"
                    value={responsibilityTypeValues.title}
                    onChange={handleInputChange}
                    {...(errors.title && { invalid: true })}
                  />
                  {errors.title && (
                    <FormFeedback>
                      <h4>{errors.title}</h4>
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label>{<GetLanguageString props="settings_code" />}</Label>
                  <Input
                    type="text"
                    name="code"
                    className="no-drag"
                    value={responsibilityTypeValues.code}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    {<GetLanguageString props="settings_sort_order" />}
                  </Label>
                  <Input
                    type="text"
                    name="sortOrder"
                    className="no-drag"
                    value={responsibilityTypeValues.sortOrder}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                {responsibilityTypeValues.renewalRequired ? (
                  <FormGroup>
                    <Label>
                      {
                        <GetLanguageString props="settings_responsibilities_renewal_period" />
                      }
                    </Label>
                    <Input
                      type="number"
                      name="renewalPeriod"
                      className="no-drag"
                      value={responsibilityTypeValues.renewalPeriod}
                      onChange={handleInputChange}
                    />
                    {errors.renewalPeriod === "error" ? (
                      <h4 className="text-error">
                        {
                          <GetLanguageString props="settings_responsibilities_renewal_period_error" />
                        }
                      </h4>
                    ) : null}
                  </FormGroup>
                ) : null}
                <FormGroup check>
                  <Label check for="renewalRequired">
                    <Input
                      id="renewalRequired"
                      type="checkbox"
                      name="renewalRequired"
                      value={!responsibilityTypeValues.renewalRequired}
                      onChange={handleInputChange}
                    />
                    {
                      <GetLanguageString props="settings_responsibilities_renewal_required" />
                    }
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check for="isDisable">
                    <Input
                      id="isDisable"
                      type="checkbox"
                      name="isDisable"
                      value={!responsibilityTypeValues.isDisable}
                      onChange={handleInputChange}
                    />
                    {<GetLanguageString props="common_is_incharge" />}
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check for="isMultiple">
                    <Input
                      id="isMultiple"
                      type="checkbox"
                      checked={responsibilityTypeValues.isMultiple}
                      name="isMultiple"
                      value={!responsibilityTypeValues.isMultiple}
                      onChange={handleInputChange}
                    />
                    {
                      <GetLanguageString props="settings_add_responsibility_is_multiple" />
                    }
                  </Label>
                </FormGroup>
                <hr></hr>
                <Row>
                  <Col>
                    {" "}
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          checked={
                            !responsibilityTypeValues.isRequired &&
                            !responsibilityTypeValues.isSemiRequired
                          }
                          name="notRequired"
                          value={
                            responsibilityTypeValues.isRequired &&
                            responsibilityTypeValues.isSemiRequired
                          }
                          onChange={handleInputChange}
                        />
                        {
                          <GetLanguageString props="settings_add_responsibility_is_not_required" />
                        }
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col>
                    {" "}
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          checked={responsibilityTypeValues.isRequired}
                          name="isRequired"
                          value={!responsibilityTypeValues.isRequired}
                          onChange={handleInputChange}
                        />
                        {
                          <GetLanguageString props="settings_add_responsibility_is_required" />
                        }
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          checked={responsibilityTypeValues.isSemiRequired}
                          name="isSemiRequired"
                          value={!responsibilityTypeValues.isSemiRequired}
                          onChange={handleInputChange}
                        />
                        {
                          <GetLanguageString props="settings_add_responsibility_is_semi_required" />
                        }
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button color="white" onClick={toggle}>
                  {<GetLanguageString props="common_cancel" />}
                </Button>
                <Button color="primary" type="submit">
                  {<GetLanguageString props="common_save_changes" />}
                </Button>
              </ModalFooter>
            </Form>
          ) : (
            LoadingSpinner()
          )}
        </Modal>
      </Draggable>
    </div>
  );
};

export default AddResponsibilityType;
