import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
} from "reactstrap";
import swal from "sweetalert";
import { GetLanguageString } from "../helper/Components";
import { addMember } from "./ApiMember";
import { GetSelectedLanguage } from "../helper/Method";
import Draggable from "react-draggable";

const CancelMemberRegistration = ({
  setCancelMemberRegistrationModal,
  memberId,
}) => {
  const notify = (string) => toast.success(string);
  const toggle = () => setCancelMemberRegistrationModal(false);
  const { t } = useTranslation();
  const [cancelMemberRegistration, setCancelMemberRegistration] = useState({
    memberId: memberId,
    remarks: "",
    referenceNote: "",
  });
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCancelMemberRegistration({
      ...cancelMemberRegistration,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    swal({
      title: t("common_delete_message"),
      text: t("common_delete_detail_message"),
      className: GetSelectedLanguage() === "en" ? "" : "ur",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        addMember()
          .cancelRafiqRegistration(cancelMemberRegistration)
          .then((res) => {
            if (res.data === "") {
              swal({
                title: t("member__member_registration_cancelled_message"),
                className: GetSelectedLanguage() === "en" ? "" : "ur",
                icon: "warning",
                buttons: "OK",
              });
            } else notify(res.data);
            toggle();
          })
          .catch((err) => console.log(err));
      }
    });
  };
  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="member_cancel_rafiq_registration" />}
          </ModalHeader>
          <Form onSubmit={handleSubmit}>
            <ModalBody>
              <div className="ibox-content">
                <Row>
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="common_remarks" />}
                    </Label>
                    <Input
                      className="ur no-drag"
                      type="text"
                      value={cancelMemberRegistration.remarks}
                      name="remarks"
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </Row>
                <Row>
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="member_reference_note" />}
                    </Label>
                    <Input
                      className="ur no-drag"
                      type="text"
                      value={cancelMemberRegistration.referenceNote}
                      name="referenceNote"
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </Row>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="white" onClick={toggle}>
                {<GetLanguageString props="common_cancel" />}
              </Button>
              <Button color="primary" type="submit">
                {<GetLanguageString props="common_save_changes" />}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </Draggable>
    </div>
  );
};

export default CancelMemberRegistration;
