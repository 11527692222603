//          CUSTOM CSS FOR PRINT NOTIFICATIONS

const titleStyle = {
  fontSize: '30px',
  fontWeight: 'Bold',
  textAlign: 'center',
  textDecoration: 'underline',
};

const personalInfo = {
  display: 'flex',
  paddingRight: '12px',
  marginTop: '-12px',
};

const personalInfo2 = {
  display: 'flex',
  paddingRight: '12px',
};

const section = {
  flex: '1',
  marginLeft: '-30px',
};

const spanNo = {
  display: 'inline-block',
  width: '20px',
};

const spanHeading = {
  display: 'inline-block',
  width: '36px',
};

const spanHeading2 = {
  display: 'inline-block',
  width: '78px',
};

const spanHeading3 = {
  display: 'inline-block',
  width: '48px',
};

const spanHeading4 = { 
  display: 'inline-block',
  width: '50px',
}

const spanData = {
  display: 'inline-block',
  width: '160px',
  textAlign: 'center',
  borderBottom: '2px dotted black',
};

const spanData2 = {
  display: 'inline-block',
  width: '148px',
  textAlign: 'center',
  borderBottom: '2px dotted black',
};

const spanData3 = {
  display: 'inline-block',
  width: '264px',
  textAlign: 'center',
  borderBottom: '2px dotted black',
};

const spanData4 = {
  display: 'inline-block',
  width: '232px',
  textAlign: 'center',
  borderBottom: '2px dotted black',
};

const spanData5 = {
  display: 'inline-block',
  width: '292px',
  textAlign: 'center',
  borderBottom: '2px dotted black',
};

const spanData6 = {
  display: 'inline-block',
  width: '222px',
  textAlign: 'center',
  borderBottom: '2px dotted black',
};

const spanData7 = {
  display: 'inline-block',
  width: '304px',
  textAlign: 'center',
  borderBottom: '2px dotted black',
};

const spanData8 = {
  display: 'inline-block',
  width: '260px',
  textAlign: 'center',
  borderBottom: '2px dotted black',
};

const section2No = {
  paddingRight: '12px',
  paddingLeft: '12px',
}

const section2Heading = {
  paddingRight: '10px',
  paddingLeft: '6px',
}

const courseDate = {
  display: 'inline-block',
  width: '117px',
  textAlign: 'center',
  borderBottom: '2px dotted black',
}

const courseLocation = {
  display: 'inline-block',
  width: '252px',
  textAlign: 'center',
  borderBottom: '2px dotted black',
}

const modalLabelButtonEn = {
  marginLeft:  '250px',
}
const modalLabelButtonUr = {
  marginRight: '330px',
}

//          CUSTOM CSS FOR MEMBER DOCUMENTS REPORT

const baitHeading = {
  fontSize: '16px',
}

export {
  modalLabelButtonEn,
  modalLabelButtonUr,
  titleStyle,
  personalInfo,
  personalInfo2,
  section,
  spanNo,
  spanHeading,
  spanHeading2,
  spanHeading3,
  spanHeading4,
  spanData,
  spanData2,
  spanData3,
  spanData4,
  spanData5,
  spanData6,
  spanData7,
  spanData8,
  section2No,
  section2Heading,
  courseDate,
  courseLocation,
  baitHeading
}