import axios from "axios";

import { BASEURL } from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";

export function ApiDashboard() {
  const userToken = encryptStorage.getItem("token");

  const authAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const url = BASEURL + "dashboard/";
  return {
    getDashboardDetails: (unitId) =>
      authAxios.get(url + "getDashboardDetails/" + unitId),
    getDashboardMemberDetails: (unitId) =>
      authAxios.get(url + "GetDashboardMemberDetails/" + unitId),
    getDashboardEventDetails: (unitId) =>
      authAxios.get(url + "GetDashboardEventDetails/" + unitId),
    getEventDetailModel: (unitId, eventTypeId) =>
      authAxios.get(url + "getEventDetailModel/" + unitId + "/" + eventTypeId),
    getExpectedMemberList: (memberId) =>
      authAxios.get(url + "getExpectedMemberList/" + memberId)
  };
}
