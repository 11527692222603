import React, { useState } from "react";
import { LoadingSpinner, isUrdu } from "../../constants/const";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { GetLanguageString } from "../helper/Components";
import { encryptStorage } from "../../constants/EncryptStorage";
import { useTranslation } from "react-i18next";
import { ApiAhbab } from "./ApiAhbab";
import swal from "sweetalert";
import { GetSelectedLanguage } from "../helper/Method";
import { toast } from "react-toastify";
import Draggable from "react-draggable";
import SelectUnitForMember from "../unit/SelectUnitForMember";

const TransferHabibModal = ({
  setTransferHabibModal,
  habibId,
  previousUnitId,
  update,
  setUpdate
}) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const unitTypeName = encryptStorage.getItem("unitTypeName");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const notify = (string) => toast.success(string);
  const [values, setValues] = useState({
    unit: { value: loggedInUnitId, lable: "", unitName: "" },
  });

  const [transferHabibData, setTransferHabibData] = useState({
    habibId: habibId,
    comments: "",
    previousUnitId: previousUnitId,
    newUnitId: 0,
  });
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  const selectedLanguage = GetSelectedLanguage();

  const toggle = () => setTransferHabibModal(false);

  const { t } = useTranslation();

  const handleInputChange = (e) => {
    const { value } = e.target;
    setTransferHabibData({
      ...transferHabibData,
      comments: value,
    });
  };
  const validate = () => {
    let temp = {};
    temp.unitId =
      values.unit.value === 0 ? (
        <GetLanguageString props="unit_unitmember_empty_unit_error_message" />
      ) : values.unit.unitName !== "Halqa" && (unitTypeName === "Markaz" || unitTypeName === "Zone") ? (
        <GetLanguageString props="ahbab_transfer_only_halqa_error" />
      ) : loggedInUnitId === values.unit.value ? (
        <GetLanguageString props="unit_unitmember_already_exists_in_unit" />
      ) : (
        ""
      );
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    transferHabibData.newUnitId = values.unit.value;
    if (validate()) {
      setButtonDisable(true);
      setLoading(true);
      ApiAhbab()
        .TransferHabib(transferHabibData)
        .then((res) => {
          if (
            res.data.isRequest &&
            res.data.message === "request_exists_for_transfer_habib"
          ) {
            swal({
              title: t("request_exists_for_transfer_habib"),
              className: selectedLanguage === "en" ? "" : "ur",
              icon: "error",
              buttons: selectedLanguage === "en" ? "OK" : "ٹھیک ہے ",
            });
            setLoading(false);
            setButtonDisable(false);
            toggle();
          } else if (res.data.includes("Move")) {
            notify(res.data);
            setLoading(false);
            setUpdate(!update)
            setButtonDisable(false);
            toggle();
          }
        })
        .catch((err) => {
          swal({
            title: "Error: " + err.response?.data || err,
            icon: "error",
            buttons: "OK",
          });
          setLoading(false);
          setButtonDisable(false);
          console.log(err);
        });
    }
  };
  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal
          isOpen={true}
          size="lg"
          style={{ maxWidth: "1080px", width: "100%" }}
          className="inmodal"
        >
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="common_action" />}
          </ModalHeader>
          <Form>
            <ModalBody>
              {!loading ? (
                <>
                  <div className="ibox-title">
                    <h5>
                      {
                        <GetLanguageString props="ahbab_transfer_habib_select_unit" />
                      }
                    </h5>
                  </div>
                  <div className="ibox-content">
                    <SelectUnitForMember
                      values={values}
                      setValues={setValues}
                      setErrors={setErrors}
                      unitId={loggedInUnitId}
                      showExUnit={false}
                      page={"TransferHabib"}
                      check={"Outside"}
                    />
                    {errors.unitId ? (
                      <h4 className="text-error">*{errors.unitId}</h4>
                    ) : null}
                    <FormGroup>
                      <Label>
                        {<GetLanguageString props="common_comments" />}
                      </Label>
                      <Input
                        type="text"
                        name="comments"
                        className={
                          isUrdu(transferHabibData.comments)
                            ? "ur no-drag"
                            : "no-drag"
                        }
                        placeholder={t("common_comments")}
                        value={transferHabibData.comments}
                        onChange={handleInputChange}
                      />
                    </FormGroup>{" "}
                    <p>{errors?.unit}</p>
                  </div>
                </>
              ) : (
                LoadingSpinner()
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="white" onClick={toggle}>
                {<GetLanguageString props="common_cancel" />}
              </Button>
              <Button
                color="primary"
                disabled={buttonDisable}
                type="submit"
                onClick={handleSubmit}
              >
                {<GetLanguageString props="common_save_changes" />}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </Draggable>
    </div>
  );
};

export default TransferHabibModal;
