import axios from "axios";

import { BASEURL } from "../../../constants/const";
import { encryptStorage } from "../../../constants/EncryptStorage";

export function ApiTutorialCategory() {
  const userToken = encryptStorage.getItem("token");

  const authAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const url = BASEURL + "videoTutorials/";
  return {
    getCategory: () => authAxios.get(url + "GetCategory"),
    checkCategory: (id) => authAxios.get(url + `CheckTutorialCategory/${id}`),
    updateCategory: (id, formData) => authAxios.put(url + `UpdateCategory/${id}`, formData),
    deleteCategory: (id) => authAxios.delete(url + `DeleteCategory/${id}`),
  };
}
