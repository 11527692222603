import jwtDecode from "jwt-decode";
import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Row,
} from "reactstrap";

import i18next from "i18next";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { requestAccountApi } from "../../actions/api";
import { LoadingSpinner } from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";
import { GetLanguageString } from "../helper/Components";
import { GetSelectedLanguage } from "../helper/Method";
import useForm from "../shared/useForm";

const intialFieldValues = {
  UserName: "",
  Password: "",
};
const Login = ({
  setValues,
  setSignUp,
  signUp,
  setForgotPassword,
  forgotPassword,
}) => {
  const languageMap = useMemo(() => {
    return {
      en: { label: "English", dir: "ltr", active: true },
      separator: { label: "|" },
      ur: { label: "اردو", dir: "rtl", active: false },
    };
  }, []);
  const selected = GetSelectedLanguage();
  const [loading, setLoading] = useState(false);
  const [loadingForLang, setLoadingForLang] = useState(false);
  const validate = () => {
    let temp = {};
    //Email Validation
    // ? /^$|.+@.+..+/.test(values.Email)
    // : "Email is not valid"
    temp.UserName = values.UserName
      ? ""
      : selected === "en"
      ? "The Registration Number field is required."
      : selected === "ur"
      ? " رجسٹریشن نمبر لازم ہے "
      : null;
    temp.Password = values.Password
      ? ""
      : selected === "en"
      ? "The Password field is required."
      : selected === "ur"
      ? "پاس ورڈ لازم ہے "
      : null;
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  useEffect(() => {
    document.body.dir = languageMap[selected].dir;
    setLoadingForLang(false);
  }, [languageMap, loadingForLang, selected]);

  const {
    errors,
    setErrors,
    serverError,
    setServerError,
    values,
    handleInputChange,
  } = useForm(intialFieldValues);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      requestAccountApi()
        .createLogin(values)
        .then((res) => {
          encryptStorage.setItem("userClaims", res.data.userClaims);
          const getToken = res.data.token;
          var decodedToken = jwtDecode(getToken);
          if (decodedToken.role === "admin")
            encryptStorage.setItem("canManage", "true");
          else encryptStorage.setItem("canManage", decodedToken.CanManage);

          encryptStorage.setItem(
            "responsibilityName",
            decodedToken.ResponsibilityName
          );
          encryptStorage.setItem("memberId", decodedToken.MemberId);
          encryptStorage.setItem("memberName", decodedToken.MemberName);
          encryptStorage.setItem("userId", decodedToken.UserName);
          encryptStorage.setItem("loggedInUnitId", decodedToken.UnitId);
          encryptStorage.setItem("loggedInUnitName", decodedToken.UnitName);
          encryptStorage.setItem("unitTypeName", decodedToken.UnitTypeName);

          encryptStorage.setItem(
            "responsibilityId",
            decodedToken.ResponsibilityId
          );
          encryptStorage.setItem("token", JSON.stringify(getToken));
          encryptStorage.setItem(
            "refreshToken",
            JSON.stringify(res.data.refreshToken)
          );
          setLoading(false);
          setValues({
            ...values,
            token: getToken,
          });
        })
        .catch((err) => {
          setLoading(false);
          setServerError(err.response?.data.errors[0]);
        });
    }
  };

  const [passwordType, setPasswordType] = useState("password");

  const handlePasswordTypeChange = () => {
    setPasswordType(passwordType === "password" ? "text" : "password");
  };
  return (
    <div>
      {!loading ? (
        <>
          <p>
            <GetLanguageString props="login_login_to_start" />
          </p>
          {serverError && <h4 className="text-danger">{serverError}</h4>}
          <Form onSubmit={handleSubmit} className="m-t">
            <FormGroup>
              <Input
                autoFocus
                className={selected === "ur" ? "ur" : "en"}
                type="text"
                name="UserName"
                value={values.UserName}
                onChange={handleInputChange}
                placeholder={
                  selected === "en"
                    ? "Registration Number"
                    : selected === "ur"
                    ? "رجسٹریشن نمبر"
                    : null
                }
                {...(errors.UserName && { invalid: true })}
              />
              {errors.UserName && (
                <FormFeedback>
                  <h4 className={selected === "ur" ? "ur" : "en"}>
                    {errors.UserName}
                  </h4>
                </FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <InputGroup>
                <Input
                  type={passwordType}
                  className={selected === "ur" ? "ur" : "en"}
                  name="Password"
                  value={values.Password}
                  onChange={handleInputChange}
                  placeholder={
                    selected === "en"
                      ? "Password"
                      : selected === "ur"
                      ? "پاس ورڈ"
                      : null
                  }
                  {...(errors.Password && { invalid: true })}
                />
                <InputGroupText
                  style={{ backgroundColor: "#FFFFFF" }}
                  onClick={handlePasswordTypeChange}
                >
                  {passwordType === "password" ? (
                    <FaEye color="#5C636A" />
                  ) : (
                    <FaEyeSlash color="#5C636A" />
                  )}
                </InputGroupText>
              </InputGroup>
              {errors.Password && (
                <FormFeedback>
                  <h4 className={selected === "ur" ? "ur" : "en"}>
                    {errors.Password}
                  </h4>
                </FormFeedback>
              )}
            </FormGroup>
            <Button color="primary" type="submit" className="full-width m-b">
              <GetLanguageString props="login" />
            </Button>
            <p
              style={{ cursor: "pointer", color: "#007bff" }}
              onClick={() => setForgotPassword(!forgotPassword)}
            >
              <GetLanguageString props="login_forgot_password" />
            </p>
            <p className="text-muted text-center">
              <GetLanguageString props="login_do_not_gave_an_account" />
            </p>
            <Button
              size="sm"
              color="white"
              className="full-width"
              onClick={() => setSignUp(!signUp)}
            >
              <GetLanguageString props="login_create_an_account" />
            </Button>
          </Form>
          <div className="m">
            <Row>
              {Object.keys(languageMap)?.map((item, index) => (
                <Col
                  key={index}
                  md="4"
                  className={item === "ur" ? "ur" : "en"}
                  style={{
                    cursor: item !== "separator" && "pointer",
                    color: "#01822f",
                    textDecorationLine:
                      selected === "en"
                        ? item === "en" && "underline"
                        : item === "ur" && "underline",
                  }}
                  onClick={() => {
                    item !== "separator" && i18next.changeLanguage(item);
                    setLoadingForLang(true);
                  }}
                >
                  {languageMap[item].label}
                </Col>
              ))}
            </Row>
          </div>
        </>
      ) : (
        LoadingSpinner()
      )}
    </div>
  );
};

export default Login;
