import MetisMenu from "@metismenu/react";
import jwtDecode from "jwt-decode";
import React from "react";
import { Link } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";

import "metismenujs/dist/metismenujs.css";

import { FaUserCircle, FaUserFriends } from "react-icons/fa";
import { IoDocuments, IoSettings } from "react-icons/io5";
import { MdManageAccounts } from "react-icons/md";
import { PiVideoFill } from "react-icons/pi";
import { TbLayoutDashboardFilled, TbReportSearch } from "react-icons/tb";
import { encryptStorage } from "../../constants/EncryptStorage";
import { CheckPermission } from "../../constants/const";
import { GetLanguageString } from "../helper/Components";
import { GetCustomStyles, GetSelectedLanguage } from "../helper/Method";
import ProfileSettings from "./profile/ProfileSettings";

const SideBar = () => {
  const isIncharge = encryptStorage.getItem("canManage");
  const tokenString = encryptStorage.getItem("token");
  var decodedToken = jwtDecode(tokenString);

  const isRtl = () => {
    if (GetSelectedLanguage() === "ur") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <nav
      className="navbar-default navbar-static-side fixed-sidebar"
      role="navigation"
    >
      <div className="sidebar-collapse navbar-static-side">
        <div
          className="navbar-static-side"
          style={{
            zIndex: 2001,
            height: "100vh",
            position: "fixed",
            backgroundColor: "#2F4050",
          }}
        >
          <React.Fragment>
            <MetisMenu>
              <li className="nav-header">
                <ProfileSettings />
                <div className="logo-element">TIS</div>
              </li>
              <li>
                <Link to="/">
                  <TbLayoutDashboardFilled size={16} /> &nbsp;
                  <span className="nav-label">
                    {<GetLanguageString props="sidebar_dashboard" />}
                  </span>
                </Link>
              </li>
              {decodedToken.MemberId === "0" ? null : (
                <li>
                  <Link to="/personalInfo">
                    <FaUserCircle size={16} /> &nbsp;
                    <span className="nav-label">
                      {<GetLanguageString props="sidebar_personal_info" />}
                    </span>
                  </Link>
                </li>
              )}
              <li>
                <Link to="#">
                  <span
                    style={{
                      float: isRtl() ? "left" : "right",
                      transform: isRtl() ? "scaleX(-1)" : "scaleX(1)",
                    }}
                    className="fa arrow"
                  ></span>
                  <MdManageAccounts size={17} /> &nbsp;
                  <span className="nav-label">
                    {<GetLanguageString props="sidebar_manage" />}
                  </span>{" "}
                </Link>
                <Nav className="nav-second-level">
                  {isIncharge ? (
                    <>
                      {CheckPermission("CanViewNazam") ? (
                        <NavItem className="col-12">
                          <Link to="/unit">
                            {<GetLanguageString props="sidebar_unit" />}
                          </Link>
                        </NavItem>
                      ) : null}
                      {CheckPermission("CanViewMember") ? (
                        <NavItem className="col-12">
                          <Link to="/member">
                            {<GetLanguageString props="sidebar_member" />}
                          </Link>
                        </NavItem>
                      ) : null}
                      {CheckPermission("CanViewMudaris") ? (
                        <NavItem
                          className={GetCustomStyles().sidebar_urdu_style}
                        >
                          <Link to="/mudariseen">
                            {<GetLanguageString props="sidebar_mudariseen" />}
                          </Link>
                        </NavItem>
                      ) : null}
                      {CheckPermission("CanViewCourses") ? (
                        <NavItem className="col-12">
                          <Link to="/course">
                            {<GetLanguageString props="sidebar_courses" />}
                          </Link>
                        </NavItem>
                      ) : null}
                      {CheckPermission("CanViewEvents") ? (
                        <NavItem className="col-12">
                          <Link to="/event">
                            {<GetLanguageString props="sidebar_events" />}
                          </Link>
                        </NavItem>
                      ) : null}
                      {CheckPermission("CanViewFunds") ? (
                        <NavItem className="col-12">
                          <Link
                            to="#"
                            className={GetCustomStyles().sidebar_urdu_style}
                          >
                            <span
                              style={{
                                float: isRtl() ? "left" : "right",
                                transform: isRtl() ? "scaleX(-1)" : "scaleX(1)",
                              }}
                              className="fa arrow"
                            ></span>
                            {<GetLanguageString props="funds" />}
                          </Link>
                          <Nav>
                            <NavItem>
                              <Link
                                to="/fundsmanagement"
                                className={
                                  GetCustomStyles().sidebar_second_item_style
                                }
                              >
                                {
                                  <GetLanguageString props="sidebar_funds_infaq" />
                                }
                              </Link>
                              <Link
                                to="/fundsmanagement/expenses"
                                className={
                                  GetCustomStyles().sidebar_second_item_style
                                }
                              >
                                {
                                  <GetLanguageString props="sidebar_funds_expenses" />
                                }
                              </Link>
                            </NavItem>
                          </Nav>
                        </NavItem>
                      ) : null}
                      {CheckPermission("CanViewAhbab") ? (
                        <NavItem className="col-12">
                          <Link
                            to="#"
                            className={GetCustomStyles().sidebar_urdu_style}
                          >
                            {<GetLanguageString props="ahbab" />}
                            <span
                              style={{
                                float: isRtl() ? "left" : "right",
                                transform: isRtl() ? "scaleX(-1)" : "scaleX(1)",
                              }}
                              className="fa arrow"
                            ></span>
                          </Link>{" "}
                          <Nav>
                            <NavItem>
                              <Link
                                to="/ahbabFromContactForm"
                                className={
                                  GetCustomStyles().sidebar_second_item_style
                                }
                              >
                                {
                                  <GetLanguageString props="sidebar_contact_form_ahbab" />
                                }
                              </Link>
                              <Link
                                to="/ahbab"
                                className={
                                  GetCustomStyles().sidebar_second_item_style
                                }
                              >
                                {<GetLanguageString props="ahbab" />}
                              </Link>
                            </NavItem>
                          </Nav>
                        </NavItem>
                      ) : null}
                    </>
                  ) : null}
                </Nav>
              </li>
              {(isIncharge && CheckPermission("CanViewMember")) ||
              CheckPermission("CanViewFunds") ||
              CheckPermission("CanSendMessage") ||
              CheckPermission("CanViewEvents") ||
              CheckPermission("CanViewCourses") ? (
                <>
                  <li>
                    <Link to="#">
                      <TbReportSearch size={17} /> &nbsp;
                      <span className="nav-label">
                        {<GetLanguageString props="sidebar_reports" />}
                      </span>
                      <span
                        style={{
                          float: isRtl() ? "left" : "right",
                          transform: isRtl() ? "scaleX(-1)" : "scaleX(1)",
                        }}
                        className="fa arrow"
                      ></span>
                    </Link>
                    <Nav className="nav-second-level">
                      {CheckPermission("CanViewMember") ||
                      CheckPermission("CanViewFunds") ? (
                        <NavItem className="col-12">
                          <Link to="/reports">
                            {
                              <GetLanguageString props="sidebar_quick_reports" />
                            }
                          </Link>
                        </NavItem>
                      ) : null}
                      {/* <NavItem className="col-12">
                        <Link to="#">
                          {<GetLanguageString props="sidebar_unit" />}
                          <span className="fa arrow"></span>
                        </Link>
                        <Nav>
                          <NavItem>
                            <Link
                              to="/unit/monthlyreport"
                              className={
                                GetCustomStyles().sidebar_second_item_style
                              }
                            >
                              {
                                <GetLanguageString props="sidebar_unit_monthly_report" />
                              }
                            </Link>
                          </NavItem>
                          <NavItem>
                            <Link
                              to="/unit/cummulativereport"
                              className={
                                GetCustomStyles().sidebar_second_item_style
                              }
                            >
                              {
                                <GetLanguageString props="sidebar_unit_cummulative_stats" />
                              }
                            </Link>
                          </NavItem>
                          <NavItem>
                            <Link
                              to="/unit/createReports"
                              className={
                                GetCustomStyles().sidebar_second_item_style
                              }
                            >
                              {
                                <GetLanguageString props="sidebar_unit_create_reports" />
                              }
                            </Link>
                          </NavItem>
                        </Nav>
                      </NavItem> */}
                      {CheckPermission("CanViewMember") ? (
                        <NavItem className="col-12">
                          <Link to="/member/report">
                            {<GetLanguageString props="sidebar_member" />}
                          </Link>
                        </NavItem>
                      ) : null}
                      {CheckPermission("CanViewCourses") ? (
                        <NavItem className="col-12">
                          <Link to="/course/report">
                            {<GetLanguageString props="sidebar_courses" />}
                          </Link>
                        </NavItem>
                      ) : null}
                      {CheckPermission("CanViewEvents") ? (
                        <NavItem className="col-12">
                          <Link to="/eventReports/">
                            {<GetLanguageString props="sidebar_events" />}
                          </Link>
                        </NavItem>
                      ) : null}
                      {CheckPermission("CanViewFunds") ? (
                        <NavItem className="col-12">
                          <Link
                            to="#"
                            className={GetCustomStyles().sidebar_urdu_style}
                          >
                            <span
                              style={{
                                float: isRtl() ? "left" : "right",
                                transform: isRtl() ? "scaleX(-1)" : "scaleX(1)",
                              }}
                              className="fa arrow"
                            ></span>
                            {<GetLanguageString props="funds" />}
                          </Link>
                          <Nav>
                            <NavItem>
                              <Link
                                to="/infaqreport"
                                className={
                                  GetCustomStyles().sidebar_second_item_style
                                }
                              >
                                {
                                  <GetLanguageString props="sidebar_funds_infaq" />
                                }
                              </Link>
                              <Link
                                to="/fundsmanagement/report/expenses"
                                className={
                                  GetCustomStyles().sidebar_second_item_style
                                }
                              >
                                {
                                  <GetLanguageString props="sidebar_funds_expenses" />
                                }
                              </Link>
                            </NavItem>
                          </Nav>
                        </NavItem>
                      ) : null}
                      {CheckPermission("CanViewAhbabReport") ? (
                        <NavItem className="col-12">
                          <Link
                            to="#"
                            className={GetCustomStyles().sidebar_urdu_style}
                          >
                            {<GetLanguageString props="ahbab" />}
                            <span
                              style={{
                                float: isRtl() ? "left" : "right",
                                transform: isRtl() ? "scaleX(-1)" : "scaleX(1)",
                              }}
                            ></span>
                          </Link>{" "}
                          <Nav>
                            <NavItem>
                              <Link
                                to="/ahbabFromContactForm/report"
                                className={
                                  GetCustomStyles().sidebar_second_item_style
                                }
                              >
                                {
                                  <GetLanguageString props="sidebar_contact_form_ahbab" />
                                }
                              </Link>
                              <Link
                                to="/ahbab/report"
                                className={
                                  GetCustomStyles().sidebar_second_item_style
                                }
                              >
                                {<GetLanguageString props="ahbab" />}
                              </Link>
                            </NavItem>
                          </Nav>
                        </NavItem>
                      ) : null}

                      {CheckPermission("CanSendMessage") ? (
                        <NavItem className="col-12">
                          <Link
                            to="#"
                            className={GetCustomStyles().sidebar_urdu_style}
                          >
                            {<GetLanguageString props="sidebar_message" />}
                            <span
                              style={{
                                float: isRtl() ? "left" : "right",
                                transform: isRtl() ? "scaleX(-1)" : "scaleX(1)",
                              }}
                              className="fa arrow"
                            ></span>
                          </Link>
                          <Nav>
                            <NavItem>
                              <Link
                                to="/message/sms"
                                className={
                                  GetCustomStyles().sidebar_second_item_style
                                }
                              >
                                {
                                  <GetLanguageString props="sidebar_message_sms" />
                                }
                              </Link>
                            </NavItem>
                          </Nav>
                        </NavItem>
                      ) : null}
                    </Nav>
                  </li>
                </>
              ) : null}
              {CheckPermission("CanViewDocuments") ? (
                <li>
                  <Link to="/documents">
                    <IoDocuments size={18} />
                    &nbsp;
                    <span className="nav-label">
                      {<GetLanguageString props="sidebar_documents" />}
                    </span>
                  </Link>
                </li>
              ) : null}
              <>
                {CheckPermission("CanCreateUser") ? (
                  <li>
                    <Link to="/users">
                      <FaUserFriends size={16} /> &nbsp;
                      <span className="nav-label">
                        {<GetLanguageString props="sidebar_users" />}
                      </span>
                    </Link>
                  </li>
                ) : null}
                {CheckPermission("CanAccessSettings") ? (
                  <li>
                    <Link to="/config">
                      <IoSettings size={16} /> &nbsp;
                      <span className="nav-label">
                        {<GetLanguageString props="sidebar_settings" />}
                      </span>
                    </Link>
                  </li>
                ) : null}
                <li>
                  <Link to="/videoTutorials">
                    <PiVideoFill size={16} /> &nbsp;
                    <span className="nav-label">
                      {<GetLanguageString props="tutorial" />}
                    </span>
                  </Link>
                </li>
              </>
            </MetisMenu>
          </React.Fragment>
        </div>
      </div>
    </nav>
  );
};

export default SideBar;
