import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
} from "reactstrap";
import { withRouter, Link, useLocation } from "react-router-dom";

import { addMember } from "./ApiMember";
import {
  GetLanguageString,
  GetSortingUI,
  NoResult,
} from "../helper/Components";
import { ApiUnit } from "../unit/ApiUnit";
import { GetCustomStyles, GetSelectedLanguage } from "../helper/Method";
import {
  CheckIsAdmin,
  CheckPermission,
  LoadingSpinner,
} from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";
import useSorting from "../shared/useSorting";
import CancelMemberRegistration from "./CancelMemberRegistration";
import { ToastContainer } from "react-toastify";
import MoveMemberModal from "./MoveMemberModal";

import "./styles.css";

const initialValues = {
  name: "neutral",
  joiningDate: "neutral",
  birthDate: "neutral",
  memberType: "neutral",
};

const MemberList = ({history}) => {
  const selectedLanguage = GetSelectedLanguage();
  const location = useLocation();
  const formValue = location.state?.formValue;
  const [loading, setLoading] = useState(false);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const isIncharge = encryptStorage.getItem("canManage");
  const [updatedDetails, setUpdatedDetails] = useState(false);
  const unitTypeName = encryptStorage.getItem("unitTypeName");
  const [unitId, setUnitId] = useState();
  const [memberId, setMemberId] = useState();
  const [check, setCheck] = useState();
  const [moveMemberModal, setMoveMemberModal] = useState(false);
  const [members, setMembers] = useState([]);
  const [pagination, setPagination] = useState({ activePage: 1 });
  const [unit, setUnit] = useState({
    unitId: loggedInUnitId,
    unitName: "",
    unitTypeName: unitTypeName,
  });
  const [cancelMemberRegistrationModal, setCancelMemberRegistrationModal] =
    useState(false);
  const [memberIdForCancellation, setMemberIdForCancellation] = useState();
  const [searchMemberModel, setSearchMemberModel] = useState({
    unitId: loggedInUnitId,
    loggedInUnitId: loggedInUnitId,
    activePage: 1,
    regNo: "",
    isSubUnits: true,
    sortModel: {},
  });
  let recordIndex = pagination.pageSize * (pagination.activePage - 1) + 1;
  const { sortObject, handleSorting } = useSorting(initialValues);

  const toggleDropdownOpen = (index) => {
    let temp = [...members];
    temp[index].dropDown = !members[index].dropDown;
    setMembers(temp);
  };

  useEffect(() => {
    const arrayFromString = formValue.split(/[،,]/);
    setSearchMemberModel((prevValues) => ({
      ...prevValues,
      regNo: arrayFromString[0] && arrayFromString[0].trim(),
      name: arrayFromString[0] && arrayFromString[0].trim(),
      fatherName : arrayFromString[1] && arrayFromString[1].trim(),
    }));
  }, [formValue]);

  useEffect(() => {
    if (unit.unitId === 0) {
      ApiUnit()
        .getUnit(loggedInUnitId, loggedInUnitId)
        .then((res) => {
          let temp = res.data.unitList[0];
          setSearchMemberModel((prevValues) => ({
            ...prevValues,
            unitId: temp.id,
            activePage: 1,
          }));
          setUnit({ unitId: temp.id, unitName: temp.name });
        })
        .catch((err) => console.log(err));
    }
  }, [unit.unitId, loggedInUnitId]);

  const handleOnMoveMember = (name, value, memberId) => {
    setUnitId(value);
    setCheck(name);
    setMemberId(memberId);
    setMoveMemberModal(true);
  };

  useEffect(() => {
    setLoading(true);
    let tempSortObject = {
      isFullNameDescending: sortObject.name === "descending",
      isFullNameAscending: sortObject.name === "ascending",
      isBirthDateDescending: sortObject.birthDate === "descending",
      isBirthDateAscending: sortObject.birthDate === "ascending",
      isJoiningDateDescending: sortObject.joiningDate === "descending",
      isJoiningDateAscending: sortObject.joiningDate === "ascending",
      isMemberTypeAscending: sortObject.memberType === "ascending",
      isMemberTypeDescending: sortObject.memberType === "descending",
    };
    searchMemberModel.sortModel = tempSortObject;
    if (searchMemberModel.unitId !== 0 && searchMemberModel.regNo !== "") {
      addMember()
        .getMember(searchMemberModel)
        .then((res) => {

          // REDIRECT TO MEMBER DETAILS PAGE IF ONLY ONE RECORD
          if (res.data.list.length === 1){
            var item =  res.data.list[0];
            history.push({
              pathname: `member/membersdetails/${item.id}`,
              state: {
                unitId: item.unitId,
                isEditable: item.isEditable,
                isDeletable: item.isDeletable,
                canModifyType: item.canModifyType
              }
            });
          }
          // DISPLAY THE LIST IF THERE ARE MORE THAN ONE RECORDS
          else{
            let temp = [];
            res.data.list.forEach((element) => {
              let tempElement = element;
              tempElement.dropDown = false;
              temp.push(tempElement);
            });
            setMembers(temp);
            setPagination(res.data);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [searchMemberModel, sortObject, cancelMemberRegistrationModal, history, updatedDetails]);

  const handlePageChange = (pageNumber) => {
    setSearchMemberModel({
      ...searchMemberModel,
      activePage: pageNumber,
    });
    setPagination({
      ...pagination,
      activePage: pageNumber,
    });
  };

  const handleOnDelete = (id) => {
    setMemberIdForCancellation(id);
    setCancelMemberRegistrationModal(true);
  };

  return (
    <div>
      <ToastContainer
        autoClose={3000}
        bodyClassName={selectedLanguage}
        closeOnClick
        pauseOnHover={false}
        draggable
      />
      {moveMemberModal && (
        <MoveMemberModal
          memberId={memberId}
          unitId={unitId}
          check={check}
          setRefresh={setUpdatedDetails}
          refresh={updatedDetails}
          setMoveMemberModal={setMoveMemberModal}
        />
      )}
      {cancelMemberRegistrationModal && (
        <CancelMemberRegistration
          setCancelMemberRegistrationModal={setCancelMemberRegistrationModal}
          memberId={memberIdForCancellation}
        />
      )}
      <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
        <div className="ibox ">
          <div className="ibox-title">
            <h5>
              {
                <GetLanguageString
                  props="member_member_list_heading"
                  value={formValue}
                />
              }
            </h5>
          </div>
          <div className="ibox-content">
            {!loading ? (
              members.length !== 0 ? (
                <div>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th
                          className="cursor-pointer"
                          onClick={() => handleSorting("name")}
                        >
                          <GetLanguageString props="common_name" />
                          <GetSortingUI sortObject={sortObject} name="name" />
                        </th>
                        <th>
                          {<GetLanguageString props="member_reg_number" />}
                        </th>
                        <th>
                          <GetLanguageString props="member_father_name" />
                        </th>
                        <th>{<GetLanguageString props="member_address" />}</th>
                        <th>{<GetLanguageString props="member_phone" />}</th>
                        <th
                          className="cursor-pointer"
                          onClick={() => handleSorting("memberType")}
                        >
                          <GetLanguageString props="settings_member_type" />
                          <GetSortingUI
                            sortObject={sortObject}
                            name="memberType"
                          />
                        </th>
                        <th>{<GetLanguageString props="unit_unit_table" />}</th>
                        {isIncharge ? <th></th> : null}
                      </tr>
                    </thead>
                    <tbody>
                      {members?.map((record, index) => {
                        return (
                          <tr key={index}>
                            <td className="p-t-custom">
                              {index + recordIndex}
                            </td>
                            <td className="ur p-t-custom">{record.fullName}</td>
                            <td className="p-t-custom">{record.regNo}</td>
                            <td className="ur p-t-custom">
                              {record.fatherName}
                            </td>
                            <td className="ur p-t-custom">
                              {record.currentAddress}
                            </td>
                            <td className="p-t-custom p-t-custom">
                              {record.phone}
                            </td>
                            <td>
                              {
                                <Button
                                  size="xs"
                                  color="info"
                                  className="label label-info m-r-xs"
                                >
                                  <h5 className="ur">{record.memberType}</h5>
                                </Button>
                              }
                            </td>
                            <td>
                              {
                                <Button
                                  size="xs"
                                  color="info"
                                  className="label label-info m-r-xs"
                                >
                                  <h5 className="ur">{record.unitName}</h5>
                                </Button>
                              }
                            </td>
                            {isIncharge ? (
                              <td>
                                <Dropdown
                                  isOpen={record.dropDown}
                                  toggle={() => {
                                    toggleDropdownOpen(index);
                                  }}
                                  style={{ position: "static" }}
                                >
                                  <DropdownToggle
                                    className="btn btn-default"
                                    tag="span"
                                    aria-expanded={record.dropDown}
                                  >
                                    {
                                      <GetLanguageString props="common_action" />
                                    }
                                    <b className="caret m-l-xs" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    style={{
                                      minWidth: "0rem",
                                      fontSize: "13px",
                                    }}
                                  >
                                      <div>
                                        {CheckIsAdmin() ? (
                                          <DropdownItem
                                            onClick={(e) =>
                                              handleOnMoveMember(
                                                "admin",
                                                record.unitId,
                                                record.id
                                              )
                                            }
                                          >
                                            {
                                              <GetLanguageString props="unit_unitmember_move_member" />
                                            }
                                          </DropdownItem>
                                        ) : null}
                                        {!CheckIsAdmin() &&
                                        CheckPermission(
                                          "CanMoveMembersLocalTransfer"
                                        ) ? (
                                          <DropdownItem
                                            onClick={(e) =>
                                              handleOnMoveMember(
                                                "Local",
                                                record.unitId,
                                                record.id
                                              )
                                            }
                                          >
                                            {unitTypeName === "Halqa" ? (
                                              <GetLanguageString props="unit_unitmember_move_member_localy" />
                                            ) : unitTypeName === "Tanzeem" ? (
                                              <GetLanguageString props="unit_unitmember_move_member_localy" />
                                            ) : (
                                              <GetLanguageString props="unit_unitmember_move_member_localy" />
                                            )}
                                          </DropdownItem>
                                        ) : null}
                                        {!CheckIsAdmin() &&
                                        (CheckPermission(
                                          "CanMoveMembersOutsideTransfer"
                                        ) ||
                                          CheckPermission(
                                            "CanRequestMoveMembersOutsideTransfer"
                                          )) ? (
                                          <DropdownItem
                                            onClick={(e) =>
                                              handleOnMoveMember(
                                                "Outside",
                                                record.unitId,
                                                record.id
                                              )
                                            }
                                          >
                                            {unitTypeName === "Halqa" ? (
                                              <GetLanguageString props="unit_unitmember_move_members_other" />
                                            ) : unitTypeName === "Tanzeem" ? (
                                              <GetLanguageString props="unit_unitmember_move_members_other" />
                                            ) : (
                                              <GetLanguageString props="unit_unitmember_move_members_other" />
                                            )}
                                          </DropdownItem>
                                        ) : null}
                                      </div>
                                    <Link
                                      to={{
                                        pathname: `member/membersdetails/${record.id}`,
                                        state: {
                                          unitId: record.unitId,
                                          isEditable: record.isEditable,
                                          isDeletable: record.isDeletable,
                                          canModifyType: record.canModifyType
                                        },
                                      }}
                                    >
                                      <DropdownItem>
                                        {
                                          <GetLanguageString props="member_detail_btn" />
                                        }
                                      </DropdownItem>
                                    </Link>
                                    {record.isEditable ? (
                                      <Link
                                        to={{
                                          pathname: `member/updateMember/${record.id}`,
                                          state: {
                                            unitId: loggedInUnitId,
                                            currentUnitId: record.unitId,
                                            unitName: "",
                                            canModifyType: record.canModifyType,
                                            addNewRafiq: false,
                                          },
                                        }}
                                      >
                                        <DropdownItem>
                                          {
                                            <GetLanguageString props="common_edit" />
                                          }
                                        </DropdownItem>
                                      </Link>
                                    ) : null}
                                    {record.isDeletable ? (
                                      <DropdownItem
                                        onClick={() =>
                                          handleOnDelete(record.id)
                                        }
                                      >
                                        {
                                          <GetLanguageString props="member_cancel_registration" />
                                        }
                                      </DropdownItem>
                                    ) : null}
                                  </DropdownMenu>
                                </Dropdown>
                              </td>
                            ) : null}
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan="11">
                          <ul className={GetCustomStyles().page_style}>
                            <Pagination
                              activePage={pagination.activePage}
                              itemsCountPerPage={pagination.pageSize}
                              totalItemsCount={pagination.totalItem}
                              pageRangeDisplayed={pagination.pageRange}
                              onChange={handlePageChange}
                            />
                          </ul>
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </div>
              ) : (
                <NoResult />
              )
            ) : (
              LoadingSpinner()
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(MemberList);
