import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { Row, Col, Form, FormGroup, Label, Input, Button,ButtonGroup } from "reactstrap";
import { withRouter, useLocation } from "react-router-dom";

import useForm from "../shared/useForm";
import { requestApi } from "../../actions/api";
import { GetLanguageString } from "../helper/Components";
import SelectUnit from "../shared/SelectUnit";
import { ApiEventType } from "../config/eventType/ApiEventType";
import { LoadingSpinner } from "../../constants/const";
import { GetSelectedLanguage } from "../helper/Method";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";

const AddEvent = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const unitId = location.state.unitId;
  const eventValues = {
    date: null,
    endDate: null,
    address: "",
    description: "",
    remarks: "",
    title: "",
    eventType: {},
    unit: { value: unitId, lable: "" },
    canSubunitMarkAttendance: false,
    isRecurring: false,
    recurringPeriod: 1,
  };
  const [eventTypeList, setEventTypeList] = useState([]);
  const [eventType, setEventType] = useState({});
  const { errors, setErrors, values, handleInputChange, setValues } =
    useForm(eventValues);

  const { t } = useTranslation();

  const [activePeriod, setActivePeriod] = useState(1); // Default to 1 month
  const periods = [
    { value: 1, labelKey: 'events_recurring_period_btn_1month' },
    { value: 3, labelKey: 'events_recurring_period_btn_3month' },
    { value: 6, labelKey: 'events_recurring_period_btn_6month' },
    { value: 9, labelKey: 'events_recurring_period_btn_9month' },
    { value: 12, labelKey: 'events_recurring_period_btn_year' }
  ];

  const handleRecurringPeriod = (value) => {
    setActivePeriod(value);
    // Update the values state here as well
    setValues({
      ...values,
      recurringPeriod: value,
    });
  };

  useEffect(() => {
    ApiEventType()
      .getUnitEventTypeList(values.unit.value, false)
      .then((res) => {
        setEventTypeList(res.data);
        setEventType(res.data[0]);
        if (Object.keys(res.data).length === 0) {
          setEventType({});
        }
        setValues({
          ...values,
          eventType: Object.keys(res.data).length === 0 ? {} : res.data[0],
        });
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, [values.unit]);

  const validate = () => {
    let temp = {};
    temp.address = values.address ? (
      ""
    ) : (
      <GetLanguageString props="events_address_error_message" />
    );
    // Check date range from 2020 to next two years
  const currentDate = new Date();
  const minDate = new Date(2020, 0, 1);
  const maxDate = new Date(currentDate.getFullYear() + 2, 11, 31);

  temp.date =
    values.date !== null ? (
      values.date >= minDate && values.date <= maxDate ? (
        ""
      ) : (
        <GetLanguageString props="events_date_range_error_message" />
      )
    ) : (
      <GetLanguageString props="events_date_error_message" />
    );
    temp.eventType =
      eventType !== undefined
        ? Object.keys(eventType).length === 0
          ? "error"
          : ""
        : "";
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleChangeEventType = (record) => {
    let temp = {};
    if (record !== null) temp = record;
    setEventType(temp);
    setErrors({
      eventType: "",
    });
  };

  const handleInputChangeDate = (value) => {
    setValues((prevValues) => ({
      ...prevValues,
      date: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      date: "",
    }));
  };

  const handleInputChangeEndDate = (value) => {
    setValues((prevValues) => ({
      ...prevValues,
      endDate: value,
    }));
  };

  const handleCheckBox = (event) => {
    const { checked, name } = event.target;

    setValues((prevValues) => ({
      ...prevValues,
      [name]: checked,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (Object.keys(values.unit).length === 0)
      values.unit = { value: unitId, lable: "" };
    values.eventType = eventType;
    if (validate()) {
      setLoading(true);
      values.date = new Date(values.date.setHours(values.date.getHours() + 5));
      if(values.endDate !== null)
        values.endDate = new Date(values.endDate.setHours(values.endDate.getHours() + 5));
      requestApi("event/")
        .create(values)
        .then((res) => {
          swal({
            title: t("events_add_event_success"),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "success",
            buttons: t("common_ok_button"),
          });
          history.push("/event");
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };
  
  const rTl = () => {
    if (GetSelectedLanguage() === "ur") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
      {!loading ? (
        <Form onSubmit={handleSubmit}>

          <div className="ibox">
            <div className="ibox-title">
              <h3>{<GetLanguageString props="courses_select_unit" />}</h3>
            </div>
            <div className="ibox-content">
              {
                <SelectUnit
                  setValues={setValues}
                  values={values}
                  unitId={unitId}
                  excludeExUnit={true}
                />
              }
            </div>
          </div>
          <div className="ibox ">
            <div className="ibox-title">
              <h3>{<GetLanguageString props="events_add_event" />}</h3>
            </div>
            <div className="ibox-content">
              <Row>
                <Col lg="4">
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="events_address" />}
                    </Label>
                    <Input
                      className="ur"
                      type="text"
                      name="address"
                      value={values.address}
                      onChange={handleInputChange}
                      {...(errors.address && { invalid: true })}
                    />
                  </FormGroup>
                  <h4 className="text-error">{errors.address}</h4>
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="events_date" />}
                    </Label>
                    <DatePicker
                      showYearDropdown
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      timeCaption="Time"
                      placeholderText="dd/mm/yyyy"
                      className="form-control"
                      selected={values.date}
                      onChange={handleInputChangeDate}
                      dateFormat="MMMM d, yyyy h:mm aa"
                    />
                    {errors.date && (
                      <h4 className="text-error">{errors.date}</h4>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="events_event_type" />}
                    </Label>
                    <Select
                      value={eventType}
                      options={eventTypeList}
                      className="basic-single ur"
                      isClearable={true}
                      placeholder={
                        GetSelectedLanguage() === "en" ? "Select" : "منتخب کریں"
                      }
                      onChange={handleChangeEventType}
                    />
                    {errors.eventType === "error" ? (
                      <h4 className="text-error">
                        {
                          <GetLanguageString props="events_event_type_error_message" />
                        }
                      </h4>
                    ) : null}
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="events_end_date" />}
                    </Label>
                    <DatePicker
                      showYearDropdown
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      timeCaption="Time"
                      placeholderText="dd/mm/yyyy"
                      className="form-control"
                      selected={values.endDate}
                      onChange={handleInputChangeEndDate}
                      dateFormat="MMMM d, yyyy h:mm aa"
                    />
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="common_description" />}
                    </Label>
                    <Input
                      className="ur"
                      type="text"
                      name="description"
                      value={values.description}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="common_remarks" />}
                    </Label>
                    <Input
                      className="ur"
                      type="text"
                      name="remarks"
                      value={values.remarks}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <FormGroup check>
                    <Label
                      className={
                        GetSelectedLanguage === "ur"
                          ? "ur col-form-label"
                          : "col-form-label"
                      }
                      check
                      for="isRecurring"
                    >
                      <span
                        style={{
                          float: rTl() ? "right" : "left",
                          marginLeft: rTl() ? "24px" : "0px",
                        }}
                      >
                        <Input
                          id="isRecurring"
                          type="checkbox"
                          name="isRecurring"
                          onChange={handleCheckBox}
                          checked={values.isRecurring}
                        />
                      </span>
                      <span>
                        {
                          <GetLanguageString props="events_is_recurring_period" />
                        }
                      </span>
                    </Label>
                  </FormGroup>
                </Col>
                {values?.isRecurring ? (
                  <Col lg="4">
                    <FormGroup>
                      <Label className="col-form-label">
                        {<GetLanguageString props="events_select_recurring_period" />}
                      </Label>
                      <ButtonGroup className={rTl() ? "m-r" : "m-l"}>
                        {periods.map(period => (
                          <Button
                            key={period.value}
                            size="sm"
                            color={activePeriod === period.value ? "primary" : "white"}
                            value={period.value}
                            onClick={() => handleRecurringPeriod(period.value)}
                          >
                            {<GetLanguageString props={period.labelKey} />}
                          </Button>
                        ))}
                        </ButtonGroup>
                    </FormGroup>
                  </Col>
                ) : null}
              </Row>
              <Row>
                {eventType?.isSelected ? (
                  <Col md="4">
                    <FormGroup check>
                      <Label
                        className={
                          GetSelectedLanguage === "ur"
                            ? "ur col-form-label"
                            : "col-form-label"
                        }
                        check
                        for="canSubunitMarkAttendance"
                      >
                        <span
                          style={{
                            float: rTl() ? "right" : "left",
                            marginLeft: rTl() ? "24px" : "0px",
                          }}
                        >
                          <Input
                            id="canSubunitMarkAttendance"
                            type="checkbox"
                            name="canSubunitMarkAttendance"
                            onChange={handleCheckBox}
                            checked={values.canSubunitMarkAttendance}
                          />
                        </span>
                        <span>
                          {
                            <GetLanguageString props="settings_event_type_allow_sub_units" />
                          }
                        </span>
                      </Label>
                    </FormGroup>
                  </Col>
                ) : null}
              </Row>
              <Row className="m-t-lg">
                <Col md="4">
                  <Button color="white" onClick={() => history.goBack()}>
                    {<GetLanguageString props="common_cancel" />}
                  </Button>
                  <Button color="primary" className="m-l-sm" type="submit">
                    {<GetLanguageString props="common_save_changes" />}
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      ) : (
        LoadingSpinner()
      )}
    </div>
  );
};

export default withRouter(AddEvent);
