import React, { useEffect, useState } from "react";
import { Table, Button, ButtonGroup } from "reactstrap";

import { GetLanguageString, NoResult } from "../helper/Components";
import {
  CheckIsAdmin,
  CheckPermission,
  LoadingSpinner,
} from "../../constants/const";
import { addMember } from "../member/ApiMember";
import { GetFooTableCustomStyles, GetSelectedLanguage } from "../helper/Method";
import { withRouter } from "react-router-dom";
import { encryptStorage } from "../../constants/EncryptStorage";

const initialValues = {
  name: "neutral",
  joiningDate: "neutral",
  regNo: "neutral",
  memberType: "neutral",
};

const NonResponsibleUnitMembers = ({ unitId, tab3, history, match }) => {
  const unitTypeName = encryptStorage.getItem("unitTypeName");
  const [nonResponsibleMemberLoading, setNonResponsibleMemberLoading] =
    useState(false);
  const [nonResponsibleMembers, setNonResponsibleMembers] = useState([]);
  const [sortObject, setSortObject] = useState(initialValues);

  const handleSorting = (name) => {
    if (sortObject[name] === "neutral" || sortObject[name] === "descending") {
      setSortObject({
        ...initialValues,
        [name]: "ascending",
      });
    } else if (sortObject[name] === "ascending") {
      setSortObject({
        ...initialValues,
        [name]: "descending",
      });
    }
  };

  useEffect(() => {
    if (tab3 === "active") {
      let tempSortObject = {
        isFullNameDescending: sortObject.name === "descending",
        isFullNameAscending: sortObject.name === "ascending",
        isJoiningDateDescending: sortObject.joiningDate === "descending",
        isJoiningDateAscending: sortObject.joiningDate === "ascending",
        isRegNoAscending: sortObject.regNo === "ascending",
        isRegNoDescending: sortObject.regNo === "descending",
        isMemberTypeAscending: sortObject.memberType === "ascending",
        isMemberTypeDescending: sortObject.memberType === "descending",
      };
      setNonResponsibleMemberLoading(true);
      addMember()
        .getNonResponsibleUnitMember(unitId, tempSortObject)
        .then((res) => {
          setNonResponsibleMembers(res.data);
          setNonResponsibleMemberLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setNonResponsibleMemberLoading(false);
        });
    }
  }, [unitId, tab3, sortObject]);

  const handleOnMoveMember = (e) => {
    const { value, name } = e.target;
    history.push({
      pathname: `${match.url}/movemembers`,
      state: {
        unitId: value,
        check: name,
      },
    });
  };

  return (
    <>
      <div role="tabpanel" id="tab-3" className={"tab-pane " + tab3}>
        <div className="ibox-title">
          <div
            className={
              GetSelectedLanguage() === "en"
                ? "pull-right m-b-md"
                : "pull-left m-b-md"
            }
          >
            <ButtonGroup>
              {CheckIsAdmin() ? (
                <Button
                  color="success"
                  size="sm"
                  onClick={handleOnMoveMember}
                  name="admin"
                  value={unitId}
                >
                  {<GetLanguageString props="unit_unitmember_move_member" />}
                </Button>
              ) : null}
              {!CheckIsAdmin() &&
              CheckPermission("CanMoveMembersLocalTransfer") ? (
                <Button
                  color="primary"
                  size="sm"
                  onClick={handleOnMoveMember}
                  name="Local"
                  value={unitId}
                >
                  {unitTypeName === "Halqa" ? (
                    <GetLanguageString props="unit_unitmember_move_member_localy" />
                  ) : unitTypeName === "Tanzeem" ? (
                    <GetLanguageString props="unit_unitmember_move_member_localy" />
                  ) : (
                    <GetLanguageString props="unit_unitmember_move_members_localy" />
                  )}
                </Button>
              ) : null}
              {/* {!CheckIsAdmin() &&
              (CheckPermission("CanMoveMembersOutsideTransfer") ||
                CheckPermission("CanRequestMoveMembersOutsideTransfer")) ? (
                <Button
                  color="info"
                  size="sm"
                  onClick={handleOnMoveMember}
                  name="Outside"
                  value={unitId}
                >
                  {unitTypeName === "Halqa" ? (
                    <GetLanguageString props="unit_unitmember_move_member_other_halqa" />
                  ) : unitTypeName === "Tanzeem" ? (
                    <GetLanguageString props="unit_unitmember_move_member_other_tanzeem" />
                  ) : (
                    <GetLanguageString props="unit_unitmember_move_member_other" />
                  )}
                </Button>
              ) : null} */}
            </ButtonGroup>
          </div>
        </div>
        <div className="panel-body">
          {!nonResponsibleMemberLoading ? (
            <div className="table-responsive">
              {nonResponsibleMembers.length !== 0 ? (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th
                        className="cursor-pointer"
                        onClick={() => handleSorting("name")}
                      >
                        {<GetLanguageString props="common_name" />}
                        <span className={GetFooTableCustomStyles().span_style}>
                          {sortObject.name === "neutral" ? (
                            <>
                              <i
                                className={
                                  GetFooTableCustomStyles().up_down_style
                                }
                              />
                              <i
                                className={
                                  GetFooTableCustomStyles().down_up_style
                                }
                              />
                            </>
                          ) : sortObject.name === "ascending" ? (
                            <i className={GetFooTableCustomStyles().up_style} />
                          ) : (
                            <i
                              className={GetFooTableCustomStyles().down_style}
                            />
                          )}
                        </span>
                      </th>
                      <th
                        className="cursor-pointer"
                        onClick={() => handleSorting("regNo")}
                      >
                        {<GetLanguageString props="member_reg_number" />}
                        <span className={GetFooTableCustomStyles().span_style}>
                          {sortObject.regNo === "neutral" ? (
                            <>
                              <i
                                className={
                                  GetFooTableCustomStyles().up_down_style
                                }
                              />
                              <i
                                className={
                                  GetFooTableCustomStyles().down_up_style
                                }
                              />
                            </>
                          ) : sortObject.regNo === "ascending" ? (
                            <i className={GetFooTableCustomStyles().up_style} />
                          ) : (
                            <i
                              className={GetFooTableCustomStyles().down_style}
                            />
                          )}
                        </span>
                      </th>
                      <th>{<GetLanguageString props="member_phone" />}</th>
                      <th>{<GetLanguageString props="member_address" />}</th>
                      <th
                        className="cursor-pointer"
                        onClick={() => handleSorting("memberType")}
                      >
                        {<GetLanguageString props="settings_member_type" />}
                        <span className={GetFooTableCustomStyles().span_style}>
                          {sortObject.memberType === "neutral" ? (
                            <>
                              <i
                                className={
                                  GetFooTableCustomStyles().up_down_style
                                }
                              />
                              <i
                                className={
                                  GetFooTableCustomStyles().down_up_style
                                }
                              />
                            </>
                          ) : sortObject.memberType === "ascending" ? (
                            <i className={GetFooTableCustomStyles().up_style} />
                          ) : (
                            <i
                              className={GetFooTableCustomStyles().down_style}
                            />
                          )}
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {nonResponsibleMembers.map((record, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="ur">{record.name}</td>
                          <td>{record.regNo}</td>
                          <td>
                            {record.phone != null
                              ? record.phone
                                  .split(",")
                                  .map((phoneNumber, index) => (
                                    <div key={index}>{phoneNumber}</div>
                                  ))
                              : ""}
                          </td>
                          <td className="ur">{record.currentAddress}</td>
                          <td>
                            {
                              <Button
                                size="xs"
                                color="info"
                                className="label label-info m-r-xs"
                              >
                                <h5 className="ur">{record.memberTypeName}</h5>
                              </Button>
                            }
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <NoResult />
              )}
            </div>
          ) : (
            LoadingSpinner()
          )}
        </div>
      </div>
    </>
  );
};

export default withRouter(NonResponsibleUnitMembers);
