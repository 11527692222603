import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import swal from "sweetalert";
import { GetLanguageString } from "../../helper/Components";

import Draggable from "react-draggable";
import { encryptStorage } from "../../../constants/EncryptStorage";
import { convertToDateString, LoadingSpinner } from "../../../constants/const";
import { GetSelectedLanguage } from "../../helper/Method";
import { ApiUnit } from "../../unit/ApiUnit";
import { addMember } from "../ApiMember";

const DeleteResponsibility = ({
  deleteResponsibility,
  setEndMemberResponsibilityDateModal,
  setResponsibilityStateChange,
  setUpdatedDetails,
}) => {
  const toggle = () => {
    setEndMemberResponsibilityDateModal(false);
  };
  const [errors, setErrors] = useState();
  const [values, setValues] = useState({
    responsibilityEndDate: null,
    memberResponsibilityId: deleteResponsibility.memberResponsibilityId,
    unitId: 0,
  });
  const [responsibilityEndDate, setResponsibilityEndDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const notify = (string) => toast.success(string);
  const { t } = useTranslation();
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  const unitTypeName = "null";
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [unitList, setUnitList] = useState([]);

  useEffect(() => {
    ApiUnit()
      .getUnitList(loggedInUnitId, loggedInUnitId, false, unitTypeName, true)
      .then((res) => {
        setUnitList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [loggedInUnitId]);

  const handleChangeUnits = (record) => {
    if (record !== null) {
      ApiUnit()
        .getUnitList(record.value, loggedInUnitId, false, unitTypeName, true)
        .then((res) => {
          setUnitList(res.data);
        })
        .catch((err) => console.log(err));

      setValues({
        ...values,
        unitId: record.value,
      });
    }
  };

  const validate = () => {
    let temp = {};
    temp.responsibilityEndDate =
      responsibilityEndDate !== null ? (
        ""
      ) : (
        <GetLanguageString props="member_responsibility_end_date" />
      );

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputChangeResponsibilityEndDate = (value) => {
    if (value !== null) {
      if (value <= new Date()) {
        setResponsibilityEndDate(value);
        setErrors({
          ...errors,
          responsibilityEndDate: "",
        });
      } else {
        setErrors({
          ...errors,
          responsibilityEndDate:
            GetSelectedLanguage() === "en"
              ? "The End date must not be greater than current date."
              : "ذمہ داری ک ختم ہونے ک تاریخ موجودہ تاریخ سی بری نہیں ہو سکتی",
        });
      }
    } else {
      setResponsibilityEndDate(null);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setButtonDisable(true);
      setLoading(true);
      let responsbilities = [];
      values.responsibilityEndDate = convertToDateString(responsibilityEndDate);
      responsbilities.push(values);
      addMember()
        .deleteMemberResponsibility(responsbilities)
        .then((res) => {
          if (res.data === "Updated") {
            swal({
              title: t("member__member_responsibility_added_success_message"),
              icon: "success",
              buttons: "OK",
              className: GetSelectedLanguage() === "en" ? "" : "ur",
            });
            setResponsibilityStateChange((prevState) => !prevState);
            setLoading(false);
            setButtonDisable(false);
            setUpdatedDetails((prevState) => !prevState);
            toggle();
          } else if (res.data === "Date Error") {
            notify(res.data);
            setLoading(false);
            setButtonDisable(false);
            setErrors({
              ...errors,
              responsibilityEndDate:
                "End Date must be Greater than Create Responsibility Date.",
            });
          } else if (res.data === "Your request for modification is sent.") {
            notify(res.data);
            setLoading(false);
            setButtonDisable(false);
            toggle();
          }
        })
        .catch((err) => {
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
          console.log(err);
          setLoading(false);
          setButtonDisable(false);
        });
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal size="lg" isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="member_responsibility_end_date_title" />}
          </ModalHeader>
          <Form onSubmit={handleSubmit}>
            <ModalBody>
              {!loading ? (
                <div className="ibox-content">
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label className="col-form-label">
                          {GetSelectedLanguage() === "en" ? (
                            <h5>
                              End Date for{" "}
                              <span
                                className="ur no-drag"
                                style={{ fontSize: 17 }}
                              >
                                {deleteResponsibility.responsibility.label}
                              </span>{" "}
                            </h5>
                          ) : (
                            <h5 className="ur no-drag">
                              <span
                                style={{ fontWeight: "bold", fontSize: 20 }}
                              >
                                {" "}
                                {deleteResponsibility.responsibility.label}
                              </span>{" "}
                              کے ختم ہونے کی تاریخ{" "}
                            </h5>
                          )}
                        </Label>
                        <DatePicker
                          showYearDropdown
                          placeholderText="dd/mm/yyyy"
                          className="form-control no-drag"
                          dateFormat="dd/MM/yyyy"
                          selected={responsibilityEndDate}
                          maxDate={new Date()}
                          onChange={(value) =>
                            handleInputChangeResponsibilityEndDate(value)
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {errors?.responsibilityEndDate && (
                    <h4 className="text-error">
                      {errors?.responsibilityEndDate}
                    </h4>
                  )}

                  {deleteResponsibility.isChangeUnitRequired ? (
                    <Row>
                      <Label className="col-form-label">
                        <GetLanguageString props="member_select_responsibility_to_move" />
                      </Label>
                      {unitList.map((record, index) => {
                        const unitOptions = [];
                        record.unitList.forEach((d) =>
                          unitOptions.push({ value: d.id, label: d.name })
                        );
                        let selected = {};
                        if (record.selectedUnit !== null) {
                          selected = {
                            value: record.selectedUnit.id,
                            label: record.selectedUnit.name,
                          };
                        }
                        const isLastIndex = index === unitList.length - 1;

                        return (
                          <>
                            <Col md="4" key={index}>
                              <FormGroup>
                                <Row>
                                  <Col md="10">
                                    <Select
                                      options={unitOptions}
                                      value={selected}
                                      className="basic-single ur no-drag"
                                      classNamePrefix="select"
                                      isDisabled={
                                        deleteResponsibility.responsibilityPermissionExists
                                      }
                                      onChange={handleChangeUnits}
                                    />
                                  </Col>
                                  {isLastIndex ? null : (
                                    <Col md="2" className="p-0">
                                      {GetSelectedLanguage() === "en" ? (
                                        <i className="fa fa-arrow-right fa-lg m"></i>
                                      ) : (
                                        <i className="fa fa-arrow-left fa-lg m"></i>
                                      )}
                                    </Col>
                                  )}
                                </Row>
                              </FormGroup>
                            </Col>
                          </>
                        );
                      })}
                    </Row>
                  ) : null}
                </div>
              ) : (
                LoadingSpinner()
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="white" onClick={toggle}>
                {<GetLanguageString props="common_cancel" />}
              </Button>
              <Button color="primary" type="submit" disabled={buttonDisable}>
                {<GetLanguageString props="common_save_changes" />}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </Draggable>
    </div>
  );
};

export default DeleteResponsibility;
