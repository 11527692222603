import axios from "axios";

import { BASEURL } from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";

export function ApiFund() {
  const userToken = encryptStorage.getItem("token");

  const authAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const url = BASEURL + "fundsmanagement/";
  return {
    addFund: (newRecord) => authAxios.post(url + "add/", newRecord),
    addBulkFund: (newRecord) => authAxios.post(url + "addBulk/", newRecord),
    getMembersFundsDetails: (newRecord) =>
      authAxios.post(url + "getMembersFundsDetails/", newRecord),
    getUnitWiseFundsDetails: (newRecord) =>
      authAxios.post(url + "GetUnitWiseFundsDetails/", newRecord),
    getList: (unitId, memberId) =>
      authAxios.get(url + "getList/" + unitId + "/" + memberId),
    getById: (id) => authAxios.get(url + "edit/" + id),
    update: (id, updateRecord) =>
      authAxios.put(url + "edit/" + id, updateRecord),
    deleteFund: (id) => authAxios.delete(url + "delete/" + id),
  };
}
