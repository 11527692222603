import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup,
  Form,
  Button,
} from "reactstrap";
import { GetLanguageString } from "../../helper/Components";
import {
  LoadingSpinner,
  convertToDateString,
  convertUTCToLocalTime,
  isUrdu,
} from "../../../constants/const";
import DatePicker from "react-datepicker";
import { ApiMudaris } from "./ApiMudaris";
import swal from "sweetalert";
import { GetSelectedLanguage } from "../../helper/Method";
import { useTranslation } from "react-i18next";
import Draggable from "react-draggable";

const MemberMudarisEditRegistration = ({
  setEditRegistrationModal,
  registrationId,
  mudarisId,
  setUpdate,
  update,
}) => {
  const [loading, setLoading] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [registrationData, setRegistrationData] = useState({
    mudarisId: mudarisId,
    remarks: "",
    referenceNote: "",
    date: null,
    markazi: false,
    accepted: false,
    id: 0,
  });
  const [selectedDate, setSelectedDate] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  const [errors, setErrors] = useState(registrationData);
  const toggle = () => setEditRegistrationModal(false);

  const { t } = useTranslation();

  const validate = () => {
    let temp = {};
    temp.date =
      selectedDate !== null ? (
        ""
      ) : (
        <GetLanguageString props="member_member_detail_mudaris_details_assessments_date_error" />
      );

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  useEffect(() => {
    setLoading(true);
    ApiMudaris()
      .EditMudarisRegistration(registrationId)
      .then((res) => {
        setRegistrationData({
          ...res.data,
          date: res.data.date
            ? new Date(convertUTCToLocalTime(res.data.date))
            : new Date(),
        });
        setSelectedDate(
          res.data.date
            ? new Date(convertUTCToLocalTime(res.data.date))
            : new Date()
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
        setLoading(false);
      });
  }, [registrationId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    var v = value;
    if (name === "markazi" && value === "true") v = true;
    else if (name === "markazi" && value === "false") v = false;

    if (name === "accepted" && value === "true") v = true;
    else if (name === "accepted" && value === "false") v = false;

    setRegistrationData({
      ...registrationData,
      [name]: v,
    });
  };

  const handleInputChangeDate = (value) => {
    if (value !== null) {
      if (value <= new Date()) {
        setSelectedDate(value);
        setErrors({
          ...errors,
          date: "",
        });
      } else {
        setErrors({
          ...errors,
          date: <GetLanguageString props="tutorial_date_error_message" />,
        });
      }
    } else {
      setSelectedDate(null);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      setButtonDisable(true);
      registrationData.date = convertToDateString(selectedDate);
      ApiMudaris()
        .UpdateMudarisRegistration(registrationData)
        .then((res) => {
          if (res.data === "") {
            swal({
              title: t(
                "member_member_detail_mudaris_details_registrations_add_registrations_update_success"
              ),
              className: GetSelectedLanguage() === "en" ? "" : "ur",
              icon: "success",
              buttons: "OK",
            });
            setLoading(false);
            setButtonDisable(false);
            setUpdate(!update);
            toggle();
          }
        })
        .catch((err) => {
          console.log(err);
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
          setLoading(false);
          setButtonDisable(false);
        });
    }
  };
  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {
              <GetLanguageString props="member_member_detail_mudaris_details_registrations_edit" />
            }
          </ModalHeader>
          {!loading ? (
            <Form onSubmit={handleSubmit}>
              <ModalBody>
                <FormGroup>
                  <Label className="col-form-label">
                    {
                      <GetLanguageString props="member_member_detail_mudaris_details_registrations_start_date" />
                    }
                  </Label>
                  <DatePicker
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd/mm/yyyy"
                    className="form-control no-drag"
                    selected={selectedDate}
                    maxDate={new Date()}
                    onChange={handleInputChangeDate}
                    dateFormat="dd/MM/yyyy"
                  />
                  {errors.date ? (
                    <h4 className="text-error">{errors.date}</h4>
                  ) : null}
                </FormGroup>
                <FormGroup>
                  <Label>{<GetLanguageString props="common_remarks" />}</Label>
                  <Input
                    type="text"
                    name="remarks"
                    className={
                      isUrdu(registrationData.remarks)
                        ? "ur no-drag"
                        : "no-drag"
                    }
                    value={registrationData.remarks}
                    onChange={handleInputChange}
                  />
                </FormGroup>{" "}
                <FormGroup>
                  <Label>
                    {<GetLanguageString props="member_reference_note" />}
                  </Label>
                  <Input
                    type="text"
                    name="referenceNote"
                    className={
                      isUrdu(registrationData.referenceNote)
                        ? "ur no-drag"
                        : "no-drag"
                    }
                    value={registrationData.referenceNote}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup check>
                  <Label check for="markazi">
                    <Input
                      id="markazi"
                      type="checkbox"
                      name="markazi"
                      checked={registrationData.markazi}
                      value={!registrationData.markazi}
                      onChange={handleInputChange}
                    />
                    {
                      <GetLanguageString props="member_member_detail_mudaris_markazi" />
                    }
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check for="accepted">
                    <Input
                      id="accepted"
                      type="checkbox"
                      name="accepted"
                      checked={registrationData.accepted}
                      value={!registrationData.accepted}
                      onChange={handleInputChange}
                    />
                    {
                      <GetLanguageString props="member_member_detail_mudaris_accepted" />
                    }
                  </Label>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="white" onClick={toggle}>
                  {<GetLanguageString props="common_cancel" />}
                </Button>
                <Button color="primary" type="submit" disabled={buttonDisable}>
                  {<GetLanguageString props="common_save_changes" />}
                </Button>
              </ModalFooter>
            </Form>
          ) : (
            LoadingSpinner()
          )}
        </Modal>
      </Draggable>
    </div>
  );
};

export default MemberMudarisEditRegistration;
