import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { LoadingSpinner } from "../../constants/const";
import { GetLanguageString, NoResult } from "../helper/Components";
import { addMember } from "../member/ApiMember";
import TableView from "../shared/SimilarRecordTable";

const AddNewRafiqRequestSimilarRecords = ({
  setSimilarRecordsModal,
  similarRecords,
}) => {
  const toggle = () => setSimilarRecordsModal(false);
  const [loading, setLoading] = useState(false);
  const [exactMatchRecords, setExactMatchRecords] = useState();
  const [similarMatchRecords, setSimilarMatchRecords] = useState();
  const [cnicMatchRecords, setCnicMatchRecords] = useState();

  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  useEffect(() => {
    setLoading(true);
    addMember()
      .GetMemberSimilarRecords(similarRecords)
      .then((res) => {
        setCnicMatchRecords(res.data.cnicRecords);
        setExactMatchRecords(res.data.exactMatchRecords);
        setSimilarMatchRecords(res.data.similarRecords);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [similarRecords]);

  // const seeDetailedData = (memberId, memberUnitId) => {

  //   history.push({
  //     pathname: `member/updateMember/${memberId}`,
  //     state: {
  //       unitId: unit.unitId,
  //       currentUnitId: memberUnitId,
  //       unitName: unit.unitName,
  //       canModifyType: true,
  //       addNewRafiq: true,
  //     },
  //   });
  // };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal
          size="lg"
          style={{ maxWidth: "980px", width: "100%" }}
          isOpen={true}
          className="inmodal"
        >
          <ModalHeader className="modal-title" tag="h4">
            {
              <GetLanguageString props="notifications_see_changes_similar_records" />
            }
          </ModalHeader>
          <ModalBody>
            {!loading ? (
              <>
                <div className="ibox">
                  <div className="ibox-title">
                    <h5>
                      {
                        <GetLanguageString props="notifications_see_changes_similar_records_exact" />
                      }
                    </h5>
                  </div>
                  <div
                    className="ibox-content no-drag"
                    style={{
                      maxHeight: 300,
                      overflowY: "auto",
                    }}
                  >
                    <div>
                      {exactMatchRecords?.length !== 0 ? (
                        <TableView data={exactMatchRecords} />
                      ) : (
                        <NoResult />
                      )}
                    </div>
                  </div>
                </div>

                <br></br>
                <div className="ibox">
                  <div className="ibox-title">
                    <h5>
                      {
                        <GetLanguageString props="notifications_see_changes_similar_records_similar" />
                      }
                    </h5>
                  </div>
                  <div
                    className="ibox-content no-drag"
                    style={{
                      maxHeight: 300,
                      overflowY: "auto",
                    }}
                  >
                    <div>
                      {similarMatchRecords?.length !== 0 ? (
                        <TableView data={similarMatchRecords} />
                      ) : (
                        <NoResult />
                      )}
                    </div>
                  </div>
                </div>
                <br></br>
                <div className="ibox">
                  <div className="ibox-title">
                    <h5>
                      {
                        <GetLanguageString props="notifications_see_changes_similar_records_cnic" />
                      }
                    </h5>
                  </div>
                  <div
                    className="ibox-content no-drag"
                    style={{
                      maxHeight: 300,
                      overflowY: "auto",
                    }}
                  >
                    <div>
                      {cnicMatchRecords?.length !== 0 ? (
                        <TableView data={cnicMatchRecords} />
                      ) : (
                        <NoResult />
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              LoadingSpinner()
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggle}>
              {<GetLanguageString props="users_role_ok_btn" />}
            </Button>
          </ModalFooter>
        </Modal>
      </Draggable>
    </div>
  );
};

export default AddNewRafiqRequestSimilarRecords;
