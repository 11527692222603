import React, { useEffect } from "react";
import MemberMudarisDetails from "../MemberMudaris/MemberMudarisDetails";

const UpdateMemberMudarisInfo = ({ tab11, editValue, refresh, setRefresh,  discard, setDiscard, activeTab }) => {

  useEffect(()=>{
    if(tab11 === "active"){
      if(activeTab && activeTab !== "tab11"){
        setDiscard(!discard);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[activeTab]);
  return (
    <div role="tabpanel" id="tab-11" className={"tab-pane " + tab11}>
      <div className="panel-body" style={{ backgroundColor: "#f9f9f9" }}>
      <MemberMudarisDetails
          memberId={editValue}
          page="edit"
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </div>
    </div>
  );
};

export default UpdateMemberMudarisInfo;
