import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { GetLanguageString } from "../helper/Components";
import swal from "sweetalert";
import {
  CheckPermission,
  LoadingSpinner,
  convertUTCToLocalTime,
  isUrdu,
} from "../../constants/const";
import SelectUnitForMember from "../unit/SelectUnitForMember";
import { ApiUnitMember } from "../unit/ApiUnit";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { encryptStorage } from "../../constants/EncryptStorage";
import { GetSelectedLanguage } from "../helper/Method";
import { ApiResponsibility } from "../config/responsibilityType/ApiResponsibilityType";
import moment from "moment";
import EndMoveMemberResponsibilityDate from "./EndMoveMemberResponsibilityDate";
import Draggable from "react-draggable";

const MoveMemberModal = ({
  memberId,
  unitId,
  check,
  setMoveMemberModal,
  refresh,
  setRefresh,
}) => {
  const [errors, setErrors] = useState({});
  const selectedLanguage = GetSelectedLanguage();
  const unitTypeName = encryptStorage.getItem("unitTypeName");
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const notify = (string) => toast.success(string);
  const [values, setValues] = useState({
    unit: { value: 0, lable: "", unitName: "" },
  });
  const [buttonDisable, setButtonDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [
    endMoveMemberResponsibilityDateModal,
    setEndMoveMemberResponsibilityDateModal,
  ] = useState(false);
  const [memberResponsibilities, setMemberResponsibilities] = useState([]);
  const [unitMembers, setUnitMembers] = useState({
    memberId: memberId,
    previousUnitId: unitId,
    status: true,
    newUnitId: values.unit.value,
    newAddress: "",
    referenceNote: "",
    nisabBaraEMutaliya: "",
    taluqMaAllah: "",
    samaOTaat: "",
    samaOBasar: "",
    infradiDawat: "",
    jihadBilMaal: "",
    memberResponsibiities: [],
    otherInfo: "",
  });
  const [errorsFields, setErrorsFields] = useState(unitMembers);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  useEffect(() => {
    ApiResponsibility()
      .GetMemberResponsibilities(memberId)
      .then((res) => {
        setMemberResponsibilities(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [memberId]);

  const handleResponsibilitiesDelete = (e, index) => {
    const { checked } = e.target;
    let tempValues = [...memberResponsibilities];
    tempValues[index].delete = checked;
    setMemberResponsibilities(tempValues);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setUnitMembers({
      ...unitMembers,
      [name]: value,
    });
    setErrorsFields({
      ...errorsFields,
      [name]: "",
    });
  };
  const { t } = useTranslation();
  const toggle = () => setMoveMemberModal(false);

  const validate = () => {
    let temp = {};
    temp.unitId =
      values.unit.value === 0 ? (
        <GetLanguageString props="unit_unitmember_empty_unit_error_message" />
      ) : (
          check === "Outside"
            ? values.unit.unitName !== unitTypeName && unitTypeName === "Halqa"
            : null
        ) ? (
        <GetLanguageString props="unit_unitmember_halqa_unit_error_message" />
      ) : (check === "Outside" && loggedInUnitId === values.unit.value) ||
        (check === "Local" && unitId === values.unit.value) ? (
        <GetLanguageString props="unit_unitmember_already_exists_in_unit" />
      ) : (
        ""
      );
    setErrors({
      ...temp,
    });
    let temp1 = {};
    if (
      CheckPermission("CanRequestMoveMembersOutsideTransfer") &&
      check === "Outside"
    ) {
      temp1.newAddress = unitMembers.newAddress ? (
        ""
      ) : (
        <GetLanguageString props="member__address_error_message" />
      );

      temp1.nisabBaraEMutaliya = unitMembers.nisabBaraEMutaliya ? (
        ""
      ) : (
        <GetLanguageString props="member_move_field_Required" />
      );
      temp1.taluqMaAllah = unitMembers.taluqMaAllah ? (
        ""
      ) : (
        <GetLanguageString props="member_move_field_Required" />
      );
      temp1.samaOTaat = unitMembers.samaOTaat ? (
        ""
      ) : (
        <GetLanguageString props="member_move_field_Required" />
      );
      temp1.samaOBasar = unitMembers.samaOBasar ? (
        ""
      ) : (
        <GetLanguageString props="member_move_field_Required" />
      );
      temp1.infradiDawat = unitMembers.infradiDawat ? (
        ""
      ) : (
        <GetLanguageString props="member_move_field_Required" />
      );
      temp1.jihadBilMaal = unitMembers.jihadBilMaal ? (
        ""
      ) : (
        <GetLanguageString props="member_move_field_Required" />
      );
    }
    setErrorsFields({
      ...temp1,
    });
    if (
      Object.values(temp).every((x) => x === "") &&
      Object.values(temp1).every((x) => x === "")
    )
      return true;
    else return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    unitMembers.newUnitId = values.unit.value;
    unitMembers.memberResponsibiities = memberResponsibilities;
    let unitMembersList = [];
    unitMembersList.push(unitMembers);
    if (validate()) {
      if (
        unitMembers.memberResponsibiities.some((obj) => obj.delete === true)
      ) {
        setEndMoveMemberResponsibilityDateModal(true);
      } else {
        setButtonDisable(true);
        setLoading(true);
        ApiUnitMember()
          .moveUnitMembers({
            unitMemberModels: unitMembersList,
            permission: check,
          })
          .then((res) => {
            if (
              res.data.isRequest &&
              res.data.message === "request_exists_for_transfer_rafiq"
            ) {
              swal({
                title: t("request_exists_for_transfer_rafiq"),
                className: GetSelectedLanguage() === "en" ? "" : "ur",
                icon: "error",
                buttons: GetSelectedLanguage() === "en" ? "OK" : "ٹھیک ہے ",
              });
              setLoading(false);
              setButtonDisable(false);
              toggle();
            } else if (res.data.includes("Move")) {
              notify(res.data);
              setLoading(false);
              setButtonDisable(false);
              toggle();
            } else if (res.data === "") {
              swal({
                title: t("unit_unitmember_move_rufaqa_success"),
                className: GetSelectedLanguage() === "en" ? "" : "ur",
                icon: "success",
              });
              setRefresh(!refresh);
              setLoading(false);
              toggle();
            }
          })
          .catch((err) => {
            swal({
              title: "Error: " + err.response?.data,
              icon: "error",
              buttons: "OK",
            });
            setLoading(false);
            setButtonDisable(false);
            toggle();
            console.log(err);
          });
      }
    }
  };
  return (
    <div>
      <ToastContainer
        autoClose={3000}
        bodyClassName={selectedLanguage}
        closeOnClick
        pauseOnHover={false}
        draggable
      />
      {endMoveMemberResponsibilityDateModal ? (
        <EndMoveMemberResponsibilityDate
          setEndMoveMemberResponsibilityDateModal={
            setEndMoveMemberResponsibilityDateModal
          }
          unitMembers={unitMembers}
          memberResponsibilities={memberResponsibilities}
          setMemberResponsibilities={setMemberResponsibilities}
          values={values}
          check={check}
          setMoveMemberModal={setMoveMemberModal}
        />
      ) : null}
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal
          isOpen={true}
          size="lg"
          style={{ maxWidth: "1080px", width: "100%" }}
          className="inmodal"
        >
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="common_action" />}
          </ModalHeader>
          <Form>
            <ModalBody>
              {!loading ? (
                <>
                  <div className="ibox-title">
                    {CheckPermission("CanRequestMoveMembersOutsideTransfer") &&
                    check === "Outside" ? (
                      <h5>
                        {
                          <GetLanguageString props="member_move_select_unit_and_address" />
                        }
                      </h5>
                    ) : (
                      <h5>
                        {<GetLanguageString props="member_select_unit" />}
                      </h5>
                    )}
                  </div>
                  <div className="ibox-content">
                    <SelectUnitForMember
                      values={values}
                      setValues={setValues}
                      setErrors={setErrors}
                      unitId={unitId}
                      check={check}
                    />
                    {errors.unitId ? (
                      <h4 className="text-error">*{errors.unitId}</h4>
                    ) : null}

                    {CheckPermission("CanRequestMoveMembersOutsideTransfer") &&
                    check === "Outside" ? (
                      <>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <Label className="col-form-label">
                                {
                                  <GetLanguageString props="member_new_address" />
                                }
                              </Label>
                              <Input
                                type="text"
                                name="newAddress"
                                className={
                                  isUrdu(unitMembers.newAddress)
                                    ? "ur no-drag"
                                    : "no-drag"
                                }
                                value={unitMembers.newAddress}
                                onChange={handleInputChange}
                                {...(errorsFields.newAddress && {
                                  invalid: true,
                                })}
                              />
                              {errorsFields.newAddress && (
                                <FormFeedback>
                                  <h4>*{errorsFields.newAddress}</h4>
                                </FormFeedback>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            {" "}
                            <FormGroup>
                              <Label className="col-form-label">
                                {
                                  <GetLanguageString props="member_reference_note" />
                                }
                              </Label>
                              <Input
                                type="text"
                                className={
                                  isUrdu(unitMembers.referenceNote)
                                    ? "ur no-drag"
                                    : "no-drag"
                                }
                                name="referenceNote"
                                value={unitMembers.referenceNote}
                                onChange={handleInputChange}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </>
                    ) : null}
                  </div>
                  {CheckPermission("CanRequestMoveMembersOutsideTransfer") &&
                  check === "Outside" ? (
                    <>
                      <div className="ibox-title">
                        <h3 className="ur">کیفیت رفیق</h3>
                      </div>
                      <div className="ibox-content">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <Label className="col-form-label">
                                <h4
                                  className="ur"
                                  style={{ fontWeight: "lighter" }}
                                >
                                  نصاب برائے مطالعہ / سماعت کی موجودہ کیفیت
                                  (مبتدی، ملتزم، ذمہ داران، مدرسین)
                                </h4>
                              </Label>
                              <Input
                                type="text"
                                name="nisabBaraEMutaliya"
                                className={
                                  isUrdu(unitMembers.nisabBaraEMutaliya)
                                    ? "ur no-drag"
                                    : "no-drag"
                                }
                                value={unitMembers.nisabBaraEMutaliya}
                                onChange={handleInputChange}
                                {...(errorsFields.nisabBaraEMutaliya && {
                                  invalid: true,
                                })}
                              />
                              {errorsFields.nisabBaraEMutaliya && (
                                <FormFeedback>
                                  <h4>{errorsFields.nisabBaraEMutaliya}</h4>
                                </FormFeedback>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label className="col-form-label">
                                <h4
                                  className="ur"
                                  style={{ fontWeight: "lighter" }}
                                >
                                  تعلق مع اللہ (عبادات، اخلاقیات، معاملات)
                                </h4>
                              </Label>
                              <Input
                                type="text"
                                name="taluqMaAllah"
                                className={
                                  isUrdu(unitMembers.taluqMaAllah)
                                    ? "ur no-drag"
                                    : "no-drag"
                                }
                                value={unitMembers.taluqMaAllah}
                                onChange={handleInputChange}
                                {...(errorsFields.taluqMaAllah && {
                                  invalid: true,
                                })}
                              />
                              {errorsFields.taluqMaAllah && (
                                <FormFeedback>
                                  <h4>{errorsFields.taluqMaAllah}</h4>
                                </FormFeedback>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label className="col-form-label">
                                <h4
                                  className="ur"
                                  style={{ fontWeight: "lighter" }}
                                >
                                  سمع و طاعت (تنظیمی اجتماعات میں حاضری کی
                                  کیفیت)
                                </h4>
                              </Label>
                              <Input
                                type="text"
                                name="samaOTaat"
                                className={
                                  isUrdu(unitMembers.samaOTaat)
                                    ? "ur no-drag"
                                    : "no-drag"
                                }
                                value={unitMembers.samaOTaat}
                                onChange={handleInputChange}
                                {...(errorsFields.samaOTaat && {
                                  invalid: true,
                                })}
                              />
                              {errorsFields.samaOTaat && (
                                <FormFeedback>
                                  <h4>{errorsFields.samaOTaat}</h4>
                                </FormFeedback>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label className="col-form-label">
                                <h4
                                  className="ur"
                                  style={{ fontWeight: "lighter" }}
                                >
                                  سمع و بصر (جرائد و دیگر کتب کا مطالعہ اور امیر
                                  محترم کے خطبات جمعہ کا مطالعہ یا سماعت)
                                </h4>
                              </Label>
                              <Input
                                type="text"
                                name="samaOBasar"
                                className={
                                  isUrdu(unitMembers.samaOBasar)
                                    ? "ur no-drag"
                                    : "no-drag"
                                }
                                value={unitMembers.samaOBasar}
                                onChange={handleInputChange}
                                {...(errorsFields.samaOBasar && {
                                  invalid: true,
                                })}
                              />
                              {errorsFields.samaOBasar && (
                                <FormFeedback>
                                  <h4>{errorsFields.samaOBasar}</h4>
                                </FormFeedback>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label className="col-form-label">
                                <h4
                                  className="ur"
                                  style={{ fontWeight: "lighter" }}
                                >
                                  انفرادی دعوت / درس میں شرکت / تدریس کا اہتمام
                                </h4>
                              </Label>
                              <Input
                                type="text"
                                name="infradiDawat"
                                className={
                                  isUrdu(unitMembers.infradiDawat)
                                    ? "ur no-drag"
                                    : "no-drag"
                                }
                                value={unitMembers.infradiDawat}
                                onChange={handleInputChange}
                                {...(errorsFields.infradiDawat && {
                                  invalid: true,
                                })}
                              />
                              {errorsFields.infradiDawat && (
                                <FormFeedback>
                                  <h4>{errorsFields.infradiDawat}</h4>
                                </FormFeedback>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label className="col-form-label">
                                <h4
                                  className="ur"
                                  style={{ fontWeight: "lighter" }}
                                >
                                  جہاد بالمال خصوصا انفاق فی سبیل للہ کی کیفیت
                                </h4>
                              </Label>
                              <Input
                                type="text"
                                name="jihadBilMaal"
                                value={unitMembers.jihadBilMaal}
                                className={
                                  isUrdu(unitMembers.jihadBilMaal)
                                    ? "ur no-drag"
                                    : "no-drag"
                                }
                                onChange={handleInputChange}
                                {...(errorsFields.jihadBilMaal && {
                                  invalid: true,
                                })}
                              />
                              {errorsFields.jihadBilMaal && (
                                <FormFeedback>
                                  <h4>{errorsFields.jihadBilMaal}</h4>
                                </FormFeedback>
                              )}
                            </FormGroup>
                          </Col>
                          {/* Field for other info */}
                          <Col md="6">
                            <FormGroup>
                              <Label className="col-form-label">
                                <h4
                                  className="ur"
                                  style={{ fontWeight: "lighter" }}
                                >
                                  دیگر تفصیلات
                                </h4>
                              </Label>
                              <Input
                                type="text"
                                name="otherInfo"
                                className={
                                  isUrdu(unitMembers.otherInfo)
                                    ? "ur no-drag"
                                    : "no-drag"
                                }
                                value={unitMembers.otherInfo}
                                onChange={handleInputChange}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </>
                  ) : null}
                  {memberResponsibilities?.length !== 0 ? (
                    <>
                      <div className="ibox-title">
                        <h3 className="text-error">
                          {(CheckPermission(
                            "CanRequestMoveMembersLocalTransfer"
                          ) &&
                            check === "Local") ||
                          (CheckPermission(
                            "CanRequestMoveMembersOutsideTransfer"
                          ) &&
                            check === "Outside") ? (
                            <GetLanguageString props="move_member_note_remove_responsibility_request" />
                          ) : (
                            <GetLanguageString props="move_member_note_remove_responsibility" />
                          )}
                        </h3>
                      </div>
                      <div className="ibox-content">
                        <div>
                          <Table responsive>
                            <thead>
                              <tr>
                                <th></th>
                                <th>#</th>
                                <th className="text-center">
                                  {
                                    <GetLanguageString props="member_member_detail_common_responsibility" />
                                  }
                                </th>
                                <th className="text-center">
                                  {
                                    <GetLanguageString props="dashboard_event_page_unit_name" />
                                  }
                                </th>
                                <th className="text-center">
                                  {
                                    <GetLanguageString props="dashboard_event_page_unit_type" />
                                  }
                                </th>
                                <th className="text-center">
                                  {
                                    <GetLanguageString props="member_member_detail_common_responsibility_date" />
                                  }
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {memberResponsibilities?.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      {" "}
                                      <Input
                                        id={"delete" + index}
                                        type="checkbox"
                                        checked={item.delete}
                                        onChange={(e) =>
                                          handleResponsibilitiesDelete(e, index)
                                        }
                                      />
                                    </td>
                                    <td>{index + 1}</td>
                                    <td className="text-center ur no-drag">
                                      {item.responsibilityName}
                                    </td>
                                    <td className="text-center ur no-drag">
                                      {item.unitName}
                                    </td>
                                    <td className="text-center">
                                      {
                                        <Label
                                          size="xs"
                                          color="info"
                                          className="label label-info m-r-xs no-drag"
                                          onClick={() => {}}
                                        >
                                          <h5 className="ur no-drag">
                                            {item.unitType}
                                          </h5>
                                        </Label>
                                      }
                                    </td>
                                    <td className="text-center no-drag">
                                      {moment(
                                        convertUTCToLocalTime(
                                          item.responsibilityDate
                                        )
                                      ).format("DD/MM/yyyy")}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </>
                  ) : null}
                </>
              ) : (
                LoadingSpinner()
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="white" onClick={toggle}>
                {<GetLanguageString props="common_cancel" />}
              </Button>
              <Button
                color="primary"
                disabled={buttonDisable}
                type="submit"
                onClick={handleSubmit}
              >
                {<GetLanguageString props="common_save_changes" />}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </Draggable>
    </div>
  );
};

export default MoveMemberModal;
