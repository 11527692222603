import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import {
  Row,
  Col,
  Form,
  Label,
  Input,
  FormGroup,
  Button,
  FormFeedback,
} from "reactstrap";
import { withRouter, useParams } from "react-router-dom";
import jwtDecode from "jwt-decode";

import { addMember } from "./ApiMember";
import { GetLanguageString } from "../helper/Components";
import { GetCustomStyles } from "../helper/Method";
import SelectUnit from "./SelectUnit";
import ChangeMemberType from "./ChangeMemberType";
import { LoadingSpinner,DateOfBirthValidater, JoiningDateValidater } from "../../constants/const";
import { requestApi } from "../../actions/api";
import { encryptStorage } from "../../constants/EncryptStorage";

import "react-datepicker/dist/react-datepicker.css";

const EditMember = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const tokenString = encryptStorage.getItem("token");
  const [excludeExUnit, setExcludeExUnit] = useState(true);
  var decodedToken = jwtDecode(tokenString);
  const { id } = useParams();
  const editValue = parseInt(id);
  const [unitId, setUnitId] = useState(0);
  const [modal, setModal] = useState(false);
  const [qualification, setQualification] = useState([]);
  const [memberValues, setMemberValues] = useState({
    birthDate: null,
    cnic: "",
    currentAddress: "",
    fatherName: "",
    firstName: "",
    lastName: "",
    permanentAddress: "",
    phone1: "",
    phone2: "",
    phone3: "",
    education: "",
    regNo: "",
    joiningDate: null,
    memberTypeList: [],
    unitResponsibilityList: [],
    memberTypes: {},
    qualifications: [],
  });
  const [errors, setErrors] = useState(memberValues);
  const [units, setUnits] = useState([]);
  const qualificationOptions = [];
  const [selectedQualifications, setSelectedQualifications] = useState([]);

  useEffect(() => {
    setLoading(true);
    addMember()
      .getMemberById(editValue)
      .then((res) => {
        let unitResponsibilities = [];
        res.data.unitResponsibilityList.forEach((ur) => {
          let tempResponsibilityList = [];

          ur.responsibilityList.length !== 0
            ? ur.responsibilityList.forEach((r) => {
                tempResponsibilityList.push({
                  ...r,
                  responsibilityDate: r.responsibilityDate
                    ? new Date(r.responsibilityDate)
                    : new Date(),
                });
              })
            : tempResponsibilityList.push({
                isSelected: false,
                value: 0,
                label: "",
                responsibilityDate: new Date(),
              });

          unitResponsibilities.push({
            ...ur,
            unitDate: ur.unitDate ? new Date(ur.unitDate) : new Date(),
            responsibilityList: tempResponsibilityList,
          });
          setUnitId(ur.value);
        });
        setMemberValues({
          ...res.data,
          unitResponsibilityList: unitResponsibilities,
          memberTypeChangeDate: null,
          firstName:
            res.data.lastName !== null
              ? res.data.firstName + " " + res.data.lastName
              : res.data.firstName,
          birthDate: res.data.birthDate ? new Date(res.data.birthDate) : null,
          joiningDate: res.data.joiningDate
            ? new Date(res.data.joiningDate)
            : null,
        });
        setUnits(unitResponsibilities);
        setSelectedQualifications(res.data.qualifications);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    requestApi("qualification/")
      .fetchAll()
      .then((res) => {
        setQualification(res.data);
      })
      .catch((err) => console.log(err));
  }, [editValue]);

  qualification.forEach((d) =>
    qualificationOptions.push({ value: d.id, label: d.name })
  );

  const validate = () => {
    let temp = {};
    temp.firstName = memberValues.firstName ? (
      ""
    ) : (
      <GetLanguageString props="member_first_name_error_message" />
    );
    temp.fatherName = memberValues.fatherName ? (
      ""
    ) : (
      <GetLanguageString props="member_father_name_error_message" />
    );
    temp.phone1 = memberValues.phone1 ? (
      /^((\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})$/.test(memberValues.phone1) ? (
        ""
      ) : (
        "Mobile Number is not valid."
      )
    ) : (
      <GetLanguageString props="member_phone_error_message" />
    );
    temp.cnic = memberValues.cnic
      ? /^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$/.test(memberValues.cnic)
        ? ""
        : "CNIC is not valid. Format(00000-0000000-0)"
      : "";
    temp.fatherName = memberValues.fatherName ? (
      ""
    ) : (
      <GetLanguageString props="member_father_name_error_message" />
    );
    temp.regNo = memberValues.regNo ? (
      ""
    ) : (
      <GetLanguageString props="member_reg_number_error_message" />
    );
    temp.currentAddress = memberValues.currentAddress ? (
      ""
    ) : (
      <GetLanguageString props="member_address_error_message" />
    );
    temp.birthDate =
      memberValues.birthDate !== null ? (
        DateOfBirthValidater(memberValues.birthDate)?(
          ""
        ):(
          <GetLanguageString props="member_birth_date_less_error_message" />
        )
      ) : (
        <GetLanguageString props="member_birth_date_error_message" />
      );
    temp.joiningDate =
      memberValues.joiningDate !== null ? (
        JoiningDateValidater(memberValues.joiningDate) ? "" : (
          <GetLanguageString props="member_joining_date_range_error_message" />
        )
      ) : (
        <GetLanguageString props="member_joining_date_error_message" />
      );

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputChangeJoiningDate = (value) => {
    if (value !== null) {
      if (value <= new Date()) {
        // let date = new Date(value.setHours(value.getHours() + 5));
        setMemberValues({
          ...memberValues,
          joiningDate: value,
        });
        setErrors({
          joiningDate: "",
        });
      } else {
        setErrors({
          ...errors,
          joiningDate:
            "The Joining date must not be greater than current date.",
        });
      }
    } else {
      setMemberValues({
        ...memberValues,
        joiningDate: null,
      });
    }
  };

  const handleInputChangeBirthDate = (value) => {
    if (value !== null) {
      if (value <= new Date()) {
        // let date = new Date(value.setHours(value.getHours() + 5));
        setMemberValues({
          ...memberValues,
          birthDate: value,
        });
        setErrors({
          birthDate: "",
        });
      } else {
        setErrors({
          ...errors,
          birthDate: "The Birth date must not be greater than current date.",
        });
      }
    } else {
      setMemberValues({
        ...memberValues,
        birthDate: null,
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMemberValues({
      ...memberValues,
      [name]: value,
    });
    setErrors({
      [name]: "",
    });
  };

  const handleOnChangeMemberType = () => {
    setModal(true);
  };

  const handleChangeQualification = (record) => {
    if (record !== null) {
      setMemberValues({
        ...memberValues,
        qualifications: record,
      });
      setSelectedQualifications(record);
    } else {
      setSelectedQualifications([]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    memberValues.unitResponsibilityList = units;
    if (validate()) {
      if (memberValues.memberTypeChangeDate !== null) {
        setLoading(true);
        requestApi("membertypechangedate/")
          .create(memberValues.memberTypeChangeDate)
          .then((res) => {
            addMember()
              .updateMember(editValue, memberValues)
              .then((res) => {
                setLoading(false);
                history.goBack();
              })
              .catch((err) => {
                setErrors({
                  regNo: err.response.data,
                });
                setLoading(false);
                console.log(err);
              });
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      } else {
        setLoading(true);
        addMember()
          .updateMember(editValue, memberValues)
          .then((res) => {
            history.goBack();
            setLoading(false);
          })
          .catch((err) => {
            setErrors({
              regNo: err.response.data,
            });
            setLoading(false);
            console.log(err);
          });
      }
    }
  };

  const handleExcludeExUnit = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setExcludeExUnit(v);
  };

  return (
    <>
      {modal ? (
        <ChangeMemberType
          setModal={setModal}
          memberId={editValue}
          memberValues={memberValues}
          setMemberValues={setMemberValues}
        />
      ) : null}
      <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
        {!loading ? (
          <Form onSubmit={handleSubmit}>
            <div className="ibox">
              <div className="ibox-title">
                <h3>{<GetLanguageString props="member_edit_member" />}</h3>
              </div>
              <div className="ibox-content">
                <Row>
                  <Col md="3">
                    <FormGroup>
                      <Label className="col-form-label">
                        {<GetLanguageString props="member_first_name" />}
                      </Label>
                      <Input
                        className="ur"
                        autoFocus
                        type="text"
                        name="firstName"
                        value={memberValues.firstName}
                        onChange={handleInputChange}
                        {...(errors.firstName && { invalid: true })}
                      />
                      {errors.firstName && (
                        <FormFeedback>
                          <h4>{errors.firstName}</h4>
                        </FormFeedback>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label">
                        {<GetLanguageString props="member_reg_number" />}
                      </Label>
                      <Input
                        type="text"
                        name="regNo"
                        disabled={!decodedToken.role}
                        value={memberValues.regNo}
                        onChange={handleInputChange}
                        {...(errors.regNo && { invalid: true })}
                      />
                      {errors.regNo && (
                        <FormFeedback>
                          <h4>{errors.regNo}</h4>
                        </FormFeedback>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label className="col-form-label">
                        {<GetLanguageString props="member_father_name" />}
                      </Label>
                      <Input
                        className="ur"
                        type="text"
                        name="fatherName"
                        value={memberValues.fatherName}
                        onChange={handleInputChange}
                        {...(errors.fatherName && { invalid: true })}
                      />
                      {errors.fatherName && (
                        <FormFeedback>
                          <h4>{errors.fatherName}</h4>
                        </FormFeedback>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label">
                        {<GetLanguageString props="member_joining_date" />}
                      </Label>
                      <DatePicker
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="dd/mm/yyyy"
                        className="form-control"
                        disabled={!decodedToken.role}
                        selected={memberValues.joiningDate}
                        onChange={handleInputChangeJoiningDate}
                        dateFormat="dd/MM/yyyy"
                      />
                      {errors.joiningDate && (
                        <h4 className="text-error">{errors.joiningDate}</h4>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label className="col-form-label">
                        {<GetLanguageString props="member_cnic" />}
                      </Label>
                      <Input
                        type="text"
                        name="cnic"
                        value={memberValues.cnic}
                        onChange={handleInputChange}
                        {...(errors.cnic && { invalid: true })}
                      />
                      {errors.cnic && (
                        <FormFeedback>
                          <h4>{errors.cnic}</h4>
                        </FormFeedback>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label">
                        {<GetLanguageString props="member_education" />}
                      </Label>
                      <Input
                        type="text"
                        name="education"
                        value={memberValues.education}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label className="col-form-label">
                        {<GetLanguageString props="member_date_of_birth" />}
                      </Label>
                      <DatePicker
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="dd/mm/yyyy"
                        className="form-control"
                        selected={memberValues.birthDate}
                        onChange={handleInputChangeBirthDate}
                        dateFormat="dd/MM/yyyy"
                      />
                      {errors.birthDate && (
                        <h4 className="text-error">{errors.birthDate}</h4>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label">
                        {<GetLanguageString props="member_phone" />}
                      </Label>
                      <Input
                        type="text"
                        name="phone3"
                        value={memberValues.phone3}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <Label className="col-form-label">
                        {<GetLanguageString props="member_address" />}
                      </Label>
                      <Input
                        className="ur"
                        type="text"
                        name="currentAddress"
                        value={memberValues.currentAddress}
                        onChange={handleInputChange}
                        {...(errors.currentAddress && { invalid: true })}
                      />
                      {errors.currentAddress && (
                        <FormFeedback>
                          <h4>{errors.currentAddress}</h4>
                        </FormFeedback>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label">
                        {<GetLanguageString props="member_permanent_address" />}
                      </Label>
                      <Input
                        className="ur"
                        type="text"
                        name="permanentAddress"
                        value={memberValues.permanentAddress}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label className="col-form-label">
                            {
                              <GetLanguageString props="member_mobile_number_1" />
                            }
                          </Label>
                          <Input
                            type="text"
                            name="phone1"
                            value={memberValues.phone1}
                            onChange={handleInputChange}
                            {...(errors.phone1 && { invalid: true })}
                          />
                          {errors.phone1 && (
                            <FormFeedback>
                              <h4>{errors.phone1}</h4>
                            </FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label className="col-form-label">
                            {
                              <GetLanguageString props="member_mobile_number_2" />
                            }
                          </Label>
                          <Input
                            type="text"
                            name="phone2"
                            value={memberValues.phone2}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <FormGroup>
                        <Row>
                          <Col md="8">
                            <Label className="col-form-label">
                              {
                                <GetLanguageString props="settings_member_type" />
                              }
                            </Label>
                            <Input
                              className="ur"
                              type="text"
                              name="memberTypes"
                              disabled
                              value={memberValues.memberTypes.label}
                            />
                          </Col>
                          <Col md="4" className="m-t-xl">
                            <Button
                              color="primary"
                              size="sm"
                              className={GetCustomStyles().btn_style}
                              onClick={handleOnChangeMemberType}
                            >
                              {
                                <GetLanguageString props="member_edit_member_change_member_type" />
                              }
                            </Button>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="ibox">
              <div className="ibox-title">
                {<GetLanguageString props="member_common_qualiification" />}
              </div>
              <div className="ibox-content">
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label className="col-form-label">
                        {
                          <GetLanguageString props="member_select_qualiification" />
                        }
                      </Label>
                      <Select
                        value={selectedQualifications}
                        options={qualificationOptions}
                        className="basic-multi-select ur"
                        isClearable={true}
                        isMulti
                        classNamePrefix="select"
                        onChange={handleChangeQualification}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="ibox">
              <div className="ibox-title">
                <h3>
                  {
                    <GetLanguageString props="member_select_unit_and_responsibilities" />
                  }
                </h3>
                <Button
                  color="primary"
                  size="sm"
                  className={GetCustomStyles().err_btn_style}
                  onClick={() =>
                    setUnits([
                      ...units,
                      {
                        value: unitId,
                        label: "",
                        unitDate: new Date(),
                        responsibilityList: [
                          {
                            isSelected: false,
                            value: 0,
                            label: "",
                            responsibilityDate: new Date(),
                          },
                        ],
                      },
                    ])
                  }
                >
                  {<GetLanguageString props="unit_add_unit" />}
                </Button>
              </div>
              <div className="ibox-content">
                {units.map((record, index) => {
                  return (
                    <SelectUnit
                      key={index}
                      id={index}
                      unitId={record.value}
                      setValues={setUnits}
                      values={units}
                      excludeExUnit={excludeExUnit}
                    />
                  );
                })}
                <Row>
                  <Col md="4">
                    <FormGroup check>
                      <Label check for="excludeExUnit">
                        <Input
                          id="excludeExUnit"
                          type="checkbox"
                          name="excludeExUnit"
                          checked={!excludeExUnit}
                          value={!excludeExUnit}
                          onChange={handleExcludeExUnit}
                        />
                        {<GetLanguageString props="member_search_member_ex_unit" />}
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <Button color="white" onClick={() => history.goBack()}>
                      {<GetLanguageString props="common_cancel" />}
                    </Button>
                    <Button color="primary" className="m-l-sm" type="submit">
                      {<GetLanguageString props="common_save_changes" />}
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Form>
        ) : (
          LoadingSpinner()
        )}
      </div>
    </>
  );
};

export default withRouter(EditMember);
