import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { GetLanguageString } from "../../helper/Components";
import Select from "react-select";
import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import { addMember, setupApi } from "../ApiMember";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import { withRouter } from "react-router-dom";
import {
  CheckIsAdmin,
  CheckPermission,
  LoadingSpinner,
  isUrdu,
} from "../../../constants/const";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UpdateContactInfo = ({
  history,
  tab4,
  memberId,
  addNewRafiq,
  memberTypeId,
  activeTab,
  setActiveTab,
  discard,
  setDiscard,
}) => {
  const notify = (string) => toast.success(string);
  const selectedLanguage = GetSelectedLanguage();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState();

  useEffect(() => {
    if (tab4 === "active") {
      setLoading(true);
      setupApi()
        .GetCountries()
        .then((res) => {
          setCountries(res.data.countries);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab4]);

  const [contactTypeList, setContactTypeList] = useState();
  const [contactList, setContactList] = useState([
    {
      contactType: {},
      contactInfo: "",
      countriesList: countries,
      statesList: [],
      citiesList: [],
      country: {},
      state: {},
      city: {},
      referenceNote: "",
      isWhatsApp: false,
      deletable: false,
      edited: false,
      isHabibRequest: memberTypeId !== 11 ? false : true,
    },
  ]);

  const [errors, setErrors] = useState({
    contactType: "",
    contactInfo: "",
    country: "",
    state: "",
    city: "",
  });

  const validate = () => {
    let temp = {};
    temp.contactType =
      Object.keys(contactList[0].contactType).length !== 0 ? (
        ""
      ) : (
        <GetLanguageString props="member_member_details_contact_type_error_message" />
      );
    temp.contactInfo = contactList[0].contactInfo ? (
      ""
    ) : (
      <GetLanguageString props="member_member_details_contact_error_message" />
    );

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  useEffect(() => {
    if (tab4 === "active") {
      addMember()
        .getMemberContactsDetail(memberId)
        .then((res) => {
          let contacts = [];
          res.data.contacts.forEach((c) => {
            contacts.push({ ...c, countriesList: countries });
          });

          res.data.contacts.length !== 0
          ? setContactList(contacts)
          : setContactList([
              {
                contactType: {},
                contactInfo: "",
                country: {},
                countriesList: [],
                statesList: [],
                citiesList: [],
                city: {},
                state: {},
                referenceNote: "",
                isWhatsApp: false,
                deletable: false,
                edited: false,
                isHabibRequest: memberTypeId !== 11 ? false : true
              },
            ]);
      })
        .catch((err) => {
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
          console.log(err);
        });
    }
  }, [memberId, selectedLanguage, tab4, countries, memberTypeId]);

  useEffect(() => {
    if (tab4 === "active") {
      setupApi()
        .getContactTypes()
        .then((res) => {
          setContactTypeList(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [tab4, selectedLanguage]);

  const handleOnChangeContactType = (value, id) => {
    let tempValues = [...contactList];
    tempValues[id].contactType = value;
    tempValues[id].edited = true;
    setContactList(tempValues);
  };

  const handleOnChangeCountry = (country, id) => {
    let tempValues = [...contactList];
    tempValues[id].country = country;
    tempValues[id].edited = true;
    setContactList(tempValues);
    setupApi()
      .GetStates(country.value)
      .then((res) => {
        setContactList((prevContactList) => {
          let tempValues = [...prevContactList];
          tempValues[id].statesList = res.data;
          tempValues[id].citiesList = [];
          tempValues[id].state = {};
          tempValues[id].city = {};
          return tempValues;
        });
      })
      .catch((err) => console.log(err));
  };
  const handleOnChangeState = (state, id) => {
    let tempValues = [...contactList];
    tempValues[id].state = state;
    tempValues[id].edited = true;
    setContactList(tempValues);

    setupApi()
      .GetCities(state.value)
      .then((res) => {
        setContactList((prevContactList) => {
          let tempValues = [...prevContactList];
          tempValues[id].citiesList = res.data;
          tempValues[id].city = {};
          return tempValues;
        });
      })
      .catch((err) => console.log(err));
  };

  const handleOnChangeCity = (value, id) => {
    let tempValues = [...contactList];
    tempValues[id].city = value;
    tempValues[id].edited = true;
    setContactList(tempValues);
  };

  const handleContactInfo = (contact, index) => {
    let tempValues = [...contactList];
    tempValues[index].contactInfo = contact.target.value;
    tempValues[index].edited = true;
    setContactList(tempValues);
  };

  const handleReferenceNote = (referenceNote, index) => {
    let tempValues = [...contactList];
    tempValues[index].referenceNote = referenceNote.target.value;
    tempValues[index].edited = true;
    setContactList(tempValues);
  };

  const handleIsWhatsApp = (index) => {
    var v = !contactList[index].isWhatsApp;
    let tempValues = [...contactList];
    tempValues[index].isWhatsApp = v;
    tempValues[index].edited = true;
    setContactList(tempValues);
  };

 const addContact = () => {
  const tempValues = [
    {
      contactType: {},
      countriesList: countries,
      contactInfo: "",
      country: {},
      statesList: [],
      citiesList: [],
      city: {},
      state: {},
      referenceNote: "",
      isWhatsApp: false,
      deletable: false,
      edited: false,
      isHabibRequest: memberTypeId !== 11 ? false : true,
    },
    ...contactList
  ];
  setContactList(tempValues);
};

  const removeObject = (id) => {
    let tempValues = [...contactList];
    tempValues[id].deletable = true;
    tempValues[id].edited = true;
    setContactList(tempValues);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    apiCAll();
  };
  const apiCAll=()=>{
    if (contactList.every((item) => item.edited === false)) {
      swal({
        title: t("member_member_contact_added_success_message"),
        className: GetSelectedLanguage() === "en" ? "" : "ur",
        icon: "success",
        buttons: "OK",
      });
    } else {
      if (validate()) {
        setLoading(true);
        addMember()
          .addMemberContact(
            { memberId: memberId, contacts: contactList },
            addNewRafiq
          )
          .then((res) => {
            if (res.data === "") {
              swal({
                title:
                  memberTypeId !== 11
                    ? t("member_member_contact_added_success_message")
                    : t("member_habib_contact_added_success_message"),
                className: GetSelectedLanguage() === "en" ? "" : "ur",
                icon: "success",
                buttons: "OK",
              }).then((ok) => {
                setDiscard(!discard);
              });
              addMember()
                .getMemberContactsDetail(memberId)
                .then((res) => {
                  let contacts = [];
                  res.data.contacts.forEach((c) => {
                    contacts.push(c);
                  });
                  res.data.contacts.length !== 0
                    ? setContactList(contacts)
                    : setContactList([
                        {
                          contactType: {},
                          contactInfo: "",
                          country: {},
                          city: {},
                          referenceNote: "",
                          isWhatsApp: false,
                          deletable: false,
                          edited: false,
                        },
                      ]);
                });
            } else notify(res.data);

            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            swal({
              title: err,
              icon: "error",
              buttons: "OK",
            }).then((ok)=>{
              setActiveTab("tab4");
            })
            console.log(err);
          });
      }
    }
  }

  const handleCancel = (e) => {
    if (!contactList.every((item) => item.edited === false)) {
      swal({
        title: "Discard changes?",
        text: "Do you want to discard changes or save before canceling?",
        icon: "warning",
        buttons: ["Save Changes", "Discard"],
        closeOnClickOutside: false,
        dangerMode: true,
      }).then((willDiscard) => {
        if (willDiscard) {
          history.goBack(); // Process with cancel
        } else {
          handleSubmit(e); // Call the save function when the user chooses not to discard changes
        }
      }); 
  } else {
    history.goBack(); // No changes, directly go back
  }
};

  useEffect(() => {
    if (tab4 === "active") {
      // Check if the active tab has changed
      if (activeTab && activeTab !== "tab4") {
        // Check if there are unsaved changes
        if (!contactList.every((item) => item.edited === false)) {
          swal({
            title: "Discard changes?",
            text: "Do you want to discard changes or save before canceling for Contact info?",
            icon: "warning",
            buttons: ["Save Changes", "Discard"],
            closeOnClickOutside: false,
            dangerMode: true,
          }).then((willDiscard) => {
            if (willDiscard) {
              setDiscard(!discard);
            } else {
              apiCAll();
            }
          });
        } else {
          setDiscard(!discard);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <div role="tabpanel" id="tab-4" className={"tab-pane " + tab4}>
      <div className="panel-body">
        <div className="ibox">
          {!loading ? (
            <>
              <div className="ibox-title">
                <Row>
                  <Col md="11">
                    <h5>{<GetLanguageString props="member_contact" />}</h5>
                  </Col>
                  <Col md="1" className="text-center">
                    <div>
                      <i
                        className={"fa fa-2x fa-plus-square"}
                        style={{ cursor: "pointer", color: "green" }}
                        onClick={addContact}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="ibox-content">
                <Form onSubmit={handleSubmit}>
                  {contactList?.map((item, index) => {
                    return !item.deletable ? (
                      <>
                        <Row key={index}>
                          <Col md="4">
                            <FormGroup>
                              <Label className="col-form-label">
                                {
                                  <GetLanguageString props="member_contact_type" />
                                }
                              </Label>
                              <Select
                                options={contactTypeList}
                                value={item.contactType}
                                className={
                                  isUrdu(item.contactType.label)
                                    ? "basic-single ur"
                                    : "basic-single"
                                }
                                placeholder={
                                  selectedLanguage === "en"
                                    ? "Select"
                                    : "منتخب کریں"
                                }
                                classNamePrefix="select"
                                onChange={(type) => {
                                  handleOnChangeContactType(type, index);
                                  setErrors({
                                    ...errors,
                                    contactType: "",
                                  });
                                }}
                              />
                              {errors.contactType && (
                                <h4 className="text-error">
                                  {errors.contactType}
                                </h4>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md="2">
                            <FormGroup>
                              <Label className="col-form-label">
                                {<GetLanguageString props="member_country" />}
                              </Label>
                              <Select
                                options={item.countriesList}
                                value={item.country}
                                className="basic-single"
                                placeholder={
                                  selectedLanguage === "en"
                                    ? "Select"
                                    : "منتخب کریں"
                                }
                                classNamePrefix="select"
                                onChange={(country) => {
                                  handleOnChangeCountry(country, index);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="2">
                            {item.statesList?.length === 0 ||
                            item.statesList === null ? (
                              <h3 className="text-center m-5">{" - "}</h3>
                            ) : (
                              <FormGroup>
                                <Label className="col-form-label">
                                  {
                                    <GetLanguageString props="ahbeb_state_province" />
                                  }
                                </Label>
                                <Select
                                  options={item.statesList}
                                  value={item.state}
                                  className="basic-single"
                                  placeholder={
                                    item.statesList?.length === 0 ||
                                    item.statesList === null
                                      ? selectedLanguage === "en"
                                        ? "No States Available"
                                        : "کوئی صوبہ موجود نہی ہے"
                                      : selectedLanguage === "en"
                                      ? "Select"
                                      : "منتخب کریں"
                                  }
                                  classNamePrefix="select"
                                  onChange={(state) => {
                                    handleOnChangeState(state, index);
                                  }}
                                />
                              </FormGroup>
                            )}
                          </Col>
                          <Col md="2">
                            {item.citiesList?.length === 0 ||
                            item.citiesList === null ? (
                              <h3 className="text-center m-5">{" - "}</h3>
                            ) : (
                              <FormGroup>
                                <Label className="col-form-label">
                                  {<GetLanguageString props="member_city" />}
                                </Label>
                                <Select
                                  options={item.citiesList}
                                  value={item.city}
                                  className="basic-single"
                                  placeholder={
                                    item.citiesList?.length === 0 ||
                                    item.citiesList === null
                                      ? selectedLanguage === "en"
                                        ? "No Cities Available"
                                        : "کوئی شہر موجود نہی ہے"
                                      : selectedLanguage === "en"
                                      ? "Select"
                                      : "منتخب کریں"
                                  }
                                  classNamePrefix="select"
                                  onChange={(city) => {
                                    handleOnChangeCity(city, index);
                                  }}
                                />
                              </FormGroup>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col md="4">
                            <FormGroup>
                              <Label className="col-form-label">
                                {
                                  <GetLanguageString props="member_contact_Info" />
                                }
                              </Label>
                              <Input
                                type="text"
                                name="contactInfo"
                                value={item.contactInfo}
                                onChange={(contact) => {
                                  handleContactInfo(contact, index);
                                  setErrors({
                                    ...errors,
                                    contactInfo: "",
                                  });
                                }}
                              />
                              {errors.contactInfo && (
                                <h4 className="text-error">
                                  {errors.contactInfo}
                                </h4>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label className="col-form-label">
                                {
                                  <GetLanguageString props="member_reference_note" />
                                }
                              </Label>
                              <Input
                                className={
                                  GetSelectedLanguage() === "en" ? "" : "ur"
                                }
                                type="text"
                                name="referenceNote"
                                value={item.referenceNote}
                                onChange={(referenceNote) =>
                                  handleReferenceNote(referenceNote, index)
                                }
                              />
                            </FormGroup>
                          </Col>
                          {item.contactType.value === 1 ? (
                            <Col md="3" className="m-t-xl text-center">
                              <FormGroup check>
                                <Label check for={"isWhatsApp" + index}>
                                  <Input
                                    id={"isWhatsApp" + index}
                                    type="checkbox"
                                    checked={contactList[index].isWhatsApp}
                                    onChange={() => handleIsWhatsApp(index)}
                                  />
                                  {
                                    <GetLanguageString props="member_contact_whatsapp" />
                                  }
                                </Label>
                              </FormGroup>
                            </Col>
                          ) : null}
                          <Col md="1" className="text-center">
                            <i
                              className={"fa fa-2x fa-minus-square m-t-xl "}
                              style={{ cursor: "pointer", color: "red" }}
                              onClick={() => removeObject(index)}
                            />
                          </Col>
                        </Row>
                        <br></br>
                        <hr></hr>
                      </>
                    ) : null;
                  })}
                  <Row>
                    <Col md="8"></Col>
                    <Col md="4">
                      <div className={GetCustomStyles().btn_style}>
                        <Button color="white" onClick={handleCancel}>
                          {<GetLanguageString props="common_cancel" />}
                        </Button>
                        <Button
                          color="primary"
                          className="m-l-sm"
                          type="submit"
                        >
                          {<GetLanguageString props="common_save_changes" />}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
                {CheckPermission("CanRequestMemberModificationPersonalInfo") &&
                !CheckIsAdmin() &&
                !addNewRafiq ? (
                  <div>
                    <h4 className="text-error">* Request Modification</h4>
                  </div>
                ) : null}
              </div>
            </>
          ) : (
            LoadingSpinner()
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(UpdateContactInfo);
