import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import Draggable from "react-draggable";
import {
  LoadingSpinner,
  convertToDateString,
  convertUTCToLocalTime,
  isUrdu,
} from "../../../constants/const";
import DatePicker from "react-datepicker";
import { GetLanguageString } from "../../helper/Components";
import { ApiPledges } from "./ApiPledges";
import { GetSelectedLanguage } from "../../helper/Method";
import swal from "sweetalert";

const EditPledge = ({ setEditModal, setReload, reload, id }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [pledgeValues, setPledgeValues] = useState({
    id: id,
    ameerName: "",
    pledgeName: "",
    fromDate: null,
    toDate: null,
  });
  const [selectedDates, setSelectedDates] = useState({
    fromDate: null,
    toDate: null,
  });
  const [errors, setErrors] = useState(pledgeValues);
  const toggle = () => setEditModal(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  const validate = () => {
    let temp = {};
    temp.pledgeName = pledgeValues.pledgeName
      ? ""
      : t("settings_name_error_message");
    temp.fromDate = selectedDates.fromDate
      ? ""
      : t("common_date_error_message");
    temp.toDate =
      selectedDates.toDate < selectedDates.fromDate
        ? t("settings_pledge_from_date_to_date_greater_error")
        : "";
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputChangeFromDate = (value) => {
    if (value !== null) {
      if (value <= new Date()) {
        setSelectedDates({
          ...selectedDates,
          fromDate: value,
        });
        setErrors({
          ...errors,
          fromDate: "",
        });
      } else {
        setErrors({
          ...errors,
          fromDate: t("date_greater_than_current_date_error"),
        });
      }
    } else {
      setSelectedDates({
        ...selectedDates,
        fromDate: new Date(),
      });
    }
  };

  const handleInputChangeToDate = (value) => {
    if (value !== null) {
      if (value <= new Date()) {
        setSelectedDates({
          ...selectedDates,
          toDate: value,
        });
        setErrors({
          ...errors,
          toDate: "",
        });
      } else {
        setErrors({
          ...errors,
          toDate: t("date_greater_than_current_date_error"),
        });
      }
    } else {
      setSelectedDates({
        ...selectedDates,
        toDate: null,
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    ApiPledges()
      .GetUpdatePledge(id)
      .then((res) => {
        setPledgeValues((prevState) => {
          const newPledgeValues = {
            ...prevState,
            pledgeName: res.data.pledgeName || "",
            ameerName: res.data.ameerName || "",
            fromDate: res.data.fromDate
              ? new Date(convertUTCToLocalTime(res.data.fromDate))
              : null,
            toDate: res.data.toDate
              ? new Date(convertUTCToLocalTime(res.data.toDate))
              : null,
          };
          setSelectedDates({
            fromDate: res.data.fromDate
              ? new Date(convertUTCToLocalTime(res.data.fromDate))
              : null,
            toDate: res.data.toDate
              ? new Date(convertUTCToLocalTime(res.data.toDate))
              : null,
          });

          return newPledgeValues;
        });

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPledgeValues({
      ...pledgeValues,
      [name]: value,
    });
    setErrors({
      ...errors,
      pledgeName: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      pledgeValues.fromDate = convertToDateString(selectedDates.fromDate);
      pledgeValues.toDate = convertToDateString(selectedDates.toDate);
      ApiPledges()
        .SaveUpdatePledge(pledgeValues)
        .then((res) => {
          toggle();
          setLoading(false);
          swal({
            title: t("settings_pledge_edit_success"),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "success",
            buttons: "OK",
          });
          setReload(!reload);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
        });
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="member_member_detail_edit_pledge" />}
          </ModalHeader>
          {!loading ? (
            <Form onSubmit={handleSubmit}>
              <ModalBody>
                <FormGroup>
                  <Label>
                    {<GetLanguageString props="report_member_ammer_name" />}
                  </Label>
                  <Input
                    autoFocus
                    type="text"
                    name="ameerName"
                    className="no-drag ur"
                    value={pledgeValues.ameerName}
                    disabled
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    {
                      <GetLanguageString props="member_member_detail_pledges_name" />
                    }
                  </Label>
                  <Input
                    type="text"
                    name="pledgeName"
                    className={
                      isUrdu(pledgeValues.pledgeName) ? "no-drag ur" : "no-drag"
                    }
                    value={pledgeValues.pledgeName}
                    onChange={handleInputChange}
                    {...(errors.pledgeName && { invalid: true })}
                  />
                  {errors.pledgeName && (
                    <h4
                      className={
                        GetSelectedLanguage() === "ur"
                          ? "text-error ur"
                          : "text-error"
                      }
                    >
                      {errors?.pledgeName}
                    </h4>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="col-form-label">
                    {<GetLanguageString props="member_report_from" />}
                  </Label>
                  <DatePicker
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd/mm/yyyy"
                    className="form-control no-drag"
                    selected={selectedDates?.fromDate}
                    onChange={handleInputChangeFromDate}
                    dateFormat="dd/MM/yyyy"
                  />
                  {errors.fromDate && (
                    <h4
                      className={
                        GetSelectedLanguage() === "ur"
                          ? "text-error ur"
                          : "text-error"
                      }
                    >
                      {errors?.fromDate}
                    </h4>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="col-form-label">
                    {<GetLanguageString props="member_report_to" />}
                  </Label>
                  <DatePicker
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd/mm/yyyy"
                    className="form-control no-drag"
                    selected={selectedDates.toDate}
                    maxDate={new Date()}
                    onChange={handleInputChangeToDate}
                    dateFormat="dd/MM/yyyy"
                  />
                  {errors.toDate && (
                    <h4
                      className={
                        GetSelectedLanguage() === "ur"
                          ? "text-error ur"
                          : "text-error"
                      }
                    >
                      {errors?.toDate}
                    </h4>
                  )}
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="white" onClick={toggle}>
                  {<GetLanguageString props="common_cancel" />}
                </Button>
                <Button color="primary" type="submit">
                  {<GetLanguageString props="common_save_changes" />}
                </Button>
              </ModalFooter>
            </Form>
          ) : (
            LoadingSpinner()
          )}
        </Modal>
      </Draggable>
    </div>
  );
};

export default EditPledge;
