import React, { useEffect, useState } from "react";
import moment from "moment";
import { Button, Table, Row } from "reactstrap";

import { GetCustomStyles } from "../../helper/Method";
import { ApiEvent } from "../ApiEvent";
import SearchEventReport from "./SearchEventReport";
import {
  GetLanguageString,
  GetSortingUI,
  NoResult,
  SetReportTitle,
} from "../../helper/Components";
import { ApiUnit } from "../../unit/ApiUnit";
import {
  LoadingSpinner,
  convertUTCToLocalTime,
  isUrdu,
} from "../../../constants/const";
import { encryptStorage } from "../../../constants/EncryptStorage";
import useSorting from "../../shared/useSorting";
import { FcPrint } from "react-icons/fc";

const initialValues = {
  unit: "neutral",
  date: "neutral",
  eventType: "neutral",
};

const EventReport = () => {
  const [loading, setLoading] = useState(false);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [events, setEvents] = useState([]);
  const [reportTitle, setReportTitle] = useState("");
  const [modal, setModal] = useState(false);
  const [searchButton, setSearchButton] = useState(false);

  const currentDate = new Date();

  // Get the year and month of the next month
  const nextMonthYear =
    currentDate.getMonth() === 11
      ? currentDate.getFullYear() + 1
      : currentDate.getFullYear();
  const nextMonth =
    currentDate.getMonth() === 11 ? 0 : currentDate.getMonth() + 1;

  // Create a new date object for the same day of the next month
  const dateOfNextMonth = new Date(
    nextMonthYear,
    nextMonth,
    currentDate.getDate()
  );
  const previousMonthYear =
    currentDate.getMonth() === 0
      ? currentDate.getFullYear() - 1
      : currentDate.getFullYear();
  const previousMonth =
    currentDate.getMonth() === 0 ? 11 : currentDate.getMonth() - 1;

  // Create a new date object for the same day of the previous month
  const dateOfPreviousMonth = new Date(
    previousMonthYear,
    previousMonth,
    currentDate.getDate()
  );

  const [searchEventModel, setSearchEventModel] = useState({
    unit: { value: loggedInUnitId, lable: "" },
    startDate: dateOfPreviousMonth,
    endDate: dateOfNextMonth,
    isSubUnits: true,
    isDeletedUnits: false,
  });
  const { sortObject, handleSorting } = useSorting(initialValues);

  useEffect(() => {
    if (searchEventModel.unit.value === 0) {
      setLoading(true);
      ApiUnit()
        .getUnit(loggedInUnitId, loggedInUnitId)
        .then((res) => {
          let temp = res.data.unitList[0];
          let temp2 = { value: temp.id, lable: temp.name };
          setSearchEventModel({
            unit: temp2,
          });
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  }, [searchEventModel.unit.value, loggedInUnitId]);

  useEffect(() => {
    setLoading(true);
    let tempSortObject = {
      isUnitDescending: sortObject.unit === "descending",
      isUnitAscending: sortObject.unit === "ascending",
      isDateDescending: sortObject.date === "descending",
      isDateAscending: sortObject.date === "ascending",
      isEventTypeAscending: sortObject.eventType === "ascending",
      isEventTypeDescending: sortObject.eventType === "descending",
    };
    searchEventModel.sortModel = tempSortObject;

    ApiEvent()
      .getEventsReport(searchEventModel)
      .then((res) => {
        setEvents(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [searchEventModel, sortObject]);

  useEffect(() => {
    if (reportTitle !== "") window.print();
  }, [reportTitle]);

  const handleReportTitle = () => {
    setModal(true);
  };

  return (
    <>
      {modal ? (
        <SetReportTitle setModal={setModal} setReportTitle={setReportTitle} />
      ) : null}
      <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
        <div className="ibox noprint">
          <div className="ibox-title">
            <h5>{<GetLanguageString props="common_generate_report" />}</h5>
            <Button
              color="default"
              size="sm"
              onClick={handleReportTitle}
              className={GetCustomStyles().err_btn_style}
            >
                <FcPrint size={15}  className={GetCustomStyles().fa_style} />
              {<GetLanguageString props="common_print" />}
            </Button>
          </div>
          <div className="ibox-content">
            <SearchEventReport
              unitId={searchEventModel.unit.value}
              searchEventModel={searchEventModel}
              setSearchEventModel={setSearchEventModel}
              setSearchButton={setSearchButton}
            />
          </div>
        </div>
        {searchButton && (
          <div className="ibox" id="section-to-print">
            <div className="ibox-content ibox-center">
              <Row className="print">
                <div className="text-center">
                  <h3>Title : {reportTitle}</h3>
                </div>
              </Row>
              {!loading ? (
                events.length !== 0 ? (
                  <div className="table-responsive">
                    <Table className="table-stripped table-bordered footable">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th
                            className="cursor-pointer"
                            onClick={() => handleSorting("eventType")}
                          >
                            {
                              <GetLanguageString props="events_report_event_name" />
                            }
                            <GetSortingUI
                              sortObject={sortObject}
                              name="eventType"
                              isReport
                            />
                          </th>
                          <th>
                            {<GetLanguageString props="events_address" />}
                          </th>
                          <th
                            className="cursor-pointer"
                            onClick={() => handleSorting("date")}
                          >
                            <GetLanguageString props="events_date" />
                            <GetSortingUI
                              sortObject={sortObject}
                              name="date"
                              isReport
                            />
                          </th>
                          <th>
                            {" "}
                            {<GetLanguageString props="events_description" />}
                          </th>
                          <th
                            className="cursor-pointer"
                            onClick={() => handleSorting("unit")}
                          >
                            <GetLanguageString props="events_unit" />
                            <GetSortingUI
                              sortObject={sortObject}
                              name="unit"
                              isReport
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {events.map((record, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td className="ur">{record.eventType.label}</td>
                              <td
                                className={isUrdu(record.address) ? "ur" : ""}
                              >
                                {record.address}
                              </td>
                              <td>
                                {moment(
                                  convertUTCToLocalTime(record.date)
                                ).format("DD/MM/yyyy  h:mm a")}
                              </td>
                              <td className="ur">{record.description}</td>
                              <td className="ur">{record.unit.label}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <NoResult />
                )
              ) : (
                LoadingSpinner()
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EventReport;
