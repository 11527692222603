import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";

import { GetLanguageString } from "../../helper/Components";
import { ApiUnit } from "../../unit/ApiUnit";
import { encryptStorage } from "../../../constants/EncryptStorage";

const SearchAhbabFromContactForm = ({ values, setValues, unitId = 0, isDeleted = false }) => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [unitList, setUnitList] = useState([]);
  if (unitId === 0) unitId = loggedInUnitId;
  const unitTypeName = "null";

  useEffect(() => {
    ApiUnit()
      .getUnitList(
        unitId,
        loggedInUnitId,
        isDeleted,
        unitTypeName
      )
      .then((res) => {
        setUnitList(res.data);
      })
      .catch((err) => console.log(err));
  }, [unitId, loggedInUnitId, isDeleted]);

  const handleSubUnits = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setValues({
      ...values,
      isSubUnits: v,
    });
  };
  const handleChangeUnits = (record) => {
    if (record !== null) {
      ApiUnit()
        .getUnitList(
          record.value,
          loggedInUnitId,
          isDeleted,
          unitTypeName
        )
        .then((res) => {
          setUnitList(res.data);
        })
        .catch((err) => console.log(err));

      setValues({
        ...values,
        unit: record,
      });
    }
  };

  return (
    <div>
      {" "}
      <>
        <Row>
          {unitList.map((record, index) => {
            const unitOptions = [];
            record.unitList.forEach((d) =>
              unitOptions.push({ value: d.id, label: d.name })
            );
            let selected = {};
            let unitName = "";
            if (record.parentUnitName !== null)
              unitName = record.parentUnitName;
            if (record.selectedUnit !== null) {
              selected = {
                value: record.selectedUnit.id,
                label: record.selectedUnit.name,
              };
            }

            return (
              <Col xl="2" key={index}>
                <FormGroup>
                  <Label className="col-form-label">
                    {unitName !== "" ? (
                      <GetLanguageString
                        props="member_select_unit_name"
                        value={unitName}
                      />
                    ) : (
                      <GetLanguageString props="member_select_unit" />
                    )}
                  </Label>
                  <Select
                    options={unitOptions}
                    value={selected}
                    className="basic-single ur"
                    classNamePrefix="select"
                    onChange={handleChangeUnits}
                  />
                </FormGroup>
              </Col>
            );
          })}
        </Row>
      </>
      <Row>
        <Col md="2">
          <FormGroup check>
            <Label check for="subUnit">
              <Input
                id="subUnit"
                type="checkbox"
                name="isSubUnits"
                checked={values.isSubUnits}
                value={!values.isSubUnits}
                onChange={handleSubUnits}
              />
              {<GetLanguageString props="member_search_member_sub_unit" />}
            </Label>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(SearchAhbabFromContactForm);
