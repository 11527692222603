import React, { useEffect, useState } from "react";
import { Table, Button } from "reactstrap";

import {
  GetLanguageString,
  NoResult,
  SetReportTitle,
} from "../../../helper/Components";
import { GetCustomStyles, SelectOnlyDate } from "../../../helper/Method";
import { LoadingSpinner } from "../../../../constants/const";
import UnitWiseInfaqSearchReport from "./UnitWiseInfaqSearchReport";
import { ApiFund } from "../../../funds/ApiFund";
import { encryptStorage } from "../../../../constants/EncryptStorage";
import { FcPrint } from "react-icons/fc";

const UnitWiseInfaqReport = () => {
  const [loading, setLoading] = useState(false);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [searchButton, setSearchButton] = useState(false);
  const [cumulativeFund, setCumulativeFund] = useState({
    monthModels: [],
  });
  const [unitFundsDetails, setUnitFundsDetails] = useState([]);
  let tempDate = new Date();
  tempDate.setDate(1);
  const [values, setValues] = useState({
    unit: { value: loggedInUnitId, lable: "" },
    startDate: SelectOnlyDate(tempDate),
    excludeExUnit: true,
    endDate: SelectOnlyDate(new Date()),
  });
  const [reportTitle, setReportTitle] = useState("");
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if(searchButton){
    setLoading(true);
    ApiFund()
      .getUnitWiseFundsDetails(values)
      .then((res) => {
        setCumulativeFund(res.data);
        setUnitFundsDetails(res.data.unitFundsDetails);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    }
  }, [values, searchButton]);

  useEffect(() => {
    if (reportTitle !== "") window.print();
  }, [reportTitle]);

  const handleReportTitle = () => {
    setModal(true);
  };

  return (
    <>
      {modal ? (
        <SetReportTitle setModal={setModal} setReportTitle={setReportTitle} />
      ) : null}
      <div
        className="wrapper-custom wrapper wrapper-content animated fadeInRight"
        id="section-to-print"
      >
        <div className="ibox noprint">
          <div className="ibox-title">
            <h5>{<GetLanguageString props="funds_report_search_fund" />} </h5>
            <Button
               color="default"
               size="sm"
               onClick={handleReportTitle}
               className={GetCustomStyles().err_btn_style}
             >
                <FcPrint size={15}  className={GetCustomStyles().fa_style} />
               {<GetLanguageString props="common_print" />}
             </Button>
          </div>
          <UnitWiseInfaqSearchReport fundsValues={values} setFundsValues={setValues} setSearchButton={setSearchButton} />
        </div>
        {!loading ? (
          <>
           { searchButton ? (
            <div className="ibox ">
                <div className="ibox-title">
                    <h5>
                    {
                        <GetLanguageString props="funds_report_members_funds_detail" />
                    }
                    </h5>
                </div>
                <div className="ibox-content">
                    <>
                    {unitFundsDetails?.length !== 0 ? (
                        <div className="table-responsive">
                        <Table className="table-stripped table-bordered footable">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>
                                {<GetLanguageString props="common_name" />}
                                </th>
                                <th></th>
                                {cumulativeFund.monthModels.map((record1) => {
                                return (
                                    <th className="text-center">
                                    {record1.monthName + " " + record1.year}
                                    </th>
                                );
                                })}
                            </tr>
                            </thead>
                            <tbody>
                            {unitFundsDetails?.map((record, index) => {
                                return (
                                  <>
                                    {
                                      record?.memberTypes?.map((red, indx) => {
                                        return (
                                          <tr key={index + "-" + indx}>
                                            {indx === 0 ? (
                                              <>
                                                <td rowSpan={5} className="text-center pt-7">{index + 1}</td>
                                                <td className="ur text-center pt-7" rowSpan={5}>{record.name}</td>
                                              </>
                                            ): null}
                                            <td className="ur">{red?.memberType}</td> 
                                            {red?.monthsStatus.map((record1) => {
                                            return (
                                                <td className="text-center">{
                                                  record1.totalUnitMemberCount !== 0 && record1.unitMemberCount !== 0 ?
                                                  (
                                                    <span>
                                                        {record1.unitMemberCount} / {record1.totalUnitMemberCount} ( {((record1.unitMemberCount / record1.totalUnitMemberCount) * 100).toFixed(2)} %)
                                                    </span>
                                                  )
                                                  : (0).toFixed(1)
                                                } 
                                                </td>
                                            );
                                            })}
                                          </tr>
                                        )
                                      })
                                    }
                                </>
                                );
                            })}
                            </tbody>
                        </Table>
                        </div>
                    ) : (
                        <NoResult />
                    )}
                    </>
                </div>
            </div>
           ): null}
          </>
        ) : (
          LoadingSpinner()
        )}
      </div>
    </>
  );
};

export default UnitWiseInfaqReport;
