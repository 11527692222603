import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import swal from "sweetalert";
import { LoadingSpinner, isUrdu } from "../../constants/const";
import { GetLanguageString } from "../helper/Components";
import { GetSelectedLanguage } from "../helper/Method";
import { ApiDocuments } from "./ApiDocuments";
import { ApiDocumentType } from "../config/documentType/ApiDocumentType";
import Permissions from "./PermissionsComponent";
import { FaSquareMinus, FaSquarePlus } from "react-icons/fa6";
import CreateableTags from "./CreateableTags";

const EditFile = ({ setEditFileModal, parentFolderId, setRefresh, fileId }) => {
  const toggle = () => {
    setEditFileModal(false);
  };
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [documentsTypesDropdown, setDocumentsTypesDropdown] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [filePermissions, setFilePermissions] = useState(false);
  const [uploadFileValues, setUploadFileValues] = useState({
    documentType: "",
    description: "",
    parentFolderId: parentFolderId,
  });

  const [permissions, setPermissions] = useState([
    {
      unit: { value: 0, label: "" },
      forChildUnits: false,
      responsibilities: [],
      selectUnit: false,
      selectResponsibilities: false,
    },
  ]);

  const addPermission = () => {
    const tempValues = [
      {
        unit: { value: 0, label: "" },
        forChildUnits: false,
        responsibilities: [],
        selectUnit: true,
        selectResponsibilities: false,
      },
      ...permissions,
    ];
    setPermissions(tempValues);
  };

  const removeObject = (id) => {
    let tempValues = [...permissions];
    tempValues.splice(id, 1);
    setPermissions(tempValues);
  };

  const handleFolderPermissions = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;

    setFilePermissions(v);

    setUploadFileValues({
      ...uploadFileValues,
      permissionsApplied: v,
    });
  };

  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  const handleChangeDocumentType = (record) => {
    setUploadFileValues({
      ...uploadFileValues,
      documentType: record,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUploadFileValues({
      ...uploadFileValues,
      [name]: value,
    });
  };

  useEffect(() => {
    setLoading(true);
    ApiDocumentType()
      .getDocumentTypesForDropDowns()
      .then((res) => {
        setDocumentsTypesDropdown(res.data);
        setLoading(false);
      })
      .catch((err) => {
        swal({
          title: err.response?.data || err,
          icon: "error",
          buttons: "OK",
        });
        setLoading(false);
        console.log(err.response?.data || err);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    ApiDocuments()
      .editFile(fileId)
      .then((res) => {
        setUploadFileValues({
          id: res.data.id,
          description: res.data.description,
          documentType: res.data.documentType,
          parentFolderId: parentFolderId,
          driveFileId: res.data.driveFileId,
          tags: res.data.tags,
        });
        setSelectedTags(res.data.tags);
        setFilePermissions(res.data.permissionsApplied);
        if (res.data.permissionsApplied === true) {
          setPermissions(res.data.permissions);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
      });
  }, [parentFolderId, fileId]);

  const handleSubmit = () => {
    setLoading(true);
    uploadFileValues.permissionsApplied = filePermissions;
    uploadFileValues.permissions = permissions;
    uploadFileValues.tags = selectedTags;
    ApiDocuments()
      .updateFile(uploadFileValues)
      .then((res) => {
        swal({
          title: t("document_edit_file_success"),
          className: GetSelectedLanguage() === "en" ? "" : "ur",
          icon: "success",
          buttons: "OK",
        });
        toggle();
        setRefresh((prevState) => !prevState);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
      });
  };

  return (
    <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
      <Modal
        size={filePermissions ? "lg" : "m"}
        style={{
          maxWidth: filePermissions ? "1080px" : "500px",
          width: "100%",
        }}
        isOpen
        className="inmodal"
        autoFocus={false}
      >
        <ModalHeader className="modal-title" tag="h4">
          {<GetLanguageString props="document_edit_file" />}
        </ModalHeader>
        {!loading ? (
          <Form>
            <ModalBody>
              <Form>
                <Row>
                  <Col md={filePermissions ? "4" : "12"}>
                    <FormGroup>
                      <Label className="col-form-label">
                        {" "}
                        {
                          <GetLanguageString props="member_member_detail_select_documents_type" />
                        }
                      </Label>
                      <Select
                        value={uploadFileValues.documentType}
                        options={documentsTypesDropdown}
                        className={
                          GetSelectedLanguage() === "ur"
                            ? "basic-multi-select ur no-drag"
                            : "basic-multi-select no-drag"
                        }
                        classNamePrefix="select"
                        onChange={(record) => {
                          handleChangeDocumentType(record);
                        }}
                      />
                    </FormGroup>
                  </Col>{" "}
                  <Col md={filePermissions ? "4" : "12"}>
                    <FormGroup>
                      <Label for="tag-selector">
                        {<GetLanguageString props="document_document_tags" />}
                      </Label>
                      <CreateableTags
                        selectedTags={selectedTags}
                        setSelectedTags={setSelectedTags}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={filePermissions ? "4" : "12"}>
                    <FormGroup>
                      <Label for="description">
                        <GetLanguageString props={"common_description"} />
                      </Label>
                      <Input
                        className={
                          isUrdu(uploadFileValues.description)
                            ? "ur no-drag"
                            : "no-drag"
                        }
                        type="text"
                        name="description"
                        value={uploadFileValues.description}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>{" "}
                <Row>
                  <FormGroup>
                    <Label check for={"filePermissions"}>
                      <Input
                        id={"filePermissions"}
                        type="checkbox"
                        name={"filePermissions"}
                        checked={filePermissions}
                        value={!filePermissions}
                        onChange={handleFolderPermissions}
                      />{" "}
                      {
                        <GetLanguageString props="folder_document_apply_permissions" />
                      }
                    </Label>
                  </FormGroup>
                </Row>
                {filePermissions && (
                  <div className="ibox-content">
                    <Row>
                      <Col md="11"></Col>
                      <Col md="1" className="text-center">
                        <div>
                          <FaSquarePlus
                            color="green"
                            size={25}
                            style={{ cursor: "pointer" }}
                            onClick={addPermission}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {permissions?.map((item, index) => {
                        return (
                          <>
                            <div>
                              <br></br>
                              <Permissions
                                item={item}
                                index={index}
                                permissions={permissions}
                                setPermissions={setPermissions}
                              />
                              <br></br>
                            </div>
                            {permissions.length !== 1 && (
                              <Row>
                                <Col md="11"></Col>
                                <Col md="1" className="text-center">
                                  <div>
                                    <FaSquareMinus
                                      color="red"
                                      size={25}
                                      style={{ cursor: "pointer" }}
                                      onClick={() => removeObject(index)}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            )}
                            <div>
                              {index + 1 !== permissions.length && (
                                <hr
                                  style={{
                                    borderTop: "1px dashed #000",
                                    width: "50%",
                                    margin: "0 auto",
                                  }}
                                />
                              )}
                              <br></br>
                            </div>
                          </>
                        );
                      })}
                    </Row>
                  </div>
                )}
              </Form>
            </ModalBody>
          </Form>
        ) : (
          LoadingSpinner()
        )}
        <ModalFooter>
          <Button color="white" onClick={toggle}>
            {<GetLanguageString props="common_cancel" />}
          </Button>
          <Button color="primary" onClick={handleSubmit}>
            {<GetLanguageString props="common_save_changes" />}
          </Button>
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};

export default EditFile;
