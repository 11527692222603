import React, { useEffect, useState } from "react";
import { Table, Button } from "reactstrap";

import { ApiFund } from "../ApiFund";
import SearchFunds from "./SearchReport";
import {
  GetLanguageString,
  NoResult,
  SetReportTitle,
} from "../../helper/Components";
import {
  GetCustomStyles,
  GetSelectedLanguage,
  SelectOnlyDate,
} from "../../helper/Method";
import { CheckPermission, LoadingSpinner } from "../../../constants/const";
import { encryptStorage } from "../../../constants/EncryptStorage";
import { FcPrint } from "react-icons/fc";
import { RxCross2 } from "react-icons/rx";

const FundsReport = () => {
  const showAmount = CheckPermission("CanViewInfaqAmount");
  const [loading, setLoading] = useState(false);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [searchButton, setSearchButton] = useState(false);
  const [cumulativeFund, setCumulativeFund] = useState({
    totalMembers: 0,
    monthModels: [],
  });
  const [memberFundsDetails, setMemberFundsDetails] = useState([]);
  let tempDate = new Date();
  tempDate.setDate(1);
  const [values, setValues] = useState({
    unit: { value: loggedInUnitId, lable: "" },
    isSubUnits: false,
    displayMemberDetails: true,
    startDate: SelectOnlyDate(tempDate),
    excludeExUnit: true,
    endDate: SelectOnlyDate(new Date()),
    showAmount: false,
    memberTypeIds: [],
  });
  const [reportTitle, setReportTitle] = useState("");
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (searchButton) {
      setLoading(true);
      ApiFund()
        .getMembersFundsDetails(values)
        .then((res) => {
          setCumulativeFund(res.data);
          setMemberFundsDetails(res.data.memberFundsDetails);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [values, searchButton]);

  useEffect(() => {
    if (reportTitle !== "") window.print();
  }, [reportTitle]);

  const handleReportTitle = () => {
    setModal(true);
  };

  const labels = [];
  cumulativeFund.monthModels.map((record) =>
    labels.push(record.monthName + " " + record.year)
  );

  return (
    <>
      {modal ? (
        <SetReportTitle setModal={setModal} setReportTitle={setReportTitle} />
      ) : null}
      <div
        className="wrapper-custom wrapper wrapper-content animated fadeInRight"
        id="section-to-print"
      >
        <div className="ibox noprint">
          <div className="ibox-title">
            <h5>{<GetLanguageString props="funds_report_search_fund" />} </h5>
          </div>
          <SearchFunds
            fundsValues={values}
            setFundsValues={setValues}
            setSearchButton={setSearchButton}
            showAmount={showAmount}
          />
        </div>
        {!loading ? (
          <>
            {values.displayMemberDetails ? (
              <div className="ibox ">
                <div className="ibox-title">
                  <h5>
                    {
                      <GetLanguageString props="funds_report_members_funds_detail" />
                    }
                  </h5>
                  {cumulativeFund.totalMembers !== 0 ? (
                    <Button
                      color="default"
                      size="sm"
                      onClick={handleReportTitle}
                      className={GetCustomStyles().err_btn_style}
                    >
                      <FcPrint
                        size={15}
                        className={GetCustomStyles().fa_style}
                      />
                      {<GetLanguageString props="common_print" />}
                    </Button>
                  ) : null}
                </div>
                <div className="ibox-content">
                  <>
                    {memberFundsDetails?.length !== 0 ? (
                      <div className="table-responsive">
                        <Table className="table-stripped table-bordered footable">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>
                                {<GetLanguageString props="common_name" />}
                              </th>
                              <th>
                                {
                                  <GetLanguageString props="member_reg_number" />
                                }
                              </th>
                              <th>
                                {
                                  <GetLanguageString props="settings_member_type" />
                                }
                              </th>
                              <th>
                                {<GetLanguageString props="unit_unit_table" />}
                              </th>
                              {cumulativeFund.monthModels.map((record1) => {
                                return (
                                  <th className="text-center">
                                    {record1.monthName + " " + record1.year}
                                  </th>
                                );
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {memberFundsDetails?.map((record, index) => {
                              let unitName = record.unitName?.split("/");
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td className="ur">{record.name}</td>
                                  <td>{record.regNo}</td>
                                  <td>
                                    {
                                      <Button
                                        size="xs"
                                        color="info"
                                        className="label label-info m-r-xs"
                                      >
                                        <h5 className="ur">
                                          {record.memberType}
                                        </h5>
                                      </Button>
                                    }
                                  </td>
                                  <td className="ur">
                                    <ol className="breadcrumb">
                                      {unitName?.map((item, index) => {
                                        return (
                                          <li
                                            className={
                                              GetSelectedLanguage() === "ur"
                                                ? "breadcrumb-item-ur"
                                                : "breadcrumb-item"
                                            }
                                            key={index}
                                          >
                                            <span>{item}</span>
                                          </li>
                                        );
                                      })}
                                    </ol>
                                  </td>
                                  {record.monthsStatus.map((record1) => {
                                    return (
                                      <td className="text-center">
                                        {record1.status ? (
                                          showAmount && values.showAmount ? (
                                            <ol className="breadcrumb text-center">
                                              <li
                                                className={
                                                  GetSelectedLanguage() === "ur"
                                                    ? "breadcrumb-item-ur"
                                                    : "breadcrumb-item"
                                                }
                                              >
                                                <span>
                                                  RS {record1.infaqAmount}
                                                </span>
                                              </li>
                                              <li
                                                className={
                                                  GetSelectedLanguage() === "ur"
                                                    ? "breadcrumb-item-ur"
                                                    : "breadcrumb-item"
                                                }
                                              >
                                                <span>
                                                  RCPT-{record1.receiptNumber}
                                                </span>
                                              </li>
                                            </ol>
                                          ) : (
                                            <span className="text-center">
                                              RCPT-{record1.receiptNumber}
                                            </span>
                                          )
                                        ) : (
                                          <RxCross2
                                            size={18}
                                            color="red"
                                            style={{
                                              marginRight: 3,
                                              marginBottom: 1,
                                            }}
                                          />
                                        )}
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    ) : (
                      <NoResult />
                    )}
                  </>
                </div>
              </div>
            ) : null}
          </>
        ) : (
          LoadingSpinner()
        )}
      </div>
    </>
  );
};

export default FundsReport;
