import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Button,
  ButtonGroup,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { GetLanguageString } from "../helper/Components";
import { ApiCourse } from "./ApiCourse";
import Draggable from "react-draggable";

const EditParticipant = ({
  editValue,
  setRenderParticipants,
  setEditModal,
  renderParticipants,
}) => {
  const [values, setValues] = useState({
    comments: editValue.comments,
    status: editValue.status,
    id: editValue.id,
  });
  const { t } = useTranslation();
  const [completeColor, setCompleteColor] = useState(
    values.status ? "primary" : "white"
  );
  const [inCompleteColor, setInCompleteColor] = useState(
    values.status ? "white" : "primary"
  );
  const toggle = () => setEditModal(false);

  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  const handleOnChangeComment = (e) => {
    const { value } = e.target;
    setValues({
      ...values,
      comments: value,
    });
  };
  const handleOnClick = (e) => {
    const { value } = e.target;
    if (value === "1") {
      setValues({
        ...values,
        status: true,
      });
      setCompleteColor("primary");
      setInCompleteColor("white");
    } else if (value === "0") {
      setValues({
        ...values,
        status: false,
      });
      setInCompleteColor("primary");
      setCompleteColor("white");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    ApiCourse()
      .updateCourseParticipant(values)
      .then((res) => {
        setRenderParticipants(!renderParticipants);
        setEditModal(false);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal
          isOpen={true}
          className="inmodal"
          autoFocus={false}
          animation={false}
        >
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="course_edit_participant" />}
          </ModalHeader>
          <ModalBody>
            <Label>
              {<GetLanguageString props="member_member_detail_course_comments" />}
            </Label>
            <Input
              className="ur no-drag"
              autoFocus
              type="text"
              placeholder={t("courses_participants_comments")}
              onChange={handleOnChangeComment}
              value={values.comments}
            />
            <br></br>
            <Label>
              {<GetLanguageString props="member_member_detail_course_status" />}
            </Label>
            <br></br>
            <br></br>
            <ButtonGroup>
              <Button
                color={completeColor}
                size="s"
                onClick={handleOnClick}
                value="1"
              >
                {<GetLanguageString props="courses_status_completed" />}
              </Button>
              <Button
                color={inCompleteColor}
                size="s"
                onClick={handleOnClick}
                value="0"
              >
                {<GetLanguageString props="courses_status_incompleted" />}
              </Button>
            </ButtonGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="white" onClick={toggle}>
              {<GetLanguageString props="common_cancel" />}
            </Button>
            <Button color="primary" onClick={handleSubmit}>
              {<GetLanguageString props="common_save_changes" />}
            </Button>
          </ModalFooter>
        </Modal>
      </Draggable>  
    </div>
  );
};

export default EditParticipant;
