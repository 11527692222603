import React, { useEffect, useState } from "react";
import {
  Button,
  Label,
  Row,
  Col,
  FormGroup,
  Input,
} from "reactstrap";
import Select from "react-select";

import SelectUnit from "../../shared/SelectUnit";
import { ApiUnit } from "../../unit/ApiUnit";
import { GetLanguageString } from "../../helper/Components";
import { encryptStorage } from "../../../constants/EncryptStorage";
import FundFilter from "../../helper/SearchFilter/FundFilter";
import { requestApi } from "../../../actions/api";

const SearchFunds = ({ fundsValues, setFundsValues, setSearchButton, showAmount }) => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [values, setValues] = useState(fundsValues);
  const excludeExUnit =  fundsValues?.excludeExUnit;

  useEffect(() => {
    if (values.unit.value === 0) {
      ApiUnit()
        .getUnit(loggedInUnitId, loggedInUnitId, excludeExUnit)
        .then((res) => {
          let temp = res.data.unitList[0];
          let temp2 = { value: temp.id, lable: temp.name };
          setValues({
            ...values,
            unit: temp2,
          });
        })
        .catch((err) => console.log(err));
    }
  // eslint-disable-next-line
  }, [loggedInUnitId, excludeExUnit])

  const handleSubUnits = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setValues({
      ...values,
      isSubUnits: v,
    });
  };

  const handleCheckBox = (e) => {
    const { name, value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setValues({
      ...values,
      [name]: v,
    });
  };

  const handleSearch = () => {
    setFundsValues(values);
    setSearchButton(true);
  };

  const [memberTypeList, setMemberTypeList] = useState([]);
  const memberTypeOptions = [];
  useEffect(() => {
    requestApi("membertype/")
      .fetchAll()
      .then((res) => {
        setMemberTypeList(res.data);
      })
      .catch((err) => console.log(err));
  }, [])
  memberTypeList.forEach((d) =>
    memberTypeOptions.push({ value: d.id, label: d.type })
  );

  const handleChangeMemberTypes = (record) => {
    if (record !== null) {
      let ids = [];
      record.forEach((r) => ids.push(r.value));
      setValues({
        ...values,
        memberTypeIds: ids,
      });
    } else {
      setValues({
        ...values,
        memberTypeIds: 0,
      });
    }
  };

  return (
    <div className="ibox-content">
       <FundFilter values={values} setValues={setValues} />
       <Row>
       <Col md="4">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="member_select_member_type" />}
            </Label>
            <Select
              options={memberTypeOptions}
              className="basic-multi-select ur"
              classNamePrefix="select"
              isMulti
              isClearable
              onChange={handleChangeMemberTypes}
            />
          </FormGroup>
        </Col>
       </Row>
      <SelectUnit
        values={values}
        setValues={setValues}
        unitId={values.unit.value}
        excludeExUnit={excludeExUnit}
      />
      <Row>
        <Col md="2">
          <FormGroup check>
            <Label check for="subUnit">
              <Input
                id="subUnit"
                type="checkbox"
                name="isSubUnits"
                checked={values.isSubUnits}
                value={!values.isSubUnits}
                onChange={handleSubUnits}
              />
              {<GetLanguageString props="member_search_member_sub_unit" />}
            </Label>
          </FormGroup>
        </Col>
        {showAmount ? (
          <Col md="2">
            <FormGroup check>
              <Label check for="showAmount">
                <Input
                  id="showAmount"
                  type="checkbox"
                  name="showAmount"
                  value={!values.showAmount}
                  onChange={handleCheckBox}
                />
                {
                  <GetLanguageString props="funds_report_show_amount" />
                }
              </Label>
            </FormGroup>
          </Col>
        ) : null}
      </Row>
      <Row>
        <Col md="12" className="text-center m-t-sm">
          <Button color="primary" type="submit" onClick={handleSearch}>
            {<GetLanguageString props="funds_submit" />}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default SearchFunds;
