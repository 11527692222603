import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";

import Draggable from "react-draggable";
import swal from "sweetalert";
import { LoadingSpinner } from "../../../constants/const";
import { GetLanguageString, NoResult } from "../../helper/Components";
import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import AddLetterForCounsellingQuestion from "./AddLetterForCounsellingQuestion";
import { ApiLetterForCounselling } from "./ApiLetterForCounselling";
import { useTranslation } from "react-i18next";
import EditLetterForCounsellingQuestion from "./EditLetterForCounsellingQuestion";
import { IoAdd } from "react-icons/io5";

const LetterForCounsellingQuestions = ({
  setQuestiionsModal,
  lfcId,
  lfcTitle,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [editValue, setEditValue] = useState();
  const [questionsList, setQuestionsList] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const toggle = () => setQuestiionsModal(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    setLoading(true);
    ApiLetterForCounselling()
      .getLetterForCounsellingQuestions(lfcId)
      .then((res) => {
        setQuestionsList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
      });
  }, [lfcId, reload]);

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  const handleOnEdit = (e) => {
    const { value } = e.target;
    setEditValue(value);
    setEditModal(true);
  };

  const handleOnDelete = (value, isAffiliated) => {
    if (isAffiliated) {
      swal({
        title: t("common_is_affiliated"),
        text: t("common_is_affiliated_message"),
        className: GetSelectedLanguage() === "en" ? "" : "ur",
        icon: "warning",
      });
    } else {
      swal({
        title: t("common_delete_message"),
        text: t("common_delete_detail_message"),
        icon: "warning",
        className: GetSelectedLanguage() === "en" ? "" : "ur",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setLoading(true);
          ApiLetterForCounselling()
            .deleteLetterForCounsellingQuestion(value)
            .then((res) => {
              swal({
                title: t(
                  "settings_letter_for_counselling_question_delete_success"
                ),
                className: GetSelectedLanguage() === "en" ? "" : "ur",
                icon: "success",
                buttons: "OK",
              });
              setLoading(false);
              setReload(!reload);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        }
      });
    }
  };

  return (
    <div>
      {addModal ? (
        <AddLetterForCounsellingQuestion
          setAddModal={setAddModal}
          reload={reload}
          setReload={setReload}
          lfcId={lfcId}
          lfcTitle={lfcTitle}
        />
      ) : null}{" "}
      {editModal ? (
        <EditLetterForCounsellingQuestion
          setEditModal={setEditModal}
          reload={reload}
          setReload={setReload}
          lfcId={lfcId}
          editValue={editValue}
          lfcTitle={lfcTitle}
        />
      ) : null}
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal
          isOpen={true}
          size="lg"
          style={{ maxWidth: "1080px", width: "100%" }}
          className="inmodal"
          autoFocus={false}
        >
          <ModalHeader className="modal-title" tag="h4">
            {
              <GetLanguageString props="settings_letter_for_counselling_questions" />
            }
          </ModalHeader>
          {!loading ? (
            <>
              <ModalBody>
                <div className="ibox">
                  <div className="ibox-title">
                    <h5>
                      {
                        <GetLanguageString props="settings_letter_for_counselling_questions" />
                      }
                    </h5>
                    <Button
                      color="white"
                      size="sm"
                      className={GetCustomStyles().btn_style}
                      onClick={() => {
                        setAddModal(true);
                      }}
                    >
                      <IoAdd
                        size={18}
                        color="#18A689"
                        style={{ marginRight: 3, marginBottom: 1 }}
                      />
                      <GetLanguageString props="settings_letter_for_counselling_question_add_question" />
                    </Button>
                  </div>
                  <div className="ibox-content">
                    {questionsList.length !== 0 ? (
                      <div className="table-responsive">
                        <Table responsive>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th className="text-center">
                                {
                                  <GetLanguageString props="settings_letter_for_counselling_question" />
                                }
                              </th>
                              <th className="text-center">
                                {<GetLanguageString props="unit_sort_order" />}
                              </th>
                              <th className="text-center">
                                {
                                  <GetLanguageString props="settings_letter_for_counselling_question_question_number" />
                                }
                              </th>
                              <th className="text-center">
                                {
                                  <GetLanguageString props="settings_letter_for_counselling_question_tmp_question_code" />
                                }
                              </th>
                              <th className="text-center">
                                {
                                  <GetLanguageString props="settings_letter_for_counselling_question_default_rating" />
                                }
                              </th>
                              <th className="text-center">
                                {
                                  <GetLanguageString props="settings_letter_for_counselling_archive" />
                                }
                              </th>
                              <th className="text-center">
                                <GetLanguageString props="member_address_enabled" />
                              </th>
                              <th className="text-center">
                                {<GetLanguageString props="common_action" />}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {questionsList.map((record, index) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td className="ur text-center">
                                    {record.question}
                                  </td>
                                  <td className="text-center">
                                    {record.sortOrder ? record.sortOrder : "-"}
                                  </td>
                                  <td className="text-center">
                                    {record.questionNumber
                                      ? record.questionNumber
                                      : "-"}
                                  </td>
                                  <td className="text-center">
                                    {record.tmpQuestionCode
                                      ? record.tmpQuestionCode
                                      : "-"}
                                  </td>
                                  <td className="text-center">
                                    {record.defaultRating
                                      ? record.defaultRating
                                      : "-"}
                                  </td>
                                  <td className="text-center">
                                    {record.isArchived ? (
                                      <GetLanguageString props="Yes" />
                                    ) : (
                                      <GetLanguageString props="No" />
                                    )}
                                  </td>
                                  <td className="text-center">
                                    {record.isEnabled ? (
                                      <GetLanguageString props="Yes" />
                                    ) : (
                                      <GetLanguageString props="No" />
                                    )}
                                  </td>{" "}
                                  <td className="text-center">
                                    <ButtonGroup>
                                      <Button
                                        color="white"
                                        size="sm"
                                        onClick={handleOnEdit}
                                        value={record.id}
                                      >
                                        {
                                          <GetLanguageString props="common_edit" />
                                        }
                                      </Button>
                                      <Button
                                        color="white"
                                        size="sm"
                                        onClick={() =>
                                          handleOnDelete(
                                            record.id,
                                            record.isAffiliated
                                          )
                                        }
                                      >
                                        {
                                          <GetLanguageString props="common_delete" />
                                        }
                                      </Button>
                                    </ButtonGroup>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    ) : (
                      <NoResult />
                    )}
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="white" onClick={toggle}>
                  {<GetLanguageString props="common_cancel" />}
                </Button>
              </ModalFooter>
            </>
          ) : (
            LoadingSpinner()
          )}
        </Modal>
      </Draggable>
    </div>
  );
};

export default LetterForCounsellingQuestions;
