import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Row, Col, FormGroup, Label, NavLink } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";

import { GetLanguageString } from "../helper/Components";
import { ApiUnit } from "../unit/ApiUnit";
import SelectResponsibility from "./SelectResponsibility";
import { encryptStorage } from "../../constants/EncryptStorage";

const SelectUnit = ({ setValues, values, unitId, id, errors, setErrors, loading, excludeExUnit }) => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [unitList, setUnitList] = useState([]);
  const [tab1, setTab1] = useState("active");
  const [tab2, setTab2] = useState("");
  const unitTypeName = "null";

  useEffect(() => {
    ApiUnit()
      .getUnitList(unitId, loggedInUnitId, false, unitTypeName, excludeExUnit)
      .then((res) => {
        setUnitList(res.data);
      })
      .catch((err) => console.log(err));
  }, [unitId, loggedInUnitId, excludeExUnit]);

  const handleChangeUnits = (record) => {
    if (record !== null) {
      ApiUnit()
        .getUnitList(record.value, loggedInUnitId, false, unitTypeName, excludeExUnit)
        .then((res) => {
          setUnitList(res.data);
        })
        .catch((err) => console.log(err));

      let tempValues = [...values];
      tempValues[id].value = record.value;
      tempValues[id].label = record.label;
      setValues(tempValues);
    }
  };

  const removeObject = () => {
    let tempValues = [...values];
    tempValues[id].isDisabled = true;

    tempValues[id].responsibilityList.map((record, index) => {
      return record.isDisabled = true;
    })
    setValues(tempValues);
  };

  const AddResponsibility = () => {
    let tempValues = [...values];
    tempValues[id].responsibilityList.push({
      id: 0,
      value: 0,
      label: "",
      isSelected: false,
      isDisabled: false,
      responsibilityDate: new Date(),
    });
    setValues(tempValues);
  };

  return (
    <>
      <Row>
        <div className="col-md-12">
          <div className="tabs-container">
            {id >= 1 ? (
              <div>
                <i
                  className={"fa fa-2x fa-minus-square m-b-xs m-l-xs"}
                  style={{ cursor: "pointer", color: "red" }}
                  onClick={removeObject}
                />
              </div>
            ) : null}
            <ul className="nav nav-tabs" role="tablist">
              <li>
                <NavLink
                  to="#"
                  className={"nav-link " + tab1}
                  onClick={() => {
                    setTab1("active");
                    setTab2("");
                  }}
                >
                  {<GetLanguageString props="member_select_unit" />}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="#"
                  className={"nav-link " + tab2}
                  onClick={() => {
                    setTab1("");
                    setTab2("active");
                  }}
                >
                  {<GetLanguageString props="member_select_responsibility" />}
                </NavLink>
              </li>
            </ul>
            <div className="tab-content">
              <div role="tabpanel" id="tab-1" className={"tab-pane " + tab1}>
                <div className="panel-body">
                  <Row>
                    {unitList.map((record, index) => {
                      const unitOptions = [];
                      record.unitList.forEach((d) =>
                        unitOptions.push({ value: d.id, label: d.name })
                      );
                      let selected = {};
                      let unitName = "";
                      if (record.parentUnitName !== null)
                        unitName = record.parentUnitName;
                      if (record.selectedUnit !== null) {
                        selected = {
                          value: record.selectedUnit.id,
                          label: record.selectedUnit.name,
                        };
                      }

                      return (
                        <Col md="2" key={index}>
                          <FormGroup>
                            <Label className="col-form-label">
                              {unitName !== "" ? (
                                <GetLanguageString
                                  props="member_select_unit_name"
                                  value={unitName}
                                />
                              ) : (
                                <GetLanguageString props="member_select_unit" />
                              )}
                            </Label>
                            <Select
                              options={unitOptions}
                              value={selected}
                              className="basic-single ur"
                              classNamePrefix="select"
                              onChange={handleChangeUnits}
                            />
                          </FormGroup>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              </div>
              <div role="tabpanel" id="tab-2" className={"tab-pane " + tab2}>
                <div className="panel-body">
                  <div>
                    {values[id].isAvailable ?
                    <i
                      className={"fa fa-2x fa-plus-square"}
                      style={{ cursor: "pointer", color: "green" }}
                      onClick={AddResponsibility}
                    /> : null}
                  </div>
                  {values[id].responsibilityList.map((record, index) => {
                    return (
                      !record.isDisabled ?
                      <SelectResponsibility
                        loading={loading}
                        key={index}
                        id={index}
                        previousResponsibility={record.id}
                        unitId={id}
                        setValues={setValues}
                        values={values}
                        errors={errors}
                        setErrors={setErrors}
                        selectedUnit={values[id].value}
                      /> : null
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row>
      <hr />
    </>
  );
};

export default SelectUnit;
