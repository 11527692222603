import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Label,
  Button,
} from "reactstrap";
import Select from "react-select";

import useForm from "../shared/useForm";
import { GetLanguageString } from "../helper/Components";
import { requestApi } from "../../actions/api";
import Draggable from "react-draggable";

const memberTypeValues = {
  date: null,
  memberId: "",
  memberTypeId: "",
};

const ChangeMemberType = ({
  setModal,
  memberId,
  setMemberValues,
  memberValues,
}) => {
  const { errors, setErrors, values, setValues } = useForm(memberTypeValues);

  const toggle = () => setModal(false);
  const [memberType, setMemberType] = useState([]);
  const [selectedMemberType, setSelectedMemberType] = useState({});
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  useEffect(() => {
    requestApi("membertype/")
      .fetchAll()
      .then((res) => {
        let temp = [];
        res.data.forEach((record) =>
          temp.push({ value: record.id, label: record.type })
        );
        setMemberType(temp);
      })
      .catch((err) => console.log(err));
  }, []);

  const validate = () => {
    let temp = {};
    temp.date =
      values.date !== null ? (
        ""
      ) : (
        <GetLanguageString props="member_date_error_message" />
      );
    temp.memberTypeId = values.memberTypeId ? (
      ""
    ) : (
      <GetLanguageString props="member_member_type_error_message" />
    );
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputChangeDate = (value) => {
    setValues({
      ...values,
      date: value,
    });
    setErrors({
      date: "",
    });
  };

  const handleChangeMemberType = (record) => {
    setValues({
      ...values,
      memberTypeId: record.value,
      memberId: parseInt(memberId),
    });
    setSelectedMemberType(record);
    setErrors({
      memberTypeId: "",
    });
  };

  const handleSubmit = () => {
    if (validate()) {
      setModal(false);
      setMemberValues({
        ...memberValues,
        memberTypes: selectedMemberType,
        memberTypeChangeDate: values,
      });
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal">
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="member_edit_member_change_member_type" />}
          </ModalHeader>
          <Form>
            <ModalBody>
              <FormGroup>
                <Label className="col-form-label">
                  {
                    <GetLanguageString props="member_edit_member_select_member_type" />
                  }
                </Label>
                <Select
                  options={memberType}
                  className="basic-single ur no-drag"
                  classNamePrefix="select"
                  onChange={handleChangeMemberType}
                />
                {errors.memberTypeId ? (
                  <h4 className="text-error">{errors.memberTypeId}</h4>
                ) : null}
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label">
                  {<GetLanguageString props="member_edit_member_date" />}
                </Label>
                <DatePicker
                  placeholderText="dd/mm/yyyy"
                  className="form-control no-drag"
                  selected={values.date}
                  onChange={handleInputChangeDate}
                  dateFormat="dd/MM/yyyy"
                />
                {errors.date && <h4 className="text-error">{errors.date}</h4>}
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="white" onClick={toggle}>
                {<GetLanguageString props="common_cancel" />}
              </Button>
              <Button color="primary" onClick={handleSubmit}>
                {<GetLanguageString props="common_save_changes" />}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </Draggable>
    </div>
  );
};

export default ChangeMemberType;
