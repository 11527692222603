import React, { useEffect, useState } from "react";
import {
  ButtonGroup,
  Button,
  Label,
  Row,
  Col,
  FormGroup,
  Input,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";

import { ApiUnit } from "../../unit/ApiUnit";
import { GetLanguageString } from "../../helper/Components";
import { encryptStorage } from "../../../constants/EncryptStorage";
import { DateValidater } from "../../helper/Method";
import { requestApi } from "../../../actions/api";
import SelectUnitForEventReports from "./SelectUnitForEventReports";

const SearchEventsWithNoAttendance = ({
  searchEventAttendanceModel,
  setSearchEventAttendanceModel,
  setSearchButton,
}) => {
  const [eventType, setEventType] = useState([]);
  const [errors, setErrors] = useState({
    startDate: "",
    endDate: "",
  });
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [values, setValues] = useState(searchEventAttendanceModel);
  const [currentMonth, setCurrentMonth] = useState("primary");
  const [lastMonth, setLastMonth] = useState("white");
  const [currentWeek, setCurrentWeek] = useState("white");
  const [lastWeek, setLastWeek] = useState("white");
  const [searchEnterDates, setSearchEnterDates] = useState(false);
  const [searchByFilters, setSearchByFilters] = useState(true);
  const dateRangeValidation = {
    startDate: null,
    endDate: null,
  };
  const [dateValidations, setDateValidations] = useState(dateRangeValidation);

  useEffect(() => {
    requestApi("eventtype/")
      .fetchAll()
      .then((res) => {
        setEventType(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const eventOptions = [];
  eventType.forEach((d) => eventOptions.push({ value: d.id, label: d.type }));

  const validate = () => {
    let temp = {};
    temp.startDate = values.startDate ? (
      ""
    ) : (
      <GetLanguageString props="courses_start_date_error_message" />
    );
    temp.endDate = values.endDate ? (
      ""
    ) : (
      <GetLanguageString props="courses_end_date_error_message" />
    );

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  if (values?.unit.value === 0) {
    ApiUnit()
      .getUnit(loggedInUnitId, loggedInUnitId)
      .then((res) => {
        let temp = res.data.unitList[0];
        let temp2 = { value: temp.id, lable: temp.name };
        setValues({
          ...values,
          unit: temp2,
        });
      })
      .catch((err) => console.log(err));
  }

  const handleInputChangeStartDate = (value) => {
    setValues({
      ...values,
      startDate: value,
    });
    setDateValidations(dateRangeValidation);
    setErrors({
      ...errors,
      startDate: "",
    });
  };

  const handleInputChangeEndDate = (value) => {
    setValues({
      ...values,
      endDate: value,
    });
    setDateValidations(dateRangeValidation);
    setErrors({
      ...errors,
      endDate: "",
    });
  };

  const handleSubUnits = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setValues({
      ...values,
      isSubUnits: v,
    });
  };

  const handleSearch = () => {
    if (validate()) {
      if (!DateValidater(values.startDate, values.endDate)) {
        setDateValidations({
          ...dateValidations,
          endDate: (
            <GetLanguageString props="common_end_date_validation_message" />
          ),
        });
      } else {
        setSearchEventAttendanceModel(values);
        setSearchButton(true);
      }
    }
  };

  const handleChangeEventType = (record) => {
    if (record !== null) {
      let ids = [];
      record.forEach((r) => ids.push(r.value));
      setValues({
        ...values,
        eventTypeIds: ids,
      });
    } else {
      setValues({
        ...values,
        eventTypeIds: 0,
      });
    }
  };

  const handleSearchDates = (name) => {
    if (name === "enterDates") {
      setSearchEnterDates(true);
      setSearchByFilters(false);
    } else if (name === "filters") {
      setSearchEnterDates(false);
      setSearchByFilters(true);
      let tempDate = new Date();
      tempDate.setUTCDate(1);
      setValues({
        ...values,
        startDate: tempDate,
        endDate: new Date(),
      });
    }
  };

  const handleEventDate = (e) => {
    const { value } = e.target;
    let date = new Date();
    let currentDate = new Date();
    var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
    if (value === "0") {
      setLastMonth("white");
      setCurrentMonth("primary");
      setLastWeek("white");
      setCurrentWeek("white");
      date.setUTCDate(1);
      setValues({
        ...values,
        startDate: date,
        endDate: currentDate,
      });
    } else if (value === "1") {
      setLastMonth("primary");
      setCurrentMonth("white");
      setLastWeek("white");
      setCurrentWeek("white");
      let firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
      setValues({
        ...values,
        startDate: firstDay,
        endDate: lastDay,
      });
    } else if (value === "2") {
      setLastMonth("white");
      setCurrentMonth("white");
      setLastWeek("white");
      setCurrentWeek("primary");
      const firstDay = new Date(
        date.getTime() - ((date.getDay()-1) % 7) * 86400000 // Adjust to start of current week
      );
      setValues({
        ...values,
        startDate: firstDay,
        endDate: currentDate,
      });
    } else if (value === "3") {
      setLastMonth("white");
      setCurrentMonth("white");
      setLastWeek("primary");
      setCurrentWeek("white");
      const lastSunday = new Date(date.getTime() - (date.getDay() + 6) * 86400000); // Sunday of last week
      const firstDay = new Date(lastSunday.getTime() + 1); // Adjust to start of last week
      const lastDay = new Date(firstDay.getTime() + 6 * 86400000 - 1); // End of last week
      setValues({
        ...values,
        startDate: firstDay,
        endDate: lastDay,
      });
    }
    setDateValidations(dateRangeValidation);
  };

  return (
    <div className="ibox-content">
      <Row className="m-t-md">
        <Col md="6">
          <Label for="filters">
            {" "}
            <Input
              id="filters"
              type="radio"
              checked={searchByFilters}
              onChange={() => {
                handleSearchDates("filters");
              }}
            />{" "}
            {
              <GetLanguageString props="event_event_wise_attendance_search_filters" />
            }
          </Label>
          <Row>
            <ButtonGroup style={{ padding: 32 }}>
              <Button
                disabled={searchEnterDates}
                color={currentMonth}
                value={0}
                onClick={handleEventDate}
              >
                {<GetLanguageString props="funds_report_current_month" />}
              </Button>

              <Button
                disabled={searchEnterDates}
                color={lastMonth}
                value={1}
                onClick={handleEventDate}
              >
                {<GetLanguageString props="funds_report_last_month" />}
              </Button>

              <Button
                disabled={searchEnterDates}
                color={currentWeek}
                value={2}
                onClick={handleEventDate}
              >
                {<GetLanguageString props="quick_reports4_current_week" />}
              </Button>

              <Button
                disabled={searchEnterDates}
                color={lastWeek}
                value={3}
                onClick={handleEventDate}
              >
                {<GetLanguageString props="quick_reports4_last_week" />}
              </Button>
            </ButtonGroup>
          </Row>
        </Col>{" "}
        <Col md="6">
          <Label for="enterDates">
            {" "}
            <Input
              id="enterDates"
              type="radio"
              checked={searchEnterDates}
              onChange={() => {
                handleSearchDates("enterDates");
              }}
            />{" "}
            {
              <GetLanguageString props="event_event_wise_attendance_search_enter_date" />
            }
          </Label>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label className="col-form-label">
                  {<GetLanguageString props="courses_start_date" />}
                </Label>
                <DatePicker
                  disabled={searchByFilters}
                  showYearDropdown
                  placeholderText="dd/mm/yyyy"
                  className="form-control"
                  selected={values?.startDate}
                  onChange={handleInputChangeStartDate}
                  dateFormat="dd/MM/yyyy"
                />
                {dateValidations.startDate && (
                  <h4 className="text-error">{dateValidations.startDate}</h4>
                )}
                {errors.startDate && (
                  <h4 className="text-error">{errors.startDate}</h4>
                )}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label className="col-form-label">
                  {<GetLanguageString props="courses_end_date" />}
                </Label>
                <DatePicker
                  disabled={searchByFilters}
                  showYearDropdown
                  placeholderText="dd/mm/yyyy"
                  className="form-control"
                  selected={values?.endDate}
                  onChange={handleInputChangeEndDate}
                  dateFormat="dd/MM/yyyy"
                />
                {dateValidations.endDate && (
                  <h4 className="text-error">{dateValidations.endDate}</h4>
                )}
                {errors.endDate && (
                  <h4 className="text-error">{errors.endDate}</h4>
                )}
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="events_event_type" />}
            </Label>
            <Select
              options={eventOptions}
              className="basic-multi-select ur"
              classNamePrefix="select"
              isMulti
              isClearable
              onChange={handleChangeEventType}
            />
          </FormGroup>
        </Col>
      </Row>
      <SelectUnitForEventReports
        values={values}
        setValues={setValues}
        unitId={values?.unit.value}
      />
      <Row>
        <Col md="2">
          <FormGroup check>
            <Label check for="subUnit">
              <Input
                id="subUnit"
                type="checkbox"
                name="isSubUnits"
                checked={values?.isSubUnits}
                value={!values?.isSubUnits}
                onChange={handleSubUnits}
              />
              {<GetLanguageString props="member_search_member_sub_unit" />}
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="12" className="text-center m-t-sm">
          <Button color="primary" type="submit" onClick={handleSearch}>
            {<GetLanguageString props="funds_submit" />}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default SearchEventsWithNoAttendance;
