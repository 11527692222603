import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import {
  Table,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";

import {
  GetLanguageString,
  NoResult,
  GetSortingUI,
} from "../../helper/Components";
import EditResponsibilityType from "./EditResponsibility";
import { ApiResponsibility } from "./ApiResponsibilityType";
import { LoadingSpinner } from "../../../constants/const";
import ManagePermissions from "./ManagePermissions";
import useSorting from "../../shared/useSorting";
import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import AddResponsibilityType from "./AddResponsibility";
import { IoAdd } from "react-icons/io5";

const initialValues = {
  name: "neutral",
  isIncharge: "neutral",
};

const ResponsibilityType = ({ activeTab }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [responsibilityType, setResponsibilityType] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [permissionModal, setPermissionModal] = useState(false);
  const [editValue, setEditValue] = useState();
  const [responsibilityId, setResponsibilityId] = useState();
  const { sortObject, handleSorting } = useSorting(initialValues);

  const toggleDropdownOpen = (index, index1) => {
    let temp = [...responsibilityType];
    temp[index1].responsibilities[index].dropDown =
      !responsibilityType[index1].responsibilities[index].dropDown;
    setResponsibilityType(temp);
  };

  useEffect(() => {
    if (activeTab === "responsibility") {
      setLoading(true);
      let tempSortObject = {
        isTypeAscending: sortObject.name === "ascending",
        isTypeDescending: sortObject.name === "descending",
        isInchargeAscending: sortObject.isIncharge === "ascending",
        isInchargeDescending: sortObject.isIncharge === "descending",
      };
      ApiResponsibility()
        .getSortedResponsibility(tempSortObject)
        .then((res) => {
          setResponsibilityType(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [sortObject, activeTab]);

  const handleOnDelete = (value, isAffiliated) => {
    if (isAffiliated) {
      swal({
        title: t("common_is_affiliated"),
        text: t("common_is_affiliated_message"),
        className: GetSelectedLanguage() === "en" ? "" : "ur",
        icon: "warning",
      });
    } else {
      swal({
        title: t("common_delete_message"),
        text: t("common_delete_detail_message"),
        className: GetSelectedLanguage() === "en" ? "" : "ur",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setLoading(true);
          ApiResponsibility()
            .deleteResponsibility(value)
            .then((res) => {
              setResponsibilityType(res.data);
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        }
      });
    }
  };

  const handleOnEdit = (e) => {
    const { value } = e.target;
    setEditValue(value);
    setEditModal(true);
  };

  const handleManagePermissions = (e) => {
    const { value } = e.target;
    setResponsibilityId(value);
    setPermissionModal(true);
  };

  return (
    <>
      {editModal ? (
        <EditResponsibilityType
          editValue={editValue}
          setEditModal={setEditModal}
          setResponsibilityType={setResponsibilityType}
        />
      ) : null}
      {permissionModal ? (
        <ManagePermissions
          responsibilityId={responsibilityId}
          setPermissionModal={setPermissionModal}
        />
      ) : null}
      {modal ? (
        <AddResponsibilityType
          setModal={setModal}
          setResponsibilityType={setResponsibilityType}
        />
      ) : null}
      <div className="ibox ">
        <div className="ibox-title">
          <Button
            color="white"
            size="sm"
            className={GetCustomStyles().btn_style}
            onClick={() => {
              setModal(true);
            }}
          >
            <IoAdd
              size={18}
              color="#18A689"
              style={{ marginRight: 3, marginBottom: 1 }}
            />
            {<GetLanguageString props="settings_add_responsibility" />}
          </Button>
        </div>
        <div className="ibox-content">
          {!loading ? (
            responsibilityType.length !== 0 ? (
              <Row>
                {responsibilityType?.map((responsibility, index1) => {
                  return (
                    <Col md="6" key={index1}>
                      <div className="panel panel-default">
                        <div className="panel-heading">
                          <h3 className="text-center ur">
                            {responsibility?.unitTypeName}
                          </h3>
                        </div>
                        <div className="table-responsive">
                          <Table responsive className="m-b-none">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th
                                  className="cursor-pointer"
                                  onClick={() => handleSorting("name")}
                                >
                                  <GetLanguageString props="common_name" />
                                  <GetSortingUI
                                    sortObject={sortObject}
                                    name="name"
                                  />
                                </th>
                                <th>
                                  {
                                    <GetLanguageString props="settings_responsibility_type" />
                                  }
                                </th>
                                <th
                                  className="cursor-pointer"
                                  onClick={() => handleSorting("isIncharge")}
                                >
                                  <GetLanguageString props="settings_code" />
                                  <GetSortingUI
                                    sortObject={sortObject}
                                    name="isIncharge"
                                  />
                                </th>
                                <th></th>
                                {/* <th className='text-center'>
                                    {<GetLanguageString props='common_action' />}
                                  </th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {responsibility?.responsibilities?.map(
                                (record, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="p-t-custom">
                                        {index + 1}
                                      </td>
                                      <td className="ur p-t-custom">
                                        {record.type}
                                      </td>
                                      <td className="ur p-t-custom">
                                        {record.responsibilityType}
                                      </td>
                                      <td className="p-t-custom">
                                        {record.code}
                                      </td>
                                      <td className="text-center">
                                        <Dropdown
                                          isOpen={record.dropDown}
                                          toggle={() => {
                                            toggleDropdownOpen(index, index1);
                                          }}
                                          style={{ position: "static" }}
                                        >
                                          <DropdownToggle
                                            className="btn btn-default"
                                            tag="span"
                                            aria-expanded={record.dropDown}
                                          >
                                            {
                                              <GetLanguageString props="common_action" />
                                            }
                                            <b className="caret m-l-xs" />
                                          </DropdownToggle>
                                          <DropdownMenu
                                            style={{
                                              minWidth: "0rem",
                                              fontSize: "13px",
                                            }}
                                          >
                                            <DropdownItem
                                              onClick={handleOnEdit}
                                              value={record.id}
                                            >
                                              {
                                                <GetLanguageString props="common_edit" />
                                              }
                                            </DropdownItem>
                                            <DropdownItem
                                              onClick={() =>
                                                handleOnDelete(
                                                  record.id,
                                                  record.isAffiliated
                                                )
                                              }
                                            >
                                              {
                                                <GetLanguageString props="common_delete" />
                                              }
                                            </DropdownItem>
                                            <DropdownItem
                                              onClick={handleManagePermissions}
                                              value={record.id}
                                            >
                                              {
                                                <GetLanguageString props="settings_responsibility_manage_permission" />
                                              }
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </Dropdown>
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            ) : (
              <NoResult />
            )
          ) : (
            LoadingSpinner()
          )}
        </div>
      </div>
    </>
  );
};

export default ResponsibilityType;
