import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Label,
  Button,
  Input,
  Row,
  Col,
  NavLink,
  TabContent,
  TabPane,
  Nav,
  NavItem,
} from "reactstrap";
import Select from "react-select";
import { LoadingSpinner } from "../../../constants/const";
import { GetLanguageString } from "../../helper/Components";
import { ApiUser } from "../../users/ApiUsers";
import classnames from "classnames";
import Draggable from "react-draggable";

const ManagePermissions = ({ responsibilityId, setPermissionModal }) => {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [modifyMember, setModifyMember] = useState([]);
  const [requestModifyMember, setRequestModifyMember] = useState([]);
  const [addRafiq, setAddRafiq] = useState(false);
  const [requestAddRafiq, setRequestAddRafiq] = useState(false);
  const [cancelRafiq, setCancelRafiq] = useState(false);
  const [requestCancelRafiq, setRequestCancelRafiq] = useState(false);
  const [modifyMemberType, setModifyMemberType] = useState([]);
  const [requestModifyMemberType, setRequestModifyMemberType] = useState([]);
  const [markAsEx, setMarkAsEx] = useState([]);
  const [requestMarkAsEx, setRequestMarkAsEx] = useState([]);
  const [modifyResponsibility, setModifyResponsibility] = useState([]);
  const [
    requestResponsibilityModification,
    setRequestResponsibilityModification,
  ] = useState([]);
  const [moveMembers, setMoveMembers] = useState([]);
  const [requestMoveMembers, setRequestMoveMembers] = useState([]);
  const [values, setValues] = useState({
    responsibilityId: responsibilityId,
    permissions: [],
    options: [],
  });
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  const toggleTabs = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const toggle = () => setPermissionModal(false);

  useEffect(() => {
    setLoading(true);
    ApiUser()
      .getResponsibilityPermissions(responsibilityId)
      .then((res) => {
        setValues((prevValues) => ({
          ...prevValues,
          permissions: res.data.permissions,
          options: res.data.options,
        }));

        res.data.permissions.map((p) =>
          p.name.includes("Rufaqa")
            ? p.claims.map((c) =>
                c.name === "CanModifyMembers"
                  ? setModifyMember(c.selectedOptions)
                  : c.name.includes("RequestMemberModification")
                  ? setRequestModifyMember(c.selectedOptions)
                  : c.name.includes("ModifyMemberType")
                  ? setModifyMemberType(c.selectedOptions)
                  : c.name.includes("RequestMemberTypeModification")
                  ? setRequestModifyMemberType(c.selectedOptions)
                  : c.name.includes("CanMoveMembers")
                  ? setMoveMembers(c.selectedOptions)
                  : c.name.includes("RequestMoveMembers")
                  ? setRequestMoveMembers(c.selectedOptions)
                  : c.name.includes("CanMarkAsEx")
                  ? setMarkAsEx(c.selectedOptions)
                  : c.name.includes("RequestMarkRafiqAsEx")
                  ? setRequestMarkAsEx(c.selectedOptions)
                  : c.name.includes("CanModifyResponsibility")
                  ? setModifyResponsibility(c.selectedOptions)
                  : c.name.includes("CanRequestResponsibilityModification")
                  ? setRequestResponsibilityModification(c.selectedOptions)
                  : c.name === "CanCancelMemberRegistration"
                  ? c.isSelected
                    ? setCancelRafiq(true)
                    : setCancelRafiq(false)
                  : c.name === "CanRequestMemberRegistrationCancelation"
                  ? c.isSelected
                    ? setRequestCancelRafiq(true)
                    : setRequestCancelRafiq(false)
                  : c.name === "CanAddMember"
                  ? c.isSelected
                    ? setAddRafiq(true)
                    : setAddRafiq(false)
                  : c.name === "CanRequestAddMember"
                  ? c.isSelected
                    ? setRequestAddRafiq(true)
                    : setRequestAddRafiq(false)
                  : null
              )
            : null
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [responsibilityId]);

  const handlePermissions = (e, index, index1, label) => {
    const { checked } = e.target;
    let tempValues = values.permissions;
    tempValues[index].claims[index1].isSelected = checked;
    tempValues[index].claims.map((item, index2) => {
      return item.name.includes("View")
        ? item.isSelected
          ? null
          : (tempValues[index].claims[index2].isSelected = checked)
        : null;
    });
    setValues({
      ...values,
      permissions: tempValues,
    });

    if (label === "CanCancelMemberRegistration") {
      setCancelRafiq(!cancelRafiq);
    } else if (label === "CanRequestMemberRegistrationCancelation") {
      setRequestCancelRafiq(!requestCancelRafiq);
    } else if (label === "CanAddMember") {
      setAddRafiq(!addRafiq);
    } else if (label === "CanRequestAddMember") {
      setRequestAddRafiq(!requestAddRafiq);
    }
  };

  const [errors, setErrors] = useState({
    modifyMember: "",
    modifyMemberType: "",
    modifyMoveMembers: "",
    addMember: "",
    CancelMemberRegistration: "",
    modifyResponsibility: "",
    markAsEx: "",
  });

  const validate = () => {
    let temp = {};
    let member = modifyMember.filter((item) =>
      requestModifyMember.some(
        (requestModifyMember) => requestModifyMember.value === item.value
      )
    );
    let memberType = modifyMemberType.filter((item) =>
      requestModifyMemberType.some(
        (requestModifyMember) => requestModifyMember.value === item.value
      )
    );
    let moveMember = moveMembers.filter((item) =>
      requestMoveMembers.some(
        (requestMoveMembers) => requestMoveMembers.value === item.value
      )
    );

    let markAsExRafiq = markAsEx.filter((item) =>
      requestMarkAsEx.some(
        (requestMarkAsEx) => requestMarkAsEx.value === item.value
      )
    );
    let responsibilityModification = modifyResponsibility.filter((item) =>
      requestResponsibilityModification.some(
        (requestResponsibilityModification) =>
          requestResponsibilityModification.value === item.value
      )
    );

    temp.modifyResponsibility =
      Object.keys(responsibilityModification).length === 0 ? (
        ""
      ) : (
        <GetLanguageString props="settings_responsibility_manage_permission_rufaqaa_permission_modify_responsibility_error" />
      );

    temp.modifyMember =
      Object.keys(member).length === 0 ? (
        ""
      ) : (
        <GetLanguageString props="settings_responsibility_manage_permission_rufaqaa_permission_modify_Member_error" />
      );
    temp.markAsExRafiq =
      Object.keys(markAsExRafiq).length === 0 ? (
        ""
      ) : (
        <GetLanguageString props="settings_responsibility_manage_permission_rufaqaa_permission_mark_as_ex_error" />
      );
    temp.modifyMemberType =
      Object.keys(memberType).length === 0 ? (
        ""
      ) : (
        <GetLanguageString props="settings_responsibility_manage_permission_rufaqaa_permission_modify_Member_Type_error" />
      );

    temp.modifyMoveMembers =
      Object.keys(moveMember).length === 0 ? (
        ""
      ) : (
        <GetLanguageString props="settings_responsibility_manage_permission_rufaqaa_permission_modify_move_Member_error" />
      );

    temp.addMember =
      addRafiq && requestAddRafiq ? (
        <GetLanguageString props="settings_responsibility_manage_permission_rufaqaa_permission_add_member_error" />
      ) : (
        ""
      );

    temp.CancelMemberRegistration =
      cancelRafiq && requestCancelRafiq ? (
        <GetLanguageString props="settings_responsibility_manage_permission_rufaqaa_permission_cancel_rafiq_registration_error" />
      ) : (
        ""
      );

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleSelectedOptions = (
    record,
    index,
    index1,
    claimName,
    permissionName
  ) => {
    let tempValues = values.permissions;
    tempValues[index].claims[index1].selectedOptions = record;
    setValues({
      ...values,
      permissions: tempValues,
    });

    if (permissionName.includes("Rufaqaa")) {
      if (claimName === "CanModifyMembers") {
        setModifyMember(record);
      } else if (claimName.includes("RequestMemberModification")) {
        setRequestModifyMember(record);
      } else if (claimName.includes("ModifyMemberType")) {
        setModifyMemberType(record);
      } else if (claimName.includes("RequestMemberTypeModification")) {
        setRequestModifyMemberType(record);
      } else if (claimName.includes("CanMoveMembers")) {
        setMoveMembers(record);
      } else if (claimName.includes("RequestMoveMembers")) {
        setRequestMoveMembers(record);
      } else if (claimName.includes("ModifyResponsibility")) {
        setModifyResponsibility(record);
      } else if (claimName.includes("RequestResponsibilityModification")) {
        setRequestResponsibilityModification(record);
      } else if (claimName.includes("MarkAsEx")) {
        setMarkAsEx(record);
      } else if (claimName.includes("RequestMarkRafiqAsEx")) {
        setRequestMarkAsEx(record);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      ApiUser()
        .setResponsibilityPermissions(values)
        .then((res) => {
          toggle();
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal size="lg" isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {
              <GetLanguageString props="settings_responsibility_manage_permission" />
            }
          </ModalHeader>
          {!loading ? (
            <Form>
              <ModalBody>
                <div className="wrapper wrapper-content">
                  <Row>
                    <Col>
                      <div className="tabs-container">
                        <div className="tabs-left">
                          <Nav tabs>
                            {values.permissions?.map((item, index) => {
                              return (
                                <NavItem>
                                  <NavLink
                                    key={index}
                                    className={classnames({
                                      active: activeTab === index + 1,
                                    })}
                                    onClick={() => {
                                      toggleTabs(index + 1);
                                    }}
                                  >
                                    <h5>{item.name}</h5>
                                  </NavLink>
                                </NavItem>
                              );
                            })}
                          </Nav>
                          <TabContent activeTab={activeTab}>
                            {values.permissions?.map((item, index) => {
                              return (
                                <TabPane tabId={index + 1} key={index}>
                                  <div className="panel-body">
                                    {item.claims.map((record, index1) => {
                                      return (
                                        <>
                                          <Row key={index1}>
                                            <Col md="6">
                                              <FormGroup check>
                                                <Row className="m-4">
                                                  <Label>
                                                    <Input
                                                      id={
                                                        "isSelected" +
                                                        record.value
                                                      }
                                                      type="checkbox"
                                                      checked={record.isSelected}
                                                      onChange={(e) => {
                                                        handlePermissions(
                                                          e,
                                                          index,
                                                          index1,
                                                          record.name
                                                        );
                                                      }}
                                                    />
                                                    {record.label}
                                                  </Label>
                                                </Row>
                                              </FormGroup>
                                            </Col>
                                            {item.name.includes("Rufaqaa") ? (
                                              record.isSelected &&
                                              (record.label.includes("Modif") ||
                                                record.label.includes("Ex") ||
                                                record.label.includes("Move")) ? (
                                                <Col md="5" className="m">
                                                  <FormGroup>
                                                    <Select
                                                      value={
                                                        record.selectedOptions
                                                      }
                                                      options={record.options}
                                                      className={
                                                        record.label.includes(
                                                          "Type"
                                                        ) ||
                                                        record.label.includes(
                                                          "Ex"
                                                        ) ||
                                                        record.label.includes(
                                                          "Responsibili"
                                                        )
                                                          ? "basic-multi-select ur"
                                                          : "basic-multi-select"
                                                      }
                                                      classNamePrefix="Select"
                                                      isMulti
                                                      onChange={(e) => {
                                                        handleSelectedOptions(
                                                          e,
                                                          index,
                                                          index1,
                                                          record.name,
                                                          item.name
                                                        );
                                                      }}
                                                    />
                                                  </FormGroup>
                                                </Col>
                                              ) : null
                                            ) : record.isSelected &&
                                              !item.name.includes("Funds") &&
                                              !item.name.includes("Other") &&
                                              !item.name.includes("Ahbab") &&
                                              !item.name.includes("Mudaris") &&
                                              !item.name.includes("Document") &&
                                              !record.name.includes("View") ? (
                                              <Col md="5" className="m">
                                                <FormGroup>
                                                  <Select
                                                    value={record.selectedOptions}
                                                    options={record?.options}
                                                    className="basic-multi-select ur"
                                                    classNamePrefix="Select a Type"
                                                    placeholder=" قسم منتخب کریں"
                                                    isMulti
                                                    onChange={(e) => {
                                                      handleSelectedOptions(
                                                        e,
                                                        index,
                                                        index1,
                                                        record.name,
                                                        item.name
                                                      );
                                                    }}
                                                  />
                                                </FormGroup>
                                              </Col>
                                            ) : null}
                                          </Row>
                                        </>
                                      );
                                    })}
                                  </div>
                                </TabPane>
                              );
                            })}
                          </TabContent>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {errors.modifyMember && (
                  <h4 className="text-error">{errors.modifyMember}</h4>
                )}
                {errors.modifyMemberType && (
                  <h4 className="text-error">{errors.modifyMemberType}</h4>
                )}
                {errors.addMember && (
                  <h4 className="text-error">{errors.addMember}</h4>
                )}
                {errors.CancelMemberRegistration && (
                  <h4 className="text-error">
                    {errors.CancelMemberRegistration}
                  </h4>
                )}
                {errors.modifyMoveMembers && (
                  <h4 className="text-error">{errors.modifyMoveMembers}</h4>
                )}
                {errors.modifyResponsibility && (
                  <h4 className="text-error">{errors.modifyResponsibility}</h4>
                )}
                {errors.markAsExRafiq && (
                  <h4 className="text-error">{errors.markAsExRafiq}</h4>
                )}
              </ModalBody>
              <ModalFooter>
                <Button color="white" onClick={toggle}>
                  {<GetLanguageString props="common_cancel" />}
                </Button>
                <Button color="primary" type="submit" onClick={handleSubmit}>
                  {<GetLanguageString props="common_save_changes" />}
                </Button>
              </ModalFooter>
            </Form>
          ) : (
            LoadingSpinner()
          )}
        </Modal>
      </Draggable>
    </div>
  );
};

export default ManagePermissions;
