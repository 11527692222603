import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Label,
  Button,
  Row,
  Col,
  Input,
} from "reactstrap";
import Select from "react-select";

import { GetLanguageString } from "../helper/Components";
import { GetMonths, GetSelectedLanguage, GetYears } from "../helper/Method";
import { ApiFund } from "./ApiFund";
import { convertToDateString, LoadingSpinner } from "../../constants/const";
import Draggable from "react-draggable";
import swal from "sweetalert";
import { t } from "i18next";

const AddFund = ({
  setModal,
  memberId,
  unitId,
  memberName,
  setMemberFunds,
}) => {
  const [loading, setLoading] = useState(false);
  const toggle = () => setModal(false);
  let months = GetMonths();
  let years = GetYears();
  const [selectedYear, setSelectedYear] = useState({});
  const [selectedMonth, setSelectedMonth] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [fundValues, setFundValues] = useState({});
  const [serverError, setServerError] = useState("");
  const [errors, setErrors] = useState({
    date: "",
    month: "",
    year: "",
    receiptNumber: "",
    amount: "",
  });
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  useEffect(() => {
    let currentDate = new Date();
    let tempYear = currentDate.getFullYear();
    let tempMonth = currentDate.getMonth() + 1;
    let monthList = GetMonths();
    monthList.forEach((m) =>
      m.value === tempMonth ? setSelectedMonth(m) : null
    );
    setSelectedYear({ value: tempYear, label: tempYear });
    setFundValues({
      memberId: memberId,
      unitId: unitId,
      date: currentDate,
      month: tempMonth,
      year: tempYear,
      receiptNumber: "",
      amount: 0,
    });
  }, [unitId, memberId]);

  const validate = () => {
    let temp = {};
    temp.month = fundValues.month ? (
      ""
    ) : (
      <GetLanguageString props="funds_add_fund_select_month_error" />
    );
    temp.year = fundValues.year ? (
      ""
    ) : (
      <GetLanguageString props="funds_add_fund_select_year_error" />
    );
    temp.receiptNumber = fundValues.receiptNumber ? (
      ""
    ) : (
      <GetLanguageString props="funds_receipt_number_error" />
    );
    temp.date = selectedDate ? (
      ""
    ) : (
      <GetLanguageString props="funds_add_fund_date_paid_error" />
    );
    temp.amount = fundValues.amount ? (
      ""
    ) : (
      <GetLanguageString props="funds_add_fund_amount_paid_error" />
    );

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputChangeDate = (value) => {
    setSelectedDate(value);
    setErrors({
      date: "",
    });
  };

  const handleMonth = (record) => {
    setFundValues({
      ...fundValues,
      month: record.value,
    });
    setSelectedMonth(record);
    setErrors({
      month: "",
    });
  };

  const handleYears = (record) => {
    setFundValues({
      ...fundValues,
      year: record.value,
    });
    setSelectedYear(record);
    setErrors({
      year: "",
    });
  };

  const handleReceiptNumber = (e) => {
    const { value } = e.target;
    setFundValues({
      ...fundValues,
      receiptNumber: value,
    });
    setErrors({
      receiptNumber: "",
    });
  };

  // It will handle chnage in Amount
  const handleAmountChange = (e) => {
    const { value } = e.target;
    setFundValues({
      ...fundValues,
      amount: value,
    });
    setErrors({
      amount: "",
    });
  };

  const handleSubmit = () => {
    if (validate()) {
      setLoading(true);
      fundValues.date = convertToDateString(selectedDate);
      ApiFund()
        .addFund(fundValues)
        .then((res) => {
          swal({
            title: t("member_member_detail_fund_added"),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "success",
            buttons: "OK",
          });
          ApiFund()
            .getList(fundValues.unitId, fundValues.memberId)
            .then((res) => {
              setMemberFunds(res.data);
              setModal(false);
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        })
        .catch((err) => {
          if (err.response.data.length < 100) {
            setServerError(err.response.data);
          }
          setModal(false);
          swal({
            title: t("member_member_detail_fund_added_error"),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "error",
            buttons: "OK",
          });
          console.log(err.response.data);
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="funds_add_fund" />}
            <p className="ur">{memberName}</p>
          </ModalHeader>
          {!loading ? (
            <Form>
              <ModalBody>
                {serverError && <h4 className="text-danger">{serverError}</h4>}
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label className="col-form-label">
                        {
                          <GetLanguageString props="funds_add_fund_select_month" />
                        }
                      </Label>
                      <Select
                        value={selectedMonth}
                        className="basic-single no-drag"
                        classNamePrefix="select"
                        options={months}
                        onChange={handleMonth}
                      />
                      {errors.month && (
                        <h4 className="text-error">{errors.month}</h4>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label className="col-form-label">
                        {
                          <GetLanguageString props="funds_add_fund_select_year" />
                        }
                      </Label>
                      <Select
                        value={selectedYear}
                        className="basic-single no-drag"
                        classNamePrefix="select"
                        options={years}
                        onChange={handleYears}
                      />
                      {errors.year && (
                        <h4 className="text-error">{errors.year}</h4>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label className="col-form-label">
                        {<GetLanguageString props="funds_add_fund_date_paid" />}
                      </Label>
                      <DatePicker
                        maxDate={new Date()}
                        placeholderText="dd/mm/yyyy"
                        className="form-control no-drag"
                        dateFormat="dd/MM/yyyy"
                        selected={selectedDate}
                        onChange={handleInputChangeDate}
                      />
                      {errors.date && (
                        <h4 className="text-error">{errors.date}</h4>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label className="col-form-label">
                        {<GetLanguageString props="funds_receipt_number" />}
                      </Label>
                      <Input
                        autoFocus
                        type="text"
                        className="no-drag"
                        value={fundValues.receiptNumber}
                        onChange={handleReceiptNumber}
                      />
                      {errors.receiptNumber && (
                        <h4 className="text-error">{errors.receiptNumber}</h4>
                      )}
                    </FormGroup>
                  </Col>

                  {/* Add Amount for the fund */}
                  <Col md="6">
                    <FormGroup>
                      <Label className="col-form-label">
                        {<GetLanguageString props="funds_add_fund_amount" />}
                      </Label>
                      <Input
                        autoFocus
                        type="number"
                        className="no-drag"
                        value={fundValues.amount}
                        onChange={handleAmountChange}
                      />
                      {errors.amount && (
                        <h4 className="text-error no-drag">{errors.amount}</h4>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button color="white" onClick={toggle}>
                  {<GetLanguageString props="common_cancel" />}
                </Button>
                <Button color="primary" onClick={handleSubmit}>
                  {<GetLanguageString props="common_save_changes" />}
                </Button>
              </ModalFooter>
            </Form>
          ) : (
            LoadingSpinner()
          )}
        </Modal>
      </Draggable>
    </div>
  );
};

export default AddFund;
