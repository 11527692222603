import React, { useEffect, useState } from "react";
import moment from "moment";
import { Button, Table, Row } from "reactstrap";
import { withRouter } from "react-router-dom";

import { GetCustomStyles } from "../../helper/Method";
import {
  GetLanguageString,
  GetSortingUI,
  NoResult,
  SetReportTitle,
} from "../../helper/Components";
import { ApiUnit } from "../../unit/ApiUnit";
import { ApiCourse } from "../ApiCourse";
import SearchCourseReport from "./SearchCourseReport";
import {
  LoadingSpinner,
  convertUTCToLocalTime,
} from "../../../constants/const";
import useSorting from "../../shared/useSorting";
import { FcPrint } from "react-icons/fc";

const initialValues = {
  courseType: "neutral",
  startDate: "neutral",
  endDate: "neutral",
};

const CourseReport = ({ match, history }) => {
  const [loading, setLoading] = useState(false);
  const [searchButton, setSearchButton] = useState(false);
  const [courses, setCourses] = useState([]);
  const [reportTitle, setReportTitle] = useState("");
  const [modal, setModal] = useState(false);
  const [searchCourseModel, setSearchCourseModel] = useState({
    unit: { value: 0, lable: "" },
    startDate: null,
    endDate: null,
    isSubUnits: true,
    isDeletedUnits: false,
  });
  const { sortObject, handleSorting } = useSorting(initialValues);

  if (searchCourseModel.unit.value === 0) {
    ApiUnit()
      .getUnit(0, 0)
      .then((res) => {
        let temp = res.data.unitList[0];
        let temp2 = { value: temp.id, lable: temp.name };
        setSearchCourseModel({
          unit: temp2,
        });
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    setLoading(true);

    let tempSortObject = {
      isCourseTypeDescending: sortObject.courseType === "descending",
      isCourseTypeAscending: sortObject.courseType === "ascending",
      isStartDateDescending: sortObject.startDate === "descending",
      isStartDateAscending: sortObject.startDate === "ascending",
      isEndDateDescending: sortObject.endDate === "descending",
      isEndDateAscending: sortObject.endDate === "ascending",
    };
    searchCourseModel.sortModel = tempSortObject;
    
    if (searchButton) {
      ApiCourse()
        .getCourseReport(searchCourseModel)
        .then((res) => {
          setCourses(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [searchCourseModel, sortObject, searchButton]);

  useEffect(() => {
    if (reportTitle !== "") window.print();
  }, [reportTitle]);

  const handleReportTitle = () => {
    setModal(true);
  };

  const handleChangeDetails = (e) => {
    const { value } = e.target;
    history.push({
      pathname: `${match.url}/courseparticipants`,
      state: {
        courseId: value,
      },
    });
  };

  return (
    <>
      {modal ? (
        <SetReportTitle setModal={setModal} setReportTitle={setReportTitle} />
      ) : null}
      <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
        <div className="ibox ">
          <div className="ibox-title">
            <h5>{<GetLanguageString props="common_generate_report" />}</h5>
            <Button
               color="default"
               size="sm"
               onClick={handleReportTitle}
               className={GetCustomStyles().err_btn_style}
             >
                <FcPrint size={15}  className={GetCustomStyles().fa_style} />
               {<GetLanguageString props="common_print" />}
             </Button>
          </div>
          <div className="ibox-content">
            <SearchCourseReport
              unitId={searchCourseModel.unit.value}
              searchCourseModel={searchCourseModel}
              setSearchCourseModel={setSearchCourseModel}
              setSearchButton={setSearchButton}
            />
          </div>
        </div>
        {searchButton && (
          <div className="ibox" id="section-to-print">
            <div className="ibox-content">
              <Row className="print">
                <div className="text-center">
                  <h3>Title : {reportTitle}</h3>
                </div>
              </Row>
              {!loading ? (
                courses.length !== 0 ? (
                  <div className="table-responsive">
                    <Table className="table-stripped table-bordered footable">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th
                            className="cursor-pointer"
                            onClick={() => handleSorting("courseType")}
                          >
                            <GetLanguageString props="courses_report_course_name" />
                            <GetSortingUI
                              sortObject={sortObject}
                              name="courseType"
                              isReport
                            />
                          </th>
                          <th
                            className="cursor-pointer"
                            onClick={() => handleSorting("startDate")}
                          >
                            <GetLanguageString props="courses_start_date" />
                            <GetSortingUI
                              sortObject={sortObject}
                              name="startDate"
                              isReport
                            />
                          </th>
                          <th
                            className="cursor-pointer"
                            onClick={() => handleSorting("endDate")}
                          >
                            <GetLanguageString props="courses_end_date" />
                            <GetSortingUI
                              sortObject={sortObject}
                              name="endDate"
                              isReport
                            />
                          </th>
                          <th>
                            {" "}
                            {<GetLanguageString props="courses_address" />}
                          </th>
                          <th>
                            {" "}
                            {<GetLanguageString props="courses_description" />}
                          </th>
                          <th>{<GetLanguageString props="courses_unit" />}</th>
                          <th className="noprint"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {courses.map((record, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td className="ur">{record.courseType.label}</td>
                              <td>
                                {moment(
                                  convertUTCToLocalTime(record.startDate)
                                ).format("DD/MM/yyyy")}
                              </td>
                              <td>
                                {record.endDate
                                  ? moment(
                                      convertUTCToLocalTime(record.endDate)
                                    ).format("DD/MM/yyyy")
                                  : ""}
                              </td>
                              <td className="ur">
                                {record.address !== null &&
                                record.address !== ""
                                  ? record.address
                                  : record.courseLocation?.label}
                              </td>
                              <td className="ur">{record.description}</td>
                              <td className="ur">{record.unit.label}</td>
                              <td className="text-center noprint">
                                <Button
                                  color="white"
                                  size="sm"
                                  value={record.id}
                                  onClick={handleChangeDetails}
                                >
                                  {
                                    <GetLanguageString props="courses_report_details" />
                                  }
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <NoResult />
                )
              ) : (
                LoadingSpinner()
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default withRouter(CourseReport);
