import React from "react";
import { Table } from "reactstrap";
import { GetLanguageString, NoResult } from "../../helper/Components";
import moment from "moment";
import { convertUTCToLocalTime } from "../../../constants/const";

const MemberBooks = ({ tab6, books }) => {
  return (
    <div role="tabpanel" id="tab-6" className={"tab-pane " + tab6}>
      <div className="panel-body">
        {books?.length !== 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>
                  {
                    <GetLanguageString props="member_member_detail_books_book_name" />
                  }
                </th>
                <th>
                  {
                    <GetLanguageString props="member_member_detail_books_date" />
                  }
                </th>
                <th>
                  {
                    <GetLanguageString props="common_remarks" />
                  }
                </th>
                <th>
                  {
                    <GetLanguageString props="member_reference_note" />
                  }
                </th>
              </tr>
            </thead>
            <tbody>
              {books?.map((record, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td className="ur ">{record.bookName}</td>
                    <td>
                      {record.completedAt
                        ? moment(
                            convertUTCToLocalTime(record.completedAt)
                          ).format("DD/MM/yyyy")
                        : "-"}
                    </td>
                    <td className="ur ">{record.remarks}</td>
                    <td className="ur ">{record.referenceNote}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <NoResult />
        )}
      </div>
    </div>
  );
};

export default MemberBooks;
