import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Input, Label, Row, Table } from "reactstrap";
import { GetLanguageString } from "../../../../helper/Components";

const Activities = ({ usraIjtimaSection, setUsraIjtimaSection }) => {
  const [values, setValues] = useState({
    conductedAsInstruction: usraIjtimaSection.conductedAsInstruction,
    ifNotConductedAsInstruction: usraIjtimaSection.ifNotConductedAsInstruction,
    lessThanFourConductedExplanation:
      usraIjtimaSection.lessThanFourConductedExplanation,
  });
  const [usraConductedChecks, setUsraConductedChecks] = useState(
    usraIjtimaSection.usraConducted
  );
  const [usraMembersCounts, setUsraMembersCounts] = useState(
    usraIjtimaSection.usraMembersCount
  );

  useEffect(() => {
    setUsraIjtimaSection({
      ...usraIjtimaSection,
      conductedAsInstruction: values.conductedAsInstruction,
      ifNotConductedAsInstruction: values.ifNotConductedAsInstruction,
      lessThanFourConductedExplanation: values.lessThanFourConductedExplanation,
      usraMembersCount: usraMembersCounts,
      usraConducted: usraConductedChecks,
    });
  }, [values, usraConductedChecks, usraMembersCounts, usraIjtimaSection, setUsraIjtimaSection]);

  const handleInput1Change = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleInput2Change = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleValuesChange = (e) => {
    const { name, value } = e.target;
    setUsraMembersCounts({
      ...usraMembersCounts,
      [name]: value,
    });
  };

  const handleWeeks = (e) => {
    const { checked, name } = e.target;
    setUsraConductedChecks({
      ...usraConductedChecks,
      [name]: checked,
    });
  };

  return (
    <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
      <div className="ibox panel panel-info">
        <div className="panel-heading ur">
          <h2 className="text-center font-bold">اجتماع اُسرہ (ہفتہ وار)</h2>
        </div>
        <Row>
          <Col md="6">
            <div className="ibox-content">
              <Table responsive> size="sm">
                <thead>
                  <tr className="text-center">
                    <th>
                      <h4>
                        {" "}
                        {
                          <GetLanguageString props="create_report_conducted" />
                        }{" "}
                      </h4>
                    </th>
                    <th>
                      <h3 className="font-bold ur">مبتدی</h3>
                    </th>
                    <th>
                      <h3 className="font-bold ur">ملتزم</h3>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <h5 className="text-center">
                        <Input
                          type="checkbox"
                          checked={usraConductedChecks.firstWeek}
                          name="firstWeek"
                          value={usraConductedChecks.firstWeek}
                          onChange={(e) => handleWeeks(e)}
                        />{" "}
                        {
                          <GetLanguageString props="create_report_conducted_first_week" />
                        }
                      </h5>
                    </td>
                    <td>
                      <Input
                        className="text-center"
                        type="text"
                        name="firstWeekMubtadi"
                        value={usraMembersCounts.firstWeekMubtadi}
                        onChange={handleValuesChange}
                      />
                    </td>
                    <td>
                      <Input
                        className="text-center"
                        type="text"
                        name="firstWeekMultazim"
                        value={usraMembersCounts.firstWeekMultazim}
                        onChange={handleValuesChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5 className="text-center">
                        <Input
                          type="checkbox"
                          checked={usraConductedChecks.secondWeek}
                          name="secondWeek"
                          value={usraConductedChecks.secondWeek}
                          onChange={(e) => handleWeeks(e)}
                        />{" "}
                        {
                          <GetLanguageString props="create_report_conducted_second_week" />
                        }{" "}
                      </h5>
                    </td>
                    <td>
                      <Input
                        className="text-center"
                        type="text"
                        name="secondWeekMubtadi"
                        value={usraMembersCounts.secondWeekMubtadi}
                        onChange={handleValuesChange}
                      />
                    </td>
                    <td>
                      <Input
                        className="text-center"
                        type="text"
                        name="secondWeekMultazim"
                        value={usraMembersCounts.secondWeekMultazim}
                        onChange={handleValuesChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5 className="text-center">
                        <Input
                          type="checkbox"
                          checked={usraConductedChecks.thirdWeek}
                          name="thirdWeek"
                          value={usraConductedChecks.thirdWeek}
                          onChange={(e) => handleWeeks(e)}
                        />{" "}
                        {
                          <GetLanguageString props="create_report_conducted_third_week" />
                        }
                      </h5>
                    </td>
                    <td>
                      <Input
                        className="text-center"
                        type="text"
                        name="thirdWeekMubtadi"
                        value={usraMembersCounts.thirdWeekMubtadi}
                        onChange={handleValuesChange}
                      />
                    </td>
                    <td>
                      <Input
                        className="text-center"
                        type="text"
                        name="thirdWeekMultazim"
                        value={usraMembersCounts.thirdWeekMultazim}
                        onChange={handleValuesChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5 className="text-center">
                        <Input
                          type="checkbox"
                          checked={usraConductedChecks.fourthWeek}
                          name="fourthWeek"
                          value={usraConductedChecks.fourthWeek}
                          onChange={(e) => handleWeeks(e)}
                        />{" "}
                        {
                          <GetLanguageString props="create_report_conducted_fourth_week" />
                        }
                      </h5>
                    </td>
                    <td>
                      <Input
                        className="text-center"
                        type="text"
                        name="fourtheekMubtadi"
                        value={usraMembersCounts.fourtheekMubtadi}
                        onChange={handleValuesChange}
                      />
                    </td>
                    <td>
                      <Input
                        className="text-center"
                        type="text"
                        name="fourthWeekMultazim"
                        value={usraMembersCounts.fourthWeekMultazim}
                        onChange={handleValuesChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5 className="text-center">
                        <Input
                          type="checkbox"
                          checked={usraConductedChecks.extra}
                          name="extra"
                          value={usraConductedChecks.extra}
                          onChange={(e) => handleWeeks(e)}
                        />{" "}
                        {
                          <GetLanguageString props="create_report_conducted_extra" />
                        }
                      </h5>
                    </td>
                    <td>
                      <Input
                        className="text-center"
                        type="text"
                        name="extraUsraMubtadi"
                        value={usraMembersCounts.extraUsraMubtadi}
                        onChange={handleValuesChange}
                      />
                    </td>
                    <td>
                      <Input
                        className="text-center"
                        type="text"
                        name="extraUsraMultazim"
                        value={usraMembersCounts.extraUsraMultazim}
                        onChange={handleValuesChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h3 className="text-center">
                        {
                          <GetLanguageString props="create_report_conducted_total" />
                        }
                      </h3>
                    </td>
                    <td>
                      <Input
                        className="text-center"
                        type="text"
                        name="totalMubtadi"
                        value={usraMembersCounts.totalMubtadi}
                        onChange={handleValuesChange}
                      />
                    </td>
                    <td>
                      <Input
                        className="text-center"
                        type="text"
                        name="totalMultazim"
                        value={usraMembersCounts.totalMultazim}
                        onChange={handleValuesChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5 className="text-center">
                        {
                          <GetLanguageString props="create_report_conducted_average" />
                        }
                      </h5>
                    </td>
                    <td>
                      <Input
                        className="text-center"
                        type="text"
                        name="averageMubtadi
                        "
                        placeholder="%"
                        value={usraMembersCounts.averageMubtadi}
                        onChange={handleValuesChange}
                      />
                    </td>
                    <td>
                      <Input
                        className="text-center"
                        type="text"
                        name="averageMultazim"
                        placeholder="%"
                        value={usraMembersCounts.averageMultazim}
                        onChange={handleValuesChange}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>
          <Col md="6">
            <div className="ibox-content">
              <h3 className="ur">
                (i) نظام العمل میں دی گئی ترتیب و نصاب کے مطابق انعقاد ہوئے؟
              </h3>
              <Row className="text-center">
                <Col md="6">
                  <Input
                    type="radio"
                    checked={values.conductedAsInstruction ? true : false}
                    value={!values.conductedAsInstruction}
                    onChange={() => {
                      setValues({
                        ...values,
                        conductedAsInstruction: !values.conductedAsInstruction,
                      });
                    }}
                  />{" "}
                  {<GetLanguageString props="Yes" />}
                </Col>
                <Col md="6">
                  <Input
                    type="radio"
                    checked={values.conductedAsInstruction ? false : true}
                    value={!values.conductedAsInstruction}
                    onChange={() => {
                      setValues({
                        ...values,
                        conductedAsInstruction: !values.conductedAsInstruction,
                      });
                    }}
                  />{" "}
                  {<GetLanguageString props="No" />}
                </Col>
              </Row>
              <Label className="col-form-label ur">
                <h3>{"اگر نہیں تو وجوہات اور متبادل تفصیل لکھیں"}</h3>
              </Label>
              <Input
                className="ur"
                type="textarea"
                name="ifNotConductedAsInstruction"
                value={values.ifNotConductedAsInstruction}
                onChange={handleInput1Change}
              />
            </div>
            <div className="ibox-content ur">
              <Label className="col-form-label">
                <h3>{"(ii) چار سے کم منعقد ہونے کی صورت میں وجوہات؟"}</h3>
              </Label>
              <Input
                type="textarea"
                name="lessThanFourConductedExplanation"
                value={values.lessThanFourConductedExplanation}
                onChange={handleInput2Change}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Activities;
