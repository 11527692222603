import React, { useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { GetLanguageString } from "../helper/Components";
import { LoadingSpinner, isUrdu } from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";
import { ApiUnit } from "./ApiUnit";
import swal from "sweetalert";
import { GetSelectedLanguage } from "../helper/Method";
import { useTranslation } from "react-i18next";
import Draggable from "react-draggable";

const DeleteUnit = ({
  id,
  isChildUnit,
  isUnitMember,
  setDeleteUnitModal,
  setUnit,
}) => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));

  const { t } = useTranslation(); // Use to translate label in different language
  const [values, setValues] = useState({
    id: id,
    loggedInUnitId: loggedInUnitId,
    referenceNote: "",
  });
  const toggle = () => {
    setDeleteUnitModal(false);
  };
  const [loading, setLoading] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setValues({
      ...values,
      referenceNote: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isChildUnit) {
      swal({
        title: t("unit_child_unit_title_message"),
        className: GetSelectedLanguage() === "en" ? "" : "ur",
        text: t("unit_child_unit_text_message"),
        icon: "warning",
      });
    } else if (isUnitMember) {
      swal({
        title: t("unit_unit_member_title_message"),
        className: GetSelectedLanguage() === "en" ? "" : "ur",
        text: t("unit_unit_member_text_message"),
        icon: "warning",
      });
    } else {
      swal({
        title: t("common_delete_message"),
        className: GetSelectedLanguage() === "en" ? "" : "ur",
        text: t("common_delete_detail_message"),
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setLoading(true);
          setButtonDisable(true);
          ApiUnit()
            .deleteUnit(values)
            .then((res) => {
              swal({
                title: t("unit_deleted"),
                className: GetSelectedLanguage() === "en" ? "" : "ur",
                icon: "success",
              });
              if (res.data.unitList !== null) setUnit(res.data.unitList);
              else setUnit([]);
              toggle();
              setLoading(false);
              setButtonDisable(false);
            })
            .catch((err) => {
              toggle();
              swal({
                title: t(err.response.data),
                icon: "error",
                buttons: "OK",
              });
              console.log(err);
              setLoading(false);
              setButtonDisable(false);
            });
        }
      });
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="unit_delete_unit" />}
          </ModalHeader>
          <Form onSubmit={handleSubmit}>
            <ModalBody>
              {!loading ? (
                <div className="ibox-content">
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="member_reference_note" />}
                    </Label>
                    <Input
                      type="text"
                      name="referenceNote"
                      className={
                        isUrdu(values.referenceNote) ? "ur no-drag" : "no-drag"
                      }
                      value={values.referenceNote}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </div>
              ) : (
                LoadingSpinner()
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="white" onClick={toggle}>
                {<GetLanguageString props="common_cancel" />}
              </Button>
              <Button color="primary" type="submit" disabled={buttonDisable}>
                {<GetLanguageString props="common_save_changes" />}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </Draggable>
    </div>
  );
};

export default DeleteUnit;
