import React, { useEffect, useState } from "react";

import { addMember } from "../../member/ApiMember";
import ImageDisplay from "../../helper/ImageDisplay";

const SaveCropFile = () => {
    const [image, setImage] = useState(null);
  const [config, setConfig] = useState(null);
  const [memberPhotoId, setMemberPhotoId] = useState(null);

  useEffect(() => {
    const fetchImageToCrop = async () => {
      try {
        const res = await addMember().getImageToCrop();
        if (res.data) {
            setImage(`data:image/jpeg;base64,${res.data.image}`);
          setConfig({
            x: res.data.config.x,
            y: res.data.config.y,
            scaleX: res.data.config.scaleX,
            scaleY: res.data.config.scaleY,
            width: res.data.config.width,
            height: res.data.config.height,
            scaleWidth: res.data.config.scaleWidth,
            scaleHeight: res.data.config.scaleHeight,
          });
          setMemberPhotoId(res.data.memberPhotoId);
        } 
      } catch (err) {
        console.error(err);
      }
    };

    if(image === null && config === null){
      fetchImageToCrop();
    }
    
  }, [image, config]);

  return <>
    {image && config ?  
      <CropFile 
        image={image} 
        config={config} 
        memberPhotoId={memberPhotoId} 
        setConfig={setConfig} 
        setImage={setImage}
      /> : null}
    
  </>;
};

const CropFile = ({image, config, memberPhotoId, setConfig, setImage}) => {
const [croppedImage, setCroppedImage] = useState(null);

useEffect(() => {
  const saveCroppedImage = async () => {
    if (croppedImage && memberPhotoId) {
      try {
        const res = await fetch(croppedImage);
        const blob = await res.blob();
        const formData = new FormData();
        formData.append("imageFile", blob);
        var res2 = await addMember().saveCropImage(memberPhotoId, formData);
        if(res2){
          setImage(null);
          setConfig(null);
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  saveCroppedImage();
}, [croppedImage, memberPhotoId, setConfig, setImage]);

return <>
  {image && config && (
      <ImageDisplay
      imageToCrop={image}
      config={config}
      onImageCropped={(croppedImage, cropConfig) => {
          setCroppedImage(croppedImage);
          setConfig(cropConfig);
      }}
      autoCrop={true}
      />
  )}
</>;
};

export default SaveCropFile;
