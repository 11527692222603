import axios from "axios";

import { BASEURL } from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";

export function ApiUnit() {
  const userToken = encryptStorage.getItem("token");

  const authAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const fileAuthAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    responseType: "arraybuffer",
  });

  const url = BASEURL + "unit/";
  return {
    addUnit: (newRecord) => authAxios.post(url + "add/", newRecord),
    getUnit: (id, loggedInUnitId, excludeExUnit = false, record = {}) =>
      authAxios.post(url + "getunit/" + id + "/" + loggedInUnitId + "/" + excludeExUnit, record),
    smsStatus: (id, loggedInUnitId) =>
      authAxios.get(url + "changeSMSStauts/" + id + "/" + loggedInUnitId),
    getUnitById: (id, unitTypeId) =>
      authAxios.get(url + "getunitbyid/" + id + "/" + unitTypeId),
    getUnitList: (id, loggedInUnitId, isDeleted, unitTypeName, excludeExUnit = false, dateTime = new Date().toISOString()) =>
      authAxios.get(
        url +
          "getunitlists/" +
          id +
          "/" +
          loggedInUnitId +
          "/" +
          isDeleted +
          "/" +
          unitTypeName +
          "/" +
          excludeExUnit +
          "/" + 
          dateTime
      ),
    updateUnit: (id, loggedInUnitId, updateRecord) =>
      authAxios.put(url + "putunit/" + id + "/" + loggedInUnitId, updateRecord),
    deleteUnit: (record) => authAxios.post(url + "deleteunit/", record),
    getUnitMonthlyReport: (searchModel) =>
      authAxios.post(url + "getUnitMonthlyReport/", searchModel),
    checkIsValidChild: (id, loggedInUnitId) =>
      authAxios.get(url + "checkIsValidChild/" + id + "/" + loggedInUnitId),
    getChildUnitMembersCount: (searchValue) =>
      authAxios.post(url + "getChildUnitMembersCount/", searchValue),
    getChildUnitMembersCountReportExcel: (reportData, unitId) =>
      fileAuthAxios.post(
        url + "GenerateChildUnitMembersCountReport/" + unitId,
        reportData
      ),
    getUnitCummulativeStats: (id) =>
      authAxios.get(url + "getUnitCummulativeStats/" + id),
    getImmediateChildUnitsDetails: (id) =>
      authAxios.get(url + "getImmediateChildUnitsDetails/" + id),
    createMonthlyReport: (createReportModel) =>
      authAxios.post(url + "createMonthlyReport/", createReportModel),
    getUnitMonthlyReportExcel: (reportData) =>
      fileAuthAxios.post(url + "GenerateUnitMonthlyReport/", reportData),

    GetUnitsForNotificationFilters: (responsibilityId, loggedInUnitId) =>
      authAxios.get(
        url +
          "GetUnitsForNotificationFilters/" +
          responsibilityId +
          "/" +
          loggedInUnitId
      ),
    GetExTypeUnits: () => authAxios.get(url + "GetExTypeUnits/"),
    MarkRafiqAsEx: (record) => authAxios.put(url + "MarkRafiqAsEx/", record),
    MoveUnit: (record) => authAxios.post(url + "MoveUnit/", record),
  };
}

export function ApiUnitMember() {
  const userToken = encryptStorage.getItem("token");

  const authAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const url = BASEURL + "unitmember/";
  return {
    moveUnitMembers: (unitMembers) =>
      authAxios.post(url + "replaceUnitMembers/", unitMembers),
    moveMemberDataSave: (record) =>
      authAxios.put(url + "moveMembersDataSave/", record),
  };
}
