import React, { useState, useEffect } from 'react';
import { Button, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { ApiTutorials } from "./ApiTutorials";
import DatePicker from "react-datepicker";
import AddTutorial from "./AddTutorial";
import { GetCustomStyles } from "../helper/Method";
import { GetLanguageString } from '../helper/Components';
import Select from "react-select";
import VideoTutorials from './Tutorials';
import { convertUTCToLocalTime } from '../../constants/const';

const SearchTutorials = () => {
  const [values, setValues] = useState([]);
  const [formData, setFormData] = useState({
    category: {},
    searchKey: "",
    startDate: null,
    endDate: null
  });
  const [search, setSearch] = useState(true)
  const [category, setCategory] = useState();
  const [selectedCategory, setSelectedCategory] = useState({});
  const [loading, setLoading] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [showTutorials, setShowTutorials] = useState(false);
  const [ refresh, setRefresh ] = useState(false);

  useEffect(() => {
    setLoading(true);
    ApiTutorials()
    .getCategory()
    .then((res) => {
      setCategory(res.data);
      setLoading(false);
    })
    .catch((err) => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if(search){
      setShowTutorials(true);
      setLoading(true);
      ApiTutorials()
      .searchTutorialsModel(formData)
      .then((res) => {
        setValues(res.data);
        setLoading(false);
        setSearch(false);
      })
      .catch((err) => {
        setLoading(false);
      });
      }
    }, [formData, search, refresh])

  const handleSubmit = (e) => {
    e.preventDefault();
    setSearch(true);
  };

  const handleTutorialCategory = (record) => {
    setFormData({
      ...formData,
      category: record !== null ? record : {}
    });
    setSelectedCategory(record);
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  const handleInputChangeStartDate = (value) => {
    setFormData({
      ...formData,
      startDate: value ? new Date(convertUTCToLocalTime(value)) : null,
    });
  }

  const handleInputChangeEndDate = (value) => {
    setFormData({
      ...formData,
      endDate: value ? new Date(convertUTCToLocalTime(value)) : null,
    });
  }

  const handleAdd = () => {
    setShowAddForm(true);
  };

  return (
    <>
    <br />
    <div className="ibox">
      { showAddForm ? (
        <AddTutorial
        setShowAddForm={setShowAddForm}
        refresh={refresh}
        setRefresh={setRefresh}
        setSearch={setSearch}
        />
      ) : null }
      <div className="ibox-title">
          <h5>
            <GetLanguageString props="search_video_tutorial" />
          </h5>
          <Button 
            color="primary"
            size="xs"
            className={GetCustomStyles().btn_style}
            onClick={handleAdd}
          >
            <GetLanguageString props="video_tutorial_add_tutorial" />
          </Button>
      </div>
      <div className="ibox-content">
        <Row>
          <Col md="6">
            <FormGroup>
              <Label>
                <GetLanguageString props="video_tutorial_category" />
              </Label>
                <Select
                  options={category}
                  value={selectedCategory}
                  className="basic-single"
                  isClearable={true}
                  onChange={handleTutorialCategory}
                />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>
                <GetLanguageString props="search_in_tutorial" />
              </Label>
                <Input
                  type="text"
                  value={formData.searchKey}
                  name='searchKey'
                  onChange={handleInputChange}
                />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="3">              
            <FormGroup>
              <Label className="col-form-label">
                {<GetLanguageString props="member_member_detail_course_start_date" />}
              </Label>
              <DatePicker
                showYearDropdown
                dropdownMode="select"
                placeholderText="dd/mm/yyyy"
                className="form-control"
                selected={formData.startDate}
                onChange={handleInputChangeStartDate}
                dateFormat="dd/MM/yyyy"
              />
              </FormGroup>
          </Col>
          <Col md="3">              
            <FormGroup>
              <Label className="col-form-label">
                {<GetLanguageString props="member_member_detail_course_end_date" />}
              </Label>
              <DatePicker
                showYearDropdown
                dropdownMode="select"
                placeholderText="dd/mm/yyyy"
                className="form-control"
                selected={formData.endDate}
                onChange={handleInputChangeEndDate}
                dateFormat="dd/MM/yyyy"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <FormGroup>
              <Button
                color="primary"
                className="m-l-sm"
                type="submit"
                onClick={handleSubmit}
              >
                {<GetLanguageString props="common_search" />}
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </div>
      { showTutorials ? (
        <VideoTutorials 
        values = {values}
        setValues = {setValues}
        loading = {loading} 
        setLoading = {setLoading}
        refresh={refresh}
        setRefresh={setRefresh}
        setSearch={setSearch}
      />
      ) : null }
    </>
  );
};

export default SearchTutorials;
