import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";

import { GetLanguageString, NoResult } from "../helper/Components";
import { GetCustomStyles } from "../helper/Method";
import { ApiDashboard } from "./ApiDashboard";
import { LoadingSpinner } from "../../constants/const";

const MemberDetail = ({ tempUnit, tab2 }) => {
  const [loading, setLoading] = useState(false);
  const [memberColSize, setMemberColSize] = useState(0);
  const [unitMemberType, setUnitMemberType] = useState([]);

  useEffect(() => {
    if(tab2 === "active"){
        setLoading(true);
        if(tempUnit.value !== 0){
          ApiDashboard()
            .getDashboardMemberDetails(tempUnit.value)
            .then((res) => {
              setLoading(false);
              setUnitMemberType(res.data.unitMemberTypeDetails);
              let tempMemberlength = Math.floor(
                12 / res.data.unitMemberTypeDetails.length
              );
              if (tempMemberlength >= 2) setMemberColSize(tempMemberlength);
              else setMemberColSize(2);
            })
            .catch((err) => {
              console.log("err ", err);
              setLoading(false);
            });
        }
    }
  }, [tempUnit, tab2]);

  return (
    <>
      <div role="tabpanel" id="tab-2" className={"tab-pane " + tab2}>
        <div className="panel-body">
        {!loading ? (
            <Row>
              {unitMemberType.length !== 0
                ? unitMemberType.map((record, index) => {
                    return (
                      <Col xl={memberColSize} key={index}>
                        <div className="ibox">
                          <div className="ibox-title">
                            <div className={GetCustomStyles().btn_style}>
                              <span className={"label label-info float-right"}>
                                {
                                  <GetLanguageString props="dashboard_members_type_details_rufaqa" />
                                }
                              </span>
                            </div>
                            <h5 className="ur">{record.memberTypeName}</h5>
                          </div>
                          <div className="ibox-content">
                            <h1 className="no-margins">
                              {record.membersCount}
                            </h1>
                            <div
                              className={
                                GetCustomStyles().btn_style +
                                " font-bold text-info"
                              }
                            >
                              {record.membersPercentage}%
                            </div>
                            <small className="ur">{record.memberTypeName} </small>
                          </div>
                        </div>
                      </Col>
                    );
                  })
                : <NoResult />}
            </Row>
          ) : (
            LoadingSpinner()
          )}
        </div>
      </div>
    </>
  );
};

export default MemberDetail;
