import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { GetLanguageString } from "../helper/Components";
import swal from "sweetalert";
import {
  CheckPermission,
  LoadingSpinner,
  convertUTCToLocalTime,
  isUrdu,
} from "../../constants/const";
import SelectUnitForMember from "../unit/SelectUnitForMember";
import { useTranslation } from "react-i18next";
import { GetSelectedLanguage } from "../helper/Method";
import moment from "moment";
import { ApiNotifications } from "./ApiNotifications";
import Draggable from "react-draggable";

const EditMoveMemberNotificationModal = ({
  notificationId,
  memberId,
  unitId,
  check,
  setMoveMemberModal,
  setupdateList,
  updateList,
}) => {
  const [setErrors] = useState({});
  const selectedLanguage = GetSelectedLanguage();
  const [values, setValues] = useState({
    unit: { value: 0, lable: "", unitName: "" },
  });
  const [buttonDisable, setButtonDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [memberResponsibilities, setMemberResponsibilities] = useState([]);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  const [unitMembers, setUnitMembers] = useState({
    memberId: memberId,
    previousUnitId: unitId,
    status: false,
    newUnitId: values.unit.value,
    newAddress: "",
    referenceNote: "",
    nisabBaraEMutaliya: "",
    taluqMaAllah: "",
    samaOTaat: "",
    samaOBasar: "",
    infradiDawat: "",
    jihadBilMaal: "",
    memberResponsibiities: [],
    otherInfo:""
  });

  const [errorsFields, setErrorsFields] = useState(unitMembers);
  useEffect(() => {
    setLoading1(true);
    ApiNotifications()
      .EditData(notificationId)
      .then((res) => {
        setUnitMembers(res.data.moveMember);
        setMemberResponsibilities(res.data.moveMember.memberResponsibiities);
        setLoading1(false);
      })
      .catch((err) => {
        console.log(err);
        swal({
          title: "Error: " + err.response?.data || err,
          icon: "error",
          buttons: "OK",
        });
        setLoading1(false);
      });
  }, [notificationId]);

  const handleResponsibilitiesDelete = (e, index) => {
    const { checked } = e.target;
    let tempValues = [...memberResponsibilities];
    tempValues[index].delete = checked;
    setMemberResponsibilities(tempValues);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setUnitMembers({
      ...unitMembers,
      [name]: value,
    });
    setErrorsFields({
      ...errorsFields,
      [name]: "",
    });
  };
  const { t } = useTranslation();
  const toggle = () => setMoveMemberModal(false);
  const validate = () => {
    let temp1 = {};
    if (
      CheckPermission("CanRequestMoveMembersOutsideTransfer") &&
      check === "Outside"
    ) {
      temp1.newAddress =
        unitMembers.newAddress && unitMembers.newAddress !== null ? (
          ""
        ) : (
          <GetLanguageString props="member__address_error_message" />
        );

      temp1.nisabBaraEMutaliya =
        unitMembers.nisabBaraEMutaliya && unitMembers.newAddress !== null ? (
          ""
        ) : (
          <GetLanguageString props="member_move_field_Required" />
        );
      temp1.taluqMaAllah =
        unitMembers.taluqMaAllah && unitMembers.newAddress !== null ? (
          ""
        ) : (
          <GetLanguageString props="member_move_field_Required" />
        );
      temp1.samaOTaat =
        unitMembers.samaOTaat !== "" && unitMembers.samaOTaat !== null ? (
          ""
        ) : (
          <GetLanguageString props="member_move_field_Required" />
        );
      temp1.samaOBasar =
        unitMembers.samaOBasar && unitMembers.newAddress !== null ? (
          ""
        ) : (
          <GetLanguageString props="member_move_field_Required" />
        );
      temp1.infradiDawat =
        unitMembers.infradiDawat && unitMembers.newAddress !== null ? (
          ""
        ) : (
          <GetLanguageString props="member_move_field_Required" />
        );
      temp1.jihadBilMaal =
        unitMembers.jihadBilMaal && unitMembers.newAddress !== null ? (
          ""
        ) : (
          <GetLanguageString props="member_move_field_Required" />
        );
    }
    setErrorsFields({
      ...temp1,
    });
    if (Object.values(temp1).every((x) => x === "")) return true;
    else return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (unitMembers.memberResponsibiities !== null) {
      unitMembers.memberResponsibiities = memberResponsibilities.filter(
        (item) => item.delete
      );
    }
    if (validate()) {
      setButtonDisable(true);
      setLoading(true);
      ApiNotifications()
        .EditMoveMemberNotificationRequest(notificationId, unitMembers)
        .then((res) => {
          swal({
            title: t(res.data),
            icon: "success",
            buttons: "OK",
            className: selectedLanguage === "en" ? "en" : "ur",
          });
          setLoading(false);
          setupdateList(!updateList);
          toggle();
        })
        .catch((err) => {
          swal({
            title: "Error: " + err.response?.data || err,
            icon: "error",
            buttons: "OK",
          });
          setLoading(false);
          setButtonDisable(false);
          console.log(err);
        });
    }
  };
  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal
          isOpen={true}
          size="lg"
          style={{ maxWidth: "1080px", width: "100%" }}
          className="inmodal"
        >
          <>
            <ModalHeader className="modal-title" tag="h4">
              {<GetLanguageString props="edit_move_member_notification" />}
            </ModalHeader>
            {!loading1 ? (
              <Form>
                <ModalBody>
                  {!loading ? (
                    <>
                      <div className="ibox-title">
                        {CheckPermission(
                          "CanRequestMoveMembersOutsideTransfer"
                        ) && check === "Outside" ? (
                          <h5>
                            {
                              <GetLanguageString props="member_move_select_unit_and_address" />
                            }
                          </h5>
                        ) : (
                          <h5>
                            {<GetLanguageString props="member_select_unit" />}
                          </h5>
                        )}
                      </div>
                      <div className="ibox-content">
                        <SelectUnitForMember
                          values={values}
                          setValues={setValues}
                          setErrors={setErrors}
                          unitId={unitMembers.newUnitId}
                          check={check}
                          edit={true}
                          setLoading={setLoading1}
                        />
                        {CheckPermission(
                          "CanRequestMoveMembersOutsideTransfer"
                        ) && check === "Outside" ? (
                          <>
                            <Row>
                              <Col md="6">
                                <FormGroup>
                                  <Label className="col-form-label">
                                    {
                                      <GetLanguageString props="member_new_address" />
                                    }
                                  </Label>
                                  <Input
                                    className={
                                      isUrdu(unitMembers.newAddress) ? "ur no-drag" : ""
                                    }
                                    type="text"
                                    name="newAddress"
                                    value={unitMembers.newAddress}
                                    onChange={handleInputChange}
                                    {...(errorsFields.newAddress && {
                                      invalid: true,
                                    })}
                                  />
                                  {errorsFields.newAddress && (
                                    <FormFeedback>
                                      <h4>*{errorsFields.newAddress}</h4>
                                    </FormFeedback>
                                  )}
                                </FormGroup>
                              </Col>
                              <Col md="6">
                                {" "}
                                <FormGroup>
                                  <Label className="col-form-label">
                                    {
                                      <GetLanguageString props="member_reference_note" />
                                    }
                                  </Label>
                                  <Input
                                    className={
                                      isUrdu(unitMembers.newAddress) ? "ur no-drag" : ""
                                    }
                                    type="text"
                                    name="referenceNote"
                                    value={unitMembers.referenceNote}
                                    onChange={handleInputChange}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </>
                        ) : null}
                      </div>
                      {CheckPermission("CanRequestMoveMembersOutsideTransfer") &&
                      check === "Outside" ? (
                        <>
                          <div className="ibox-title">
                            <h3 className="ur">کیفیت رفیق</h3>
                          </div>
                          <div className="ibox-content">
                            <Row>
                              <Col md="6">
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <h4
                                      className="ur"
                                      style={{ fontWeight: "lighter" }}
                                    >
                                      نصاب برائے مطالعہ / سماعت کی موجودہ کیفیت
                                      (مبتدی، ملتزم، ذمہ داران، مدرسین)
                                    </h4>
                                  </Label>
                                  <Input
                                    className={
                                      isUrdu(unitMembers.nisabBaraEMutaliya)
                                        ? "ur no-drag"
                                        : "no-drag"
                                    }
                                    type="text"
                                    name="nisabBaraEMutaliya"
                                    value={unitMembers.nisabBaraEMutaliya}
                                    onChange={handleInputChange}
                                    {...(errorsFields.nisabBaraEMutaliya && {
                                      invalid: true,
                                    })}
                                  />
                                  {errorsFields.nisabBaraEMutaliya && (
                                    <FormFeedback>
                                      <h4>{errorsFields.nisabBaraEMutaliya}</h4>
                                    </FormFeedback>
                                  )}
                                </FormGroup>
                              </Col>
                              <Col md="6">
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <h4
                                      className="ur"
                                      style={{ fontWeight: "lighter" }}
                                    >
                                      تعلق مع اللہ (عبادات، اخلاقیات، معاملات)
                                    </h4>
                                  </Label>
                                  <Input
                                    className={
                                      isUrdu(unitMembers.taluqMaAllah) ? "ur no-drag" : ""
                                    }
                                    type="text"
                                    name="taluqMaAllah"
                                    value={unitMembers.taluqMaAllah}
                                    onChange={handleInputChange}
                                    {...(errorsFields.taluqMaAllah && {
                                      invalid: true,
                                    })}
                                  />
                                  {errorsFields.taluqMaAllah && (
                                    <FormFeedback>
                                      <h4>{errorsFields.taluqMaAllah}</h4>
                                    </FormFeedback>
                                  )}
                                </FormGroup>
                              </Col>
                              <Col md="6">
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <h4
                                      className="ur"
                                      style={{ fontWeight: "lighter" }}
                                    >
                                      سمع و طاعت (تنظیمی اجتماعات میں حاضری کی
                                      کیفیت)
                                    </h4>
                                  </Label>
                                  <Input
                                    className={
                                      isUrdu(unitMembers.samaOTaat) ? "ur no-drag" : ""
                                    }
                                    type="text"
                                    name="samaOTaat"
                                    value={unitMembers.samaOTaat}
                                    onChange={handleInputChange}
                                    {...(errorsFields.samaOTaat && {
                                      invalid: true,
                                    })}
                                  />
                                  {errorsFields.samaOTaat && (
                                    <FormFeedback>
                                      <h4>{errorsFields.samaOTaat}</h4>
                                    </FormFeedback>
                                  )}
                                </FormGroup>
                              </Col>
                              <Col md="6">
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <h4
                                      className="ur"
                                      style={{ fontWeight: "lighter" }}
                                    >
                                      سمع و بصر (جرائد و دیگر کتب کا مطالعہ اور
                                      امیر محترم کے خطبات جمعہ کا مطالعہ یا سماعت)
                                    </h4>
                                  </Label>
                                  <Input
                                    className={
                                      isUrdu(unitMembers.samaOBasar) ? "ur no-drag" : ""
                                    }
                                    type="text"
                                    name="samaOBasar"
                                    value={unitMembers.samaOBasar}
                                    onChange={handleInputChange}
                                    {...(errorsFields.samaOBasar && {
                                      invalid: true,
                                    })}
                                  />
                                  {errorsFields.samaOBasar && (
                                    <FormFeedback>
                                      <h4>{errorsFields.samaOBasar}</h4>
                                    </FormFeedback>
                                  )}
                                </FormGroup>
                              </Col>
                              <Col md="6">
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <h4
                                      className="ur"
                                      style={{ fontWeight: "lighter" }}
                                    >
                                      انفرادی دعوت / درس میں شرکت / تدریس کا
                                      اہتمام
                                    </h4>
                                  </Label>
                                  <Input
                                    className={
                                      isUrdu(unitMembers.infradiDawat) ? "ur no-drag" : ""
                                    }
                                    type="text"
                                    name="infradiDawat"
                                    value={unitMembers.infradiDawat}
                                    onChange={handleInputChange}
                                    {...(errorsFields.infradiDawat && {
                                      invalid: true,
                                    })}
                                  />
                                  {errorsFields.infradiDawat && (
                                    <FormFeedback>
                                      <h4>{errorsFields.infradiDawat}</h4>
                                    </FormFeedback>
                                  )}
                                </FormGroup>
                              </Col>
                              <Col md="6">
                                <FormGroup>
                                  <Label className="col-form-label">
                                    <h4
                                      className="ur"
                                      style={{ fontWeight: "lighter" }}
                                    >
                                      جہاد بالمال خصوصا انفاق فی سبیل للہ کی کیفیت
                                    </h4>
                                  </Label>
                                  <Input
                                    className={
                                      isUrdu(unitMembers.jihadBilMaal) ? "ur no-drag" : ""
                                    }
                                    type="text"
                                    name="jihadBilMaal"
                                    value={unitMembers.jihadBilMaal}
                                    onChange={handleInputChange}
                                    {...(errorsFields.jihadBilMaal && {
                                      invalid: true,
                                    })}
                                  />
                                  {errorsFields.jihadBilMaal && (
                                    <FormFeedback>
                                      <h4>{errorsFields.jihadBilMaal}</h4>
                                    </FormFeedback>
                                  )}
                                </FormGroup>
                              </Col>
                              {/* Field for other info */}
                          <Col md="6">
                          <FormGroup>
                              <Label className="col-form-label">
                                <h4
                                  className="ur"
                                  style={{ fontWeight: "lighter" }}
                                >
                                  دیگر تفصیلات
                                </h4>
                              </Label>
                              <Input
                                type="text"
                                name="otherInfo"
                                className="no-drag"
                                value={unitMembers.otherInfo}
                                onChange={handleInputChange}
                              />
                            </FormGroup>
                          </Col>
                            </Row>
                          </div>
                        </>
                      ) : null}
                      {memberResponsibilities?.length !== 0 &&
                      memberResponsibilities !== null ? (
                        <>
                          <div className="ibox-title">
                            <h3 className="text-error">
                              {(CheckPermission(
                                "CanRequestMoveMembersLocalTransfer"
                              ) &&
                                check === "Local") ||
                              (CheckPermission(
                                "CanRequestMoveMembersOutsideTransfer"
                              ) &&
                                check === "Outside") ? (
                                <GetLanguageString props="move_member_note_remove_responsibility_request_edit" />
                              ) : (
                                <GetLanguageString props="move_member_note_remove_responsibility" />
                              )}
                            </h3>
                          </div>
                          <div className="ibox-content">
                            <div>
                              <Table responsive>
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th>#</th>
                                    <th className="text-center">
                                      {
                                        <GetLanguageString props="member_member_detail_common_responsibility" />
                                      }
                                    </th>
                                    <th className="text-center">
                                      {
                                        <GetLanguageString props="dashboard_event_page_unit_name" />
                                      }
                                    </th>
                                    <th className="text-center">
                                      {
                                        <GetLanguageString props="dashboard_event_page_unit_type" />
                                      }
                                    </th>
                                    <th className="text-center">
                                      {
                                        <GetLanguageString props="member_member_detail_common_responsibility_date" />
                                      }
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {memberResponsibilities?.map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>
                                          {" "}
                                          <Input
                                            id={"delete" + index}
                                            type="checkbox"
                                            checked={item.delete}
                                            onChange={(e) =>
                                              handleResponsibilitiesDelete(
                                                e,
                                                index
                                              )
                                            }
                                          />
                                        </td>
                                        <td>{index + 1}</td>
                                        <td className="text-center ur no-drag">
                                          {item.responsibilityName}
                                        </td>
                                        <td className="text-center ur no-drag">
                                          {item.unitName}
                                        </td>
                                        <td className="text-center">
                                          {
                                            <Label
                                              size="xs"
                                              color="info"
                                              className="label label-info m-r-xs"
                                              onClick={() => {}}
                                            >
                                              <h5 className="ur no-drag">
                                                {item.unitType}
                                              </h5>
                                            </Label>
                                          }
                                        </td>
                                        <td className="text-center no-drag">
                                          {moment(
                                            convertUTCToLocalTime(
                                              item.responsibilityDate
                                            )
                                          ).format("DD/MM/yyyy")}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </>
                  ) : (
                    LoadingSpinner()
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button color="white" onClick={toggle}>
                    {<GetLanguageString props="common_cancel" />}
                  </Button>
                  <Button
                    color="primary"
                    disabled={buttonDisable}
                    type="submit"
                    onClick={handleSubmit}
                  >
                    {<GetLanguageString props="common_save_changes" />}
                  </Button>
                </ModalFooter>
              </Form>
            ) : (
              LoadingSpinner()
            )}
          </>
        </Modal>
      </Draggable>
    </div>
  );
};

export default EditMoveMemberNotificationModal;
