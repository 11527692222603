import moment from "moment";
import { Table } from "reactstrap";
import { convertUTCToLocalTime } from "../../constants/const";
import { GetLanguageString } from "../helper/Components";

const TableView = ({ data }) => {
  return (
    <Table responsive>
      <thead>
        <tr>
          <th>#</th>
          <th className="text-center">
            {<GetLanguageString props="member_reg_number" />}
          </th>
          <th className="text-center">
            {<GetLanguageString props="member_first_name" />}
          </th>
          <th className="text-center">
            {<GetLanguageString props="member_fathers_name" />}
          </th>
          <th className="text-center">
            {<GetLanguageString props="member_date_of_birth" />}
          </th>
          <th className="text-center">
            {<GetLanguageString props="member_cnic" />}
          </th>
          <th className="text-center">
            {<GetLanguageString props="member_phone" />}
          </th>
        </tr>
      </thead>
      <tbody>
        {data?.map((item, index) => {
          return (
            <tr key={index}>
              <td>{index + 1}</td>
              <td className="text-center">{item.regNo}</td>
              <td className="text-center ur">{item.name}</td>
              <td className="text-center ur">{item.fatherName}</td>
              <td className="text-center">
                {moment(convertUTCToLocalTime(item.dateOfBirth)).format(
                  "DD/MM/yyyy"
                )}
              </td>
              <td className="text-center">
                {item.cnic?.replace(/(\d{5})(\d{7})(\d{1})/, "$1-$2-$3")}
              </td>
              <td className="text-center">{item.phone}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default TableView;
