import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, ButtonGroup, Table } from "reactstrap";
import moment from "moment";

import { GetLanguageString } from "../../helper/Components";
import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import { addMember } from "../ApiMember";
import {
  LoadingSpinner,
  convertUTCToLocalTime,
  isUrdu,
} from "../../../constants/const";
import ChangeMemberUnit from "./ChangeMemberUnit";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
// import AddUnitByUnit from "./AddUnitByUnit";
// import DeleteUnit from "./DeleteUnit";
// import EditMemberUnit from "./EditMemberUnit";

const MemberUnitList = ({ tab9, memberId, addNewRafiq, setUpdatedDetails }) => {
  const [unitStateChange, setUnitStateChange] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [previousUnitId, setPreviousUnitId] = useState(false);
  const [units, setUnits] = useState([]);
  const [unitNames, setUnitNames] = useState([]);
  const [editUnit, setEditUnit] = useState({});
  const [addUnitModal, setAddUnitModal] = useState(false);
  const [editUnitModal, setEditUnitModal] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (tab9 === "active") {
      setLoading1(true);
      addMember()
        .GetMemberUnitsList(memberId)
        .then((res) => {
          let tempNames = [];
          let previousUnitId = null;

          res.data.forEach((element) => {
            tempNames.push({
              names: element.unitNameBreadCrumbs.split("،"),
            });

            if (element.isCurrent) {
              previousUnitId = element.id;
            }
          });

          setUnitNames(tempNames);
          setPreviousUnitId(previousUnitId);
          setUnits(res.data);
          setLoading1(false);
        })
        .catch((err) => {
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
          setLoading1(false);
        });
    }
  }, [memberId, unitStateChange, tab9]);

  const handleOnDelete = (record) => {
    swal({
      title: t("common_delete_message"),
      text: t("common_delete_detail_message"),
      icon: "warning",
      className: GetSelectedLanguage() === "en" ? "" : "ur",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading1(true);
        addMember()
          .DeleteMemberUnit({
            memberId: memberId,
            isCurrent: record.isCurrent,
            memberUnitId: record.id,
          })
          .then((res) => {
            swal({
              title: t("member__member_unit_delete_success_message"),
              className: GetSelectedLanguage() === "en" ? "" : "ur",
              icon: "success",
              buttons: "OK",
            });
            setLoading1(false);
            setUnitStateChange(!unitStateChange);
            setUpdatedDetails((prevState) => !prevState);
          })
          .catch((err) => {
            console.log(err);
            swal({
              title: err,
              icon: "error",
              buttons: "OK",
            });
            setLoading1(false);
          });
      }
    });
  };
  
  return (
    <>
      {editUnitModal ? (
        <ChangeMemberUnit
          setChangeUnitModal={setEditUnitModal}
          memberId={memberId}
          memberUnitData={editUnit}
          previousUnitId={previousUnitId}
          addNewRafiq={addNewRafiq}
          setUpdatedDetails={setUpdatedDetails}
          setUnitStateChange={setUnitStateChange}
          edit={true}
        />
      ) : null}
      {addUnitModal ? (
        <ChangeMemberUnit
          setChangeUnitModal={setAddUnitModal}
          memberUnitData={null}
          memberId={memberId}
          previousUnitId={previousUnitId}
          addNewRafiq={addNewRafiq}
          setUpdatedDetails={setUpdatedDetails}
          setUnitStateChange={setUnitStateChange}
        />
      ) : null}
      <div className="panel-body">
        <div className="panel panel-primary">
          <div className="panel-heading">
            <Button
              color="dark"
              size="xs"
              className={GetCustomStyles().btn_style}
              onClick={() => setAddUnitModal(true)}
            >
              {<GetLanguageString props="member_member_detail_chnage_unit" />}
            </Button>
            {<GetLanguageString props="member__member_unit" />}
          </div>
          <div className="panel-body">
            {!loading1 ? (
              units?.length !== 0 ? (
                <Table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>
                        {
                          <GetLanguageString props="member_member_detail_common_unit_name" />
                        }
                      </th>
                      <th>
                        {
                          <GetLanguageString props="member_member_detail_common_unit_date" />
                        }
                      </th>
                      <th>
                        {<GetLanguageString props="common_description" />}
                      </th>
                      <th>
                        {<GetLanguageString props="member_reference_note" />}
                      </th>
                      <th className="text-right">
                        {<GetLanguageString props="common_action" />}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {units?.map((record, index) => {
                      return (
                        <tr
                          key={index}
                          style={{
                            backgroundColor: record.isCurrent ? "#AFF1DD" : "",
                          }}
                        >
                          <td>{index + 1}</td>
                          <td className="ur">
                            <ol
                              className="breadcrumb"
                              style={{
                                backgroundColor: record.isCurrent
                                  ? "#AFF1DD"
                                  : "",
                              }}
                            >
                              {unitNames[index]?.names?.map((item, index) => {
                                return (
                                  <li
                                    className={
                                      GetSelectedLanguage() === "ur"
                                        ? "breadcrumb-item-ur"
                                        : "breadcrumb-item"
                                    }
                                    key={index}
                                  >
                                    {item}
                                  </li>
                                );
                              })}
                            </ol>
                          </td>
                          <td>
                            {record.unitDate
                              ? moment(
                                  convertUTCToLocalTime(record.unitDate)
                                ).format("DD/MM/yyyy")
                              : "-"}
                          </td>
                          <td
                            className={isUrdu(record.description) ? "ur" : ""}
                          >
                            {record.description}
                          </td>
                          <td
                            className={isUrdu(record.referenceNote) ? "ur" : ""}
                          >
                            {record.referenceNote}
                          </td>
                          <td className="text-right">
                            <ButtonGroup>
                              <Button
                                color="white"
                                size="sm"
                                onClick={() => {
                                  setEditUnit(record);
                                  setEditUnitModal(true);
                                }}
                              >
                                {<GetLanguageString props="common_edit" />}
                              </Button>
                              <Button
                                color="white"
                                size="sm"
                                onClick={() => {
                                  handleOnDelete(record);
                                }}
                              >
                                {<GetLanguageString props="common_delete" />}
                              </Button>
                            </ButtonGroup>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <>
                  <h4 className="text-error text-center">
                    {
                      <GetLanguageString props="common_no_result_responsibilities" />
                    }
                  </h4>
                </>
              )
            ) : (
              LoadingSpinner()
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(MemberUnitList);
