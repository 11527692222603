import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Pagination from "react-js-pagination";
import { Button, ButtonGroup, Label, Table } from "reactstrap";
import swal from "sweetalert";

import { CheckPermission, LoadingSpinner } from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";
import { GetLanguageString, NoResult } from "../helper/Components";
import { addMember } from "../member/ApiMember";
import AdminModal from "./AdminModal";
import { ApiUser } from "./ApiUsers";
import SearchUsers from "./SearchUsers";
import UsersCredential from "./UsersCredential";
import { ApiUnit } from "../unit/ApiUnit";
import { GetCustomStyles, GetFooTableCustomStyles, GetSelectedLanguage } from "../helper/Method";

const initialValues = {
  name: 'neutral',
}

const Users = () => {
  const [loading, setLoading] = useState(false);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const memberId = parseInt(encryptStorage.getItem("memberId"));
  const isIncharge = encryptStorage.getItem("canManage");
  const { t } = useTranslation();
  const [members, setMembers] = useState([]);
  const [modal, setModal] = useState(false);
  const [adminModal, setAdminModal] = useState(false);
  const newPasswordRequest = { regNo: "" };
  const [pagination, setPagination] = useState({ activePage: 1 });
  const [searchButton, setSearchButton] = useState(false);
  const [unit, setUnit] = useState({ unitId: loggedInUnitId, unitName: "" });
  const [values, setValues] = useState({ id: "", username: "", password: "" });
  const [removeAdmin, setRemoveAdmin] = useState(false);
  const [searchMemberModel, setSearchMemberModel] = useState({
    unitId: loggedInUnitId,
    activePage: 1,
    isSubUnits: true,
  });
  const unitTypeName = "null";
  let recordIndex = pagination.pageSize * (pagination.activePage - 1) + 1;
  const [sortObject, setSortObject] = useState(initialValues);

  const handleSorting = (name) => {
    if(sortObject[name] === 'neutral' || sortObject[name] === 'descending'){
      setSortObject({
        ...initialValues,
        [name]:'ascending'
      })
    }else if(sortObject[name] === 'ascending'){
      setSortObject({
        ...initialValues,
        [name]:'descending'
      })
    }
  }

  useEffect(() => {
    if (unit.unitId === 0) {
      ApiUnit()
        .getUnitList(0, loggedInUnitId, false, unitTypeName)
        .then((res) => {
          let temp = res.data[0].unitList[0];
          setSearchMemberModel({
            unitId: temp.id,
            activePage: 1,
          });
          setUnit({ unitId: temp.id, unitName: temp.name });
        })
        .catch((err) => console.log(err));
    }
  }, [unit.unitId, loggedInUnitId]);

  const ApiRequest = useCallback(() => {
    setLoading(true);
    let tempSortObject = {
      isFullNameDescending: sortObject.name === 'descending',
      isFullNameAscending: sortObject.name === 'ascending',
    }
    searchMemberModel.sortModel = tempSortObject;

    addMember()
      .getUserMember(searchMemberModel)
      .then((res) => {
        setMembers(res.data.list);
        setPagination(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [searchMemberModel, sortObject]);

  useEffect(() => {
    ApiRequest();
  }, [ApiRequest]);

  const handlePageChange = (pageNumber) => {
    setSearchMemberModel({
      ...searchMemberModel,
      activePage: pageNumber,
    });
    setPagination({
      ...pagination,
      activePage: pageNumber,
    });
  };

  const handleEnableLogin = (e) => {
    const { value } = e.target;
    let temp = { id: value };
    swal({
      title: t("common_delete_message"),
      className: GetSelectedLanguage() === "en" ? "" : "ur",
      text: t("user_login_will_be_enabled"),
      icon: "success",
      buttons: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        ApiUser()
          .enableUser(temp)
          .then((res) => {
            ApiRequest();
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      }
    });
  };
  const handleOnCreateLogin = (e) => {
    const { value } = e.target;
    let temp = { id: value };
    setLoading(true);
    ApiUser()
      .createUser(temp)
      .then((res) => {
        setValues(res.data);
        ApiRequest();
        setModal(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  

  const handleOnMakeAdmin = (e) => {
    const { value } = e.target;
    setLoading(true);
    ApiUser()
      .makeAdmin(value)
      .then((res) => {
        setAdminModal(true);
        ApiRequest();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleOnRemoveAdmin = (e) => {
    const { value } = e.target;
    setLoading(true);
    ApiUser()
      .removeAdmin(value)
      .then((res) => {
        setRemoveAdmin(true)
        setAdminModal(true);
        ApiRequest();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleOnDisable = (e) => {
    const { value } = e.target;
    let temp = { id: value };
    swal({
      title: t("common_delete_message"),
      className: GetSelectedLanguage() === "en" ? "" : "ur",
      text: t("user_login_will_be_disabled"),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        ApiUser()
          .disableUser(temp)
          .then((res) => {
            ApiRequest();
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      }
    });
  };

  const handleResetPassword = (e) => {
    const { value } = e.target;
    newPasswordRequest.regNo = value;
    ApiUser()
      .resetPassword(newPasswordRequest)
      .then((res) => {
        swal({
          title: t("users_new_password_generated"),
          className: GetSelectedLanguage() === "en" ? "" : "ur",
          text: t("user_password") + " : " + res.data,
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div>
      {modal ? (
        <UsersCredential
          setModal={setModal}
          values={values}
          setValues={setValues}
        />
      ) : null}
      {adminModal ? <AdminModal setModal={setAdminModal} removeAdmin={removeAdmin} setRemoveAdmin={setRemoveAdmin}/> : null}
      <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
        <div className="ibox ">
          <div className="ibox-title">
            <h5>{<GetLanguageString props="user_search_users" />}</h5>
          </div>
          <div className="ibox-content">
            <SearchUsers
              unitId={unit.unitId}
              setUnit={setUnit}
              searchMemberModel={searchMemberModel}
              setSearchMemberModel={setSearchMemberModel}
              setSearchButton={setSearchButton}
            />
          </div>
        </div>
        {searchButton && (
          <div className="ibox ">
            <div className="ibox-title">
              <h5>{<GetLanguageString props="user_search_list" />}</h5>
            </div>
            <div className="ibox-content">
              {!loading ? (
                members.length !== 0 ? (
                  <div className="table-responsive">
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th className='cursor-pointer' onClick={() => handleSorting('name')}>
                            {<GetLanguageString props="common_name" />}
                            <span className={GetFooTableCustomStyles().span_style}>
                              { sortObject.name === 'neutral' ? (
                                <>
                                <i className={GetFooTableCustomStyles().up_down_style} />
                                <i className={GetFooTableCustomStyles().down_up_style} />
                                </>
                              ): sortObject.name === 'ascending' ? 
                              <i className={GetFooTableCustomStyles().up_style} /> : 
                              <i className={GetFooTableCustomStyles().down_style} />
                              }
                            </span>
                          </th>
                          <th>
                            {<GetLanguageString props="member_reg_number" />}
                          </th>
                          <th>
                            {<GetLanguageString props="settings_member_type" />}
                          </th>
                          <th>
                            {<GetLanguageString props="unit_unit_table" />}
                          </th>
                          <th>
                            {<GetLanguageString props="dashboard_event_page_status" />}
                          </th>
                          {isIncharge ? (
                            <th className="text-center">
                              {<GetLanguageString props="common_action" />}
                            </th>
                          ) : null}
                        </tr>
                      </thead>
                      <tbody>
                        {members?.map((record, index) => {
                          return (
                            <tr key={index}>
                              <td className="p-t-custom">{index + recordIndex}</td>
                              <td className="ur p-t-custom">{record.fullName}</td>
                              <td className="p-t-custom">{record.regNo}</td>
                              <td className="ur p-t-custom">{record.memberType}</td>
                              <td>
                                <Button
                                  size="xs"
                                  color="info"
                                  className="label label-info m-r-xs"
                                >
                                  <h5 className="ur">{record.unitName}</h5>
                                </Button>
                              </td>
                              <td>
                                {record.aspNetUsersId == null ? (
                                  "-"
                                ) : record.isUserDisabled ? (
                                  <Label
                                    size="sm"
                                    color="danger"
                                    className="label label-danger m-r-xs"
                                  >
                                    {
                                      <GetLanguageString props="user_account_disabled" />
                                    }
                                  </Label>
                                ) : (
                                  <Label
                                    size="sm"
                                    color="primary"
                                    className="label label-primary m-r-xs"
                                  >
                                    {<GetLanguageString props="user_active" />}
                                  </Label>
                                )}
                              </td>
                              {isIncharge ? (
                                <td className="text-center">
                                  {record.isUserDisabled ? (
                                    <Button
                                      size="sm"
                                      color="primary"
                                      className="label-primary"
                                      onClick={handleEnableLogin}
                                      value={record.id}
                                    >
                                      {
                                        <GetLanguageString props="user_enable_login" />
                                      }
                                    </Button>
                                  ) : record.aspNetUsersId == null ? (
                                    CheckPermission("CanCreateUser") ? (
                                      <Button
                                        color="white"
                                        size="sm"
                                        onClick={handleOnCreateLogin}
                                        value={record.id}
                                      >
                                        {
                                          <GetLanguageString props="user_create_login" />
                                        }
                                      </Button>
                                    ) : null
                                  ) : (
                                    <ButtonGroup>
                                      {CheckPermission("CanResetPassword") ? (
                                        <Button
                                          color="white"
                                          size="sm"
                                          onClick={handleResetPassword}
                                          value={record.serialNumber}
                                        >
                                          {
                                            <GetLanguageString props="user_re_generate_password" />
                                          }
                                        </Button>
                                      ) : null}
                                      <Button
                                        color="white"
                                        size="sm"
                                        onClick={handleOnDisable}
                                        value={record.id}
                                      >
                                        {
                                          <GetLanguageString props="user_disabled_login" />
                                        }
                                      </Button>
                                      {memberId === 0 ? (
                                        record.isAdmin ? (
                                          <Button
                                            color="danger"
                                            size="sm"
                                            onClick={handleOnRemoveAdmin}
                                            value={record.id}
                                          >
                                            {
                                              <GetLanguageString props="user_remove_admin" />
                                            }
                                          </Button>
                                        ) : (
                                          <Button
                                            color="white"
                                            size="sm"
                                            onClick={handleOnMakeAdmin}
                                            value={record.id}
                                          >
                                            {
                                              <GetLanguageString props="user_make_admin" />
                                            }
                                          </Button>
                                        )
                                      ) : null}
                                    </ButtonGroup>
                                  )}
                                </td>
                              ) : null}
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan="11">
                            <ul className={GetCustomStyles().page_style}>
                              <Pagination
                                activePage={pagination.activePage}
                                itemsCountPerPage={pagination.pageSize}
                                totalItemsCount={pagination.totalItem}
                                pageRangeDisplayed={pagination.pageRange}
                                onChange={handlePageChange}
                              />
                            </ul>
                          </td>
                        </tr>
                      </tfoot>
                    </Table>
                  </div>
                ) : (
                  <NoResult />
                )
              ) : (
                LoadingSpinner()
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Users;
