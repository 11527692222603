import React, { useEffect, useState } from "react";
import Header from "./Header";
import SideBar from "./SideBar";
import SwitchRouter from "./SwitchRouter";
import { useMediaQuery } from "react-responsive";
import { GetSelectedLanguage } from "../helper/Method";
import { LoadingSpinner } from "../../constants/const";
import { GetLanguageString } from "../helper/Components";

export default function Layout() {
  const [value, setValue] = useState(true);
  const [loading, setLoading] = useState(false);
  const isBigScreen = useMediaQuery({ minWidth: 767 });
  let btn_class = "pace-done ";

  if (value && !isBigScreen) btn_class = "body-small";
  else if (!value && isBigScreen) btn_class = "mini-navbar";
  else if (!value && !isBigScreen) btn_class = "body-small mini-navbar";

  useEffect(() => {
    document.body.className = btn_class;
  }, [btn_class]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "/config.js";
    script.type = "text/javascript";
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      {!loading ? (
        <div id="wrapper" className={GetSelectedLanguage()}>
          <>
            <SideBar />
            <div id="page-wrapper" className="gray-bg">
              <Header
                setValue={setValue}
                value={value}
                setLoading={setLoading}
              />
              <SwitchRouter />
            </div>
          </>
        </div>
      ) : (
        <div
          className="text-center"
          style={{ backgroundColor: "white", width: "100vw", height: "100vh" }}
        >
          <br></br>
          <h2>{<GetLanguageString props={"responsibility_change_text"} />}</h2>
          {LoadingSpinner()}
        </div>
      )}
    </div>
  );
}
