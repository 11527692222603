import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Label,
  Button,
  Row,
  Input,
} from "reactstrap";
import { GetLanguageString } from "../../helper/Components";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { GetSelectedLanguage } from "../../helper/Method";
import { addMember, memberTypeApi } from "../ApiMember";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { convertToDateString, LoadingSpinner } from "../../../constants/const";
import Draggable from "react-draggable";

const ModifyOrRequestMemberType = ({
  setChangeMemberTypeModal,
  memberTypeId,
  memberType,
  memberId,
  addNewRafiq,
  setUpdatedDetails,
  setMemberyTypeSave,
}) => {
  const { t } = useTranslation();
  const notify = (string) => toast.success(string);
  const toggle = () => setChangeMemberTypeModal(false);
  const [loading, setLoading] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [memberTypeList, setMemberTypeList] = useState();
  const [memberyTypeDate, setMemberyTypeDate] = useState(new Date());
  const [changeMemberType, setChangeMemberType] = useState({
    Id: memberTypeId,
    memberType: memberType,
    memberId: memberId,
    date: new Date(),
    description: "",
    referenceNote: "",
    modifiable: true,
  });
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  useEffect(() => {
    if (addNewRafiq) {
      memberTypeApi()
        .getMemberTypes()
        .then((res) => {
          setMemberTypeList(res.data);
        })
        .catch((err) => console.log(err));
    } else {
      memberTypeApi()
        .GetModifyAndRequestCombinedMemberTypes()
        .then((res) => {
          setMemberTypeList(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [addNewRafiq]);

  const handleInputDate = (value) => {
    if (value !== null) {
      if (value <= new Date()) {
        setMemberyTypeDate(value);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setChangeMemberType({
      ...changeMemberType,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setButtonDisable(true);
    changeMemberType.date = convertToDateString(memberyTypeDate);
    addMember()
      .addMemberType(changeMemberType, addNewRafiq)
      .then((res) => {
        if (res.data === "") {
          swal({
            title: t("member__member_type_added_success_message"),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "success",
            buttons: "OK",
          });
          setChangeMemberTypeModal(false);
          setUpdatedDetails((prevState) => !prevState);
          setMemberyTypeSave((prevState) => !prevState);
        } else if (res.data === "CanNotChange") {
          swal({
            title: t("member__member_type_not_change_able"),
            icon: "warning",
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            buttons: "OK",
          });
        } else {
          notify(res.data);
          setChangeMemberTypeModal(false);
        }
        setLoading(false);
        setButtonDisable(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setButtonDisable(false);
      });
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {
              <GetLanguageString props="member_edit_member_change_member_type" />
            }
          </ModalHeader>
          {!loading ? (
            <Form onSubmit={handleSubmit}>
              <ModalBody>
                <div className="ibox-content">
                  <Row>
                    <FormGroup>
                      <Label className="col-form-label">
                        {
                          <GetLanguageString props="mmeber_details_change_member_type" />
                        }
                      </Label>
                      <Select
                        options={memberTypeList}
                        value={changeMemberType?.memberType}
                        className="basic-single ur no-drag"
                        classNamePrefix="select"
                        placeholder={
                          GetSelectedLanguage() === "en"
                            ? "Select"
                            : "منتخب کریں"
                        }
                        onChange={(mt) => {
                          setChangeMemberType({
                            ...changeMemberType,
                            memberType: mt,
                          });
                        }}
                      />
                    </FormGroup>
                  </Row>
                  <Row>
                    <FormGroup>
                      <Label className="col-form-label">
                        {<GetLanguageString props="courses_description" />}
                      </Label>
                      <Input
                        className={GetSelectedLanguage()}
                        type="text"
                        name="description"
                        value={changeMemberType?.description}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Row>
                  <Row>
                    <FormGroup>
                      <Label className="col-form-label">
                        {<GetLanguageString props="member_reference_note" />}
                      </Label>
                      <Input
                        className="ur no-drag"
                        type="text"
                        name="referenceNote"
                        value={changeMemberType?.referenceNote}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Row>
                  <Row>
                    <FormGroup>
                      <Label className="col-form-label">
                        {<GetLanguageString props="member_edit_member_date" />}
                      </Label>
                      <DatePicker
                        placeholderText="dd/mm/yyyy"
                        className="form-control no-drag"
                        selected={memberyTypeDate}
                        maxDate={new Date()}
                        onChange={handleInputDate}
                        dateFormat="dd/MM/yyyy"
                      />
                    </FormGroup>
                  </Row>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="white" onClick={toggle}>
                  {<GetLanguageString props="common_cancel" />}
                </Button>
                <Button color="primary" disabled={buttonDisable} type="submit">
                  {<GetLanguageString props="common_save_changes" />}
                </Button>
              </ModalFooter>
            </Form>
          ) : (
            LoadingSpinner()
          )}
        </Modal>
      </Draggable>
    </div>
  );
};

export default ModifyOrRequestMemberType;
