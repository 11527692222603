import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Form, Row, Col, FormGroup, Label, Input, Button } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import useForm from "../../shared/useForm";
import { ApiUnit } from "../../unit/ApiUnit";
import { GetLanguageString } from "../../helper/Components";
import { requestApi } from "../../../actions/api";
import { ApiResponsibility } from "../../config/responsibilityType/ApiResponsibilityType";
import { DateValidater, GetSelectedLanguage } from "../../helper/Method";
import { encryptStorage } from "../../../constants/EncryptStorage";
import { isUrdu } from "../../../constants/const";
import Switch from "react-switch";

const SearchReport = ({
  setReportSearchMemberModel,
  reportSearchMemberModel,
  setSearchButton,
}) => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [unitList, setUnitList] = useState([]);
  const [excludeExUnit, setExcludeExUnit] = useState(true);
  const [courseCheckBox, setCourseCheckBox] = useState(false);
  const [eventCheckBox, setEventCheckBox] = useState(false);
  const [memberTypeList, setMemberTypeList] = useState([]);
  const [courseTypeList, setCourseTypeList] = useState([]);
  const selectedLanguage = GetSelectedLanguage();
  const [selectCourseTypeList, setSelectCourseTypeList] = useState([]);
  const [selectEventTypeList, setSelectEventTypeList] = useState([]);
  const [eventTypeList, setEventTypeList] = useState([]);
  const [responsibilityList, setResponsibilityList] = useState([]);
  const dateRangeValidation = {
    startDate: null,
    endDate: null,
  };
  const [dateValidations, setDateValidations] = useState(dateRangeValidation);
  const memberTypeOptions = [];
  const courseTypeOptions = [];
  const eventTypeOptions = [];
  const ResponsibilityOptions = [];
  const { values, handleInputChange, setValues } = useForm(
    reportSearchMemberModel
  );
  let absent = false;
  let present = false;
  let leave = false;

  const [errors, setErrors] = useState({
    courseType: "",
  });
  const unitTypeName = "null";

  const validate = () => {
    let temp = {};
    temp.courseType =
      selectCourseTypeList !== null ? (
        selectCourseTypeList.length !== 0 ? (
          ""
        ) : (
          <GetLanguageString props="courses_course_type_error_message" />
        )
      ) : (
        ""
      );

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };
  useEffect(() => {
    ApiResponsibility()
      .getResponsibilitiesByLoggedInUnit(loggedInUnitId)
      .then((res) => {
        setResponsibilityList(res.data);
      })
      .catch((err) => console.log(err));

    requestApi("eventtype/")
      .fetchAll()
      .then((res) => {
        setEventTypeList(res.data);
      })
      .catch((err) => console.log(err));

    requestApi("membertype/")
      .fetchAll()
      .then((res) => {
        setMemberTypeList(res.data);
      })
      .catch((err) => console.log(err));

    requestApi("coursetype/")
      .fetchAll()
      .then((res) => {
        setCourseTypeList(res.data);
      })
      .catch((err) => console.log(err));
  }, [loggedInUnitId]);

  useEffect(() => {
    ApiUnit()
      .getUnitList(
        values.unitId,
        loggedInUnitId,
        false,
        unitTypeName,
        excludeExUnit
      )
      .then((res) => {
        setUnitList(res.data);
      })
      .catch((err) => console.log(err));
  }, [loggedInUnitId, values.unitId, excludeExUnit]);

  courseTypeList.forEach((d) =>
    courseTypeOptions.push({ value: d.id, label: d.name })
  );
  eventTypeList.forEach((d) =>
    eventTypeOptions.push({ value: d.id, label: d.type })
  );
  memberTypeList.forEach((d) =>
    memberTypeOptions.push({ value: d.id, label: d.type })
  );
  responsibilityList.forEach((d) =>
    ResponsibilityOptions.push({ value: d.value, label: d.label })
  );

  const handleChangeUnits = (record) => {
    if (record !== null) {
      ApiUnit()
        .getUnitList(
          record.value,
          loggedInUnitId,
          false,
          unitTypeName,
          excludeExUnit
        )
        .then((res) => {
          setUnitList(res.data);
        })
        .catch((err) => console.log(err));
      setValues({
        ...values,
        unitId: record.value,
      });
    } else {
      ApiUnit()
        .getUnitList(
          loggedInUnitId,
          loggedInUnitId,
          false,
          unitTypeName,
          excludeExUnit
        )
        .then((res) => {
          setUnitList(res.data);
        })
        .catch((err) => console.log(err));
      setValues({
        ...values,
        unitId: 0,
      });
    }
  };

  const handleChangeMemberTypes = (record) => {
    if (record !== null) {
      let ids = [];
      record.forEach((r) => ids.push(r.value));
      setValues({
        ...values,
        memberTypeIds: ids,
      });
    } else {
      setValues({
        ...values,
        memberTypeIds: 0,
      });
    }
  };

  const handleChangeEventTypes = (record) => {
    if (record !== null) {
      let ids = [];
      record.forEach((r) => ids.push(r.value));
      setValues({
        ...values,
        eventTypeIds: ids,
      });
      setSelectEventTypeList(record);
    } else {
      setValues({
        ...values,
        eventTypeIds: 0,
      });
      setSelectEventTypeList(record);
    }
  };

  const handleChangeCourseTypes = (record) => {
    if (record !== null) {
      let ids = [];
      record.forEach((r) => ids.push(r.value));
      setValues({
        ...values,
        courseTypeIds: ids,
      });
      setSelectCourseTypeList(record);
    } else {
      setValues({
        ...values,
        courseTypeIds: 0,
      });
      setSelectCourseTypeList(record);
    }
    setErrors({
      courseType: "",
    });
  };

  const handleChangeResponsibility = (record) => {
    if (record !== null) {
      let ids = [];
      record.forEach((r) => ids.push(r.value));
      setValues({
        ...values,
        responsibilityIds: ids,
      });
    } else {
      setValues({
        ...values,
        responsibilityIds: 0,
      });
    }
  };

  const handleSubUnits = (e) => {
    const { value, name } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setValues({
      ...values,
      [name]: v,
    });
  };

  const handleIncludeCourse = (e) => {
    const { checked, id } = e.target;
    if (id === "excludeCourse" && checked) {
      setValues({
        ...values,
        includeCourse: false,
      });
    } else if (id === "includeCourse" && checked) {
      setValues({
        ...values,
        includeCourse: true,
      });
    }
  };

  const handleCompletedCourse = (e) => {
    const { checked, name } = e.target;
    setValues({
      ...values,
      [name]: checked,
    });
  };

  const handleCourseStartDate = (value) => {
    if (DateValidater(value, values.courseEndDate)) {
      let handleCourseStartDate = new Date(value.setHours(5));
      setValues({
        ...values,
        courseStartDate: handleCourseStartDate,
      });
      setDateValidations(dateRangeValidation);
    } else {
      setDateValidations({
        ...dateValidations,
        startDate: (
          <GetLanguageString props="common_start_date_validation_message" />
        ),
      });
    }
  };

  const handleEventStartDate = (value) => {
    if (DateValidater(value, values.eventEndDate)) {
      let handleEventStartDate = new Date(value.setHours(5));
      setValues({
        ...values,
        eventStartDate: handleEventStartDate,
      });
      setDateValidations(dateRangeValidation);
    } else {
      setDateValidations({
        ...dateValidations,
        startDate: (
          <GetLanguageString props="common_start_date_validation_message" />
        ),
      });
    }
  };

  const handleCourseEndDate = (value) => {
    if (DateValidater(values.courseStartDate, value)) {
      let handleCourseEndDate = new Date(value.setHours(5));
      setValues({
        ...values,
        courseEndDate: handleCourseEndDate,
      });
      setDateValidations(dateRangeValidation);
    } else {
      setDateValidations({
        ...dateValidations,
        endDate: (
          <GetLanguageString props="common_end_date_validation_message" />
        ),
      });
    }
  };

  const handleEventEndDate = (value) => {
    if (DateValidater(values.eventStartDate, value)) {
      let handleEventEndDate = new Date(value.setHours(5));
      setValues({
        ...values,
        eventEndDate: handleEventEndDate,
      });
      setDateValidations(dateRangeValidation);
    } else {
      setDateValidations({
        ...dateValidations,
        endDate: (
          <GetLanguageString props="common_end_date_validation_message" />
        ),
      });
    }
  };

  const handleEventCheckBox = () => {
    setEventCheckBox(!eventCheckBox);
    setSelectEventTypeList(null);
    setValues({
      ...values,
      eventEndDate: null,
      eventStartDate: null,
      eventTypeIds: [],
    });
  };

  const handleCourseCheckBox = () => {
    setCourseCheckBox(!courseCheckBox);
    setSelectCourseTypeList([]);
    setValues({
      ...values,
      courseEndDate: null,
      courseStartDate: null,
      courseTypeIds: [],
    });
    setErrors({
      courseType: "",
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (courseCheckBox) {
      if (validate()) {
        setSearchButton(true);
        setReportSearchMemberModel(values);
      }
    } else {
      setSearchButton(true);
      setReportSearchMemberModel(values);
    }
  };

  const handleAttendanceStatus = (status) => {
    setValues({
      ...values,
      attendanceStatus: status,
    });
  };

  const handleOnTime = (e) => {
    setValues({
      ...values,
      isOnTime: e,
    });
  };

  const handleExcludeExUnit = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setExcludeExUnit(v);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col md="2">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="member_search_by_name" />}
            </Label>
            <Input
              className="ur"
              type="text"
              name="name"
              value={values.name}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="member_search_by_father_name" />}
            </Label>
            <Input
              type="text"
              name="fatherName"
              value={values.fatherName}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="member_search_by_regno" />}
            </Label>
            <Input
              type="text"
              name="regNo"
              value={values.regNo}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="member_search_by_phoneno_email" />}
            </Label>
            <Input
              type="text"
              name="phoneNo"
              value={values.phoneNo}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="member_search_by_address" />}
            </Label>
            <Input
              className={isUrdu(values.address) ? "ur" : ""}
              type="text"
              name="address"
              value={values.address}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="member_select_responsibility" />}
            </Label>
            <Select
              options={ResponsibilityOptions}
              className="basic-multi-select ur"
              classNamePrefix="select"
              isMulti
              isClearable
              onChange={handleChangeResponsibility}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="member_select_member_type" />}
            </Label>
            <Select
              options={memberTypeOptions}
              className="basic-multi-select ur"
              classNamePrefix="select"
              isMulti
              isClearable
              onChange={handleChangeMemberTypes}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        {unitList.map((record, index) => {
          const unitOptions = [];
          record.unitList.forEach((d) =>
            unitOptions.push({ value: d.id, label: d.name })
          );
          let selected = {};
          let unitName = "";
          if (record.parentUnitName !== null) unitName = record.parentUnitName;
          if (record.selectedUnit !== null) {
            selected = {
              value: record.selectedUnit.id,
              label: record.selectedUnit.name,
            };
          }

          return (
            <Col md="2" key={index}>
              <FormGroup>
                <Label className="col-form-label">
                  {unitName !== "" ? (
                    <GetLanguageString
                      props="member_select_unit_name"
                      value={unitName}
                    />
                  ) : (
                    <GetLanguageString props="member_select_unit" />
                  )}
                </Label>
                <Select
                  options={unitOptions}
                  value={selected}
                  className="basic-single ur"
                  classNamePrefix="select"
                  onChange={handleChangeUnits}
                />
              </FormGroup>
            </Col>
          );
        })}
      </Row>
      {values.unitId !== 0 ? (
        <Row>
          <Col md="2">
            <FormGroup check>
              <Label check for="excludeExUnit">
                <Input
                  id="excludeExUnit"
                  type="checkbox"
                  name="excludeExUnit"
                  checked={!excludeExUnit}
                  value={!excludeExUnit}
                  onChange={handleExcludeExUnit}
                />
                {<GetLanguageString props="member_search_member_ex_unit" />}
              </Label>
            </FormGroup>
          </Col>
          <Col md="2">
            <FormGroup check>
              <Label check for="subUnit">
                <Input
                  id="subUnit"
                  type="checkbox"
                  name="isSubUnits"
                  checked={values.isSubUnits}
                  value={!values.isSubUnits}
                  onChange={handleSubUnits}
                />
                {<GetLanguageString props="member_search_member_sub_unit" />}
              </Label>
            </FormGroup>
          </Col>
          <Col md="2">
            <FormGroup check>
              <Label check for="includeEXMember">
                <Input
                  id="includeEXMember"
                  type="checkbox"
                  name="includeEXMember"
                  checked={values.includeEXMember}
                  value={!values.includeEXMember}
                  onChange={handleSubUnits}
                />
                {<GetLanguageString props="member_search_member_ex_member" />}
              </Label>
            </FormGroup>
          </Col>
        </Row>
      ) : null}
      <hr />
      <Row className="m-t-md">
        <Col md="6">
          <FormGroup check>
            <Label check for="eventSearch">
              <Input
                id="eventSearch"
                type="checkbox"
                name="isSubUnits"
                className="big-checkbox m-r-sm"
                value={!eventCheckBox}
                onChange={handleEventCheckBox}
              />
              <div className="set-label">
                {" "}
                {<GetLanguageString props="member_report_search_in_events" />}
              </div>
            </Label>
          </FormGroup>
          {eventCheckBox ? (
            <>
              <FormGroup>
                <Label className="col-form-label">
                  {<GetLanguageString props="settings_event_type" />}
                </Label>
                <Select
                  options={eventTypeOptions}
                  value={selectEventTypeList}
                  className="basic-multi-select ur"
                  classNamePrefix="select"
                  isMulti
                  isClearable
                  onChange={handleChangeEventTypes}
                />
              </FormGroup>
              <Row>
                <Col md="6">
                  <Label className="form-check form-check-inline">
                    {values.attendanceStatus === 0 ? (absent = true) : null}
                    <Input
                      id={"absent"}
                      type="radio"
                      checked={absent}
                      name={"status"}
                      onChange={() => handleAttendanceStatus(0)}
                      className="form-check-input"
                    />
                    {
                      <GetLanguageString props="events_participants_attendance_absent" />
                    }
                  </Label>
                  <Label className="form-check form-check-inline">
                    {values.attendanceStatus === 1 ? (present = true) : null}
                    <Input
                      id={"present"}
                      type="radio"
                      checked={present}
                      name={"status"}
                      onChange={() => handleAttendanceStatus(1)}
                      className="form-check-input"
                    />
                    {
                      <GetLanguageString props="events_participants_attendance_present" />
                    }
                  </Label>
                  <Label className="form-check form-check-inline">
                    {values.attendanceStatus === 2 ? (leave = true) : null}
                    <Input
                      id={"leave"}
                      checked={leave}
                      type="radio"
                      name={"status"}
                      onChange={() => handleAttendanceStatus(2)}
                      className="form-check-input"
                    />
                    {
                      <GetLanguageString props="events_participants_attendance_leave" />
                    }
                  </Label>
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="member_report_from" />}
                    </Label>
                    <DatePicker
                      showYearDropdown
                      placeholderText="dd/mm/yyyy"
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                      selected={values.eventStartDate}
                      onChange={handleEventStartDate}
                    />
                    {dateValidations.startDate && (
                      <h4 className="text-error">
                        {dateValidations.startDate}
                      </h4>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <Label htmlFor="small-radius-switch" for={"onTime"}>
                    <Switch
                      id={"onTime"}
                      disabled={present ? false : true}
                      checked={values.isOnTime}
                      onChange={(e) => handleOnTime(e)}
                      handleDiameter={32}
                      offColor="#888888"
                      onColor="#6fcab8"
                      offHandleColor="#fff"
                      onHandleColor="#18A689"
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={25}
                      width={90}
                      borderRadius={20}
                      uncheckedIcon={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            color: "white",
                            paddingRight: 25,
                            fontSize: selectedLanguage === "ur" ? 25 : 15,
                            paddingBottom: selectedLanguage === "ur" ? 10 : 0,
                            fontWeight: "bold",
                          }}
                        >
                          <GetLanguageString
                            props={"events_participants_late"}
                          />
                        </div>
                      }
                      checkedIcon={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            width: "60px",
                            color: "white",
                            paddingLeft: 5,
                            fontSize: selectedLanguage === "ur" ? 19 : 12,
                            paddingBottom: selectedLanguage === "ur" ? 5 : 0,
                            fontWeight: "bold",
                          }}
                        >
                          <GetLanguageString
                            props={"events_participants_on_time"}
                          />
                        </div>
                      }
                      className="react-switch"
                    />
                  </Label>
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="member_report_to" />}
                    </Label>
                    <DatePicker
                      showYearDropdown
                      placeholderText="dd/mm/yyyy"
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                      selected={values.eventEndDate}
                      onChange={handleEventEndDate}
                    />
                    {dateValidations.endDate && (
                      <h4 className="text-error">{dateValidations.endDate}</h4>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </>
          ) : null}
        </Col>
        <Col md="6">
          <FormGroup check>
            <Label check for="courseSearch">
              <Input
                id="courseSearch"
                type="checkbox"
                name="isSubUnits"
                className="big-checkbox m-r-sm"
                value={!courseCheckBox}
                onChange={handleCourseCheckBox}
              />
              <div className="set-label">
                {<GetLanguageString props="member_report_search_in_courses" />}
              </div>
            </Label>
          </FormGroup>
          {courseCheckBox ? (
            <>
              <Row className="m-t-md">
                <Col md="6">
                  <FormGroup check>
                    <Label check for="includeCourse">
                      <Input
                        id="includeCourse"
                        type="radio"
                        name="includeCourse"
                        checked={values.includeCourse}
                        onChange={handleIncludeCourse}
                      />
                      {
                        <GetLanguageString props="member_report_course_attended" />
                      }
                    </Label>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup check>
                    <Label check for="excludeCourse">
                      <Input
                        id="excludeCourse"
                        type="radio"
                        name="includeCourse"
                        onChange={handleIncludeCourse}
                      />
                      {
                        <GetLanguageString props="member_report_course_not_attended" />
                      }
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              {values.includeCourse && (
                <Row className="m-t-md">
                  <Col md="6">
                    <Row>
                      <Col md="4">
                        <FormGroup check>
                          <Label check for="complete">
                            <Input
                              className="text-right"
                              type="checkbox"
                              checked={values.complete}
                              name="complete"
                              value={values.complete}
                              onChange={(e) => handleCompletedCourse(e)}
                            />
                            {
                              <GetLanguageString props="courses_status_completed" />
                            }
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup check>
                          <Label check for="inComplete">
                            <Input
                              className="text-left"
                              type="checkbox"
                              checked={values.inComplete}
                              name="inComplete"
                              value={values.inComplete}
                              onChange={(e) => handleCompletedCourse(e)}
                            />
                            {
                              <GetLanguageString props="courses_status_incompleted" />
                            }
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col md="4"></Col>
                    </Row>
                  </Col>
                </Row>
              )}
              <FormGroup>
                <Label className="col-form-label">
                  {<GetLanguageString props="settings_course_type" />}
                </Label>
                <Select
                  options={courseTypeOptions}
                  value={selectCourseTypeList}
                  className="basic-multi-select ur"
                  classNamePrefix="select"
                  isMulti
                  isClearable
                  onChange={handleChangeCourseTypes}
                />
                {errors.courseType && (
                  <h4 className="text-error">{errors.courseType}</h4>
                )}
              </FormGroup>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="member_report_from" />}
                    </Label>
                    <DatePicker
                      showYearDropdown
                      placeholderText="dd/mm/yyyy"
                      className="form-control"
                      selected={values.courseStartDate}
                      dateFormat="dd/MM/yyyy"
                      onChange={handleCourseStartDate}
                    />
                    {dateValidations.startDate && (
                      <h4 className="text-error">
                        {dateValidations.startDate}
                      </h4>
                    )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="member_report_to" />}
                    </Label>
                    <DatePicker
                      showYearDropdown
                      placeholderText="dd/mm/yyyy"
                      className="form-control"
                      selected={values.courseEndDate}
                      dateFormat="dd/MM/yyyy"
                      onChange={handleCourseEndDate}
                    />
                    {dateValidations.endDate && (
                      <h4 className="text-error">{dateValidations.endDate}</h4>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </>
          ) : null}
        </Col>
      </Row>
      <Row className="m-t-md">
        <Col className="text-center">
          <FormGroup>
            <Button color="primary" className="m-l-sm" type="submit">
              {<GetLanguageString props="common_view_report" />}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default SearchReport;
