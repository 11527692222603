import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";


import Draggable from "react-draggable";
import { TagsInput } from "react-tag-input-component";
import swal from "sweetalert";
import { requestApi } from "../../../actions/api";
import { LoadingSpinner, isAlphaNumeric, isCodeUnique } from "../../../constants/const";
import { GetLanguageString } from "../../helper/Components";
import PickColor from "../PickColor";
import { ApiUnitType } from "../unitType/ApiUnitType";
import { ApiEventType } from "./ApiEventType";

const AddEventType = ({ setAddModal, setEventType }) => {
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("");
  const { t } = useTranslation();
  const [unitType, setUnitType] = useState([]);
  const [codeList, setCodeList] = useState([]);
  const [eventTypeValues, setEventTypeValues] = useState({
    type: "",
    code: "",
    unitType: [],
    frequency: {},
    allowAhbab: false,
    forChildUnit: false,
    defaultParticipantsFilters: [],
    color: "",
  });
  const [errors, setErrors] = useState(eventTypeValues);
  const [staticFrequencyType, setStaticFrequencyType] = useState([]);
  const toggle = () => setAddModal(false);
  const [selectedStaticFrequencyType, setSelectedStaticFrequencyType] =
    useState({});
  const options = [];
  const [defaultParticipantsFilters, setDefaultParticipantsFilters] = useState(
    []
  );
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  useEffect(() => {
    setLoading(true);
    ApiUnitType()
      .getUnitType()
      .then((res) => {
        setUnitType(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    ApiEventType()
      .getStaticEventTypeFrequency()
      .then((res) => {
        setStaticFrequencyType(res.data);
        setSelectedStaticFrequencyType(res.data[0]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    ApiEventType()
      .getEventTypeCode()
      .then((res) => {
        setCodeList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setErrors({
      ...errors,
      [name]: "",
    });

    setEventTypeValues({
      ...eventTypeValues,
      [name]: value,
    });
  };

  unitType.forEach((d) => options.push({ value: d.id, label: d.type }));

  const validate = () => {
    let temp = {};
    temp.type = eventTypeValues.type ? "" : t("settings_name_error_message");
    temp.code = eventTypeValues.code ? (
      isAlphaNumeric(eventTypeValues.code) ? (
        isCodeUnique(codeList, eventTypeValues.code) ? (
          ""
        ) : (
          <GetLanguageString props="settings_code_unique_error_message" />
        )
      ) : (
        <GetLanguageString props="settings_code_aplha_numeric_error_message" />
      )
    ) : (
      <GetLanguageString props="settings_code_error_message" />
    );
    temp.unitType =
      Object.keys(eventTypeValues.unitType).length !== 0 ? (
        ""
      ) : (
        <GetLanguageString props="settings_select_unit_type_error" />
      );

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleChangeUnitType = (record) => {
    setEventTypeValues({
      ...eventTypeValues,
      unitType: record,
    });
    setErrors({
      ...errors,
      unitType: "",
    });
  };

  const handleChangeStaticFrequencyType = (record) => {
    if (record === null) {
      setEventTypeValues({
        ...eventTypeValues,
        frequency: {},
      });
      setSelectedStaticFrequencyType({});
    } else {
      setEventTypeValues({
        ...eventTypeValues,
        frequency: record,
      });
      setSelectedStaticFrequencyType(record);
    }
    setErrors({
      ...errors,
      frequency: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    eventTypeValues.frequency = selectedStaticFrequencyType;
    eventTypeValues.defaultParticipantsFilters =
      defaultParticipantsFilters.toString();
    eventTypeValues.color = color;

    if (validate()) {
      setLoading(true);
      requestApi("eventtype/")
        .create(eventTypeValues)
        .then((res) => {
          toggle();
          setEventType(res.data);
          setLoading(false);
        })
        .catch((err) => {
          toggle();
          swal({
            title: err,
            text: err.response.data,
            icon: "error",
            buttons: "OK",
          });
          setLoading(false);
          console.log(`Error Msg: ${err.response.data}, ${err}`);
        });
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="settings_add_event_type" />}
          </ModalHeader>
          {!loading ? (
            <Form onSubmit={handleSubmit}>
              <ModalBody>
                <FormGroup>
                  <Label className="no-drag">{<GetLanguageString props="common_name" />}</Label>
                  <Input
                    autoFocus
                    type="text"
                    name="type"
                    className="ur no-drag"
                    value={eventTypeValues.type}
                    onChange={handleInputChange}
                    {...(errors.type && { invalid: true })}
                  />
                  {errors.type && (
                    <FormFeedback>
                      <h4>{errors.type}</h4>
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label>{<GetLanguageString props="settings_code" />}</Label>
                  <Input
                    type="text"
                    name="code"
                    className="ur no-drag"
                    value={eventTypeValues.code}
                    onChange={handleInputChange}
                    {...(errors.code && { invalid: true })}
                  />
                  {errors.code && (
                    <FormFeedback>
                      <h4>{errors.code}</h4>
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label>
                    {<GetLanguageString props="settings_unit_type" />}
                  </Label>
                  <Select
                    options={options}
                    className="basic-single ur no-drag"
                    classNamePrefix="select"
                    onChange={handleChangeUnitType}
                  />
                  {errors?.unitType ? (
                    <h4 className="text-error">{errors?.unitType}</h4>
                  ) : null}
                </FormGroup>
                <FormGroup>
                  <Label>
                    {
                      <GetLanguageString props="settings_event_type_frequency" />
                    }
                  </Label>
                  <Select
                    value={selectedStaticFrequencyType}
                    options={staticFrequencyType}
                    className="basic-single no-drag"
                    classNamePrefix="select"
                    onChange={handleChangeStaticFrequencyType}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    {
                      <GetLanguageString props="settings_event_type_default_participants_filters" />
                    }
                  </Label>
                  <TagsInput
                    value={defaultParticipantsFilters}
                    onChange={setDefaultParticipantsFilters}
                    className="no-drag"
                    name="defaultParticipantsFilters"
                  />
                </FormGroup>
                <FormGroup check>
                  <Label check for="allowAhbab">
                    <Input
                      id="allowAhbab"
                      type="checkbox"
                      name="allowAhbab"
                      value={!eventTypeValues.allowAhbab}
                      onChange={handleInputChange}
                      checked={eventTypeValues.allowAhbab}
                    />
                    {
                      <GetLanguageString props="settings_event_type_allow_ahbab" />
                    }
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check for="forChildUnit">
                    <Input
                      id="forChildUnit"
                      type="checkbox"
                      name="forChildUnit"
                      value={!eventTypeValues.forChildUnit}
                      onChange={handleInputChange}
                      checked={eventTypeValues.forChildUnit}
                    />
                    {<GetLanguageString props="events_for_child_unit" />}
                  </Label>
                </FormGroup>
                
                <PickColor setColor={setColor} color={color} />
              </ModalBody>
              <ModalFooter>
                <Button color="white" onClick={toggle}>
                  {<GetLanguageString props="common_cancel" />}
                </Button>
                <Button color="primary" type="submit">
                  {<GetLanguageString props="common_save_changes" />}
                </Button>
              </ModalFooter>
            </Form>
          ) : (
            LoadingSpinner()
          )}
        </Modal>
      </Draggable>
    </div>
  );
};

export default AddEventType;
