import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { GetLanguageString } from "../../helper/Components";
import DatePicker from "react-datepicker";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import { addMember } from "../ApiMember";
import { withRouter } from "react-router-dom";
import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import ModifyOrRequestMemberType from "./ModifyOrRequestMemberType";
import { isEqual } from "lodash";
import {
  LoadingSpinner,
  convertToDateString,
  convertUTCToLocalTime,
  isUrdu,
} from "../../../constants/const";

const UpdateMemberType = ({
  history,
  tab7,
  memberId,
  addNewRafiq,
  setUpdatedDetails,
  activeTab,
  discard,
  setDiscard,
}) => {
  const { t } = useTranslation();
  const [changeMemberTypeModal, setChangeMemberTypeModal] = useState();
  const [loading, setLoading] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [memberyTypeSave, setMemberyTypeSave] = useState(false);
  const [memberyTypeDate, setMemberyTypeDate] = useState();
  const [memberType, setMemberType] = useState({
    id: 0,
    memberId: memberId,
    memberType: {},
    date: new Date(),
    description: "",
    referenceNote: "",
  });
  const [originalMemberType, setOriginalMemberType] = useState({
    date: new Date(),
    description: "",
    referenceNote: "",
  });
  const [comparisonValues, setComparisonValues] = useState({
    date: new Date(),
    description: "",
    referenceNote: "",
  });

  useEffect(() => {
    if (tab7 === "active") {
      addMember()
        .getMemberTypeDetail(memberId)
        .then((res) => {
          setMemberType({
            ...res.data,
            date: res.data.date
              ? new Date(convertUTCToLocalTime(res.data.date))
              : new Date(),
            memberType: res.data.memberType,
          });
          setOriginalMemberType({
            date: res.data.date
              ? new Date(convertUTCToLocalTime(res.data.date))
              : new Date(),
            description: res.data.description,
            referenceNote: res.data.referenceNote,
          });
          setComparisonValues({
            date: res.data.date
              ? new Date(convertUTCToLocalTime(res.data.date))
              : new Date(),
            description: res.data.description,
            referenceNote: res.data.referenceNote,
          });

          setMemberyTypeDate(
            res.data.date
              ? new Date(convertUTCToLocalTime(res.data.date))
              : new Date()
          );
        })
        .catch((err) => console.log(err));
    }
  }, [memberId, memberyTypeSave, tab7]);

  const handleInputDate = (value) => {
    if (value !== null) {
      if (value <= new Date()) {
        setMemberyTypeDate(value);
        setComparisonValues({
          ...comparisonValues,
          date: value,
        });
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMemberType({
      ...memberType,
      [name]: value,
    });
    setComparisonValues({
      ...comparisonValues,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    apiCAll();
    setOriginalMemberType("");
    setComparisonValues("");
  };

  const apiCAll = () => {
    setLoading(true);
    setButtonDisable(true);
    memberType.date = convertToDateString(memberyTypeDate);
    addMember()
      .addMemberType(memberType, addNewRafiq)
      .then((res) => {
        swal({
          title: t("member__member_type_added_success_message"),
          icon: "success",
          className: GetSelectedLanguage() === "en" ? "" : "ur",
          buttons: "OK",
        }).then((ok) => {
          setDiscard(!discard);
        });
        setLoading(false);
        setButtonDisable(false);
        setUpdatedDetails((prevState) => !prevState);
      })
      .catch((err) => {
        console.log(err);
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
        setLoading(false);
        setButtonDisable(false);
      });
  };
  const handleCancel = (e) => {
    const isMemberTypeChanged = isEqual(originalMemberType, comparisonValues);

    if (!isMemberTypeChanged) {
      swal({
        title: "Discard changes?",
        text: "Do you want to discard changes or save before canceling?",
        icon: "warning",
        buttons: ["Save Changes", "Discard"],
        closeOnClickOutside: false,
        dangerMode: true,
      }).then((willDiscard) => {
        if (willDiscard) {
          history.goBack(); // Process with cancel
        } else {
          handleSubmit(e); // Call the save function when the user chooses not to discard changes
        }
      });
    } else {
      history.goBack(); // No changes, directly go back
    }
  };
  // useEffect to propmpt a pop up to save changes or discard
  useEffect(() => {
    if (tab7 === "active") {
      // Check if the active tab has changed
      if (activeTab && activeTab !== "tab7") {
        const isMemberTypeChanged = isEqual(
          originalMemberType,
          comparisonValues
        );
        if (!isMemberTypeChanged) {
          swal({
            title: "Discard changes?",
            text: "Do you want to discard changes or save before canceling for MemberType?",
            icon: "warning",
            buttons: ["Save Changes", "Discard"],
            closeOnClickOutside: false,
            dangerMode: true,
          }).then((willDiscard) => {
            if (willDiscard) {
              setDiscard(!discard);
              setOriginalMemberType("");
              setComparisonValues("");
            } else {
              apiCAll();
            }
          });
        } else {
          setDiscard(!discard);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <div role="tabpanel" id="tab-7" className={"tab-pane " + tab7}>
      {changeMemberTypeModal ? (
        <ModifyOrRequestMemberType
          setChangeMemberTypeModal={setChangeMemberTypeModal}
          memberTypeId={memberType.id}
          memberType={memberType.memberType}
          memberId={memberId}
          addNewRafiq={addNewRafiq}
          setUpdatedDetails={setUpdatedDetails}
          setMemberyTypeSave={setMemberyTypeSave}
        />
      ) : null}
      <Form onSubmit={handleSubmit}>
        <div className="panel-body">
          {!loading ? (
            <div className="ibox">
              <div className="ibox-content">
                <Row>
                  <Col md={"6"}>
                    <FormGroup>
                      <Label className="col-form-label">
                        {
                          <GetLanguageString props="meeting_members_member_type" />
                        }
                      </Label>
                      <Input
                        className="ur"
                        type="text"
                        value={memberType.memberType.label}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col md="2" className="m-4 p-2">
                    <Button
                      color="primary"
                      className="m-l-sm"
                      onClick={() => setChangeMemberTypeModal(true)}
                    >
                      {
                        <GetLanguageString props="member_edit_member_change_member_type" />
                      }
                    </Button>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label className="col-form-label">
                        {<GetLanguageString props="member_edit_member_date" />}
                      </Label>
                      <DatePicker
                        placeholderText="dd/mm/yyyy"
                        className="form-control"
                        maxDate={new Date()}
                        selected={memberyTypeDate}
                        onChange={handleInputDate}
                        dateFormat="dd/MM/yyyy"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label className="col-form-label">
                        {<GetLanguageString props="courses_description" />}
                      </Label>
                      <Input
                        className={GetSelectedLanguage()}
                        type="text"
                        name="description"
                        value={memberType.description}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label className="col-form-label">
                        {<GetLanguageString props="member_reference_note" />}
                      </Label>
                      <Input
                        className={isUrdu(memberType.referenceNote) ? "ur" : ""}
                        type="text"
                        name="referenceNote"
                        value={memberType.referenceNote}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </div>
          ) : (
            LoadingSpinner()
          )}
          <Row>
            <Col md="8"></Col>

            <Col md="4">
              <div className={GetCustomStyles().btn_style}>
                <Button color="white" onClick={handleCancel}>
                  {<GetLanguageString props="common_cancel" />}
                </Button>
                <Button
                  color="primary"
                  disabled={buttonDisable}
                  className="m-l-sm"
                  type="submit"
                >
                  {<GetLanguageString props="common_save_changes" />}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default withRouter(UpdateMemberType);
