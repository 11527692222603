import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col, FormGroup, Button } from "reactstrap";
import { GetLanguageString } from "../../../helper/Components";

const SelectMonth = ({ searchUnit, setSearchUnit }) => {
  const [values, setValues] = useState(searchUnit);
  const [errors, setErrors] = useState(searchUnit);

  const validate = () => {
    let temp = {};
    temp.date =
      values.date !== null ? "" : "The Select Month field is required.";

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputChangeDate = (value) => {
    if (value !== null) {
      let temp = new Date(value);
      setValues({
        ...values,
        date: value,
        month: temp.getMonth() + 1,
        year: temp.getFullYear(),
      });

      setErrors({
        date: "",
      });
    }
  };

  const handleSubmit = () => {
    if (validate()) {
      setSearchUnit(values);
    }
  };

  return (
    <>
      <Row>
        <Col md="6">
          <FormGroup>
            <DatePicker
              dateFormat="MMMM yyyy"
              placeholderText="Select month"
              className="form-control"
              showMonthYearPicker
              selected={values.date}
              onChange={handleInputChangeDate}
            />
            {errors.date && <h4 className="text-error">{errors.date}</h4>}
          </FormGroup>
        </Col>
        <Col className="text-left">
          <FormGroup>
            <Button
              color="primary"
              className="m-l-sm"
              type="submit"
              onClick={handleSubmit}
            >
              {<GetLanguageString props="create_report" />}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default SelectMonth;
