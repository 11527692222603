import React, { useState } from 'react';
import { Table, Button, ButtonGroup } from 'reactstrap';
import { ApiTutorials } from "./ApiTutorials";
import { GetSelectedLanguage } from "../helper/Method";
import swal from "sweetalert";
import { LoadingSpinner, convertUTCToLocalTime } from "../../constants/const";
import { t } from "i18next";
import EditTutorial from './EditTutorial';
import { GetLanguageString, NoResult } from '../helper/Components';
import moment from 'moment';

const VideoTutorials = ({ values, setValues, loading, refresh, setRefresh, setSearch }) => {
  const [editModal, setEditModal] = useState(false); 
  const [selectedVideo, setSelectedVideo] = useState({});

  const handleEdit = (video) => {
    setSelectedVideo(video);
    setEditModal(true);
  };

  const handleDelete = (id) => {
    swal({
      title: t("common_delete_message"),
      text: t("common_delete_detail_message"),
      className: GetSelectedLanguage() === "en" ? "" : "ur",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if(willDelete) {
        ApiTutorials()
        .deleteTutorials(id)
        .then(() => {
          setValues((preValues) => preValues.filter((v) => v.id !== id));
          setSearch(true);
          setRefresh(!refresh);
        })
        .catch((err) => {
          setRefresh(!refresh);
        });
      }}
  )};

  return (
    <div className="ibox">
      { editModal ? ( 
        <EditTutorial
          video={selectedVideo}
          setEditModal={setEditModal}
          refresh={refresh}
          setRefresh={setRefresh}
          setSearch={setSearch}
        />
      ) : null }
      <div className="ibox-title">
        <h5>
          <GetLanguageString props="video_tutorial" />
        </h5>
      </div>
      <div className="ibox-content">
        <div className="table-responsive">
      {!loading ? (
        values.length !== 0 ? (
        <div>
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>
                  <GetLanguageString props="settings_title" />
                </th>
                <th>
                  <GetLanguageString props="video_tutorial_category" />
                </th>
                <th>
                  <GetLanguageString props="common_description" />
                </th>
                <th>
                  <GetLanguageString props="video_tutorial_date_added" />
                </th>
                <th className='text-center'>
                  <GetLanguageString props="video_tutorial_url" />
                </th>
                <th className='text-center'>
                  <GetLanguageString props="common_action" />
                </th>
              </tr>
            </thead>
            <tbody>
              {values.map((v, i) => {
                return (
                  <tr key={v.tutorialId}>
                  <th scope="row">{i + 1}</th>
                  <td className='ur'>{v.title}</td>
                  <td className='ur'>{v.category.label}</td>
                  <td className='ur'>{v.description}</td>
                  <td>
                    {moment(
                      convertUTCToLocalTime(v.dateAdded)
                    ).format("DD/MM/yyyy")}
                  </td>
                  <td className='text-center'>
                    <Button
                    color='white'
                    size='s'
                    >
                    <a style={{textDecoration: "none", color: "black"}} href={`//${v.url}`} target='_blank' rel='noopener noreferrer'>
                    <i class="fa fa-eye"></i>{" "}
                    <GetLanguageString props="member_add_rafiq_request_view_document" />
                    </a>
                    </Button>

                  </td>
                  <td className='text-center'>
                    <ButtonGroup>
                    <Button
                      color="white"
                      size="sm"
                      onClick={() => handleEdit(v)}
                    >
                      <GetLanguageString props="common_edit" />
                    </Button>{' '}
                    <Button
                      color="white"
                      size="sm"
                      onClick={() => handleDelete(v.tutorialId)}
                    >
                    <GetLanguageString props="common_delete" />
                    </Button>{' '}
                    </ButtonGroup>
                  </td>
                  </tr>
                  )}
              )}
            </tbody>
          </Table>
        </div>
        ) : (
          <NoResult />
        )
      ) : (
        LoadingSpinner()
      )}
        </div>
      </div>
    </div>
  );
};

export default VideoTutorials;
