import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Label,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";

import { ApiUnit } from "./ApiUnit";
import { GetLanguageString } from "../helper/Components";
import swal from "sweetalert";
import {
  JoiningDateValidater,
  LoadingSpinner,
  convertToDateString,
  convertUTCToLocalTime,
  nonAlphanumericRegex,
} from "../../constants/const";
import ChangeUnitCode from "./ChangeUnitCode";
import { GetSelectedLanguage } from "../helper/Method";
import {
  modalLabelButtonEn,
  modalLabelButtonUr,
} from "../../content/css/customCss";
import Draggable from "react-draggable";

const EditUnit = ({
  editValue,
  setEditModal,
  setUnit,
  loggedInUnitId,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [changeUnitCodeModal, setChangeUnitCodeModal] = useState();
  const [disableUnitCode, setDisableUnitCode] = useState(false);
  const [serverError, setServerError] = useState("");
  const [unitValues, setUnitValues] = useState({
    name: "",
    code: "",
    description: "",
    sortOrder: "",
    codeReferenceNote: "",
    creationDate: null,
    codeChangeDate: null,
  });

  const { t } = useTranslation();
  const [errors, setErrors] = useState(unitValues);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  const toggle = () => setEditModal(false);

  useEffect(() => {
    setLoading(true);
    ApiUnit()
      .getUnitById(editValue, 0)
      .then((res) => {
        setUnitValues({
          ...res.data,
          creationDate: res.data.creationDate
            ? convertToDateString(res.data.creationDate)
            : null,
          codeChangeDate: res.data.codeChangeDate
            ? new Date(convertUTCToLocalTime(res.data.codeChangeDate))
            : null,
        });

        setSelectedDate(
          res.data.creationDate
            ? new Date(convertUTCToLocalTime(res.data.creationDate))
            : null
        );
        setLoading(false);
        if (
          res.data.unitTypeName === "اسرہ" ||
          res.data.unitTypeName === "منفرد" ||
          res.data.unitTypeName === "اسرہ معاونین"
        ) {
          setDisableUnitCode(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [editValue]);

  const validate = () => {
    let temp = {};
    temp.name = unitValues.name ? "" : t("settings_name_error_message");
    temp.creationDate = selectedDate ? (
      JoiningDateValidater(selectedDate) === true ? (
        ""
      ) : (
        <GetLanguageString props="unit_creation_date_range_error_message" />
      )
    ) : (
      <GetLanguageString props="unit_creation_date_error_message" />
    );
    temp.code = unitValues.code ? (
      unitValues.code.length === 2 ? (
        ""
      ) : (
        <GetLanguageString props="unit_code_error_message1" />
      )
    ) : (
      <GetLanguageString props="unit_code_error_message" />
    );
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "code") {
      if (!nonAlphanumericRegex.test(value)) {
        setUnitValues({
          ...unitValues,
          code: value,
        });
        setErrors({
          code: "",
        });
      }
    } else {
      setErrors({
        name: "",
      });
      setUnitValues({
        ...unitValues,
        [name]: value,
      });
    }
  };

  const handleInputChangeCreationDate = (value) => {
    if (value !== null) {
      // let date = new Date(value.setHours(value.getHours() + 5));

      setSelectedDate(value);
      setErrors({
        creationDate: "",
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      unitValues.creationDate = convertToDateString(selectedDate);
      ApiUnit()
        .updateUnit(editValue, loggedInUnitId, unitValues)
        .then((res) => {
          swal({
            title: t("unit_edit_success_message"),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "success",
            buttons: "OK",
          });
          toggle();
          setUnit(res.data.unitList);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
          if (
            err?.response?.data === "sameCode" ||
            err?.response?.data === "notAllowed" ||
            err?.response?.data === "duplicate"
          ) {
            setServerError(err?.response?.data);
          }
        });
    }
  };

  return (
    <div>
      {changeUnitCodeModal ? (
        <ChangeUnitCode
          setChangeUnitCodeModal={setChangeUnitCodeModal}
          setUnitValues={setUnitValues}
          unitValues={unitValues}
          setServerError={setServerError}
        />
      ) : null}
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="unit_edit_unit" />}
          </ModalHeader>
          {!loading ? (
            <Form onSubmit={handleSubmit}>
              <ModalBody>
                {serverError === "sameCode" ? (
                  <h4 className="text-danger text-center">
                    {
                      <GetLanguageString props="unit_code_server_error_message" />
                    }
                  </h4>
                ) : serverError === "notAllowed" ? (
                  <h4 className="text-danger text-center">
                    {
                      <GetLanguageString props="unit_code_mq_server_error_message" />
                    }
                  </h4>
                ) : null}
                <FormGroup>
                  <Label>{<GetLanguageString props="common_name" />}</Label>
                  <Input
                    className="ur no-drag"
                    autoFocus
                    type="text"
                    name="name"
                    value={unitValues.name}
                    onChange={handleInputChange}
                    {...(errors.name && { invalid: true })}
                  />
                  {errors.name && (
                    <FormFeedback>
                      <h4>{errors.name}</h4>
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label>
                    {<GetLanguageString props="unit_unit_code" />}
                    {!disableUnitCode ? (
                      <Button
                        style={
                          GetSelectedLanguage() === "en"
                            ? modalLabelButtonEn
                            : modalLabelButtonUr
                        }
                        color="primary"
                        size="sm"
                        onClick={() => {
                          setChangeUnitCodeModal(true);
                        }}
                      >
                        {<GetLanguageString props="unit_change_unit_code" />}
                      </Button>
                    ) : null}
                  </Label>
                  <Input
                    className="ur no-drag"
                    type="text"
                    name="code"
                    disabled={true}
                    value={unitValues.code}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="col-form-label">
                    {<GetLanguageString props="unit_creation_date" />}
                  </Label>
                  <DatePicker
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd/mm/yyyy"
                    className="form-control no-drag"
                    maxDate={new Date()}
                    selected={selectedDate}
                    onChange={handleInputChangeCreationDate}
                    dateFormat="dd/MM/yyyy"
                  />
                  {errors.creationDate && (
                    <h4 className="text-error">{errors.creationDate}</h4>
                  )}
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="white" onClick={toggle}>
                  {<GetLanguageString props="common_cancel" />}
                </Button>
                <Button color="primary" type="submit">
                  {<GetLanguageString props="common_save_changes" />}
                </Button>
              </ModalFooter>
            </Form>
          ) : (
            LoadingSpinner()
          )}
        </Modal>
      </Draggable>
    </div>
  );
};

export default EditUnit;
