import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import { GetLanguageString } from "../../helper/Components";
import {
  LoadingSpinner,
  isUrdu,
  convertUTCToLocalTime,
  convertToDateString,
} from "../../../constants/const";
import { ApiBooks } from "../../books/ApiBooks";
import Draggable from "react-draggable";
import DatePicker from "react-datepicker";
import { GetSelectedLanguage } from "../../helper/Method";
const EditBook = ({ editValue, setEditBookModel, setRefresh, refresh }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [booksValues, setBooksValues] = useState({
    id: "",
    bookName: "",
    completedAt: null,
    remarks: "",
    referenceNote: "",
  });
  const [errors, setErrors] = useState(booksValues);
  const toggle = () => setEditBookModel(false);
  const [completedAtDate, setCompletedAtDate] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  useEffect(() => {
    setLoading(true);
    ApiBooks()
      .GetBookById(editValue)
      .then((res) => {
        setBooksValues({
          ...res.data,
          completedAt: res.data.completedAt
            ? new Date(convertUTCToLocalTime(res.data.completedAt))
            : null,
        });
        setCompletedAtDate(
          res.data.completedAt
            ? new Date(convertUTCToLocalTime(res.data.completedAt))
            : null
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [editValue]);

  const validate = () => {
    let temp = {};
    temp.name = completedAtDate ? "" : t("member_book_date_error_message");
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputChangeDate = (value) => {
    if (value <= new Date()) {
      setCompletedAtDate(value);
      setErrors({
        ...errors,
        completedAt: "",
      });
    } else {
      setCompletedAtDate(null);
      setErrors({
        ...errors,
        completedAt: t("tutorial_date_error_message"),
      });
    }
  };

  const handleInputChangeRemarks = (e) => {
    const value = e.target.value;
    setBooksValues((prevValues) => ({
      ...prevValues,
      remarks: value,
    }));
  };

  const handleInputChangeReference = (e) => {
    const value = e.target.value;
    setBooksValues((prevValues) => ({
      ...prevValues,
      referenceNote: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      booksValues.completedAt = convertToDateString(completedAtDate);
      ApiBooks()
        .EditBook(editValue, booksValues)
        .then((res) => {
          swal({
            title: t("member_member_detail_edit_book_msg"),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "success",
            buttons: GetSelectedLanguage() === "en" ? "OK" : "ٹھیک ہے ",
          });
          setRefresh(!refresh);
          setEditBookModel(false);
          setLoading(false);
        })
        .catch((err) => {
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
          setLoading(false);
        });
    }
  };
  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="settings_edit_books" />}
          </ModalHeader>
          {!loading ? (
            <Form onSubmit={handleSubmit}>
              <ModalBody>
                <FormGroup>
                  <Label>{<GetLanguageString props="common_name" />}</Label>
                  <Input
                    autoFocus
                    type="text"
                    name="name"
                    className="ur no-drag"
                    value={booksValues.bookName}
                    disabled
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="col-form-label">
                    {
                      <GetLanguageString props="member_member_detail_books_date" />
                    }
                  </Label>
                  <DatePicker
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd/mm/yyyy"
                    className="form-control no-drag"
                    selected={completedAtDate}
                    maxDate={new Date()}
                    onChange={handleInputChangeDate}
                    dateFormat="dd/MM/yyyy"
                  />
                  {errors.completedAt && (
                    <h4 className="text-error">{errors.completedAt}</h4>
                  )}
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="common_remarks" />}
                    </Label>
                    <Input
                      className={
                        isUrdu(booksValues.remarks) ? "ur no-drag" : "no-drag"
                      }
                      type="text"
                      name="remarks"
                      value={booksValues.remarks}
                      onChange={handleInputChangeRemarks}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="member_reference_note" />}
                    </Label>
                    <Input
                      className={
                        isUrdu(booksValues.referenceNote)
                          ? "ur no-drag"
                          : "no-drag"
                      }
                      type="text"
                      name="referenceNote"
                      value={booksValues.referenceNote}
                      onChange={handleInputChangeReference}
                    />
                  </FormGroup>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="white" onClick={toggle}>
                  {<GetLanguageString props="common_cancel" />}
                </Button>
                <Button color="primary" type="submit">
                  {<GetLanguageString props="common_save_changes" />}
                </Button>
              </ModalFooter>
            </Form>
          ) : (
            LoadingSpinner()
          )}
        </Modal>
      </Draggable>
    </div>
  );
};

export default EditBook;
