import axios from "axios";

import { BASEURL } from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";

export function ApiNotifications() {
  const userToken = encryptStorage.getItem("token");

  const authAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const fileAuthAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    responseType: "arraybuffer",
  });

  const url = BASEURL + "notifications/";
  return {
    getNotificationList:  (record)  => authAxios.post(url + "getList", record),
    getMemberRequests:    (record)   => authAxios.post(url + "getMemberRequestList", record),
   
    generateNotificationsReport: (reportData) => 
      fileAuthAxios.post(url + "GenerateNotificationsReport/", reportData),
    generateMemberNotificationsReport: (reportData) => 
      fileAuthAxios.post(url + "GenerateNotificationsReport/", reportData),
 
    generateNotificationsPdfForm: (id, type) => authAxios.get(url + "generateNotificationsPdfForm/" + id + "/" + type),
    getNotificationsSummary: (responsibility_ids, unitId) =>
      authAxios.get(
        url + "getNotificationsSummary/" + responsibility_ids + "/" + unitId
      ),
    getNotificationById: (id) => authAxios.get(url + "edit/" + id),
    NotificationDocsSave: (files) =>
      authAxios.post(url + "NotificationDocsSave/", files),
    GetNotificationsDocs: (notificationId, serialNumber, docType) =>
      authAxios.get(
        url +
          "GetNotificationDocs/" +
          notificationId +
          "/" +
          serialNumber +
          "/" +
          docType
      ),
    GetNotificationTypes: () => authAxios.get(url + "GetNotificationTypes"),
    GetApprovalStatuses: () => authAxios.get(url + "GetApprovalStatuses"),
    GetResponsibilitiesForNotifications: (memberId) =>
      authAxios.get(url + "GetResponsibilitiesForNotifications/" + memberId),
    CancelReqeustNotification: (record) =>
      authAxios.put(url + "CancelReqeustNotification", record),
    EditData: (notificationId) =>
      authAxios.get(url + "EditNotificationRequest/" + notificationId),
    EditMoveMemberNotificationRequest: (notificationId, record) =>
      authAxios.put(
        url + "EditMoveMemberNotificationRequest/" + notificationId,
        record
      ),
    EditAddRafiqNotificationRequest: (notificationId, record) =>
      authAxios.put(
        url + "EditAddRafiqNotificationRequest/" + notificationId,
        record
      ),
    GetNotificationRelevantUnits: () =>
      authAxios.get(url + "GetNotificationRelevantUnits"),
      
      GetMemberRequestsRelevantUnits: () =>
    authAxios.get(url + "GetMemberRequestsRelevantUnits"),
  };
}
