import React, { useEffect, useState } from "react";
import { ButtonGroup, Button, Row } from "reactstrap";

import { GetLanguageString } from "../../helper/Components";
import { SelectOnlyDate } from "../../helper/Method";

const MonthFilterUI = ({ values, setValues, checkDisabled }) => {
  const [currentMonth, setCurrentMonth] = useState("primary");
  const [lastThreeMonth, setLastThreeMonth] = useState("white");
  const [lastMonth, setLastMonth] = useState("white");
  const [lastSixMonth, setLastSixMonth] = useState("white");
  const [lastYear, setLastYear] = useState("white");

  useEffect(() => {
    if (!checkDisabled) {
      setCurrentMonth("primary");
      setLastMonth("white");
      setLastThreeMonth("white");
      setLastSixMonth("white");
      setLastYear("white");
    }
  }, [checkDisabled]);

  const handleMonthStatus = (e) => {
    const { value } = e.target;
    let date = new Date();
    let currentDate = new Date();
    var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
    if (value === "0") {
      setLastMonth("white");
      setCurrentMonth("primary");
      setLastThreeMonth("white");
      setLastSixMonth("white");
      setLastYear("white");
      date.setDate(1);
      setValues({
        ...values,
        startDate: SelectOnlyDate(date),
        endDate: SelectOnlyDate(currentDate),
      });
    } else if (value === "1") {
      setLastMonth("primary");
      setCurrentMonth("white");
      setLastSixMonth("white");
      setLastThreeMonth("white");
      setLastYear("white");
      let firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
      setValues({
        ...values,
        startDate: SelectOnlyDate(firstDay),
        endDate: SelectOnlyDate(lastDay),
      });
    } else if (value === "2") {
      setLastMonth("white");
      setCurrentMonth("white");
      setLastThreeMonth("white");
      setLastSixMonth("primary");
      setLastYear("white");
      let firstDay = new Date(date.getFullYear(), date.getMonth() - 5, 1);
      setValues({
        ...values,
        startDate: SelectOnlyDate(firstDay),
        endDate: SelectOnlyDate(currentDate),
      });
    } else if (value === "3") {
      setLastMonth("white");
      setCurrentMonth("white");
      setLastSixMonth("white");
      setLastThreeMonth("white");
      setLastYear("primary");
      let firstDay = new Date(date.getFullYear(), date.getMonth() - 11, 1);
      setValues({
        ...values,
        startDate: SelectOnlyDate(firstDay),
        endDate: SelectOnlyDate(currentDate),
      });
    } else if (value === "4") {
      setLastMonth("white");
      setCurrentMonth("white");
      setLastSixMonth("white");
      setLastThreeMonth("primary");
      setLastYear("white");
      let firstDay = new Date(date.getFullYear(), date.getMonth() - 2, 1);
      setValues({
        ...values,
        startDate: SelectOnlyDate(firstDay),
        endDate: SelectOnlyDate(currentDate),
      });
    }
  };

  return (
    <Row>
      <ButtonGroup style={{ padding: 32 }}>
        <Button
          disabled={checkDisabled}
          color={currentMonth}
          value={0}
          onClick={handleMonthStatus}
        >
          {<GetLanguageString props="funds_report_current_month" />}
        </Button>
        <Button
          disabled={checkDisabled}
          color={lastMonth}
          value={1}
          onClick={handleMonthStatus}
        >
          {<GetLanguageString props="funds_report_previous_month" />}
        </Button>
        <Button
          disabled={checkDisabled}
          color={lastThreeMonth}
          value={4}
          onClick={handleMonthStatus}
        >
          {<GetLanguageString props="quick_reports4_3_months" />}
        </Button>
        <Button
          disabled={checkDisabled}
          color={lastSixMonth}
          value={2}
          onClick={handleMonthStatus}
        >
          {<GetLanguageString props="funds_report_6_months" />}
        </Button>
        <Button
          disabled={checkDisabled}
          color={lastYear}
          value={3}
          onClick={handleMonthStatus}
        >
          {<GetLanguageString props="funds_report_year" />}
        </Button>
      </ButtonGroup>
    </Row>
  );
};

export default MonthFilterUI;
