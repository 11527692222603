import axios from "axios";

import { BASEURL } from "../../../constants/const";
import { encryptStorage } from "../../../constants/EncryptStorage";

export function ApiEventType() {
  const userToken = encryptStorage.getItem("token");

  const authAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const url = BASEURL + "eventtype/";
  return {
    getStaticEventTypeFrequency: () =>
      authAxios.get(url + "getStaticEventTypeFrequency/"),
    getEventTypeCode: () =>
      authAxios.get(url + "getEventTypeCode/"),
    getUnitEventTypeList: (unitId, includeChildUnitEvents) =>
      authAxios.get(url + "getUnitEventTypeList/" + unitId + "/" + includeChildUnitEvents),
    getEventSubType: () => authAxios.get(url + "getEventSubType/"),
  };
}
