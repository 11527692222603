import React, { useState, useEffect } from "react";
import { t } from "i18next";
import { Button, ButtonGroup, Table, Row } from "reactstrap";
import { GetLanguageString, NoResult } from "../../helper/Components";
import { addMember } from "../ApiMember";
import moment from "moment";
import { convertUTCToLocalTime,isUrdu,LoadingSpinner } from "../../../constants/const";
import swal from "sweetalert";
import { GetSelectedLanguage, GetCustomStyles } from "../../helper/Method";
import AddBook from "../Books/AddBook";
import EditBook from "../Books/EditBook";

const UpdateBooks = ({ tab13, memberId, discard, setDiscard, activeTab }) => {
  const [books, setBooks] = useState();
  
  const [addBookModel, setAddBookModel] = useState(false);
  const [editBookModel, setEditBookModel] = useState(false);
  const [editValue, setEditValue] = useState();
  const [canAddBooks, setCanAddBooks] = useState(true);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  // Load the Data
  useEffect(() => {
    if (memberId !== 0) {
      setLoading(true);
      addMember()
        .GetMemberBooksById(memberId)
        .then((res) => {
          setBooks(res.data.memberBooksModel);
          setCanAddBooks(res.data.canAdd);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [memberId, refresh]);

  useEffect(()=>{
    if(tab13 === "active"){
      if(activeTab && activeTab !== "tab13"){
        setDiscard(!discard);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[activeTab])

  //   handle the data edit
  const handleOnEdit = (e) => {
    const  value  = e.target.value;
    setEditValue(value);
    setEditBookModel(true);
  };

  //   handle the data delete
  const handleDelete = (id) => {
    swal({
      title: t("common_delete_message"),
      text: t("common_delete_detail_message"),
      className: GetSelectedLanguage() === "en" ? "" : "ur",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        addMember()
          .deleteMemberBooks(id)
          .then(() => {
            swal({
              title: t("member_delete_book"),
              icon: "success",
              className: GetSelectedLanguage() === "en" ? "" : "ur",
              buttons: "OK",
            });
            setRefresh(!refresh);
          })
          .catch((err) => {            
            swal({
              title: err,
              icon: "error",
              buttons: "OK",
            });
            setLoading(false);
            console.log(err);
          });
      }
    });
  };
  return (
    <div role="tabpanel" id="tab12" className={"tab-pane " + tab13}>
      {addBookModel  ? (
      <AddBook
        setAddBookModel={setAddBookModel}
        setBooks={setBooks}
        memberId={memberId}
        setRefresh={setRefresh}
        refresh={refresh}
      />
    ) : null}
      {editBookModel && (
        <EditBook 
          editValue={editValue}
          setEditBookModel={setEditBookModel}
          setRefresh={setRefresh}
          refresh ={refresh} />
      )}
      {!loading ? (
        <div className="panel-body">
          {canAddBooks && (
          <Row>
            <div className={GetCustomStyles().btn_style}>
              <Button
                color="primary"
                size="sm"
                className={GetCustomStyles().btn_style}
                onClick={() => setAddBookModel(true)}
              >
                <GetLanguageString props="settings_add_books" />
              </Button>
            </div>
          </Row>
          )}
          {books?.length !== 0 ? (
            <div className="table-responsive">
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th className="text-center">
                      {
                        <GetLanguageString props="member_member_detail_books_book_name" />
                      }
                    </th>
                    <th className="text-center">
                      {
                        <GetLanguageString props="member_member_detail_books_date" />
                      }
                    </th>
                    <th>{<GetLanguageString props="common_remarks" />}</th>
                    <th>
                      {<GetLanguageString props="member_reference_note" />}
                    </th>
                    <th className="text-center">
                      {<GetLanguageString props="common_action" />}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {books?.map((record, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td className="ur text-center ">{record.bookName}</td>
                        <td className="text-center">
                          {record.completedAt
                            ? moment(
                                convertUTCToLocalTime(record.completedAt)
                              ).format("DD/MM/yyyy")
                            : "-"}
                        </td>
                        <td className={isUrdu(record.remarks) ? "ur no-drag" : "no-drag"}>
                          {record.remarks}</td>
                        <td className={isUrdu(record.referenceNote) ? "ur no-drag" : "no-drag"}>
                          {record.referenceNote}</td>
                        <td className="text-center">
                          <ButtonGroup>
                            <Button
                             color="white"
                                size="sm"
                                onClick={handleOnEdit}
                                value={record.id}
                            >
                              <GetLanguageString props="common_edit" />
                            </Button>{" "}
                            <Button
                              color="white"
                              size="sm"
                              onClick={() => handleDelete(record.id)}
                            >
                              <GetLanguageString props="common_delete" />
                            </Button>{" "}
                          </ButtonGroup>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          ) : (
            <NoResult />
          )}
        </div>
      ) : (
        LoadingSpinner()
      )}
    </div>
  );
};

export default UpdateBooks;
