import { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import IdleTimer from "react-idle-timer";

import LoginSignUp from "../loginSignUp/LoginSignUp";
import Layout from "../shared/Layout.js";
import Logout from "../logout/Logout";
import { encryptStorage } from "../../constants/EncryptStorage";
import UpdateToken from "../../UpdateToken";
import jwtDecode from "jwt-decode";

const tokenString = encryptStorage.getItem("token");
let localToken = "";
if (tokenString !== undefined) localToken = tokenString;

const LoginRedirect = () => {
  const [values, setValues] = useState({ token: "" });
  const [doLogout, setDoLogout] = useState(false);
  const [isTokenExist, setIsTokenExist] = useState(false);
  useEffect((values) => {
    setValues({
      ...values,
      token: localToken,
    });
    setIsTokenExist(true);
  }, []);

  if (!isTokenExist) {
    return null;
  }

  if (!values.token) {
    return <LoginSignUp setValues={setValues} />;
  }

  if (values.token) {
    let token = jwtDecode(values.token);
    let tokenTime = new Date(token.exp * 1000)
    tokenTime.setMinutes(tokenTime.getMinutes() + 1);
    const currentTime = new Date();
    if (tokenTime < currentTime) {
      return <Logout setDoLogout={setDoLogout} />;
    }
  }

  const handleOnIdle = (event) => {
    setDoLogout(true);
  };
  return (
    <Router>
      <IdleTimer
        timeout={1000 * 60 * 15} // 15 minutes
        onIdle={handleOnIdle}
      />
      <UpdateToken setDoLogout={setDoLogout} />
      {doLogout ? <Logout setDoLogout={setDoLogout} /> : <Layout />}
    </Router>
  );
}

export default LoginRedirect;
