import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Label,
  Button,
  Alert,
} from "reactstrap";
import Draggable from "react-draggable";

import { GetLanguageString } from "../helper/Components";

const UsersCredential = ({ setModal, setValues, values }) => {
  const [copyText, setCopyText] = useState(false);
  const toggle = () => {
    setModal(false);
    setValues({ id: "", username: "", password: "" });
  };
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  const copy = () => {
    /* Copy the text inside the text field */
    navigator.clipboard.writeText(
      "UserName : " + values.username + "\nPassword : " + values.password
    );
    setCopyText(true);
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal">
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="users_credential" />}
          </ModalHeader>
          <Form>
            <ModalBody>
              <FormGroup>
                <Label>
                  <b>{<GetLanguageString props="user_user_name" />}: </b>
                </Label>
                <Label   className="no-drag"> {values.username}</Label>
              </FormGroup>
              <FormGroup>
                <Label>
                  <b>{<GetLanguageString props="user_password" />}: </b>
                </Label>
                <Label className="no-drag"> {values.password}</Label>
              </FormGroup>
              {copyText ? (
                <Alert>
                  {
                    <GetLanguageString props="users_credential_copied_to_clipboard" />
                  }
                </Alert>
              ) : null}
            </ModalBody>
            <ModalFooter>
              <Button color="white" onClick={toggle}>
                {<GetLanguageString props="common_cancel" />}
              </Button>
              <Button color="white" onClick={copy}>
                {<GetLanguageString props="user_copy_button" />}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </Draggable>
    </div>
  );
};

export default UsersCredential;
