import { useEffect, useState } from "react";
import { encryptStorage } from "../../../constants/EncryptStorage";
import { addMember } from "../../member/ApiMember";
import logo from "./default-image.jpg";
import ImageDisplay from "../../helper/ImageDisplay";
import { LoadingSpinner } from "../../../constants/const";

const ProfilePhoto = () => {
  const [imageUrl, setImageUrl] = useState(null);
  const [croppedimage, setCroppedImage] = useState(null);
  const [config, setConfig] = useState({
    unit: 'px',
    aspect: 0.9,
  });

  const memberId = encryptStorage.getItem("memberId");

  useEffect(() => {
    addMember()
      .getMemberPhoto(memberId)
        .then((res) => {
          setImageUrl("data:image/jpeg;base64," + res.data.image);
          setConfig({
            ...config,
            x: res.data.config.x,
            y: res.data.config.y,
            scaleX: res.data.config.scaleX,
            scaleY: res.data.config.scaleY,
            width: res.data.config.width,
            height: res.data.config.height,
            scaleWidth: res.data.config.scaleWidth,
            scaleHeight: res.data.config.scaleHeight,
          });

          if(res.data === ""){
            setImageUrl(logo);
          }
        })
        .catch((err) => {
        })
  //  eslint-disable-next-line
  }, [memberId]);

  return (
    <>
      { imageUrl && config !== null && config.scaleWidth > 0 &&
        <ImageDisplay 
          imageToCrop={imageUrl}
          config={config}
          onImageCropped={(croppedImage) => {
            setCroppedImage(croppedImage)
          }}
          autoCrop={true}
        />
      }
      <img
        alt="images"
        className="rounded-circle"
        style={{ width: "100px", height: "100px" }}
        src={
          imageUrl !== null ? 
            config !== null && config.scaleWidth > 0 ? 
              croppedimage ? 
                croppedimage
                : LoadingSpinner()
              : imageUrl 
          : logo}
      />
    </>
  );
};
export default ProfilePhoto;