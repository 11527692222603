import axios from "axios";

import { BASEURL } from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";

export function ApiBooks() {
  const userToken = encryptStorage.getItem("token");

  const authAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const url = BASEURL + "books/";
  return {
    getBooksList: (memberId) =>
      authAxios.get(url + "GetBooksList/" + memberId),
    updateBooks: (values) => 
      authAxios.post(url + "UpdateBooks/", values),
    getAllBooksForMember: (memberId) =>
      authAxios.get(url + "GetAllBooksForMember/" + memberId),
    addBook: (bookValues) => authAxios.post(url + "AddBook/", bookValues),
    GetBookById: (bookid) => authAxios.get(url + "GetBookById/" + bookid),
    EditBook: (id, bookValues) => 
    authAxios.put(url + "EditBook/" + id,bookValues)
  };
}
