import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";

import { GetLanguageString } from "../helper/Components";
import { ApiUnit } from "../unit/ApiUnit";
import { encryptStorage } from "../../constants/EncryptStorage";

const SelectMemberUnit = ({ setValues, values, loggedInUnitId = 0, unitId = 0, isDeleted = false, showExcludeUnit = true }) => {
  loggedInUnitId = loggedInUnitId === 0 ? parseInt(encryptStorage.getItem("loggedInUnitId")) : loggedInUnitId;
  
  const [unitList, setUnitList] = useState([]);
  const [excludeExUnit, setExcludeExUnit] = useState(true);
  if (unitId === 0) unitId = loggedInUnitId;
  const unitTypeName = "null";
  useEffect(() => {
    ApiUnit()
      .getUnitList(unitId, loggedInUnitId, isDeleted, unitTypeName, excludeExUnit)
      .then((res) => {
        setUnitList(res.data);
      })
      .catch((err) => console.log(err));
  }, [unitId, loggedInUnitId, isDeleted, excludeExUnit]);

  const handleChangeUnits = (record) => {
    if (record !== null) {
      ApiUnit()
        .getUnitList(record.value, loggedInUnitId, isDeleted, unitTypeName, excludeExUnit)
        .then((res) => {
          setUnitList(res.data);
        })
        .catch((err) => console.log(err));

      setValues({
        ...values,
        unit: record,
      });
    }
  };

  const handleExcludeExUnit = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setExcludeExUnit(v);
  };

  return (
    <>
      <Row>
        {unitList.map((record, index) => {
          const unitOptions = [];
          record.unitList.forEach((d) =>
            unitOptions.push({ value: d.id, label: d.name, unitTypeName: d.unitTypeName })
          );
          let selected = {};
          let unitName = "";
          if (record.parentUnitName !== null) unitName = record.parentUnitName;
          if (record.selectedUnit !== null) {
            selected = {
              value: record.selectedUnit.id,
              label: record.selectedUnit.name,
              unitTypeName: record.selectedUnit.unitTypeName
            };
          }

          return (
            <Col xl="3" key={index}>
              <FormGroup>
                <Label className="col-form-label">
                  {unitName !== "" ? (
                    <GetLanguageString
                      props="member_select_unit_name"
                      value={unitName}
                    />
                  ) : (
                    <GetLanguageString props="member_select_unit" />
                  )}
                </Label>
                <Select
                  options={unitOptions}
                  value={selected}
                  className="basic-single ur"
                  classNamePrefix="select"
                  onChange={handleChangeUnits}
                />
              </FormGroup>
            </Col>
          );
        })}
      </Row>
      {showExcludeUnit ?
      <Row>
      <Col md="4">
        <FormGroup check>
          <Label check for="excludeExUnit">
            <Input
              id="excludeExUnit"
              type="checkbox"
              name="excludeExUnit"
              checked={!excludeExUnit}
              value={!excludeExUnit}
              onChange={handleExcludeExUnit}
            />
            {<GetLanguageString props="member_search_member_ex_unit" />}
          </Label>
        </FormGroup>
      </Col>
      </Row> : null}
    </>
  );
};

export default SelectMemberUnit;
