import React, { useEffect, useState } from "react";
import { useLocation, withRouter } from "react-router-dom";
import { Button, ButtonGroup, Col, Input, Label, Row } from "reactstrap";

import { IoAdd } from "react-icons/io5";
import { requestApi } from "../../actions/api";
import {
  CheckIsAdmin,
  CheckPermission,
  LoadingSpinner,
} from "../../constants/const";
import AddAhbabModel from "../ahbab/AddAhbabModel";
import { GetLanguageString } from "../helper/Components";
import { GetCustomStyles, GetSelectedLanguage } from "../helper/Method";
import SearchAutoComplete from "../shared/SearchAutoComplete";
import { ApiCourse } from "./ApiCourse";
import ParticipantsList from "./ParticipantsList";
import { useTranslation } from "react-i18next";

const CourseParticipants = ({ history }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const courseId = parseInt(location.state.courseId);
  const [isPublished, setIsPublished] = useState(location.state.published);
  const areAhbabAllowed = location.state.ahbabAllowed;
  const [loading, setloading] = useState(false);
  const [members, setMembers] = useState([]);
  const [ahbab, setAhbab] = useState([]);
  const [habibName, setHabibName] = useState("");
  const [rafiqName, setRafiqName] = useState("");
  const [memberId, setMemberId] = useState(0);
  const [comments, setComments] = useState();
  const [renderParticipants, setRenderParticipants] = useState(false);
  const [selectedRafiqName, setSelectedRafiqName] = useState("select");
  const [selectedHabibName, setSelectedHabibName] = useState("select");
  const [status, setStatus] = useState(true);
  const [completeColor, setCompleteColor] = useState("primary");
  const [inCompleteColor, setInCompleteColor] = useState("white");
  const [searchType, setSearchType] = useState("");
  const [loadingRafiqSearch, setLoadingRafiqSearch] = useState(false);
  const [loadingHabibSearch, setLoadingHabibSearch] = useState(false);

  const [callAddAhbabModel, setCallAddAhbabModel] = useState(false);

  useEffect(() => {
    if (
      searchType === "ahbab" &&
      habibName !== "" &&
      !habibName.includes("/")
    ) {
      setLoadingHabibSearch(true);
      const timeoutId = setTimeout(() => {
        ApiCourse()
          .SearchCourseParticipants(habibName, searchType, courseId)
          .then((res) => {
            setAhbab(res.data);
            setLoadingHabibSearch(false);
          })
          .catch((err) => {
            setLoadingHabibSearch(false);
            console.log(err);
          });
      }, 1500);
      return () => clearTimeout(timeoutId);
    }
    setAhbab([]);
    setLoadingHabibSearch(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [habibName, courseId]);

  useEffect(() => {
    if (
      searchType === "rufqa" &&
      rafiqName !== "" &&
      !rafiqName.includes("/")
    ) {
      setLoadingRafiqSearch(true);
      const timeoutId = setTimeout(() => {
        ApiCourse()
          .SearchCourseParticipants(rafiqName, searchType, courseId)
          .then((res) => {
            setMembers(res.data);
            setLoadingRafiqSearch(false);
          })
          .catch((err) => {
            setLoadingRafiqSearch(false);
            console.log(err);
          });
      }, 1500);
      return () => clearTimeout(timeoutId);
    }
    setMembers([]);
    setLoadingRafiqSearch(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rafiqName, courseId]);

  const handleOnChange = (e) => {
    const { value } = e.target;
    setComments(value);
  };

  const handleOnClick = (e) => {
    const { value } = e.target;
    if (value === "1") {
      setStatus(true);
      setCompleteColor("primary");
      setInCompleteColor("white");
    } else if (value === "0") {
      setStatus(false);
      setInCompleteColor("primary");
      setCompleteColor("white");
    }
  };
  const handleOnSave = () => {
    setloading(true);
    let courseParticipant = {
      memberId: memberId,
      courseId: courseId,
      status: status,
      comments: comments,
    };

    requestApi("courseparticipant/")
      .create(courseParticipant)
      .then((res) => {
        setSelectedHabibName("select");
        setSelectedRafiqName("select");
        setHabibName("");
        setRafiqName("");
        setloading(false);
        setRenderParticipants(!renderParticipants);
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };

  const handleOnSelect = (item, type) => {
    if (item !== null && item !== undefined) {
      var str = item?.name;
      let remove = str.split(" - ").pop();
      let requiredName = str.replace(" - " + remove, "   ");
      if (type === "rufqa") {
        setSelectedRafiqName(item.name);
        setRafiqName(requiredName + " - " + remove.replace("/", " "));
      } else {
        setSelectedHabibName(item.name);
        setHabibName(requiredName + " - " + remove.replace("/", " "));
      }
      setMemberId(item.id);
      setMembers([]);
    }
  };

  return (
    <div>
      {callAddAhbabModel && (
        <AddAhbabModel
          setCallAddAhbabModel={setCallAddAhbabModel}
          setRenderParticipants={setRenderParticipants}
          courseId={courseId}
        />
      )}
      {!loading ? (
        <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
          {!isPublished ? (
            <div className="ibox">
              <div className="ibox-title">
                {!areAhbabAllowed ? (
                  <h5>{<GetLanguageString props="member_search_member" />}</h5>
                ) : null}
                <ButtonGroup className={GetCustomStyles().unit_btn_style}>
                  {CheckPermission("CanAddAhbab") || CheckIsAdmin() ? (
                    <Button
                      color="primary"
                      size="xs"
                      onClick={() => {
                        setCallAddAhbabModel(true);
                      }}
                    >
                      {" "}
                      <IoAdd
                        size={15}
                        style={{ marginRight: 3, marginBottom: 1 }}
                      />
                      {<GetLanguageString props="ahbab_add" />}
                    </Button>
                  ) : null}
                  <Button
                    color="success"
                    size="xs"
                    onClick={() =>
                      history.push({
                        pathname: `/course/addbulkparticipant`,
                        state: {
                          courseId: courseId,
                        },
                      })
                    }
                  >
                    {" "}
                    <IoAdd
                      size={15}
                      style={{ marginRight: 3, marginBottom: 1 }}
                    />
                    {<GetLanguageString props="participant_add_bulk" />}
                  </Button>
                  <Button
                    color="primary"
                    size="xs"
                    onClick={() => history.goBack()}
                  >
                    {<GetLanguageString props="courses_participants_back" />}
                  </Button>
                </ButtonGroup>
              </div>
              {!areAhbabAllowed ? (
                <div className="ibox-content">
                  <div className={GetSelectedLanguage() === "ur" ? "ur" : ""}>
                    <SearchAutoComplete
                      members={members}
                      setMembers={setMembers}
                      setName={setRafiqName}
                      setSelectedName={setSelectedRafiqName}
                      name={rafiqName}
                      handleOnSelect={handleOnSelect}
                      setSearchType={setSearchType}
                      type={"rufqa"}
                      placeholder={t("participant_add_rafiq")}
                      loading={loadingRafiqSearch}
                      calledFrom="course"
                    />
                  </div>
                  {selectedRafiqName !== "select" ? (
                    <>
                      <Row className="m-t-lg">
                        <Col md="3">
                          <Label>
                            <b>
                              {
                                <GetLanguageString props="courses_participants_status" />
                              }
                            </b>
                          </Label>
                        </Col>
                        <Col md="3">
                          <b>
                            <Label>
                              {
                                <GetLanguageString props="courses_participants_comments" />
                              }
                            </Label>
                          </b>
                        </Col>
                      </Row>
                      <Row className="m-t">
                        <Col md="3">
                          <ButtonGroup>
                            <Button
                              color={completeColor}
                              size="sm"
                              onClick={handleOnClick}
                              value="1"
                            >
                              {
                                <GetLanguageString props="courses_status_completed" />
                              }
                            </Button>
                            <Button
                              color={inCompleteColor}
                              size="sm"
                              onClick={handleOnClick}
                              value="0"
                            >
                              {
                                <GetLanguageString props="courses_status_incompleted" />
                              }
                            </Button>
                          </ButtonGroup>
                        </Col>
                        <Col md="3">
                          <Input
                            className="ur"
                            type="text"
                            name="comments"
                            value={comments}
                            onChange={handleOnChange}
                          />
                        </Col>
                        <Col md="4">
                          <Button
                            color="primary"
                            size="sm"
                            onClick={handleOnSave}
                          >
                            {<GetLanguageString props="common_save" />}
                          </Button>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                </div>
              ) : (
                <div className="ibox">
                  <div className="ibox-content">
                    <div className="ibox">
                      <Row>
                        <Col md="6">
                          <div className="ibox-title">
                            <h5>
                              {
                                <GetLanguageString props="member_search_member" />
                              }
                            </h5>
                          </div>
                          <div className="ibox-content">
                            <Row>
                              <Col
                                md="12"
                                className={
                                  GetSelectedLanguage() === "ur" ? "ur" : ""
                                }
                              >
                                <SearchAutoComplete
                                  members={members}
                                  setMembers={setMembers}
                                  setName={setRafiqName}
                                  name={rafiqName}
                                  setSelectedName={setSelectedRafiqName}
                                  setSearchType={setSearchType}
                                  type={"rufqa"}
                                  loading={loadingRafiqSearch}
                                  placeholder={t("participant_add_rafiq")}
                                  handleOnSelect={handleOnSelect}
                                  calledFrom="course"
                                />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="ibox-title">
                            <h5>
                              {<GetLanguageString props="ahbab_search" />}
                            </h5>
                          </div>
                          <div className="ibox-content">
                            <Row>
                              <Col
                                md="12"
                                className={
                                  GetSelectedLanguage() === "ur" ? "ur" : ""
                                }
                              >
                                <SearchAutoComplete
                                  members={ahbab}
                                  setMembers={setAhbab}
                                  setName={setHabibName}
                                  name={habibName}
                                  setSelectedName={setSelectedHabibName}
                                  setSearchType={setSearchType}
                                  type={"ahbab"}
                                  loading={loadingHabibSearch}
                                  placeholder={t("participant_add_habib")}
                                  handleOnSelect={handleOnSelect}
                                  calledFrom="course"
                                />
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>{" "}
                    {selectedHabibName !== "select" ||
                    selectedRafiqName !== "select" ? (
                      <div>
                        <Row className="m-t-lg">
                          <Col md="3">
                            <Label>
                              <b>
                                {
                                  <GetLanguageString props="courses_participants_status" />
                                }
                              </b>
                            </Label>
                          </Col>
                          <Col md="3">
                            <b>
                              <Label>
                                {
                                  <GetLanguageString props="courses_participants_comments" />
                                }
                              </Label>
                            </b>
                          </Col>
                        </Row>
                        <Row className="m-t">
                          <Col md="3">
                            <ButtonGroup>
                              <Button
                                color={completeColor}
                                size="sm"
                                onClick={handleOnClick}
                                value="1"
                              >
                                {
                                  <GetLanguageString props="courses_status_completed" />
                                }
                              </Button>
                              <Button
                                color={inCompleteColor}
                                size="sm"
                                onClick={handleOnClick}
                                value="0"
                              >
                                {
                                  <GetLanguageString props="courses_status_incompleted" />
                                }
                              </Button>
                            </ButtonGroup>
                          </Col>
                          <Col md="3">
                            <Input
                              className="ur"
                              type="text"
                              name="comments"
                              value={comments}
                              onChange={handleOnChange}
                            />
                          </Col>
                          <Col md="4">
                            <Button
                              color="primary"
                              size="sm"
                              onClick={handleOnSave}
                            >
                              {<GetLanguageString props="common_save" />}
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
          ) : null}
          <ParticipantsList
            courseId={courseId}
            renderParticipants={renderParticipants}
            setRenderParticipants={setRenderParticipants}
            setIsPublished={setIsPublished}
            isPublished={isPublished}
          />
        </div>
      ) : (
        LoadingSpinner()
      )}
    </div>
  );
};

export default withRouter(CourseParticipants);
