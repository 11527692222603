import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import swal from "sweetalert";
import { LoadingSpinner } from "../../../constants/const";
import { encryptStorage } from "../../../constants/EncryptStorage";
import { GetLanguageString } from "../../helper/Components";
import { GetSelectedLanguage } from "../../helper/Method";
import SearchAutoComplete from "../../shared/SearchAutoComplete";
import { ApiUnit } from "../../unit/ApiUnit";
import { addMember } from "../ApiMember";
import { ApiMudaris } from "./ApiMudaris";

const MemberMudarisCreateProfile = ({
  history,
  setCreateProfileModal,
  setRefresh,
  refresh,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [memberExists, setMemberExists] = useState(true);
  const [searchByName, setSearchByName] = useState(true);
  const [searchByRegNo, setSearchByRegNo] = useState(false);
  const [unitList, setUnitList] = useState([]);
  const unitTypeName = "null";
  const [regNo, setRegNo] = useState(0);
  const [memberId, setMemberId] = useState(0);
  const [createProfileData, setCreateProfileData] = useState({
    memberId: memberId,
    memberRegNo: regNo,
    unit: {
      value: loggedInUnitId,
      label: "",
    },
  });
  const [errors, setErrors] = useState({ member: "" });
  const toggle = () => setCreateProfileModal(false);

  const [members, setMembers] = useState([]);
  const [name, setName] = useState("");
  const { t } = useTranslation();
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  const selectedLanguage = GetSelectedLanguage();

  useEffect(() => {
    if (name !== "" && !name.includes("/")) {
      setLoadingSearch(true);
      const timeoutId = setTimeout(() => {
        addMember()
          .getUnitParticipants(createProfileData.unit.value, name)
          .then((res) => {
            setMembers(res.data);
            setLoadingSearch(false);
          })
          .catch((err) => {
            console.log(err);
            setLoadingSearch(false);
          });
      }, 1500);
      return () => clearTimeout(timeoutId);
    }
    setMembers([]);
    setLoadingSearch(false);
  }, [createProfileData.unit.value, name]);

  const handleRegNo = (e) => {
    setRegNo(e.target.value);
    setErrors({
      ...errors,
      member: "",
    });
  };

  const handleOnSelect = (item) => {
    var str = item?.name;
    let remove = str.split(" - ").pop();
    let requiredName = str.replace(" - " + remove, "   ");
    setName(requiredName + " - " + remove.replace("/", " "));
    setMemberId(item.id);
    setErrors({
      ...errors,
      member: "",
    });
    setMembers([]);
  };

  useEffect(() => {
    ApiUnit()
      .getUnitList(
        createProfileData.unit.value,
        loggedInUnitId,
        false,
        unitTypeName,
        true
      )
      .then((res) => {
        setUnitList(res.data);
      })
      .catch((err) => console.log(err));
  }, [createProfileData.unit.value, loggedInUnitId]);

  const handleChangeUnits = (record) => {
    if (record !== null) {
      ApiUnit()
        .getUnitList(record.value, loggedInUnitId, false, unitTypeName, true)
        .then((res) => {
          setUnitList(res.data);
        })
        .catch((err) => console.log(err));

      setCreateProfileData({
        ...createProfileData,
        unit: { label: record.label, value: record.value },
      });
    }
  };

  const validate = () => {
    let temp = {};
    temp.member =
      memberId !== 0 || regNo !== 0
        ? ""
        : selectedLanguage === "ur"
        ? "براہ مہربانی رفیق کا انتخاب کریں "
        : "Please Select Member";

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      createProfileData.memberRegNo = regNo;
      createProfileData.memberId = memberId;
      setLoading(true);
      ApiMudaris()
        .CreateMudarisProfileNew(createProfileData)
        .then((res) => {
          if (res.data.includes("Created")) {
            swal({
              title: t(
                "member_member_detail_mudaris_details_create_profile_success_message"
              ),
              className: selectedLanguage === "en" ? "" : "ur",
              icon: "success",
              buttons: {
                cancel: "Go Back",
                confirm: t(
                  "member_member_detail_mudaris_details_create_profile_add_more_data"
                ),
              },
            }).then(
              (response) =>
                response &&
                history.push({
                  pathname: `/member/mudaris/${memberId}`,
                  state: {
                    memberId: memberId,
                    page: "edit",
                  },
                })
            );
            setLoading(false);
            setRefresh(!refresh);
          } else if (res.data.includes("exist")) {
            setMemberExists(false);
            setLoading(false);
          } else if (res.data.includes("Mudaris Already")) {
            swal({
              title: t(
                "member_member_detail_mudaris_details_create_profile_error_message"
              ),
              className: selectedLanguage === "ur" ? "ur" : "",
              icon: "error",
              buttons: "OK",
            });
            setLoading(false);
          }
          toggle();
        })
        .catch((err) => {
          swal({
            title: err.response?.data,
            icon: "error",
            buttons: "OK",
          });
          setLoading(false);
          console.log(err.response?.data);
        });
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal
          size="lg"
          style={{ maxWidth: "1200px", width: "100%" }}
          isOpen={true}
          className="inmodal"
          autoFocus={false}
        >
          <ModalHeader className="modal-title" tag="h4">
            {
              <GetLanguageString props="member_member_detail_mudaris_details_create_profile" />
            }
          </ModalHeader>
          <ModalBody>
            {!loading ? (
              <>
                <div className="ibox ">
                  <div className="ibox-title">
                    <h5>
                      {
                        <GetLanguageString props="unit_add_responsibility_search_member" />
                      }
                    </h5>
                  </div>
                  <div className="ibox-content">
                    <Row>
                      {unitList.map((record, index) => {
                        const unitOptions = [];
                        record.unitList.forEach((d) =>
                          unitOptions.push({
                            value: d.id,
                            label: d.name,
                          })
                        );
                        let selected = {};
                        let unitName = "";
                        if (record.parentUnitName !== null)
                          unitName = record.parentUnitName;
                        if (record.selectedUnit !== null) {
                          selected = {
                            value: record.selectedUnit.id,
                            label: record.selectedUnit.name,
                          };
                        }

                        return (
                          <Col md="3" key={index} style={{ zIndex: 1000 }}>
                            <FormGroup>
                              <Label className="col-form-label no-drag">
                                {unitName !== "" ? (
                                  <GetLanguageString
                                    props="member_select_unit_name"
                                    value={unitName}
                                  />
                                ) : (
                                  <GetLanguageString props="member_select_unit" />
                                )}
                              </Label>
                              <Select
                                options={unitOptions}
                                value={selected}
                                className="basic-single ur no-drag"
                                classNamePrefix="select"
                                onChange={handleChangeUnits}
                              />
                            </FormGroup>
                          </Col>
                        );
                      })}
                    </Row>
                    <br></br>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Input
                            id="name"
                            type="radio"
                            checked={searchByName}
                            onChange={() => {
                              setSearchByRegNo(false);
                              setSearchByName(true);
                              setRegNo(0);
                            }}
                          />{" "}
                          <Label for="name">
                            {
                              <GetLanguageString props="unit_add_responsibility_member_by_name" />
                            }
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label for="regNo">
                            <Input
                              id="regNo"
                              type="radio"
                              className="no-drag"
                              checked={searchByRegNo}
                              onChange={() => {
                                setSearchByName(false);
                                setSearchByRegNo(true);
                                setRegNo(0);
                              }}
                            />{" "}
                            {
                              <GetLanguageString props="unit_add_responsibility_member_by_reg_no" />
                            }
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        {!searchByRegNo ? (
                          <div
                            className={
                              GetSelectedLanguage() === "ur" ? "ur" : ""
                            }
                          >
                            <SearchAutoComplete
                              members={members}
                              setMembers={setMembers}
                              setName={setName}
                              name={name}
                              type={"rufqa"}
                              loading={loadingSearch}
                              placeholder={t("common_search_by_name")}
                              handleOnSelect={handleOnSelect}
                              calledFrom="mudaris"
                            />
                          </div>
                        ) : (
                          <Input type="text" name="reg_no" disabled />
                        )}
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Input
                            type="number"
                            name="reg_no"
                            className={
                              selectedLanguage === "ur"
                                ? "ur no-drag"
                                : "no-drag"
                            }
                            placeholder={
                              selectedLanguage === "ur"
                                ? "رجسٹریشن نمبر"
                                : "Reg No."
                            }
                            onChange={(e) => handleRegNo(e)}
                            value={regNo !== 0 ? regNo : ""}
                            disabled={searchByName}
                            {...(!memberExists &&
                              regNo !== null && { invalid: true })}
                          />
                          {!memberExists && regNo !== null ? (
                            <h4
                              style={{ color: "red", marginTop: 2 }}
                              className={selectedLanguage === "ur" ? "ur" : ""}
                            >
                              {selectedLanguage === "ur"
                                ? " براہ مہربانی درست رجسٹریشن نمبر ڈالیں. کوئی رفیق اس نظم میں اس  رجسٹریشن نمبر کے ساتھ موجود نہیں ہے"
                                : " Please enter a valid Reg No. This user doesn't exist with this Reg Number in this unit"}
                            </h4>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <br></br>
                    <Row>
                      {errors.member && (
                        <h4
                          className={
                            selectedLanguage === "ur"
                              ? "text-error text-center ur"
                              : "text-error text-center"
                          }
                        >
                          {errors.member}
                        </h4>
                      )}
                    </Row>
                  </div>
                </div>
              </>
            ) : (
              LoadingSpinner()
            )}
          </ModalBody>{" "}
          <ModalFooter>
            <Button color="white" onClick={toggle}>
              {<GetLanguageString props="common_cancel" />}
            </Button>
            <Button color="primary" type="submit" onClick={handleSubmit}>
              {<GetLanguageString props="common_save_changes" />}
            </Button>
          </ModalFooter>
        </Modal>
      </Draggable>
    </div>
  );
};

export default MemberMudarisCreateProfile;
