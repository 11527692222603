import React, { useEffect, useState } from "react";
import Select from "react-select";
import { FormGroup, Input, Label } from "reactstrap";
import { customOption } from "../../constants/const";
import { ApiResponsibility } from "../config/responsibilityType/ApiResponsibilityType";
import { GetLanguageString } from "../helper/Components";
import SelectUnitForPermissions from "./SelectUnitForPermissions";
import { encryptStorage } from "../../constants/EncryptStorage";

const Permissions = ({ permissions, setPermissions, item, index }) => {
  const [selectUnit, setSelectUnit] = useState(item.selectUnit);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [selectResponsibilities, setSelectResponsibilities] = useState(
    item.selectResponsibilities
  );
  const [values, setValues] = useState(item);
  const [selectedUnit, setSelectedUnit] = useState({});

  const [responsibility, setResponsibility] = useState([]);
  useEffect(() => {
    ApiResponsibility()
      .getResponsibilitiesByLoggedInUnit(
        values?.unit?.value === 0 ? loggedInUnitId : values?.unit?.value
      )
      .then((res) => {
        setResponsibility(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, [selectedUnit]);

  const handleSelectUnit = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;

    setSelectUnit(v);
    setValues({
      ...values,
      selectUnit: v,
    });
  };
  const handleSelectResponsibilities = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;

    setSelectResponsibilities(v);
    setValues({
      ...values,
      selectResponsibilities: v,
    });
  };

  const handleOnChangeResponsibilities = (record) => {
    setValues({
      ...values,
      responsibilities: record,
    });
  };

  useEffect(() => {
    let tempValues = [...permissions];
    tempValues[index] = values;

    setPermissions(tempValues);
    // eslint-disable-next-line
  }, [values, index]);

  return (
    <div>
      <FormGroup check>
        <Label check for={"selectUnits" + index}>
          <Input
            id={"selectUnits" + index}
            className="big-checkbox m-r-sm"
            type="checkbox"
            name={"selectUnits" + index}
            checked={selectUnit}
            value={!selectUnit}
            onChange={handleSelectUnit}
          />
          <div className="set-label">
            {" "}
            {<GetLanguageString props="member_select_unit" />}
          </div>
        </Label>
      </FormGroup>
      <br></br>
      <SelectUnitForPermissions
        unitId={values?.unit?.value}
        isDisabled={!selectUnit}
        values={values}
        setValues={setValues}
        setSelectedUnit={setSelectedUnit}
        responsibilitySelected={selectResponsibilities}
      />
      <hr></hr>
      <FormGroup check inline>
        <Label check for={"selectResponsibilities" + index}>
          <Input
            id={"selectResponsibilities" + index}
            type="checkbox"
            name={"selectResponsibilities" + index}
            className="big-checkbox m-r-sm"
            checked={selectResponsibilities}
            value={!selectResponsibilities}
            onChange={handleSelectResponsibilities}
          />
          <div className="set-label">
            {" "}
            {<GetLanguageString props="member_select_responsibilities" />}
          </div>
        </Label>
      </FormGroup>
      <br></br>
      <br></br>
      <Select
        options={responsibility}
        value={values.responsibilities}
        className="basic-multi-select ur"
        components={{ Option: customOption }}
        isMulti
        isDisabled={!selectResponsibilities}
        classNamePrefix="select"
        isClearable={true}
        onChange={(state) => {
          handleOnChangeResponsibilities(state);
        }}
      />
    </div>
  );
};

export default Permissions;
