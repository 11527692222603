import React, { useState } from "react";
import { Form, FormGroup, Input, Button, FormFeedback } from "reactstrap";

import useForm from "../shared/useForm";
import { requestAccountApi } from "../../actions/api";
import { LoadingSpinner } from "../../constants/const";
import SetCode from "./SetCode";
import { GetLanguageString } from "../helper/Components";
import { GetSelectedLanguage } from "../helper/Method";

const intialFieldValues = {
  regNo: "",
  code: "",
  password: "",
  isVerified: false,
  isPassword: false,
};

const SignUp = ({ setSignUp, signUp }) => {
  const selected = GetSelectedLanguage();
  const [loading, setLoading] = useState(false);
  const [loadCode, setLoadCode] = useState(false);

  const {
    errors,
    setErrors,
    serverError,
    setServerError,
    values,
    setValues,
    handleInputChange,
  } = useForm(intialFieldValues);

  const validate = () => {
    let temp = {};
    temp.regNo = values.regNo
      ? ""
      : selected === "en"
      ? "The Registration Number field is required."
      : selected === "ur"
      ? " رجسٹریشن نمبر لازم ہے "
      : null;
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      requestAccountApi()
        .createDynamicUser(values)
        .then((res) => {
          setLoading(false);
          setLoadCode(true);
        })
        .catch((err) => {
          setLoading(false);
          setServerError(err.response.data.errors[0]);
        });
    }
  };

  return (
    <div>
      {!loading ? (
        <>
          {loadCode ? (
            <SetCode
              signUp={signUp}
              setSignUp={setSignUp}
              signUpValues={values}
              setSignUpValues={setValues}
            />
          ) : (
            <>
              <p>
                {" "}
                <GetLanguageString props="login_create_account_text" />
              </p>
              {serverError && <h4 className="text-danger">{serverError}</h4>}
              <Form onSubmit={handleSubmit} className="m-t">
                <FormGroup>
                  <Input
                    autoFocus
                    className={selected === "ur" ? "ur" : "en"}
                    type="text"
                    name="regNo"
                    value={values.regNo}
                    onChange={handleInputChange}
                    placeholder={
                      selected === "en"
                        ? "Registration Number"
                        : selected === "ur"
                        ? "رجسٹریشن نمبر"
                        : null
                    }
                    {...(errors.regNo && { invalid: true })}
                  />
                  {errors.regNo && (
                    <FormFeedback>
                      <h4 className={selected === "ur" ? "ur" : "en"}>
                        {errors.regNo}
                      </h4>
                    </FormFeedback>
                  )}
                </FormGroup>
                <Button
                  color="primary"
                  type="submit"
                  className="full-width m-b"
                >
                  <GetLanguageString props="login_send_code" />
                </Button>
              </Form>
            </>
          )}
          <p className="text-muted text-center">
            <GetLanguageString props="login_create_account_already_have_account" />
          </p>
          <Button
            size="sm"
            color="white"
            className="full-width"
            onClick={() => setSignUp(!signUp)}
          >
            <GetLanguageString props="login" />
          </Button>
        </>
      ) : (
        LoadingSpinner()
      )}
    </div>
  );
};

export default SignUp;
