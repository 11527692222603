import React from "react";
import UserPermissionsByUnit from "../UserPermissionsByUnit";
import { Button, Col, Row } from "reactstrap";
import { CheckIsAdmin, CheckPermission, LoadingSpinner } from "../../../constants/const";
import {
  useLocation,
  withRouter,
} from "react-router-dom/cjs/react-router-dom.min";
import { GetLanguageString, NoResult } from "../../helper/Components";

const MemberAccessRights = ({
  tab8,
  accessRights,
  history,
  editValue,
  accessRightsLoading,
}) => {
  const location = useLocation();
  const handleManagePermissions = () => {
    // const { value } = e.target;
    history.push({
      pathname: `/userPermissions`,
      state: {
        memberId: editValue,
      },
    });
  };
  return (
    <div role="tabpanel" id="tab-8" className={"tab-pane " + tab8}>
      <div className="panel-body">
        {!accessRightsLoading ? (
          accessRights?.length !== 0 ? (
            <Row className="m">
              {accessRights?.map((record, index) => {
                return (
                  <>
                    {record.title === "User Permissions" ? (
                      <>
                        <div className="panel panel-primary p-0" key={index}>
                          <div className="panel-heading">
                            <Row>
                              <Col md="6">
                                <h4>{record.title}</h4>
                              </Col>
                              {location.pathname !== "/personalInfo" ? (
                                (CheckPermission("CanDelegatePermissions") &&
                                  !CheckIsAdmin()) ||
                                (CheckPermission("CanDelegatePermissions") &&
                                  CheckIsAdmin()) ? (
                                  <Col md="6" className="text-right">
                                    <Button
                                      color="success"
                                      size="sm"
                                      onClick={handleManagePermissions}
                                    >
                                      {
                                        <GetLanguageString props="settings_responsibility_manage_permission" />
                                      }
                                    </Button>
                                  </Col>
                                ) : null
                              ) : null}
                            </Row>
                          </div>
                          <div
                            className="panel-body"
                            style={{
                              backgroundColor: "#f9f9f9",
                            }}
                          >
                            <UserPermissionsByUnit values={record.userRights} />
                          </div>
                        </div>
                      </>
                    ) : record.responsibilityRights.length !== 0 ? (
                      <div className="panel panel-primary p-0" key={index}>
                        <div className="panel-heading">
                          <h4>{record.title}</h4>{" "}
                        </div>
                        <div
                          className="panel-body"
                          style={{ backgroundColor: "#f9f9f9" }}
                        >
                          <UserPermissionsByUnit
                            values={record.responsibilityRights}
                          />
                        </div>
                      </div>
                    ) : null}
                  </>
                );
              })}
            </Row>
          ) : (
            NoResult()
          )
        ) : (
          LoadingSpinner()
        )}
      </div>
    </div>
  );
};

export default withRouter(MemberAccessRights);
