import React, { useEffect, useState } from "react";
import { Table, Button, Label, Input } from "reactstrap";
import { withRouter } from "react-router-dom";
import { encryptStorage } from "../../constants/EncryptStorage";
import SearchMembers from "./SearchMembers";
import { addMember } from "../member/ApiMember";
import { GetLanguageString, NoResult } from "../helper/Components";
import { GetCustomStyles } from "../helper/Method";
import { LoadingSpinner } from "../../constants/const";

const MembersMeeting = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const memberId = parseInt(encryptStorage.getItem("memberId"));
  const canManage = encryptStorage.getItem("canManage");
  const [meetingMembers, setMeeetingMembers] = useState();
  const [updateMeetingMembers, setUpdateMeeetingMembers] = useState();
  const [searchMembersModel, setSearchMembersModel] = useState({
    unit: { value: loggedInUnitId, label: "" },
    isSubUnits: true,
    loggedInUnitId: loggedInUnitId,
  });

  useEffect(() => {
    setLoading(true);
    addMember()
      .getMeetingMembers(searchMembersModel)
      .then((res) => {
        setUpdateMeeetingMembers(res.data);
        setMeeetingMembers(res.data.meetingMembersModelList);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [searchMembersModel]);

  const handleMeetingHappens = (e, index) => {
    const { checked } = e.target;
    let tempValues = [...meetingMembers];
    tempValues[index].meetingHappens = checked;
    setMeeetingMembers(tempValues);
  };

  const handleKnowsPersonally = (e, index) => {
    const { checked } = e.target;
    let tempValues = [...meetingMembers];
    tempValues[index].knowsPersonally = checked;
    setMeeetingMembers(tempValues);
  };

  const handleSubmit = () => {
    updateMeetingMembers.meetingMembers = meetingMembers;
    setLoading(true);
    addMember()
      .updateMeetingMembersDetails(updateMeetingMembers)
      .then((res) => {
        history.goBack();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  return (
    <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
      {memberId !== 0 && !canManage ? null : (
        <SearchMembers
          searchMembersModel={searchMembersModel}
          setSearchMembersModel={setSearchMembersModel}
          unitId={searchMembersModel.unit.value}
        />
      )}
      <div className="ibox ">
        <div className="ibox-title">
          <h5>
            <GetLanguageString props="meeting_members" />
          </h5>
        </div>
        <div className="ibox-content">
          {!loading ? (
            meetingMembers?.length !== 0 ? (
              <>
                <div className="table-responsive">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th className="text-center">
                          {<GetLanguageString props="common_name" />}
                        </th>
                        <th className="text-center">
                          {
                            <GetLanguageString props="meeting_members_member_type" />
                          }
                        </th>
                        <th className="text-center">
                          {
                            <GetLanguageString props="meeting_members_responsibilities" />
                          }
                        </th>
                        <th className="text-center">
                          {
                            <GetLanguageString props="meeting_members_meetingHappens" />
                          }
                        </th>
                        <th className="text-center">
                          {
                            <GetLanguageString props="meeting_members_knowsPersonally" />
                          }
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {meetingMembers?.map((record, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td className="text-center ur">{record.name}</td>
                            <td className="text-center ur">
                              {record.memberTypes.label}
                            </td>
                            <td className="text-center">
                              {record.responsibilityList.map(
                                (item1, index1) => {
                                  return (
                                    <Button
                                      key={index1}
                                      size="xs"
                                      color="success"
                                      className="label label-success m-r-xs"
                                    >
                                      <h5 className="ur">{item1.label}</h5>
                                    </Button>
                                  );
                                }
                              )}
                            </td>
                            <td className="text-center">
                              <Label
                                className="form-check form-check-inline"
                                for={"meetingMembers" + record.memberId}
                              >
                                <Input
                                  id={"meetingMembers" + record.memberId}
                                  checked={record.meetingHappens}
                                  type="checkbox"
                                  name={"meetingMembers" + record.memberId}
                                  onChange={(e) =>
                                    handleMeetingHappens(e, index)
                                  }
                                  className="form-check-input"
                                />
                                {
                                  <GetLanguageString props="meeting_members_meetingHappens" />
                                }
                              </Label>
                            </td>
                            <td className="text-center">
                              <Label
                                className="form-check form-check-inline"
                                for={"knowsPersonally" + record.memberId}
                              >
                                <Input
                                  id={"knowsPersonally" + record.memberId}
                                  checked={record.knowsPersonally}
                                  type="checkbox"
                                  name={"knowsPersonally" + record.memberId}
                                  onChange={(e) =>
                                    handleKnowsPersonally(e, index)
                                  }
                                  className="form-check-input"
                                />
                                {
                                  <GetLanguageString props="meeting_members_knowsPersonally" />
                                }
                              </Label>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tr>
                      <td colSpan="7">
                        <ul className={GetCustomStyles().btn_style}>
                          <Button
                            color="primary"
                            onClick={() => handleSubmit()}
                          >
                            {<GetLanguageString props="common_save_changes" />}
                          </Button>
                        </ul>
                      </td>
                    </tr>
                  </Table>
                </div>
              </>
            ) : (
              <NoResult />
            )
          ) : (
            LoadingSpinner()
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(MembersMeeting);
