import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import { GetLanguageString } from "../helper/Components";
import moment from "moment";
import { LoadingSpinner, convertUTCToLocalTime } from "../../constants/const";
import swal from "sweetalert";
import { ApiUnitMember } from "./ApiUnit";
import { GetSelectedLanguage } from "../helper/Method";
import { useTranslation } from "react-i18next";
import EndMoveMemberResponsibilityDate from "../member/EndMoveMemberResponsibilityDate";
import Draggable from "react-draggable";

const MoveMemberResponsibilities = ({
  moveMemberResponsibilities,
  unitMembers,
  check,
  setMoveMemberResponsibilityModal,
  setMembersMoved,
  membersMoved,
}) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const notify = (string) => toast.success(string);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  const [memberResponsibilities, setMemberResponsibilities] = useState(
    moveMemberResponsibilities
  );  const [
    endMoveMemberResponsibilityDateModal,
    setEndMoveMemberResponsibilityDateModal,
  ] = useState(false);
  const toggle = () => setMoveMemberResponsibilityModal(false);

  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedUnitMembers = unitMembers.map((unitMember) => {
      const correspondingResponsibilities = memberResponsibilities.filter(
        (resp) => resp.memberId === unitMember.memberId
      );

      if (correspondingResponsibilities.length > 0) {
        return {
          ...unitMember,
          memberResponsibiities: correspondingResponsibilities,
        };
      } else {
        return unitMember;
      }
    });

    if (memberResponsibilities.some((obj) => obj.delete === true)) {
      setEndMoveMemberResponsibilityDateModal(true);
    } else {
      setLoading(true);
      setButtonDisable(true);
      ApiUnitMember()
        .moveUnitMembers({
          unitMemberModels: updatedUnitMembers,
          permission: check,
        })
        .then((res) => {
          if (res.data === "") {
            swal({
              title: t("unit_unitmember_move_rufaqa_success"),
              className: GetSelectedLanguage() === "en" ? "" : "ur",
              icon: "success",
            });
          } else notify(res.data);
          setLoading(false);
          setButtonDisable(false);
          setMembersMoved(!membersMoved);
          setMoveMemberResponsibilityModal(false);
        })
        .catch((err) => {
          swal({
            title: "Error: " + err.response?.data,
            icon: "error",
            buttons: "OK",
          });
          console.log(err);
          setLoading(false);
          setButtonDisable(false);
        });
    }
  };

  const handleResponsibilitiesDelete = (e, index) => {
    const { checked } = e.target;
    let tempValues = [...memberResponsibilities];
    tempValues[index].delete = checked;
    setMemberResponsibilities(tempValues);
  };
  return (
    <div>
      <ToastContainer
        autoClose={3000}
        bodyClassName={GetSelectedLanguage()}
        closeOnClick
        pauseOnHover={false}
        draggable
      />
      {endMoveMemberResponsibilityDateModal ? (
        <EndMoveMemberResponsibilityDate
          setEndMoveMemberResponsibilityDateModal={
            setEndMoveMemberResponsibilityDateModal
          }
          unitMembers={unitMembers}
          memberResponsibilities={memberResponsibilities}
          setMemberResponsibilities={setMemberResponsibilities}
          values={null}
          check={check}
          setMoveMemberModal={setMoveMemberResponsibilityModal}
        />
      ) : null}
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal
          isOpen={true}
          size="lg"
          style={{ maxWidth: "1080px", width: "100%" }}
          className="inmodal"
        >
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="common_action" />}
          </ModalHeader>
          <Form>
            <ModalBody>
              {!loading ? (
                <>
                  {memberResponsibilities?.length !== 0 ? (
                    <>
                      <div className="ibox-title">
                        <h3 className="text-error">
                          <GetLanguageString props="move_members_note_remove_responsibility" />
                        </h3>
                      </div>
                      <div className="ibox-content">
                        <div>
                          <Table responsive>
                            <thead>
                              <tr>
                                <th></th>
                                <th>#</th>
                                <th className="text-center">
                                  {
                                    <GetLanguageString props="member_first_name" />
                                  }
                                </th>
                                <th className="text-center">
                                  {
                                    <GetLanguageString props="member_father_name" />
                                  }
                                </th>
                                <th className="text-center">
                                  {
                                    <GetLanguageString props="member_reg_number" />
                                  }
                                </th>
                                <th className="text-center">
                                  {
                                    <GetLanguageString props="member_member_detail_common_responsibility" />
                                  }
                                </th>
                                <th className="text-center">
                                  {
                                    <GetLanguageString props="dashboard_event_page_unit_name" />
                                  }
                                </th>
                                <th className="text-center">
                                  {
                                    <GetLanguageString props="dashboard_event_page_unit_type" />
                                  }
                                </th>
                                <th className="text-center">
                                  {
                                    <GetLanguageString props="member_member_detail_common_responsibility_date" />
                                  }
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {memberResponsibilities?.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      {" "}
                                      <Input
                                        id={"delete" + index}
                                        type="checkbox"
                                        checked={item.delete}
                                        onChange={(e) =>
                                          handleResponsibilitiesDelete(e, index)
                                        }
                                      />
                                    </td>
                                    <td>{index + 1}</td>
                                    <td className="text-center ur no-drag">
                                      {item.memberName}
                                    </td>
                                    <td className="text-center ur no-drag">
                                      {item.fatherName}
                                    </td>
                                    <td className="text-center no-drag">
                                      {item.registrationNumber}
                                    </td>
                                    <td className="text-center ur no-drag">
                                      {item.responsibilityName}
                                    </td>
                                    <td className="text-center ur no-drag">
                                      {item.unitName}
                                    </td>
                                    <td className="text-center no-drag">
                                      {
                                        <Label
                                          size="xs"
                                          color="info"
                                          className="label label-info m-r-xs"
                                          onClick={() => {}}
                                        >
                                          <h5 className="ur no-drag">{item.unitType}</h5>
                                        </Label>
                                      }
                                    </td>
                                    <td className="text-center no-drag">
                                      {moment(
                                        convertUTCToLocalTime(
                                          item.responsibilityDate
                                        )
                                      ).format("DD/MM/yyyy")}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </>
                  ) : null}
                </>
              ) : (
                LoadingSpinner()
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="white" onClick={toggle}>
                {<GetLanguageString props="common_cancel" />}
              </Button>
              <Button
                color="primary"
                disabled={buttonDisable}
                type="submit"
                onClick={handleSubmit}
              >
                {<GetLanguageString props="common_save_changes" />}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </Draggable>
    </div>
  );
};

export default MoveMemberResponsibilities;
