import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Row, Col, FormGroup, Label, Input, Button } from "reactstrap";
import { GetLanguageString } from "../helper/Components";
import { encryptStorage } from "../../constants/EncryptStorage";

import { ApiUnit } from "../unit/ApiUnit";
import { GetSelectedLanguage } from "../helper/Method";
import { GoArrowLeft, GoArrowRight } from "react-icons/go";

const EventParticipantSelectUnit = ({
  setValues,
  values,
  eventUnitId,
  setSearchEventParticipants,
  searchEventParticipants,
  forChildUnit,
}) => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [unitList, setUnitList] = useState([]);
  const [unitId, setUnitid] = useState(eventUnitId);

  const unitTypeName = "null";

  useEffect(() => {
    ApiUnit()
      .checkIsValidChild(eventUnitId, loggedInUnitId)
      .then((res) => {
        if (res.data) setUnitid(eventUnitId);
        else setUnitid(loggedInUnitId);
      })
      .catch((err) => console.log(err));
  }, [eventUnitId, loggedInUnitId, unitId]);

  useEffect(() => {
    ApiUnit()
      .getUnitList(unitId, unitId, false, unitTypeName, true)
      .then((res) => {
        setUnitList(res.data);
      })
      .catch((err) => console.log(err));
  }, [unitId]);

  const handleChangeUnits = (record) => {
    if (record !== null) {
      ApiUnit()
        .getUnitList(record.value, unitId, false, unitTypeName, true)
        .then((res) => {
          setUnitList(res.data);
        })
        .catch((err) => console.log(err));

      setValues({
        ...values,
        unit: record,
      });
    }
  };

  const handleSubUnits = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setValues({
      ...values,
      isSubUnits: v,
    });
  };

  const handleSubmit = (e) => {
    setSearchEventParticipants({
      ...searchEventParticipants,
      unitId: values.unit.value,
      isSubUnits: values.isSubUnits,
      activePage: 1,
    });
  };

  return (
    <>
      {forChildUnit ? (
        <>
          <Row>
            {unitList.map((record, index) => {
              const unitOptions = [];
              record.unitList.forEach((d) =>
                unitOptions.push({ value: d.id, label: d.name })
              );
              let selected = {};
              if (record.selectedUnit !== null) {
                selected = {
                  value: record.selectedUnit.id,
                  label: record.selectedUnit.name,
                };
              }

              const isLastIndex = index === unitList.length - 1;

              return (
                <Col md="2" key={index}>
                  <FormGroup>
                    <Row>
                      <Col md="10">
                        <Select
                          options={unitOptions}
                          value={selected}
                          className="basic-single ur no-drag"
                          classNamePrefix="select"
                          onChange={handleChangeUnits}
                        />
                      </Col>
                      {isLastIndex ? null : (
                        <Col md="2" className="p-0">
                          {GetSelectedLanguage() === "en" ? (
                            <GoArrowRight
                              size={20}
                              style={{ marginRight: 3, marginBottom: 1 }}
                            />
                          ) : (
                            <GoArrowLeft
                              size={20}
                              style={{ marginRight: 3, marginBottom: 1 }}
                            />
                          )}
                        </Col>
                      )}
                    </Row>
                  </FormGroup>
                </Col>
              );
            })}
          </Row>
          <Row>
            <Col md="4">
              <FormGroup check>
                <Label check for="subUnit">
                  <Input
                    id="subUnit"
                    type="checkbox"
                    name="isSubUnits"
                    checked={values.isSubUnits}
                    value={!values.isSubUnits}
                    onChange={handleSubUnits}
                  />
                  {<GetLanguageString props="member_search_member_sub_unit" />}
                </Label>
              </FormGroup>
            </Col>
          </Row>{" "}
        </>
      ) : null}
      <Row>
        <Col className="text-center">
          <FormGroup>
            <Button
              color="primary"
              className="m-l-sm"
              type="submit"
              onClick={handleSubmit}
            >
              {<GetLanguageString props="common_search" />}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default EventParticipantSelectUnit;
