import React, { useState, useEffect } from "react";
import { Col, Row, Table, TabContent, Nav, NavLink, Button } from "reactstrap";
import swal from "sweetalert";
import Pagination from "react-js-pagination";

import SearchRafiqAttendance from "./SearchRafiqAttendance";
import { ApiEvent } from "../ApiEvent";
import { LoadingSpinner } from "../../../constants/const";
import { GetLanguageString, NoResult } from "../../helper/Components";
import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import { encryptStorage } from "../../../constants/EncryptStorage";

const RafiqWiseEventAttendance = () => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [loading, setLoading] = useState(false);
  const [searchButton, setSearchButton] = useState(false);
  const [data, setData] = useState({});
   const [tab1, setTab1] = useState("active");
  const [tab2, setTab2] = useState("");
  const [pagination, setPagination] = useState({ activePage: 1 });

  let tempDate = new Date();
  tempDate.setUTCDate(1);
  const [searchEventWiseAttendanceModel, setSearchEventWiseAttendanceModel] =
    useState({
      unit: { value: loggedInUnitId, lable: "" },
      isSubUnits: true,
      startDate: tempDate,
      endDate: new Date(),
      eventTypeIds: [],
      memberTypeIds: [],
      isAhbab: false,
      activePage: 1,
    });

  useEffect(() => {
    if (searchButton) {
      setLoading(true);
      ApiEvent()
        .GetRafiqWiseEventAttendanceReport(searchEventWiseAttendanceModel)
        .then((res) => {
          setData(res.data);
          setPagination({
            activePage: res.data.activePage,
            pageSize : res.data.pageSize,
            totalItem: res.data.totalItem,
            pageRange: res.data.pageRange,
          })
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
          setLoading(false);
        });
    }
  }, [searchButton, searchEventWiseAttendanceModel]);

  const handlePageChange = (pageNumber) => {
    setSearchEventWiseAttendanceModel({
      ...searchEventWiseAttendanceModel,
      activePage: pageNumber,
    });
    setPagination({
      ...pagination,
      activePage: pageNumber,
    });
  };

  return (
    <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
      <div className="ibox">
        <div className="ibox-title">
          <h5>{<GetLanguageString props="common_generate_report" />}</h5>
        </div>
        <SearchRafiqAttendance
          searchEventWiseAttendanceModel={searchEventWiseAttendanceModel}
          setSearchEventWiseAttendanceModel={setSearchEventWiseAttendanceModel}
          setSearchButton={setSearchButton}
          tab2={tab2}
        />
      </div>
      {searchButton ? (
        <div className="ibox">
          <div className="ibox-title">
            <h5>{<GetLanguageString props="quick_event_reports_button5" />}</h5>
          </div>
          <div className="ibox-content">
            <Row>
              <Col md="12">
                <div className="tabs-container">
                  <Nav tabs>
                    <li>
                      <NavLink
                        to="#"
                        className={tab1}
                        onClick={() => {
                          setTab1("active");
                          setTab2("");
                          setSearchEventWiseAttendanceModel({
                            ...searchEventWiseAttendanceModel,
                            isAhbab: false,
                            activePage: 1
                          });
                        }}
                      >
                        <GetLanguageString props="sidebar_member" />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="#"
                        className={tab2}
                        onClick={() => {
                          setTab1("");
                          setTab2("active");
                          setSearchEventWiseAttendanceModel({
                            ...searchEventWiseAttendanceModel,
                            isAhbab: true,
                            activePage: 1
                          });
                        }}
                      >
                        <GetLanguageString props="ahbab" />
                      </NavLink>
                    </li>
                  </Nav>
                  <TabContent>
                    <div role="tabpanel" id="tab-1" className={"tab-pane " + tab1}>
                     <ShowData loading={loading} data={data} handlePageChange={handlePageChange} pagination={pagination} tab2={tab2} />
                    </div>
                    <div role="tabpanel" id="tab-2" className={"tab-pane " + tab2}>
                    <ShowData loading={loading} data={data} handlePageChange={handlePageChange} pagination={pagination} tab2={tab2} />
                    </div>
                  </TabContent>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default RafiqWiseEventAttendance;

const ShowData = ({loading, data, handlePageChange, pagination, tab2}) => {
  const recordIndex = pagination.pageSize * (pagination.activePage - 1) + 1;

  return (
    <div className="panel-body ibox-center">
      {!loading ? (
        data !== undefined &&
        data !== null &&
        data?.columns !== null &&
        data?.columns?.length !== 0 &&
        data?.memberList !== undefined &&
        data?.memberList !== null &&
        data?.memberList.length !== 0 &&
        Object.keys(data).length !== 0 ? (
          <Table className="table-bordered m-b-none">
            <thead>
              <tr>
                <th>#</th>
                <th>
                  <GetLanguageString props="common_name" />
                </th>
                { tab2 === "active" ? null :
                <th className="text-center">
                  <GetLanguageString props="settings_member_type" />
                </th>
                }
                <th className="text-center">
                  <GetLanguageString props="unit_unit_table" />
                </th>
                {data?.columns?.map((record, index) => {
                    return (
                        <th className="text-center ur" key={index}>
                          <Row>
                            <Col md="4"></Col>
                            <Col md="4">
                              <h3>{record.name} </h3>
                            </Col>
                            <Col md="1" className="m-1">
                              <span
                                className="label label-success"
                                style={{ borderRadius: "50%" }}
                              >
                                {record.eventCounts}
                              </span>
                            </Col>
                            <Col md="4"></Col>
                          </Row>
                        </th>
                    );
                })}
              </tr>
            </thead>
            <tbody>
              {data?.memberList?.map((item, index) => {
                let unitName = item.unitName?.split("/");
                return (
                <tr key={index}>
                  <td>{index + recordIndex}</td>
                  <td className="ur">
                    {item.memberName}
                  </td>
                  { tab2 === "active" ? null :
                    <td className="text-center">{
                      <Button
                        size="xxs"
                        color="info"
                        className="label label-info m-r-xs"
                      >
                        <h5 className="ur">
                          {item.memberType}
                        </h5>
                      </Button>
                    }
                    </td>
                  }
                  
                  <td className="ur">
                    <ol className="breadcrumb">
                      {unitName?.map((item1, index1) => {
                        return (
                          <li
                            className={
                              GetSelectedLanguage() === "ur"
                                ? "breadcrumb-item-ur"
                                : "breadcrumb-item"
                            }
                            key={index1}
                          >
                            <span>{item1}</span>
                          </li>
                        );
                      })}
                    </ol>
                  </td>
                  {item?.memberDetails?.map((record, index1) => {
                        return (
                  <td className="text-center" key={index1}>
                    {record.percentage
                      ? record.percentage.toFixed(2) + "%"
                      : "0.0%"}
                  </td>)
                  })}
                </tr>
              )})}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="20">
                  <ul className={GetCustomStyles().page_style}>
                    <Pagination
                      activePage={pagination.activePage}
                      itemsCountPerPage={pagination.pageSize}
                      totalItemsCount={pagination.totalItem}
                      pageRangeDisplayed={pagination.pageRange}
                      onChange={handlePageChange}
                    />
                  </ul>
                </td>
              </tr>
            </tfoot>
          </Table>
        ) : (
          <NoResult />
        )
      ) : (
        LoadingSpinner()
      )}
    </div>
  )
}
