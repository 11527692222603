import React, { useEffect, useState } from "react";
import {
  Col,
  Row
} from "reactstrap";

import { NoResult } from "../helper/Components";
import { LoadingSpinner } from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";
import { ApiDashboard } from "./ApiDashboard";

const ExpectedMembersDashboard = ({tab5}) => {
  const [loading, setLoading] = useState(false);
  const memberId = parseInt(encryptStorage.getItem("memberId"));
  const [expectedMembers, setExpectedMembers] = useState([]);

  useEffect(() => {
    if(tab5 === "active"){
      setLoading(true);
      ApiDashboard()
        .getExpectedMemberList(memberId)
        .then((res) => {
          setExpectedMembers(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [memberId, tab5]);

  return (
    <div role="tabpanel" id="tab-5" className={"tab-pane " + tab5}>
      <div className="panel-body">
      {!loading ? (
        expectedMembers.length !== 0 ? (
          <div>
            {expectedMembers?.map((record, index) => {
              return (
                <div className="timeline-item" key={index}>
                  <div className="content no-top-border">
                    <Row className="m">
                      <Col md="10">
                        <h2 className="ur">{record.firstName}</h2>
                      </Col>
                      <Col md="2">
                        <h4 className="text-navy">{record.phone1}</h4>
                      </Col>
                      <h4>
                        <strong className="ur">{record.currentAddress}</strong>
                      </h4>
                      {record.description && (
                        <p className="text-navy ur">{record.description}</p>
                      )}
                    </Row>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <NoResult />
        )
      ) : (
        LoadingSpinner()
      )}
      </div>
    </div>
  );
};

export default ExpectedMembersDashboard;
