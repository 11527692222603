import React, { useEffect, useState } from "react";
import { FormGroup, Input } from "reactstrap";
import { GetLanguageString } from "../helper/Components";
import Select from "react-select";
import { LoadingSpinner } from "../../constants/const";
import { addMember } from "./ApiMember";

const SelectSerialNumber = ({
  setSerialNumber,
  loading,
}) => {
  const [cancelledSerialNumberCheck, setCancelledSerialNumberCheck] =
    useState(false);
  const [newSerialNumber, setNewSerialNumber] = useState();
  const [cancelledSerialNumbers, setCancelledSerialNumbers] = useState([]);
  const [newSerialNumberCheck, setNewSerialNumberCheck] = useState(true);

  useEffect(() => {
    addMember()
      .getNewRafiqSerialNumber()
      .then((res) => {
        setNewSerialNumber(res.data);
        setSerialNumber(res.data);
      })
      .catch((err) => console.log(err));
  }, [setSerialNumber]);

  useEffect(() => {
    addMember()
      .getCancelledRafiqRegistrationSerialNumbers()
      .then((res) => {
        setCancelledSerialNumbers(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div>
      {!loading ? (
        <>
          <FormGroup>
            <Input
              type="radio"
              checked={newSerialNumberCheck}
              onChange={() => {
                setCancelledSerialNumberCheck(false);
                setNewSerialNumberCheck(true);
                setSerialNumber(newSerialNumber);
              }}
            />{" "}
            {
              <GetLanguageString props="member_select_serial_number_in_sequence" />
            }
          </FormGroup>
          <FormGroup>
            <div className="ibox-content">
              <div className="text-center">
                <h2 className="font-bold">{newSerialNumber}</h2>
              </div>
            </div>
          </FormGroup>
          <FormGroup>
            <Input
              type="radio"
              checked={cancelledSerialNumberCheck}
              onChange={() => {
                setCancelledSerialNumberCheck(true);
                setNewSerialNumberCheck(false);
                setSerialNumber(cancelledSerialNumbers[0]);
              }}
            />{" "}
            {
              <GetLanguageString props="member_select_serial_number_cancel_registration" />
            }
          </FormGroup>
          <FormGroup>
            <Select
              options={cancelledSerialNumbers.map((s) => ({
                value: s,
                label: s,
              }))}
              className="basic-single"
              classNamePrefix="select"
              isDisabled={newSerialNumberCheck}
              onChange={(sn) => setSerialNumber(sn.value)}
            />
          </FormGroup>
        </>
      ) : (
        LoadingSpinner()
      )}
    </div>
  );
};

export default SelectSerialNumber;
