import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { GetLanguageString } from "../../helper/Components";
import { isUrdu } from "../../../constants/const";
import { GetSelectedLanguage } from "../../helper/Method";
import Draggable from "react-draggable";

const ResponsibilityErrorMessage = ({
  setDisplayMessageModal,
  data,
  displayName,
}) => {
  const toggle = () => {
    setDisplayMessageModal(false);
  };
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  const selectedLanguage = GetSelectedLanguage();
  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal size="lg" isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {
              <GetLanguageString props="member_member_details_responsibility_exists_error_message" />
            }
          </ModalHeader>
          <ModalBody>
            <div className="ibox-content text-center">
              {data.thisMemberHaveResponsibility ? (
                <>
                  {selectedLanguage === "ur" ? (
                    <h2 className="ur">
                      اس رفیق کی{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {data.unitName}{" "}
                      </span>{" "}
                      میں ذمہ داری
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        {displayName !== ""
                          ? data.responsibilityName + "(" + displayName + ")"
                          : data.responsibilityName}
                      </span>{" "}
                      پہلے سے موجود ہے.
                    </h2>
                  ) : (
                    <h3>
                      Responsibility{" "}
                      <span
                        className={
                          isUrdu(data.responsibilityName)
                            ? "ur no-drag"
                            : "no-drag"
                        }
                      >
                        {displayName !== ""
                          ? "(" + displayName + ")" + data.responsibilityName 
                          : data.responsibilityName}
                      </span>{" "}
                      already exists for this Rafiq in{" "}
                      <span className={isUrdu(data.unitName) ? "ur" : ""}>
                        {data.unitName}.
                      </span>
                    </h3>
                  )}
                  <br></br>
                </>
              ) : data.otherMemberHaveResponsibility ? (
                <>
                  {selectedLanguage === "ur" ? (
                    <h2 className="ur">
                      رفیق{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {data.memberName}{" "}
                      </span>{" "}
                      کی{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {data.unitName}{" "}
                      </span>{" "}
                      میں ذمہ داری
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        {displayName !== ""
                          ?  data.responsibilityName + "(" + displayName + ")"
                          : data.responsibilityName}
                      </span>{" "}
                      پہلے سے موجود ہے.
                    </h2>
                  ) : (
                    <h3>
                      Responsibility{" "}
                      <span
                        className={
                          isUrdu(data.responsibilityName)
                            ? "ur no-drag"
                            : "no-drag"
                        }
                      >
                        {displayName !== ""
                          ? "(" + displayName + ")" + data.responsibilityName 
                          : data.responsibilityName}
                      </span>{" "}
                      already exists for{" "}
                      <span
                        className={
                          isUrdu(data.memberName) ? "ur no-drag" : "no-drag"
                        }
                      >
                        {data.memberName}
                      </span>{" "}
                      in{" "}
                      <span
                        className={
                          isUrdu(data.unitName) ? "ur no-drag" : "no-drag"
                        }
                      >
                        {data.unitName}.
                      </span>
                    </h3>
                  )}
                  <br></br>
                </>
              ) : data.isUsraMavin ? (
                <>
                  {selectedLanguage === "ur" ? (
                    <h2 className="ur">
                      <span style={{ fontWeight: "bold" }}>
                        {data.unitName}{" "}
                      </span>{" "}
                      میں اسرہ معاونین موجود نہیں ہے
                    </h2>
                  ) : (
                    <h3>
                      <span className="ur" style={{ fontWeight: "bold" }}>
                        اسرہ معاونین{" "}
                      </span>{" "}
                      in{" "}
                      <span
                        className={
                          isUrdu(data.unitName) ? "ur no-drag" : "no-drag"
                        }
                      >
                        {data.unitName}{" "}
                      </span>{" "}
                      does not exist.
                    </h3>
                  )}
                  <br></br>
                </>
              ) : null}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggle}>
              {<GetLanguageString props="users_role_ok_btn" />}
            </Button>
          </ModalFooter>
        </Modal>
      </Draggable>
    </div>
  );
};

export default ResponsibilityErrorMessage;
