import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";
import swal from "sweetalert";

import Draggable from "react-draggable";
import { convertToDateString, LoadingSpinner } from "../../../constants/const";
import { encryptStorage } from "../../../constants/EncryptStorage";
import { GetLanguageString } from "../../helper/Components";
import { GetSelectedLanguage } from "../../helper/Method";
import { addMember } from "../../member/ApiMember";
import { ApiUnit } from "../../unit/ApiUnit";

const DeleteMultipleResponsibility = ({
  memberId,
  setDeleteModal,
  deleteResponsibilityToggle,
  setDeleteResponsibilityToggle,
}) => {
  const [loading, setLoading] = useState(false);
  const [responsibilityList, setResponsibilityList] = useState([]);
  const [checkResponsibilityList, setCheckResponsibilityList] = useState([]);
  const [responsibilityEndDate, setResponsibilityEndDate] = useState(false);
  const [unitId, setUnitId] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [isChangeUnit, setIsChangeUnit] = useState(false);
  const [isUnit, setIsUnit] = useState(false);
  const [isResponsibility, setIsResponsibility] = useState(false);
  const [responsibilityCount, setResponsibilityCount] = useState(0);
  const [errors, setErrors] = useState();
  const notify = (string) => toast.success(string);
  const { t } = useTranslation();
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  const toggle = () => setDeleteModal(false);

  const unitTypeName = "null";
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [unitList, setUnitList] = useState([]);

  useEffect(() => {
    ApiUnit()
      .getUnitList(
        loggedInUnitId,
        loggedInUnitId,
        false,
        unitTypeName,
        true
      )
      .then((res) => {
        setUnitList(res.data);
        setUnitId(loggedInUnitId);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [loggedInUnitId]);

  const handleChangeUnits = (record) => {
    if (record !== null) {
      ApiUnit()
        .getUnitList(
          record.value,
          loggedInUnitId,
          false,
          unitTypeName,
          true
        )
        .then((res) => {
          setUnitList(res.data);
        })
        .catch((err) => console.log(err));

      setUnitId(record.value);
    }
  };

  useEffect(() => {
    setLoading(true);
    addMember()
      .GetMemberResponsibilitiesToDelete(memberId)
      .then((res) => {
        setResponsibilityList(res.data);
        setIsUnit(res.data[0].isUnit);
        setIsResponsibility(res.data[0].isResponsibility);
        setResponsibilityCount(res.data[0].responsibilityCount);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [memberId]);

  useEffect(() => {
    if (
      isUnit &&
      responsibilityEndDate &&
      !isResponsibility &&
      checkResponsibilityList.length > 0
    ) {
      setLoading(true);
      addMember()
        .checkChangeMemberUnit(checkResponsibilityList, responsibilityCount)
        .then((res) => {
          setIsChangeUnit(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [
    isUnit,
    responsibilityEndDate,
    isResponsibility,
    responsibilityCount,
    checkResponsibilityList,
  ]);

  useEffect(() => {
    let dateCount = responsibilityList.filter(
      (r) => r.responsibilityEndDate !== null
    ).length;
    if (
      isUnit &&
      responsibilityEndDate &&
      !isResponsibility &&
      dateCount === 0
    ) {
      let responsbilities = [];
      responsibilityList.forEach((element) => {
        if (element.responsibility.isDisabled) {
          responsbilities.push({
            memberResponsibilityId: element.responsibility.value,
          });
        }
      });
      setCheckResponsibilityList(responsbilities);
    }
  }, [isUnit, responsibilityEndDate, isResponsibility, responsibilityList]);

  const removeObject = (index) => {
    let tempValues = [...responsibilityList];
    tempValues[index].responsibility.isDisabled = true;
    setResponsibilityList(tempValues);
    setErrors({
      ...errors,
      selectResponsibility: "",
    });
  };

  const handleInputChangeResponsibilityEndDate = (value, index) => {
    if (value !== null) {
      if (value <= new Date()) {
        let tempValues = [...responsibilityList];
        tempValues[index].responsibilityEndDate = value;
        setResponsibilityList(tempValues);
        setErrors({
          ...errors,
          endDate: "",
        });
      } else {
        setErrors({
          ...errors,
          endDate:
            GetSelectedLanguage() === "en"
              ? "The End date must not be greater than current date."
              : "ذمہ داری ک ختم ہونے ک تاریخ موجودہ تاریخ سی بری نہیں ہو سکتی",
        });
      }
    }
  };

  const handleDeleteResponsibility = () => {
    if (
      responsibilityList.some((obj) => obj.responsibility.isDisabled === true)
    ) {
      setResponsibilityEndDate(true);
    } else {
      setErrors({
        ...errors,
        selectResponsibility:
          GetSelectedLanguage() === "en"
            ? "Please delete a responsibility to proceed."
            : "براہ کرم کوئی ذمہ داری ہدف کریں ",
      });
    }
  };

  const validate = () => {
    let temp = {};
    temp.endDate =
      responsibilityList.findIndex(
        (obj) =>
          obj.responsibility.isDisabled === true &&
          obj.responsibilityEndDate === null
      ) !== -1 ? (
        responsibilityList[
          responsibilityList.findIndex(
            (obj) =>
              obj.responsibility.isDisabled === true &&
              obj.responsibilityEndDate === null
          )
        ].responsibilityEndDate !== null ? (
          ""
        ) : (
          <GetLanguageString props="member_responsibility_end_date" />
        )
      ) : (
        ""
      );

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setButtonDisable(true);
      setLoading(true);
      let responsbilities = [];

      responsibilityList.forEach((element) => {
        if (element.responsibility.isDisabled) {
          responsbilities.push({
            responsibilityEndDate: convertToDateString(
              element.responsibilityEndDate
            ),
            memberResponsibilityId: element.responsibility.value,
            unitId: (isUnit && isResponsibility) || isChangeUnit ? unitId : 0,
          });
        }
      });
      addMember()
        .deleteMemberResponsibility(responsbilities)
        .then((res) => {
          if (res.data === "Updated") {
            swal({
              title: t("unit_member_deleted_responsibility"),
              className: GetSelectedLanguage() === "en" ? "" : "ur",
              icon: "success",
            });
            toggle();
            setDeleteResponsibilityToggle(!deleteResponsibilityToggle);
            setLoading(false);
            setButtonDisable(false);
          } else if (res.data === "Date Error") {
            notify(res.data);
            setLoading(false);
            setButtonDisable(false);
            setErrors({
              ...errors,
              endDate:
                "End Date must be Greater than Create Responsibility Date.",
            });
          } else if (res.data === "Your request for modification is sent.") {
            notify(res.data);
            setLoading(false);
            setButtonDisable(false);
            setDeleteResponsibilityToggle(!deleteResponsibilityToggle);
            toggle();
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setButtonDisable(false);
        });
    }
  };


  return (
    <div>
      {responsibilityEndDate ? (
        <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
          <Modal size="xl" isOpen={true} className="inmodal" autoFocus={false}>
            <ModalHeader className="modal-title" tag="h4">
              {
                <GetLanguageString props="member_responsibility_end_date_title" />
              }
            </ModalHeader>
            <Form onSubmit={handleSubmit}>
              <ModalBody>
                {!loading ? (
                  <div className="ibox-content">
                    {responsibilityList?.map((record, index) => {
                      return record.responsibility.isDisabled ? (
                        <>
                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <Label className="col-form-label">
                                  {GetSelectedLanguage() === "en" ? (
                                    <h5>
                                      End Date for{" "}
                                      <span
                                        className="ur no-drag"
                                        style={{ fontSize: 17 }}
                                      >
                                        {record.responsibility.label}
                                      </span>{" "}
                                    </h5>
                                  ) : (
                                    <h5 className="ur">
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 20,
                                        }}
                                      >
                                        {" "}
                                        {record.responsibility.label}
                                      </span>{" "}
                                      کے ختم ہونے کی تاریخ{" "}
                                    </h5>
                                  )}
                                </Label>
                                <DatePicker
                                  showYearDropdown
                                  placeholderText="dd/mm/yyyy"
                                  className="form-control no-drag"
                                  dateFormat="dd/MM/yyyy"
                                  selected={record.responsibilityEndDate}
                                  maxDate={new Date()}
                                  onChange={(value) =>
                                    handleInputChangeResponsibilityEndDate(
                                      value,
                                      index
                                    )
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </>
                      ) : null;
                    })}
                    {errors?.endDate && (
                      <h4 className="text-error">{errors?.endDate}</h4>
                    )}

                    {(isUnit && isResponsibility) || isChangeUnit ? (
                      <Row>
                        <Label className="col-form-label">
                          <GetLanguageString props="member_select_responsibility_to_move" />
                        </Label>
                        {unitList.map((record, index) => {
                          const unitOptions = [];
                          record.unitList.forEach((d) =>
                            unitOptions.push({ value: d.id, label: d.name })
                          );
                          let selected = {};
                          if (record.selectedUnit !== null) {
                            selected = {
                              value: record.selectedUnit.id,
                              label: record.selectedUnit.name,
                            };
                          }
                          const isLastIndex = index === unitList.length - 1;

                          return (
                            <>
                              <Col md="3" key={index}>
                                <FormGroup>
                                  <Row>
                                    <Col md="10">
                                      <Select
                                        options={unitOptions}
                                        value={selected}
                                        className="basic-single ur no-drag"
                                        classNamePrefix="select"
                                        onChange={handleChangeUnits}
                                      />
                                    </Col>
                                    {isLastIndex ? null : (
                                      <Col md="2" className="p-0">
                                        {GetSelectedLanguage() === "en" ? (
                                          <i className="fa fa-arrow-right fa-lg m"></i>
                                        ) : (
                                          <i className="fa fa-arrow-left fa-lg m"></i>
                                        )}
                                      </Col>
                                    )}
                                  </Row>
                                </FormGroup>
                              </Col>
                            </>
                          );
                        })}
                      </Row>
                    ) : null}
                  </div>
                ) : (
                  LoadingSpinner()
                )}
              </ModalBody>
              <ModalFooter>
                <Button color="white" onClick={toggle}>
                  {<GetLanguageString props="common_cancel" />}
                </Button>
                <Button color="primary" type="submit" disabled={buttonDisable}>
                  {<GetLanguageString props="common_save_changes" />}
                </Button>
              </ModalFooter>
            </Form>
          </Modal>
        </Draggable>
      ) : (
        <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
          <Modal isOpen={true} className="inmodal" autoFocus={false}>
            <ModalHeader className="modal-title" tag="h4">
              {<GetLanguageString props="unit_member_delete_responsibility" />}
            </ModalHeader>
            {!loading ? (
              <>
                <ModalBody>
                  {responsibilityList.filter(
                    (r) =>
                      !r.responsibility.isDisabled &&
                      r.responsibility.isDeletable
                  ).length !== 0 ? (
                    responsibilityList.map((record, index) => {
                      return record.responsibility.isDisabled === false ? (
                        <div key={index} className="ibox-content ur">
                          <Row>
                            <Col md="6">
                              <h3>{record.responsibility.label}</h3>
                            </Col>
                            <Col md="6" className="text-right">
                              <i
                                className={"fa fa-2x fa-minus-square no-drag"}
                                style={{ cursor: "pointer", color: "red" }}
                                onClick={() =>
                                  removeObject(
                                    index,
                                    record.responsibility.value
                                  )
                                }
                              />
                            </Col>
                          </Row>
                        </div>
                      ) : null;
                    })
                  ) : (
                    <h4
                      style={{
                        color: "red",
                        fontStyle: "italic",
                        textAlign: "center",
                      }}
                    >
                      No Responsibility!
                    </h4>
                  )}

                  {errors?.selectResponsibility && (
                    <h4
                      className={
                        GetSelectedLanguage() === "en"
                          ? "text-error"
                          : "text-error ur"
                      }
                    >
                      {errors?.selectResponsibility}
                    </h4>
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button color="white" onClick={() => toggle()}>
                    {<GetLanguageString props="common_cancel" />}
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    onClick={() => handleDeleteResponsibility()}
                  >
                    {<GetLanguageString props="Next" />}
                  </Button>
                </ModalFooter>
              </>
            ) : (
              LoadingSpinner()
            )}
          </Modal>
        </Draggable>
      )}
    </div>
  );
};

export default DeleteMultipleResponsibility;
