import {useState} from "react";

const useSorting = (initialValues) => {
    
  const [sortObject, setSortObject] = useState(initialValues);

  const handleSorting = (name) => {
    if(sortObject[name] === 'neutral' || sortObject[name] === 'descending'){
      setSortObject({
        ...initialValues,
        [name]:'ascending'
      })
    }else if(sortObject[name] === 'ascending'){
      setSortObject({
        ...initialValues,
        [name]:'descending'
      })
    }
  }
    return{
        sortObject,
        setSortObject,
        handleSorting
    }
}

export default useSorting;