import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import Select from "react-select";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import swal from "sweetalert";
import {
  LoadingSpinner,
  isAlphaNumeric,
  isCodeUniqueEdit,
  isNumeric,
} from "../../../constants/const";
import { GetLanguageString } from "../../helper/Components";
import PickColor from "../PickColor";
import { ApiUnitType } from "./ApiUnitType";

const EditUnitType = ({ editValue, setEditModal, setUnitType }) => {
  const [loading, setLoading] = useState(false);
  const [initialFieldValues, setInitialFieldValues] = useState({
    type: "",
    code: "",
    selectList: [],
    sortOrder: "",
  });
  const [gender, setGender] = useState();
  const [checks, setChecks] = useState({
    allowRufqa: false,
    exRufqa: false,
  });

  const [color, setColor] = useState("");
  const [prevCode, setPrevCode] = useState();
  const [checkUnitName, setUnitName] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const toggle = () => setEditModal(false);
  const [errors, setErrors] = useState(initialFieldValues);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  useEffect(() => {
    setLoading(true);
    ApiUnitType()
      .getUnitTypeById(editValue)
      .then((res) => {
        setInitialFieldValues({
          id: res.data.id,
          type: res.data.type,
          code: res.data.code,
          selectList: res.data.selectList,
          sortOrder: res.data.sortOrder,
        });
        setGender(res.data.gender);
        setChecks({
          allowRufqa: res.data.allowRufqa,
          exRufqa: res.data.exRufqa,
        });
        setPrevCode(res.data.code);
        let optionsA = [];
        if (res.data.selectList !== null) setOptions(res.data.selectList);
        if (res.data.selectList !== null)
          res.data.selectList.forEach((d) =>
            d.isSelected ? optionsA.push(d) : null
          );
        if (res.data.color !== null) setColor(res.data.color);
        setSelectedOptions(optionsA);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    ApiUnitType()
      .getUniqueUnitName()
      .then((res) => {
        setUnitName(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [editValue]);

  const validate = () => {
    let temp = {};
    temp.type = initialFieldValues.type ? (
      ""
    ) : (
      <GetLanguageString props="settings_name_error_message" />
    );
    temp.code = initialFieldValues.code ? (
      isAlphaNumeric(initialFieldValues.code) ? (
        isCodeUniqueEdit(checkUnitName, prevCode, initialFieldValues.code) ? (
          ""
        ) : (
          <GetLanguageString props="settings_code_unique_error_message" />
        )
      ) : (
        <GetLanguageString props="settings_code_aplha_numeric_error_message" />
      )
    ) : (
      <GetLanguageString props="settings_code_error_message" />
    );
    temp.sortOrder = initialFieldValues.sortOrder ? (
      isNumeric(initialFieldValues.sortOrder) ? (
        ""
      ) : (
        <GetLanguageString props="settings_sort_order_numeric_error_message" />
      )
    ) : (
      <GetLanguageString props="settings_sort_order_error_message" />
    );

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setErrors({
      type: "",
    });
    setInitialFieldValues({
      ...initialFieldValues,
      [name]: value,
    });
  };
  const handleCheckbox = (e) => {
    const { name, value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setChecks({
      ...checks,
      [name]: v,
    });
  };

  const handleChangeUnitType = (record) => {
    setSelectedOptions(record);
  };

  const handleGenderStatus = (e) => {
    const value = e.target.value;
    let gender = "";
    if (value === "0") {
      gender = "m";
    } else if (value === "1") {
      gender = "f";
    }
    setGender(gender);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    initialFieldValues.selectList = selectedOptions;
    let record = {
      id: initialFieldValues.id,
      type: initialFieldValues.type,
      code: initialFieldValues.code,
      prevCode: prevCode,
      selectList: initialFieldValues.selectList,
      sortOrder: initialFieldValues.sortOrder,
      gender: gender,
      allowRufqa: checks.allowRufqa,
      exRufqa: checks.exRufqa,
      color: color,
    };
    if (validate()) {
      setLoading(true);
      ApiUnitType()
        .updateUnitType(editValue, record)
        .then((res) => {
          toggle();
          setUnitType(res.data);
          setLoading(false);
        })
        .catch((err) => {
          toggle();
          swal({
            title: err,
            text: err.response.data,
            icon: "error",
            buttons: "OK",
          });
          setLoading(false);
          console.log(`Error Msg: ${err.response.data}, ${err}`);
        });
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="settings_edit_unit_type" />}
          </ModalHeader>
          {!loading ? (
            <Form onSubmit={handleSubmit}>
              <ModalBody>
                <FormGroup>
                  <Label>{<GetLanguageString props="common_name" />}</Label>
                  <Input
                    autoFocus
                    type="text"
                    name="type"
                    className="ur no-drag"
                    value={initialFieldValues.type}
                    onChange={handleInputChange}
                    {...(errors?.type && { invalid: true })}
                  />
                  {errors?.type && (
                    <FormFeedback>
                      <h4>{errors?.type}</h4>
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label>
                    {
                      <GetLanguageString props="settings_notification_type_code" />
                    }
                  </Label>
                  <Input
                    type="text"
                    name="code"
                    className="no-drag"
                    value={initialFieldValues.code}
                    onChange={handleInputChange}
                    {...(errors?.code && { invalid: true })}
                  />
                  {errors?.code && (
                    <FormFeedback>
                      <h4>{errors?.code}</h4>
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="col-form-label">
                    {<GetLanguageString props="settings_select_parent_units" />}
                  </Label>
                  <Select
                    value={selectedOptions}
                    options={options}
                    className="basic-multi-select ur"
                    classNamePrefix="select"
                    isMulti
                    onChange={handleChangeUnitType}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>{<GetLanguageString props="unit_sort_order" />}</Label>
                  <Input
                    type="text"
                    name="sortOrder"
                    className="no-drag"
                    value={initialFieldValues.sortOrder}
                    onChange={handleInputChange}
                    {...(errors?.sortOrder && { invalid: true })}
                  />
                  {errors?.sortOrder && (
                    <FormFeedback>
                      <h4>{errors?.sortOrder}</h4>
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup check>
                  <Label check for="allowRufqa">
                    <Input
                      id="allowRufqa"
                      type="checkbox"
                      name="allowRufqa"
                      value={!checks.allowRufqa}
                      onChange={handleCheckbox}
                      checked={checks.allowRufqa}
                    />
                    {<GetLanguageString props="settings_allow_rufqa" />}
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check for="exRufqa">
                    <Input
                      id="exRufqa"
                      type="checkbox"
                      name="exRufqa"
                      value={!checks.exRufqa}
                      onChange={handleCheckbox}
                      checked={checks.exRufqa}
                    />
                    {<GetLanguageString props="settings_ex_rufqa" />}
                  </Label>
                </FormGroup>
                <FormGroup>
                  <Label>{<GetLanguageString props="settings_gender" />}</Label>
                  <Row className=" m-t-sm">
                    <Col md="2">
                      <FormGroup check>
                        <Label check for="male">
                          <Input
                            id="male"
                            type="radio"
                            checked={gender === "m"}
                            value={0}
                            onChange={handleGenderStatus}
                          />
                          {<GetLanguageString props="settings_gender_male" />}
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup check>
                        <Label check for="female">
                          <Input
                            id="female"
                            type="radio"
                            checked={gender === "f"}
                            value={1}
                            onChange={handleGenderStatus}
                          />
                          {<GetLanguageString props="settings_gender_female" />}
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </FormGroup>
                <PickColor setColor={setColor} color={color} />
              </ModalBody>
              <ModalFooter>
                <Button color="white" onClick={toggle}>
                  {<GetLanguageString props="common_cancel" />}
                </Button>
                <Button color="primary" type="submit">
                  {<GetLanguageString props="common_save_changes" />}
                </Button>
              </ModalFooter>
            </Form>
          ) : (
            LoadingSpinner()
          )}
        </Modal>
      </Draggable>
    </div>
  );
};

export default EditUnitType;
