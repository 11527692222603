import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import { withRouter, useLocation } from "react-router-dom";
import swal from "sweetalert";
import { GetLanguageString } from "../helper/Components";
import { requestApi } from "../../actions/api";
import moment from "moment";
import BulkMemberField from "./BulkMemberField";
import { ApiCourse } from "../courses/ApiCourse";
import { useTranslation } from "react-i18next";
import { convertUTCToLocalTime } from "../../constants/const";
import { GetSelectedLanguage } from "../helper/Method";

const AddBulkParticipants = ({ history }) => {
  const selectedLanguage = localStorage.getItem("i18nextLng");
  const [coursesDetail, setcoursesDetail] = useState({
    startDate: null,
    address: "",
    description: "",
    endDate: null,
    courseType: {},
  });
  const { t } = useTranslation();
  const location = useLocation();
  const courseId = location.state.courseId;
  const [status, setStatus] = useState(true);
  const [loading, setloading] = useState(false)
  const [comments, setComments] = useState("");
  const [regNo, setRegNo] = useState("");
  const [values, setValues] = useState([
    {
      regNo: regNo,
      comments: comments,
      status: status,
      courseId: courseId,
    },
  ]);

  useEffect(() => {
    requestApi("course/")
      .fetchById(courseId)
      .then((res) => {
        setcoursesDetail(res.data);
      });
  }, [courseId]);

  const addMember = () => {
    const tempValues = [...values];
    tempValues.push({
      regNo: regNo,
      comments: comments,
      status: status,
      courseId: courseId,
    });
    setValues(tempValues);
  };
  const handleonSave = () => {
      const tempValues = [...values];
      tempValues.push({
        regNo: regNo,
        comments: comments,
        status: status,
        courseId: courseId,
      });
      ApiCourse()
        .addMembersbyRegNo(values)
        .then((res) => {
          if (res.data.toString() !== "") {
            swal({
              title: t("course_participant_invalid_reg_no"),
              className: GetSelectedLanguage() === "en" ? "" : "ur",
              text:
                t("course_participant_invalid_reg_no_list") +
                res.data.toString(),
              icon: "warning",
              buttons: {
                cancel: t("courses_participants_back"),
                confirm: t("course_participant_add_again_btn"),
              },
            }).then((res) => {
              if (res) {
                setloading(true)
                setValues([
                    {
                      regNo: regNo,
                      comments: comments,
                      status: status,
                      courseId: courseId,
                    },
                  ])
                setloading(false)
              } else {
                history.goBack();
              }
            });
          } else {
            history.goBack()
          }
        })
        .catch((err) => console.log(err));
    
  };
  return (
    <div>
      <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
        <div className="ibox">
          <div className="ibox-title">
            <h5>
              {
                <GetLanguageString props="courses_participants_course_details" />
              }
            </h5>
          </div>
          <div className="ibox-content">
            <Row>
              <Col lg="3">
                <h3 className="ur">
                  {<GetLanguageString props="common_name" />}:{" "}
                  {coursesDetail.courseType.label}
                </h3>
              </Col>
              <Col lg="3">
                <h3 className="ur">
                  {<GetLanguageString props="courses_address" />}:{" "}
                  {coursesDetail.address}
                </h3>{" "}
              </Col>
              <Col lg="3">
                <h3>
                  {" "}
                  {<GetLanguageString props="courses_start_date" />}:{" "}
                  {moment(convertUTCToLocalTime(coursesDetail.startDate)).format("DD MMMM, yyyy")}
                </h3>
              </Col>
              <Col lg="3">
                <h3>
                  {<GetLanguageString props="courses_end_date" />}:{" "}
                  {moment(convertUTCToLocalTime(coursesDetail.endDate)).format("DD MMMM, yyyy")}
                </h3>
              </Col>
            </Row>
          </div>
        </div>
        {!loading && 
        <div className="ibox">
          <div className="ibox-title">
            <h5>{<GetLanguageString props="participant_add_by_reg_no" />}</h5>
            <div className={selectedLanguage === "en" ? "pull-right" : "pull-left"}>
            <i
              className={"fa fa-2x fa-plus-square"}
              style={{ cursor: "pointer", color: "green" }}
              onClick={addMember}
            />
            </div>
          </div>
          <div className="ibox-content">
            <div className="table-responsive">
              {values.map((record, index) => {
                return (
                  <BulkMemberField
                    key={index}
                    id={index}
                    setValues={setValues}
                    values={values}
                    setComments={setComments}
                    setRegNo={setRegNo}
                    setStatus={setStatus}
                  />
                );
              })}
            </div>
            <Row>
              <Col md="4">
                <Button color="white" onClick={() => history.goBack()}>
                  {<GetLanguageString props="common_cancel" />}
                </Button>
                <Button
                  color="primary"
                  className="m-l-sm"
                  type="submit"
                  onClick={handleonSave}
                >
                  {<GetLanguageString props="common_save_changes" />}
                </Button>
              </Col>
            </Row>
          </div>
        </div>
        }
      </div>
    </div>
  );
};

export default withRouter(AddBulkParticipants);
