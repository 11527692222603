import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./content/css/style.css";
import "./content/css/custom.css";
import "./content/css/print.css";
import LoginRedirect from "./components/RedirectPage/LoginRedirect";
import AhbabForm from "./components/ahbab/ContactFormAhbab/AhbabForm";
function App() {
  return (
    <Router>
      <Switch>
        <Route exact path={"/contactUs"}>
          <AhbabForm />
        </Route>
        <Route exact path={"*"}>
          <LoginRedirect />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
