import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Button,
  Alert,
} from "reactstrap";
import Draggable from "react-draggable";

import { GetLanguageString } from "../helper/Components";

const AdminModal = ({ setModal, removeAdmin, setRemoveAdmin }) => {
  const toggle = () => {
    setModal(false);
    setRemoveAdmin(false)
  };
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal">
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="users_role_assigned" />}
          </ModalHeader>
          <Form>
            <ModalBody>
              {removeAdmin ? <Alert color="danger">{<GetLanguageString props="users_role_remove_alert" />}.</Alert> : <Alert>{<GetLanguageString props="users_role_alert" />}.</Alert>}
            </ModalBody>
            <ModalFooter>
              <Button color="white" onClick={toggle}>
                {<GetLanguageString props="users_role_ok_btn" />}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </Draggable>
    </div>
  );
};

export default AdminModal;
