import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Draggable from "react-draggable";
import swal from "sweetalert";
import { LoadingSpinner, isUrdu } from "../../constants/const";
import { GetSelectedLanguage } from "../helper/Method";
import { ApiCourse } from "./ApiCourse";
import { GetLanguageString } from "../helper/Components";

const AddCourseLocation = ({
  setAddCourseLocationModal,
  setReloadList,
  reloadList,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [courseLocation, setCourseLocation] = useState("");
  const [errors, setErrors] = useState("");
  const toggle = () => setAddCourseLocationModal(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  const validate = () => {
    let temp = {};
    temp.name = courseLocation ? "" : t("common_field_required_error");

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setErrors({
      ...errors,
      name: "",
    });
    setCourseLocation(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      ApiCourse()
        .addCourseLocation(courseLocation)
        .then((res) => {
          toggle();
          setLoading(false);
          swal({
            title: t("courses_add_course_location_success"),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "success",
            buttons: "OK",
          });
          setReloadList(!reloadList);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          swal({
            title: "Error: " + err.response?.data || err,
            icon: "error",
            buttons: "OK",
          });
        });
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="courses_add_course_location" />}
          </ModalHeader>
          {!loading ? (
            <Form onSubmit={handleSubmit}>
              <ModalBody>
                <FormGroup>
                  <Label>
                    {<GetLanguageString props="courses_course_location" />}
                  </Label>
                  <Input
                    autoFocus
                    type="text"
                    name="name"
                    className={
                      isUrdu(courseLocation) ? "no-drag ur" : "no-drag"
                    }
                    value={courseLocation}
                    onChange={handleInputChange}
                    {...(errors.name && { invalid: true })}
                  />
                  {errors.name && (
                    <FormFeedback>
                      <h4>{errors.name}</h4>
                    </FormFeedback>
                  )}
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="white" onClick={toggle}>
                  {<GetLanguageString props="common_cancel" />}
                </Button>
                <Button color="primary" type="submit">
                  {<GetLanguageString props="common_save_changes" />}
                </Button>
              </ModalFooter>
            </Form>
          ) : (
            LoadingSpinner()
          )}
        </Modal>
      </Draggable>
    </div>
  );
};

export default AddCourseLocation;
