import React, { useEffect, useState } from "react";
import { convertToDateString, LoadingSpinner } from "../../constants/const";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { GetLanguageString } from "../helper/Components";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { GetSelectedLanguage } from "../helper/Method";
import { ApiUnit } from "../unit/ApiUnit";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import Draggable from "react-draggable";

const MarkRafiqToEx = ({
  setMarkRafiqExModal,
  memberId,
  setRefresh,
  refresh,
}) => {
  const toggle = () => {
    setMarkRafiqExModal(false);
  };

  const [values, setValues] = useState({
    memberId: memberId,
    reason: "",
    date: null,
    referenceNote: "",
  });

  const { t } = useTranslation();
  const notify = (string) => toast.success(string);
  const [errors, setErrors] = useState(values);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [units, setUnits] = useState([]);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  useEffect(() => {
    setLoading(true);
    setButtonDisable(true);
    ApiUnit()
      .GetExTypeUnits()
      .then((res) => {
        setUnits(res.data);
        setLoading(false);
        setButtonDisable(false);
      })
      .catch((err) => {
        setLoading(true);
        setButtonDisable(true);
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
        console.log(err);
      });
  }, []);

  const handleInputChangeDate = (value) => {
    if (value !== null) {
      setSelectedDate(value);
      setErrors({
        ...errors,
        date: "",
      });
    }
  };
  const validate = () => {
    let temp = {};
    temp.reason =
      values.reason === "" ? (
        <GetLanguageString props="member_member_mark_rafiq_ex_reason_error" />
      ) : (
        ""
      );

    temp.date =
      selectedDate === null || selectedDate === "" ? (
        <GetLanguageString props="member_member_mark_rafiq_ex_date_error" />
      ) : (
        ""
      );
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleChangeReason = (record) => {
    setValues({
      ...values,
      reason: record,
    });
    setErrors({
      ...errors,
      reason: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      setButtonDisable(true);
      values.date = convertToDateString(selectedDate);
      ApiUnit()
        .MarkRafiqAsEx(values)
        .then((res) => {
          if (res.data === "Marked") {
            swal({
              title: t("member_member_mark_rafiq_ex_success"),
              className: GetSelectedLanguage() === "en" ? "" : "ur",
              icon: "success",
              buttons: "OK",
            });
            toggle();
            setRefresh(!refresh);
          } else {
            notify(res.data);
            toggle();
          }
          setLoading(false);
          setButtonDisable(false);
        })
        .catch((err) => {
          setLoading(true);
          setButtonDisable(true);
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
          setLoading(false);
          setButtonDisable(false);
          console.log(err);
        });
    }
  };

  return (
    <div>
      <ToastContainer
        autoClose={3000}
        bodyClassName={GetSelectedLanguage()}
        closeOnClick
        pauseOnHover={false}
        draggable
      />
      <Draggable position={position} onDrag={handleDrag} cancel="no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="member_details_button_mark_rafiq_ex" />}
          </ModalHeader>
          <Form onSubmit={handleSubmit}>
            {!loading ? (
              <ModalBody>
                {" "}
                <FormGroup>
                  <Label className="col-form-label">
                    {
                      <GetLanguageString props="member_details_mark_rafiq_ex_reason" />
                    }
                  </Label>
                  <Select
                    options={units}
                    className="basic-single ur"
                    classNamePrefix="select"
                    placeholder={
                      GetSelectedLanguage() === "en" ? "Select" : "منتخب کریں"
                    }
                    onChange={(record) => {
                      handleChangeReason(record);
                    }}
                  />
                  {errors?.reason && (
                    <h4 className="text-error">{errors.reason}</h4>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="col-form-label">
                    {<GetLanguageString props="funds_date" />}
                  </Label>
                  <DatePicker
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd/mm/yyyy"
                    className="form-control"
                    selected={selectedDate}
                    maxDate={new Date()}
                    onChange={handleInputChangeDate}
                    dateFormat="dd/MM/yyyy"
                  />
                  {errors.date && <h4 className="text-error">{errors.date}</h4>}
                </FormGroup>
                <FormGroup>
                  <Label className="col-form-label">
                    {<GetLanguageString props="member_reference_note" />}
                  </Label>
                  <Input
                    type="text"
                    name="referenceNote"
                    className="no-drag"
                    value={values.referenceNote}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </ModalBody>
            ) : (
              LoadingSpinner()
            )}
            <ModalFooter>
              <Button color="white" onClick={toggle}>
                {<GetLanguageString props="common_cancel" />}
              </Button>
              <Button color="primary" type="submit" disabled={buttonDisable}>
                {<GetLanguageString props="common_save_changes" />}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </Draggable>
    </div>
  );
};

export default MarkRafiqToEx;
