import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Label,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";
import Select from "react-select";

import DatePicker from "react-datepicker";
import swal from "sweetalert";
import { ApiUnit } from "./ApiUnit";
import { ApiUnitType } from "../config/unitType/ApiUnitType";
import { GetLanguageString } from "../helper/Components";
import {
  convertToDateString,
  JoiningDateValidater,
  LoadingSpinner,
  nonAlphanumericRegex,
} from "../../constants/const";

import Draggable from "react-draggable";
import { GetSelectedLanguage } from "../helper/Method";
import { useTranslation } from "react-i18next";

const AddUnit = ({ setModal, setUnit, unitId, unitTypeId, loggedInUnitId }) => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disableUnitCode, setDisableUnitCode] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  const [serverError, setServerError] = useState("");
  const [values, setValues] = useState({
    name: "",
    code: "",
    sortOrder: "",
    creationDate: null,
    unitTypeId: 0,
  });
  const [errors, setErrors] = useState({
    name: "",
    code: "",
    sortOrder: "",
    unitTypeId: 0,
    creationDate: null,
  });
  const toggle = () => setModal(false);
  const [unitType, setUnitType] = useState([]);
  const [selectedUnitType, setSelectedUnitType] = useState({});

  useEffect(() => {
    let value = unitTypeId ? unitTypeId : 0;

    ApiUnitType()
      .GetUnitTypeForUnitAccordingToPermission(value)
      .then((res) => {
        setUnitType(res.data);
        setSelectedUnitType(res.data[0]);
        if (
          res.data[0].label === "اسرہ" ||
          res.data[0].label === "منفرد" ||
          res.data[0].label === "اسرہ معاونین"
        ) {
          setDisableUnitCode(true);
        }
      })
      .catch((err) => console.log(err));
  }, [unitTypeId]);

  const validate = () => {
    let temp = {};
    temp.name = values.name ? (
      ""
    ) : (
      <GetLanguageString props="settings_name_error_message" />
    );
    temp.unitTypeId =
      values.unitTypeId !== 0 ? (
        ""
      ) : (
        <GetLanguageString props="unit_unit_type_error_message" />
      );
    temp.creationDate = selectedDate ? (
      JoiningDateValidater(selectedDate) === true ? (
        ""
      ) : (
        <GetLanguageString props="unit_creation_date_range_error_message" />
      )
    ) : (
      <GetLanguageString props="unit_creation_date_error_message" />
    );
    temp.code = disableUnitCode ? (
      ""
    ) : values.code ? (
      values.code.length === 2 ? (
        ""
      ) : (
        <GetLanguageString props="unit_code_error_message1" />
      )
    ) : (
      <GetLanguageString props="unit_code_error_message" />
    );

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "code") {
      if (!nonAlphanumericRegex.test(value)) {
        setValues({
          ...values,
          code: value,
        });
        setErrors({
          code: "",
        });
      }
    } else {
      setValues({
        ...values,
        [name]: value,
      });
      setErrors({
        [name]: "",
      });
    }
  };

  const handleChangeUnitType = (record) => {
    setSelectedUnitType(record);
    if (
      record.label === "اسرہ" ||
      record.label === "منفرد" ||
      record.label === "اسرہ معاونین"
    ) {
      setDisableUnitCode(true);
      setValues({
        ...values,
        unitTypeId: record.value,
        code: "",
      });
    } else {
      setValues({
        ...values,
        unitTypeId: record.value,
      });
      setDisableUnitCode(false);
    }
    setErrors({
      unitTypeId: "",
    });
  };

  const handleInputChangeCreationDate = (value) => {
    if (value !== null) {
      setSelectedDate(value);
      setErrors({
        creationDate: "",
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    values.unitTypeId =
      selectedUnitType === undefined ? 0 : selectedUnitType.value;
    if (unitId !== 0 && unitId !== "0") values.parentUnitId = unitId;
    if (validate()) {
      setLoading(true);
      values.creationDate = convertToDateString(selectedDate);
      ApiUnit()
        .addUnit(values)
        .then((res) => {
          swal({
            title: t("unit_add_success_message"),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "success",
            buttons: "OK",
          });
          let parentId = values.parentUnitId === null ? 0 : values.parentUnitId;
          ApiUnit()
            .getUnit(parentId, loggedInUnitId)
            .then((res) => {
              toggle();
              setUnit(res.data.unitList);
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              swal({
                title: err,
                icon: "error",
                buttons: "OK",
              });
              setLoading(false);
            });
        })
        .catch((err) => {
          console.log(err);
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
          setLoading(false);
          if (
            err?.response?.data === "sameCode" ||
            err?.response?.data === "notAllowed" ||
            err?.response?.data === "duplicate"
          ) {
            setServerError(err?.response?.data);
          }
        });
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="unit_add_unit" />}
          </ModalHeader>
          {!loading ? (
            <Form onSubmit={handleSubmit}>
              <ModalBody>
                {serverError === "sameCode" ? (
                  <h4 className="text-danger text-center">
                    {
                      <GetLanguageString props="unit_code_server_error_message" />
                    }
                  </h4>
                ) : serverError === "notAllowed" ? (
                  <h4 className="text-danger text-center">
                    {
                      <GetLanguageString props="unit_code_mq_server_error_message" />
                    }
                  </h4>
                ) : serverError === "duplicate" ? (
                  <h4 className="text-danger text-center">
                    {
                      <GetLanguageString props="unit_duplicate_server_error_message" />
                    }
                  </h4>
                ) : null}
                <FormGroup>
                  <Label>{<GetLanguageString props="common_name" />}</Label>
                  <Input
                    className="ur no-drag"
                    autoFocus
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleInputChange}
                    {...(errors.name && { invalid: true })}
                  />
                  {errors.name && (
                    <FormFeedback>
                      <h4>{errors.name}</h4>
                    </FormFeedback>
                  )}
                </FormGroup>
                {disableUnitCode ? (
                  <></>
                ) : (
                  <FormGroup>
                    <Label>
                      {<GetLanguageString props="unit_unit_code" />}
                    </Label>
                    <Input
                      className="ur no-drag"
                      type="text"
                      name="code"
                      value={values.code}
                      onChange={handleInputChange}
                      {...(errors.code && { invalid: true })}
                    />
                    {errors.code && (
                      <FormFeedback>
                        <h4>{errors.code}</h4>
                      </FormFeedback>
                    )}
                  </FormGroup>
                )}
                <FormGroup>
                  <Label className="col-form-label">
                    {<GetLanguageString props="unit_creation_date" />}
                  </Label>
                  <DatePicker
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd/mm/yyyy"
                    className="form-control no-drag"
                    selected={selectedDate}
                    maxDate={new Date()}
                    onChange={handleInputChangeCreationDate}
                    dateFormat="dd/MM/yyyy"
                  />
                  {errors.creationDate && (
                    <h4 className="text-error">{errors.creationDate}</h4>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="col-form-label">
                    {<GetLanguageString props="settings_select_unit_type" />}
                  </Label>
                  <Select
                    value={selectedUnitType}
                    options={unitType}
                    className="basic-single ur no-drag"
                    classNamePrefix="select"
                    onChange={handleChangeUnitType}
                  />
                  {errors.unitTypeId ? (
                    <h4 className="text-error">{errors.unitTypeId}</h4>
                  ) : null}
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="white" onClick={toggle}>
                  {<GetLanguageString props="common_cancel" />}
                </Button>
                <Button color="primary" type="submit">
                  {<GetLanguageString props="common_save_changes" />}
                </Button>
              </ModalFooter>
            </Form>
          ) : (
            LoadingSpinner()
          )}
        </Modal>
      </Draggable>
    </div>
  );
};

export default AddUnit;
