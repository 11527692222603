import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { Table, Button, ButtonGroup } from "reactstrap";
import { useTranslation } from "react-i18next";

import { GetLanguageString, NoResult } from "../../helper/Components";
import { LoadingSpinner, isUrdu } from "../../../constants/const";
import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import { ApiContactType } from "./ApiContactType";
import AddContactType from "./AddContactType";
import EditContactType from "./EditContactType";
import { IoAdd } from "react-icons/io5";

const ContactType = ({ activeTab }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [contactType, setContactType] = useState([]);
  const [reload, setReload] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editValue, setEditValue] = useState();
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (activeTab === "contactType") {
      ApiContactType()
        .getContactType()
        .then((res) => {
          setContactType(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [activeTab, reload]);

  const handleOnDelete = (value, isAffiliated) => {
    if (isAffiliated) {
      swal({
        title: t("common_is_affiliated"),
        text: t("common_is_affiliated_message"),
        className: GetSelectedLanguage() === "en" ? "" : "ur",
        icon: "warning",
      });
    } else {
      swal({
        title: t("common_delete_message"),
        text: t("common_delete_detail_message"),
        className: GetSelectedLanguage() === "en" ? "" : "ur",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setLoading(true);
          ApiContactType()
            .deleteContactType(value)
            .then((res) => {
              swal({
                title: t("settings_contact_type_delete_success"),
                className: GetSelectedLanguage() === "en" ? "" : "ur",
                icon: "success",
                buttons: "OK",
              });
              setLoading(false);
              setReload(!reload);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        }
      });
    }
  };

  const handleOnEdit = (e) => {
    const { value } = e.target;
    setEditValue(value);
    setEditModal(true);
  };

  return (
    <>
      {editModal ? (
        <EditContactType
          id={editValue}
          setEditModal={setEditModal}
          setReload={setReload}
          reload={reload}
        />
      ) : null}
      {modal ? (
        <AddContactType
          setModal={setModal}
          setReload={setReload}
          reload={reload}
        />
      ) : null}
      {!loading ? (
        <div className="ibox">
          <div className="ibox-title">
            <h5>
              <GetLanguageString props="settings_contact_types" />
            </h5>
            <Button
              color="white"
              size="sm"
              className={GetCustomStyles().btn_style}
              onClick={() => {
                setModal(true);
              }}
            >
              <IoAdd
                size={18}
                color="#18A689"
                style={{ marginRight: 3, marginBottom: 1 }}
              />
              {<GetLanguageString props="settings_contact_type_add" />}
            </Button>
          </div>
          <div className="ibox-content">
            {contactType.length !== 0 ? (
              <div className="table-responsive">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th className="text-center">
                        <GetLanguageString props="common_name" />
                      </th>
                      <th className="text-center">
                        {<GetLanguageString props="settings_code" />}
                      </th>
                      <th className="text-center">
                        {
                          <GetLanguageString props="settings_contact_type_input_type" />
                        }
                      </th>
                      <th className="text-center">
                        {
                          <GetLanguageString props="settings_contact_type_input_rtl" />
                        }
                      </th>
                      <th className="text-center">
                        {
                          <GetLanguageString props="settings_contact_type_input_icon_html" />
                        }
                      </th>
                      <th className="text-center">
                        {<GetLanguageString props="common_action" />}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {contactType?.map((record, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td
                            className={
                              isUrdu(record.type)
                                ? "ur text-center"
                                : "text-center"
                            }
                          >
                            {record.type}
                          </td>
                          <td className="text-center">{record.code}</td>
                          <td className="text-center">{record.inputType}</td>
                          <td className="text-center">
                            {record.isRtl ? (
                              <GetLanguageString props="Yes" />
                            ) : (
                              <GetLanguageString props="No" />
                            )}
                          </td>
                          <td className="text-center">
                            {record.iconHtml} (&nbsp;
                            <span
                              dangerouslySetInnerHTML={{
                                __html: record.iconHtml,
                              }}
                            ></span>
                            &nbsp;)
                          </td>
                          <td className="text-center">
                            <ButtonGroup>
                              <Button
                                color="white"
                                size="sm"
                                onClick={handleOnEdit}
                                value={record.id}
                              >
                                {<GetLanguageString props="common_edit" />}
                              </Button>
                              <Button
                                color="white"
                                size="sm"
                                onClick={() =>
                                  handleOnDelete(record.id, record.isAffiliated)
                                }
                              >
                                {<GetLanguageString props="common_delete" />}
                              </Button>
                            </ButtonGroup>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            ) : (
              <NoResult />
            )}
          </div>
        </div>
      ) : (
        LoadingSpinner()
      )}
    </>
  );
};

export default ContactType;
