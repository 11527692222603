import axios from "axios";

import { BASEURL } from "../../../constants/const";
import { encryptStorage } from "../../../constants/EncryptStorage";

export function ApiResponsibilityType() {
  const userToken = encryptStorage.getItem("token");

  const authAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const url = BASEURL + "responsibilitytype/";
  return {
    getResponsibility: () => authAxios.get(url + "getresponsibilitytype/"),
    getResponsibilityTypeSelectList: () =>
      authAxios.get(url + "GetResponsibilityTypeSelectList/"),
    getStaticResponsibilityType: () =>
      authAxios.get(url + "getStaticResponsibilityTypes/"),
    getResponsibilityById: (id) =>
      authAxios.get(url + "getresponsibilitytypebyid/" + id),
    setResponsibility: (newRecord) =>
      authAxios.post(url + "addresponsibilitytype/", newRecord),
    deleteResponsibility: (id) =>
      authAxios.delete(url + "deleteresponsibilitytype/" + id),
    updateResponsibility: (id, updateRecord) =>
      authAxios.put(url + "putresponsibilitytype/" + id, updateRecord),
  };
}

export function ApiResponsibility() {
  const userToken = encryptStorage.getItem("token");

  const authAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const fileAuthAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    responseType: "arraybuffer",
  });

  const url = BASEURL + "responsibility/";
  return {
    getResponsibility: () => authAxios.get(url + "GetResponsibilities/"),
    getResponsibilitiesByLoggedInUnit: (unitId) =>
      authAxios.get(url + "GetResponsibilitiesByLoggedInUnit/" + unitId),
    getResponsibilitiesDictionary: () =>
      authAxios.get(url + "GetResponsibilitiesDictionary/"),
    getSortedResponsibility: (record) =>
      authAxios.post(url + "GetResponsibilities/", record),
    getUnitResponsibility: (unitId) =>
      authAxios.get(url + "GetUnitResponsibilities/" + unitId),
    getUnitResponsibilitiesByPermissions: (unitId, memberId) =>
      authAxios.get(
        url + "GetUnitResponsibilitiesByPermissions/" + unitId + "/" + memberId
      ),
    getStaticResponsibilityType: () =>
      authAxios.get(url + "getStaticResponsibilityTypes/"),
    getResponsibilityById: (id) =>
      authAxios.get(url + "getresponsibilitytypebyid/" + id),
    setResponsibility: (newRecord) =>
      authAxios.post(url + "AddResponsibility/", newRecord),
    deleteResponsibility: (id) =>
      authAxios.delete(url + "deleteresponsibilitytype/" + id),
    updateResponsibility: (id, updateRecord) =>
      authAxios.put(url + "putresponsibilitytype/" + id, updateRecord),
    GetResponsibilitiesForNotificationFilters: () =>
      authAxios.get(url + "GetResponsibilitiesForNotificationFilters"),
    GetResponisbiitesInUnitAndChildUnits: (record) =>
      authAxios.post(url + "GetResponisbiitesInUnitAndChildUnits/", record),
    GetMemberResponsibilities: (memberId) =>
      authAxios.get(url + "GetMemberResponsibilities/" + memberId),
    GetMovingMemberResponsibilities: (memberIds) =>
      authAxios.post(url + "GetMovingMemberResponsibilities/", memberIds),
    GetVacantResponsibilitiesReport: (unitId, showDetails, showSemiRequired, showSelectedUnitsCheck) =>
      authAxios.get(url + "GetVacantResponsibilitiesReport/" + unitId + "/" + showDetails + "/" + showSemiRequired + "/" + showSelectedUnitsCheck),
    GetVacantResponsibilitiesExcelReport: (unitId, showDetails, showSemiRequired, showSelectedUnitsCheck) =>
      fileAuthAxios.get(url + "GetVacantResponsibilitiesExcelReport/" + unitId + "/" + showDetails + "/" + showSemiRequired+ "/" + showSelectedUnitsCheck),
  };
}
