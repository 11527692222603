import React, { useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap";
import MemberPermissions from "./MemberPermissions";
import { NoResult } from "../helper/Components";

const UserPermissionsByUnit = ({ values }) => {
  const [open, setOpen] = useState("");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  return (
    <>
      {values.length !== 0 ? (
        values?.map((item, index) => {
          return (
            <div key={index}>
              <Accordion open={open} toggle={toggle} key={index}>
                <AccordionItem>
                  <AccordionHeader
                    style={{ backgroundColor: "#f9f9f9" }}
                    className="ur"
                    targetId={(index + 1).toString()}
                  >
                    {item.label}
                  </AccordionHeader>
                  <AccordionBody accordionId={(index + 1).toString()}>
                    <div style={{ backgroundColor: "#f9f9f9" }}>
                      <MemberPermissions values={item.permissions} />
                    </div>
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
            </div>
          );
        })
      ) : (
        <NoResult />
      )}
    </>
  );
};

export default UserPermissionsByUnit;
