import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Label,
  Button,
  FormFeedback,
  Input,
} from "reactstrap";

import useForm from "../shared/useForm";
import { smsApi } from "./SendSMSApi";
import { LoadingSpinner } from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";
import { GetLanguageString } from "../helper/Components";
import Draggable from "react-draggable";

const SendSMSModal = ({ setModal, memberList, memberListLength }) => {
  const [loading, setLoading] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  const memberId = parseInt(encryptStorage.getItem("memberId"));
  const { errors, setErrors, values, handleInputChange } = useForm({
    message: "",
    number: "",
  });
  let membersPhoneNumbers = [];

  for (let i = 0; i < memberListLength; i++) {
    if (memberList[i].status) membersPhoneNumbers.push(memberList[i].mobileNo);
  }

  const toggle = () => setModal(false);

  const validate = () => {
    let temp = {};
    temp.message = values.message ? "" : "Please enter a message";
    temp.number =
      membersPhoneNumbers.length !== 0 ? "" : "Atleast select a number";
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      setLoading(true);
      let smsModal = {
        userId: memberId,
        messageBody: values.message,
        mobileNumbers: membersPhoneNumbers.join(", "),
      };
      smsApi()
        .sendSMS(smsModal)
        .then((res) => {
          setLoading(false);
          setModal(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          {!loading ? (
            <>
              <ModalHeader className="modal-title" tag="h4">
                {<GetLanguageString props="sms_send_sms" />}
              </ModalHeader>
              <Form>
                <ModalBody>
                  <FormGroup>
                    <Label>{<GetLanguageString props="sms_send_to" />} </Label>
                    <p>{membersPhoneNumbers.join(", ")}</p>
                    {errors.number && (
                      <h4 className="text-danger">{errors.number}</h4>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      {<GetLanguageString props="sms_message_body" />}
                    </Label>
                    <Input
                      autoFocus
                      type="textarea"
                      name="message"
                      className="no-drag"
                      rows="4"
                      cols="4"
                      value={values.message}
                      onChange={handleInputChange}
                      {...(errors.message && { invalid: true })}
                    />
                    {errors.message && (
                      <FormFeedback>
                        <h4>{errors.message}</h4>
                      </FormFeedback>
                    )}
                  </FormGroup>
                </ModalBody>
                <ModalFooter>
                  <Button color="white" onClick={toggle}>
                    {<GetLanguageString props="common_cancel" />}
                  </Button>
                  <Button color="primary" onClick={handleSubmit}>
                    {<GetLanguageString props="sms_send" />}
                  </Button>
                </ModalFooter>
              </Form>
            </>
          ) : (
            LoadingSpinner()
          )}
        </Modal>
      </Draggable>
    </div>
  );
};

export default SendSMSModal;
