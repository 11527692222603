import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Table } from "reactstrap";
import swal from "sweetalert";

import { LoadingSpinner } from "../../../../constants/const";
import { GetLanguageString, NoResult } from "../../../helper/Components";
import { GetCustomStyles, GetSelectedLanguage } from "../../../helper/Method";
import { ApiLetterForCounselling } from "../ApiLetterForCounselling";
import AddCounsellingQuestions from "./AddCounsellingQuestions";
import EditCounsellingQuestions from "./EditCounsellingQuestions";
import { IoAdd } from "react-icons/io5";
// import EditCounsellingQuestions from "./EditCounsellingQuestions";

const CounsellingQuestions = ({ activeTab }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [counsellingQuestions, setCounsellingQuestions] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [editValue, setEditValue] = useState();
  const [addModal, setAddModal] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (activeTab === "counsellingQuestions") {
      setLoading(true);
      ApiLetterForCounselling()
        .getCounsellingQuestions()
        .then((res) => {
          setCounsellingQuestions(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [activeTab, reload]);

  const handleOnDelete = (value, isAffiliated) => {
    if (isAffiliated) {
      swal({
        title: t("common_is_affiliated"),
        text: t("common_is_affiliated_message"),
        className: GetSelectedLanguage() === "en" ? "" : "ur",
        icon: "warning",
      });
    } else {
      swal({
        title: t("common_delete_message"),
        text: t("common_delete_detail_message"),
        icon: "warning",
        className: GetSelectedLanguage() === "en" ? "" : "ur",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setLoading(true);
          ApiLetterForCounselling()
            .deleteCounsellingQuestion(value)
            .then((res) => {
              swal({
                title: t("settings_counselling_question_delete_success"),
                className: GetSelectedLanguage() === "en" ? "" : "ur",
                icon: "success",
                buttons: "OK",
              });
              setLoading(false);
              setReload(!reload);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        }
      });
    }
  };

  const handleOnEdit = (e) => {
    const { value } = e.target;
    setEditValue(value);
    setEditModal(true);
  };

  return (
    <>
      {addModal ? (
        <AddCounsellingQuestions
          setAddModal={setAddModal}
          setReload={setReload}
          reload={reload}
        />
      ) : null}
      {editModal ? (
        <EditCounsellingQuestions
          editValue={editValue}
          setEditModal={setEditModal}
          setReload={setReload}
          reload={reload}
        />
      ) : null}
      {!loading ? (
        <div className="ibox">
          <div className="ibox-title">
            <h5>
              {<GetLanguageString props="settings_counselling_questions" />}
            </h5>
            <Button
              color="white"
              size="sm"
              className={GetCustomStyles().btn_style}
              onClick={() => setAddModal(true)}
            >
              <IoAdd
                size={18}
                color="#18A689"
                style={{ marginRight: 3, marginBottom: 1 }}
              />
              <GetLanguageString props="settings_counselling_question_add" />
            </Button>
          </div>
          <div className="ibox-content">
            {counsellingQuestions.length !== 0 ? (
              <div className="table-responsive">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>
                        {
                          <GetLanguageString props="settings_counselling_question_content" />
                        }
                      </th>
                      <th className="text-center">
                        {
                          <GetLanguageString props="settings_counselling_question_updated_version" />
                        }
                      </th>
                      <th className="text-center">
                        {
                          <GetLanguageString props="settings_letter_for_counselling_archive" />
                        }
                      </th>
                      <th className="text-center">
                        <GetLanguageString props="member_address_enabled" />
                      </th>
                      <th className="text-center">
                        {<GetLanguageString props="common_action" />}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {counsellingQuestions.map((record, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="ur">{record.content}</td>
                          <td className="ur text-center">
                            {record.hasUpdatedVersion?.label
                              ? record.hasUpdatedVersion.label
                              : "-"}
                          </td>
                          <td className="text-center">
                            {record.isArchived ? (
                              <GetLanguageString props="Yes" />
                            ) : (
                              <GetLanguageString props="No" />
                            )}
                          </td>
                          <td className="text-center">
                            {record.isEnabled ? (
                              <GetLanguageString props="Yes" />
                            ) : (
                              <GetLanguageString props="No" />
                            )}
                          </td>{" "}
                          <td className="text-center">
                            <ButtonGroup>
                              <Button
                                color="white"
                                size="sm"
                                onClick={handleOnEdit}
                                value={record.id}
                              >
                                {<GetLanguageString props="common_edit" />}
                              </Button>
                              <Button
                                color="white"
                                size="sm"
                                onClick={() =>
                                  handleOnDelete(record.id, record.isAffiliated)
                                }
                              >
                                {<GetLanguageString props="common_delete" />}
                              </Button>
                            </ButtonGroup>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            ) : (
              <NoResult />
            )}
          </div>
        </div>
      ) : (
        LoadingSpinner()
      )}
    </>
  );
};

export default CounsellingQuestions;
