import React, { useEffect, useState } from "react";
import { Table, Button } from "reactstrap";

import { GetLanguageString, NoResult } from "../helper/Components";
import { isUrdu, LoadingSpinner } from "../../constants/const";
import { addMember } from "../member/ApiMember";
import { GetFooTableCustomStyles } from "../helper/Method";
import { withRouter } from "react-router-dom";

const initialValues = {
  name: "neutral",
  joiningDate: "neutral",
  regNo: "neutral",
  memberType: "neutral",
};

const UnitAhbab = ({ unitId, tab4, history, match }) => {
  // const unitTypeName = encryptStorage.getItem("unitTypeName");
  const [unitAhbabMemberLoading, setUnitAhbabMemberLoading] = useState(false);
  const [unitAhbabMembers, setUnitAhbabMembers] = useState([]);
  const [sortObject, setSortObject] = useState(initialValues);

  //   sorting applied on name
  const handleSorting = (name) => {
    if (sortObject[name] === "neutral" || sortObject[name] === "descending") {
      setSortObject({
        ...initialValues,
        [name]: "ascending",
      });
    } else if (sortObject[name] === "ascending") {
      setSortObject({
        ...initialValues,
        [name]: "descending",
      });
    }
  };

  useEffect(() => {
    if (tab4 === "active") {
      let tempSortObject = {
        isFullNameDescending: sortObject.name === "descending",
        isFullNameAscending: sortObject.name === "ascending",
        isJoiningDateDescending: sortObject.joiningDate === "descending",
        isJoiningDateAscending: sortObject.joiningDate === "ascending",
        isRegNoAscending: sortObject.regNo === "ascending",
        isRegNoDescending: sortObject.regNo === "descending",
        isMemberTypeAscending: sortObject.memberType === "ascending",
        isMemberTypeDescending: sortObject.memberType === "descending",
      };
      setUnitAhbabMemberLoading(true);
      addMember()
        .getUnitAhbaabMember(unitId, tempSortObject)
        .then((res) => {
          setUnitAhbabMembers(res.data);
          setUnitAhbabMemberLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setUnitAhbabMemberLoading(false);
        });
    }
  }, [unitId, tab4, sortObject]);

  return (
    <>
      <div role="tabpanel" id="tab-4" className={"tab-pane " + tab4}>
        {/* <div className="ibox-title">
        <div
          className={
            GetSelectedLanguage() === "en"
              ? "pull-right m-b-md"
              : "pull-left m-b-md"
          }
        >
        </div>
      </div> */}
        <div className="panel-body">
          {!unitAhbabMemberLoading ? (
            <div className="table-responsive">
              {unitAhbabMembers.length !== 0 ? (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th
                        className="cursor-pointer"
                        onClick={() => handleSorting("name")}
                      >
                        {<GetLanguageString props="common_name" />}
                        <span className={GetFooTableCustomStyles().span_style}>
                          {sortObject.name === "neutral" ? (
                            <>
                              <i
                                className={
                                  GetFooTableCustomStyles().up_down_style
                                }
                              />
                              <i
                                className={
                                  GetFooTableCustomStyles().down_up_style
                                }
                              />
                            </>
                          ) : sortObject.name === "ascending" ? (
                            <i className={GetFooTableCustomStyles().up_style} />
                          ) : (
                            <i
                              className={GetFooTableCustomStyles().down_style}
                            />
                          )}
                        </span>
                      </th>
                      <th
                        className="cursor-pointer"
                        onClick={() => handleSorting("regNo")}
                      >
                        {<GetLanguageString props="member_reg_number" />}
                        <span className={GetFooTableCustomStyles().span_style}>
                          {sortObject.regNo === "neutral" ? (
                            <>
                              <i
                                className={
                                  GetFooTableCustomStyles().up_down_style
                                }
                              />
                              <i
                                className={
                                  GetFooTableCustomStyles().down_up_style
                                }
                              />
                            </>
                          ) : sortObject.regNo === "ascending" ? (
                            <i className={GetFooTableCustomStyles().up_style} />
                          ) : (
                            <i
                              className={GetFooTableCustomStyles().down_style}
                            />
                          )}
                        </span>
                      </th>
                      <th>{<GetLanguageString props="member_phone" />}</th>
                      <th>{<GetLanguageString props="member_address" />}</th>
                      <th
                        className="cursor-pointer"
                        onClick={() => handleSorting("memberType")}
                      >
                        {<GetLanguageString props="settings_member_type" />}
                        <span className={GetFooTableCustomStyles().span_style}>
                          {sortObject.memberType === "neutral" ? (
                            <>
                              <i
                                className={
                                  GetFooTableCustomStyles().up_down_style
                                }
                              />
                              <i
                                className={
                                  GetFooTableCustomStyles().down_up_style
                                }
                              />
                            </>
                          ) : sortObject.memberType === "ascending" ? (
                            <i className={GetFooTableCustomStyles().up_style} />
                          ) : (
                            <i
                              className={GetFooTableCustomStyles().down_style}
                            />
                          )}
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {unitAhbabMembers.map((record, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className={isUrdu(record.name) ? "ur" : ""}>
                            {record.name}
                          </td>
                          <td>{record.regNo}</td>
                          <td>
                            {record.phone != null
                              ? record.phone
                                  .split(",")
                                  .map((phoneNumber, index) => (
                                    <div key={index}>{phoneNumber}</div>
                                  ))
                              : ""}
                          </td>
                          <td
                            className={
                              isUrdu(record.currentAddress) ? "ur" : ""
                            }
                          >
                            {record.currentAddress}
                          </td>
                          <td>
                            {
                              <Button
                                size="xs"
                                color="info"
                                className="label label-info m-r-xs"
                              >
                                <h5 className="ur">{record.memberTypeName}</h5>
                              </Button>
                            }
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <NoResult />
              )}
            </div>
          ) : (
            LoadingSpinner()
          )}
        </div>
      </div>
    </>
  );
};

export default withRouter(UnitAhbab);
// add with router
