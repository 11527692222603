import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import { LoadingSpinner } from "../../../constants/const";
import { GetLanguageString } from "../../helper/Components";
import { ApiEducation } from "./ApiEducation";
import Draggable from "react-draggable";
import swal from "sweetalert";
import { GetSelectedLanguage } from "../../helper/Method";

const AddInstitute = ({ setInstituteAddModal }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [institute, setInstitute] = useState({
    name: ""
  });
  const [errors, setErrors] = useState(institute);
  const toggle = () => setInstituteAddModal(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  const validate = () => {
    let temp = {};
    temp.name = institute.name
      ? ""
      : t("education_institute_err");

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setErrors({
      ...errors,
      name: "",
    });
    setInstitute({
      ...institute,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      setInstituteAddModal(false);
      ApiEducation()
        .addInstitute(institute)
        .then((res) => {
          toggle();
          setLoading(false);
          swal({
            title: t("education_add_institute_success"),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "success",
            buttons: "OK",
          });
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          swal({
            title: "Error: " + err.response?.data || err,
            icon: "error",
            buttons: "OK",
          });
        });
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="education_add_institute" />}
          </ModalHeader>
          {!loading ? (
            <Form onSubmit={handleSubmit}>
              <ModalBody>
              <FormGroup>
                  <Label>
                    {
                      <GetLanguageString props="education_add_institute_name" />
                    }
                  </Label>
                  <Input
                    autoFocus
                    type="text"
                    name="name"
                    className="no-drag"
                    value={institute.name}
                    onChange={handleInputChange}
                    {...(errors.name && { invalid: true })}
                  />
                  {errors.name && (
                    <FormFeedback>
                      <h4>{errors.name}</h4>
                    </FormFeedback>
                  )}
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="white" onClick={toggle}>
                  {<GetLanguageString props="common_cancel" />}
                </Button>
                <Button color="primary" type="submit" >
                  {<GetLanguageString props="common_save_changes" />}
                </Button>
              </ModalFooter>
            </Form>
          ) : (
            LoadingSpinner()
          )}
        </Modal>
      </Draggable>
    </div>
  );
};

export default AddInstitute;
