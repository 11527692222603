import React, { useEffect, useState } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import swal from "sweetalert";

import { saveAs } from "file-saver";
import moment from "moment";
import Select from "react-select";
import {
  convertUTCToLocalTime,
  LoadingSpinner,
} from "../../../constants/const";
import { encryptStorage } from "../../../constants/EncryptStorage";
import {
  GetLanguageString,
  NoResult,
  SetReportTitle,
} from "../../helper/Components";
import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import { addMember } from "../../member/ApiMember";
import { ApiUnit } from "../../unit/ApiUnit";
import { FcPrint } from "react-icons/fc";
import { RiFileExcel2Line } from "react-icons/ri";

const ReappointmentReport = () => {
  const unitTypeName = "null";
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [loading, setLoading] = useState(false);
  const [searchButton, setSearchButton] = useState(false);
  const [reportTitle, setReportTitle] = useState("");
  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState({
    unitId: loggedInUnitId,
    date: new Date(),
    isSubUnits: true,
  });
  const [unitList, setUnitList] = useState([]);

  useEffect(() => {
    ApiUnit()
      .getUnitList(loggedInUnitId, loggedInUnitId, false, unitTypeName, true)
      .then((res) => {
        setUnitList(res.data);
      })
      .catch((err) => console.log(err));
  }, [loggedInUnitId]);

  const handleChangeUnits = (record) => {
    if (record !== null) {
      ApiUnit()
        .getUnitList(record.value, loggedInUnitId, false, unitTypeName, true)
        .then((res) => {
          setUnitList(res.data);
        })
        .catch((err) => console.log(err));
      setSearchValue({
        ...searchValue,
        unitId: record.value,
      });
    } else {
      ApiUnit()
        .getUnitList(loggedInUnitId, loggedInUnitId, false, unitTypeName, true)
        .then((res) => {
          setUnitList(res.data);
        })
        .catch((err) => console.log(err));
      setSearchValue({
        ...searchValue,
        unitId: 0,
      });
    }
  };
  useEffect(() => {
    if (reportTitle !== "") window.print();
  }, [reportTitle]);

  const handleReportTitle = () => {
    setModal(true);
  };

  const handleSubUnits = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setSearchValue({
      ...searchValue,
      isSubUnits: v,
    });
  };

  const excelDownload = () => {
    addMember()
      .GetExcelReappointmentMuqamiAmeerReport(searchValue)
      .then((res) => {
        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "ReappointmentMuqamiAmeerReport.xlsx");
      })
      .catch((err) => console.log(err));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSearchButton(true);
    setLoading(true);
    addMember()
      .GetReappointmentMuqamiAmeerReport(searchValue)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
      });
  };

  return (
    <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
      {modal ? (
        <SetReportTitle setModal={setModal} setReportTitle={setReportTitle} />
      ) : null}
      <div className="ibox noprint">
        <div className="ibox-title">
          <h5>{<GetLanguageString props="common_generate_report" />}</h5>
        </div>
        <div className="ibox-content">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md="4">
                <FormGroup>
                  <Label className="col-form-label">
                    {<GetLanguageString props="report_member_select_date" />}
                  </Label>
                  <DatePicker
                    showYearDropdown
                    className="form-control"
                    selected={searchValue.date}
                    onChange={(value) => {
                      setSearchValue({
                        ...searchValue,
                        date: value,
                      });
                    }}
                    dateFormat="dd/MM/yyyy"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {unitList.map((record, index) => {
                const unitOptions = [];
                record.unitList.forEach((d) =>
                  d.unitTypeName !== "Munfarid" &&
                  d.unitTypeName !== "UsraMuavin" &&
                  d.unitTypeName !== "EX"
                    ? unitOptions.push({
                        value: d.id,
                        label: d.name,
                        label1: d.unitTypeName,
                      })
                    : null
                );
                let selected = {};
                let unitName = "";
                if (record.parentUnitName !== null)
                  unitName = record.parentUnitName;
                if (record.selectedUnit !== null) {
                  selected = {
                    value: record.selectedUnit.id,
                    label: record.selectedUnit.name,
                  };
                }

                return (
                  <Col md="2" key={index}>
                    <FormGroup>
                      <Label className="col-form-label">
                        {unitName !== "" ? (
                          <GetLanguageString
                            props="member_select_unit_name"
                            value={unitName}
                          />
                        ) : (
                          <GetLanguageString props="member_select_unit" />
                        )}
                      </Label>
                      <Select
                        options={unitOptions}
                        value={selected}
                        className="basic-single ur"
                        classNamePrefix="select"
                        onChange={handleChangeUnits}
                      />
                    </FormGroup>
                  </Col>
                );
              })}
            </Row>
            <Row>
              {" "}
              <Col>
                {" "}
                <FormGroup check>
                  <Label check for="subUnit">
                    <Input
                      id="subUnit"
                      type="checkbox"
                      name="isSubUnits"
                      checked={searchValue.isSubUnits}
                      value={!searchValue.isSubUnits}
                      onChange={handleSubUnits}
                    />
                    {
                      <GetLanguageString props="member_search_member_sub_unit" />
                    }
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row className="m-t-md">
              <Col className="text-center">
                <FormGroup>
                  <Button color="primary" className="m-l-sm" type="submit">
                    {<GetLanguageString props="common_view_report" />}
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      {searchButton && (
        <div className="ibox">
          <div className="ibox-title">
            {data.length !== 0 ? (
              <ButtonGroup className={GetCustomStyles().btn_style}>
                <Button color="default" size="sm" onClick={excelDownload}>
                  <RiFileExcel2Line
                    size={15}
                    color="#009440"
                    style={{ marginRight: 3, marginBottom: 1 }}
                  />
                  {<GetLanguageString props="member_report_export_excel" />}
                </Button>
                <Button color="default" size="sm" onClick={handleReportTitle}>
                  <FcPrint size={15} className={GetCustomStyles().fa_style} />
                  {<GetLanguageString props="common_print" />}
                </Button>
              </ButtonGroup>
            ) : null}
          </div>{" "}
          <div className="ibox-content ibox-center" id="section-to-print">
            <Row className="print">
              <div className="text-center">
                <h3>Title : {reportTitle}</h3>
              </div>
            </Row>
            {!loading ? (
              data.length !== 0 ? (
                <Table responsive bordered>
                  {" "}
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{<GetLanguageString props="member_reg_number" />}</th>
                      <th>{<GetLanguageString props="member_first_name" />}</th>
                      <th>
                        {
                          <GetLanguageString props="dashboard_event_page_unit_name" />
                        }
                      </th>
                      <th>
                        {
                          <GetLanguageString props="member_member_detail_common_responsibility_date" />
                        }
                      </th>
                      <th>
                        {
                          <GetLanguageString props="member_member_detail_last_renewal_date" />
                        }
                      </th>
                      <th>
                        {
                          <GetLanguageString props="member_member_detail_next_renewal_date" />
                        }
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => {
                      let unitName = item.unitName?.split("/");
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.regNo}</td>
                          <td className="ur">{item.memberName}</td>
                          <td className="ur">
                            <ol className="breadcrumb">
                              {unitName.map((item, index) => {
                                return (
                                  <li
                                    className={
                                      GetSelectedLanguage() === "ur"
                                        ? "breadcrumb-item-ur"
                                        : "breadcrumb-item"
                                    }
                                    key={index}
                                  >
                                    {item}
                                  </li>
                                );
                              })}
                            </ol>
                          </td>
                          <td>
                            {moment(
                              convertUTCToLocalTime(item.responsibilityDate)
                            ).format("DD/MM/yyyy")}
                          </td>
                          <td>
                            {item.reappointmentDate
                              ? moment(
                                  convertUTCToLocalTime(item.reappointmentDate)
                                ).format("DD/MM/yyyy")
                              : "-"}
                          </td>
                          <td>
                            {moment(
                              convertUTCToLocalTime(item.nextReappointmentDate)
                            ).format("DD/MM/yyyy")}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>{" "}
                </Table>
              ) : (
                <NoResult />
              )
            ) : (
              LoadingSpinner()
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ReappointmentReport;
