import React from "react";
import { Table } from "reactstrap";
import { GetLanguageString } from "../../helper/Components";
import { isUrdu } from "../../../constants/const";

const MemberAddressDetails = ({ tab13, memberAddressDetails }) => {
  return (
    <div role="tabpanel" id="tab-13" className={"tab-pane " + tab13}>
      <div className="panel-body">
        <div className="table-responsive">
          <Table responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>{<GetLanguageString props="member_address_type" />}</th>
                <th>{<GetLanguageString props="member_address" />}</th>
                <th>{<GetLanguageString props="member_country" />}</th>
                <th>{<GetLanguageString props="ahbeb_state_province" />}</th>
                <th>{<GetLanguageString props="member_city" />}</th>
              </tr>
            </thead>
            <tbody>
              {memberAddressDetails
                ?.sort(function (a, b) {
                  if (a.staticType > b.staticType) return 1;
                  if (a.staticType < b.staticType) return -1;
                  return 0;
                })
                .map((record, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className={isUrdu(record.addressType) ? "ur" : "en"}>
                        {record.addressType}{" "}
                      </td>
                      <td className={isUrdu(record.address) ? "ur" : "en"}>
                        {record.address}
                      </td>
                      <td className={isUrdu(record.country) ? "ur" : "en"}>
                        {record.country !== "" ? record.country : "-" }
                      </td>
                      <td className={isUrdu(record.state) ? "ur" : "en"}>
                        {record.state !== "" ? record.state : "-" }
                      </td>
                      <td className={isUrdu(record.city) ? "ur" : "en"}>
                        {record.city !== "" ? record.city : "-" }
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default MemberAddressDetails;
