import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Table,
  Label,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Accordion,
  Collapse,
} from "reactstrap";

import {
  GetLanguageString,
  NoResult,
  SetReportTitle,
} from "../../helper/Components";
import { GetCustomStyles, WidgetClassName } from "../../helper/Method";
import { encryptStorage } from "../../../constants/EncryptStorage";
import { ApiDashboard } from "../../dashboard/ApiDashboard";
import SearchCummulativeReport from "./SearchCummulativeReport";
import { ApiUnit } from "../ApiUnit";
import { LoadingSpinner } from "../../../constants/const";

const CummulativeReport = () => {
  const [loading, setLoading] = useState(false);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [values, setValues] = useState({
    unit: { value: loggedInUnitId, lable: "" },
  });
  const [reportTitle, setReportTitle] = useState("");
  const [modal, setModal] = useState(false);
  const [search, setSearch] = useState(false);
  const [unitDetails, setUnitsDetails] = useState();
  const [subUnitsDetails, setSubUnitsDetails] = useState();
  const [membersCountByCourse, setMembersCountByCourse] = useState();
  const [memberResponsibilities, setMemberResponsibilities] = useState();
  const [subUnitMemberResponsibilities, setSubUnitMemberResponsibilities] =
    useState();
  const [openUnit, setOpenUnit] = useState(false);
  const [openSubUnit, setOpenSubUnit] = useState(false);
  const [open, setOpen] = useState("");

  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const toggleUnit = () => {
    setOpenUnit(!openUnit);
  };

  const toggleSubUnit = () => {
    setOpenSubUnit(!openSubUnit);
  };

  useEffect(() => {
    if (reportTitle !== "") window.print();
  }, [reportTitle]);

  useEffect(() => {
    setLoading(true);
    ApiDashboard()
      .getDashboardMemberDetails(values.unit.value)
      .then((res) => {
        setUnitsDetails(res.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));

    ApiUnit()
      .getUnitCummulativeStats(values.unit.value)
      .then((res) => {
        setMemberResponsibilities(res.data.memberResponsibilities);
        setMembersCountByCourse({
          mubtadi: res.data.mubtadiCourseMembersCount,
          multazim: res.data.multazimCourseMembersCount,
        });
      })
      .catch((err) => console.log(err));

    ApiUnit()
      .getImmediateChildUnitsDetails(values.unit.value)
      .then((res) => {
        setSubUnitsDetails(res.data);
        setSubUnitMemberResponsibilities(res.data.unitDetails);
      })
      .catch((err) => console.log(err));
  }, [values]);
  const handleReportTitle = () => {
    setModal(true);
  };

  return (
    <>
      {modal ? (
        <SetReportTitle setModal={setModal} setReportTitle={setReportTitle} />
      ) : null}
      <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
        <div className="ibox noprint">
          <div className="ibox-title">
            <h5>Search Units</h5>
            <Button
              color="primary"
              size="sm"
              className={GetCustomStyles().btn_style}
              onClick={handleReportTitle}
            >
              {<GetLanguageString props="common_print" />}
            </Button>
          </div>
          <div className="ibox-content">
            <SearchCummulativeReport
              searchUnit={values}
              setSearchUnit={setValues}
              showResult={setSearch}
            />
          </div>
        </div>
        {search && (
          <>
            {unitDetails?.length !== 0 ? (
              <div className="panel panel-primary">
                <div className="panel-heading p-0" onClick={toggleUnit}>
                  <Row>
                    <Col md="4" className="m-auto">
                      <h3>
                        {
                          <GetLanguageString
                            props="unit_cummulative_report_unit_details"
                            value={unitDetails?.unitName}
                          />
                        }
                      </h3>
                    </Col>
                    <Col md="7" className="m-auto">
                      <div className={GetCustomStyles().btn_style}>
                        <h2 className="float-right m-auto">
                          {unitDetails?.totalMembersInUnit}
                          <h6>
                            {
                              <GetLanguageString props="unit_cummulative_report_total_members" />
                            }
                          </h6>
                        </h2>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Collapse isOpen={openUnit}>
                  <div className="panel-body">
                    {!loading ? (
                      unitDetails?.length !== 0 ? (
                        <>
                          <Row className="text-center">
                            {unitDetails.unitMemberTypeDetails?.map(
                              (record, index) => {
                                return (
                                  <Col key={index}>
                                    <div
                                      className={WidgetClassName(
                                        record.memberType
                                      )}
                                    >
                                      <Row>
                                        <Col md="8">
                                          <h3 className="ur">
                                            {record.memberTypeName}
                                          </h3>
                                        </Col>
                                        <Col md="4">
                                          <h3 className="font-bold">
                                            {record.membersCount}
                                          </h3>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                );
                              }
                            )}
                          </Row>
                        </>
                      ) : (
                        <NoResult />
                      )
                    ) : (
                      LoadingSpinner()
                    )}
                    <div>
                      <br></br>
                      <Row>
                        <Col>
                          <div className="widget style1 panel panel-primary white-bg">
                            <Row>
                              <Col md="11">
                                <h3>
                                  {
                                    <GetLanguageString props="unit_cummulative_report_total_participant_in_mubtadi_course" />
                                  }
                                </h3>
                              </Col>
                              <Col md="1">
                                <h3 className="font-bold">
                                  {membersCountByCourse?.mubtadi}
                                </h3>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col>
                          <div className="widget style1 panel panel-primary white-bg">
                            <Row>
                              <Col md="11">
                                <h3>
                                  {
                                    <GetLanguageString props="unit_cummulative_report_total_participant_in_multazim_course" />
                                  }
                                </h3>
                              </Col>
                              <Col md="1">
                                <h3 className="font-bold">
                                  {membersCountByCourse?.multazim}
                                </h3>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                      <br></br>
                    </div>
                    <div className="panel panel-info">
                      <div className="panel-heading">
                        <h4>
                          {
                            <GetLanguageString props="unit_cummulative_report_unit_official" />
                          }
                        </h4>
                      </div>
                      <div className="panel-body">
                        {!loading ? (
                          memberResponsibilities?.length !== 0 ? (
                            <div className="table-responsive">
                              <Table responsive>
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>
                                      {
                                        <GetLanguageString props="common_name" />
                                      }
                                    </th>
                                    <th>
                                      {
                                        <GetLanguageString props="settings_responsibilities" />
                                      }
                                    </th>
                                    <th>
                                      {
                                        <GetLanguageString props="ahbab_contact1" />
                                      }
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {memberResponsibilities?.map(
                                    (item, index) => {
                                      return (
                                        <tr key={index}>
                                          <td>{index + 1}</td>
                                          <td className="ur">
                                            {item.memberName}
                                          </td>
                                          <td>
                                            {item.responsibilities.map(
                                              (record, index) => {
                                                return (
                                                  <Label
                                                    key={index}
                                                    className="label label-primary sm"
                                                  >
                                                    <h5 className="ur">
                                                      {record.label}
                                                    </h5>
                                                  </Label>
                                                );
                                              }
                                            )}
                                          </td>
                                          <td>{item.contactNo}</td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          ) : (
                            <NoResult />
                          )
                        ) : (
                          LoadingSpinner()
                        )}
                      </div>
                    </div>
                  </div>
                </Collapse>
              </div>
            ) : null}
            {subUnitsDetails?.length !== 0 ? (
              subUnitsDetails?.totalSubUnits ? (
                <div className="panel panel-success">
                  <div className="panel-heading p-0" onClick={toggleSubUnit}>
                    <Row>
                      <Col md="4" className="m-auto">
                        <h3>
                          {
                            <GetLanguageString
                              props="unit_cummulative_report_sub_unit_details"
                              value={unitDetails.unitName}
                            />
                          }
                        </h3>
                      </Col>
                      <Col md="7" className="m-auto">
                        <div className={GetCustomStyles().btn_style}>
                          <h2 className="float-right m-auto">
                            {subUnitsDetails.totalSubUnits}
                            <h6>
                              {
                                <GetLanguageString props="unit_cummulative_report_total_subUnits" />
                              }
                            </h6>
                          </h2>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <Collapse isOpen={openSubUnit}>
                    {subUnitsDetails?.unitDetails.map((item, index) => {
                      return (
                        <Accordion
                          className="m-auto"
                          open={open}
                          toggle={toggle}
                          key={index}
                        >
                          <AccordionItem>
                            <AccordionHeader
                              className="m-auto ur"
                              targetId={index + 1}
                            >
                              {item.unitName}
                              <Col>
                                <div
                                  className={GetCustomStyles().btn_style}
                                  style={{ paddingRight: 20 }}
                                >
                                  <h3 className="text-center align-items-center m-auto">
                                    {item.totalMembersInUnit}
                                    <h6>
                                      {
                                        <GetLanguageString props="unit_cummulative_report_total_members" />
                                      }
                                    </h6>
                                  </h3>
                                </div>
                              </Col>
                            </AccordionHeader>
                            <AccordionBody accordionId={index + 1}>
                              <div className="ibox-content text-center">
                                <Row className="text-center">
                                  {item.unitMemberTypeDetails?.map(
                                    (record, index) => {
                                      return (
                                        <Col key={index}>
                                          <div
                                            className={WidgetClassName(
                                              record.memberType
                                            )}
                                          >
                                            <Row>
                                              <Col md="8">
                                                <h3 className="ur">
                                                  {record.memberTypeName}
                                                </h3>
                                              </Col>
                                              <Col md="4">
                                                <h3 className="font-bold">
                                                  {record.membersCount}
                                                </h3>
                                              </Col>
                                            </Row>
                                          </div>
                                        </Col>
                                      );
                                    }
                                  )}
                                </Row>
                              </div>
                              <div>
                                <br></br>
                                <br></br>
                              </div>
                              <div className="panel panel-primary">
                                <div className="panel-heading">
                                  <h4>
                                    {
                                      <GetLanguageString props="unit_cummulative_report_unit_official" />
                                    }
                                  </h4>
                                </div>
                                <div className="panel-body">
                                  {!loading ? (
                                    subUnitMemberResponsibilities?.length !==
                                    null ? (
                                      <div className="table-responsive">
                                        <Table responsive>
                                          <thead>
                                            <tr>
                                              <th>#</th>
                                              <th>
                                                {
                                                  <GetLanguageString props="common_name" />
                                                }
                                              </th>
                                              <th>
                                                {
                                                  <GetLanguageString props="settings_responsibilities" />
                                                }
                                              </th>
                                              <th>
                                                {
                                                  <GetLanguageString props="ahbab_contact1" />
                                                }
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {item.memberResponsibilities?.map(
                                              (item, index) => {
                                                return (
                                                  <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td className="ur">
                                                      {item.memberName}
                                                    </td>
                                                    <td>
                                                      {item.responsibilities.map(
                                                        (record, index) => {
                                                          return (
                                                            <Label
                                                              key={index}
                                                              className="label label-primary sm"
                                                            >
                                                              <h5 className="ur">
                                                                {record}
                                                              </h5>
                                                            </Label>
                                                          );
                                                        }
                                                      )}
                                                    </td>
                                                    <td>{item.contactNo}</td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                          </tbody>
                                        </Table>
                                      </div>
                                    ) : (
                                      <NoResult />
                                    )
                                  ) : (
                                    LoadingSpinner()
                                  )}
                                </div>
                              </div>
                            </AccordionBody>
                          </AccordionItem>
                        </Accordion>
                      );
                    })}
                  </Collapse>
                </div>
              ) : null
            ) : null}
          </>
        )}
      </div>
    </>
  );
};

export default CummulativeReport;
