import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { GetLanguageString, NoResult } from "../../helper/Components";
import { encryptStorage } from "../../../constants/EncryptStorage";
import SearchMudariseen from "./SearchMudariseen";
import {
  CheckPermission,
  LoadingSpinner,
  convertUTCToLocalTime,
} from "../../../constants/const";
import { ApiMudaris } from "./ApiMudaris";
import Pagination from "react-js-pagination";
import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import { Button, ButtonGroup, Table } from "reactstrap";
import MemberMudarisCreateProfile from "./MemberMudarisCreateProfile";
import { saveAs } from "file-saver";
import moment from "moment";
import { RiFileExcel2Line } from "react-icons/ri";
import { IoAdd } from "react-icons/io5";
import { FcCheckmark } from "react-icons/fc";
import { RxCross2 } from "react-icons/rx";

const MemberMudriseen = ({ history, renderPage = "" }) => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [loading, setLoading] = useState(false);
  const [searchButton, setSearchButton] = useState(false);
  const [createProfileModal, setCreateProfileModal] = useState(false);
  const [updateCall, setUpdateCall] = useState(false);
  const [mudariseen, setMudariseen] = useState();
  const [searchMudariseenModel, setSearchMudariseenModel] = useState({
    unitId: loggedInUnitId,
    regNo: "",
    arabicTestStatusIds: null,
    tajweedTestStatusIds: null,
    activePage: 1,
    isSubUnits: true,
    registrationStatusesIds: null,
    booksIds: null,
    booksStatus: null,
    includeExUnit: false,
  });
  const [pagination, setPagination] = useState({ activePage: 1 });
  const [unit, setUnit] = useState({
    unitId: loggedInUnitId,
    unitName: "",
  });
  let recordIndex = pagination.pageSize * (pagination.activePage - 1) + 1;

  useEffect(() => {
    setLoading(true);
    if (searchButton) {
      ApiMudaris()
        .GetMudariseen(searchMudariseenModel)
        .then((res) => {
          setMudariseen(res.data.list);
          setPagination(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [searchMudariseenModel, searchButton, updateCall]);

  const excelDownload = () => {
    if (searchButton) {
      ApiMudaris()
        .GetMudariseenExcelReport(searchMudariseenModel)
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(blob, "Mudariseen.xlsx");
        })
        .catch((err) => console.log(err));
    }
  };

  const handlePageChange = (pageNumber) => {
    setSearchMudariseenModel((prevState) => ({
      ...prevState,
      activePage: pageNumber,
    }));

    setPagination((prevPagination) => ({
      ...prevPagination,
      activePage: pageNumber,
    }));
  };

  return (
    <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
      {createProfileModal ? (
        <MemberMudarisCreateProfile
          setCreateProfileModal={setCreateProfileModal}
          setRefresh={setUpdateCall}
          refresh={updateCall}
        />
      ) : null}
      <div className="ibox ">
        <div className="ibox-title">
          <h5>{<GetLanguageString props="mudariseen_search_mudaris" />}</h5>
        </div>
        <div className="ibox-content">
          <SearchMudariseen
            unitId={unit.unitId}
            setUnit={setUnit}
            searchMudariseenModel={searchMudariseenModel}
            setSearchMudariseenModel={setSearchMudariseenModel}
            setSearchButton={setSearchButton}
            updateCall={updateCall}
            setUpdateCall={setUpdateCall}
          />
        </div>
      </div>{" "}
      {searchButton && (
        <div className="ibox ">
          <div className="ibox-title">
            <h5>{<GetLanguageString props="mudariseen_detials" />}</h5>
            <ButtonGroup className={GetCustomStyles().btn_group_style}>
              {renderPage !== "reports" && CheckPermission("CanAddMudaris") ? (
                <Button
                  color="default"
                  size="sm"
                  onClick={() => setCreateProfileModal(true)}
                >
                  <IoAdd
                    size={18}
                    color="#18A689"
                    style={{ marginRight: 3, marginBottom: 1 }}
                  />
                  <GetLanguageString props="member_member_detail_mudaris_details_create_profile" />
                </Button>
              ) : null}
              {mudariseen?.length !== 0 ? (
                <Button color="default" size="sm" onClick={excelDownload}>
                  <RiFileExcel2Line
                    size={15}
                    color="#009440"
                    style={{ marginRight: 3, marginBottom: 1 }}
                  />
                  {<GetLanguageString props="member_report_export_excel" />}
                </Button>
              ) : null}
            </ButtonGroup>
          </div>
          <div className="ibox-content">
            {!loading ? (
              mudariseen?.length !== 0 ? (
                <div className="table-responsive">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>
                          {<GetLanguageString props="member_reg_number" />}
                        </th>
                        <th>
                          <GetLanguageString props="common_name" />
                        </th>
                        <th>
                          <GetLanguageString props="unit" />
                        </th>
                        <th className="text-center">
                          <GetLanguageString props="member_member_detail_mudaris_Mudariseen_Refresher_Course" />
                        </th>
                        <th className="text-center">
                          <GetLanguageString props="member_member_detail_mudaris_course" />
                        </th>
                        <th className="text-center">
                          <GetLanguageString props="member_member_detail_mudaris_multazim_course" />
                        </th>
                        <th className="text-center">
                          <GetLanguageString props="book_update_book4" />
                        </th>
                        <th className="text-center">
                          <GetLanguageString props="book_update_book3" />
                        </th>
                        <th className="text-center">
                          <GetLanguageString props="settings_member_type" />
                        </th>
                        <th className="text-center">
                          <GetLanguageString props="mudariseen_mudaris_arabic_test_status" />
                        </th>
                        <th className="text-center">
                          {
                            <GetLanguageString props="mudariseen_mudaris_tajweed_test_status" />
                          }
                        </th>
                        <th className="text-center">
                          {
                            <GetLanguageString props="member_member_detail_mudaris_registered" />
                          }
                        </th>
                        <th>
                          {
                            <GetLanguageString props="member_member_detail_mudaris_accepted" />
                          }
                        </th>
                        <th>
                          {
                            <GetLanguageString props="member_member_detail_mudaris_markazi" />
                          }
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {mudariseen?.map((record, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + recordIndex}</td>
                            <td>{record.regNo}</td>
                            <td className="ur">{record.mudarisName}</td>
                            <td className="ur">
                              <ol className="breadcrumb">
                                {record.unitName
                                  .split("/")
                                  .map((item, index) => {
                                    return (
                                      <li
                                        className={
                                          GetSelectedLanguage() === "ur"
                                            ? "breadcrumb-item-ur"
                                            : "breadcrumb-item"
                                        }
                                        key={index}
                                      >
                                        <Button
                                          size="xs"
                                          color="info"
                                          className="ur"
                                        >
                                          {item}
                                        </Button>
                                      </li>
                                    );
                                  })}
                              </ol>
                            </td>
                            <td className="text-center">
                              {record.mudariseenRefresherCourse === null
                                ? "-"
                                : moment(
                                    convertUTCToLocalTime(
                                      record.mudariseenRefresherCourse.startDate
                                    )
                                  ).format("DD/MM/yyyy")}
                            </td>
                            <td className="text-center">
                              {record.mudariseenCourse === null
                                ? "-"
                                : moment(
                                    convertUTCToLocalTime(
                                      record.mudariseenCourse.startDate
                                    )
                                  ).format("DD/MM/yyyy")}
                            </td>
                            <td className="text-center">
                              {record.multazimCourse === null
                                ? "-"
                                : moment(
                                    convertUTCToLocalTime(
                                      record.multazimCourse.startDate
                                    )
                                  ).format("DD/MM/yyyy")}
                            </td>
                            <td className="ur text-center">
                              {record.memberNisaabs &&
                              record.memberNisaabs.length > 0
                                ? record.memberNisaabs.some(
                                    (book) =>
                                      book.bookName === "مدرسین نصاب مکمل"
                                  )
                                  ? "مکمل"
                                  : "-"
                                : "-"}
                            </td>
                            <td className="ur text-center">
                              {record.memberNisaabs &&
                              record.memberNisaabs.length > 0
                                ? record.memberNisaabs.some(
                                    (book) =>
                                      book.bookName === "ملتزم نصاب مکمل"
                                  )
                                  ? "مکمل"
                                  : "-"
                                : "-"}
                            </td>
                            <td className="ur text-center">
                              {record.memberTypeName}
                            </td>
                            <td className="ur text-center">
                              {record.arabicTestStatus
                                ? record.arabicTestStatus
                                : "-"}
                            </td>
                            <td className="ur text-center">
                              {record.tajweedTestStatus
                                ? record.tajweedTestStatus
                                : "-"}
                            </td>
                            <td className="text-center">
                              {record.isRegistered ? (
                                <FcCheckmark
                                  size={18}
                                  color="green"
                                  style={{ marginRight: 3, marginBottom: 1 }}
                                />
                              ) : (
                                <RxCross2
                                  size={18}
                                  color="red"
                                  style={{ marginRight: 3, marginBottom: 1 }}
                                />
                              )}
                            </td>
                            <td className="text-center">
                              {record.isAccepted ? (
                                <FcCheckmark
                                  size={18}
                                  color="green"
                                  style={{ marginRight: 3, marginBottom: 1 }}
                                />
                              ) : (
                                <RxCross2
                                  size={18}
                                  color="red"
                                  style={{ marginRight: 3, marginBottom: 1 }}
                                />
                              )}
                            </td>
                            <td className="text-center">
                              {record.isMarkazi ? (
                                <FcCheckmark
                                  size={18}
                                  color="green"
                                  style={{ marginRight: 3, marginBottom: 1 }}
                                />
                              ) : (
                                <RxCross2
                                  size={18}
                                  color="red"
                                  style={{ marginRight: 3, marginBottom: 1 }}
                                />
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan="120">
                          <ul className={GetCustomStyles().page_style}>
                            <Pagination
                              activePage={pagination.activePage}
                              itemsCountPerPage={pagination.pageSize}
                              totalItemsCount={pagination.totalItem}
                              pageRangeDisplayed={pagination.pageRange}
                              onChange={handlePageChange}
                            />
                          </ul>
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </div>
              ) : (
                <NoResult />
              )
            ) : (
              LoadingSpinner()
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(MemberMudriseen);
