import React, { useEffect, useState } from "react";
import moment from "moment";
import { Button, Table, Row } from "reactstrap";
import { saveAs } from "file-saver";

import { GetCustomStyles } from "../../../helper/Method";
import {
  GetLanguageString,
  GetSortingUI,
  NoResult,
  SetReportTitle,
} from "../../../helper/Components";
import {
  LoadingSpinner,
  convertUTCToLocalTime,
  isUrdu,
} from "../../../../constants/const";
import useSorting from "../../../shared/useSorting";
import SearchAhbabFromContactFormReport from "./SearchAhbabFromContactFormReport";
import { ApiAhbab } from "../../ApiAhbab";
import swal from "sweetalert";
import { encryptStorage } from "../../../../constants/EncryptStorage";
import { FcPrint } from "react-icons/fc";
import { RiFileExcel2Line } from "react-icons/ri";

const initialValues = {
  date: "neutral",
  habibName: "neutral",
};

const AhbabFromContactFormReport = () => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [loading, setLoading] = useState(false);
  const [ahbab, setAhbab] = useState([]);
  const [reportTitle, setReportTitle] = useState("");
  const [modal, setModal] = useState(false);
  const [searchButton, setSearchButton] = useState(false);
  const [searchAhbabModel, setSearchAhbabModel] = useState({
    startDate: null,
    endDate: null,
    address: "",
    habibName: "",
    mobileNumber: "",
    isSubUnits: false,
    country: null,
    state: null,
    city: null,
    unit: { value: loggedInUnitId, label: "" },
  });
  const { sortObject, handleSorting } = useSorting(initialValues);

  useEffect(() => {
    setLoading(true);
    let tempSortObject = {
      isDateDescending: sortObject.date === "descending",
      isDateAscending: sortObject.date === "ascending",
      isHabibNameAscending: sortObject.habibName === "ascending",
      isHabibNameDescending: sortObject.habibName === "descending",
    };
    searchAhbabModel.sortModel = tempSortObject;

    if (searchButton) {
      ApiAhbab()
        .GetContactFormAhbab(searchAhbabModel)
        .then((res) => {
          setAhbab(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
        });
    }
  }, [searchAhbabModel, sortObject, searchButton]);

  const excelDownload = () => {
    let tempSortObject = {
      isDateDescending: sortObject.date === "descending",
      isDateAscending: sortObject.date === "ascending",
      isHabibNameAscending: sortObject.habibName === "ascending",
      isHabibNameDescending: sortObject.habibName === "descending",
    };
    searchAhbabModel.sortModel = tempSortObject;

    ApiAhbab()
      .GetAhbabExcelReport(searchAhbabModel)
      .then((res) => {
        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "Ahbab.xlsx");
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (reportTitle !== "") window.print();
  }, [reportTitle]);

  const handleReportTitle = () => {
    setModal(true);
  };

  return (
    <>
      {modal ? (
        <SetReportTitle setModal={setModal} setReportTitle={setReportTitle} />
      ) : null}
      <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
        <div className="ibox noprint">
          <div className="ibox-title">
            <h5>{<GetLanguageString props="common_generate_report" />}</h5>
            <Button
              color="default"
              size="sm"
              onClick={handleReportTitle}
              className={GetCustomStyles().err_btn_style}
            >
              <FcPrint size={15} className={GetCustomStyles().fa_style} />
              {<GetLanguageString props="common_print" />}
            </Button>
          </div>
          <div className="ibox-content">
            <SearchAhbabFromContactFormReport
              searchAhbabModel={searchAhbabModel}
              setSearchAhbabModel={setSearchAhbabModel}
              setSearchButton={setSearchButton}
              setLoading={setLoading}
            />
          </div>
        </div>
        {searchButton && (
          <div className="ibox" id="section-to-print">
            <div className="ibox-title">
              <h5>{<GetLanguageString props="ahbab" />}</h5>
              {ahbab.length !== 0 ? (
                <Button
                  color="default"
                  size="sm"
                  onClick={excelDownload}
                  className={GetCustomStyles().btn_style}
                >
                  <RiFileExcel2Line
                    size={15}
                    color="#009440"
                    style={{ marginRight: 3, marginBottom: 1 }}
                  />
                  {<GetLanguageString props="member_report_export_excel" />}
                </Button>
              ) : null}
            </div>
            <div className="ibox-content">
              <Row className="print">
                <div className="text-center">
                  <h3>Title : {reportTitle}</h3>
                </div>
              </Row>
              {!loading ? (
                ahbab.length !== 0 ? (
                  <div className="table-responsive">
                    <Table>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th
                            className="cursor-pointer"
                            onClick={() => handleSorting("habibName")}
                          >
                            {<GetLanguageString props="common_name" />}
                            <GetSortingUI
                              sortObject={sortObject}
                              name="habibName"
                              isReport
                            />
                          </th>
                          <th>
                            {
                              <GetLanguageString props="ahbab_associated_member" />
                            }
                          </th>
                          <th>{<GetLanguageString props="ahbab_address" />}</th>
                          <th>{<GetLanguageString props="member_city" />}</th>
                          <th>
                            {
                              <GetLanguageString props="member_member_detail_course_location" />
                            }
                          </th>
                          <th
                            className="cursor-pointer"
                            onClick={() => handleSorting("date")}
                          >
                            <GetLanguageString props="dashboard_event_page_date" />
                            <GetSortingUI
                              sortObject={sortObject}
                              name="date"
                              isReport
                            />
                          </th>
                          <th>
                            {<GetLanguageString props="ahbab_contact1" />}
                          </th>
                          <th>{<GetLanguageString props="member_email" />}</th>
                          <th>
                            {<GetLanguageString props="member_education" />}
                          </th>
                          <th>
                            {<GetLanguageString props="member_occupation" />}
                          </th>
                          <th className="text-center">
                            {<GetLanguageString props="ahbab_request_status" />}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {ahbab.map((record, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td
                                className={
                                  isUrdu(record.habibName) ? "ur" : "en"
                                }
                              >
                                {record.habibName}
                              </td>
                              <td
                                className={
                                  isUrdu(record.associatedMember) ? "ur" : "en"
                                }
                              >
                                {record.associatedMember}
                              </td>
                              <td
                                className={isUrdu(record.address) ? "ur" : "en"}
                              >
                                {record.address}
                              </td>
                              <td>{record.city !== "" ? record.city : "-"}</td>
                              <td>{record.location}</td>
                              <td>
                                {moment(
                                  convertUTCToLocalTime(record.date)
                                ).format("DD/MM/yyyy")}
                              </td>
                              <td>{record.mobileNumber}</td>
                              <td>{record.email}</td>
                              <td>{record.education}</td>
                              <td>{record.occupation}</td>
                              <td
                                className="text-center"
                                style={{
                                  color:
                                    record.requestStatus === 1
                                      ? "Green"
                                      : record.requestStatus === 2
                                      ? "Red"
                                      : record.requestStatus === 4
                                      ? "Red"
                                      : record.requestStatus === 0
                                      ? "Orange"
                                      : record.requestStatus === 3
                                      ? "Gold "
                                      : null,
                                  fontWeight: "bold",
                                }}
                              >
                                {" "}
                                {record.requestStatus === 1 ? (
                                  <GetLanguageString props="notifications_approve" />
                                ) : record.requestStatus === 2 ? (
                                  <GetLanguageString props="notifications_reject" />
                                ) : record.requestStatus === 4 ? (
                                  <GetLanguageString props="notifications_cancel" />
                                ) : record.requestStatus === 0 ? (
                                  <GetLanguageString props="notifications_pending" />
                                ) : record.requestStatus === 3 ? (
                                  <GetLanguageString props="notifications_on_hold" />
                                ) : record.requestStatus === null ? (
                                  " - "
                                ) : null}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <NoResult />
                )
              ) : (
                LoadingSpinner()
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AhbabFromContactFormReport;
