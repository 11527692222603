import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./assets/locales/en/translation.json";
import translationUR from './assets/locales/ur/translation.json';

const selectedLanguage = localStorage.getItem("i18nextLng");
const fallbackLng = [selectedLanguage];
const availableLanguages = ["en", "ur"];

const resources = {
  en: {
    translation: translationEN
  },
  ur: {
    translation: translationUR
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,

    detection: {
      checkWhitelist: true
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
