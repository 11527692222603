import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, NavLink, ButtonGroup, Button } from "reactstrap";
import { withRouter, useLocation, useParams } from "react-router-dom";
import { GetLanguageString } from "../../helper/Components";

import "react-datepicker/dist/react-datepicker.css";
import UpdateMaritalStatus from "./UpdateMaritalStatus";
import UpdateMemberType from "./UpdateMemberType";
import UpdateEducationOccupationSkill from "./UpdateEducationOccupationSkill";
import UpdateMemberResponsibility from "./UpdateMemberResponsibility";
import UpdateMemberUnit from "./UpdateMemberUnit";
import UpdateMemberInfo from "./UpdateMemberInfo";
import UpdateAddress from "./UpdateAddress";
import UpdateContactInfo from "./UpdateContactInfo";
import UpdateMemberPhoto from "./UpdateMemberPhoto";
import {
  CheckIsAdmin,
  CheckPermission,
  LoadingSpinner,
  convertUTCToLocalTime,
} from "../../../constants/const";
import { ToastContainer } from "react-toastify";
import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import { addMember } from "../ApiMember";
import moment from "moment";
import { encryptStorage } from "../../../constants/EncryptStorage";
import MoveMemberModal from "../MoveMemberModal";
import ChangePageTitle from "../../shared/ChangePageTitle";
import UpdateDocuments from "./UpdateDocuments";
import MarkRafiqToEx from "../MarkRafiqToEx";
import UpdateMemberMudarisInfo from "./UpdateMemberMudarisInfo";
import UpdatePledges from "./UpdatePledges";
import UpdateBooks from "./UpdateBooks";
import UpdateLetterForCounselling from "../MemberLetterForCounselling/UpdateLetterForCounselling";

const UpdateMember = ({ history }) => {
  const selectedLanguage = GetSelectedLanguage();
  const location = useLocation();
  const [tab1, setTab1] = useState("");
  const [tab2, setTab2] = useState("");
  const [tab3, setTab3] = useState("");
  const [tab4, setTab4] = useState("");
  const [tab5, setTab5] = useState("");
  const [tab6, setTab6] = useState("");
  const [tab7, setTab7] = useState("");
  const [tab8, setTab8] = useState("");
  const [tab9, setTab9] = useState("");
  const [tab10, setTab10] = useState("");
  const [tab11, setTab11] = useState("");
  const [tab12, setTab12] = useState("");
  const [tab13, setTab13] = useState("");
  const [tab14, setTab14] = useState("");
  const [check, setCheck] = useState();
  const [markRafiqEx, setMarkRafiqExModal] = useState(false);
  const [moveMemberModal, setMoveMemberModal] = useState(false);
  const [isRafiqEx, setIsRafiqEx] = useState(null);
  const unitTypeName = encryptStorage.getItem("unitTypeName");

  const [imageUrl, setImageUrl] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);

  const [updatedDetails, setUpdatedDetails] = useState(false);
  const [getDocuments, setGetDocuments] = useState(false);
  const unitId = location.state?.unitId;
  const [currentUnitId, setCurrentUnitId] = useState();
  const unitName = location.state?.unitName;
  const canModifyType = location.state?.canModifyType;
  const addNewRafiq = location.state?.addNewRafiq;
  const memberTypeId = location.state?.memberTypeId;
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [memberId, setMemberId] = useState(parseInt(id));
  const [activeTab, setActiveTab] = useState();
  const [discard, setDiscard] = useState(false);
  const [memberValues, setMemberValues] = useState({
    birthDate: null,
    fatherName: "",
    fullName: "",
    serialNumber: "",
    regNo: "",
    joiningDate: null,
    unitNames: "",
    responsibility: "",
    age: "",
    colorCode: "",
    memberType: "",
  });

  const handleOnMoveMember = (e) => {
    const { name } = e.target;
    setCheck(name);
    setMoveMemberModal(true);
  };

  const handleMarkExRafiq = () => {
    setMarkRafiqExModal(true);
  };

  useEffect(() => {
    if (
      addNewRafiq ||
      CheckPermission("CanModifyMembersPersonalInfo") ||
      CheckPermission("CanRequestMemberModificationPersonalInfo")
    ) {
      setTab1("active");
    } else if (canModifyType) {
      setTab7("active");
    } else if (addNewRafiq || CheckPermission("CanModifyResponsibility")) {
      setTab8("active");
    } else if (
      addNewRafiq ||
      CheckPermission("CanModifyMembersUnit") ||
      CheckPermission("CanRequestMemberModificationUnit")
    ) {
      setTab9("active");
    }
  }, [canModifyType, addNewRafiq]);

  const resetTabs = () => {
    setTab1("");
    setTab2("");
    setTab3("");
    setTab4("");
    setTab5("");
    setTab6("");
    setTab7("");
    setTab8("");
    setTab9("");
    setTab10("");
    setTab11("");
    setTab12("");
    setTab13("");
    setTab14("");
  };
  // on the bases of discard changes
  const discardChanges = useCallback(() => {
    // Reset all tabs
    resetTabs();

    // Determine the tab to set as "active" based on activeTab value
    switch (activeTab) {
      case "tab1":
        setTab1("active");
        break;
      case "tab2":
        setTab2("active");
        break;
      case "tab3":
        setTab3("active");
        break;
      case "tab4":
        setTab4("active");
        break;
      case "tab5":
        setTab5("active");
        break;
      case "tab6":
        setTab6("active");
        break;
      case "tab7":
        setTab7("active");
        break;
      case "tab8":
        setTab8("active");
        break;
      case "tab9":
        setTab9("active");
        break;
      case "tab10":
        setTab10("active");
        setGetDocuments(true);
        break;
      case "tab11":
        setTab11("active");
        break;
      case "tab12":
        setTab12("active");
        break;
      case "tab13":
        setTab13("active");
        break;
      case "tab14":
        setTab14("active");
        break;
      // Add more cases for other tabs as needed
      default:
        break;
    }
  }, [
    activeTab,
    setTab1,
    setTab2,
    setTab3,
    setTab4,
    setTab5,
    setTab6,
    setTab7,
    setTab8,
    setTab9,
    setTab10,
    setTab11,
    setTab12,
    setTab13,
    setTab14,
  ]);

  useEffect(() => {
    if (memberId && imageUrl === null) {
      setImageLoading(true);
      addMember()
        .getMemberPhoto(memberId)
        .then((res) => {
          if (res.data) {
            setImageUrl("data:image/jpeg;base64," + res.data.image);
          }
          setImageLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setImageLoading(false);
        });
    }
  }, [memberId, imageUrl]);

  useEffect(() => {
    setLoading(true);
    addMember()
      .GetMemberDataForDetails(memberId)
      .then((res) => {
        setMemberValues({
          ...res.data,
          birthDate: res.data.birthDate
            ? new Date(convertUTCToLocalTime(res.data.birthDate))
            : null,
          joiningDate: res.data.joiningDate
            ? new Date(convertUTCToLocalTime(res.data.joiningDate))
            : null,
          age:
            new Date() -
            new Date(convertUTCToLocalTime(res.data.birthDate)).getTime(),
        });
        setCurrentUnitId(res.data.unitId);
        setIsRafiqEx(res.data.isExRafiq);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    // eslint-disable-next-line
  }, [memberId, updatedDetails]);

  useEffect(() => {
    // Trigger discardChanges when a specific state changes
    if (discard) {
      discardChanges();
      setDiscard(!discard);
    }
  }, [discard, discardChanges, setDiscard]);
  return (
    <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
      <ChangePageTitle
        pageTitle={`TI-ERP | ${memberValues.serialNumber} | ${memberValues.fullName}`}
      />
      <ToastContainer
        autoClose={3000}
        bodyClassName={selectedLanguage}
        closeOnClick
        pauseOnHover={false}
        draggable
      />
      {moveMemberModal && (
        <MoveMemberModal
          memberId={memberId}
          unitId={currentUnitId}
          check={check}
          setMoveMemberModal={setMoveMemberModal}
          setRefresh={setUpdatedDetails}
          refresh={updatedDetails}
        />
      )}
      {markRafiqEx ? (
        <MarkRafiqToEx
          setMarkRafiqExModal={setMarkRafiqExModal}
          memberId={memberId}
          setRefresh={setUpdatedDetails}
          refresh={updatedDetails}
        />
      ) : null}
      <Row className={GetCustomStyles().btn_style}>
        <ButtonGroup>
          {memberTypeId !== 11 ? (
            <>
              {CheckPermission("Ex") && !isRafiqEx && isRafiqEx !== null ? (
                <Button color="danger" size="sm" onClick={handleMarkExRafiq}>
                  {
                    <GetLanguageString props="member_details_button_mark_rafiq_ex" />
                  }
                </Button>
              ) : null}
              <div>
                {!isRafiqEx && isRafiqEx !== null ? (
                  <ButtonGroup>
                    {CheckIsAdmin() ? (
                      <Button
                        color="primary"
                        size="m"
                        onClick={(e) => handleOnMoveMember(e)}
                        name="admin"
                        value={unitId}
                      >
                        {
                          <GetLanguageString props="unit_unitmember_move_member" />
                        }
                      </Button>
                    ) : null}
                    {location.pathname !== "/personalInfo" &&
                    !CheckIsAdmin() &&
                    CheckPermission("CanMoveMembersLocalTransfer") ? (
                      <Button
                        color="success"
                        size="sm"
                        onClick={(e) => handleOnMoveMember(e)}
                        name="Local"
                        value={unitId}
                      >
                        {unitTypeName === "Halqa" ? (
                          <GetLanguageString props="unit_unitmember_move_member_localy" />
                        ) : unitTypeName === "Tanzeem" ? (
                          <GetLanguageString props="unit_unitmember_move_member_localy" />
                        ) : (
                          <GetLanguageString props="unit_unitmember_move_member_localy" />
                        )}
                      </Button>
                    ) : null}
                    {location.pathname !== "/personalInfo" &&
                    !CheckIsAdmin() &&
                    (CheckPermission("CanMoveMembersOutsideTransfer") ||
                      CheckPermission(
                        "CanRequestMoveMembersOutsideTransfer"
                      )) ? (
                      <Button
                        color="info"
                        size="sm"
                        onClick={(e) => handleOnMoveMember(e)}
                        name="Outside"
                        value={unitId}
                      >
                        {unitTypeName === "Halqa" ? (
                          <GetLanguageString props="unit_unitmember_move_members_other" />
                        ) : unitTypeName === "Tanzeem" ? (
                          <GetLanguageString props="unit_unitmember_move_members_other" />
                        ) : (
                          <GetLanguageString props="unit_unitmember_move_members_other" />
                        )}
                      </Button>
                    ) : null}
                  </ButtonGroup>
                ) : null}
              </div>
            </>
          ) : null}
          <Button
            size="sm"
            color="primary"
            onClick={() => {
              history.goBack();
            }}
          >
            {<GetLanguageString props="unit_back_btn" />}
          </Button>
        </ButtonGroup>
      </Row>
      <br></br>
      <br></br>
      <>
        <div
          className="ibox-content"
          style={{
            backgroundColor: "#264058",
            color: "white",
            opacity: 0.8,
            borderBottom: `8px solid ${memberValues.colorCode}`,
          }}
        >
          {!loading ? (
            <Row>
              {imageLoading ? (
                <Col md="2" className="responsive-image-col">
                  {LoadingSpinner()}
                </Col>
              ) : (
                imageUrl && (
                  <Col md="2" className="responsive-image-col">
                    <img
                      style={{ height: 180 }}
                      src={imageUrl}
                      className="responsive-image"
                      alt="Uploaded"
                    />
                  </Col>
                )
              )}
              <Col md={imageUrl || imageLoading ? "3" : "4"}>
                <Row>
                  <h1 style={{ fontSize: "40px" }} className="ur">
                    {memberValues.fullName}
                  </h1>
                </Row>
                <Row>
                  <h3
                    style={
                      selectedLanguage === "ur"
                        ? { fontSize: "20px", fontWeight: "lighter" }
                        : null
                    }
                  >
                    {<GetLanguageString props="member_fathers_name" />} :{" "}
                    <span
                      style={{ fontSize: "20px", fontWeight: "lighter" }}
                      className="ur"
                    >
                      {memberValues.fatherName}
                    </span>{" "}
                  </h3>
                </Row>
                <Row>
                  <h3
                    style={
                      selectedLanguage === "ur"
                        ? { fontSize: "20px", fontWeight: "lighter" }
                        : null
                    }
                  >
                    {<GetLanguageString props="unit_unit_table" />} :{" "}
                    <span
                      style={{ fontSize: "20px", fontWeight: "lighter" }}
                      className="ur"
                    >
                      {memberValues.unitNames.replace(/،/g, " / ")}
                    </span>
                  </h3>
                </Row>
              </Col>
              <Col md={imageUrl || imageLoading ? "3" : "4"}>
                <Row className="text-center">
                  <h3 style={{ fontSize: "30px", unicodeBidi: "plaintext" }}>
                    {memberValues.regNo}
                  </h3>
                </Row>
                {memberTypeId !== 11 ? (
                  <Row className="text-center">
                    <h3
                      style={
                        selectedLanguage === "ur"
                          ? { fontSize: "20px", fontWeight: "lighter" }
                          : null
                      }
                    >
                      {<GetLanguageString props="member_registration_date" />} :{" "}
                      {memberValues.joiningDate
                        ? moment(
                            convertUTCToLocalTime(memberValues.joiningDate)
                          ).format("DD/MM/yyyy")
                        : "-"}
                    </h3>
                  </Row>
                ) : (
                  <Row className="text-center">
                    <h3
                      style={
                        selectedLanguage === "ur"
                          ? { fontSize: "20px", fontWeight: "lighter" }
                          : null
                      }
                    >
                      {
                        <GetLanguageString props="meeting_members_member_type" />
                      }{" "}
                      : <span className="ur">{memberValues.memberType}</span>
                    </h3>
                  </Row>
                )}
              </Col>
              <Col md="4">
                <div className={GetCustomStyles().btn_style}>
                  {memberValues.responsibility !== "،" ? (
                    <Row>
                      <h3
                        style={
                          selectedLanguage === "ur"
                            ? { fontSize: "20px", fontWeight: "lighter" }
                            : null
                        }
                      >
                        {<GetLanguageString props="member_responsibility" />} :{" "}
                        <span
                          style={{ fontSize: "20px", fontWeight: "lighter" }}
                          className="ur"
                        >
                          <ol
                            className="breadcrumb"
                            style={{
                              backgroundColor: "#264058",
                            }}
                          >
                            {memberValues.responsibility
                              .split("،")
                              ?.map((item, index) => {
                                return (
                                  <li
                                    style={{
                                      backgroundColor: "#264058",
                                    }}
                                    className={
                                      GetSelectedLanguage() === "ur"
                                        ? "breadcrumb-item-ur"
                                        : "breadcrumb-item"
                                    }
                                    key={index}
                                  >
                                    <span style={{ color: "white" }}>
                                      {item}
                                    </span>
                                  </li>
                                );
                              })}
                          </ol>
                        </span>
                      </h3>
                    </Row>
                  ) : null}
                  <Row>
                    <h3
                      style={
                        selectedLanguage === "ur"
                          ? { fontSize: "20px", fontWeight: "lighter" }
                          : null
                      }
                    >
                      {<GetLanguageString props="member_date_of_birth" />} :{" "}
                      {moment(
                        convertUTCToLocalTime(memberValues.birthDate)
                      ).format("DD/MM/yyyy")}
                    </h3>
                  </Row>
                  {memberValues.birthDate ? (
                    <Row>
                      <h3
                        style={
                          selectedLanguage === "ur"
                            ? { fontSize: "20px", fontWeight: "lighter" }
                            : null
                        }
                      >
                        {<GetLanguageString props="member_age" />} :{" "}
                        {new Date(memberValues.age).getUTCFullYear() - 1970}
                      </h3>
                    </Row>
                  ) : null}
                </div>
              </Col>
            </Row>
          ) : (
            LoadingSpinner()
          )}
        </div>

        <br></br>
        <Row>
          <Col md="12">
            <div className="tabs-container">
              <ul className="nav nav-tabs" role="tablist">
                {addNewRafiq ||
                CheckPermission("CanModifyMembersPersonalInfo") ||
                CheckPermission("CanRequestMemberModificationPersonalInfo") ||
                memberTypeId !== 11 ? (
                  <>
                    <li>
                      <NavLink
                        to="#"
                        className={"nav-link " + tab1}
                        onClick={() => {
                          setActiveTab("tab1");
                        }}
                      >
                        {memberTypeId !== 11 ? (
                          <GetLanguageString props="member_member_detail" />
                        ) : (
                          <GetLanguageString props="ahbab_transfer_habib_habib_details" />
                        )}
                      </NavLink>
                    </li>
                    {memberTypeId !== 11 ? (
                      <>
                        <li>
                          <NavLink
                            to="#"
                            className={"nav-link " + tab2}
                            onClick={() => {
                              setActiveTab("tab2");
                            }}
                          >
                            {
                              <GetLanguageString props="member_marital_status" />
                            }
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="#"
                            className={"nav-link " + tab10}
                            onClick={() => {
                              setActiveTab("tab10");
                            }}
                          >
                            {
                              <GetLanguageString props="member_member_detail_documents" />
                            }
                          </NavLink>
                        </li>
                      </>
                    ) : null}
                    <li>
                      <NavLink
                        to="#"
                        className={"nav-link " + tab3}
                        onClick={() => {
                          setActiveTab("tab3");
                        }}
                      >
                        {<GetLanguageString props="member_address" />}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="#"
                        className={"nav-link " + tab4}
                        onClick={() => {
                          setActiveTab("tab4");
                        }}
                      >
                        <GetLanguageString props="member_contact" />
                      </NavLink>
                    </li>

                    {memberTypeId !== 11 ? (
                      <>
                        <li>
                          <NavLink
                            to="#"
                            className={"nav-link " + tab5}
                            onClick={() => {
                              setActiveTab("tab5");
                            }}
                          >
                            <GetLanguageString props="member_education_occupatoin_skills" />
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="#"
                            className={"nav-link " + tab6}
                            onClick={() => {
                              setActiveTab("tab6");
                            }}
                          >
                            <GetLanguageString props="member_photo" />
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="#"
                            className={"nav-link " + tab13}
                            onClick={() => {
                              setActiveTab("tab13");
                            }}
                          >
                            {
                              <GetLanguageString props="member_member_detail_books" />
                            }
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="#"
                            className={"nav-link " + tab14}
                            onClick={() => {
                              setActiveTab("tab14");
                            }}
                          >
                            {
                              <GetLanguageString props="member_member_detail_letter_for_counselling" />
                            }
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="#"
                            className={"nav-link " + tab12}
                            onClick={() => {
                              setActiveTab("tab12");
                            }}
                          >
                            {
                              <GetLanguageString props="member_member_detail_pledges" />
                            }
                          </NavLink>
                        </li>
                      </>
                    ) : null}
                  </>
                ) : null}{" "}
                {CheckPermission("CanModifyMudaris") && memberTypeId !== 11 ? (
                  <li>
                    <NavLink
                      to="#"
                      className={"nav-link " + tab11}
                      onClick={() => {
                        setActiveTab("tab11");
                      }}
                    >
                      <GetLanguageString props="member_member_detail_mudaris" />
                    </NavLink>
                  </li>
                ) : null}
                {(canModifyType || addNewRafiq) && memberTypeId !== 11 ? (
                  <li>
                    <NavLink
                      to="#"
                      className={"nav-link " + tab7}
                      onClick={() => {
                        setActiveTab("tab7");
                      }}
                    >
                      <GetLanguageString props="meeting_members_member_type" />
                    </NavLink>
                  </li>
                ) : null}
                {(addNewRafiq || CheckPermission("CanModifyResponsibility")) &&
                memberTypeId !== 11 ? (
                  <li>
                    <NavLink
                      to="#"
                      className={"nav-link " + tab8}
                      onClick={() => {
                        setActiveTab("tab8");
                      }}
                    >
                      <GetLanguageString props="member_member_detail_common_responsibility" />
                    </NavLink>
                  </li>
                ) : null}
                {addNewRafiq ||
                CheckPermission("CanModifyMembersUnit") ||
                CheckPermission("CanRequestMemberModificationUnit") ? (
                  <li>
                    <NavLink
                      to="#"
                      className={"nav-link " + tab9}
                      onClick={() => {
                        setActiveTab("tab9");
                      }}
                    >
                      <GetLanguageString props="courses_unit" />
                    </NavLink>
                  </li>
                ) : null}
              </ul>
              <div className="tab-content">
                {addNewRafiq ||
                CheckPermission("CanModifyMembersPersonalInfo") ||
                CheckPermission("CanRequestMemberModificationPersonalInfo") ? (
                  <>
                    <UpdateMemberInfo
                      tab1={tab1}
                      memberId={memberId}
                      addNewRafiq={addNewRafiq}
                      setMemberId={setMemberId}
                      setUpdatedDetails={setUpdatedDetails}
                      memberTypeId={memberTypeId}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      discard={discard}
                      setDiscard={setDiscard}
                    />
                    <UpdateMaritalStatus
                      tab2={tab2}
                      memberId={memberId}
                      addNewRafiq={addNewRafiq}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      setDiscard={setDiscard}
                      discard={discard}
                    />
                    <UpdateAddress
                      tab3={tab3}
                      memberId={memberId}
                      addNewRafiq={addNewRafiq}
                      setUpdatedDetails={setUpdatedDetails}
                      memberTypeId={memberTypeId}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      setDiscard={setDiscard}
                    />
                    <UpdateContactInfo
                      tab4={tab4}
                      memberId={memberId}
                      addNewRafiq={addNewRafiq}
                      memberTypeId={memberTypeId}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      setDiscard={setDiscard}
                    />
                    <UpdateEducationOccupationSkill
                      tab5={tab5}
                      memberId={memberId}
                      addNewRafiq={addNewRafiq}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      discard={discard}
                      setDiscard={setDiscard}
                    />
                    <UpdateMemberPhoto
                      tab6={tab6}
                      memberId={memberId}
                      addNewRafiq={addNewRafiq}
                      setImageUrlForDetails={setImageUrl}
                      imageUrl={imageUrl}
                      activeTab={activeTab}
                      discard={discard}
                      setDiscard={setDiscard}
                    />
                    <UpdateBooks
                      tab13={tab13}
                      memberId={memberId}
                      discard={discard}
                      setDiscard={setDiscard}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                    />
                    <UpdateDocuments
                      tab10={tab10}
                      memberId={memberId}
                      getDocuments={getDocuments}
                      discard={discard}
                      setDiscard={setDiscard}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                    />
                    <UpdatePledges
                      tab12={tab12}
                      memberId={memberId}
                      setDiscard={setDiscard}
                      activeTab={activeTab}
                      discard={discard}
                    />
                    <UpdateLetterForCounselling
                      tab14={tab14}
                      activeTab={activeTab}
                      memberId={memberId}
                      discard={discard}
                      setDiscard={setDiscard}
                      memberValues={{
                        memberName: memberValues.fullName,
                        memberResponsibility: memberValues.responsibility,
                        memberRegNo: memberValues.regNo,
                        memberUnit: memberValues.unitNames,
                      }}
                    />
                  </>
                ) : null}
                {canModifyType ? (
                  <UpdateMemberType
                    tab7={tab7}
                    memberId={memberId}
                    addNewRafiq={addNewRafiq}
                    setUpdatedDetails={setUpdatedDetails}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    discard={discard}
                    setDiscard={setDiscard}
                  />
                ) : null}
                {addNewRafiq || CheckPermission("CanModifyResponsibility") ? (
                  <UpdateMemberResponsibility
                    tab8={tab8}
                    memberId={memberId}
                    unitId={unitId}
                    unitName={unitName}
                    addNewRafiq={addNewRafiq}
                    setUpdatedDetails={setUpdatedDetails}
                    discard={discard}
                    setDiscard={setDiscard}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                ) : null}
                {addNewRafiq ||
                CheckPermission("CanModifyMembersUnit") ||
                CheckPermission("CanRequestMemberModificationUnit") ? (
                  <UpdateMemberUnit
                    tab9={tab9}
                    memberId={memberId}
                    addNewRafiq={addNewRafiq}
                    setUpdatedDetails={setUpdatedDetails}
                    memberTypeId={memberTypeId}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    setDiscard={setDiscard}
                  />
                ) : null}
                {addNewRafiq || CheckPermission("CanModifyMudaris") ? (
                  <UpdateMemberMudarisInfo
                    tab11={tab11}
                    editValue={memberId}
                    refresh={updatedDetails}
                    setRefresh={setUpdatedDetails}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    setDiscard={setDiscard}
                    discard={discard}
                  />
                ) : null}
              </div>
            </div>
          </Col>
        </Row>
      </>
    </div>
  );
};

export default withRouter(UpdateMember);
