import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import { LoadingSpinner } from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";
import FundFilter from "../helper/SearchFilter/FundFilter";
import { GetCustomStyles, SelectOnlyDate } from "../helper/Method";
import { GetLanguageString } from "../helper/Components";
import { ApiFund } from "../funds/ApiFund";
import { MdOutlineBolt } from "react-icons/md";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const FundDetail = ({tab6}) => {
  const [loading, setLoading] = useState(false);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [cumulativeFund, setCumulativeFund] = useState({
    totalMembers: 0,
    monthModels: [],
  });
  let tempDate = new Date();
  tempDate.setDate(1);
  const [values, setValues] = useState({
    unit: { value: loggedInUnitId, lable: "" },
    isSubUnits: true,
    displayMemberDetails: false,
    startDate: SelectOnlyDate(tempDate),
    excludeExUnit: true,
    endDate: SelectOnlyDate(new Date()),
  });

  let percentage = 0.0;

  useEffect(() => {
    if(tab6 === "active"){
      setLoading(true);
      ApiFund()
        .getMembersFundsDetails(values)
        .then((res) => {
          setCumulativeFund(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [values, tab6]);

  const additionalTooltipData = cumulativeFund.monthModels.map(record => ({
    paidMembers: record.unitMemberCount,
    totalMembers: record.totalUnitMemberCount,
  }));

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Cumulative Members Funds",
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            let label = 'Percentage';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += `${context.parsed.y.toFixed(2)}% `;
              
            }
            
            const index = context.dataIndex; // Get the index of the current data item
            const tooltipData = additionalTooltipData[index];
            if(tooltipData){
              label += ` Paid: ${tooltipData.paidMembers}, Total: ${tooltipData.totalMembers})`;
            }
            return label;
          }
        }
      }
    },
    scales: {
      y: {
        min: 0,
        max: 100,
        ticks: {
          callback: function (value, index, values) {
            // if (Math.floor(value) === value) {
            //   return value;
            // }
            return value + '%';
          },
        },
      },
    },
  };
 
  const data = {
    labels: cumulativeFund.monthModels.map(record => `${record.monthName} ${record.year}`),
    datasets: [
      {
        label: "Fund Paid",
       data: cumulativeFund.monthModels.map(record => (record.unitMemberCount / record.totalUnitMemberCount) * 100),
        backgroundColor: "#81d9c9",
      },
    ],
  };

  return (
    <div role="tabpanel" id="tab-6" className={"tab-pane " + tab6}>
      <div className="panel-body">
        <div className="ibox">
        <div className="ibox-content">
          <FundFilter values={values} setValues={setValues}/>
        </div>
        </div>
        {!loading ? (
          <>
            {cumulativeFund.monthModels.length !== 0 ? (
              <Row>
                <Col xl="6">
                  <div className="ibox">
                    <div className="ibox-title">
                      <h5>
                        {
                          <GetLanguageString props="funds_report_cumulative_members_funds_detail" />
                        }
                      </h5>
                    </div>
                    <div className="ibox-content">
                      <ul className="stat-list">
                        <li>
                          <Row>
                            {cumulativeFund.monthModels.map((record) => {
                              percentage = (
                                (record.unitMemberCount / record.totalUnitMemberCount) *
                                100
                              ).toFixed(1);
                              return (
                                <Col md="4">
                                  <h2 className="no-margins">{record.unitMemberCount} / {record.totalUnitMemberCount}</h2>
                                  <small>
                                    {record.monthName + " " + record.year}
                                  </small>
                                  <div
                                    className={
                                      GetCustomStyles().stat_list_percent
                                    }
                                  >
                                    {percentage}%{" "}
                                    <MdOutlineBolt size={18} color="#1AB394" />
                                  </div>
                                  <div className="progress progress-mini">
                                    <div
                                      style={{
                                        width: percentage + "%",
                                      }}
                                      className="progress-bar"
                                    ></div>
                                  </div>
                                </Col>
                              );
                            })}
                          </Row>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col xl="6">
                  <div className="ibox">
                    <div className="ibox-title">
                      <h5>Bar Chart</h5>
                    </div>
                    <div className="ibox-content">
                      <div>
                        <Bar data={data} options={options} />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : null}
          </>
        ) : (
          LoadingSpinner()
        )}
      </div>
    </div>
  );
};

export default FundDetail;
