import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { requestApi } from "../../../actions/api";
import { GetLanguageString } from "../../helper/Components";
import { LoadingSpinner } from "../../../constants/const";
import { ApiBooks } from "./ApiBooks";
import Draggable from "react-draggable";

const EditBooks = ({ editValue, setEditModal, setBooks }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [booksValues, setBooksValues] = useState({
    name: "",
    staticBookType: {},
  });
  const [staticBookType, setStaticBookType] = useState([]);
  const [selectedStaticBookType, setSelectedStaticBookType] = useState({});
  const [errors, setErrors] = useState(booksValues);
  const toggle = () => setEditModal(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  useEffect(() => {
    setLoading(true);
    requestApi("books/")
      .fetchById(editValue)
      .then((res) => {
        setBooksValues(res.data);
        setSelectedStaticBookType(res.data.staticBookType);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

      ApiBooks()
      .getStaticBookType()
      .then((res) => {
        setStaticBookType(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [editValue]);

  const validate = () => {
    let temp = {};
    temp.name = booksValues.name ? "" : t("settings_name_error_message");
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleChangeStaticBookType = (record) => {
    setSelectedStaticBookType(record);
    setErrors({
        staticBookType: {},
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setErrors({
      name: "",
    });
    setBooksValues({
      ...booksValues,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    booksValues.staticBookType = selectedStaticBookType;
    if (validate()) {
      setLoading(true);
      requestApi("books/")
        .update(editValue, booksValues)
        .then((res) => {
            requestApi("books/")
            .fetchAll()
            .then((res) => {
              toggle();
              setBooks(res.data);
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  return (
    <div>
    <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
      <Modal isOpen={true} className="inmodal" autoFocus={false}>
        <ModalHeader className="modal-title" tag="h4">
          {<GetLanguageString props="settings_edit_books" />}
        </ModalHeader>
        {!loading ? (
          <Form onSubmit={handleSubmit}>
            <ModalBody>
              <FormGroup>
                <Label>{<GetLanguageString props="common_name" />}</Label>
                <Input
                  autoFocus
                  type="text"
                  name="name"
                  className="ur no-drag"
                  value={booksValues.name}
                  onChange={handleInputChange}
                  {...(errors.name && { invalid: true })}
                />
                {errors.name && (
                  <FormFeedback>
                    <h4>{errors.name}</h4>
                  </FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label>{<GetLanguageString props="book_type" />}</Label>
                <Select
                  value={selectedStaticBookType}
                  options={staticBookType}
                  className="basic-single ur"
                  classNamePrefix="select"
                  onChange={handleChangeStaticBookType}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="white" onClick={toggle}>
                {<GetLanguageString props="common_cancel" />}
              </Button>
              <Button color="primary" type="submit">
                {<GetLanguageString props="common_save_changes" />}
              </Button>
            </ModalFooter>
          </Form>
        ) : (
          LoadingSpinner()
        )}
      </Modal>
    </Draggable>  
    </div>
  );
};

export default EditBooks;
