import React from 'react'
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup, Input, Table } from 'reactstrap';
import { GetLanguageString } from '../helper/Components';

const BulkMemberField = ({setValues, id, values}) => {
    const { t } = useTranslation();
    const [completeColor, setCompleteColor] = useState("primary");
    const [inCompleteColor, setInCompleteColor] = useState("white");

    const handleOnChangeComment = (e) => {
        const {value} = e.target;
        let tempValues = [...values];
        tempValues[id].comments = value;
        setValues(tempValues);
      }
      const handleOnChangeReg = (e) => {
        const {value} = e.target;
        let tempValues = [...values];
        tempValues[id].regNo = value;
        setValues(tempValues);
      }
    const handleOnClick = (e) => {
        const { value } = e.target;
        if (value === "1") {
            let tempValues = [...values];
            tempValues[id].status = true;
            setValues(tempValues);
          setCompleteColor("primary");
          setInCompleteColor("white");
        } else if (value === "0") {
            let tempValues = [...values];
            tempValues[id].status = false;
            setValues(tempValues);
          setInCompleteColor("primary");
          setCompleteColor("white");
        }
      };

      const removeObject = () => {
        let tempValues = [...values];
        tempValues.splice(id, 1);
        setValues(tempValues);
      };
    
  return (
    <>
    <Table responsive>
        <tbody>
            <tr>
                <td>
                    <Input 
                        type='text'
                        name='regNo'
                        style={{borderRadius : 5, width: '60%'}}
                        placeholder={t("member_reg_number")}
                        onChange={handleOnChangeReg}
                        value={values.regNo}
                    />
                </td>
                <td>
                    <Input 
                        type='text'
                        name='comments'
                        style={{borderRadius : 5, width: '80%'}}
                        placeholder={t("courses_participants_comments")}
                        onChange={handleOnChangeComment}
                        value={values.comments}
                    />
                </td>
                <td>
                    <ButtonGroup>
                        <Button
                            color={completeColor}
                            size="m"
                            onClick={handleOnClick}
                            value="1"
                        >
                            {<GetLanguageString props="courses_status_completed" />}
                        </Button>
                        <Button
                            color={inCompleteColor}
                            size="m"
                            onClick={handleOnClick}
                            value="0"
                        >
                            {
                            <GetLanguageString props="courses_status_incompleted" />
                            }
                        </Button>
                    </ButtonGroup>
                </td>
                <td>
                    <i
                        className='fa fa-3x fa-minus'
                        style={{ cursor: 'pointer', color: 'red' }}
                        onClick={removeObject}
                    />
                </td>
                
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        </tbody> 
        </Table>
    </>
  )
}

export default BulkMemberField