import React, { useState } from "react";
import {
  ButtonGroup,
  Button,
  Label,
  Row,
  Col,
  FormGroup,
  Input,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import SelectUnit from "../../shared/SelectUnit";
import { ApiUnit } from "../../unit/ApiUnit";
import { GetLanguageString } from "../../helper/Components";
import { encryptStorage } from "../../../constants/EncryptStorage";
import { DateValidater } from "../../helper/Method";
import { convertToDateString } from "../../../constants/const";

const SearchMemberStatusChange = ({
  memberStatusChangeValues,
  setMemberStatusChangeValues,
  setSearchButton,
}) => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [values, setValues] = useState(memberStatusChangeValues);
  const [currentMonth, setCurrentMonth] = useState("primary");
  const [excludeExUnit, setExcludeExUnit] = useState(true);
  const [lastMonth, setLastMonth] = useState("white");
  const [lastSixMonth, setLastSixMonth] = useState("white");
  const [lastThreeMonth, setLastThreeMonth] = useState("white");
  const [lastYear, setLastYear] = useState("white");
  const dateRangeValidation = {
    startDate: null,
    endDate: null,
  };
  const [selectedDates, setSelectedDates] = useState({
    startDate: values.startDate,
    endDate: values.endDate,
  });
  const [dateValidations, setDateValidations] = useState(dateRangeValidation);

  if (values.unit.value === 0) {
    ApiUnit()
      .getUnit(loggedInUnitId, loggedInUnitId)
      .then((res) => {
        let temp = res.data.unitList[0];
        let temp2 = { value: temp.id, lable: temp.name };
        setValues({
          ...values,
          unit: temp2,
        });
      })
      .catch((err) => console.log(err));
  }

  const handleInputChangeStartDate = (value) => {
    if (DateValidater(value, selectedDates.endDate)) {
      setSelectedDates({
        ...selectedDates,
        startDate: value,
      });
      setDateValidations(dateRangeValidation);
    } else {
      setDateValidations({
        ...dateValidations,
        startDate: (
          <GetLanguageString props="common_start_date_validation_message" />
        ),
      });
    }
  };

  const handleInputChangeEndDate = (value) => {
    if (DateValidater(selectedDates.startDate, value)) {
      setSelectedDates({
        ...selectedDates,
        endDate: value,
      });
      setDateValidations(dateRangeValidation);
    } else {
      setDateValidations({
        ...dateValidations,
        endDate: (
          <GetLanguageString props="common_end_date_validation_message" />
        ),
      });
    }
  };

  const handleSubUnits = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setValues({
      ...values,
      isSubUnits: v,
    });
  };

  const handleSearch = () => {
    if (!DateValidater(selectedDates.startDate, selectedDates.endDate)) {
      setDateValidations({
        ...dateValidations,
        endDate: (
          <GetLanguageString props="common_end_date_validation_message" />
        ),
      });
    } else {
      setMemberStatusChangeValues({
        ...values,
        startDate: convertToDateString(selectedDates.startDate),
        endDate: convertToDateString(selectedDates.endDate),
      });
      setSearchButton(true);
    }
  };

  const handleEventDate = (e) => {
    const { value } = e.target;
    let date = new Date();
    let currentDate = new Date();
    var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
    if (value === "0") {
      setLastMonth("white");
      setCurrentMonth("primary");
      setLastThreeMonth("white");
      setLastSixMonth("white");
      setLastYear("white");
      date.setUTCDate(1);
      setSelectedDates({
        ...selectedDates,
        startDate: date,
        endDate: currentDate,
      });
    } else if (value === "1") {
      setLastMonth("primary");
      setCurrentMonth("white");
      setLastSixMonth("white");
      setLastThreeMonth("white");
      setLastYear("white");
      let firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
      setSelectedDates({
        ...selectedDates,
        startDate: firstDay,
        endDate: lastDay,
      });
    } else if (value === "2") {
      setLastMonth("white");
      setCurrentMonth("white");
      setLastThreeMonth("white");
      setLastSixMonth("primary");
      setLastYear("white");
      let firstDay = new Date(date.getFullYear(), date.getMonth() - 6, 1);
      setSelectedDates({
        ...selectedDates,
        startDate: firstDay,
        endDate: lastDay,
      });
    } else if (value === "3") {
      setLastMonth("white");
      setCurrentMonth("white");
      setLastSixMonth("white");
      setLastThreeMonth("white");
      setLastYear("primary");
      let firstDay = new Date(date.getFullYear(), date.getMonth() - 12, 1);
      setSelectedDates({
        ...selectedDates,
        startDate: firstDay,
        endDate: lastDay,
      });
    } else if (value === "4") {
      setLastMonth("white");
      setCurrentMonth("white");
      setLastSixMonth("white");
      setLastThreeMonth("primary");
      setLastYear("white");
      let firstDay = new Date(date.getFullYear(), date.getMonth() - 3, 1);
      setSelectedDates({
        ...selectedDates,
        startDate: firstDay,
        endDate: lastDay,
      });
    }
    setDateValidations(dateRangeValidation);
  };

  const handleExcludeExUnit = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setExcludeExUnit(v);
  };

  return (
    <div className="ibox-content">
      <SelectUnit
        values={values}
        setValues={setValues}
        unitId={values.unit.value}
        excludeExUnit={excludeExUnit}
      />
      <Row>
        <Col md="2">
          <FormGroup check>
            <Label check for="excludeExUnit">
              <Input
                id="excludeExUnit"
                type="checkbox"
                name="excludeExUnit"
                checked={!excludeExUnit}
                value={!excludeExUnit}
                onChange={handleExcludeExUnit}
              />
              {<GetLanguageString props="member_search_member_ex_unit" />}
            </Label>
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup check>
            <Label check for="subUnit">
              <Input
                id="subUnit"
                type="checkbox"
                name="isSubUnits"
                checked={values.isSubUnits}
                value={!values.isSubUnits}
                onChange={handleSubUnits}
              />
              {<GetLanguageString props="member_search_member_sub_unit" />}
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <Row className="m-t-md">
        <Col md="6">
          <ButtonGroup>
            <Button color={currentMonth} value={0} onClick={handleEventDate}>
              {<GetLanguageString props="funds_report_current_month" />}
            </Button>
            <Button color={lastMonth} value={1} onClick={handleEventDate}>
              {<GetLanguageString props="funds_report_last_month" />}
            </Button>
            <Button color={lastThreeMonth} value={4} onClick={handleEventDate}>
              {<GetLanguageString props="quick_reports4_last_3_months" />}
            </Button>
            <Button color={lastSixMonth} value={2} onClick={handleEventDate}>
              {<GetLanguageString props="funds_report_last_6_months" />}
            </Button>
            <Button color={lastYear} value={3} onClick={handleEventDate}>
              {<GetLanguageString props="funds_report_last_year" />}
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      <Row className="m-t-md">
        <Col xl="4" xxl="4" md="6">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="courses_start_date" />}
            </Label>
            <DatePicker
              showYearDropdown
              placeholderText="dd/mm/yyyy"
              className="form-control"
              selected={selectedDates.startDate}
              onChange={handleInputChangeStartDate}
              dateFormat="dd/MM/yyyy"
            />
            {dateValidations.startDate && (
              <h4 className="text-error">{dateValidations.startDate}</h4>
            )}
          </FormGroup>
        </Col>
        <Col xl="4" xxl="4" md="6">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="courses_end_date" />}
            </Label>
            <DatePicker
              showYearDropdown
              placeholderText="dd/mm/yyyy"
              className="form-control"
              selected={selectedDates.endDate}
              onChange={handleInputChangeEndDate}
              dateFormat="dd/MM/yyyy"
            />
            {dateValidations.endDate && (
              <h4 className="text-error">{dateValidations.endDate}</h4>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="12" className="text-center m-t-sm">
          <Button color="primary" type="submit" onClick={handleSearch}>
            {<GetLanguageString props="funds_submit" />}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default SearchMemberStatusChange;
