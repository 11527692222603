import React from "react";
import SelectMemberUnit from "../member/SelectMemberUnit";

const SelectUnitRequestMoveMember = ({ values, setValues, unitId }) => {
  return (
    <div>
      <SelectMemberUnit loggedInUnitId={unitId} unitId={unitId} setValues={setValues} values={values} />
    </div>
  );
};

export default SelectUnitRequestMoveMember;
