import React, { useEffect, useState } from "react";
import { convertToDateString, LoadingSpinner } from "../../../constants/const";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { GetLanguageString } from "../../helper/Components";
import swal from "sweetalert";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import useForm from "../../shared/useForm";
import { GetSelectedLanguage } from "../../helper/Method";
import Draggable from "react-draggable";
import { ApiBooks } from "../../books/ApiBooks";
import DatePicker from "react-datepicker";

const AddBook = ({
  setAddBookModel,
  setBooks,
  memberId,
  setRefresh,
  refresh,
}) => {
  const [booksValues, setBooksValues] = useState({
    remarks: "",
    referenceNote: "",
    completedAt: null,
    staticBookType: null,
    memberId: memberId,
  });
  const toggle = () => setAddBookModel(false);
  const [loading, setLoading] = useState(false);
  const [completedAtDate, setCompletedAtDate] = useState(new Date());
  const [booksDropdown, setBooksDropdown] = useState([]);
  const { errors, setErrors } = useForm(booksValues);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  const { t } = useTranslation();

  // Get the books list for the dropdown
  useEffect(() => {
    setLoading(true);
    ApiBooks()
      .getAllBooksForMember(memberId)
      .then((res) => {
        setBooksDropdown(res.data);
        setLoading(false);
      })
      .catch((err) => {
        swal({
          title: err.response?.data || err,
          icon: "error",
          buttons: "OK",
        });
        console.log(err.response?.data || err);
      });
  }, [memberId]);
  const validate = () => {
    let temp = {};

    // Validation for Book Type
    temp.staticBookType =
      booksValues.staticBookType && booksValues.staticBookType.label
        ? ""
        : t("member_book_type_error_message");

    // Validation for complition date
    temp.completedAt = completedAtDate
      ? ""
      : t("member_book_date_error_message");
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleChangeBookType = (record) => {
    if (record === null) {
      setBooksValues({
        ...booksValues,
        staticBookType: {},
      });
    } else {
      setBooksValues({
        ...booksValues,
        staticBookType: record,
      });
    }
    setErrors({
      staticBookType: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      booksValues.completedAt = convertToDateString(completedAtDate);
      ApiBooks()
        .addBook(booksValues)
        .then((res) => {
          swal({
            title: t("book_add_book_success_message"),
            icon: "success",
            buttons: "OK",
          });
          toggle();
          setRefresh(!refresh);
          setBooks(res.data);
          setLoading(false);
        })
        .catch((err) => {
          toggle();
          swal({
            title: err,
            text: err.response.data,
            icon: "error",
            buttons: "OK",
          });
          console.log(err);
          setLoading(false);
        });
    }
  };

  const handleInputChangeDate = (value) => {
    if (value !== null) {
      setCompletedAtDate(value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        completedAt: "",
      }));
    } else {
      setCompletedAtDate(null);
      setErrors((prevErrors) => ({
        ...prevErrors,
        completedAt: t("member_book_date_error_message"),
      }));
    }
  };

  const handleInputChangeRemarks = (e) => {
    const value = e.target.value;
    setBooksValues((prevValues) => ({
      ...prevValues,
      remarks: value,
    }));
  };

  const handleInputChangeReference = (e) => {
    const value = e.target.value;
    setBooksValues((prevValues) => ({
      ...prevValues,
      referenceNote: value,
    }));
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="settings_add_books" />}
          </ModalHeader>
          {!loading ? (
            <Form onSubmit={handleSubmit}>
              <ModalBody>
                <FormGroup>
                  <Label>{<GetLanguageString props="book_type" />}</Label>
                  <Select
                    value={booksValues.staticBookType}
                    options={booksDropdown}
                    className="basic-single ur no-drag"
                    classNamePrefix="select"
                    onChange={handleChangeBookType}
                  />
                  {errors.staticBookType && (
                    <h4 className="text-error">{t(errors.staticBookType)}</h4>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="col-form-label">
                    {
                      <GetLanguageString props="member_member_detail_books_date" />
                    }
                  </Label>
                  <DatePicker
                    showYearDropdown
                    placeholderText="dd/mm/yyyy"
                    className="form-control no-drag"
                    maxDate={new Date()}
                    selected={completedAtDate}
                    onChange={handleInputChangeDate}
                    dateFormat="dd/MM/yyyy"
                  />
                  {errors.completedAt && (
                    <h4 className="text-error">{errors.completedAt}</h4>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="col-form-label">
                    {<GetLanguageString props="common_remarks" />}
                  </Label>
                  <Input
                    className={
                      GetSelectedLanguage() === "en" ? "no-drag" : "ur no-drag"
                    }
                    type="text"
                    name="remarks"
                    value={booksValues.remarks}
                    onChange={handleInputChangeRemarks}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="col-form-label">
                    {<GetLanguageString props="member_reference_note" />}
                  </Label>
                  <Input
                    className={
                      GetSelectedLanguage() === "en" ? "no-drag" : "ur no-drag"
                    }
                    type="text"
                    name="referenceNote"
                    value={booksValues.referenceNote}
                    onChange={handleInputChangeReference}
                  />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="white" onClick={toggle}>
                  {<GetLanguageString props="common_cancel" />}
                </Button>
                <Button color="primary" type="submit" onClick={handleSubmit}>
                  {<GetLanguageString props="common_save_changes" />}
                </Button>
              </ModalFooter>
            </Form>
          ) : (
            LoadingSpinner()
          )}
        </Modal>
      </Draggable>
    </div>
  );
};

export default AddBook;
