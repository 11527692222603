import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup,
  Form,
  Button,
  Row,
  Col,
} from "reactstrap";
import { GetLanguageString } from "../../helper/Components";
import {
  LoadingSpinner,
  convertToDateString,
  convertUTCToLocalTime,
  isUrdu,
} from "../../../constants/const";
import DatePicker from "react-datepicker";
import { ApiMudaris } from "./ApiMudaris";
import swal from "sweetalert";
import { GetSelectedLanguage } from "../../helper/Method";
import { useTranslation } from "react-i18next";
import Draggable from "react-draggable";

const MemberMudarisEditAssessment = ({
  setEditAssessmentModal,
  assessmentId,
  mudarisId,
  setUpdate,
  update,
}) => {
  const [loading, setLoading] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [assessmentTypes, setAssessmentTypes] = useState([]);
  const [assessmentStatuses, setAssessmentStatuses] = useState([]);
  const [assessmentDate, setAssessmentDate] = useState(null);
  const [assessmentData, setAssessmentData] = useState({
    mudarisId: mudarisId,
    obtainedMarks: "",
    comments: "",
    referenceNote: "",
    assessmentType: null,
    assessmentStatus: null,
    date: null,
    id: 0,
  });
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  const [errors, setErrors] = useState(assessmentData);
  const toggle = () => setEditAssessmentModal(false);

  const { t } = useTranslation();

  const validate = () => {
    let temp = {};
    temp.date =
      assessmentDate !== null ? (
        ""
      ) : (
        <GetLanguageString props="member_member_detail_mudaris_details_assessments_date_error" />
      );
    temp.assessmentType =
      assessmentData.assessmentType === null ? (
        <GetLanguageString props="member_member_detail_mudaris_details_assessments_add_assessment_type_error" />
      ) : (
        ""
      );
    temp.assessmentStatus =
      assessmentData.assessmentStatus === null ? (
        <GetLanguageString props="member_member_detail_mudaris_details_assessments_add_assessment_status_error" />
      ) : (
        ""
      );

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  useEffect(() => {
    setLoading(true);
    ApiMudaris()
      .EditMudarisAssessment(assessmentId)
      .then((res) => {
        setAssessmentData({
          ...res.data,
          date: res.data.date
            ? new Date(convertUTCToLocalTime(res.data.date))
            : null,
        });

        setAssessmentDate(
          res.data.date
            ? new Date(convertUTCToLocalTime(res.data.date))
            : null
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
        setLoading(false);
      });

    ApiMudaris()
      .getAssessmentTypes()
      .then((res) => {
        setAssessmentTypes(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
        setLoading(false);
      });
  }, [assessmentId]);

  useEffect(() => {
    if (assessmentData.assessmentType?.value !== undefined) {
      ApiMudaris()
        .getAssessmentStatuses(assessmentData.assessmentType?.value)
        .then((res) => {
          setAssessmentStatuses(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [assessmentData.assessmentType]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAssessmentData({
      ...assessmentData,
      [name]:
        name === "obtainedMarks"
          ? value !== null
            ? parseInt(value)
            : value
          : value,
    });
  };

  const handleChangeSelectType = (record, name) => {
    if (name === "assessmentType") assessmentData.assessmentStatus = null;
    setAssessmentData({
      ...assessmentData,
      [name]: record,
    });
    setErrors({
      ...errors,
      [name]: null,
    });
  };
  const handleInputChangeDate = (value) => {
    if (value !== null) {
      if (value <= new Date()) {
        setAssessmentDate(value);
        setErrors({
          ...errors,
          date: "",
        });
      } else {
        setErrors({
          ...errors,
          date: <GetLanguageString props="tutorial_date_error_message" />,
        });
      }
    } else {
      setAssessmentDate(null);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      setButtonDisable(true);
      assessmentData.date = convertToDateString(assessmentDate);
      ApiMudaris()
        .UpdateMudarisAssessment(assessmentData)
        .then((res) => {
          if (res.data === "") {
            swal({
              title: t(
                "member_member_detail_mudaris_details_assessments_add_assessment_update_success"
              ),
              className: GetSelectedLanguage() === "en" ? "" : "ur",
              icon: "success",
              buttons: "OK",
            });
            setLoading(false);
            setButtonDisable(false);
            setUpdate(!update);
            toggle();
          }
        })
        .catch((err) => {
          console.log(err);
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
          setLoading(false);
          setButtonDisable(false);
        });
    }
  };
  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {
              <GetLanguageString props="member_member_detail_mudaris_details_assessments_edit_assessment" />
            }
          </ModalHeader>
          {!loading ? (
            <Form onSubmit={handleSubmit}>
              <ModalBody>
                <FormGroup>
                  <Label className="col-form-label">
                    {
                      <GetLanguageString props="member_member_detail_mudaris_details_assessments_add_assessment_type" />
                    }
                  </Label>
                  <Select
                    value={assessmentData.assessmentType}
                    options={assessmentTypes}
                    className="basic-single ur no-drag"
                    classNamePrefix="Select"
                    placeholder=" قسم منتخب کریں"
                    onChange={(record) => {
                      handleChangeSelectType(record, "assessmentType");
                    }}
                  />
                  {errors.assessmentType ? (
                    <h4 className="text-error">{errors.assessmentType}</h4>
                  ) : null}
                </FormGroup>
                <FormGroup>
                  <Label className="col-form-label no-drag">
                    {
                      <GetLanguageString props="member_member_detail_mudaris_details_assessments_add_assessment_status" />
                    }
                  </Label>
                  <Select
                    value={assessmentData.assessmentStatus}
                    options={assessmentStatuses}
                    className="basic-single ur no-drag"
                    isDisabled={assessmentStatuses.length === 0}
                    classNamePrefix="select"
                    placeholder=" کیفیت منتخب کریں"
                    onChange={(record) => {
                      handleChangeSelectType(record, "assessmentStatus");
                    }}
                  />
                  {errors.assessmentStatus ? (
                    <h4 className="text-error">{errors.assessmentStatus}</h4>
                  ) : null}
                </FormGroup>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label className="col-form-label">
                        {
                          <GetLanguageString props="member_member_detail_mudaris_details_assessments_date" />
                        }
                      </Label>
                      <DatePicker
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="dd/mm/yyyy"
                        className="form-control no-drag"
                        selected={assessmentDate}
                        maxDate={new Date()}
                        onChange={handleInputChangeDate}
                        dateFormat="dd/MM/yyyy"
                      />
                      {errors.date ? (
                        <h4 className="text-error">{errors.date}</h4>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="m-1">
                      <Label>
                        {
                          <GetLanguageString props="member_member_detail_mudaris_details_assessments_obtained_marks" />
                        }
                      </Label>
                      <Input
                        type="number"
                        name="obtainedMarks"
                        className="no-drag"
                        value={assessmentData.obtainedMarks}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Label>
                    {
                      <GetLanguageString props="member_member_detail_course_comments" />
                    }
                  </Label>
                  <Input
                    type="text"
                    name="comments"
                    className={
                      isUrdu(assessmentData.comments) ? "ur no-drag" : "no-drag"
                    }
                    value={assessmentData.comments}
                    onChange={handleInputChange}
                  />
                </FormGroup>{" "}
                <FormGroup>
                  <Label>
                    {<GetLanguageString props="member_reference_note" />}
                  </Label>
                  <Input
                    type="text"
                    name="referenceNote"
                    className={
                      isUrdu(assessmentData.referenceNote)
                        ? "ur no-drag"
                        : "no-drag"
                    }
                    value={assessmentData.referenceNote}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="white" onClick={toggle}>
                  {<GetLanguageString props="common_cancel" />}
                </Button>
                <Button color="primary" type="submit" disabled={buttonDisable}>
                  {<GetLanguageString props="common_save_changes" />}
                </Button>
              </ModalFooter>
            </Form>
          ) : (
            LoadingSpinner()
          )}
        </Modal>
      </Draggable>
    </div>
  );
};

export default MemberMudarisEditAssessment;
