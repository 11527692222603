import React, { useEffect, useState } from "react";
import { Table, Button, Label, Input, Row, Col } from "reactstrap";
import { withRouter, useLocation } from "react-router-dom";

import { GetLanguageString, NoResult } from "../helper/Components";
import { GetCustomStyles } from "../helper/Method";
import { ApiEvent } from "./ApiEvent";
import { requestApi } from "../../actions/api";
import { LoadingSpinner, convertUTCToLocalTime } from "../../constants/const";
import UpdateBook from "../books/UpdateBook";
import moment from "moment";

const EventInChargeAndResponsibleParticipants = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [updateModal, setUpdateModal] = useState(false);
  const [updateValue, setUpdateValue] = useState();
  const eventId = location.state.eventId;
  const unitId = location.state.eventUnitId;
  const unitName = location.state.unitName;
  const eventAddress = location.state.eventAddress;
  const eventDate = location.state.eventDate;
  const eventType = location.state.eventType;
  let absent = false;
  let present = false;
  let leave = false;
  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    setLoading(true);
    ApiEvent()
      .getInChargeAndResponsibleEventParticipants(unitId, eventId)
      .then((res) => {
        setParticipants(res.data);
        console.log(res.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [eventId, unitId]);

  const handleAttendanceStatus = (status, index) => {
    let tempValues = [...participants];
    tempValues[index].attendanceStatus = status;
    setParticipants(tempValues);
  };

  const handleOnTime = (e, index) => {
    const { checked } = e.target;
    let tempValues = [...participants];
    tempValues[index].onTime = checked;
    setParticipants(tempValues);
  };

  const handleSubmit = () => {
    setLoading(true);
    requestApi("eventparticipant/")
      .create({
        eventParticipantsModelList: participants,
      })
      .then((res) => {
        history.goBack();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleOnBookUpdate = (e) => {
    const { value } = e.target;
    setUpdateValue(value);
    setUpdateModal(!updateModal);
  };

  return (
    <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
      {updateModal ? (
        <UpdateBook memberId={updateValue} setUpdateModal={setUpdateModal} />
      ) : null}
      <div className="ibox">
        <div className="ibox-title">
          <h3>
            {<GetLanguageString props="events_participants_events_details" />}
          </h3>
          <Button
            color="primary"
            size="sm"
            className={GetCustomStyles().btn_style}
            onClick={() => history.goBack()}
          >
            {<GetLanguageString props="courses_participants_back" />}
          </Button>
        </div>
        <div className="ibox-content">
          <Row>
            <Col md="6">
              <Row>
                <h4 className="col-4 text-right">
                  {<GetLanguageString props="events_event_type" />} :
                </h4>
                <h4 className="col-7 ur">{eventType}</h4>
              </Row>
              <Row>
                <h4 className="col-4 text-right">
                  {<GetLanguageString props="events_address" />} :
                </h4>
                <h4 className="col-7">{eventAddress}</h4>
              </Row>
            </Col>
            <Col md="6">
              <Row>
                <h4 className="col-4 text-right">
                  {<GetLanguageString props="events_unit" />} :
                </h4>
                <h4 className="col-7 ur">{unitName}</h4>
              </Row>
              <Row>
                <h4 className="col-4 text-right">
                  {<GetLanguageString props="events_date" />} :
                </h4>
                <h4 className="col-7">
                  {moment(convertUTCToLocalTime(eventDate)).format("DD/MM/yyyy  h:mm a")}
                </h4>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <div className="ibox ">
        <div className="ibox-title">
          <h5>
            <GetLanguageString
              props="event_incharge_and_responsible_event_participants"
              value={unitName}
            />
          </h5>
        </div>
        <div className="ibox-content">
          {!loading ? (
            participants.length !== 0 ? (
              <>
                <div className="table-responsive">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>{<GetLanguageString props="common_name" />}</th>
                        <th className="text-center">
                          {<GetLanguageString props="settings_member_type" />}
                        </th>
                        <th className="text-center">
                          {<GetLanguageString props="unit_unit_table" />}
                        </th>
                        <th className="text-center">
                          {
                            <GetLanguageString props="settings_responsibilities" />
                          }
                        </th>
                        <th className="text-center">
                          {<GetLanguageString props="common_attendance" />}
                        </th>
                        <th>
                          {
                            <GetLanguageString props="events_participants_time_status" />
                          }
                        </th>
                        <th className="text-center">
                          {<GetLanguageString props="common_action" />}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {participants?.map((record, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td className="ur">{record.name}</td>
                            <td className="text-center ur">
                              {record.memberType}
                            </td>
                            <td className="text-center">
                              {record.memberUnitNames.map((item, index) => {
                                return (
                                  <Label
                                    key={index}
                                    className="label label-primary m-r"
                                  >
                                    <h5 className="ur">{item}</h5>
                                  </Label>
                                );
                              })}
                            </td>
                            <td className="text-center">
                              {record.responsibilities.map((item, index) => {
                                return (
                                  <Label
                                    key={index}
                                    className="label label-success m-r"
                                  >
                                    <h5 className="ur">{item}</h5>
                                  </Label>
                                );
                              })}
                            </td>
                            <td className="text-center">
                              <Label
                                className="form-check form-check-inline"
                                for={"absent" + record.memberId}
                              >
                                {record.attendanceStatus === 0
                                  ? (absent = true)
                                  : null}
                                <Input
                                  id={"absent" + record.memberId}
                                  type="radio"
                                  checked={absent}
                                  name={"status" + record.memberId}
                                  onChange={() =>
                                    handleAttendanceStatus(0, index)
                                  }
                                  className="form-check-input"
                                />
                                {
                                  <GetLanguageString props="events_participants_attendance_absent" />
                                }
                              </Label>
                              <Label
                                className="form-check form-check-inline"
                                for={"present" + record.memberId}
                              >
                                {record.attendanceStatus === 1
                                  ? (present = true)
                                  : null}
                                <Input
                                  id={"present" + record.memberId}
                                  type="radio"
                                  checked={present}
                                  name={"status" + record.memberId}
                                  onChange={() =>
                                    handleAttendanceStatus(1, index)
                                  }
                                  className="form-check-input"
                                />
                                {
                                  <GetLanguageString props="events_participants_attendance_present" />
                                }
                              </Label>
                              <Label
                                className="form-check form-check-inline"
                                for={"leave" + record.memberId}
                              >
                                {record.attendanceStatus === 2
                                  ? (leave = true)
                                  : null}
                                <Input
                                  id={"leave" + record.memberId}
                                  checked={leave}
                                  type="radio"
                                  name={"status" + record.memberId}
                                  onChange={() =>
                                    handleAttendanceStatus(2, index)
                                  }
                                  className="form-check-input"
                                />
                                {
                                  <GetLanguageString props="events_participants_attendance_leave" />
                                }
                              </Label>
                            </td>
                            <td>
                              <div className="switch">
                                <div className="onoffswitch">
                                  <Input
                                    id={"onTime" + record.memberId}
                                    checked={record.onTime}
                                    type="checkbox"
                                    name={"onTime" + record.memberId}
                                    onChange={(e) => handleOnTime(e, index)}
                                    className="onoffswitch-checkbox"
                                    disabled={
                                      record.attendanceStatus === 1
                                        ? false
                                        : true
                                    }
                                  />
                                  <Label
                                    className="onoffswitch-label"
                                    for={"onTime" + record.memberId}
                                  >
                                    <span className="onoffswitch-inner"></span>
                                    <span className="onoffswitch-switch"></span>
                                  </Label>
                                </div>
                              </div>
                            </td>
                            <td className="text-center">
                              <Button
                                color="info"
                                size="sm"
                                onClick={handleOnBookUpdate}
                                value={record.memberId}
                              >
                                {
                                  <GetLanguageString props="book_update_update_books" />
                                }
                              </Button>
                            </td>
                            {
                              ((absent = false),
                              (present = false),
                              (leave = false))
                            }
                          </tr>
                        );
                      })}
                    </tbody>
                    <tr>
                      <td colSpan="8">
                        <ul className={GetCustomStyles().btn_style}>
                          <Button
                            color="primary"
                            onClick={() => handleSubmit()}
                          >
                            {<GetLanguageString props="common_save_changes" />}
                          </Button>
                        </ul>
                      </td>
                    </tr>
                  </Table>
                </div>
              </>
            ) : (
              <NoResult />
            )
          ) : (
            LoadingSpinner()
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(EventInChargeAndResponsibleParticipants);
