import axios from "axios";

import { BASEURL } from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";

export function ApiTutorials() {
  const userToken = encryptStorage.getItem("token");

  const authAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const url = BASEURL + "videoTutorials/";
  return {
    getTutorials: () =>
      authAxios.get(url + "GetTutorials"),

    searchTutorialsModel: (formData) =>
    authAxios.post(url + "SearchTutorialsModel", formData),

    addTutorials: (formData) => 
      authAxios.post(url + "AddTutorials", formData),

    editTutorials: (id, formData) =>
      authAxios.put(url + `UpdateTutorials/${id}`, formData),

    deleteTutorials: (id) => 
      authAxios.delete(url + `DeleteTutorials/${id}`),
      
    getCategory: () =>
      authAxios.get(url + "GetCategory"),

    addCategory: (formData) => 
      authAxios.post(url + "AddCategory", formData),
  };
}
