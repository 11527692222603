import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Col, Row, Spinner, Table } from "reactstrap";

import { useTranslation } from "react-i18next";
import { BiMinus, BiSolidEdit } from "react-icons/bi";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import swal from "sweetalert";
import { requestApi } from "../../actions/api";
import { LoadingSpinner, convertUTCToLocalTime } from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";
import { GetLanguageString, NoResult } from "../helper/Components";
import { GetCustomStyles, GetSelectedLanguage } from "../helper/Method";
import { ApiCourse } from "./ApiCourse";
import EditParticipant from "./EditParticipant";

const ParticipantsList = ({
  ignoredRegNo,
  courseId,
  renderParticipants,
  setRenderParticipants,
  setIsPublished,
  history,
  isPublished,
}) => {
  const { t } = useTranslation();
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [participants, setParticipants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [renderDetails, setRenderDetails] = useState(false);
  const [publishCall, setPublishCall] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [course, setCourse] = useState({
    startDate: null,
    address: "",
    description: "",
    endDate: null,
    courseType: {},
  });
  const [editValue, setEditValues] = useState({
    comments: "",
    status: "",
    id: 0,
  });

  useEffect(() => {
    setLoading(true);
    ApiCourse()
      .getCourseParticipants(courseId, loggedInUnitId)
      .then((res) => {
        setParticipants(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [courseId, loggedInUnitId, renderParticipants]);

  useEffect(() => {
    setDetailsLoading(true);
    requestApi("course/")
      .fetchById(courseId)
      .then((res) => {
        setCourse(res.data);
        setIsPublished(res.data.isPublished);
        setDetailsLoading(false);
      })
      .catch((err) => {
        setDetailsLoading(false);
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId, renderDetails, renderParticipants]);

  const handleOnEdit = (comments, status, id) => {
    setEditValues({
      comments: comments,
      status: status,
      id: id,
    });
    setEditModal(true);
  };
  const handleOnDelete = (value) => {
    ApiCourse()
      .deleteCourseParticipant(value, courseId)
      .then((res) => {
        setRenderParticipants(!renderParticipants);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePublish = (flag) => {
    setPublishCall(true);
    ApiCourse()
      .ChangeCourseStatus(courseId, flag)
      .then((res) => {
        setRenderDetails(!renderDetails);
        setPublishCall(false);
        swal({
          title: res.data
            ? t("courses_published_successfully")
            : t("courses_unpublished_successfully"),
          icon: "success",
          className: GetSelectedLanguage() === "en" ? "" : "ur",
          buttons: "OK",
        });
        setRenderParticipants(!renderParticipants);
      })
      .catch((err) => {
        console.log(err);
        setPublishCall(false);
      });
  };

  return (
    <div>
      <>
        {editModal ? (
          <EditParticipant
            setEditModal={setEditModal}
            editValue={editValue}
            setLoading={setLoading}
            renderParticipants={renderParticipants}
            setRenderParticipants={setRenderParticipants}
          />
        ) : null}
      </>
      <div className="ibox">
        <div className="ibox-title">
          <h3>
            {<GetLanguageString props="courses_participants_course_details" />}
          </h3>
          {isPublished ? (
            <Button
              className={GetCustomStyles().unit_btn_style}
              color="primary"
              size="xs"
              onClick={() => history.goBack()}
            >
              {<GetLanguageString props="courses_participants_back" />}
            </Button>
          ) : null}
        </div>
        <div className="ibox-content">
          {!detailsLoading ? (
            <Row>
              <Col md="6">
                <Row>
                  <h4 className="col-4 text-right">
                    {<GetLanguageString props="common_name" />} :
                  </h4>
                  <h4 className="col-7 ur">{course.courseType.label}</h4>
                </Row>
                <Row>
                  <h4 className="col-4 text-right">
                    {<GetLanguageString props="courses_address" />} :
                  </h4>
                  <h4 className="col-7 ur">
                    {course.address !== null && course.address !== ""
                      ? course.address
                      : course.courseLocation?.label}
                  </h4>
                </Row>
                <Row>
                  <h4 className="col-4 text-right">
                    {
                      <GetLanguageString props="course_participants_total_participants" />
                    }{" "}
                    :
                  </h4>
                  <h4 className="col-7">{course.totalParticipants}</h4>
                </Row>
                <Row>
                  <h4 className="col-4 text-right">
                    {
                      <GetLanguageString props="courses_participants_course_status" />
                    }{" "}
                    :
                  </h4>
                  <h4
                    className="col-7"
                    style={{ color: course.isPublished ? "green" : "red" }}
                  >
                    {course.isPublished ? (
                      <GetLanguageString props="courses_published" />
                    ) : (
                      <GetLanguageString props="courses_not_published" />
                    )}
                  </h4>
                </Row>
              </Col>
              <Col md="6">
                <Row>
                  <h4 className="col-4 text-right">
                    {<GetLanguageString props="courses_start_date" />} :
                  </h4>
                  <h4 className="col-7">
                    {course.startDate
                      ? moment(convertUTCToLocalTime(course.startDate)).format(
                          "DD/MM/yyyy"
                        )
                      : "-"}
                  </h4>
                </Row>
                <Row>
                  <h4 className="col-4 text-right">
                    {<GetLanguageString props="courses_end_date" />} :
                  </h4>
                  <h4 className="col-7">
                    {course.endDate
                      ? moment(convertUTCToLocalTime(course.endDate)).format(
                          "DD/MM/yyyy"
                        )
                      : "-"}
                  </h4>
                </Row>
                <Row>
                  <h4 className="col-4 text-right">
                    {
                      <GetLanguageString props="course_participants_total_completed_participants" />
                    }{" "}
                    :
                  </h4>
                  <h4 className="col-7">{course.totalPartcipantsCompleted}</h4>
                </Row>
              </Col>
            </Row>
          ) : (
            LoadingSpinner()
          )}
        </div>
      </div>
      <div className="ibox ">
        <div className="ibox-title">
          <Row>
            <Col md="6">
              <h5>{<GetLanguageString props="courses_participants_list" />}</h5>
              <h5>{ignoredRegNo}</h5>
            </Col>
            <Col md="6">
              {course.isPublished ? (
                <Button
                  color="danger"
                  size="sm"
                  disabled={publishCall}
                  className={GetCustomStyles().unit_btn_style}
                  onClick={() => {
                    handlePublish(false);
                  }}
                >
                  {publishCall ? (
                    <Spinner size="sm" color="white" />
                  ) : (
                    <GetLanguageString props="courses_unpublish" />
                  )}
                </Button>
              ) : (
                <Button
                  color="primary"
                  size="sm"
                  disabled={publishCall}
                  className={GetCustomStyles().unit_btn_style}
                  onClick={() => {
                    handlePublish(true);
                  }}
                >
                  {publishCall ? (
                    <Spinner size="sm" color="white" />
                  ) : (
                    <GetLanguageString props="courses_publish" />
                  )}
                </Button>
              )}
            </Col>
          </Row>
        </div>
        <div className="ibox-content">
          {!loading ? (
            participants.length !== 0 ? (
              <div className="table-responsive">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{<GetLanguageString props="common_name" />}</th>
                      <th>{<GetLanguageString props="member_reg_number" />}</th>
                      <th>
                        {<GetLanguageString props="settings_member_type" />}
                      </th>
                      <th>{<GetLanguageString props="unit_unit_table" />}</th>
                      <th>
                        {
                          <GetLanguageString props="member_member_detail_course_comments" />
                        }
                      </th>
                      <th>
                        {
                          <GetLanguageString props="courses_participants_course_status" />
                        }
                      </th>
                      {!course.isPublished ? (
                        <>
                          <th></th>
                          <th></th>
                        </>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>
                    {participants?.map((record, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="ur">{record.fullName}</td>
                          <td>{record.regNo}</td>
                          <td className="ur">{record.memberType}</td>
                          <td>
                            <Button
                              size="xs"
                              color="info"
                              className="label label-info m-r-xs"
                            >
                              <h5 className="ur">{record.unitName}</h5>
                            </Button>
                          </td>
                          <td className="ur">{record.courseComments}</td>
                          <td>
                            {record.courseStatus ? (
                              <Button
                                size="xs"
                                color="primary"
                                className="label label-primary m-r-xs"
                              >
                                {t("courses_status_completed")}
                              </Button>
                            ) : (
                              <Button
                                size="xs"
                                color="warning"
                                className="label label-warning m-r-xs"
                              >
                                {t("courses_status_incompleted")}
                              </Button>
                            )}
                          </td>
                          {!course.isPublished ? (
                            <>
                              <td>
                                <BiSolidEdit
                                  size={25}
                                  color={"green"}
                                  onClick={() =>
                                    handleOnEdit(
                                      record.courseComments,
                                      record.courseStatus,
                                      record.courseParticipantId
                                    )
                                  }
                                  style={{
                                    marginRight: 3,
                                    marginBottom: 1,
                                    cursor: "pointer",
                                  }}
                                />
                              </td>
                              <td>
                                <BiMinus
                                  size={30}
                                  color={"red"}
                                  onClick={() => handleOnDelete(record.id)}
                                  style={{
                                    marginRight: 3,
                                    marginBottom: 1,
                                    cursor: "pointer",
                                  }}
                                />
                              </td>
                            </>
                          ) : null}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            ) : (
              <NoResult />
            )
          ) : (
            LoadingSpinner()
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(ParticipantsList);
