import React, { useState, useEffect } from "react";
import Select from "react-select";
import { 
  Form, 
  Row, 
  Col, 
  FormGroup, 
  Label, 
  Input, 
  Button
} from "reactstrap";

import { GetLanguageString } from "../../../helper/Components";
import { encryptStorage } from "../../../../constants/EncryptStorage";
import { ApiUnit } from "../../../unit/ApiUnit";

const SearchMembers = ({
  unitId,
  setUnit,
  searchValues,
  setSearchValues,
  setSearchButton,
}) => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [unitList, setUnitList] = useState([]);
  const [excludeExUnit, setExcludeExUnit] = useState(true);
  const unitTypeName = "null";
  const [values, setValues] = useState(searchValues);


  useEffect(() => {
    ApiUnit()
      .getUnitList(unitId, loggedInUnitId, false, unitTypeName, excludeExUnit)
      .then((res) => {
        setUnitList(res.data);
      })
      .catch((err) => console.log(err));
  }, [unitId, loggedInUnitId, excludeExUnit]);

  const handleChangeUnits = (record) => {
    if (record !== null) {
      ApiUnit()
        .getUnitList(record.value, loggedInUnitId, false, unitTypeName, excludeExUnit)
        .then((res) => {
          setUnitList(res.data);
        })
        .catch((err) => console.log(err));

      setUnit({ 
        unitId: record.value,
        unitName: record.label
      });
      
      setValues({ 
        ...values, 
        unitId: record.value 
      });
    }
  };

  const handleSubUnits = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setValues({
      ...values,
      isSubUnits: v,
    });
  };

  const handleExcludeExUnit = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setExcludeExUnit(v);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (values.unitId === 0) values.unitId = unitId;
    setSearchButton(true);
    setSearchValues(values);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col md="6" xl="4">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="member_search_by_name" />}
            </Label>
            <Input
              className="ur"
              type="text"
              name="name"
              value={values.name}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
        <Col md="6" xl="4">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="member_search_by_regno" />}
            </Label>
            <Input
              type="text"
              name="regNo"
              value={values.regNo}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        {unitList.map((record, index) => {
          const unitOptions = [];
          record.unitList.forEach((d) =>
            unitOptions.push({ value: d.id, label: d.name, unitTypeName: d.unitTypeName })
          );
          let selected = {};
          let unitName = "";
          if (record.parentUnitName !== null) unitName = record.parentUnitName;
          if (record.selectedUnit !== null) {
            selected = {
              value: record.selectedUnit.id,
              label: record.selectedUnit.name,
            };
          }

          return (
            <Col md="2" key={index}>
              <FormGroup>
                <Label className="col-form-label">
                  {unitName !== "" ? (
                    <GetLanguageString
                      props="member_select_unit_name"
                      value={unitName}
                    />
                  ) : (
                    <GetLanguageString props="member_select_unit" />
                  )}
                </Label>
                <Select
                  options={unitOptions}
                  value={selected}
                  className="basic-single ur"
                  classNamePrefix="select"
                  onChange={handleChangeUnits}
                />
              </FormGroup>
            </Col>
          );
        })}
      </Row>
      <Row>
        {loggedInUnitId === 1 && 
          <Col md="2">
          <FormGroup check>
            <Label check for="includeExUnit">
              <Input
                id="includeExUnit"
                type="checkbox"
                name="includeExUnit"
                checked={!excludeExUnit}
                value={!excludeExUnit}
                onChange={handleExcludeExUnit}
              />
              {<GetLanguageString props="member_search_member_ex_unit" />}
            </Label>
          </FormGroup>
          </Col>
        }
        <Col md="2">
          <FormGroup check>
            <Label check for="subUnit">
              <Input
                id="subUnit"
                type="checkbox"
                name="isSubUnits"
                checked={values.isSubUnits}
                value={!values.isSubUnits}
                onChange={handleSubUnits}
              />
              {<GetLanguageString props="member_search_member_sub_unit" />}
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <FormGroup>
            <Button color="primary" className="m-l-sm" type="submit">
              {<GetLanguageString props="common_search" />}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default SearchMembers;
