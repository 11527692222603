import React, { useState, useEffect } from "react";
import Select from "react-select";

import useForm from "../../shared/useForm";
import { ApiUnit } from "../../unit/ApiUnit";
import {
  Button,
  ButtonGroup,
  Row,
  Col,
  FormGroup,
  Form,
  Label,
  Table,
  Input,
} from "reactstrap";
import { saveAs } from "file-saver";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { GetLanguageString, SetReportTitle } from "../../helper/Components";
import { GetCustomStyles } from "../../helper/Method";
import { LoadingSpinner } from "../../../constants/const";
import { encryptStorage } from "../../../constants/EncryptStorage";
import { FcPrint } from "react-icons/fc";
import { RiFileExcel2Line } from "react-icons/ri";

const UnitMemberCountReport = () => {
  const [loading, setLoading] = useState(false);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [reportTitle, setReportTitle] = useState("");
  const [modal, setModal] = useState(false);
  const [excludeExUnit, setExcludeExUnit] = useState(true);
  const [date, setDate] = useState(new Date());
  const [unitMemberCounts, setUnitMemberCounts] = useState([]);
  const [unitType, setUnitType] = useState(
    encryptStorage.getItem("unitTypeName")
  );
  const [showAllUnits, setShowAllUnits] = useState("primary");
  const [showAllZones, setShowAllZones] = useState("white");
  const [showAllHalqas, setShowAllHalqas] = useState("white");
  const [showAllTanzeem, setShowAllTanzeem] = useState("white");
  const [showAllUsra, setShowAllUsra] = useState("white");
  const [searchButton, setSearchButton] = useState(false);
  const [columnNames, setColumnNames] = useState([]);
  const [searchValue, setSearchValue] = useState({
    unitId: loggedInUnitId,
    date: new Date(),
    showSelectedUnitsCheck: "None",
    excludeExUnit: true,
  });
  const unitTypeName = "null";
  let totalCount = {
    totalMemberCount: 0,
    multazimCount: 0,
    mutazirCount: 0,
    mubtadiCount: 0,
    tawajahTalabCount: 0,
    naqeebCount: 0,
    muqamiAmeerCount: 0,
    ameerHalqaCount: 0,
    muqamiMuavinCount: 0,
    markaziMuavinCount: 0,
    halqaJatiMuavinCount: 0,
  };

  const [unitList, setUnitList] = useState([]);
  const { values, setValues } = useForm(searchValue);

  const customStyles = {
    border: "2px solid black",
    fontWeight: "bold",
  };

  useEffect(() => {
    ApiUnit()
      .getUnitList(
        loggedInUnitId,
        loggedInUnitId,
        false,
        unitTypeName,
        excludeExUnit,
        date.toISOString()
      )
      .then((res) => {
        setUnitList(res.data);
      })
      .catch((err) => console.log(err));
  }, [loggedInUnitId, excludeExUnit, date]);

  const handleChangeUnits = (record) => {
    if (record !== null) {
      ApiUnit()
        .getUnitList(
          record.value,
          loggedInUnitId,
          false,
          unitTypeName,
          excludeExUnit
        )
        .then((res) => {
          setUnitList(res.data);
        })
        .catch((err) => console.log(err));
      setValues({
        ...values,
        unitId: record.value,
        showSelectedUnitsCheck: "None",
      });
      setShowAllZones("white");
      setShowAllUnits("primary");
      setShowAllHalqas("white");
      setShowAllTanzeem("white");
      setShowAllUsra("white");
      setUnitType(record.label1);
    } else {
      ApiUnit()
        .getUnitList(
          loggedInUnitId,
          loggedInUnitId,
          false,
          unitTypeName,
          excludeExUnit
        )
        .then((res) => {
          setUnitList(res.data);
        })
        .catch((err) => console.log(err));
      setValues({
        ...values,
        unitId: 0,
      });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setSearchButton(true);
    setSearchValue(values);
  };

  useEffect(() => {
    if (searchValue && searchButton) {
      setLoading(true);
      ApiUnit()
        .getChildUnitMembersCount(searchValue)
        .then((res) => {
          setUnitMemberCounts(res.data);
          if (res.data.length > 0) setColumnNames(res.data[0].columnNames);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  }, [searchValue, searchButton]);

  useEffect(() => {
    if (reportTitle !== "") window.print();
  }, [reportTitle]);

  const handleReportTitle = () => {
    setModal(true);
  };

  const excelDownload = () => {
    ApiUnit()
      .getChildUnitMembersCountReportExcel(unitMemberCounts, values.unitId)
      .then((res) => {
        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "MemberCounts.xlsx");
      })
      .catch((err) => console.log(err));
  };

  const handleShowSelectedUnitsCheck = (e) => {
    const { value } = e.target;
    if (value === "0") {
      setShowAllZones("white");
      setShowAllUnits("primary");
      setShowAllHalqas("white");
      setShowAllTanzeem("white");
      setShowAllUsra("white");
      setValues({
        ...values,
        showSelectedUnitsCheck: "None",
      });
    } else if (value === "1") {
      setShowAllZones("primary");
      setShowAllUnits("white");
      setShowAllHalqas("white");
      setShowAllTanzeem("white");
      setShowAllUsra("white");
      setValues({
        ...values,
        showSelectedUnitsCheck: "Zone",
      });
    } else if (value === "2") {
      setShowAllZones("white");
      setShowAllUnits("white");
      setShowAllHalqas("primary");
      setShowAllTanzeem("white");
      setShowAllUsra("white");
      setValues({
        ...values,
        showSelectedUnitsCheck: "Halqa",
      });
    } else if (value === "3") {
      setShowAllZones("white");
      setShowAllUnits("white");
      setShowAllHalqas("white");
      setShowAllTanzeem("primary");
      setShowAllUsra("white");
      setValues({
        ...values,
        showSelectedUnitsCheck: "Tanzeem",
      });
    } else if (value === "4") {
      setShowAllZones("white");
      setShowAllUnits("white");
      setShowAllHalqas("white");
      setShowAllTanzeem("white");
      setShowAllUsra("primary");
      setValues({
        ...values,
        showSelectedUnitsCheck: "Usra",
      });
    }
  };

  const handleExcludeExUnit = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setExcludeExUnit(v);
    setValues({
      ...values,
      excludeExUnit: v,
    });
  };

  return (
    <div>
      {modal ? (
        <SetReportTitle setModal={setModal} setReportTitle={setReportTitle} />
      ) : null}
      <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
        <div className="ibox noprint">
          <div className="ibox-title">
            <h5>{<GetLanguageString props="common_generate_report" />}</h5>
          </div>
          <div className="ibox-content">
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="report_member_select_date" />}
                    </Label>
                    <DatePicker
                      showYearDropdown
                      className="form-control"
                      selected={values.date}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          date: value,
                        });
                        setDate(value);
                      }}
                      dateFormat="dd/MM/yyyy"
                    />
                  </FormGroup>
                </Col>
                <Col md="4" style={{ paddingTop: 42 }}>
                  <FormGroup check>
                    <Label check for="excludeExUnit">
                      <Input
                        id="excludeExUnit"
                        type="checkbox"
                        name="excludeExUnit"
                        checked={!excludeExUnit}
                        value={!excludeExUnit}
                        onChange={handleExcludeExUnit}
                      />
                      {
                        <GetLanguageString props="member_search_member_ex_unit" />
                      }
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                {unitList.map((record, index) => {
                  const unitOptions = [];
                  record.unitList.forEach((d) =>
                    unitOptions.push({
                      value: d.id,
                      label: d.name,
                      label1: d.unitTypeName,
                    })
                  );
                  let selected = {};
                  let unitName = "";
                  if (record.parentUnitName !== null)
                    unitName = record.parentUnitName;
                  if (record.selectedUnit !== null) {
                    selected = {
                      value: record.selectedUnit.id,
                      label: record.selectedUnit.name,
                    };
                  }

                  return (
                    <Col md="2" key={index}>
                      <FormGroup>
                        <Label className="col-form-label">
                          {unitName !== "" ? (
                            <GetLanguageString
                              props="member_select_unit_name"
                              value={unitName}
                            />
                          ) : (
                            <GetLanguageString props="member_select_unit" />
                          )}
                        </Label>
                        <Select
                          options={unitOptions}
                          value={selected}
                          className="basic-single ur"
                          classNamePrefix="select"
                          onChange={handleChangeUnits}
                        />
                      </FormGroup>
                    </Col>
                  );
                })}
              </Row>
              <Row>
                <Col md="12">
                  <ButtonGroup
                    style={{ paddingTop: 22 }}
                    className="text-center"
                  >
                    {(unitType === "Markaz" ||
                      unitType === "Zone" ||
                      unitType === "Halqa") && (
                      <Button
                        color={showAllUnits}
                        value={0}
                        onClick={handleShowSelectedUnitsCheck}
                      >
                        {
                          <GetLanguageString props="report_member_show_all_units" />
                        }
                      </Button>
                    )}
                    {unitType === "Markaz" && (
                      <Button
                        color={showAllZones}
                        value={1}
                        onClick={handleShowSelectedUnitsCheck}
                      >
                        {<GetLanguageString props="report_member_show_zones" />}
                      </Button>
                    )}
                    {(unitType === "Markaz" || unitType === "Zone") && (
                      <Button
                        color={showAllHalqas}
                        value={2}
                        onClick={handleShowSelectedUnitsCheck}
                      >
                        {<GetLanguageString props="report_member_show_halqa" />}
                      </Button>
                    )}
                    {(unitType === "Markaz" ||
                      unitType === "Zone" ||
                      unitType === "Halqa") && (
                      <Button
                        color={showAllTanzeem}
                        value={3}
                        onClick={handleShowSelectedUnitsCheck}
                      >
                        {
                          <GetLanguageString props="report_member_show_tanzeem" />
                        }
                      </Button>
                    )}
                    {(unitType === "Markaz" ||
                      unitType === "Zone" ||
                      unitType === "Halqa") && (
                      <Button
                        color={showAllUsra}
                        value={4}
                        onClick={handleShowSelectedUnitsCheck}
                      >
                        {<GetLanguageString props="report_member_show_usra" />}
                      </Button>
                    )}
                  </ButtonGroup>
                </Col>
              </Row>
              <Row className="m-t-md">
                <Col className="text-center">
                  <FormGroup>
                    <Button color="primary" className="m-l-sm" type="submit">
                      {<GetLanguageString props="common_view_report" />}
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
        {searchButton ? (
          <div className="ibox">
            <div className="ibox-title">
              <ButtonGroup className={GetCustomStyles().btn_style}>
                <Button color="default" size="sm" onClick={excelDownload}>
                  <RiFileExcel2Line
                    size={15}
                    color="#009440"
                    style={{ marginRight: 3, marginBottom: 1 }}
                  />
                  {<GetLanguageString props="member_report_export_excel" />}
                </Button>
                <Button color="default" size="sm" onClick={handleReportTitle}>
                  <FcPrint size={15} className={GetCustomStyles().fa_style} />
                  {<GetLanguageString props="common_print" />}
                </Button>
              </ButtonGroup>
            </div>
            <div className="ibox-content ibox-center" id="section-to-print">
              <Row className="print">
                <div className="text-center">
                  <h3>Title : {reportTitle}</h3>
                </div>
              </Row>
              {!loading ? (
                <>
                  <Table className="table-bordered m-b-none">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>
                          {
                            <GetLanguageString props="quick_reports3_unit_name" />
                          }
                        </th>
                        <th className="text-center">
                          {<GetLanguageString props="quick_reports3_mubtadi" />}
                        </th>
                        <th className="text-center">
                          {
                            <GetLanguageString props="quick_reports3_multazim" />
                          }
                        </th>
                        <th className="text-center">
                          {<GetLanguageString props="quick_reports3_mutazir" />}
                        </th>
                        <th className="text-center">
                          {
                            <GetLanguageString props="quick_reports3_tawajah_talab" />
                          }
                        </th>
                        <th className="text-center">
                          {
                            <GetLanguageString props="report_member_total_members" />
                          }
                        </th>
                        {columnNames.includes("Naqeeb") ? (
                          <th className="text-center">
                            {
                              <GetLanguageString props="quick_reports3_naqeeb" />
                            }
                          </th>
                        ) : null}
                        {columnNames.includes("Muqami Ameer") ? (
                          <th className="text-center">
                            {
                              <GetLanguageString props="quick_reports3_muqami_ameer" />
                            }
                          </th>
                        ) : null}
                        {columnNames.includes("Ameer Halqa") ? (
                          <th className="text-center">
                            {
                              <GetLanguageString props="quick_reports3_ameer_halqa" />
                            }
                          </th>
                        ) : null}
                        {columnNames.includes("Muqami Muavin") ? (
                          <th className="text-center">
                            {
                              <GetLanguageString props="quick_reports3_muqami_muavin" />
                            }
                          </th>
                        ) : null}
                        {columnNames.includes("Halqa Jati Muavin") ? (
                          <th className="text-center">
                            {
                              <GetLanguageString props="quick_reports3_halqa_jati_muavin" />
                            }
                          </th>
                        ) : null}
                        {columnNames.includes("Markazi Muavin") ? (
                          <th className="text-center">
                            {
                              <GetLanguageString props="quick_reports3_markazi_muavin" />
                            }
                          </th>
                        ) : null}
                      </tr>
                    </thead>
                    <tbody>
                      {unitMemberCounts?.map((record, index) => {
                        totalCount.mubtadiCount += record.mubtadiCount;
                        totalCount.multazimCount += record.multazimCount;
                        totalCount.tawajahTalabCount +=
                          record.tawajahTalabCount;
                        totalCount.naqeebCount += record.naqeebCount;
                        totalCount.mutazirCount += record.mutazirCount;
                        totalCount.muqamiAmeerCount += record.muqamiAmeerCount;
                        totalCount.ameerHalqaCount += record.ameerHalqaCount;
                        totalCount.muqamiMuavinCount +=
                          record.muqamiMuavinCount;
                        totalCount.markaziMuavinCount +=
                          record.markaziMuavinCount;
                        totalCount.halqaJatiMuavinCount +=
                          record.halqaJatiMuavinCount;
                        totalCount.totalMemberCount += record.totalMemberCount;
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td className="ur">{record.name}</td>
                            <td className="text-center">
                              {record.mubtadiCount}
                            </td>
                            <td className="text-center">
                              {record.multazimCount}
                            </td>
                            <td className="text-center">
                              {record.mutazirCount}
                            </td>
                            <td className="text-center">
                              {record.tawajahTalabCount}
                            </td>
                            <td className="text-center">
                              {record.totalMemberCount}
                            </td>
                            {columnNames.includes("Naqeeb") ? (
                              <td className="text-center">
                                {record.naqeebCount}
                              </td>
                            ) : null}
                            {columnNames.includes("Muqami Ameer") ? (
                              <td className="text-center">
                                {record.muqamiAmeerCount}
                              </td>
                            ) : null}
                            {columnNames.includes("Ameer Halqa") ? (
                              <td className="text-center">
                                {record.ameerHalqaCount}
                              </td>
                            ) : null}
                            {columnNames.includes("Muqami Muavin") ? (
                              <td className="text-center">
                                {record.muqamiMuavinCount}
                              </td>
                            ) : null}
                            {columnNames.includes("Halqa Jati Muavin") ? (
                              <td className="text-center">
                                {record.halqaJatiMuavinCount}
                              </td>
                            ) : null}
                            {columnNames.includes("Markazi Muavin") ? (
                              <td className="text-center">
                                {record.markaziMuavinCount}
                              </td>
                            ) : null}
                          </tr>
                        );
                      })}
                      <tr>
                        <td></td>
                        <td></td>
                        <td className="text-center" style={customStyles}>
                          {totalCount.mubtadiCount}
                        </td>
                        <td className="text-center" style={customStyles}>
                          {totalCount.multazimCount}
                        </td>
                        <td className="text-center" style={customStyles}>
                          {totalCount.mutazirCount}
                        </td>
                        <td className="text-center" style={customStyles}>
                          {totalCount.tawajahTalabCount}
                        </td>
                        <td className="text-center" style={customStyles}>
                          {totalCount.totalMemberCount}
                        </td>
                        {columnNames.includes("Naqeeb") ? (
                          <td className="text-center" style={customStyles}>
                            {totalCount.naqeebCount}
                          </td>
                        ) : null}
                        {columnNames.includes("Muqami Ameer") ? (
                          <td className="text-center" style={customStyles}>
                            {totalCount.muqamiAmeerCount}
                          </td>
                        ) : null}
                        {columnNames.includes("Ameer Halqa") ? (
                          <td className="text-center" style={customStyles}>
                            {totalCount.ameerHalqaCount}
                          </td>
                        ) : null}
                        {columnNames.includes("Muqami Muavin") ? (
                          <td className="text-center" style={customStyles}>
                            {totalCount.muqamiMuavinCount}
                          </td>
                        ) : null}
                        {columnNames.includes("Halqa Jati Muavin") ? (
                          <td className="text-center" style={customStyles}>
                            {totalCount.halqaJatiMuavinCount}
                          </td>
                        ) : null}
                        {columnNames.includes("Markazi Muavin") ? (
                          <td className="text-center" style={customStyles}>
                            {totalCount.markaziMuavinCount}
                          </td>
                        ) : null}
                      </tr>
                    </tbody>
                  </Table>
                </>
              ) : (
                LoadingSpinner()
              )}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default UnitMemberCountReport;
