import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup,
  Form,
  Button,
  FormFeedback,
} from "reactstrap";
import { useTranslation } from "react-i18next";

import { GetLanguageString } from "../../helper/Components";
import {
  LoadingSpinner,
  isAlphaNumeric,
  isCodeUnique,
} from "../../../constants/const";
import Draggable from "react-draggable";
import { ApiCourseType } from "./ApiCourseType";
import { GetSelectedLanguage } from "../../helper/Method";
import swal from "sweetalert";
import PickColor from "../PickColor";

const AddCourseType = ({ setModal, setReload, reload }) => {
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("");
  const { t } = useTranslation();
  const [codeList, setCodeList] = useState([]);
  const [courseTypeValues, setCourseTypeValues] = useState({
    name: "",
    shortName: "",
    code: "",
    sortOrder: "",
    forTeachers: false,
    allowAhbab: false,
  });

  const toggle = () => setModal(false);
  const [errors, setErrors] = useState(courseTypeValues);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  useEffect(() => {
    setLoading(true);
    ApiCourseType()
      .GetCourseTypeCodes()
      .then((res) => {
        setCodeList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const validate = () => {
    let temp = {};
    temp.name = courseTypeValues.name ? "" : t("settings_name_error_message");
    temp.code = courseTypeValues.code ? (
      isAlphaNumeric(courseTypeValues.code) ? (
        isCodeUnique(codeList, courseTypeValues.code) ? (
          ""
        ) : (
          <GetLanguageString props="settings_code_unique_error_message" />
        )
      ) : (
        <GetLanguageString props="settings_code_aplha_numeric_error_message" />
      )
    ) : (
      <GetLanguageString props="settings_code_error_message" />
    );
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setErrors({
      type: "",
    });
    var v = value;

    if (name === "allowAhbab" && value === "true") v = true;
    else if (name === "allowAhbab" && value === "false") v = false;

    if (name === "forTeachers" && value === "true") v = true;
    else if (name === "forTeachers" && value === "false") v = false;

    setCourseTypeValues({
      ...courseTypeValues,
      [name]: v,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    courseTypeValues.color = color;
    if (validate()) {
      setLoading(true);
      ApiCourseType()
        .addCourseType(courseTypeValues)
        .then((res) => {
          toggle();
          setReload(!reload);
          swal({
            title: t("settings_course_type_add_success"),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "success",
            buttons: "OK",
          });
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="settings_add_course_type" />}
          </ModalHeader>
          {!loading ? (
            <Form onSubmit={handleSubmit}>
              <ModalBody>
                <FormGroup>
                  <Label>{<GetLanguageString props="common_name" />}</Label>
                  <Input
                    autoFocus
                    type="text"
                    name="name"
                    className="ur no-drag"
                    value={courseTypeValues.name}
                    onChange={handleInputChange}
                    {...(errors.name && { invalid: true })}
                  />
                  {errors.name && (
                    <FormFeedback>
                      <h4>{errors.name}</h4>
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label>
                    {
                      <GetLanguageString props="settings_course_type_short_name" />
                    }
                  </Label>
                  <Input
                    type="text"
                    name="shortName"
                    className="ur no-drag"
                    value={courseTypeValues.shortName}
                    onChange={handleInputChange}
                    {...(errors.shortName && { invalid: true })}
                  />
                  {errors.shortName && (
                    <FormFeedback>
                      <h4>{errors.shortName}</h4>
                    </FormFeedback>
                  )}
                </FormGroup>{" "}
                <FormGroup>
                  <Label>{<GetLanguageString props="settings_code" />}</Label>
                  <Input
                    type="text"
                    name="code"
                    className="no-drag"
                    value={courseTypeValues.code}
                    onChange={handleInputChange}
                    {...(errors.code && { invalid: true })}
                  />
                  {errors.code && (
                    <FormFeedback>
                      <h4>{errors.code}</h4>
                    </FormFeedback>
                  )}
                </FormGroup>{" "}
                <FormGroup>
                  <Label>
                    {<GetLanguageString props="settings_sort_order" />}
                  </Label>
                  <Input
                    type="text"
                    name="sortOrder"
                    className="no-drag"
                    value={courseTypeValues.sortOrder}
                    onChange={handleInputChange}
                    {...(errors.type && { invalid: true })}
                  />
                  {errors.type && (
                    <FormFeedback>
                      <h4>{errors.type}</h4>
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup check>
                  <Label check for="allowAhbab">
                    {" "}
                    <span
                      style={{
                        float:
                          GetSelectedLanguage() === "ur" ? "right" : "left",
                        marginLeft:
                          GetSelectedLanguage() === "ur" ? "24px" : "0px",
                      }}
                    >
                      <Input
                        id="allowAhbab"
                        type="checkbox"
                        checked={courseTypeValues.allowAhbab}
                        name="allowAhbab"
                        value={!courseTypeValues.allowAhbab}
                        onChange={handleInputChange}
                      />
                    </span>
                    {
                      <GetLanguageString props="settings_event_type_allow_ahbab" />
                    }
                  </Label>
                </FormGroup>{" "}
                <FormGroup check>
                  <Label check for="forTeachers">
                    {" "}
                    <span
                      style={{
                        float:
                          GetSelectedLanguage() === "ur" ? "right" : "left",
                        marginLeft:
                          GetSelectedLanguage() === "ur" ? "24px" : "0px",
                      }}
                    >
                      <Input
                        id="forTeachers"
                        type="checkbox"
                        checked={courseTypeValues.forTeachers}
                        name="forTeachers"
                        value={!courseTypeValues.forTeachers}
                        onChange={handleInputChange}
                      />
                    </span>
                    {
                      <GetLanguageString props="settings_course_type_for_teachers" />
                    }
                  </Label>
                </FormGroup>
                <PickColor setColor={setColor} color={color} />
              </ModalBody>
              <ModalFooter>
                <Button color="white" onClick={toggle}>
                  {<GetLanguageString props="common_cancel" />}
                </Button>
                <Button color="primary" type="submit">
                  {<GetLanguageString props="common_save_changes" />}
                </Button>
              </ModalFooter>
            </Form>
          ) : (
            LoadingSpinner()
          )}
        </Modal>
      </Draggable>
    </div>
  );
};

export default AddCourseType;
