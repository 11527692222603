import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Label,
  Row,
  Col,
  FormGroup,
  ButtonGroup,
} from "reactstrap";
import Select from "react-select";
import moment from "moment";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { addMember } from "../member/ApiMember";
import SelectUnit from "../shared/SelectUnit";
import AddFund from "./AddFund";
import { ApiFund } from "./ApiFund";
import { GetMonths, GetSelectedLanguage } from "../helper/Method";
import { ApiUnit } from "../unit/ApiUnit";
import { GetLanguageString, NoResult } from "../helper/Components";
import EditFund from "./EditFund";
import {
  CheckPermission,
  LoadingSpinner,
  convertUTCToLocalTime,
} from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";
import { IoAdd } from "react-icons/io5";

const Funds = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState({});
  const [memberFunds, setMemberFunds] = useState([]);
  const [editValue, setEditValue] = useState(0);
  const [editModal, setEditModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [values, setValues] = useState({
    unit: { value: loggedInUnitId, lable: "" },
  });
  const [errors, setErrors] = useState({ member: "" });
  let membersList = [];
  let months = GetMonths();
  let monthName = "";

  useEffect(() => {
    if (values.unit.value === 0) {
      setLoading(true);
      ApiUnit()
        .getUnit(loggedInUnitId, loggedInUnitId)
        .then((res) => {
          let temp = res.data.unitList[0];
          let temp2 = { value: temp.id, lable: temp.name };
          setValues({
            unit: temp2,
          });
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [values.unit.value, loggedInUnitId]);

  useEffect(() => {
    setLoading(true);
    addMember()
      .getUnitMembersForSelecting(values.unit.value)
      .then((res) => {
        setMembers(res.data);
        if (res.data.length !== 0) {
          setSelectedMember({
            value: res.data[0].id,
            label: res.data[0].name,
          });
          setErrors({ member: "" });
        } else {
          setSelectedMember({});
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    setMemberFunds([]);
  }, [values]);

  if (members.length !== 0) {
    members.forEach((m) => membersList.push({ value: m.id, label: m.name }));
  }

  const validate = () => {
    let temp = {};
    temp.member = Object.keys(selectedMember).length === 0 ? "error" : "";
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleChangeMember = (record) => {
    if (record !== null) {
      setSelectedMember(record);
      setErrors({ member: "" });
    } else {
      setSelectedMember({});
    }
  };

  const handleAddFund = () => {
    if (validate()) {
      setModal(true);
    }
  };

  const handleAddEdit = (e) => {
    const { value } = e.target;
    setEditValue(value);
    setEditModal(true);
  };

  const handleOnDelete = (e) => {
    const { value } = e.target;
    swal({
      title: t("common_delete_message"),
      text: t("common_delete_detail_message"),
      className: GetSelectedLanguage() === "en" ? "" : "ur",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        ApiFund()
          .deleteFund(value)
          .then((res) => {
            setMemberFunds(res.data);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      }
    });
  };

  const handleSearch = () => {
    if (validate()) {
      setLoading(true);
      ApiFund()
        .getList(values.unit.value, selectedMember.value)
        .then((res) => {
          setMemberFunds(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  return (
    <>
      <>
        {modal ? (
          <AddFund
            setModal={setModal}
            memberId={selectedMember.value}
            unitId={values.unit.value}
            memberName={selectedMember.label}
            setMemberFunds={setMemberFunds}
          />
        ) : null}
        {editModal ? (
          <EditFund
            editValue={editValue}
            memberName={selectedMember.label}
            setMemberFunds={setMemberFunds}
            setModal={setEditModal}
          />
        ) : null}
      </>
      <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
        <div className="ibox ">
          <div className="ibox-title">
            <h5>
              {<GetLanguageString props="funds_select_unit_and_member" />}
            </h5>
          </div>
          <div className="ibox-content">
            <SelectUnit
              values={values}
              setValues={setValues}
              unitId={values.unit.value}
            />
            <Row>
              <Col md="4">
                <FormGroup>
                  <Label className="col-form-label">
                    {<GetLanguageString props="funds_select_member" />}
                  </Label>
                  <Select
                    value={selectedMember}
                    options={membersList}
                    className="basic-single ur"
                    classNamePrefix="select"
                    onChange={handleChangeMember}
                  />
                  {errors.member === "error" ? (
                    <h4 className="text-error">
                      {<GetLanguageString props="funds_member_error_message" />}
                    </h4>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="text-center">
                <Button color="primary" type="submit" onClick={handleSearch}>
                  {<GetLanguageString props="funds_submit" />}
                </Button>
                {CheckPermission("CanAddFunds") ? (
                  <>
                    <Button
                      color="primary"
                      className="m-l-sm"
                      onClick={() => handleAddFund()}
                    >
                      <IoAdd
                        size={18}
                        style={{ marginRight: 3, marginBottom: 1 }}
                      />
                      {<GetLanguageString props="funds_add_fund" />}
                    </Button>
                    <Link
                      to={{
                        pathname: `/fundsmanagement/addbulk`,
                        state: {
                          unitId: values.unit.value,
                          membersList: membersList,
                        },
                      }}
                      className="m-l btn btn-small btn-primary"
                    >
                      <IoAdd
                        size={18}
                        style={{ marginRight: 3, marginBottom: 1 }}
                      />
                      {<GetLanguageString props="funds_add_bulk_fund" />}
                    </Link>
                  </>
                ) : null}
              </Col>
            </Row>
          </div>
        </div>
        <div className="ibox ">
          <div className="ibox-title">
            <h5>{<GetLanguageString props="funds_paid_funds" />}</h5>
          </div>
          {/* Table for showing the Paid Fund Details */}
          <div className="ibox-content">
            <>
              {!loading ? (
                memberFunds.length !== 0 ? (
                  <div className="table-responsive">
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>{<GetLanguageString props="funds_month" />}</th>
                          <th>{<GetLanguageString props="funds_year" />}</th>
                          <th>{<GetLanguageString props="funds_date" />}</th>
                          <th>
                            {
                              <GetLanguageString props="dashboard_event_page_unit_name" />
                            }
                          </th>
                          <th>
                            {<GetLanguageString props="funds_receipt_number" />}
                          </th>
                          {CheckPermission("CanViewInfaqAmount") && (
                            <th>
                              {
                                <GetLanguageString props="funds_add_fund_amount" />
                              }
                            </th>
                          )}
                          <th>{<GetLanguageString props="common_action" />}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {memberFunds?.map((record, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              {months.forEach((m) =>
                                m.value === record.month
                                  ? (monthName = m.label)
                                  : null
                              )}
                              <td>{monthName}</td>
                              <td>{record.year}</td>
                              <td>
                                {moment(
                                  convertUTCToLocalTime(record.date)
                                ).format("DD/MM/yyyy")}
                              </td>
                              <td className="ur">{record.unitName}</td>
                              <td>{record.receiptNumber}</td>
                              {CheckPermission("CanViewInfaqAmount") && ( // Checks if it has permissions then show the Amount
                                <td>{record.amount}</td>
                              )}
                              <td>
                                <ButtonGroup>
                                  {CheckPermission("CanModifyFunds") ? (
                                    <Button
                                      color="white"
                                      size="sm"
                                      value={record.id}
                                      onClick={handleAddEdit}
                                    >
                                      {
                                        <GetLanguageString props="common_edit" />
                                      }
                                    </Button>
                                  ) : null}
                                  {CheckPermission("CanDeleteFunds") ? (
                                    <Button
                                      color="white"
                                      size="sm"
                                      value={record.id}
                                      onClick={handleOnDelete}
                                    >
                                      {
                                        <GetLanguageString props="common_delete" />
                                      }
                                    </Button>
                                  ) : null}
                                </ButtonGroup>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <NoResult />
                )
              ) : (
                LoadingSpinner()
              )}
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default Funds;
