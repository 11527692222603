import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { GetLanguageString } from "../../helper/Components";
import ChangeMemberUnitSelectUnit from "./ChangeMemberUnitSelectUnit";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { encryptStorage } from "../../../constants/EncryptStorage";
import swal from "sweetalert";
import { addMember } from "../ApiMember";
import { toast } from "react-toastify";
import { GetSelectedLanguage } from "../../helper/Method";
import { convertToDateString, LoadingSpinner } from "../../../constants/const";
import Draggable from "react-draggable";

const ChangeMemberUnit = ({
  setChangeUnitModal,
  memberId,
  previousUnitId,
  memberUnitData,
  addNewRafiq,
  setUpdatedDetails,
  setUnitStateChange,
  edit = false,
}) => {
  const notify = (string) => toast.success(string);
  const toggle = () => setChangeUnitModal(false);
  const { t } = useTranslation();
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [buttonDisable, setButtonDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    memberUnitData?.unitDate ? new Date(memberUnitData?.unitDate) : new Date()
  );

  const [memberUnit, setMemberUnit] = useState(
    memberUnitData !== null
      ? {
          memberUnitId: memberUnitData.id,
          id: previousUnitId,
          memberId: memberId,
          unit: { value: memberUnitData.unitId, label: "" },
          date: convertToDateString(selectedDate),
          description: memberUnitData.description,
          referenceNote: memberUnitData.referenceNote,
        }
      : {
          id: previousUnitId,
          memberId: memberId,
          unit: { value: loggedInUnitId, label: "" },
          date: new Date(),
          description: "",
          referenceNote: "",
        }
  );
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMemberUnit({
      ...memberUnit,
      [name]: value,
    });
  };

  const handleInputChangeDate = (value) => {
    if (value !== null) {
      if (value <= new Date()) {
        setSelectedDate(value);
      }
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setButtonDisable(true);
    setLoading(true);
    memberUnit.date = convertToDateString(selectedDate);
    addMember()
      .addMemberUnit(memberUnit, addNewRafiq)
      .then((res) => {
        if (res.data === "") {
          swal({
            title: t(
              edit
                ? "member__member_unit_update_success_message"
                : "member__member_unit_added_success_message"
            ),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "success",
            buttons: "OK",
          });
          setUpdatedDetails((prevState) => !prevState);
          setUnitStateChange((prevState) => !prevState);
        } else notify(res.data);
        setLoading(false);
        setButtonDisable(false);
        toggle();
      })
      .catch((err) => {
        console.log(err);
        swal({
          title: "Error: " + err.response?.data,
          icon: "error",
          buttons: "OK",
        });
        setLoading(false);
        setButtonDisable(false);
        toggle();
      });
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal
          size="lg"
          isOpen={true}
          className="inmodal"
          autoFocus={false}
          style={{ maxWidth: "1080px", width: "100%" }}
        >
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="member_member_detail_chnage_unit" />}
          </ModalHeader>
          <Form onSubmit={handleSubmit}>
            <ModalBody>
              {!loading ? (
                <div className="ibox-content">
                  <ChangeMemberUnitSelectUnit
                    values={memberUnit}
                    setValues={setMemberUnit}
                    unitId={memberUnit.unit.value}
                  />
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <Label className="col-form-label">
                          {<GetLanguageString props="member_joining_date" />}
                        </Label>
                        <DatePicker
                          showYearDropdown
                          dropdownMode="select"
                          placeholderText="dd/mm/yyyy"
                          className="form-control no-drag"
                          selected={selectedDate}
                          onChange={handleInputChangeDate}
                          dateFormat="dd/MM/yyyy"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <Label className="col-form-label">
                          {<GetLanguageString props="courses_description" />}
                        </Label>
                        <Input
                          className="ur no-drag"
                          type="text"
                          value={memberUnit.description}
                          name="description"
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <Label className="col-form-label">
                          {<GetLanguageString props="member_reference_note" />}
                        </Label>
                        <Input
                          className="ur no-drag"
                          type="text"
                          value={memberUnit.referenceNote}
                          name="referenceNote"
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              ) : (
                LoadingSpinner()
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="white" onClick={toggle}>
                {<GetLanguageString props="common_cancel" />}
              </Button>
              <Button disabled={buttonDisable} color="primary" type="submit">
                {<GetLanguageString props="common_save_changes" />}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </Draggable>
    </div>
  );
};

export default ChangeMemberUnit;
