import React, { useState } from "react";
import PropTypes from "prop-types";
import { Helmet, HelmetProvider } from "react-helmet-async";

import logo from "./logo.jpg";
import Login from "./Login";
import SignUp from "./SignUp";
import ForgotPassword from "./ForgotPassword";

const LoginSignUp = ({ setValues }) => {
  const [signUp, setSignUp] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const bgColor = "#f3f3f4";

  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <style>
            {`
                body {
                    background-color: ${bgColor};
                }
            `}
          </style>
        </Helmet>
        {forgotPassword ? (
          <ForgotPassword
            forgotPassword={forgotPassword}
            setForgotPassword={setForgotPassword}
          />
        ) : (
          <div className="middle-box text-center loginscreen">
            <div>
            <img
                    alt="images"
                    style={{
                          width: 150,
                          height: 100,
                          marginTop: 100,
                          marginBottom: 10,
                          borderRadius: 10 
                      }}
                    src={logo}
                  />
              {signUp ? (
                <SignUp signUp={signUp} setSignUp={setSignUp} />
              ) : (
                <Login
                  forgotPassword={forgotPassword}
                  setForgotPassword={setForgotPassword}
                  signUp={signUp}
                  setSignUp={setSignUp}
                  setValues={setValues}
                />
              )}
            </div>
          </div>
        )}
      </HelmetProvider>
    </div>
  );
};

LoginSignUp.propTypes = {
  setValues: PropTypes.func.isRequired,
};

export default LoginSignUp;
