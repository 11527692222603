import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, withRouter } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { GetLanguageString } from "../helper/Components";
import SearchAutoComplete from "../shared/SearchAutoComplete";
import { ApiEvent } from "./ApiEvent";
import { GetSelectedLanguage } from "../helper/Method";

const SelectiveEventParticipants = ({
  type,
  participants,
  setParticipants,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [loading, setloading] = useState(false);
  const eventId = location.state.eventId;
  const unitId = location.state.eventUnitId;
  const [members, setMembers] = useState([]);
  const [name, setName] = useState("null");

  useEffect(() => {
    if (name !== "" && !name.includes("/")) {
      setloading(true);
      const timeoutId = setTimeout(() => {
        ApiEvent()
          .searchMembersforEventParticipants(unitId, true, name, type)
          .then((res) => {
            setMembers(res.data);
            setloading(false);
          })
          .catch((err) => {
            console.log(err);
            setloading(false);
          });
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
    setMembers([]);
    setloading(false);
  }, [name, unitId, type]);

  const handleOnSelect = (item) => {
    const exists = participants.some((m) => m.memberId === item.id);
    if (!exists) {
      const newParticipant = {
        memberId: item.id,
        eventId: eventId,
        name: item.name,
        memberType: item.memberType,
        memberRegNo: item.memberRegNo,
        memberUnitName: item.memberUnitName,
        attendanceStatus: 1,
        onTime: true,
      };
      setParticipants([...participants, newParticipant]);
    }
  };

  return (
    <div>
      <div className="ibox">
        <div className="ibox-title">
          <h5>
            {
              <GetLanguageString
                props={
                  type === "rafiq"
                    ? "participant_add_rafiq"
                    : "participant_add_habib"
                }
              />
            }
          </h5>
        </div>
        <div className="ibox-content">
          <Row>
            <Col md="12" className={GetSelectedLanguage() === "ur" ? "ur" : ""}>
              <SearchAutoComplete
                members={members}
                setMembers={setMembers}
                setName={setName}
                name={name}
                type={type}
                loading={loading}
                placeholder={t(
                  type === "rafiq"
                    ? "participant_add_rafiq"
                    : "participant_add_habib"
                )}
                handleOnSelect={handleOnSelect}
                calledFrom="event"
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SelectiveEventParticipants);
