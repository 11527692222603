import axios from "axios";

import { BASEURL } from "../../../constants/const";
import { encryptStorage } from "../../../constants/EncryptStorage";

export function ApiLetterForCounselling() {
  const userToken = encryptStorage.getItem("token");

  const authAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const url = BASEURL + "letterforcounselling/";
  return {
    addLetterForCounselling: (record) => authAxios.post(url + "add", record),
    editData: (id) => authAxios.get(url + "edit/" + id),
    update: (id, record) => authAxios.put(url + "edit/" + id, record),
    getLetterForCounsellingFrequency: () =>
      authAxios.get(url + "GetLetterFrequencies/"),
    getLetterForCounsellingQuestions: (id) =>
      authAxios.get(url + "GetLetterForCounsellingQuestions/" + id),
    getLetterForCounsellingCodes: () =>
      authAxios.get(url + "GetLetterForCounsellingCode/"),
    getLetterForCounsellingList: (unitId) =>
      authAxios.get(url + "getUnitLetterForCounsellingList/" + unitId),
    getEventSubType: () => authAxios.get(url + "getEventSubType/"),
    delete: (id) => authAxios.delete(url + "Delete/" + id),

    //COUNSELLING QUESTIONS

    addCounsellingQuestion: (record) =>
      authAxios.post(url + "AddCounsellingQuestion", record),
    getCounsellingQuestions: () =>
      authAxios.get(url + "getCounsellingQuestions/"),
    GetCouncellingQuestionsListForLfc: (lfcId) =>
      authAxios.get(url + "GetCouncellingQuestionsListForLfc/" + lfcId),
    editCounsellingQuestions: (id) =>
      authAxios.get(url + "EditCounsellingQuestion/" + id),
    updateCounsellingQuestions: (id, record) =>
      authAxios.put(url + "EditCounsellingQuestion/" + id, record),
    deleteCounsellingQuestion: (id) =>
      authAxios.delete(url + "DeleteCounsellingQuestion/" + id),

    //LETTER FOR COUNSELLING QUESTIONS
    AddLetterForCounsellingQuestion: (record) =>
      authAxios.post(url + "AddLetterForCounsellingQuestion", record),
    deleteLetterForCounsellingQuestion: (id) =>
      authAxios.delete(url + "deleteLetterForCounsellingQuestion/" + id),
    EditLetterForCounsellingQuestion: (id) =>
      authAxios.get(url + "EditLetterForCounsellingQuestion/" + id),
    UpdateLetterForCounsellingQuestion: (id, record) =>
      authAxios.put(url + "EditLetterForCounsellingQuestion/" + id, record),


    GetMemberLetterForCounsellingByYears: (year, memberId) =>
      authAxios.get(
        url + "GetMemberLetterForCounsellingByYears/" + year + "/" + memberId
      ),
  };
}
