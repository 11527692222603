import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiMinus } from "react-icons/bi";
import Pagination from "react-js-pagination";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Switch from "react-switch";
import { Button, Col, Input, Label, Row, Table } from "reactstrap";
import swal from "sweetalert";
import { LoadingSpinner } from "../../constants/const";
import { GetLanguageString, NoResult } from "../helper/Components";
import { GetCustomStyles, GetSelectedLanguage } from "../helper/Method";
import SearchAutoComplete from "../shared/SearchAutoComplete";
import { ApiEvent } from "./ApiEvent";

const EventSelectiveParticipantList = ({
  renderParticipants,
  searchEventParticipants,
  setSearchEventParticipants,
  activeTab,
  updatedList,
  setUpdatedList,
  loading,
  setButtonDisabled,
  setShowStatusDropDown,
  setRufqaPercentage,
  setAhbabPercentage,
  status,
}) => {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState({ activePage: 1 });
  const [loadingInner, setLoadingInner] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [newParticipants, setNewParticipants] = useState([]);
  const location = useLocation();
  const eventId = location.state.eventId;
  const unitId = location.state.eventUnitId;
  const [members, setMembers] = useState([]);
  const [name, setName] = useState("");
  let absent = false;
  let present = false;
  let leave = false;
  const selectedLanguage = GetSelectedLanguage();

  const handleSearchAttendanceStatus = (s) => {
    setSearchEventParticipants({
      ...searchEventParticipants,
      attendanceStatus: s,
    });
  };

  const handleAttendanceStatusNew = (status, index) => {
    let tempValues = [...newParticipants];
    tempValues[index].attendanceStatus = status;

    setNewParticipants(tempValues);

    if (entryExists(tempValues[index])) {
      updateOrAddEntry(tempValues[index]);
    } else {
      setUpdatedList((prevList) => [...prevList, tempValues[index]]);
      setButtonDisabled(false);
    }
  };

  useEffect(() => {
    setNewParticipants([]);
  }, [renderParticipants]);

  useEffect(() => {
    if (activeTab === "rufqa") {
      if (searchEventParticipants.type === "ahbab") {
        searchEventParticipants.activePage = 1;
      }
      searchEventParticipants.type = "rufqa";
      setLoadingInner(true);
      setButtonDisabled(true);
      ApiEvent()
        .getParticipantsByEvent(searchEventParticipants)
        .then((res) => {
          setPagination(res.data);
          let newList = [];
          res.data.eventParticipantsModelList.map((record) => {
            const updatedItem = updatedList.find(
              (item) =>
                item.eventId === record.eventId &&
                item.memberId === record.memberId
            );

            if (updatedItem) newList.push(updatedItem);
            else newList.push(record);

            return null;
          });
          setParticipants(newList);
          setLoadingInner(false);
          setButtonDisabled(
            res.data.eventParticipantsModelList.length !== 0 ? false : true
          );
          setShowStatusDropDown(!res.data.isFirstTime);
          setRufqaPercentage(
            res.data.rufqaPercentage === "NaN" ? 0.0 : res.data.rufqaPercentage
          );
          setAhbabPercentage(
            res.data.ahbabPercentage === "NaN" ? 0.0 : res.data.ahbabPercentage
          );
        })
        .catch((err) => {
          setLoadingInner(false);
          setButtonDisabled(false);
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
        });
    }
    // eslint-disable-next-line
  }, [searchEventParticipants, renderParticipants, activeTab]);

  const handleOnTimeNew = (e, index) => {
    let tempValues = [...newParticipants];
    tempValues[index].onTime = e;
    setNewParticipants(tempValues);

    if (entryExists(tempValues[index])) {
      updateOrAddEntry(tempValues[index]);
    } else {
      setUpdatedList((prevList) => [...prevList, tempValues[index]]);
      setButtonDisabled(false);
    }
  };
  useEffect(() => {
    if (name !== "" && !name.includes("/")) {
      setLoadingSearch(true);
      const timeoutId = setTimeout(() => {
        ApiEvent()
          .searchMembersforEventParticipants(
            unitId,
            true,
            name,
            "rufqa",
            eventId
          )
          .then((res) => {
            setMembers(res.data);
            setLoadingSearch(false);
          })
          .catch((err) => {
            setLoadingSearch(false);
            console.log(err);
          });
      }, 1500);
      return () => clearTimeout(timeoutId);
    }
    setMembers([]);
    setLoadingSearch(false);
  }, [name, unitId, eventId]);

  const entryExists = (entry) => {
    return updatedList.some((item) => item.memberId === entry.memberId);
  };
  const updateOrAddEntry = (entry) => {
    const index = updatedList.findIndex(
      (item) => item.memberId === entry.memberId
    );
    if (index !== -1) {
      // Entry exists, check if values have changed
      const existingEntry = updatedList[index];
      const valuesChanged = Object.keys(entry).some(
        (key) => entry[key] !== existingEntry[key]
      );
      if (valuesChanged) {
        updatedList[index] = { ...existingEntry, ...entry };
      }
    } else {
      // Entry doesn't exist, add it
      updatedList.push(entry);
      setButtonDisabled(false);
    }
  };

  const handleAttendanceStatus = (status, index) => {
    let tempValues = [...participants];
    tempValues[index].attendanceStatus = status;

    setParticipants(tempValues);

    if (entryExists(tempValues[index])) {
      updateOrAddEntry(tempValues[index]);
    } else {
      setUpdatedList((prevList) => [...prevList, tempValues[index]]);
    }
  };
  const handleOnTime = (e, index) => {
    let tempValues = [...participants];
    tempValues[index].onTime = e;
    setParticipants(tempValues);

    if (entryExists(tempValues[index])) {
      updateOrAddEntry(tempValues[index]);
    } else {
      setUpdatedList((prevList) => [...prevList, tempValues[index]]);
    }
  };

  const handleOnSelect = (item) => {
    const exists = newParticipants.some(
      (participant) => participant.memberId === item.id
    );

    if (!exists) {
      const newParticipant = {
        memberId: item.id,
        eventId: eventId,
        name: item.name.split(" - ")[0],
        memberType: item.memberType,
        memberRegNo: item.memberRegNo,
        memberUnitName: item.memberUnitName,
        attendanceStatus: 1,
        onTime: true,
      };
      setNewParticipants((prevParticipants) => [
        newParticipant,
        ...prevParticipants,
      ]);

      if (entryExists(newParticipants)) {
        updateOrAddEntry(newParticipants);
      } else {
        setUpdatedList((prevList) => [newParticipant, ...prevList]);
        setButtonDisabled(false);
      }
    }

    setName("");
    setMembers([]);
  };
  const removeObject = (id) => {
    let tempValues = [...newParticipants];
    const updated = updatedList.filter(
      (item) => item.memberId !== tempValues[id].memberId
    );

    setUpdatedList(updated);

    tempValues.splice(id, 1);
    setNewParticipants(tempValues);
  };

  const handlePageChange = (pageNumber) => {
    setSearchEventParticipants({
      ...searchEventParticipants,
      activePage: pageNumber,
    });
    setPagination({
      ...pagination,
      activePage: pageNumber,
    });
  };
  let recordIndex =
    searchEventParticipants.pageSize *
      (searchEventParticipants.activePage - 1) +
    1;

  let pageInfo = `Displaying ${recordIndex}-${
    recordIndex + pagination.pageSize - 1 < pagination.totalItem
      ? recordIndex + pagination.pageSize - 1
      : pagination.totalItem
  } of ${pagination.totalItem}`;

  return (
    <>
      <Row>
        <div className="ibox">
          <div className="ibox-title">
            <h5>{<GetLanguageString props={"participant_add_rafiq"} />}</h5>
          </div>
          <div className="ibox-content">
            <Row>
              <Col
                md="12"
                className={GetSelectedLanguage() === "ur" ? "ur" : ""}
              >
                <SearchAutoComplete
                  members={members}
                  setMembers={setMembers}
                  setName={setName}
                  name={name}
                  type={"rufqa"}
                  loading={loadingSearch}
                  placeholder={t("participant_add_rafiq")}
                  handleOnSelect={handleOnSelect}
                  calledFrom="event"
                />
              </Col>
            </Row>
          </div>
        </div>
      </Row>
      <Row>
        <Col md="10">
          <Row style={{ marginLeft: "20px", marginTop: "5px" }}>
            {status.map((item, index) => {
              return (
                <Col md="1">
                  <Label
                    className="form-check form-check-inline"
                    for={"statusRufqaCheck" + item.value}
                  >
                    <Input
                      id={"statusRufqaCheck" + item.value}
                      type="radio"
                      checked={
                        item.value === searchEventParticipants.attendanceStatus
                      }
                      value={searchEventParticipants.attendanceStatus}
                      name={"statusRafiq" + item.value}
                      onChange={() => handleSearchAttendanceStatus(item.value)}
                      className="form-check-input"
                    />
                    {<GetLanguageString props={item.label} />}
                  </Label>
                </Col>
              );
            })}
          </Row>
        </Col>
        <Col md="2"></Col>
      </Row>
      <hr></hr>
      {newParticipants.length !== 0 ? (
        <div className="ibox">
          <div className="ibox-title">
            <h5>
              {<GetLanguageString props={"participant_new_added_rafiq"} />}
            </h5>
          </div>
          <div className="ibox-content">
            <>
              <div className="table-responsive">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{<GetLanguageString props="common_name" />}</th>
                      <th>{<GetLanguageString props="member_reg_number" />}</th>
                      <th className="text-center">
                        {<GetLanguageString props="settings_member_type" />}
                      </th>
                      <th className="text-center">
                        {<GetLanguageString props="unit_unit_table" />}
                      </th>
                      <th className="text-center">
                        {<GetLanguageString props="common_attendance" />}
                      </th>
                      <th>
                        {
                          <GetLanguageString props="events_participants_time_status" />
                        }
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {newParticipants?.map((record, index) => {
                      let unitName = record.memberUnitName?.split("/");
                      return (
                        <tr key={index}>
                          <td>{index + recordIndex}</td>
                          <td className="ur">
                            <h4>{record.name}</h4>
                          </td>
                          <td>{record.memberRegNo}</td>
                          <td className="text-center ur">
                            {record.memberType}
                          </td>{" "}
                          <td className="ur">
                            <ol className="breadcrumb">
                              {unitName.map((item, index) => {
                                return (
                                  <li
                                    className={
                                      GetSelectedLanguage() === "ur"
                                        ? "breadcrumb-item-ur"
                                        : "breadcrumb-item"
                                    }
                                    key={index}
                                  >
                                    <Button
                                      size="xs"
                                      color="info"
                                      className="ur"
                                    >
                                      {item}
                                    </Button>
                                  </li>
                                );
                              })}
                            </ol>
                          </td>
                          <td className="text-center">
                            <Label
                              className="form-check form-check-inline"
                              for={"absentSelectiveRafiqNew" + record.memberId}
                            >
                              {record.attendanceStatus === 0
                                ? (absent = true)
                                : null}
                              <Input
                                id={"absentSelectiveRafiqNew" + record.memberId}
                                type="radio"
                                checked={absent}
                                name={"status" + record.memberId}
                                onChange={() =>
                                  handleAttendanceStatusNew(0, index)
                                }
                                className="form-check-input"
                              />
                              {
                                <GetLanguageString props="events_participants_attendance_absent" />
                              }
                            </Label>
                            <Label
                              className="form-check form-check-inline"
                              for={"presentSelectiveRafiqNew" + record.memberId}
                            >
                              {record.attendanceStatus === 1
                                ? (present = true)
                                : null}
                              <Input
                                id={
                                  "presentSelectiveRafiqNew" + record.memberId
                                }
                                type="radio"
                                checked={present}
                                name={"status" + record.memberId}
                                onChange={() =>
                                  handleAttendanceStatusNew(1, index)
                                }
                                className="form-check-input"
                              />
                              {
                                <GetLanguageString props="events_participants_attendance_present" />
                              }
                            </Label>
                            <Label
                              className="form-check form-check-inline"
                              for={"leaveSelectiveRafiqNew" + record.memberId}
                            >
                              {record.attendanceStatus === 2
                                ? (leave = true)
                                : null}
                              <Input
                                id={"leaveSelectiveRafiqNew" + record.memberId}
                                checked={leave}
                                type="radio"
                                name={"status" + record.memberId}
                                onChange={() =>
                                  handleAttendanceStatusNew(2, index)
                                }
                                className="form-check-input"
                              />
                              {
                                <GetLanguageString props="events_participants_attendance_leave" />
                              }
                            </Label>
                          </td>
                          <td>
                            <Label
                              htmlFor="small-radius-switch"
                              for={"onTimeRafiq" + record.memberId}
                            >
                              <Switch
                                id={
                                  "small-radius-switch onTimeRafiq" +
                                  record.memberId
                                }
                                disabled={
                                  record.attendanceStatus === 1 ? false : true
                                }
                                checked={record.onTime}
                                onChange={(e) => handleOnTimeNew(e, index)}
                                handleDiameter={32}
                                offColor="#888888"
                                onColor="#6fcab8"
                                offHandleColor="#fff"
                                onHandleColor="#18A689"
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={25}
                                width={90}
                                borderRadius={20}
                                uncheckedIcon={
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "100%",
                                      color: "white",
                                      paddingRight: 25,
                                      fontSize:
                                        selectedLanguage === "ur" ? 25 : 15,
                                      paddingBottom:
                                        selectedLanguage === "ur" ? 10 : 0,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <GetLanguageString
                                      props={"events_participants_late"}
                                    />
                                  </div>
                                }
                                checkedIcon={
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "100%",
                                      width: "60px",
                                      color: "white",
                                      paddingLeft: 5,
                                      fontSize:
                                        selectedLanguage === "ur" ? 19 : 12,
                                      paddingBottom:
                                        selectedLanguage === "ur" ? 5 : 0,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <GetLanguageString
                                      props={"events_participants_on_time"}
                                    />
                                  </div>
                                }
                                className="react-switch"
                              />
                            </Label>
                          </td>
                          <td>
                            <BiMinus
                              size={30}
                              color={"red"}
                              onClick={() => removeObject(index)}
                              style={{
                                marginRight: 3,
                                marginBottom: 1,
                                cursor: "pointer",
                              }}
                            />
                          </td>
                          {
                            ((absent = false),
                            (present = false),
                            (leave = false))
                          }
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </>
          </div>
        </div>
      ) : null}
      <div className="ibox">
        <div className="ibox-title">
          <h5>
            {<GetLanguageString props={"participant_already_added_rafiq"} />}
          </h5>
        </div>
        <div className="ibox-content">
          {!loading && !loadingInner ? (
            participants.length !== 0 ? (
              <>
                <div className="table-responsive">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>{<GetLanguageString props="common_name" />}</th>
                        <th>
                          {<GetLanguageString props="member_reg_number" />}
                        </th>
                        <th className="text-center">
                          {<GetLanguageString props="settings_member_type" />}
                        </th>
                        <th className="text-center">
                          {<GetLanguageString props="unit_unit_table" />}
                        </th>
                        <th className="text-center">
                          {<GetLanguageString props="common_attendance" />}
                        </th>
                        <th>
                          {
                            <GetLanguageString props="events_participants_time_status" />
                          }
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {participants?.map((record, index) => {
                        let unitName = record.memberUnitName?.split("/");
                        return (
                          <tr key={index}>
                            <td>{index + recordIndex}</td>
                            <td className="ur">
                              <h4>{record.name}</h4>
                            </td>
                            <td>{record.memberRegNo}</td>
                            <td className="text-center ur">
                              {record.memberType}
                            </td>{" "}
                            <td className="ur">
                              <ol className="breadcrumb">
                                {unitName.map((item, index) => {
                                  return (
                                    <li
                                      className={
                                        GetSelectedLanguage() === "ur"
                                          ? "breadcrumb-item-ur"
                                          : "breadcrumb-item"
                                      }
                                      key={index}
                                    >
                                      <Button
                                        size="xs"
                                        color="info"
                                        className="ur"
                                      >
                                        {item}
                                      </Button>
                                    </li>
                                  );
                                })}
                              </ol>
                            </td>
                            <td className="text-center">
                              <Label
                                className="form-check form-check-inline"
                                for={"absentSelectiveRafiq" + record.memberId}
                              >
                                {record.attendanceStatus === 0
                                  ? (absent = true)
                                  : null}
                                <Input
                                  id={"absentSelectiveRafiq" + record.memberId}
                                  type="radio"
                                  checked={absent}
                                  name={"status" + record.memberId}
                                  onChange={() =>
                                    handleAttendanceStatus(0, index)
                                  }
                                  className="form-check-input"
                                />
                                {
                                  <GetLanguageString props="events_participants_attendance_absent" />
                                }
                              </Label>
                              <Label
                                className="form-check form-check-inline"
                                for={"presentSelectiveRafiq" + record.memberId}
                              >
                                {record.attendanceStatus === 1
                                  ? (present = true)
                                  : null}
                                <Input
                                  id={"presentSelectiveRafiq" + record.memberId}
                                  type="radio"
                                  checked={present}
                                  name={"status" + record.memberId}
                                  onChange={() =>
                                    handleAttendanceStatus(1, index)
                                  }
                                  className="form-check-input"
                                />
                                {
                                  <GetLanguageString props="events_participants_attendance_present" />
                                }
                              </Label>
                              <Label
                                className="form-check form-check-inline"
                                for={"leaveSelectiveRafiq" + record.memberId}
                              >
                                {record.attendanceStatus === 2
                                  ? (leave = true)
                                  : null}
                                <Input
                                  id={"leaveSelectiveRafiq" + record.memberId}
                                  checked={leave}
                                  type="radio"
                                  name={"status" + record.memberId}
                                  onChange={() =>
                                    handleAttendanceStatus(2, index)
                                  }
                                  className="form-check-input"
                                />
                                {
                                  <GetLanguageString props="events_participants_attendance_leave" />
                                }
                              </Label>
                            </td>
                            <td>
                              <Label
                                htmlFor="small-radius-switch"
                                for={"onTimeRafiq" + record.memberId}
                              >
                                <Switch
                                  id={
                                    "small-radius-switch onTimeRafiq" +
                                    record.memberId
                                  }
                                  disabled={
                                    record.attendanceStatus === 1 ? false : true
                                  }
                                  checked={record.onTime}
                                  onChange={(e) => handleOnTime(e, index)}
                                  handleDiameter={32}
                                  offColor="#888888"
                                  onColor="#6fcab8"
                                  offHandleColor="#fff"
                                  onHandleColor="#18A689"
                                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                  height={25}
                                  width={90}
                                  borderRadius={20}
                                  uncheckedIcon={
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100%",
                                        color: "white",
                                        paddingRight: 25,
                                        fontSize:
                                          selectedLanguage === "ur" ? 25 : 15,
                                        paddingBottom:
                                          selectedLanguage === "ur" ? 10 : 0,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <GetLanguageString
                                        props={"events_participants_late"}
                                      />
                                    </div>
                                  }
                                  checkedIcon={
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100%",
                                        width: "60px",
                                        color: "white",
                                        paddingLeft: 5,
                                        fontSize:
                                          selectedLanguage === "ur" ? 19 : 12,
                                        paddingBottom:
                                          selectedLanguage === "ur" ? 5 : 0,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <GetLanguageString
                                        props={"events_participants_on_time"}
                                      />
                                    </div>
                                  }
                                  className="react-switch"
                                />
                              </Label>
                            </td>
                            {
                              ((absent = false),
                              (present = false),
                              (leave = false))
                            }
                          </tr>
                        );
                      })}
                    </tbody>
                    <tr>
                      <td colSpan="2">{pageInfo}</td>
                      <td colSpan="11">
                        <ul className={GetCustomStyles().page_style}>
                          <Pagination
                            activePage={pagination.activePage}
                            itemsCountPerPage={pagination.pageSize}
                            totalItemsCount={pagination.totalItem}
                            pageRangeDisplayed={pagination.pageRange}
                            onChange={handlePageChange}
                          />
                        </ul>
                      </td>
                    </tr>
                  </Table>
                </div>
              </>
            ) : (
              <NoResult />
            )
          ) : (
            LoadingSpinner()
          )}
        </div>
      </div>
    </>
  );
};

export default EventSelectiveParticipantList;
