import React, { useEffect } from "react";
import { CheckIsAdmin, CheckPermission } from "../../../constants/const";
import UpdateMemberPhotoForm from "./UpdateMemberPhotoForm";
const UpdateMemberPhoto = ({
  tab6,
  memberId,
  setImageUrlForDetails,
  imageUrl,
  setDiscard,
  discard,
  activeTab,
}) => {
  useEffect(()=>{
    if(tab6 === "active"){
      if(activeTab !== "tab6"){
        setDiscard(!discard);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);
  return (
    <div role="tabpanel" id="tab-6" className={"tab-pane " + tab6}>
      <div className="panel-body">
        <div style={{ width: "18rem" }}>
          <UpdateMemberPhotoForm
            tab6={tab6}
            memberId={memberId}
            setImageUrlForDetails={setImageUrlForDetails}
            imageUrl={imageUrl}
          />
        </div>
        {CheckPermission("CanRequestMemberModificationPersonalInfo") &&
        !CheckIsAdmin() ? (
          <div>
            <h4 className="text-error">* Request Modification</h4>
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default UpdateMemberPhoto;
