import React, { useEffect, useState } from "react";
import {
  useLocation,
  withRouter,
} from "react-router-dom/cjs/react-router-dom.min";
import { GetLanguageString, NoResult } from "../../helper/Components";
import { Button, Col, NavLink, Row, Spinner, Table } from "reactstrap";
import swal from "sweetalert";
import {
  CheckPermission,
  LoadingSpinner,
  convertUTCToLocalTime,
  isUrdu,
} from "../../../constants/const";
import moment from "moment";
import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import { useTranslation } from "react-i18next";
import MemberMudarisAddAssessment from "./MemberMudarisAddAssessment";
import { ApiMudaris } from "./ApiMudaris";
import MemberMudarisEditAssessment from "./MemberMudarisEditAssessment";
import MemberMudarisAddRegistration from "./MemberMudarisAddRegistration";
import MemberMudarisEditRegistration from "./MemberMudarisEditRegistration";
import MemberMudarisCancelRegistration from "./MemberMudarisCancelRegistration";
import { FcCheckmark } from "react-icons/fc";
import { RxCross2 } from "react-icons/rx";

const MemberMudarisDetails = ({
  memberId = 0,
  page = "",
  refresh,
  setRefresh,
  history,
}) => {
  const location = useLocation();
  const [update, setUpdate] = useState(false);
  const [mudarisInfo, setMudarisInfo] = useState();
  const [mudarisId, setMudarisId] = useState();
  const [assessmentId, setAssessmentId] = useState();
  const [registrationId, setRegistrationId] = useState();
  const [registrationDate, setRegistrationDate] = useState(null);
  const [createProfileButtonDisable, setCreateProfileButtonDisable] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [addAssessmentModal, setAddAssessmentModal] = useState(false);
  const [editAssessmentModal, setEditAssessmentModal] = useState(false);
  const [addRegistrationModal, setAddRegistrationModal] = useState(false);
  const [editRegistrationModal, setEditRegistrationModal] = useState(false);
  const [cancelRegistrationModal, setCancelRegistrationModal] = useState(false);

  const { t } = useTranslation();

  const [tab1, setTab1] = useState("active");
  const [tab2, setTab2] = useState("");

  if (memberId === 0) memberId = location?.state?.editValue;
  if (page === "") page = location?.state?.page;

  const selectedLanguage = GetSelectedLanguage();

  useEffect(() => {
    if (memberId !== 0 && memberId !== undefined) {
      setLoading(true);
      ApiMudaris()
        .GetMemberMudarisInfo(memberId)
        .then((res) => {
          setMudarisInfo(res.data);
          setMudarisId(res.data.mudarisId);
          setLoading(false);
        })
        .catch((err) => {
          swal({
            title: "Error: " + err.response?.data || err,
            icon: "error",
            buttons: "OK",
          });
          setLoading(false);
          console.log(err.response.data);
        });
    }
  }, [memberId, update]);

  const handleOnDeleteAssessment = (e) => {
    const { value } = e.target;
    swal({
      title: t("common_delete_message"),
      text: t("common_delete_detail_message"),
      className: GetSelectedLanguage() === "en" ? "" : "ur",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        ApiMudaris()
          .DeleteMudarisAssessment(value)
          .then((res) => {
            swal({
              title: t(
                "member_member_detail_mudaris_details_assessments_add_assessment_delete"
              ),
              className: GetSelectedLanguage() === "en" ? "" : "ur",
              icon: "success",
              buttons: "OK",
            });
            setUpdate(!update);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            swal({
              title: err,
              icon: "error",
              buttons: "OK",
            });
            setLoading(false);
          });
      }
    });
  };

  const handleUndoDeleteRegistration = (e) => {
    const { value } = e.target;
    swal({
      title: t("common_delete_message"),
      className: GetSelectedLanguage() === "en" ? "" : "ur",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        ApiMudaris()
          .UndoDeleteMudarisRegistration(value)
          .then((res) => {
            swal({
              title: t(
                "member_member_detail_mudaris_details_registrations_add_registrations_undo_delete"
              ),
              className: GetSelectedLanguage() === "en" ? "" : "ur",
              icon: "success",
              buttons: "OK",
            });
            setUpdate(!update);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            swal({
              title: err,
              icon: "error",
              buttons: "OK",
            });
            setLoading(false);
          });
      }
    });
  };

  const createProfile = () => {
    setCreateProfileButtonDisable(true);
    ApiMudaris()
      .CreateMudarisProfile(memberId)
      .then((res) => {
        if (res.data.includes("Created")) {
          swal({
            title: t(
              "member_member_detail_mudaris_details_create_profile_success_message"
            ),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "success",
            buttons: {
              cancel: "Go Back",
              confirm: t(
                "member_member_detail_mudaris_details_create_profile_add_more_data"
              ),
            },
          }).then(
            (response) =>
              response &&
              history.push({
                pathname: `/member/mudaris/${memberId}`,
                state: {
                  memberId: memberId,
                  page: "edit",
                },
              })
          );
          setRefresh(!refresh);
        }
      })
      .catch((err) => {
        swal({
          title: "Error: " + err.response?.data || err,
          icon: "error",
          buttons: "OK",
        });
        setLoading(false);
        console.log(err.response.data);
      });
  };

  return (
    <div className="wrapper wrapper-content animated fadeInRight">
      {addAssessmentModal ? (
        <MemberMudarisAddAssessment
          setAddAssessmentModal={setAddAssessmentModal}
          mudarisId={mudarisId}
          setUpdate={setUpdate}
          update={update}
        />
      ) : null}{" "}
      {editAssessmentModal ? (
        <MemberMudarisEditAssessment
          setEditAssessmentModal={setEditAssessmentModal}
          assessmentId={assessmentId}
          mudarisId={mudarisId}
          setUpdate={setUpdate}
          update={update}
        />
      ) : null}
      {addRegistrationModal ? (
        <MemberMudarisAddRegistration
          setAddRegistrationModal={setAddRegistrationModal}
          mudarisId={mudarisId}
          setUpdate={setUpdate}
          update={update}
        />
      ) : null}{" "}
      {editRegistrationModal ? (
        <MemberMudarisEditRegistration
          setEditRegistrationModal={setEditRegistrationModal}
          registrationId={registrationId}
          mudarisId={mudarisId}
          setUpdate={setUpdate}
          update={update}
        />
      ) : null}
      {cancelRegistrationModal ? (
        <MemberMudarisCancelRegistration
          setCancelRegistrationModal={setCancelRegistrationModal}
          registrationId={registrationId}
          setUpdate={setUpdate}
          update={update}
          registrationDate={registrationDate}
        />
      ) : null}
      {!loading ? (
        !mudarisInfo?.isMudaris ? (
          <div className="text-center">
            <h4
              className="text-error text-center"
              style={{ fontStyle: "italic", fontWeight: 600 }}
            >
              <GetLanguageString props="member_member_detail_mudaris_details_no_profile" />
            </h4>
            <br></br>
            {CheckPermission("CanAddMudaris") ? (
              <Button
                size="sm"
                color="primary"
                className="text-center"
                onClick={createProfile}
              >
                {createProfileButtonDisable ? (
                  <Spinner size="sm" />
                ) : (
                  <GetLanguageString props="member_member_detail_mudaris_details_create_profile" />
                )}
              </Button>
            ) : null}
          </div>
        ) : (
          <>
            {location.pathname !== `/member/updateMember/${memberId}` &&
            (page === "edit" || page === "reports") ? (
              <>
                <div
                  className="ibox-content"
                  style={{
                    backgroundColor: "#264058",
                    color: "white",
                    opacity: 0.8,
                  }}
                >
                  <Row>
                    <Col md="4">
                      <Row>
                        <h1 style={{ fontSize: "40px" }} className="ur">
                          {mudarisInfo.name}
                        </h1>
                      </Row>
                      <Row>
                        <h3
                          style={
                            selectedLanguage === "ur"
                              ? { fontSize: "20px", fontWeight: "lighter" }
                              : null
                          }
                        >
                          {<GetLanguageString props="member_fathers_name" />} :{" "}
                          <span
                            style={{ fontSize: "20px", fontWeight: "lighter" }}
                            className="ur"
                          >
                            {mudarisInfo.fatherName}
                          </span>{" "}
                        </h3>
                      </Row>
                    </Col>
                    <Col md="4">
                      <Row className="text-center m-3">
                        <h3
                          style={{ fontSize: "30px", unicodeBidi: "plaintext" }}
                        >
                          {mudarisInfo.regNo}
                        </h3>
                      </Row>
                    </Col>
                    <Col md="4">
                      <div className={GetCustomStyles().btn_style}>
                        <Row>
                          <h3
                            style={
                              selectedLanguage === "ur"
                                ? { fontSize: "20px", fontWeight: "lighter" }
                                : null
                            }
                          >
                            {
                              <GetLanguageString props="member_responsibility" />
                            }{" "}
                            :{" "}
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "lighter",
                              }}
                              className="ur"
                            >
                              {mudarisInfo.responsibilityName}
                            </span>
                          </h3>
                        </Row>
                        <Row>
                          <h3
                            style={
                              selectedLanguage === "ur"
                                ? { fontSize: "20px", fontWeight: "lighter" }
                                : null
                            }
                          >
                            {<GetLanguageString props="unit" />} :{" "}
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: "lighter",
                              }}
                              className="ur"
                            >
                              {mudarisInfo.unitName}
                            </span>
                          </h3>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
                <br></br>
                <br></br>
              </>
            ) : null}
            <Row>
              <div className="tabs-container">
                <ul className="nav nav-tabs" role="tablist">
                  <li>
                    <NavLink
                      to="#"
                      className={"nav-link " + tab1}
                      onClick={() => {
                        setTab1("active");
                        setTab2("");
                      }}
                    >
                      <h5>
                        {
                          <GetLanguageString props="member_member_detail_mudaris_details_assessments" />
                        }
                      </h5>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="#"
                      className={"nav-link " + tab2}
                      onClick={() => {
                        setTab1("");
                        setTab2("active");
                      }}
                    >
                      <h5>
                        {
                          <GetLanguageString props="member_member_detail_mudaris_details_registrations" />
                        }
                      </h5>
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="tab-content">
                <div role="tabpanel" id="tab-1" className={"tab-pane " + tab1}>
                  <div className="panel-body">
                    <Col md="12">
                      <Row>
                        <div>
                          {page === "edit" ? (
                            <Button
                              size="sm"
                              color="success"
                              className={GetCustomStyles().unit_btn_style}
                              onClick={() => {
                                setAddAssessmentModal(true);
                              }}
                            >
                              {
                                <GetLanguageString props="member_member_detail_mudaris_details_assessments_add_assessment" />
                              }
                            </Button>
                          ) : null}
                        </div>
                      </Row>
                      <Row>
                        {mudarisInfo?.assessments.length !== 0 ? (
                          <Table responsive bordered>
                            <thead>
                              <tr className="text-center">
                                {" "}
                                <th>#</th>
                                <th>
                                  {
                                    <GetLanguageString props="member_member_detail_mudaris_details_assessments_assessment" />
                                  }
                                </th>
                                <th>
                                  {
                                    <GetLanguageString props="member_member_detail_mudaris_details_assessments_assessment_status" />
                                  }
                                </th>
                                <th>
                                  {
                                    <GetLanguageString props="member_member_detail_mudaris_details_assessments_obtained_marks" />
                                  }
                                </th>
                                <th>
                                  {
                                    <GetLanguageString props="member_member_detail_mudaris_details_assessments_date" />
                                  }
                                </th>
                                <th>
                                  {
                                    <GetLanguageString props="member_member_detail_course_comments" />
                                  }
                                </th>
                                <th>
                                  {
                                    <GetLanguageString props="member_reference_note" />
                                  }
                                </th>
                                {page === "edit" ? (
                                  <th>
                                    {
                                      <GetLanguageString props="common_action" />
                                    }
                                  </th>
                                ) : null}
                              </tr>
                            </thead>
                            <tbody>
                              {mudarisInfo?.assessments.map((record, index) => {
                                return (
                                  <>
                                    <tr className="text-center">
                                      <td key={index}>{index + 1}</td>
                                      <td className="ur">
                                        {record.assessmentName}
                                      </td>
                                      <td className="ur">
                                        {record.assessmentStatus
                                          ? record.assessmentStatus
                                          : "-"}
                                      </td>
                                      <td>
                                        {record.obtainedMarks
                                          ? record.obtainedMarks
                                          : "-"}
                                      </td>
                                      <td>
                                        {record.date
                                          ? moment(
                                              convertUTCToLocalTime(record.date)
                                            ).format("DD/MM/yyyy")
                                          : "-"}
                                      </td>
                                      <td
                                        className={
                                          isUrdu(record.comments) ? "ur" : ""
                                        }
                                      >
                                        {record.comments
                                          ? record.comments
                                          : "-"}
                                      </td>
                                      <td
                                        className={
                                          isUrdu(record.referenceNote)
                                            ? "ur"
                                            : ""
                                        }
                                      >
                                        {record.referenceNote
                                          ? record.referenceNote
                                          : "-"}
                                      </td>
                                      {page === "edit" ? (
                                        <td>
                                          {" "}
                                          <Button
                                            size="sm"
                                            color="white"
                                            onClick={() => {
                                              setAssessmentId(record.id);
                                              setEditAssessmentModal(true);
                                            }}
                                          >
                                            {
                                              <GetLanguageString props="common_edit" />
                                            }
                                          </Button>{" "}
                                          <Button
                                            size="sm"
                                            color="white"
                                            onClick={handleOnDeleteAssessment}
                                            value={record.id}
                                          >
                                            {
                                              <GetLanguageString props="common_delete" />
                                            }
                                          </Button>
                                        </td>
                                      ) : null}
                                    </tr>
                                  </>
                                );
                              })}
                            </tbody>
                          </Table>
                        ) : (
                          <NoResult />
                        )}
                      </Row>
                    </Col>
                  </div>
                </div>
                <div role="tabpanel" id="tab-2" className={"tab-pane " + tab2}>
                  <div className="panel-body">
                    <Col md="12">
                      <Row>
                        <div>
                          {page === "edit" ? (
                            <Button
                              size="sm"
                              color="success"
                              className={GetCustomStyles().unit_btn_style}
                              onClick={() => {
                                setAddRegistrationModal(true);
                              }}
                            >
                              {
                                <GetLanguageString props="member_member_detail_mudaris_details_registrations_add" />
                              }
                            </Button>
                          ) : null}
                        </div>
                      </Row>
                      <Row>
                        {mudarisInfo?.registrations.length !== 0 ? (
                          <Table responsive bordered>
                            <thead>
                              <tr className="text-center">
                                {" "}
                                <th>#</th>
                                <th>
                                  {
                                    <GetLanguageString props="member_member_detail_mudaris_details_registrations_from_date" />
                                  }
                                </th>
                                <th>
                                  {
                                    <GetLanguageString props="member_member_detail_mudaris_details_registrations_to_date" />
                                  }
                                </th>
                                <th>
                                  {
                                    <GetLanguageString props="member_member_detail_mudaris_registered" />
                                  }
                                </th>
                                <th>
                                  {
                                    <GetLanguageString props="member_member_detail_mudaris_accepted" />
                                  }
                                </th>
                                <th>
                                  {
                                    <GetLanguageString props="member_member_detail_mudaris_markazi" />
                                  }
                                </th>
                                <th>
                                  {<GetLanguageString props="common_remarks" />}
                                </th>
                                <th>
                                  {
                                    <GetLanguageString props="member_reference_note" />
                                  }
                                </th>{" "}
                                {page === "edit" ? (
                                  <th>
                                    {
                                      <GetLanguageString props="common_action" />
                                    }
                                  </th>
                                ) : null}
                              </tr>
                            </thead>
                            <tbody>
                              {mudarisInfo?.registrations.map(
                                (record, index) => {
                                  return (
                                    <>
                                      <tr className="text-center">
                                        <td key={index}>{index + 1}</td>
                                        <td>
                                          {record.fromDate
                                            ? moment(
                                                convertUTCToLocalTime(
                                                  record.fromDate
                                                )
                                              ).format("DD/MM/yyyy")
                                            : "-"}
                                        </td>
                                        <td>
                                          {record.toDate
                                            ? moment(
                                                convertUTCToLocalTime(
                                                  record.toDate
                                                )
                                              ).format("DD/MM/yyyy")
                                            : "-"}
                                        </td>{" "}
                                        <td>
                                          {record.registered ? (
                                            <FcCheckmark
                                              size={18}
                                              color="green"
                                              style={{
                                                marginRight: 3,
                                                marginBottom: 1,
                                              }}
                                            />
                                          ) : (
                                            <RxCross2
                                              size={18}
                                              color="red"
                                              style={{
                                                marginRight: 3,
                                                marginBottom: 1,
                                              }}
                                            />
                                          )}
                                        </td>
                                        <td>
                                          {record.accepted ? (
                                            <FcCheckmark
                                              size={18}
                                              color="green"
                                              style={{
                                                marginRight: 3,
                                                marginBottom: 1,
                                              }}
                                            />
                                          ) : (
                                            <RxCross2
                                              size={18}
                                              color="red"
                                              style={{
                                                marginRight: 3,
                                                marginBottom: 1,
                                              }}
                                            />
                                          )}
                                        </td>
                                        <td>
                                          {record.markazi ? (
                                            <FcCheckmark
                                              size={18}
                                              color="green"
                                              style={{
                                                marginRight: 3,
                                                marginBottom: 1,
                                              }}
                                            />
                                          ) : (
                                            <RxCross2
                                              size={18}
                                              color="red"
                                              style={{
                                                marginRight: 3,
                                                marginBottom: 1,
                                              }}
                                            />
                                          )}
                                        </td>
                                        <td
                                          className={
                                            isUrdu(record.remarks) ? "ur" : ""
                                          }
                                        >
                                          {record.remarks
                                            ? record.remarks
                                            : "-"}
                                        </td>
                                        <td
                                          className={
                                            isUrdu(record.referenceNote)
                                              ? "ur"
                                              : ""
                                          }
                                        >
                                          {record.referenceNote
                                            ? record.referenceNote
                                            : "-"}
                                        </td>
                                        {page === "edit" ? (
                                          <td>
                                            {record.registered ? (
                                              <>
                                                <Button
                                                  size="sm"
                                                  color="white"
                                                  onClick={() => {
                                                    setRegistrationId(
                                                      record.id
                                                    );
                                                    setEditRegistrationModal(
                                                      true
                                                    );
                                                  }}
                                                >
                                                  {
                                                    <GetLanguageString props="common_edit" />
                                                  }
                                                </Button>
                                                <Button
                                                  size="sm"
                                                  color="white"
                                                  onClick={() => {
                                                    setRegistrationDate(
                                                      record.fromDate
                                                    );

                                                    setRegistrationId(
                                                      record.id
                                                    );
                                                    setCancelRegistrationModal(
                                                      true
                                                    );
                                                  }}
                                                >
                                                  {
                                                    <GetLanguageString props="member_cancel_registration" />
                                                  }
                                                </Button>
                                              </>
                                            ) : (
                                              <Button
                                                size="sm"
                                                color="white"
                                                onClick={
                                                  handleUndoDeleteRegistration
                                                }
                                                value={record.id}
                                              >
                                                {
                                                  <GetLanguageString props="member_undo_cancel_registration" />
                                                }
                                              </Button>
                                            )}
                                          </td>
                                        ) : null}
                                      </tr>
                                    </>
                                  );
                                }
                              )}
                            </tbody>
                          </Table>
                        ) : (
                          <NoResult />
                        )}
                      </Row>
                    </Col>
                  </div>
                </div>
              </div>
            </Row>
          </>
        )
      ) : (
        LoadingSpinner()
      )}
    </div>
  );
};

export default withRouter(MemberMudarisDetails);
