import React from "react";
import MemberMudarisDetails from "../MemberMudaris/MemberMudarisDetails";

const MemberMudarisInfo = ({ tab12, editValue, refresh, setRefresh }) => {
  return (
    <div role="tabpanel" id="tab-12" className={"tab-pane " + tab12}>
      <div className="panel-body" style={{ backgroundColor: "#f9f9f9" }}>
        <MemberMudarisDetails
          memberId={editValue}
          page="details"
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </div>
    </div>
  );
};

export default MemberMudarisInfo;
