import React, { useState } from "react";
import { 
  FormGroup, 
  Form, 
  Label, 
  Input, 
  Button, 
  ModalBody, 
  ModalFooter, 
  FormFeedback,
  Modal,
  ModalHeader, 
} from 'reactstrap';
import { ApiTutorialCategory } from "./ApiTutorialCategory";
import swal from "sweetalert";
import { GetSelectedLanguage } from "../../helper/Method";
import { GetLanguageString } from "../../helper/Components";
import { t } from "i18next";
import { LoadingSpinner } from "../../../constants/const";
import Draggable from "react-draggable";

const EditTutorialCategory = ({ selectedCategory, setEditModal, refresh, setRefresh }) => {
  const [id] = useState(selectedCategory.value)
  const [formData, setFormData] = useState({
    categoryName: selectedCategory.label
  });
  const [errors, setErrors] = useState();
  const [loading, setLoading] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  const validate = () => {
    let temp = {};
    temp.categoryName = formData.categoryName ? (
      ""
    ) : (
      <GetLanguageString props="tutorial_category_error_message" />
    );

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    })
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(validate()){
      setLoading(true);
      setButtonDisable(true);
      ApiTutorialCategory()
        .updateCategory(id, formData)
        .then(() => {
          swal({
            title: t("video_tutorial_edit_msg"),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "success",
            buttons: GetSelectedLanguage() === "en" ? "OK" : "ٹھیک ہے ",
          });
          setRefresh(!refresh);
          setEditModal(false);
          setLoading(false);
          setButtonDisable(false);
        })
        .catch((err) => {
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
          setLoading(false);
          setButtonDisable(false);
        });
      } 
  };

  return (
    <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
      <Modal isOpen={true} className="inmodal" autoFocus={false}> 
        <ModalHeader className="modal-title" tag="h4">
          <GetLanguageString props="tutorial_edit_category" />
        </ModalHeader>
        {!loading ? (
          <Form onSubmit={handleSubmit}>
            <ModalBody>
              <FormGroup>
                <Label>
                  <GetLanguageString props="tutorial_category" />
                </Label>
                <Input
                  autoFocus
                  type="text"
                  name="categoryName"
                  className="no-drag"
                  value={formData.categoryName}
                  onChange={handleInputChange}
                  {...(errors?.categoryName && { invalid: true })}
                  />
                  {errors?.categoryName && (
                    <FormFeedback>
                      <h4>{errors?.categoryName}</h4>
                    </FormFeedback>
                  )}
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="white" onClick={() => setEditModal(false)}>
                <GetLanguageString props="common_cancel" />
              </Button>
              <Button color="primary" type="submit" disabled={buttonDisable}>
                <GetLanguageString props="common_save" />
              </Button>{" "}
            </ModalFooter>
          </Form>
        ) : (
          LoadingSpinner()
        )}
      </Modal>
    </Draggable>
  );
};

export default EditTutorialCategory;
