import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Label,
  Button,
  FormFeedback,
  Input,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { GetFooTableCustomStyles } from "../helper/Method";
import useForm from "../shared/useForm";
import Draggable from "react-draggable";

import "./Helper.css";

export const GetLanguageString = ({ props, value = null }) => {
  const selected = localStorage.getItem("i18nextLng");

  const { t } = useTranslation();

  if (value !== null) {
    return <span className={selected}>{t(props, (value = { value }))}</span>;
  } else {
    return <span className={selected}>{t(props)}</span>;
  }
};

export const NoResult = () => {
  return (
    <h4 className="no-results text-centre">
      {<GetLanguageString props="common_no_result" />}{" "}
    </h4>
  );
};

export const SetReportTitle = ({ setModal, setReportTitle }) => {
  const { errors, setErrors, values, handleInputChange } = useForm({
    title: "",
  });
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  const toggle = () => setModal(false);

  const validate = () => {
    let temp = {};
    temp.title = values.title ? "" : "Please enter a title";
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setReportTitle(values.title);
      setModal(false);
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal">
          <ModalHeader className="modal-title" tag="h4">
            Report Title
          </ModalHeader>
          <Form>
            <ModalBody>
              <FormGroup>
                <Label>Enter Title</Label>
                <Input
                  type="text"
                  name="title"
                  className="no-drag"
                  value={values.title}
                  onChange={handleInputChange}
                  {...(errors.title && { invalid: true })}
                />
                {errors.title && (
                  <FormFeedback>
                    <h4>{errors.title}</h4>
                  </FormFeedback>
                )}
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="white" onClick={toggle}>
                Go Back
              </Button>
              <Button color="white" onClick={handleSubmit}>
                Print
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </Draggable>
    </div>
  );
};

export const GetSortingUI = ({ sortObject, name, isReport = false }) => {
  
  return(
    <>
     <span className={isReport ? "noprint " + GetFooTableCustomStyles().span_style : GetFooTableCustomStyles().span_style}>
      { sortObject[name] === 'neutral' ? (
        <>
        <i className={GetFooTableCustomStyles().up_down_style} />
        <i className={GetFooTableCustomStyles().down_up_style} />
        </>
      ): sortObject[name] === 'ascending' ? 
      <i className={GetFooTableCustomStyles().up_style} /> : 
      <i className={GetFooTableCustomStyles().down_style} />
      }
    </span>
    </>
  )
};