import Lottie from "lottie-react";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { BiSolidEdit } from "react-icons/bi";
import { BsTrash3Fill } from "react-icons/bs";
import { FaRegFileArchive } from "react-icons/fa";
import { FaFolderPlus, FaRegFileImage, FaRegFilePdf } from "react-icons/fa6";
import { FcFile, FcFolder, FcOpenedFolder, FcVideoCall } from "react-icons/fc";
import { FiDownload, FiUpload } from "react-icons/fi";
import { IoIosClose } from "react-icons/io";
import { RiFileExcel2Line, RiFilePpt2Line } from "react-icons/ri";
import { TbFileTypeZip, TbHomeFilled } from "react-icons/tb";
import { Link } from "react-router-dom/cjs/react-router-dom";
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Row,
  Table,
  Tooltip,
} from "reactstrap";
import swal from "sweetalert";
import {
  CheckPermission,
  LoadingSpinner,
  applyClassToCharacters,
  capitalizeFirstLetter,
  isUrdu,
} from "../../constants/const";
import { GetLanguageString } from "../helper/Components";
import { GetCustomStyles, GetSelectedLanguage } from "../helper/Method";
import AddNewFolder from "./AddNewFolder";
import { ApiDocuments, ApiFolders } from "./ApiDocuments";
import EditFile from "./EditFile";
import EditFolder from "./EditFolder";
import UploadFile from "./UploadFile";
import UploadStatus from "./UploadStatus";
import downloadingAnimation from "./downloadingIconAnimation.json";

const Documents = () => {
  const { t } = useTranslation();
  const [folderId, setFolderId] = useState(0);
  const [editValue, setEditValue] = useState();
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [uploadRefresh, setUploadRefresh] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [foldersBreadCrumbs, setFoldersBreadCrumbs] = useState([]);
  const [newFolderModal, setNewFolderModal] = useState(false);
  const [uploadFileModal, setUploadFileModal] = useState(false);
  const [editFolderModal, setEditFolderModal] = useState(false);
  const [editFileModal, setEditFileModal] = useState(false);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [selectedFile, setSelectedFile] = useState(documents[0]);
  const [detailBox, setDetailBox] = useState(false);

  const [droppedFile, setDroppedFile] = useState(null);
  const [error, setError] = useState(null);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const uploadedFile = acceptedFiles[0];

      // File size limit (26 MB = 26 * 1024 * 1024 bytes)
      const maxSizeBytes = 26214400; // 26 MB in bytes

      if (uploadedFile.size > maxSizeBytes) {
        setError(t("file_size_limit_exceed_error"));
        clearErrorAfterDelay();
        return;
      }

      // File type check (no executable files)
      const fileType =
        uploadedFile.type || uploadedFile.name.split(".").pop().toLowerCase();
      if (
        fileType === "exe" ||
        fileType === "msi" ||
        fileType === "application/x-msdownload"
      ) {
        setError(t("file_type_error"));
        clearErrorAfterDelay();
        return;
      }

      setDroppedFile(uploadedFile);
      setError(null); // Reset error state
      setUploadFileModal(true);
    },
    [t]
  );

  const clearErrorAfterDelay = () => {
    setTimeout(() => {
      setError(null);
    }, 3000); // 5000 milliseconds = 5 seconds
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
  });

  const [tooltipOpen, setTooltipOpen] = useState({});

  const toggleTooltip = (action, index) => {
    setTooltipOpen((prev) => ({
      ...prev,
      [`${action}-${index}`]: !prev[`${action}-${index}`],
    }));
  };

  useEffect(() => {
    setLoading(true);
    ApiFolders()
      .getFolderHierarchy(folderId)
      .then((res) => {
        setFoldersBreadCrumbs(res.data);
        // setFolderId(res.data[0].value);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
      });
  }, [folderId]);

  useEffect(() => {
    setLoading(true);
    ApiDocuments()
      .getDocuments(folderId)
      .then((res) => {
        setDocuments(res.data);
        setHoveredRowIndex(null);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
      });
  }, [folderId, refresh]);

  useEffect(() => {
    ApiDocuments()
      .getDocuments(folderId)
      .then((res) => {
        setDocuments(res.data);
        setHoveredRowIndex(null);
      })
      .catch((err) => {
        console.log(err);
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
      });
    // eslint-disable-next-line
  }, [uploadRefresh]);

  const [isDownloading, setIsDownloading] = useState(
    Array(documents?.length).fill(false)
  );

  const handleMouseEnter = (index) => {
    setHoveredRowIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };

  const handleRowClick = (e, index) => {
    if (!e.target.closest(".unclickable-column")) {
      setSelectedRowIndex(index === selectedRowIndex ? null : index);
    }
  };

  const showIcon = (index) => {
    return hoveredRowIndex === index || selectedRowIndex === index;
  };

  const formatSize = (sizeInBytes) => {
    const units = ["bytes", "KB", "MB", "GB", "TB"];
    let size = sizeInBytes;
    let unitIndex = 0;

    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }

    return `${size.toFixed(2)} ${units[unitIndex]}`;
  };
  const handleOnDelete = async (id, type) => {
    swal({
      title: t("common_delete_message"),
      text: t("common_delete_detail_message"),
      className: GetSelectedLanguage() === "en" ? "" : "ur",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      // <-- Notice "async" here
      if (!willDelete) return; // Exit if user cancels

      const isFolder = type.includes("folder");
      const api = isFolder ? ApiFolders() : ApiDocuments();
      const successMessageKey = isFolder
        ? "document_delete_folder_success"
        : "document_delete_file_success";

      try {
        setLoading(true);

        await api.delete(id);

        swal({
          title: t(successMessageKey),
          className: GetSelectedLanguage() === "en" ? "" : "ur",
          icon: "success",
          buttons: "OK",
        });

        setRefresh(!refresh);
      } catch (err) {
        console.error(err);
        swal({
          title: err.response?.data,
          icon: "error",
          buttons: "OK",
        });
      } finally {
        setLoading(false);
      }
    });
  };
  const getUnitName = (unitName) => {
    return (
      <ol className="breadcrumb">
        {unitName?.split("/").map((item, index) => {
          return (
            <li
              className={
                GetSelectedLanguage() === "ur"
                  ? "breadcrumb-item-ur"
                  : "breadcrumb-item"
              }
              key={index}
            >
              <span>{applyClassToCharacters(item, "ur")}</span>
            </li>
          );
        })}
      </ol>
    );
  };
  const handleDownload = async (fileId, index) => {
    const updateLoadingState = (isLoading) => {
      setIsDownloading((prevStates) => {
        const newLoadingStates = [...prevStates];
        newLoadingStates[index] = isLoading;
        return newLoadingStates;
      });
    };

    updateLoadingState(true);

    try {
      const response = await ApiDocuments().downloadDocument(fileId);

      if (response.status === 200) {
        const modifiedLink = `${response.data.downloadLink}&confirm=t`;

        // Open link in the current tab/window:
        window.location.href = modifiedLink;
      } else {
        swal({
          title: t(response.data),
          icon: "error",
          buttons: "OK",
        });
        console.error("Error getting download link:", response.data);
      }
    } catch (error) {
      swal({
        title: error.message,
        icon: "error",
        buttons: "OK",
      });
    } finally {
      updateLoadingState(false);
    }
  };

  const ButtonsComponent = (className) => {
    return CheckPermission("CanAddDocuments") ? (
      <ButtonGroup className={className}>
        <Button color="white" onClick={() => setNewFolderModal(true)}>
          <FaFolderPlus size={15} color="#FFCA28" />
          &nbsp; <GetLanguageString props={"document_new_folder"} />
        </Button>
        &nbsp;&nbsp;
        <Button
          color="white"
          onClick={() => {
            setUploadFileModal(true);
          }}
        >
          {" "}
          <FiUpload size={15} color="#35A29F" />
          &nbsp;
          <GetLanguageString props={"document_upload_file"} />
        </Button>
      </ButtonGroup>
    ) : null;
  };
  const handleBreadCrumbs = (value) => {
    setFolderId(value);
  };
  return (
    <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
      {newFolderModal ? (
        <AddNewFolder
          setNewFolderModal={setNewFolderModal}
          parentFolderId={folderId}
          setRefresh={setRefresh}
        />
      ) : null}
      {editFolderModal ? (
        <EditFolder
          setEditFolderModal={setEditFolderModal}
          parentFolderId={folderId}
          setRefresh={setRefresh}
          folderId={editValue}
        />
      ) : null}
      {editFileModal ? (
        <EditFile
          setEditFileModal={setEditFileModal}
          parentFolderId={folderId}
          setRefresh={setRefresh}
          fileId={editValue}
        />
      ) : null}
      {uploadFileModal ? (
        <UploadFile
          setUploadFileModal={setUploadFileModal}
          folderId={folderId}
          setRefresh={setUploadRefresh}
          refresh={uploadRefresh}
          documents={documents}
          setDocuments={setDocuments}
          droppedFile={droppedFile}
          setDroppedFile={setDroppedFile}
        />
      ) : null}
      <div className="ibox">
        <div className="ibox-title">
          <h5>
            <GetLanguageString props={"sidebar_documents"} />
          </h5>
        </div>
        <div className="ibox-content">
          <Row>
            <Col md="6">
              <ol className="breadcrumb" style={{ marginTop: 8 }}>
                {foldersBreadCrumbs?.map((item, index) => {
                  return (
                    <li
                      className={
                        GetSelectedLanguage() === "ur"
                          ? "breadcrumb-item-ur"
                          : "breadcrumb-item"
                      }
                      key={index}
                      onClick={() => {
                        handleBreadCrumbs(item.value);
                      }}
                    >
                      <Link to="#">
                        {index === 0 ? (
                          <TbHomeFilled
                            size={14}
                            color={
                              foldersBreadCrumbs.length - 1 === index
                                ? "#18A689"
                                : "black"
                            }
                          />
                        ) : index === foldersBreadCrumbs.length - 1 ? (
                          <FcOpenedFolder size={16} />
                        ) : (
                          <FcFolder size={16} />
                        )}
                        &nbsp;
                        <span
                          className={isUrdu(item.name) ? "ur" : ""}
                          style={{
                            display: "inline-block",
                            fontWeight:
                              foldersBreadCrumbs.length - 1 === index
                                ? 600
                                : 500,
                            color:
                              foldersBreadCrumbs.length - 1 === index
                                ? "#18A689"
                                : "black",
                          }}
                        >
                          {item.label}
                        </span>
                      </Link>
                    </li>
                  );
                })}
              </ol>
            </Col>
            <Col md="6">
              {documents.length !== 0
                ? ButtonsComponent(GetCustomStyles().unit_btn_style)
                : null}
            </Col>
          </Row>{" "}
          {!loading ? (
            documents.length !== 0 ? (
              <div
                {...getRootProps()}
                style={{ position: "relative", marginTop: "20px" }}
              >
                <input {...getInputProps()} />
                <Row>
                  <Col md={detailBox ? "9" : "12"}>
                    <Table responsive hover bordered>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>
                            <GetLanguageString props={"common_name"} />
                          </th>
                          <th>
                            <GetLanguageString
                              props={
                                "member_member_detail_document_upload_date"
                              }
                            />
                          </th>
                          <th>
                            <GetLanguageString
                              props={"settings_document_type"}
                            />
                          </th>
                          <th>
                            <GetLanguageString
                              props={"member_member_detail_document_size"}
                            />
                          </th>
                          <th>
                            <GetLanguageString
                              props={"dashboard_event_page_status"}
                            />
                          </th>
                          <th className="unclickable-column"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {documents.map((file, index) => {
                          return (
                            <tr
                              key={index}
                              onMouseEnter={() => handleMouseEnter(index)}
                              onMouseLeave={handleMouseLeave}
                              style={{
                                backgroundColor:
                                  selectedRowIndex === index ||
                                  hoveredRowIndex === index
                                    ? "#DBECE6"
                                    : "white",
                                cursor: "pointer",
                              }}
                              onDoubleClick={(e) => {
                                if (
                                  !e.target.closest(".unclickable-column") &&
                                  file.fileType.includes("folder")
                                ) {
                                  setFolderId(file.id);
                                }
                              }}
                              onClick={(e) => {
                                handleRowClick(e, index);
                                if (
                                  !file.fileType.includes("folder") &&
                                  !e.target.closest(".unclickable-column")
                                ) {
                                  setSelectedFile(file);
                                  setDetailBox(true);
                                } else setDetailBox(false);
                              }}
                            >
                              <td>{index + 1}</td>
                              <td>
                                {" "}
                                {file.fileType.includes("folder") ? (
                                  <FcFolder
                                    size={16}
                                    style={{ marginRight: 5, marginBottom: 3 }}
                                  />
                                ) : file.fileType.includes("pdf") ? (
                                  <FaRegFilePdf
                                    size={16}
                                    color="red"
                                    style={{ marginRight: 5, marginBottom: 3 }}
                                  />
                                ) : file.fileType.includes("jpg") ||
                                  file.fileType.includes("png") ||
                                  file.fileType.includes("jpeg") ? (
                                  <FaRegFileImage
                                    size={16}
                                    color="#35A29F"
                                    style={{ marginRight: 5, marginBottom: 3 }}
                                  />
                                ) : file.fileType.includes("pptx") ? (
                                  <RiFilePpt2Line
                                    size={16}
                                    color="#B94623"
                                    style={{ marginRight: 5, marginBottom: 3 }}
                                  />
                                ) : file.fileType.includes("mp4") ? (
                                  <FcVideoCall
                                    size={16}
                                    color="#5C6E91"
                                    style={{ marginRight: 5, marginBottom: 3 }}
                                  />
                                ) : file.fileType.includes(
                                    "x-zip-compressed"
                                  ) ? (
                                  <TbFileTypeZip
                                    size={16}
                                    color="#5A509B"
                                    style={{ marginRight: 5, marginBottom: 3 }}
                                  />
                                ) : file.fileType.includes("x-compressed") ? (
                                  <FaRegFileArchive
                                    size={16}
                                    color="#5A509B"
                                    style={{ marginRight: 5, marginBottom: 3 }}
                                  />
                                ) : file.fileType.includes("spreadsheet") ? (
                                  <RiFileExcel2Line
                                    size={16}
                                    color="#009440"
                                    style={{ marginRight: 5, marginBottom: 3 }}
                                  />
                                ) : (
                                  <FcFile
                                    size={16}
                                    style={{ marginRight: 5, marginBottom: 3 }}
                                  />
                                )}
                                &nbsp;{applyClassToCharacters(file.name, "ur")}
                              </td>
                              <td className={"en"}>
                                {moment(file.createdDate)
                                  .locale(GetSelectedLanguage())
                                  .format("DD/MM/YYYY")}{" "}
                                &nbsp;
                                {GetSelectedLanguage() === "ur"
                                  ? moment(file.createdDate)
                                      .locale(GetSelectedLanguage())
                                      .format("a\u00a0h:mm")
                                  : moment(file.createdDate)
                                      .locale(GetSelectedLanguage())
                                      .format("h:mm a")}
                              </td>
                              <td>
                                {file.documentType ? file.documentType : "-"}
                              </td>
                              <td>{file.size ? formatSize(file.size) : "-"}</td>
                              <td>
                                <UploadStatus
                                  fileStatus={file.fileStatus}
                                  jobId={file.fileUploadBackgroundJobId}
                                  setRefresh={setUploadRefresh}
                                  refresh={uploadRefresh}
                                  fileId={file.id}
                                />
                              </td>
                              <td
                                className="text-center unclickable-column"
                                style={{ width: "130px" }}
                              >
                                {file.driveId ? (
                                  <div>
                                    {CheckPermission("CanDownloadDocuments") ? (
                                      !isDownloading[index] ? (
                                        <FiDownload
                                          size={18}
                                          id={`downloadIcon-${index}`}
                                          style={{ outline: "none" }}
                                          color={
                                            showIcon(index) &&
                                            !file.fileType.includes("folder") &&
                                            file.fileStatus === "file_uploaded"
                                              ? "#35A29F"
                                              : "white"
                                          }
                                          onClick={() => {
                                            !file.fileType.includes("folder") &&
                                              file.fileStatus ===
                                                "file_uploaded" &&
                                              handleDownload(
                                                file.driveId,
                                                index
                                              );
                                          }}
                                        />
                                      ) : (
                                        <Lottie
                                          id={`downloadIcon-${index}`}
                                          animationData={downloadingAnimation}
                                          loop={true}
                                          autoplay={true}
                                          style={{
                                            width: "33px",
                                            height: "33px",
                                            marginBottom: -25,
                                            marginLeft: 7,
                                          }}
                                        />
                                      )
                                    ) : null}
                                    &nbsp;&nbsp;
                                    {CheckPermission("CanEditDocuments") &&
                                    file.canChangePermissions ? (
                                      <BiSolidEdit
                                        size={20}
                                        id={`editIcon-${index}`}
                                        style={{ outline: "none" }}
                                        color={
                                          showIcon(index) ? "#35A29F" : "white"
                                        }
                                        onClick={() => {
                                          setEditValue(file.id);
                                          file.fileType.includes("folder")
                                            ? setEditFolderModal(true)
                                            : setEditFileModal(true);
                                        }}
                                      />
                                    ) : null}
                                    &nbsp;&nbsp;
                                    {CheckPermission("CanDeleteDocuments") ? (
                                      <BsTrash3Fill
                                        id={`deleteIcon-${index}`}
                                        style={{ outline: "none" }}
                                        size={17}
                                        color={
                                          showIcon(index) ? "#35A29F" : "white"
                                        }
                                        onClick={() => {
                                          handleOnDelete(
                                            file.id,
                                            file.fileType
                                          );
                                        }}
                                      />
                                    ) : null}
                                    {
                                      //Tooltips for action buttons
                                      <>
                                        {CheckPermission("CanDownloadDocuments")
                                          ? document.getElementById(
                                              `deleteIcon-${index}`
                                            ) &&
                                            file.fileType !== "folder" && (
                                              <Tooltip
                                                isOpen={
                                                  tooltipOpen[
                                                    `download-${index}`
                                                  ] || false
                                                }
                                                target={`downloadIcon-${index}`}
                                                toggle={() =>
                                                  toggleTooltip(
                                                    "download",
                                                    index
                                                  )
                                                }
                                              >
                                                <GetLanguageString
                                                  props={
                                                    "member_add_rafiq_request_download_document"
                                                  }
                                                />
                                              </Tooltip>
                                            )
                                          : null}

                                        {CheckPermission("CanEditDocuments") &&
                                        file.canChangePermissions
                                          ? document.getElementById(
                                              `editIcon-${index}`
                                            ) && (
                                              <Tooltip
                                                isOpen={
                                                  tooltipOpen[
                                                    `edit-${index}`
                                                  ] || false
                                                }
                                                target={`editIcon-${index}`}
                                                toggle={() =>
                                                  toggleTooltip("edit", index)
                                                }
                                              >
                                                <GetLanguageString
                                                  props={"common_edit"}
                                                />
                                              </Tooltip>
                                            )
                                          : null}

                                        {CheckPermission("CanDeleteDocuments")
                                          ? document.getElementById(
                                              `deleteIcon-${index}`
                                            ) && (
                                              <Tooltip
                                                isOpen={
                                                  tooltipOpen[
                                                    `delete-${index}`
                                                  ] || false
                                                }
                                                target={`deleteIcon-${index}`}
                                                toggle={() =>
                                                  toggleTooltip("delete", index)
                                                }
                                              >
                                                <GetLanguageString
                                                  props={"common_delete"}
                                                />
                                              </Tooltip>
                                            )
                                          : null}
                                      </>
                                    }
                                  </div>
                                ) : null}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Col>
                  {detailBox ? (
                    <Col md={detailBox ? "3" : "0"}>
                      <div className="p-0">
                        <Row className={GetCustomStyles().unit_btn_style}>
                          <IoIosClose
                            size={25}
                            onClick={() => {
                              setDetailBox(false);
                            }}
                            style={{
                              cursor: "pointer",
                            }}
                          />
                        </Row>
                        <Row className={"text-center"}>
                          {" "}
                          {selectedFile?.fileType.includes("folder") ? (
                            <FcFolder size={80} />
                          ) : selectedFile?.fileType.includes("pdf") ? (
                            <FaRegFilePdf size={80} color="red" />
                          ) : selectedFile?.fileType.includes("jpg") ||
                            selectedFile?.fileType.includes("png") ||
                            selectedFile?.fileType.includes("jpeg") ? (
                            <FaRegFileImage size={80} color="#35A29F" />
                          ) : selectedFile?.fileType.includes("pptx") ? (
                            <RiFilePpt2Line size={80} color="#B94623" />
                          ) : selectedFile?.fileType.includes("mp4") ? (
                            <FcVideoCall size={80} color="#5C6E91" />
                          ) : selectedFile?.fileType.includes(
                              "x-zip-compressed"
                            ) ? (
                            <TbFileTypeZip size={80} color="#5A509B" />
                          ) : selectedFile?.fileType.includes(
                              "x-compressed"
                            ) ? (
                            <FaRegFileArchive size={80} color="#5A509B" />
                          ) : selectedFile?.fileType.includes("spreadsheet") ? (
                            <RiFileExcel2Line size={80} color="#009440" />
                          ) : (
                            <FcFile size={80} />
                          )}
                        </Row>
                        <Row>
                          <h4 className="text-center">
                            {applyClassToCharacters(selectedFile?.name, "ur")}
                          </h4>
                        </Row>
                        <Row>
                          {t("common_type") +
                            " : " +
                            capitalizeFirstLetter(selectedFile?.fileType)}
                        </Row>
                        <Row>
                          <Col md="1" className={"p-0"}>
                            <GetLanguageString
                              props={"member_member_detail_document_size"}
                            />
                            {" : "}
                          </Col>
                          <Col md="5">
                            {selectedFile?.size
                              ? formatSize(selectedFile?.size)
                              : "-"}
                          </Col>
                        </Row>

                        {!selectedFile?.fileType.includes("folder") && (
                          <>
                            <Row>
                              <Col md="3" className={"p-0"}>
                                <GetLanguageString
                                  props={"common_description"}
                                />
                                {" : "}
                              </Col>
                              <Col md="9" className={"p-0"}>
                                {applyClassToCharacters(
                                  selectedFile?.description,
                                  "ur"
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Col md="4" className={"p-0"}>
                                <GetLanguageString
                                  props={"document_uploader_name"}
                                />
                                {" : "}
                              </Col>
                              <Col md="8" className={"p-0 ur"}>
                                {selectedFile?.uploaderName}
                              </Col>
                            </Row>
                            <Row>
                              {t("document_uploader_responsibility") +
                                " : " +
                                selectedFile?.uploaderResponsibility}
                            </Row>
                            <Row>
                              <Col md="4" className={"p-0"}>
                                <GetLanguageString
                                  props={"document_uploader_unit"}
                                />
                                {" : "}
                              </Col>
                              <Col md="5" className={"p-0"}>
                                {getUnitName(selectedFile?.uploaderUnit)}
                              </Col>
                            </Row>{" "}
                            <Row>
                              <Col md="3" className={"p-0"}>
                                <GetLanguageString props={"tags"} />
                                {" : "}
                              </Col>
                              <Col md="9" className={"p-0"}>
                                {selectedFile?.tags.map((item, index) => {
                                  return (
                                    <>
                                      <Button
                                        key={index}
                                        color="primary"
                                        size="xs"
                                        disabled
                                      >
                                        {item}
                                      </Button>
                                      <>&nbsp;</>
                                    </>
                                  );
                                })}
                              </Col>
                            </Row>
                          </>
                        )}
                      </div>
                    </Col>
                  ) : null}
                </Row>
                {isDragActive && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      border: "2px dashed #cccccc",
                      borderRadius: "4px",
                    }}
                  >
                    <p style={{ fontSize: "24px", color: "#333" }}>
                      <GetLanguageString props={"drop_files_here_to_upload"} />
                    </p>
                  </div>
                )}
              </div>
            ) : (
              <div
                {...getRootProps()}
                style={{ position: "relative", marginTop: "20px" }}
              >
                <input {...getInputProps()} />
                <Row
                  style={{ border: "1px solid #E7EAEC", marginTop: 20 }}
                  className="m"
                >
                  <div
                    className="text-center"
                    style={{
                      fontStyle: "italic",
                      color: "#FF3333",
                      marginTop: 30,
                      marginBottom: 10,
                    }}
                  >
                    <h4>
                      <GetLanguageString
                        props={
                          CheckPermission("CanAddDocuments")
                            ? "document_no_file_folder_present"
                            : "document_no_file_folder_present_only"
                        }
                      />
                    </h4>
                  </div>
                  <div className="text-center" style={{ marginBottom: 30 }}>
                    {ButtonsComponent("")}
                  </div>
                </Row>{" "}
                {isDragActive && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      border: "2px dashed #cccccc",
                      borderRadius: "4px",
                    }}
                  >
                    <p style={{ fontSize: "24px", color: "#333" }}>
                      <GetLanguageString props={"drop_files_here_to_upload"} />
                    </p>
                  </div>
                )}
              </div>
            )
          ) : (
            LoadingSpinner()
          )}
          {error && (
            <Alert color="danger" style={{ marginTop: "20px" }}>
              {error}
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
};

export default Documents;
