import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Row, Col, FormGroup, Label } from "reactstrap";

import { GetLanguageString } from "../../helper/Components";
import { ApiUnit } from "../../unit/ApiUnit";
import { encryptStorage } from "../../../constants/EncryptStorage";

const CourseAttendeesSelectUnit = ({
  setValues,
  values,
  unitId = 0,
  isDeleted = false,
  excludeExUnit = true
}) => {
  const [unitList, setUnitList] = useState([]);
  const loggedInUnitId = encryptStorage.getItem("loggedInUnitId");
  const unitTypeName = "null";
  useEffect(() => {
    ApiUnit()
      .getUnitList(unitId, loggedInUnitId, isDeleted, unitTypeName, excludeExUnit)
      .then((res) => {
        setUnitList(res.data);
      })
      .catch((err) => console.log(err));
  }, [unitId, isDeleted, loggedInUnitId, excludeExUnit]);

  const handleChangeUnits = (record) => {
    if (record !== null) {
      ApiUnit()
        .getUnitList(record.value, loggedInUnitId, isDeleted, unitTypeName, excludeExUnit)
        .then((res) => {
          setUnitList(res.data);
        })
        .catch((err) => console.log(err));

      setValues({
        ...values,
        unit: record,
      });
    }
  };

  return (
    <>
      <Row>
        {unitList.map((record, index) => {
          const unitOptions = [];
          record.unitList.forEach((d) =>
            unitOptions.push({ value: d.id, label: d.name })
          );
          let selected = {};
          let unitName = "";
          if (record.parentUnitName !== null) unitName = record.parentUnitName;
          if (record.selectedUnit !== null) {
            selected = {
              value: record.selectedUnit.id,
              label: record.selectedUnit.name,
            };
          }

          return (
            <Col md="2" key={index}>
              <FormGroup>
                <Label className="col-form-label">
                  {unitName !== "" ? (
                    <GetLanguageString
                      props="member_select_unit_name"
                      value={unitName}
                    />
                  ) : (
                    <GetLanguageString props="member_select_unit" />
                  )}
                </Label>
                <Select
                  options={unitOptions}
                  value={selected}
                  className="basic-single ur"
                  classNamePrefix="select"
                  onChange={handleChangeUnits}
                />
              </FormGroup>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default CourseAttendeesSelectUnit;
