import React, { useState } from "react";
import {
  Label,
  Row,
  Col,
  Input,
} from "reactstrap";

import { GetLanguageString } from "../../helper/Components";
import DateFilterUI from "./DateFilterUI";
import { SelectOnlyDate } from "../../helper/Method";
import MonthFilterUI from "./MonthFilterUI";

const FundFilter = ({ values, setValues }) => {
  const [searchEnterDates, setSearchEnterDates] = useState(false);
  const [searchByFilters, setSearchByFilters] = useState(true);

  const handleSearchDates = (name) => {
    if (name === "enterDates") {
      setSearchEnterDates(true);
      setSearchByFilters(false);
    } else if (name === "filters") {
      setSearchEnterDates(false);
      setSearchByFilters(true);
      let tempDate = new Date();
      tempDate.setDate(1);
      setValues({
        ...values,
        startDate: SelectOnlyDate(tempDate),
        endDate: SelectOnlyDate(new Date()),
      });
    }
  };

  return (
    <Row className="m-t-md">
        <Col md="6">
            <Label for="filters">
            {" "}
            <Input
                id="filters"
                type="radio"
                checked={searchByFilters}
                onChange={() => {
                handleSearchDates("filters");
                }}
            />{" "}
            {
                <GetLanguageString props="event_event_wise_attendance_search_filters" />
            }
            </Label>
            <MonthFilterUI values={values} setValues={setValues} checkDisabled={searchEnterDates} />
        </Col>{" "}
        <Col md="6">
            <Label for="enterDates">
            {" "}
            <Input
                id="enterDates"
                type="radio"
                checked={searchEnterDates}
                onChange={() => {
                handleSearchDates("enterDates");
                }}
            />{" "}
            {
                <GetLanguageString props="event_event_wise_attendance_search_enter_date" />
            }
            </Label>
            <DateFilterUI setValues={setValues} values={values} checkDisabled={searchByFilters} />
        </Col>
    </Row>
  );
};

export default FundFilter;
