import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import Draggable from "react-draggable";
import swal from "sweetalert";
import {
  LoadingSpinner,
  convertToDateString,
  isAlphaNumeric,
  isCodeUnique,
} from "../../../constants/const";
import { GetLanguageString } from "../../helper/Components";
import { DateValidater, GetSelectedLanguage } from "../../helper/Method";
import PickColor from "../PickColor";
import { ApiLetterForCounselling } from "./ApiLetterForCounselling";

const AddLetterForCounselling = ({ setAddModal, setReload, reload }) => {
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("");
  const { t } = useTranslation();
  const [codeList, setCodeList] = useState([]);
  const [letterForCounsellingValues, setLetterForCounsellingValues] = useState({
    code: "",
    title: "",
    frequency: {},
    isEnabled: true,
    isArchive: false,
    pageColor: "",
    startDate: null,
    endDate: null,
    defaultRatingMin: 1,
    defaultRatingMax: 5,
  });
  const [selectedDates, setSelectedDates] = useState({
    startDate: null,
    endDate: null,
  });
  const dateRangeValidation = {
    startDate: null,
    endDate: null,
  };
  const [dateValidations, setDateValidations] = useState(dateRangeValidation);
  const [errors, setErrors] = useState(letterForCounsellingValues);
  const [frequencyList, setFrequencyList] = useState([]);
  const toggle = () => setAddModal(false);
  const [selectedFrequencyType, setSelectedFrequencyType] = useState({});
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    setLoading(true);
    ApiLetterForCounselling()
      .getLetterForCounsellingFrequency()
      .then((res) => {
        setFrequencyList(res.data);
        setSelectedFrequencyType({
          value: res.data[0].value,
          label: <GetLanguageString props={res.data[0].label} />,
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
      });
    ApiLetterForCounselling()
      .getLetterForCounsellingCodes()
      .then((res) => {
        setCodeList(res.data);
      })
      .catch((err) => {
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
      });
  }, []);

  const handleInputChangeStartDate = (value) => {
    if (DateValidater(value, selectedDates.endDate)) {
      setSelectedDates({
        ...selectedDates,
        startDate: value,
      });
      setDateValidations(dateRangeValidation);
    } else {
      setDateValidations({
        ...dateValidations,
        startDate: (
          <GetLanguageString props="common_start_date_validation_message" />
        ),
      });
    }
  };
  const handleInputChangeEndDate = (value) => {
    if (DateValidater(selectedDates.startDate, value)) {
      setSelectedDates({
        ...selectedDates,
        endDate: value,
      });
      setDateValidations(dateRangeValidation);
    } else {
      setDateValidations({
        ...dateValidations,
        endDate: (
          <GetLanguageString props="common_end_date_validation_message" />
        ),
      });
    }
  };
  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setErrors({
      ...errors,
      [name]: "",
    });

    setLetterForCounsellingValues({
      ...letterForCounsellingValues,
      [name]: value,
    });
  };

  const validate = () => {
    let temp = {};
    temp.title = letterForCounsellingValues.title
      ? ""
      : t("events_title_error_message");
    temp.code = letterForCounsellingValues.code ? (
      isAlphaNumeric(letterForCounsellingValues.code) ? (
        isCodeUnique(codeList, letterForCounsellingValues.code) ? (
          ""
        ) : (
          <GetLanguageString props="settings_code_unique_error_message" />
        )
      ) : (
        <GetLanguageString props="settings_code_aplha_numeric_error_message" />
      )
    ) : (
      <GetLanguageString props="settings_code_error_message" />
    );

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleChangeFrequencyType = (record) => {
    if (record === null) {
      setLetterForCounsellingValues({
        ...letterForCounsellingValues,
        frequency: {},
      });
      setSelectedFrequencyType({});
    } else {
      setLetterForCounsellingValues({
        ...letterForCounsellingValues,
        frequency: record,
      });
      setSelectedFrequencyType(record);
    }
    setErrors({
      ...errors,
      frequency: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    letterForCounsellingValues.letterFrequency = selectedFrequencyType.value;
    letterForCounsellingValues.pageColor = color;
    letterForCounsellingValues.startDate = convertToDateString(
      selectedDates.startDate
    );
    letterForCounsellingValues.endDate = convertToDateString(
      selectedDates.endDate
    );

    if (validate()) {
      setLoading(true);
      ApiLetterForCounselling()
        .addLetterForCounselling(letterForCounsellingValues)
        .then((res) => {
          setLoading(false);
          swal({
            title: t("settings_letter_for_counselling_add_success"),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "success",
            buttons: "OK",
          });
          setReload(!reload);
          toggle();
        })
        .catch((err) => {
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="settings_letter_for_counselling_add" />}
          </ModalHeader>
          {!loading ? (
            <Form onSubmit={handleSubmit}>
              <ModalBody>
                <FormGroup>
                  <Label className="no-drag">
                    {<GetLanguageString props="settings_title" />}
                  </Label>
                  <Input
                    autoFocus
                    type="text"
                    name="title"
                    className="ur no-drag"
                    value={letterForCounsellingValues.title}
                    onChange={handleInputChange}
                    {...(errors.title && { invalid: true })}
                  />
                  {errors.title && (
                    <FormFeedback>
                      <h4>{errors.title}</h4>
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label>{<GetLanguageString props="settings_code" />}</Label>
                  <Input
                    type="text"
                    name="code"
                    className="no-drag"
                    value={letterForCounsellingValues.code}
                    onChange={handleInputChange}
                    {...(errors.code && { invalid: true })}
                  />
                  {errors.code && (
                    <FormFeedback>
                      <h4>{errors.code}</h4>
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label>
                    {
                      <GetLanguageString props="settings_event_type_frequency" />
                    }
                  </Label>
                  <Select
                    value={selectedFrequencyType}
                    options={frequencyList.map((f) => ({
                      value: f.value,
                      label: t(f.label),
                    }))}
                    className="basic-single no-drag"
                    classNamePrefix="select"
                    onChange={handleChangeFrequencyType}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="col-form-label">
                    {<GetLanguageString props="courses_start_date" />}
                  </Label>
                  <DatePicker
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd/mm/yyyy"
                    className="form-control no-drag"
                    selected={selectedDates.startDate}
                    onChange={handleInputChangeStartDate}
                    dateFormat="dd/MM/yyyy"
                  />
                  {dateValidations.startDate && (
                    <h4
                      className={
                        GetSelectedLanguage() === "ur"
                          ? "text-error ur"
                          : "text-error"
                      }
                    >
                      {dateValidations.startDate}
                    </h4>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="col-form-label">
                    {<GetLanguageString props="courses_end_date" />}
                  </Label>
                  <DatePicker
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd/mm/yyyy"
                    className="form-control no-drag"
                    selected={selectedDates.endDate}
                    onChange={handleInputChangeEndDate}
                    dateFormat="dd/MM/yyyy"
                  />
                  {dateValidations.endDate && (
                    <h4
                      className={
                        GetSelectedLanguage() === "ur"
                          ? "text-error ur"
                          : "text-error"
                      }
                    >
                      {dateValidations.endDate}
                    </h4>
                  )}
                </FormGroup>
                <Row>
                  <Col md="6">
                    {" "}
                    <FormGroup>
                      <Label>
                        {
                          <GetLanguageString props="settings_letter_for_counselling_default_rating_min" />
                        }
                      </Label>
                      <Input
                        type="number"
                        name="defaultRatingMin"
                        className="no-drag"
                        value={letterForCounsellingValues.defaultRatingMin}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    {" "}
                    <FormGroup>
                      <Label>
                        {
                          <GetLanguageString props="settings_letter_for_counselling_default_rating_max" />
                        }
                      </Label>
                      <Input
                        type="number"
                        name="defaultRatingMax"
                        className="no-drag"
                        value={letterForCounsellingValues.defaultRatingMax}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup check>
                  <Label check for="isArchive">
                    {" "}
                    <span
                      style={{
                        float:
                          GetSelectedLanguage() === "ur" ? "right" : "left",
                        marginLeft:
                          GetSelectedLanguage() === "ur" ? "24px" : "0px",
                      }}
                    >
                      <Input
                        id="isArchive"
                        type="checkbox"
                        name="isArchive"
                        value={!letterForCounsellingValues.isArchive}
                        onChange={handleInputChange}
                        checked={letterForCounsellingValues.isArchive}
                      />
                    </span>
                    {
                      <GetLanguageString props="settings_letter_for_counselling_archive" />
                    }
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check for="isEnabled">
                    {" "}
                    <span
                      style={{
                        float:
                          GetSelectedLanguage() === "ur" ? "right" : "left",
                        marginLeft:
                          GetSelectedLanguage() === "ur" ? "24px" : "0px",
                      }}
                    >
                      <Input
                        id="isEnabled"
                        type="checkbox"
                        checked={letterForCounsellingValues.isEnabled}
                        name="isEnabled"
                        value={!letterForCounsellingValues.isEnabled}
                        onChange={handleInputChange}
                      />
                    </span>
                    {<GetLanguageString props="member_address_enabled" />}
                  </Label>
                </FormGroup>
                <PickColor setColor={setColor} color={color} />
              </ModalBody>
              <ModalFooter>
                <Button color="white" onClick={toggle}>
                  {<GetLanguageString props="common_cancel" />}
                </Button>
                <Button color="primary" type="submit">
                  {<GetLanguageString props="common_save_changes" />}
                </Button>
              </ModalFooter>
            </Form>
          ) : (
            LoadingSpinner()
          )}
        </Modal>
      </Draggable>
    </div>
  );
};

export default AddLetterForCounselling;
