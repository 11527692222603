import React, { useState } from "react";
import {
  FormGroup,
  Form,
  Label,
  Input,
  Button,
  ModalBody,
  ModalFooter,
  Modal,
  ModalHeader,
} from "reactstrap";
import swal from "sweetalert";
import { t } from "i18next";
import DatePicker from "react-datepicker";
import Draggable from "react-draggable";
import {
  convertToDateString,
  LoadingSpinner,
  PledgeDateValidator,
} from "../../../constants/const";
import { GetLanguageString } from "../../helper/Components";
import { addMember } from "../ApiMember";
import { GetSelectedLanguage } from "../../helper/Method";

const UpdatePledgesModel = ({
  pledges,
  data,
  setEditModal,
  refresh,
  setRefresh,
}) => {
  const pledgeFromDate = new Date(pledges[data.index].pledgeFromDate);
  const formData = {
    id: pledges[data.index].id,
    pledgeName: pledges[data.index].pledgeName,
    pledgeDate:
      pledges[data.index].pledgeDate != null
        ? new Date(pledges[data.index].pledgeDate)
        : new Date(),
  };
  const [errors, setErrors] = useState();
  const [loading, setLoading] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    pledges[data.index].pledgeDate != null
      ? new Date(pledges[data.index].pledgeDate)
      : new Date()
  );
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  const validate = () => {
    let temp = {};
    temp.pledgeDate = selectedDate ? (
      PledgeDateValidator(selectedDate, pledgeFromDate) ? ( //check if the pledge date is valid
        ""
      ) : (
        t("member_member_detail_pledges_date_error_message")
      )
    ) : (
      <GetLanguageString props="member_member_detail_mudaris_details_assessments_date_error" />
    );

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputChangeDate = (value) => {
    if (value <= new Date()) {
      setSelectedDate(value);
      setErrors({
        ...errors,
        pledgeDate: "",
      });
    } else {
      setErrors({
        ...errors,
        pledgeDate: t("tutorial_date_error_message"),
      });

      setSelectedDate(null);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      if (selectedDate <= new Date()) {
        formData.pledgeDate = convertToDateString(selectedDate);
        setLoading(true);
        setButtonDisable(true);
        addMember()
          .editMemberPledges(formData.id, formData)
          .then(() => {
            swal({
              title: t("member_member_detail_edit_pledge_msg"),
              className: GetSelectedLanguage() === "en" ? "" : "ur",
              icon: "success",
              buttons: GetSelectedLanguage() === "en" ? "OK" : "ٹھیک ہے ",
            });
            setRefresh(!refresh);
            setEditModal(false);
            setLoading(false);
            setButtonDisable(false);
          })
          .catch((err) => {
            swal({
              title: err,
              icon: "error",
              buttons: "OK",
            });
            setLoading(false);
            setButtonDisable(false);
          });
      }
    }
  };

  return (
    <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
      <Modal isOpen={true} className="inmodal" autoFocus={false}>
        <ModalHeader className="modal-title" tag="h4">
          <GetLanguageString props="member_member_detail_edit_pledge" />
        </ModalHeader>
        {!loading ? (
          <Form onSubmit={handleSubmit}>
            <ModalBody>
              <FormGroup>
                <Label>
                  <GetLanguageString props="member_member_detail_pledges_name" />
                </Label>
                <Input
                  className="ur no-drag"
                  type="text"
                  name="pledgeName"
                  value={formData.pledgeName}
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <Label className="col-form-label">
                  {
                    <GetLanguageString props="member_member_detail_pledges_date" />
                  }
                </Label>
                <DatePicker
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="dd/mm/yyyy"
                  className="form-control no-drag"
                  maxDate={new Date()}
                  selected={selectedDate}
                  onChange={handleInputChangeDate}
                  dateFormat="dd/MM/yyyy"
                />
                {errors?.pledgeDate && (
                  <h4 className="text-error">{errors?.pledgeDate}</h4>
                )}
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="white" onClick={() => setEditModal(false)}>
                <GetLanguageString props="common_cancel" />
              </Button>
              <Button color="primary" type="submit">
                <GetLanguageString
                  props="common_save"
                  disabled={buttonDisable}
                />
              </Button>
            </ModalFooter>
          </Form>
        ) : (
          LoadingSpinner()
        )}
      </Modal>
    </Draggable>
  );
};

export default UpdatePledgesModel;
