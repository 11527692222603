import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Form, Row, Col, FormGroup, Label, Input, Button } from "reactstrap";

import useForm from "../shared/useForm";
import { ApiUnit } from "../unit/ApiUnit";
import { GetLanguageString } from "../helper/Components";
import { requestApi } from "../../actions/api";
import { ApiResponsibility } from "../config/responsibilityType/ApiResponsibilityType";
import { encryptStorage } from "../../constants/EncryptStorage";
import { GetSelectedLanguage } from "../helper/Method";
import { isUrdu } from "../../constants/const";

const SearchMembers = ({
  unitId,
  setUnit,
  setSearchMemberModel,
  searchMemberModel,
  setSearchButton,
}) => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [unitList, setUnitList] = useState([]);
  const [memberTypeList, setMemberTypeList] = useState([]);
  const [responsibilityList, setResponsibilityList] = useState([]);
  const [excludeExUnit, setExcludeExUnit] = useState(true);
  const memberTypeOptions = [];
  const responsibilityOptions = [];
  const unitTypeName = "null";

  useEffect(() => {
    ApiResponsibility()
      .getResponsibilitiesByLoggedInUnit(loggedInUnitId)
      .then((res) => {
        setResponsibilityList(res.data);
      })
      .catch((err) => console.log(err));

    requestApi("membertype/")
      .fetchAll()
      .then((res) => {
        setMemberTypeList(res.data);
      })
      .catch((err) => console.log(err));

    ApiUnit()
      .getUnitList(unitId, loggedInUnitId, false, unitTypeName, excludeExUnit)
      .then((res) => {
        setUnitList(res.data);
      })
      .catch((err) => console.log(err));
  }, [unitId, loggedInUnitId, excludeExUnit]);

  memberTypeList.forEach((d) =>
    memberTypeOptions.push({ value: d.id, label: d.type })
  );
  responsibilityList.forEach((d) =>
    responsibilityOptions.push({ value: d.value, label: d.label })
  );
  const { values, handleInputChange, setValues } = useForm(searchMemberModel);

  const handleChangeUnits = (record) => {
    if (record !== null) {
      ApiUnit()
        .getUnitList(
          record.value,
          loggedInUnitId,
          false,
          unitTypeName,
          excludeExUnit
        )
        .then((res) => {
          setUnitList(res.data);
        })
        .catch((err) => console.log(err));

      setUnit({ unitId: record.value, unitName: record.label });
      setValues({ ...values, unitId: record.value });
    }
  };

  const handleChangeMemberTypes = (record) => {
    if (record !== null && record.length > 0) {
      let memberTypeIds = [];

      // eslint-disable-next-line
      record.map((obj) => {
        memberTypeIds.push(obj.value);
      });

      if (memberTypeIds.length > 0) {
        setValues({
          ...values,
          memberTypeIds: memberTypeIds,
        });
      }
    } else {
      setValues({
        ...values,
        memberTypeIds: null,
      });
    }
  };

  const handleChangeResponsibility = (record) => {
    if (record !== null && record.length > 0) {
      let responsibilityIds = [];

      // eslint-disable-next-line
      record.map((obj) => {
        responsibilityIds.push(obj.value);
      });

      if (responsibilityIds.length > 0) {
        setValues({
          ...values,
          responsibilityIds: responsibilityIds,
        });
      }
    } else {
      setValues({
        ...values,
        responsibilityIds: null,
      });
    }
  };

  const handleSubUnits = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setValues({
      ...values,
      isSubUnits: v,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (values.unitId === 0) values.unitId = unitId;
    setSearchButton(true);
    setSearchMemberModel(values);
  };

  const handleExcludeExUnit = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setExcludeExUnit(v);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col md="4" xl="2">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="member_search_by_name" />}
            </Label>
            <Input
              className="ur"
              type="text"
              name="name"
              value={values.name}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
        <Col md="4" xl="2">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="member_search_by_father_name" />}
            </Label>
            <Input
              className="ur"
              type="text"
              name="fatherName"
              value={values.fatherName}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
        <Col md="4" xl="2">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="member_search_by_regno" />}
            </Label>
            <Input
              type="text"
              name="regNo"
              value={values.regNo}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
        <Col md="4" xl="2">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="member_search_by_phoneno_email" />}
            </Label>
            <Input
              type="text"
              name="phoneNo"
              value={values.phoneNo}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
        <Col md="6" xl="4">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="member_search_by_address" />}
            </Label>
            <Input
              className={isUrdu(values.address) ? "ur" : ""}
              type="text"
              name="address"
              value={values.address}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="member_select_responsibility" />}
            </Label>
            <Select
              options={responsibilityOptions}
              className="basic-multi-select ur"
              isMulti
              placeholder={
                GetSelectedLanguage() === "en" ? "Select" : "منتخب کریں"
              }
              isClearable
              onChange={handleChangeResponsibility}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="member_select_member_type" />}
            </Label>
            <Select
              options={memberTypeOptions}
              className="basic-multi-select ur"
              placeholder={
                GetSelectedLanguage() === "en" ? "Select" : "منتخب کریں"
              }
              isClearable
              isMulti
              onChange={handleChangeMemberTypes}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        {unitList.map((record, index) => {
          const unitOptions = [];
          record.unitList.forEach((d) =>
            unitOptions.push({
              value: d.id,
              label: d.name,
              unitTypeName: d.unitTypeName,
            })
          );
          let selected = {};
          let unitName = "";
          if (record.parentUnitName !== null) unitName = record.parentUnitName;
          if (record.selectedUnit !== null) {
            selected = {
              value: record.selectedUnit.id,
              label: record.selectedUnit.name,
            };
          }

          return (
            <Col md="2" key={index}>
              <FormGroup>
                <Label className="col-form-label">
                  {unitName !== "" ? (
                    <GetLanguageString
                      props="member_select_unit_name"
                      value={unitName}
                    />
                  ) : (
                    <GetLanguageString props="member_select_unit" />
                  )}
                </Label>
                <Select
                  options={unitOptions}
                  value={selected}
                  className="basic-single ur"
                  classNamePrefix="select"
                  onChange={handleChangeUnits}
                />
              </FormGroup>
            </Col>
          );
        })}
      </Row>
      <Row>
        <Col md="2">
          <FormGroup check>
            <Label check for="includeExUnit">
              <Input
                id="includeExUnit"
                type="checkbox"
                name="includeExUnit"
                checked={!excludeExUnit}
                value={!excludeExUnit}
                onChange={handleExcludeExUnit}
              />
              {<GetLanguageString props="member_search_member_ex_unit" />}
            </Label>
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup check>
            <Label check for="subUnit">
              <Input
                id="subUnit"
                type="checkbox"
                name="isSubUnits"
                checked={values.isSubUnits}
                value={!values.isSubUnits}
                onChange={handleSubUnits}
              />
              {<GetLanguageString props="member_search_member_sub_unit" />}
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <FormGroup>
            <Button color="primary" className="m-l-sm" type="submit">
              {<GetLanguageString props="common_search" />}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default SearchMembers;
