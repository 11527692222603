import axios from "axios";

import { BASEURL } from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";

export function smsApi() {
  const userToken = encryptStorage.getItem("token");

  const authAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const url = BASEURL + "sms/";
  return {
    sendSMS: (smsObject) => authAxios.post(url + "sendsms/", smsObject),
    getUserCost: (smsObject) =>
      axios.post(url + "CalculateUserSMSCost/", smsObject),
    getMultipleUserCost: (smsObject) =>
      axios.post(url + "CalculateMultipleUsersSMSCost/", smsObject),
  };
}
