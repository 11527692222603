import { t } from "i18next";
import React, { useState, useEffect } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
} from "reactstrap";
import swal from "sweetalert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import 'react-image-crop/dist/ReactCrop.css';

import ImageCropper from "../../helper/ImageCropper";
import sampleMemberPhoto from "../../shared/profile/member-sample-photo.jpg";

import { addMember } from "../ApiMember";
import { GetSelectedLanguage } from "../../helper/Method";
import { LoadingSpinner } from "../../../constants/const";
import { GetLanguageString } from "../../helper/Components";

const UpdateMemberPhotoForm = ({
  tab6,
  memberId,
  setImageUrlForDetails,
  imageUrl,
}) => {
  const notify = (string) => toast.success(string);
  const [imageCropModal, setImageCropModal] = useState(false);
  const [imageToCrop, setImageToCrop] = useState(undefined);
  const [croppedImage, setCroppedImage] = useState(imageUrl);
  const [loading, setLoading] = useState(false);

  const handleDelete = (e) => {
    e.preventDefault();
    swal({
      title: t("common_delete_message"),
      text: t("common_delete_detail_message"),
      className: GetSelectedLanguage() === "en" ? "" : "ur",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        addMember()
          .deleteMemberPhoto(memberId)
          .then((res) => {
            // setImageUrl(null);
            swal({
              title: t("member_member_photo_delete_success_message"),
              className: GetSelectedLanguage() === "en" ? "" : "ur",
              text: res.data + "",
              icon: "success",
              buttons: "OK",
            });
            setImageUrlForDetails(null);
            setCroppedImage(null)
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            swal({
              title: "Error: " + err.response?.data || err,
              icon: "error",
              buttons: "OK",
            });
            setLoading(false);
          });
      }
    });
  };

  const handleUpload = async (e) => {
    try {
      if (e.target.files && e.target.files.length > 0) {
        const reader = new FileReader();

        reader.addEventListener('load', () =>
            setImageToCrop(reader.result),
            setImageCropModal(true)
        );

        reader.readAsDataURL(e.target.files[0]);

        // const selectedFile = e.target.files[0];  // TO COMPRESS
        //  COMPRESS IMAGE
        // if (selectedFile) {
        //   const options = {
        //     maxSizeMB: 10,
        //     maxWidthOrHeight: 1920,
        //     useWebWorker: true,
        //   };
  
        //   // COMPRESS THE BLOB
          
        //   const compressedFile = await imageCompression(selectedFile, options);
  
        //   setFile(compressedFile);
        //   setError(null);
        // } else {
        //   // setFile(null);
        //   setImageUrl(null);
        // }
      }
    } catch (error) {
      console.error('Error cropping image:', error);
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setImageToCrop(null);
    setLoading(true);

    if (croppedImage) {
      const res = await fetch(croppedImage);
      const blob = await res.blob();
      const formData = new FormData();
      formData.append("imageFile", blob);

      addMember()
        .uploadMemberPhoto(memberId, formData)
        .then((res) => {
          if (res.data === "") {
            swal({
              title: t("member_member_photo_upload_success_message"),
              className: GetSelectedLanguage() === "en" ? "" : "ur",
              text: res.data + "",
              icon: "success",
              buttons: "OK",
            });
            setImageUrlForDetails(croppedImage);
          } else notify(res.data);

          setLoading(false);
        })
        .catch((err) => {
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
          console.log(err);
          setLoading(false);
        })
        .finally(() => {});
    } else {
      swal({
        title: t("member_add_img_browse_error"),
        className: GetSelectedLanguage() === "en" ? "" : "ur",
        icon: "error",
        buttons: "OK",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if(memberId && imageUrl === null && tab6 === "active"){
      setLoading(true);
        addMember()
          .getMemberPhoto(memberId)
          .then((res) => {
            if (res.data) {
              setCroppedImage("data:image/jpeg;base64," + res.data.image);
              setLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
    }
    else if(imageUrl !== null){
      setCroppedImage(imageUrl);
    }
  }, [memberId, imageUrl, tab6])
  
  return (
    <div style={{
      minWidth: '1000px',
      position: 'relative',
      display: 'flex'
    }}>
      <Card
        style={{
          maxWidth: '300px',
          flex: '1'
        }}
      >  
      {!loading && croppedImage ? (
          <>
            <img src={croppedImage} alt="Uploaded" />
            <CardBody className="text-center">
              <ButtonGroup>
                <Button size="sm" onClick={handleDelete} color="danger">
                  Remove Photo
                </Button>
              </ButtonGroup>
            </CardBody>
          </>
        ) : !loading && !croppedImage && !imageToCrop ? (
          <>
            <CardTitle 
              tag="h5"
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <GetLanguageString props="photo_template" />
            </CardTitle>
            <img src={sampleMemberPhoto} alt="Sample" />
          </>
        ) : loading && (
          LoadingSpinner()
        )}
        <CardFooter>
          <CardTitle 
            tag="h5"
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <GetLanguageString props="upload_image" />
          </CardTitle>
          <Form className="d-flex">
            <Input 
              type="file" 
              accept="image/*"
              name="imageFile" 
              maxSize={10 * 1024 * 1024}
              onChange={handleUpload} 
            />
          </Form>
        </CardFooter>
      </Card>

      {imageCropModal && imageToCrop && 
        <ImageCropper
          imageToCrop={imageToCrop}
          handleSubmit={handleSubmit}
          setImageCropModal={setImageCropModal}
          setCroppedImage={setCroppedImage}
          onImageCropped={(croppedImage) => setCroppedImage(croppedImage)}
        />
      }      
      </div>
  );
};
export default UpdateMemberPhotoForm;
