import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Col, FormGroup, Label, Row } from "reactstrap";
import swal from "sweetalert";
import { encryptStorage } from "../../../constants/EncryptStorage";
import { CheckPermission } from "../../../constants/const";
import { GetLanguageString } from "../../helper/Components";
import { GetSelectedLanguage } from "../../helper/Method";
import { ApiUnit } from "../../unit/ApiUnit";

const ChangeMemberUnitSelectUnit = ({
  setValues,
  values,
  unitId = 0,
  isDeleted = false,
  excludeExUnit = true,
  outsideTransfer = false,
}) => {
  const loggedInUnitId =
    CheckPermission("CanMoveMembersOutsideTransfer") && outsideTransfer
      ? 1
      : parseInt(encryptStorage.getItem("loggedInUnitId"));

  const [unitList, setUnitList] = useState([]);
  const [disabled, setDisabled] = useState(false);

  if (unitId === 0) unitId = loggedInUnitId;
  unitId =
    CheckPermission("CanMoveMembersOutsideTransfer") && outsideTransfer
      ? 1
      : unitId;
  const unitTypeName = "null";

  useEffect(() => {
    ApiUnit()
      .getUnitList(
        unitId,
        loggedInUnitId,
        isDeleted,
        unitTypeName,
        excludeExUnit
      )
      .then((res) => {
        setUnitList(res.data);
        const lastIndex = res.data.length - 1;
        if (lastIndex >= 0 && res.data[lastIndex].selectedUnit) {
          const isEnabled = res.data[lastIndex].selectedUnit.isEnabled;
          setDisabled(!isEnabled);
        }
      })
      .catch((err) => {
        console.log(err);
        swal({
          title: "Error: " + err.response?.data,
          icon: "error",
          buttons: "OK",
        });
      });
  }, [unitId, loggedInUnitId, isDeleted, excludeExUnit]);

  const handleChangeUnits = (record) => {
    if (record !== null) {
      ApiUnit()
        .getUnitList(
          record.value,
          loggedInUnitId,
          isDeleted,
          unitTypeName,
          excludeExUnit
        )
        .then((res) => {
          setUnitList(res.data);
          const lastIndex = res.data.length - 1;
          if (lastIndex >= 0 && res.data[lastIndex].selectedUnit) {
            const isEnabled = res.data[lastIndex].selectedUnit.isEnabled;
            setDisabled(!isEnabled);
          }
        })
        .catch((err) => console.log(err));

      setValues({
        ...values,
        unit: record,
      });
    }
  };

  return (
    <>
      <Row>
        {disabled ? (
          <div>
            <h4
              className={
                GetSelectedLanguage() === "ur"
                  ? "text-error text-center"
                  : "no-results"
              }
            >
              <GetLanguageString props={"unit_deleted_unit_error"} />
            </h4>
          </div>
        ) : null}
        {unitList.map((record, index) => {
          const unitOptions = [];
          record.unitList.forEach(
            (d) =>
              d.unitTypeName !== "UsraMuavin" &&
              unitOptions.push({ value: d.id, label: d.name })
          );
          let selected = {};
          let unitName = "";
          if (record.parentUnitName !== null) unitName = record.parentUnitName;
          if (record.selectedUnit !== null) {
            selected = {
              value: record.selectedUnit.id,
              label: record.selectedUnit.name,
            };
          }

          return (
            <Col xl="3" key={index}>
              <FormGroup>
                <Label className="col-form-label">
                  {unitName !== "" ? (
                    <GetLanguageString
                      props="member_select_unit_name"
                      value={unitName}
                    />
                  ) : (
                    <GetLanguageString props="member_select_unit" />
                  )}
                </Label>
                <Select
                  options={unitOptions}
                  value={selected}
                  isDisabled={disabled}
                  className="basic-single ur"
                  classNamePrefix="select"
                  onChange={handleChangeUnits}
                />
              </FormGroup>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default ChangeMemberUnitSelectUnit;
