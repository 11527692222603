import React, { useEffect, useState } from "react";
import moment from "moment";
import swal from "sweetalert";
import { Button, Row, Col, Table, Label } from "reactstrap";

import { GetLanguageString, SetReportTitle } from "../../helper/Components";
import { GetCustomStyles } from "../../helper/Method";
import SearchMonthlyReport from "./SearchMonthlyReport";
import { ApiUnit } from "../ApiUnit";
import { encryptStorage } from "../../../constants/EncryptStorage";
import { convertUTCToLocalTime } from "../../../constants/const";

const UnitMonthlyReport = () => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [values, setValues] = useState({
    unit: { value: loggedInUnitId, lable: "" },
    month: null,
    year: null,
    date: null,
  });
  const [monthlyReportDetails, setMonthlyReportDetails] = useState({
    ameerAssistants: {
      memberResponsibilties: [],
    },
    unitEvents: [],
  });
  const [reportTitle, setReportTitle] = useState("");
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (reportTitle !== "") window.print();
  }, [reportTitle]);

  useEffect(() => {
    if (values.unit.value !== 0) {
      ApiUnit()
        .getUnitMonthlyReport(values)
        .then((res) => {
          if (res.data.unitTypeError !== null) {
            swal({
              title: "Report",
              text: res.data.unitTypeError,
              icon: "warning",
            });
            setMonthlyReportDetails({
              ameerAssistants: {
                memberResponsibilties: [],
              },
              unitEvents: [],
            });
          } else if (res.data.ameerAssistants !== null)
            setMonthlyReportDetails(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [values]);

  const handleReportTitle = () => {
    setModal(true);
  };

  return (
    <>
      {modal ? (
        <SetReportTitle setModal={setModal} setReportTitle={setReportTitle} />
      ) : null}
      <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
        <div className="ibox noprint">
          <div className="ibox-title">
            <h5>Search Units</h5>
            <Button
              color="primary"
              size="sm"
              className={GetCustomStyles().btn_style}
              onClick={handleReportTitle}
            >
              {<GetLanguageString props="common_print" />}
            </Button>
          </div>
          <div className="ibox-content">
            <SearchMonthlyReport
              searchUnit={values}
              setSearchUnit={setValues}
            />
          </div>
        </div>
        {monthlyReportDetails.ameerAssistants.memberResponsibilties.length !==
        0 ? (
          <div className="panel panel-primary">
            <div className="panel-heading">
              <h5>Monthly Report</h5>
            </div>
            <div className="panel-body" id="section-to-print">
              <div className="text-center print">
                <h3>Title : {reportTitle}</h3>
              </div>
              <Row>
                <Col md="6">
                  <div className="panel panel-info">
                    <div className="panel-heading">
                      <h5>Member Responsibilties</h5>
                    </div>
                    <div className="panel-body">
                      <div className="table-responsive">
                        <Table responsive>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Member Name</th>
                              <th>Responsibilty Name</th>
                            </tr>
                          </thead>
                          <tbody>
                            {monthlyReportDetails.ameerAssistants.memberResponsibilties.map(
                              (record, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td className="ur">{record.memberName}</td>
                                    <td className="ur">{record.responsibiltyName}</td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </Col>
                {monthlyReportDetails.unitEvents.length !== 0 ? (
                  <Col md="6">
                    <div className="panel panel-info">
                      <div className="panel-heading">
                        <h5>Events Detais</h5>
                        <i>Total Members</i>
                        <Label
                          className="label label-primary m-l-sm"
                          style={{ fontSize: "13px" }}
                        >
                          {monthlyReportDetails.ameerAssistants.totalMembers}
                        </Label>
                      </div>
                      <div className="panel-body">
                        <div className="table-responsive">
                          <Table responsive>
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Event Name</th>
                                <th>Event Date</th>
                                <th className="text-center">Present</th>
                                <th className="text-center">Absent</th>
                                <th className="text-center">Leave</th>
                              </tr>
                            </thead>
                            <tbody>
                              {monthlyReportDetails.unitEvents.map(
                                (record, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td className="ur">{record.eventName}</td>
                                      <td>
                                        {moment(convertUTCToLocalTime(record.eventDate)).format(
                                          "DD/MM/yyyy"
                                        )}
                                      </td>
                                      <td className="text-center">
                                        {record.membersPresent}
                                      </td>
                                      <td className="text-center">
                                        {record.membersAbsent}
                                      </td>
                                      <td className="text-center">
                                        {record.membersLeave}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </Col>
                ) : null}
              </Row>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default UnitMonthlyReport;
