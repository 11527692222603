// Packages Imports
import React, { useEffect, useState } from "react";
import {
  ButtonGroup,
  Button,
  Label,
  Row,
  Col,
  FormGroup,
  Input,
  Table
} from "reactstrap";
import DatePicker from "react-datepicker";
import swal from "sweetalert";
import "react-datepicker/dist/react-datepicker.css";

// Local imports
import { LoadingSpinner } from "../../../constants/const";
import { GetLanguageString, NoResult } from "../../helper/Components";
import { addMember } from "../ApiMember";

const MemberEventDetails = ({ tab14, editValue }) => {

  // Use States
  const [errors, setErrors] = useState({
    startDate: "",
    endDate: "",
  });
  let tempDate = new Date();
  tempDate.setUTCDate(1);
  const [memberEventSearchtModel, setMemberEventSearchModel] = useState({
    memberId: editValue,
    startDate: tempDate,
    endDate: new Date(),
  });
  
  const [values, setValues] = useState(memberEventSearchtModel);
  const [lastMonth, setLastMonth] = useState("primary");
  const [lastSixMonth, setLastSixMonth] = useState("white");
  const [lastThreeMonth, setLastThreeMonth] = useState("white");
  const [data, setData] = useState({});
  const [lastYear, setLastYear] = useState("white");
  const [all, setAll] = useState("white");
  const [searchEnterDates, setSearchEnterDates] = useState(false);
  const [searchByFilters, setSearchByFilters] = useState(true);
  const [searchButton, setSearchButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const dateRangeValidation = {
    startDate: null,
    endDate: null,
  };
  const [dateValidations, setDateValidations] = useState(dateRangeValidation);

  // handles change in start dates
  const handleInputChangeStartDate = (value) => {
    setValues({
      ...values,
      startDate: value,
    });
    setDateValidations(dateRangeValidation);
    setErrors({
      ...errors,
      startDate: "",
    });
  };

  // handles change in End Dates
  const handleInputChangeEndDate = (value) => {
    setValues({
      ...values,
      endDate: value,
    });
    setDateValidations(dateRangeValidation);
    setErrors({
      ...errors,
      endDate: "",
    });
  };

  // On search updates EventDetails values
  const handleSearch = () => {
    setMemberEventSearchModel(values);
    setSearchButton(true);
  };

  // Choose the Search Method
  const handleSearchDates = (name) => {
    if (name === "enterDates") {
      setSearchEnterDates(true);
      setSearchByFilters(false);
    } else if (name === "filters") {
      setSearchEnterDates(false);
      setSearchByFilters(true);
      let tempDate = new Date();
      tempDate.setUTCDate(1);
      setValues({
        ...values,
        startDate: tempDate,
        endDate: new Date(),
      });
    }
  };

  // To handle change in dates
  const handleEventDate = (e) => {
    const { value } = e.target;
    let date = new Date();
    var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);

    if (value === "0") {
      setLastMonth("primary");
      setLastThreeMonth("white");
      setLastSixMonth("white");
      setLastYear("white");
      setAll("white");

      let firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
      setValues({
        ...values,
        startDate: firstDay,
        endDate: lastDay,
      });
    } else if (value === "1") {
      setLastMonth("white");
      setLastSixMonth("white");
      setLastThreeMonth("primary");
      setLastYear("white");
      setAll("white");

      let firstDay = new Date(date.getFullYear(), date.getMonth() - 3, 1);
      setValues({
        ...values,
        startDate: firstDay,
        endDate: lastDay,
      });
    } else if (value === "2") {
      setLastMonth("white");
      setLastThreeMonth("white");
      setLastSixMonth("primary");
      setLastYear("white");
      setAll("white");

      let firstDay = new Date(date.getFullYear(), date.getMonth() - 6, 1);
      setValues({
        ...values,
        startDate: firstDay,
        endDate: lastDay,
      });
    } else if (value === "3") {
      setLastMonth("white");
      setLastSixMonth("white");
      setLastThreeMonth("white");
      setLastYear("primary");
      setAll("white");

      let firstDay = new Date(date.getFullYear(), date.getMonth() - 12, 1);
      setValues({
        ...values,
        startDate: firstDay,
        endDate: lastDay,
      });
    } else if (value === "4") {
      setLastMonth("white");
      setLastSixMonth("white");
      setLastThreeMonth("white");
      setLastYear("white");
      setAll("primary");

      setValues({
        ...values,
        startDate: null,
        endDate: null,
      });

      // Clear validation errors for both start and end dates
      setErrors({
        startDate: "",
        endDate: "",
      });
    }

    setDateValidations(dateRangeValidation);
  };

  // Use effect for the APi CAll
  useEffect(() => {
    if (searchButton) {
      setLoading(true);
      addMember()
        .GetMemberEventAttendance(memberEventSearchtModel)
        .then((res) => {
          setData(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
          setLoading(false);
        });
  }
  }, [searchButton, memberEventSearchtModel]);

  return (
    <div role="tabpanel" 
    id= "tab-14" 
    className={"tab-pane " + tab14}
    >
      <div className="panel-body">
        {/* Form to get the date range for the Members */}
        <Row className="m-t-md">
          <Col md="6">
            <Label for="filters">
              {" "}
              <Input
                id="filters"
                type="radio"
                checked={searchByFilters}
                onChange={() => {
                  handleSearchDates("filters");
                }}
              />{" "}
              {
                <GetLanguageString props="event_event_wise_attendance_search_filters" />
              }
            </Label>
            <Row>
              <ButtonGroup style={{ padding: 32 }}>
                <Button
                  disabled={searchEnterDates}
                  color={lastMonth}
                  value={0}
                  onClick={handleEventDate}
                >
                  {<GetLanguageString props="funds_report_last_month" />}
                </Button>

                <Button
                  disabled={searchEnterDates}
                  color={lastThreeMonth}
                  value={1}
                  onClick={handleEventDate}
                >
                  {<GetLanguageString props="quick_reports4_last_3_months" />}
                </Button>

                <Button
                  disabled={searchEnterDates}
                  color={lastSixMonth}
                  value={2}
                  onClick={handleEventDate}
                >
                  {<GetLanguageString props="funds_report_last_6_months" />}
                </Button>

                <Button
                  disabled={searchEnterDates}
                  color={lastYear}
                  value={3}
                  onClick={handleEventDate}
                >
                  {<GetLanguageString props="funds_report_last_year" />}
                </Button>

                <Button
                  disabled={searchEnterDates}
                  color={all}
                  value={4}
                  onClick={handleEventDate}
                >
                  {<GetLanguageString props="notifications_all" />}
                </Button>
              </ButtonGroup>
            </Row>
          </Col>{" "}
          <Col md="6">
            <Label for="enterDates">
              {" "}
              <Input
                id="enterDates"
                type="radio"
                checked={searchEnterDates}
                onChange={() => {
                  handleSearchDates("enterDates");
                }}
              />{" "}
              {
                <GetLanguageString props="event_event_wise_attendance_search_enter_date" />
              }
            </Label>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label className="col-form-label">
                    {<GetLanguageString props="courses_start_date" />}
                  </Label>
                  <DatePicker
                    disabled={searchByFilters}
                    showYearDropdown
                    placeholderText="dd/mm/yyyy"
                    className="form-control"
                    selected={values?.startDate}
                    onChange={handleInputChangeStartDate}
                    dateFormat="dd/MM/yyyy"
                  />
                  {dateValidations.startDate && (
                    <h4 className="text-error">{dateValidations.startDate}</h4>
                  )}
                  {errors.startDate && (
                    <h4 className="text-error">{errors.startDate}</h4>
                  )}
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label className="col-form-label">
                    {<GetLanguageString props="courses_end_date" />}
                  </Label>
                  <DatePicker
                    disabled={searchByFilters}
                    showYearDropdown
                    placeholderText="dd/mm/yyyy"
                    className="form-control"
                    selected={values?.endDate}
                    onChange={handleInputChangeEndDate}
                    dateFormat="dd/MM/yyyy"
                  />
                  {dateValidations.endDate && (
                    <h4 className="text-error">{dateValidations.endDate}</h4>
                  )}
                  {errors.endDate && (
                    <h4 className="text-error">{errors.endDate}</h4>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md="12" className="text-center m-t-sm">
            <Button color="primary" type="submit" onClick={handleSearch}>
              {<GetLanguageString props="funds_submit" />}
            </Button>
          </Col>
        </Row>
      </div><br/>
      {/* Table to show the Ijtimaat details */}
      {searchButton ? (
        <div className="ibox">
          <div className="ibox-title">
            <h5>
              {<GetLanguageString props="member_member's_details_Ijima'at" />}
            </h5>
          </div>
          <div className="ibox-content">
            {!loading ? (
              data !== undefined &&
              data !== null &&
              Object.keys(data).length !== 0 ? (
                <div className="panel-body ibox-center">
                  <Table className="table-bordered m-b-none">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>
                          {
                            <GetLanguageString props="member_member_detail_events_name" />
                          }
                        </th>
                        <th>
                          {
                            <GetLanguageString props="dashboard_events_details_conducted_events" />
                          }
                        </th>
                        <th>
                          {
                            <GetLanguageString props="member_member's_details_attended_Ijima'at" />
                          }
                        </th>
                        <th>
                          {
                            <GetLanguageString props="dashboard_event_page_percentage" />
                          }
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((record, index) => {
                         const percentage = (record.eventsAttended / record.eventCount) * 100;
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td className="ur ">{record.eventName}</td>
                            <td>{record.eventCount}</td>
                            <td>{record.eventsAttended}</td>
                            <td>{percentage.toFixed(2)}%</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <NoResult />
              )
            ) : (
              LoadingSpinner()
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default MemberEventDetails;
