import axios from "axios";

import { BASEURL } from "../../../constants/const";
import { encryptStorage } from "../../../constants/EncryptStorage";

export function ApiCourseType() {
  const userToken = encryptStorage.getItem("token");

  const authAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const url = BASEURL + "coursetype/";
  return {
    getList: (record) => authAxios.post(url + "getList/", record),
    GetCourseTypeCodes: () => authAxios.get(url + "GetCourseTypeCodes/"),
    addCourseType: (record) => authAxios.post(url + "add/", record),
    delete: (id) => authAxios.delete(url + "delete/" + id),
  };
}
