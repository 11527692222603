import React, { useEffect, useState } from "react";
import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import { Button, ButtonGroup, Table } from "reactstrap";
import { GetLanguageString, NoResult } from "../../helper/Components";
import { ApiPledges } from "./ApiPledges";
import {
  LoadingSpinner,
  convertUTCToLocalTime,
} from "../../../constants/const";
import moment from "moment";
import AddPledge from "./AddPledge";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import EditPledge from "./EditPledge";
import { IoAdd } from "react-icons/io5";

const Pledges = ({ activeTab }) => {
  const [pledges, setPledges] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [id, setId] = useState(0);
  const [reload, setReload] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (activeTab === "pledges") {
      setLoading(true);
      ApiPledges()
        .GetPledges()
        .then((res) => {
          setPledges(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
        });
    }
  }, [reload, activeTab]);

  const handleOnEdit = (e) => {
    const { value } = e.target;
    setId(value);
    setEditModal(true);
  };

  const handleOnDelete = (id) => {
    swal({
      title: t("common_delete_message"),
      text: t("common_delete_detail_message"),
      className: GetSelectedLanguage() === "en" ? "" : "ur",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        ApiPledges()
          .Delete(id)
          .then((res) => {
            swal({
              title: t("settings_pledge_delete_success"),
              className: GetSelectedLanguage() === "en" ? "" : "ur",
              icon: "success",
              buttons: "OK",
            });
            setLoading(false);
            setReload(!reload);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            swal({
              title: err,
              icon: "error",
              buttons: "OK",
            });
            setReload(!reload);
          });
      }
    });
  };
  return (
    <>
      {addModal ? (
        <AddPledge
          setAddModal={setAddModal}
          setReload={setReload}
          reload={reload}
        />
      ) : null}
      {editModal ? (
        <EditPledge
          setEditModal={setEditModal}
          setReload={setReload}
          reload={reload}
          id={id}
        />
      ) : null}
      {!loading ? (
        <div className="ibox">
          <div className="ibox-title">
            <h5>
              {<GetLanguageString props="member_member_detail_pledges" />}
            </h5>
            <Button
              color="white"
              size="sm"
              className={GetCustomStyles().btn_style}
              onClick={() => setAddModal(true)}
            >
              <IoAdd
                size={18}
                color="#18A689"
                style={{ marginRight: 3, marginBottom: 1 }}
              />
              <GetLanguageString props="member_member_detail_add_pledge" />
            </Button>
          </div>

          <div className="ibox-content">
            {pledges.length !== 0 ? (
              <div className="table-responsive">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th className="text-center">
                        {<GetLanguageString props="report_member_ammer_name" />}
                      </th>
                      <th className="text-center">
                        {
                          <GetLanguageString props="member_member_detail_pledges_name" />
                        }
                      </th>
                      <th className="text-center">
                        {<GetLanguageString props="member_report_from" />}
                      </th>
                      <th className="text-center">
                        {<GetLanguageString props="member_report_to" />}
                      </th>
                      <th className="text-center">
                        {<GetLanguageString props="common_action" />}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {pledges.map((record, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="text-center ur">{record.ameerName}</td>
                          <td className="text-center ur">
                            {record.pledgeName}
                          </td>
                          <td className="text-center">
                            {" "}
                            {moment(
                              convertUTCToLocalTime(record.fromDate)
                            ).format("DD/MM/yyyy")}
                          </td>
                          <td className="text-center">
                            {" "}
                            {record.toDate
                              ? moment(
                                  convertUTCToLocalTime(record.toDate)
                                ).format("DD/MM/yyyy")
                              : "-"}
                          </td>
                          <td className="text-center">
                            <ButtonGroup>
                              <Button
                                color="white"
                                size="sm"
                                onClick={handleOnEdit}
                                value={record.id}
                              >
                                {<GetLanguageString props="common_edit" />}
                              </Button>
                              <Button
                                color="white"
                                size="sm"
                                onClick={() => handleOnDelete(record.id)}
                              >
                                {<GetLanguageString props="common_delete" />}
                              </Button>
                            </ButtonGroup>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            ) : (
              <NoResult />
            )}
          </div>
        </div>
      ) : (
        LoadingSpinner()
      )}
    </>
  );
};

export default Pledges;
