import React, { useState, useEffect } from "react";
import Select from "react-select";

import useForm from "../../shared/useForm";
import { ApiUnit } from "../../unit/ApiUnit";
import {
  Button,
  ButtonGroup,
  Row,
  Col,
  FormGroup,
  Form,
  Label,
  Table,
  Input,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import { saveAs } from "file-saver";

import {
  GetLanguageString,
  NoResult,
  SetReportTitle,
} from "../../helper/Components";
import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import { LoadingSpinner } from "../../../constants/const";
import { encryptStorage } from "../../../constants/EncryptStorage";
import { ApiResponsibility } from "../../config/responsibilityType/ApiResponsibilityType";
import swal from "sweetalert";
import { FcPrint } from "react-icons/fc";
import { RiFileExcel2Line } from "react-icons/ri";

const VacantResponsibilitiesReport = () => {
  const [loading, setLoading] = useState(false);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [reportTitle, setReportTitle] = useState("");
  const [modal, setModal] = useState(false);
  const [searchButton, setSearchButton] = useState(false);
  const [showAllUnits, setShowAllUnits] = useState("primary");
  const [showAllZones, setShowAllZones] = useState("white");
  const [showAllHalqas, setShowAllHalqas] = useState("white");
  const [showAllTanzeem, setShowAllTanzeem] = useState("white");
  const [showAllUsra, setShowAllUsra] = useState("white");
  const [searchValue, setSearchValue] = useState({
    unitId: loggedInUnitId,
    showDetails: false,
    showSelectedUnitsCheck: "None",
    showSemiRequired: true,
  });
  const unitTypeName = "null";
  let totalCount = {
    vacantResponsibilities: 0,
    totalResponsibilities: 0,
    twoResponsibles: 0,
    threeResponsibiles: 0,
    mubatadiResponsibiles: 0,
  };

  const [unitList, setUnitList] = useState([]);
  const [responsibilitiesList, setResponsibilitiesList] = useState([]);
  const { values, setValues } = useForm(searchValue);
  const [reportData, setReportData] = useState();
  const [unitType, setUnitType] = useState(
    encryptStorage.getItem("unitTypeName")
  );

  const customStyles = {
    border: "2px solid black",
    fontWeight: "bold",
  };

  const customTableStyles = {
    border: "1px solid black",
  };

  useEffect(() => {
    ApiUnit()
      .getUnitList(loggedInUnitId, loggedInUnitId, false, unitTypeName, true)
      .then((res) => {
        setUnitList(res.data);
      })
      .catch((err) => console.log(err));
  }, [loggedInUnitId]);

  const handleChangeUnits = (record) => {
    if (record !== null) {
      ApiUnit()
        .getUnitList(record.value, loggedInUnitId, false, unitTypeName, true)
        .then((res) => {
          setUnitList(res.data);
        })
        .catch((err) => console.log(err));
      setValues({
        ...values,
        unitId: record.value,
        showSelectedUnitsCheck: "None",
      });
      setShowAllZones("white");
      setShowAllUnits("primary");
      setShowAllHalqas("white");
      setShowAllTanzeem("white");
      setShowAllUsra("white");
      setUnitType(record.label1);
    } else {
      ApiUnit()
        .getUnitList(loggedInUnitId, loggedInUnitId, false, unitTypeName, true)
        .then((res) => {
          setUnitList(res.data);
        })
        .catch((err) => console.log(err));
      setValues({
        ...values,
        unitId: 0,
      });
    }
  };
  const handleCheckbox = (e) => {
    const { name, value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setValues({
      ...values,
      [name]: v,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setSearchButton(true);
    setSearchValue(values);
  };
  const handleShowSelectedUnitsCheck = (e) => {
    const { value } = e.target;
    if (value === "0") {
      setShowAllZones("white");
      setShowAllUnits("primary");
      setShowAllHalqas("white");
      setShowAllTanzeem("white");
      setShowAllUsra("white");
      setValues({
        ...values,
        showSelectedUnitsCheck: "None",
      });
    } else if (value === "1") {
      setShowAllZones("primary");
      setShowAllUnits("white");
      setShowAllHalqas("white");
      setShowAllTanzeem("white");
      setShowAllUsra("white");
      setValues({
        ...values,
        showSelectedUnitsCheck: "Zone",
      });
    } else if (value === "2") {
      setShowAllZones("white");
      setShowAllUnits("white");
      setShowAllHalqas("primary");
      setShowAllTanzeem("white");
      setShowAllUsra("white");
      setValues({
        ...values,
        showSelectedUnitsCheck: "Halqa",
      });
    } else if (value === "3") {
      setShowAllZones("white");
      setShowAllUnits("white");
      setShowAllHalqas("white");
      setShowAllTanzeem("primary");
      setShowAllUsra("white");
      setValues({
        ...values,
        showSelectedUnitsCheck: "Tanzeem",
      });
    } else if (value === "4") {
      setShowAllZones("white");
      setShowAllUnits("white");
      setShowAllHalqas("white");
      setShowAllTanzeem("white");
      setShowAllUsra("primary");
      setValues({
        ...values,
        showSelectedUnitsCheck: "Usra",
      });
    }
  };

  useEffect(() => {
    if (searchValue && searchButton) {
      setLoading(true);
      ApiResponsibility()
        .GetVacantResponsibilitiesReport(
          searchValue.unitId,
          searchValue.showDetails,
          searchValue.showSemiRequired,
          searchValue.showSelectedUnitsCheck
        )
        .then((res) => {
          setReportData(res.data);
          setResponsibilitiesList(res.data.responsibilities);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
        });
    }
  }, [searchValue, searchButton]);

  useEffect(() => {
    if (reportTitle !== "") window.print();
  }, [reportTitle]);

  const handleReportTitle = () => {
    setModal(true);
  };

  const excelDownload = () => {
    if (searchValue && searchButton) {
      ApiResponsibility()
        .GetVacantResponsibilitiesExcelReport(
          searchValue.unitId,
          searchValue.showDetails,
          searchValue.showSemiRequired,
          searchValue.showSelectedUnitsCheck
        )
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(blob, "ResponsibilitiesOverviewReport.xlsx");
        })
        .catch((err) => {
          console.log(err);
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
        });
    }
  };

  return (
    <div>
      {modal ? (
        <SetReportTitle setModal={setModal} setReportTitle={setReportTitle} />
      ) : null}
      <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
        <div className="ibox noprint">
          <div className="ibox-title">
            <h5>{<GetLanguageString props="common_generate_report" />}</h5>
          </div>
          <div className="ibox-content">
            <Form onSubmit={handleSubmit}>
              <Row>
                {unitList.map((record, index) => {
                  const unitOptions = [];
                  record.unitList.forEach((d) =>
                    d.unitTypeName !== "Munfarid" &&
                    d.unitTypeName !== "UsraMuavin"
                      ? unitOptions.push({
                          value: d.id,
                          label: d.name,
                          label1: d.unitTypeName,
                        })
                      : null
                  );
                  let selected = {};
                  let unitName = "";
                  if (record.parentUnitName !== null)
                    unitName = record.parentUnitName;
                  if (record.selectedUnit !== null) {
                    selected = {
                      value: record.selectedUnit.id,
                      label: record.selectedUnit.name,
                    };
                  }

                  return (
                    <>
                      <Col md="2" key={index}>
                        <FormGroup>
                          <Label className="col-form-label">
                            {unitName !== "" ? (
                              <GetLanguageString
                                props="member_select_unit_name"
                                value={unitName}
                              />
                            ) : (
                              <GetLanguageString props="member_select_unit" />
                            )}
                          </Label>
                          <Select
                            options={unitOptions}
                            value={selected}
                            className="basic-single ur"
                            classNamePrefix="select"
                            onChange={handleChangeUnits}
                          />
                        </FormGroup>
                      </Col>
                    </>
                  );
                })}
              </Row>
              <Row className="m-t-sm">
                <Col md="2">
                  <FormGroup check>
                    <Label check for="showDetails">
                      <Input
                        id="showDetails"
                        type="checkbox"
                        name="showDetails"
                        checked={values.showDetails}
                        value={!values.showDetails}
                        onChange={handleCheckbox}
                      />
                      {
                        <GetLanguageString props="report_vacant_responsibilities_show_details" />
                      }
                    </Label>
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup check>
                    <Label check for="showSemiRequired">
                      <Input
                        id="showSemiRequired"
                        type="checkbox"
                        name="showSemiRequired"
                        checked={!values.showSemiRequired}
                        value={!values.showSemiRequired}
                        onChange={handleCheckbox}
                      />
                      {
                        <GetLanguageString props="report_vacant_responsibilities_show_semi_required_responsibilities" />
                      }
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <ButtonGroup
                    style={{ paddingTop: 32 }}
                    className="text-center"
                  >
                    {(unitType === "Markaz" ||
                      unitType === "Zone" ||
                      unitType === "Halqa") && (
                      <Button
                        color={showAllUnits}
                        value={0}
                        onClick={handleShowSelectedUnitsCheck}
                      >
                        {
                          <GetLanguageString props="report_member_show_all_units" />
                        }
                      </Button>
                    )}
                    {unitType === "Markaz" && (
                      <Button
                        color={showAllZones}
                        value={1}
                        onClick={handleShowSelectedUnitsCheck}
                      >
                        {<GetLanguageString props="report_member_show_zones" />}
                      </Button>
                    )}
                    {(unitType === "Markaz" || unitType === "Zone") && (
                      <Button
                        color={showAllHalqas}
                        value={2}
                        onClick={handleShowSelectedUnitsCheck}
                      >
                        {<GetLanguageString props="report_member_show_halqa" />}
                      </Button>
                    )}
                    {(unitType === "Markaz" ||
                      unitType === "Zone" ||
                      unitType === "Halqa") && (
                      <Button
                        color={showAllTanzeem}
                        value={3}
                        onClick={handleShowSelectedUnitsCheck}
                      >
                        {
                          <GetLanguageString props="report_member_show_tanzeem" />
                        }
                      </Button>
                    )}
                    {(unitType === "Markaz" ||
                      unitType === "Zone" ||
                      unitType === "Halqa") && (
                      <Button
                        color={showAllUsra}
                        value={4}
                        onClick={handleShowSelectedUnitsCheck}
                      >
                        {<GetLanguageString props="report_member_show_usra" />}
                      </Button>
                    )}
                  </ButtonGroup>
                </Col>
              </Row>
              <Row className="m-t-md">
                <Col className="text-center">
                  <FormGroup>
                    <Button color="primary" className="m-l-sm" type="submit">
                      {<GetLanguageString props="common_view_report" />}
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
        {!loading ? (
          searchButton && reportData !== undefined ? (
            <div id="section-to-print">
              <Row className="print">
                <div className="text-center">
                  <h3>Title : {reportTitle}</h3>
                </div>
              </Row>
              <>
                <div className="ibox">
                  <div className="ibox-title noprint">
                    <ButtonGroup className={GetCustomStyles().btn_style}>
                      <Button color="default" size="sm" onClick={excelDownload}>
                        <RiFileExcel2Line
                          size={15}
                          color="#009440"
                          style={{ marginRight: 3, marginBottom: 1 }}
                        />
                        {
                          <GetLanguageString props="member_report_export_excel" />
                        }
                      </Button>
                      <Button
                        color="default"
                        size="sm"
                        onClick={handleReportTitle}
                      >
                        <FcPrint
                          size={15}
                          className={GetCustomStyles().fa_style}
                        />
                        {<GetLanguageString props="common_print" />}
                      </Button>
                    </ButtonGroup>
                  </div>
                  <div className="panel panel-success">
                    <div className="panel-heading">
                      <h4 className="text-center">
                        <GetLanguageString props="report_vacant_responsibilities_title" />
                      </h4>
                    </div>
                    <div className="panel-body">
                      <Row>
                        <Col md="2"></Col>
                        <Col md="4">
                          <Row>
                            <h1 className="ur">{reportData?.unitName}</h1>
                          </Row>
                          <Row>
                            {" "}
                            <h3>
                              {" "}
                              <GetLanguageString props="report_vacant_responsibilities_total" />{" "}
                              : {reportData?.totalResponsibilitiesCount}
                            </h3>
                          </Row>
                        </Col>
                        <Col md="2"></Col>
                        <Col md="3">
                          <Table size="small" className="m-b-xs">
                            <tbody>
                              <tr>
                                <td>
                                  <strong>
                                    {" "}
                                    {reportData?.assignedResponsibilitiesCount}
                                  </strong>{" "}
                                  &ensp;
                                  <GetLanguageString props="report_vacant_responsibilities_assigned" />{" "}
                                </td>
                                <td>
                                  <strong>
                                    {reportData?.vacantResponsibilitiesCount}
                                  </strong>{" "}
                                  &ensp;
                                  <GetLanguageString props="report_vacant_responsibilities_vacant" />{" "}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>
                                    {reportData?.twoResponsibleMembersCount}
                                  </strong>{" "}
                                  &ensp;
                                  <GetLanguageString props="report_vacant_responsibilities_two_responsibles" />{" "}
                                </td>
                                <td>
                                  <strong>
                                    {reportData?.threeResponsibleMembersCount}
                                  </strong>{" "}
                                  &ensp;
                                  <GetLanguageString props="report_vacant_responsibilities_three_responsibles" />{" "}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>
                                    {reportData?.mubtadiResponsibleMembersCount}
                                  </strong>{" "}
                                  &ensp;
                                  <GetLanguageString props="report_vacant_responsibilities_mubtadi_responsibles" />{" "}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                      {reportData?.unitResponsibilitiesCounts !== null &&
                      reportData?.unitResponsibilities !== null ? (
                        <Row className="m-2">
                          <Table bordered className="m-b-none">
                            <thead>
                              <tr>
                                {reportData?.unitResponsibiities?.map(
                                  (item, index) => {
                                    return (
                                      <th
                                        style={customTableStyles}
                                        className="text-center ur"
                                        key={index}
                                      >
                                        {item}
                                      </th>
                                    );
                                  }
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                {Object.entries(
                                  reportData?.unitResponsibilitiesCounts
                                ).map(([key, value], index) => {
                                  return (
                                    <td
                                      style={customTableStyles}
                                      key={index}
                                      className="text-center"
                                    >
                                      {value}
                                    </td>
                                  );
                                })}
                              </tr>
                            </tbody>
                          </Table>
                        </Row>
                      ) : null}
                    </div>
                  </div>
                  {reportData.unitResponsibilityCounts?.length !== 0 ? (
                    <div className="panel panel-success">
                      <div className="panel-heading text-center">
                        {" "}
                        <h4>
                          <GetLanguageString props="report_vacant_responsibilities_details" />
                        </h4>
                      </div>
                      <div className="panel-body ibox-center">
                        <>
                          <Table className="table-bordered m-b-none">
                            <thead>
                              <tr>
                                <th style={customTableStyles}>#</th>
                                <th style={customTableStyles}>
                                  {
                                    <GetLanguageString props="quick_reports3_unit_name" />
                                  }
                                </th>{" "}
                                <th
                                  style={customTableStyles}
                                  className="text-center"
                                >
                                  {
                                    <GetLanguageString props="report_vacant_responsibilities_total" />
                                  }
                                </th>
                                <th
                                  style={customTableStyles}
                                  className="text-center"
                                >
                                  {
                                    <GetLanguageString props="report_vacant_responsibilities_vacant" />
                                  }
                                </th>
                                <th
                                  style={customTableStyles}
                                  className="text-center"
                                >
                                  {
                                    <GetLanguageString props="report_vacant_responsibilities_two_responsibles" />
                                  }
                                </th>
                                <th
                                  style={customTableStyles}
                                  className="text-center"
                                >
                                  {
                                    <GetLanguageString props="report_vacant_responsibilities_three_responsibles" />
                                  }
                                </th>
                                <th
                                  style={customTableStyles}
                                  className="text-center"
                                >
                                  {
                                    <GetLanguageString props="report_vacant_responsibilities_mubtadi_responsibles" />
                                  }
                                </th>
                                {responsibilitiesList.map((item, index) => {
                                  return (
                                    <th
                                      style={customTableStyles}
                                      className="text-center ur"
                                      key={index}
                                    >
                                      {item} &ensp;
                                      <span
                                        class="label label-success"
                                        style={{ borderRadius: "50%" }}
                                      >
                                        {
                                          reportData
                                            .responsibilityTotalInUnitCount[
                                            index
                                          ]
                                        }
                                      </span>
                                    </th>
                                  );
                                })}
                              </tr>
                            </thead>
                            <tbody>
                              {reportData?.unitResponsibilityCounts?.map(
                                (record, index) => {
                                  totalCount.vacantResponsibilities +=
                                    record.vacantUnitResponsibilitiesCount;
                                  totalCount.totalResponsibilities +=
                                    record.totalUnitResponsibilitiesCount;
                                  totalCount.twoResponsibles +=
                                    record.twoUnitResponsibleMembersCount;
                                  totalCount.threeResponsibiles +=
                                    record.threeUnitResponsibleMembersCount;
                                  totalCount.mubatadiResponsibiles +=
                                    record.mubtadiUnitResponsibleMembersCount;
                                  return (
                                    <tr key={index}>
                                      <td style={customTableStyles}>
                                        {index + 1}
                                      </td>{" "}
                                      <td
                                        style={customTableStyles}
                                        className="ur"
                                      >
                                        {record.unitName}
                                      </td>
                                      <td
                                        style={customTableStyles}
                                        className="text-center"
                                      >
                                        {record.totalUnitResponsibilitiesCount}
                                      </td>
                                      <td
                                        style={customTableStyles}
                                        className="text-center"
                                      >
                                        {record.vacantUnitResponsibilitiesCount}
                                      </td>
                                      <td
                                        style={customTableStyles}
                                        className="text-center"
                                      >
                                        {record.twoUnitResponsibleMembersCount}
                                      </td>
                                      <td
                                        style={customTableStyles}
                                        className="text-center"
                                      >
                                        {
                                          record.threeUnitResponsibleMembersCount
                                        }
                                      </td>
                                      <td
                                        style={customTableStyles}
                                        className="text-center"
                                      >
                                        {
                                          record.mubtadiUnitResponsibleMembersCount
                                        }
                                      </td>
                                      {Object.entries(
                                        record.responsibilitiesCounts
                                      ).map(([key, value], index) => {
                                        return (
                                          <td
                                            style={customTableStyles}
                                            className="text-center"
                                          >
                                            {record.responsibilitiesCountNA[
                                              index
                                            ]
                                              ? value
                                              : "N/A"}
                                          </td>
                                        );
                                      })}
                                    </tr>
                                  );
                                }
                              )}
                              <tr>
                                <td></td>
                                <td></td>
                                <td
                                  className="text-center"
                                  style={customStyles}
                                >
                                  {totalCount.totalResponsibilities}
                                </td>
                                <td
                                  className="text-center"
                                  style={customStyles}
                                >
                                  {totalCount.vacantResponsibilities}
                                </td>
                                <td
                                  className="text-center"
                                  style={customStyles}
                                >
                                  {totalCount.twoResponsibles}
                                </td>
                                <td
                                  className="text-center"
                                  style={customStyles}
                                >
                                  {totalCount.threeResponsibiles}
                                </td>
                                <td
                                  className="text-center"
                                  style={customStyles}
                                >
                                  {totalCount.mubatadiResponsibiles}
                                </td>
                                {reportData?.responsibilitiesTotalCount?.map(
                                  (item, index) => {
                                    return (
                                      <td
                                        className="text-center"
                                        style={customStyles}
                                      >
                                        {reportData
                                          ?.responsibilitiesTotalCountNA[index]
                                          ? item
                                          : "N/A"}
                                      </td>
                                    );
                                  }
                                )}
                              </tr>
                            </tbody>
                          </Table>
                        </>
                      </div>
                    </div>
                  ) : (
                    <NoResult />
                  )}
                </div>
                <>
                  {searchValue.showDetails && (
                    <>
                      <div className="panel panel-success">
                        <div className="panel-heading text-center">
                          <h4>
                            {
                              <GetLanguageString props="report_vacant_responsibilities_member_two_responsibles" />
                            }
                          </h4>
                        </div>
                        <div className="panel-body">
                          {reportData?.twoResponsibilityMembers?.length !==
                          0 ? (
                            <Table responsive bordered>
                              <thead>
                                <tr>
                                  <th style={customTableStyles}>#</th>
                                  <th style={customTableStyles}>
                                    {
                                      <GetLanguageString props="member_reg_number" />
                                    }
                                  </th>
                                  <th style={customTableStyles}>
                                    {<GetLanguageString props="common_name" />}
                                  </th>
                                  <th style={customTableStyles}>
                                    {
                                      <GetLanguageString props="member_father_name" />
                                    }
                                  </th>
                                  <th style={customTableStyles}>
                                    {
                                      <GetLanguageString props="member_responsibility" />
                                    }
                                  </th>
                                  <th style={customTableStyles}>
                                    {
                                      <GetLanguageString props="member_member_detail_common_unit_name" />
                                    }
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {reportData?.twoResponsibilityMembers?.map(
                                  (record, index) => {
                                    return record.responsibilities.map(
                                      (item, index1) => {
                                        return (
                                          <tr key={index1}>
                                            {index1 === 0 ? (
                                              <>
                                                <td
                                                  style={customTableStyles}
                                                  rowSpan={
                                                    record.responsibilities
                                                      .length
                                                  }
                                                >
                                                  {index + 1}
                                                </td>
                                                <td
                                                  style={customTableStyles}
                                                  rowSpan={
                                                    record.responsibilities
                                                      .length
                                                  }
                                                >
                                                  {record.regNo}
                                                </td>
                                                <td
                                                  style={customTableStyles}
                                                  rowSpan={
                                                    record.responsibilities
                                                      .length
                                                  }
                                                  className="ur"
                                                >
                                                  {record.name}
                                                </td>
                                                <td
                                                  style={customTableStyles}
                                                  rowSpan={
                                                    record.responsibilities
                                                      .length
                                                  }
                                                  className="ur"
                                                >
                                                  {record.fatherName}
                                                </td>
                                              </>
                                            ) : null}
                                            <td
                                              style={{
                                                borderBottom:
                                                  index1 + 1 ===
                                                  record.responsibilities.length
                                                    ? "1px solid black"
                                                    : "",
                                                borderRight: "1px solid black",
                                              }}
                                              className="ur"
                                            >
                                              {item.responsibilityName}
                                            </td>{" "}
                                            <td
                                              style={{
                                                borderBottom:
                                                  index1 + 1 ===
                                                  record.responsibilities.length
                                                    ? "1px solid black"
                                                    : "",
                                                borderRight: "1px solid black",
                                              }}
                                              className="ur"
                                            >
                                              <ol className="breadcrumb">
                                                {item.unitName
                                                  ?.split("،")
                                                  ?.map((item1, index) => {
                                                    return (
                                                      <li
                                                        className={
                                                          GetSelectedLanguage() ===
                                                          "ur"
                                                            ? "breadcrumb-item-ur"
                                                            : "breadcrumb-item"
                                                        }
                                                        key={index}
                                                      >
                                                        {item1}
                                                      </li>
                                                    );
                                                  })}
                                              </ol>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    );
                                  }
                                )}
                              </tbody>
                            </Table>
                          ) : (
                            <NoResult />
                          )}
                        </div>
                      </div>
                      <div className="panel panel-success">
                        <div className="panel-heading text-center">
                          <h4>
                            {
                              <GetLanguageString props="report_vacant_responsibilities_member_three_responsibles" />
                            }
                          </h4>
                        </div>
                        <div className="panel-body">
                          {reportData?.threeResponsibilityMembers?.length !==
                          0 ? (
                            <Table responsive bordered>
                              <thead>
                                <tr>
                                  <th style={customTableStyles}>#</th>
                                  <th style={customTableStyles}>
                                    {
                                      <GetLanguageString props="member_reg_number" />
                                    }
                                  </th>
                                  <th style={customTableStyles}>
                                    {<GetLanguageString props="common_name" />}
                                  </th>
                                  <th style={customTableStyles}>
                                    {
                                      <GetLanguageString props="member_father_name" />
                                    }
                                  </th>
                                  <th style={customTableStyles}>
                                    {
                                      <GetLanguageString props="member_responsibility" />
                                    }
                                  </th>
                                  <th style={customTableStyles}>
                                    {
                                      <GetLanguageString props="member_member_detail_common_unit_name" />
                                    }
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {reportData?.threeResponsibilityMembers?.map(
                                  (record, index) => {
                                    return record.responsibilities.map(
                                      (item, index1) => {
                                        return (
                                          <tr key={index1}>
                                            {index1 === 0 ? (
                                              <>
                                                <td
                                                  style={customTableStyles}
                                                  rowSpan={
                                                    record.responsibilities
                                                      .length
                                                  }
                                                >
                                                  {index + 1}
                                                </td>
                                                <td
                                                  style={customTableStyles}
                                                  rowSpan={
                                                    record.responsibilities
                                                      .length
                                                  }
                                                >
                                                  {record.regNo}
                                                </td>
                                                <td
                                                  style={customTableStyles}
                                                  rowSpan={
                                                    record.responsibilities
                                                      .length
                                                  }
                                                  className="ur"
                                                >
                                                  {record.name}
                                                </td>
                                                <td
                                                  style={customTableStyles}
                                                  rowSpan={
                                                    record.responsibilities
                                                      .length
                                                  }
                                                  className="ur"
                                                >
                                                  {record.fatherName}
                                                </td>
                                              </>
                                            ) : null}
                                            <td
                                              style={{
                                                borderBottom:
                                                  index1 + 1 ===
                                                  record.responsibilities.length
                                                    ? "1px solid black"
                                                    : "",
                                                borderRight: "1px solid black",
                                              }}
                                              className="ur"
                                            >
                                              {item.responsibilityName}
                                            </td>{" "}
                                            <td
                                              style={{
                                                borderBottom:
                                                  index1 + 1 ===
                                                  record.responsibilities.length
                                                    ? "1px solid black"
                                                    : "",
                                                borderRight: "1px solid black",
                                              }}
                                              className="ur"
                                            >
                                              <ol className="breadcrumb">
                                                {item.unitName
                                                  ?.split("،")
                                                  ?.map((item1, index) => {
                                                    return (
                                                      <li
                                                        className={
                                                          GetSelectedLanguage() ===
                                                          "ur"
                                                            ? "breadcrumb-item-ur"
                                                            : "breadcrumb-item"
                                                        }
                                                        key={index}
                                                      >
                                                        {item1}
                                                      </li>
                                                    );
                                                  })}
                                              </ol>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    );
                                  }
                                )}
                              </tbody>
                            </Table>
                          ) : (
                            <NoResult />
                          )}
                        </div>
                      </div>
                      <div className="panel panel-success">
                        <div className="panel-heading text-center">
                          <h4>
                            {
                              <GetLanguageString props="report_vacant_responsibilities_member_mubtadi_responsibles" />
                            }
                          </h4>
                        </div>
                        <div className="panel-body">
                          {reportData?.mubtadiResponsibilityMembers?.length !==
                          0 ? (
                            <Table responsive bordered>
                              <thead>
                                <tr>
                                  <th style={customTableStyles}>#</th>
                                  <th style={customTableStyles}>
                                    {
                                      <GetLanguageString props="member_reg_number" />
                                    }
                                  </th>
                                  <th style={customTableStyles}>
                                    {<GetLanguageString props="common_name" />}
                                  </th>
                                  <th style={customTableStyles}>
                                    {
                                      <GetLanguageString props="member_father_name" />
                                    }
                                  </th>
                                  <th style={customTableStyles}>
                                    {
                                      <GetLanguageString props="member_responsibility" />
                                    }
                                  </th>
                                  <th style={customTableStyles}>
                                    {
                                      <GetLanguageString props="member_member_detail_common_unit_name" />
                                    }
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {reportData?.mubtadiResponsibilityMembers?.map(
                                  (record, index) => {
                                    return record.responsibilities.map(
                                      (item, index1) => {
                                        return (
                                          <tr key={index1}>
                                            {index1 === 0 ? (
                                              <>
                                                <td
                                                  style={customTableStyles}
                                                  rowSpan={
                                                    record.responsibilities
                                                      .length
                                                  }
                                                >
                                                  {index + 1}
                                                </td>
                                                <td
                                                  style={customTableStyles}
                                                  rowSpan={
                                                    record.responsibilities
                                                      .length
                                                  }
                                                >
                                                  {record.regNo}
                                                </td>
                                                <td
                                                  style={customTableStyles}
                                                  rowSpan={
                                                    record.responsibilities
                                                      .length
                                                  }
                                                  className="ur"
                                                >
                                                  {record.name}
                                                </td>
                                                <td
                                                  style={customTableStyles}
                                                  rowSpan={
                                                    record.responsibilities
                                                      .length
                                                  }
                                                  className="ur"
                                                >
                                                  {record.fatherName}
                                                </td>
                                              </>
                                            ) : null}
                                            <td
                                              style={{
                                                borderBottom:
                                                  index1 + 1 ===
                                                  record.responsibilities.length
                                                    ? "1px solid black"
                                                    : "",
                                                borderRight: "1px solid black",
                                              }}
                                              className="ur"
                                            >
                                              {item.responsibilityName}
                                            </td>{" "}
                                            <td
                                              style={{
                                                borderBottom:
                                                  index1 + 1 ===
                                                  record.responsibilities.length
                                                    ? "1px solid black"
                                                    : "",
                                                borderRight: "1px solid black",
                                              }}
                                              className="ur"
                                            >
                                              <ol className="breadcrumb">
                                                {item.unitName
                                                  ?.split("،")
                                                  ?.map((item1, index) => {
                                                    return (
                                                      <li
                                                        className={
                                                          GetSelectedLanguage() ===
                                                          "ur"
                                                            ? "breadcrumb-item-ur"
                                                            : "breadcrumb-item"
                                                        }
                                                        key={index}
                                                      >
                                                        {item1}
                                                      </li>
                                                    );
                                                  })}
                                              </ol>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    );
                                  }
                                )}
                              </tbody>
                            </Table>
                          ) : (
                            <NoResult />
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </>
              </>
            </div>
          ) : null
        ) : (
          LoadingSpinner()
        )}
      </div>
    </div>
  );
};

export default VacantResponsibilitiesReport;
