import React from "react";
import { withRouter } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";

import { GetLanguageString } from "../../helper/Components";

const InfaqQuickLink = ({ history }) => {
  return (
    <div className="wrapper wrapper-content wrapper-custom">
      <div className="ibox">
        <div className="ibox-content panel-body">
          <h3>{<GetLanguageString props="quick_reports_heading" />}</h3>
          <p>{<GetLanguageString props="quick_reports_message" />}</p>
          <Row>
            <Col xl="1" className="text-center">
              <Button
                className="btn-success dim btn-large-dim"
                onClick={() =>
                  history.push({
                    pathname: `/infaqreport/unitwise`,
                  })
                }
              >
                <i className="fa fa-money"></i>
              </Button>
              <h4>{<GetLanguageString props="quick_infaq_reports_button1" />}</h4>
            </Col>{" "}
            <Col xl="1" className="text-center">
              <Button
                className="btn-primary dim btn-large-dim"
                onClick={() =>
                  history.push({
                    pathname: `/fundsmanagement/report/infaq`,
                  })
                }
              >
                <i className="fa fa-money"></i>
              </Button>
              <h4>{<GetLanguageString props="quick_infaq_reports_button2" />}</h4>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default withRouter(InfaqQuickLink);
