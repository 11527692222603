import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { Table, Button, ButtonGroup, Badge } from "reactstrap";
import { useTranslation } from "react-i18next";

import { requestApi } from "../../../actions/api";
import EditEventType from "./EditEvent";
import { GetLanguageString, NoResult } from "../../helper/Components";
import { LoadingSpinner } from "../../../constants/const";
import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import AddEventType from "./AddEventType";
import { IoAdd } from "react-icons/io5";

const EventType = ({ activeTab }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [eventType, setEventType] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [editValue, setEditValue] = useState();
  const [addModal, setAddModal] = useState(false);

  useEffect(() => {
    if (activeTab === "event") {
      setLoading(true);
      requestApi("eventtype/")
        .fetchAll()
        .then((res) => {
          setEventType(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [activeTab]);

  const handleOnDelete = (value, isAffiliated) => {
    if (isAffiliated) {
      swal({
        title: t("common_is_affiliated"),
        text: t("common_is_affiliated_message"),
        className: GetSelectedLanguage() === "en" ? "" : "ur",
        icon: "warning",
      });
    } else {
      swal({
        title: t("common_delete_message"),
        text: t("common_delete_detail_message"),
        icon: "warning",
        className: GetSelectedLanguage() === "en" ? "" : "ur",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setLoading(true);
          requestApi("eventtype/")
            .delete(value)
            .then((res) => {
              setEventType(res.data);
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        }
      });
    }
  };

  const handleOnEdit = (e) => {
    const { value } = e.target;
    setEditValue(value);
    setEditModal(true);
  };

  return (
    <>
      {addModal ? (
        <AddEventType setAddModal={setAddModal} setEventType={setEventType} />
      ) : null}
      {editModal ? (
        <EditEventType
          editValue={editValue}
          setEditModal={setEditModal}
          setEventType={setEventType}
        />
      ) : null}
      {!loading ? (
        <div className="ibox">
          <div className="ibox-title">
            <h5>{<GetLanguageString props="settings_event_type" />}</h5>
            <Button
              color="white"
              size="sm"
              className={GetCustomStyles().btn_style}
              onClick={() => setAddModal(true)}
            >
              <IoAdd
                size={18}
                color="#18A689"
                style={{ marginRight: 3, marginBottom: 1 }}
              />
              <GetLanguageString props="settings_add_event_type" />
            </Button>
          </div>
          {eventType.length !== 0 ? (
            <div className="ibox-content">
              <div className="table-responsive">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th className="text-center">
                        {<GetLanguageString props="common_name" />}
                      </th>
                      <th className="text-center">
                        {<GetLanguageString props="settings_code" />}
                      </th>
                      <th className="text-center">
                        {
                          <GetLanguageString props="dashboard_event_page_unit_type" />
                        }
                      </th>
                      <th className="text-center">
                        {
                          <GetLanguageString props="settings_event_type_frequency" />
                        }
                      </th>
                      <th className="text-center">
                        {
                          <GetLanguageString props="settings_event_type_allow_ahbab" />
                        }
                      </th>
                      <th className="text-center">
                        {
                          <GetLanguageString props="settings_events_for_child_unit" />
                        }
                      </th>
                      <th className="text-center">
                        <GetLanguageString props="member_address_enabled" />
                      </th>
                      <th className="text-center">
                        <GetLanguageString props="settings_color_code" />
                      </th>
                      <th className="text-center">
                        {
                          <GetLanguageString props="settings_event_type_default_participants_filters" />
                        }
                      </th>
                      <th className="text-center">
                        {<GetLanguageString props="common_action" />}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {eventType.map((record, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="ur text-center">{record.type}</td>
                          <td className="ur text-center">{record.code}</td>
                          <td className="text-center">
                            {record.unitType !== null ? (
                              <Button
                                size="xs"
                                color="info"
                                className="label label-info m-r-xs"
                              >
                                <h6 className="ur">{record.unitType.label}</h6>
                              </Button>
                            ) : null}
                          </td>
                          <td className="text-center">
                            {record.frequency?.label}
                          </td>
                          <td className="text-center">
                            {record.allowAhbab ? (
                              <GetLanguageString props="Yes" />
                            ) : (
                              <GetLanguageString props="No" />
                            )}
                          </td>
                          <td className="text-center">
                            {record.forChildUnit ? (
                              <GetLanguageString props="Yes" />
                            ) : (
                              <GetLanguageString props="No" />
                            )}
                          </td>{" "}
                          <td className="text-center">
                            {record.isEnabled ? (
                              <GetLanguageString props="Yes" />
                            ) : (
                              <GetLanguageString props="No" />
                            )}
                          </td>
                          <td className="p-t-custom text-center">
                            <div
                              style={{
                                width: "25px",
                                height: "25px",
                                borderRadius: "50%",
                                backgroundColor: record.color,
                              }}
                            >
                              {" "}
                              <span
                                className={
                                  GetSelectedLanguage() === "en"
                                    ? "m-l-lg"
                                    : "m-r-lg"
                                }
                              >
                                {record.color}
                              </span>
                            </div>
                          </td>
                          <td className="text-center">
                            {(record.defaultParticipantsFilters + "")
                              .split(",")
                              .map((v, i) => {
                                return (
                                  <Badge
                                    color="primary"
                                    className="m-1"
                                    key={i}
                                  >
                                    {v}
                                  </Badge>
                                );
                              })}
                          </td>
                          <td className="text-center">
                            <ButtonGroup>
                              <Button
                                color="white"
                                size="sm"
                                onClick={handleOnEdit}
                                value={record.id}
                              >
                                {<GetLanguageString props="common_edit" />}
                              </Button>
                              <Button
                                color="white"
                                size="sm"
                                onClick={() =>
                                  handleOnDelete(record.id, record.isAffiliated)
                                }
                              >
                                {<GetLanguageString props="common_delete" />}
                              </Button>
                            </ButtonGroup>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          ) : (
            <NoResult />
          )}
        </div>
      ) : (
        LoadingSpinner()
      )}
    </>
  );
};

export default EventType;
