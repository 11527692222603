export const GetSelectedLanguage = () => {
  localStorage.getItem("i18nextLng") === "en-GB" ||
  localStorage.getItem("i18nextLng") === "en-US" ||
  localStorage.getItem("i18nextLng") === "" ||
  localStorage.getItem("i18nextLng") === null
    ? localStorage.setItem("i18nextLng", "en")
    : localStorage.getItem("i18nextLng");
  return localStorage.getItem("i18nextLng");
};

export const GetCustomStyles = () => {
  const selectedLanguage = localStorage.getItem("i18nextLng");
  let export_excel_style = "";
  let fa_style = "";
  let btn_style = "";
  let btn_group_style = "";
  let err_btn_group_style = "";
  let page_style = "";
  let err_btn_style = "";
  let unit_btn_style = "";
  let movemembers_btn_style = "";
  let member_detail_event_ibox_tool = "";
  let ibox_tool = "";
  let member_detail_event_btn_title = "";
  let stat_list_percent = "";
  let sidebar_second_item_style = "";
  let sidebar_urdu_style = "";

  if (selectedLanguage === "en") {
    export_excel_style = " m-r-4xl";
    fa_style = "m-r-xs";
    btn_style = "pull-right";
    btn_group_style = "pull-right";
    err_btn_group_style = "pull-right";
    err_btn_style = "pull-right";
    unit_btn_style = "pull-right m-b";
    page_style = "pagination float-right";
    movemembers_btn_style = "big-checkbox m-l-lg";
    member_detail_event_ibox_tool = "ibox-tools";
    member_detail_event_btn_title = "m-r-sm";
    stat_list_percent = "stat-percent";
    ibox_tool = "ibox-tools";
    sidebar_second_item_style = "m-l-sm";
    sidebar_urdu_style = "";
  } else {
    export_excel_style = " m-l-4xl";
    fa_style = "m-l-xs";
    btn_group_style = "ur-btn-group pull-left";
    err_btn_group_style = "ur-btn-group pull-left m-t-n-lg";
    err_btn_style = "pull-left  m-t-n-lg";
    btn_style = "pull-left";
    unit_btn_style = "pull-left m-b";
    page_style = "pagination float-left";
    movemembers_btn_style = "big-checkbox m-r-lg";
    member_detail_event_ibox_tool = "pull-left m-t-n-lg";
    member_detail_event_btn_title = "m-l-sm";
    stat_list_percent = "pull-left";
    ibox_tool = "pull-left";
    sidebar_second_item_style = "m-r";
    sidebar_urdu_style = "m-l";
  }

  return {
    export_excel_style,
    fa_style,
    btn_style,
    page_style,
    err_btn_group_style,
    err_btn_style,
    unit_btn_style,
    movemembers_btn_style,
    member_detail_event_ibox_tool,
    member_detail_event_btn_title,
    stat_list_percent,
    ibox_tool,
    sidebar_second_item_style,
    sidebar_urdu_style,
    btn_group_style,
  };
};

export const GetFooTableCustomStyles = () => {
  const selectedLanguage = localStorage.getItem("i18nextLng");
  let span_style = "";
  let up_down_style = "fa fa-caret-up m-b-n-xs";
  let down_up_style = "fa fa-caret-down m-t-n-3px";
  let up_style = "fa fa-caret-up";
  let down_style = "fa fa-caret-down";

  if (selectedLanguage === "en") {
    span_style = "m-l-sm caret-styles";
  } else {
    span_style = "m-r-sm caret-styles";
  }

  return {
    span_style,
    up_down_style,
    down_style,
    down_up_style,
    up_style,
  };
};

export function GetMonths() {
  let months = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];
  return months;
}

export function GetYears() {
  let years = [];
  let startYear = 2015;
  let date = new Date();
  let endYear = date.getFullYear();
  for (let year = endYear; year >= startYear; year--) {
    years.push({ value: year, label: year });
  }
  return years;
}

export function DateValidater(startDate = "", endDate = "") {
  if (startDate === null || endDate === null) {
    return true;
  }

  if (startDate <= endDate) return true;
  return false;
}

export function SelectOnlyDate(date) {
  if (date === null || date === undefined) return date;
  // Set the time to 5 hours, 0 minutes, 0 seconds, and 0 milliseconds
  date.setHours(5, 0, 0, 0);
  return date;
}

export function WidgetClassName(memberType) {
  switch (memberType) {
    case "Marhoom":
    case "SabiqaRafeeq":
      return "widget style1 red-bg";
    default:
      return "widget style1 lazur-bg";
  }
}

export function GetPeriodName(letterFrequency, letterForFrequency) {
  switch (letterFrequency) {
    case 12: // MONTHLY
      switch (letterForFrequency) {
        case 1:
          return "جنوری";
        case 2:
          return "فروری";
        case 3:
          return "مارچ";
        case 4:
          return "اپریل";
        case 5:
          return "مئی";
        case 6:
          return "جون";
        case 7:
          return "جولائی";
        case 8:
          return "اگست";
        case 9:
          return "ستمبر";
        case 10:
          return "اکتوبر";
        case 11:
          return "نومبر";
        case 12:
          return "دسمبر";
        default:
          return "";
      }
    case 4: // QUARTERLY
      switch (letterForFrequency) {
        case 1:
          return "جنوری تا مارچ";
        case 2:
          return "اپریل تا جون";
        case 3:
          return "جولائی تا ستمبر";
        case 4:
          return "اکتوبر تا دسمبر";
        default:
          return "";
      }
    case 2: // BIANNUALY
      switch (letterForFrequency) {
        case 1:
          return "جنوری تا جون";
        case 2:
          return "جولائی تا دسمبر";
        default:
          return "";
      }
    default:
      return "";
  }
}

export function GetLetterForCounselingFrequencyList(letterFrequency) {
  switch (letterFrequency) {
    case 12: // MONTHLY
      return [
        { label: "جنوری", value: 1 },
        { label: "فروری", value: 2 },
        { label: "مارچ", value: 3 },
        { label: "اپریل", value: 4 },
        { label: "مئی", value: 5 },
        { label: "جون", value: 6 },
        { label: "جولائی", value: 7 },
        { label: "اگست", value: 8 },
        { label: "ستمبر", value: 9 },
        { label: "اکتوبر", value: 10 },
        { label: "نومبر", value: 11 },
        { label: "دسمبر", value: 12 },
      ];
    case 4: // QUARTERLY
      return [
        { label: "جنوری تا مارچ", value: 1 },
        { label: "اپریل تا جون", value: 2 },
        { label: "جولائی تا ستمبر", value: 3 },
        { label: "اکتوبر تا دسمبر", value: 4 },
      ];
    case 2: // BIANNUALLY
      return [
        { label: "جنوری تا جون", value: 1 },
        { label: "جولائی تا دسمبر", value: 2 },
      ];
    default:
      return [];
  }
}
