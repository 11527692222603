import React from "react";
import { Input, Row, Table } from "reactstrap";
import { GetLanguageString } from "../../helper/Components";
import { isUrdu } from "../../../constants/const";

const LetterForCounsellingDataTable = ({
  questionsList,
  setQuestionsList,
  setErrorMessage,
  errorMessage,
  letterColor,
}) => {
  const handleRating = (e, index, value) => {
    let updatedQuestions = [...questionsList];
    updatedQuestions[index] = {
      ...updatedQuestions[index],
      rating: value,
      errorColor: "#ffffff",
    };

    if (updatedQuestions.every((q) => q.rating !== 0)) {
      setErrorMessage({ ...errorMessage, questions: "" });
    }

    setQuestionsList(updatedQuestions);
  };

  const renderRatingCell = (index, item, ratingValue) => (
    <td
      className="text-center"
      onClick={() => handleRating(null, index, ratingValue)}
      style={{ cursor: "pointer" }}
    >
      <Input
        type="radio"
        checked={item.rating === ratingValue}
        readOnly
        style={{
          pointerEvents: "none",
          transform: "scale(1.2)",
          marginRight: "10px",
          cursor: "pointer",
          border: `2px solid ${letterColor}`,
          backgroundColor:
            item.rating === ratingValue ? letterColor : "transparent",
        }}
      />
    </td>
  );

  return (
    <>
      <Table responsive bordered>
        <thead style={{ backgroundColor: letterColor }}>
          <tr>
            {[
              "settings_letter_for_counselling_question_q_number",
              "settings_letter_for_counselling_questions",
              "تسلی بخس",
              "بہتر ہوئی ہے",
              "کوشش جاری ہے",
              "ارادہ ہے",
            ].map((text, i) => (
              <th
                key={i}
                className={isUrdu(text) ? "ur" : ""}
                style={{ fontSize: 15, backgroundColor: letterColor }}
              >
                <GetLanguageString props={text} />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {questionsList.map((item, index) => (
            <tr key={index} style={{ backgroundColor: item.errorColor }}>
              <td className="text-center" style={{ fontSize: 15 }}>
                {item.questionNumber}
              </td>
              <td className="ur" style={{ fontSize: 17 }}>
                {item.questionTitle}
              </td>
              {[1, 2, 3, 4].map((rating) =>
                renderRatingCell(index, item, rating)
              )}
            </tr>
          ))}
        </tbody>
      </Table>
      {Object.values(errorMessage).map(
        (msg, i) =>
          msg && (
            <Row key={i}>
              <h5 className="text-error">
                * {<GetLanguageString props={msg} />}
              </h5>
            </Row>
          )
      )}
    </>
  );
};

export default LetterForCounsellingDataTable;
