import React, { useState } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { GetLanguageString } from "../../helper/Components";
import Draggable from "react-draggable";

const HabibDetail = ({ habibDetails, setHabibDetailsModal }) => {
  const toggle = () => {
    setHabibDetailsModal(false);
  };
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  return (
    <div>
    <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
      <Modal isOpen={true} className="inmodal">
        <ModalHeader className="modal-title" tag="h4">
          {
            <GetLanguageString props="ahbab_transfer_habib_habib_details" />
          }
        </ModalHeader>
        <ModalBody>
          <div className="ibox-content">
            {" "}
            <Row>
              <Col md="1"></Col>
              <Col md="3" className="text-left">
                <h4 className="m">
                  <GetLanguageString props="member_email" />
                </h4>
              </Col>
              <Col md="1">
                <h4 className="m">{" : "}</h4>
              </Col>
              <Col md="7" className="text-left">
                <h4 className="m no-drag">{habibDetails?.email}</h4>
              </Col>
            </Row>
            <Row>
              <Col md="1"></Col>
              <Col md="3" className="text-left">
                <h4 className="m">
                  <GetLanguageString props="member_education" />
                </h4>
              </Col>
              <Col md="1">
                <h4 className="m">{" : "}</h4>
              </Col>
              <Col md="7" className="text-left">
                <h4 className="m no-drag">{habibDetails?.education}</h4>
              </Col>
            </Row>
            <Row>
              <Col md="1"></Col>
              <Col md="3" className="text-left">
                <h4 className="m">
                  <GetLanguageString props="member_occupation" />
                </h4>
              </Col>
              <Col md="1">
                <h4 className="m">{" : "}</h4>
              </Col>
              <Col md="7" className="text-left">
                <h4 className="m no-drag">{habibDetails?.occupation}</h4>
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            {<GetLanguageString props="users_role_ok_btn" />}
          </Button>
        </ModalFooter>
      </Modal>
    </Draggable>
    </div>
  );
};

export default HabibDetail;
